import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, IconButton, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SearchableDropdown from '../../../../components/SearchableDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingAgencies, getVendorSettingConstantsAction2 } from '../../../../store/Settings/action';
import { convertToOptionList } from '../../../../constants/config';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { blue, red } from '@mui/material/colors';

const VendorSettingCModal = ({ open = false, handleClose, handleAdd, activePill = null }) => {
	const dispatch = useDispatch();
	const settingsData = useSelector((state) => state.settingsConstants);
	const [data, setData] = useState(null);
	const [agencies, setAgencies] = useState([]);
	const [items, setItems] = useState([]);

	const variables = activePill?.variables || [];

	const handleDropdownSearch = (e) => {
		if (e?.target?.value) {
			dispatch(getSettingAgencies({ type: activePill.apiFieldName, searchText: e.target.value }, (res) => {
				var newOptions = convertToOptionList(res?.data?.data || [], 'options');
				setAgencies(newOptions);
			}));
		}
	}

	const handleModalClose = () => {
		setData(null);
		setAgencies([]);
		handleClose();
	}

	const handleAddOption = () => {
		const check = checkValid();
		if (check?.valid && check?.expiryValid) {
			handleAdd({ agencyID: data?.agencyID, [variables[variables.length - 1].key]: items.map(item => item.name), expiryDate: items.map(item => item.expiryDate), });
			setData(null);
			setAgencies([]);
		} else {
			if (!check?.valid) {
				toast.error("Please fill all required fields", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
				});
			} else if (!check?.expiryValid) {
				toast.error("Please fill all the expiry dates", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
				});
			} else {
				toast.error("Please fill all required fields", {
					position: toast.POSITION.TOP_CENTER,
					autoClose: 2000,
				});
			}
		}
	}

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const checkValid = () => {
		let v = ['agencyID'], valid = true, expiryValid = true;
		v.map((item) => {
			if (!data[item]) {
				valid = false;
			}
		});
		if (!data.agencyID) {
			valid = false;
		} else if (!items.length) {
			valid = false;
		} else if (items.length) {
			items.forEach((item) => {
				if (!item.expiryDate) {
					expiryValid = false;
				}
			});
		}
		return { valid, expiryValid };
	}

	const handleAddThisItem = (key) => {
		setItems(prev => (
			[
				...prev,
				{ name: data[key], expiryDate: "" }
			]
		));
		setData(prev => (
			{
				...prev,
				[key]: '',
			}
		))
	}

	const handleChangeItemValue = (index, value) => {
		let l = [...items];
		l[index].expiryDate = value;
		setItems(l);
	}

	const handleRemoveItem = (index) => {
		let l = [...items];
		l = l.filter((item, itemIndex) => itemIndex != index);
		setItems(l);
	}

	const textInputs = activePill?.variables || [];

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={"sm"}
		>
			<DialogContent>
				<Typography variant='h6'>Add</Typography>
				<Box>
					{textInputs.map((item, index) => {
						if (item?.type === 'searchable-drodown') {
							return (
								<Box mt={1} key={index}>
									<Typography variant='subtitle2'>Select an {item.label} <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
									<SearchableDropdown loading={settingsData?.agencyLoading} options={agencies} label={'Search ' + item.label} handleInputChange={handleDropdownSearch} handleChange={(e, v, r) => { handleChange(item.key, v.id) }} />
								</Box>
							)
						} else {
							return (
								<Box mt={1} key={index} sx={{ width: '100%' }}>
									<Typography variant='subtitle2'>Enter {item?.label || ""} <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
									<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										<TextField
											value={data?.[item.key] || ""}
											onChange={(e) => handleChange(item.key, e.target.value)}
											placeholder={"Please add a " + item.label + "!"}
											sx={{ width: "100%", background: "#FFFFFF", mt: 1 }}
											inputProps={{ paddingBottom: "100px" }}
											id={`input-${item.key}`}
										/>
										{data?.[item.key] ?
											<IconButton id={`add-btn-${item.key}`} onClick={() => handleAddThisItem(item.key)} sx={{ ml: 1, color: blue[400], bgcolor: blue[50] }}>
												<AddIcon />
											</IconButton>
											:
											null}
									</Box>

								</Box>
							)
						}
					})}
					<Box sx={{ width: '100%', mt: 2 }}>
						{items.map((item, index) => {
							return (
								<Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', my: 1 }}>
									<Typography variant='body1'>{item?.name || ""}</Typography>
									<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '60%', px: 2 }}>
										<Box sx={{ width: '100%' }}>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													renderInput={(inputProps) =>
														<TextField
															InputLabelProps={{ shrink: true }}
															type="date"
															value={item?.expiryDate || ""}
															variant="outlined"
															onChange={(e) => {
																handleChangeItemValue(index, e.target.value);
															}}
															sx={{ width: '100%' }}
														/>}
												/>
											</LocalizationProvider>
										</Box>
										<IconButton id={`remove-btn-${index + 1}`} onClick={() => handleRemoveItem(index)} sx={{ ml: 2, color: red[400], bgcolor: red[50] }}>
											<CloseIcon />
										</IconButton>
									</Box>
								</Box>
							)
						})}
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button id='close-btn' onClick={handleModalClose}>Close</Button>
				<Button id='add-btn' onClick={handleAddOption} variant='contained'>Add</Button>
			</DialogActions>
		</Dialog>
	)
}

export default VendorSettingCModal;