import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";
import { SettingsOptionsDataTable } from '../SettingsScreen';
import { getSettingConstantsAction2 } from '../../../../store/Settings/action';
import { useDispatch, useSelector } from 'react-redux';

const SettingCUModal = ({ open = false, mode = 'add', handleClose, handleAdd, handleUpdate, handleMerge, currentData = null, activePill = null }) => {
	const dispatch = useDispatch();
	const list2 = useSelector((state) => state.settingsConstants.list2);
	const maxPage = useSelector((state) => state.settingsConstants.maxPage2);
	const currentPage = useSelector((state) => state.settingsConstants.currentPage2);
	const [data, setData] = useState(null);
	const [list, setList] = useState([]);
	const [step, setStep] = useState(0);
	const [merge, setMerge] = useState(false);
	const [searchText, setSearchText] = useState('');

	useEffect(() => {
		let d = {};
		if (mode == 'add') {
			(activePill?.variables || []).map((a) => {
				if (currentData?.[a.key]) {
					d[a.key] = "";
				}
			});
		} else {
			(activePill?.editVariables || activePill?.variables || []).map((a) => {
				if (currentData?.[a.key]) {
					d[a.key] = currentData[a.key];
				}
			});
		}
		setData(d);
		dispatch(getSettingConstantsAction2({ type: activePill.apiFieldName, searchText: '', page: 0 }));
	}, [currentData]);

	const handlePageChange = (pageNo) => {
		dispatch(getSettingConstantsAction2({ type: activePill.apiFieldName, searchText: searchText, page: pageNo }));
	}

	const handleSearch = (value = "") => {
		setSearchText(value);
		dispatch(getSettingConstantsAction2({ type: activePill.apiFieldName, searchText: value, page: 0 }));
	}

	const handleModalClose = () => {
		setData(null);
		setStep(0);
		setMerge(false);
		setList([]);
		handleClose();
	}

	const handleNext = () => {
		const check = checkValid();
		if (check) {
			setStep(prev => prev + 1);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleBack = () => {
		setStep(prev => prev - 1);
	}

	const handleMergeOption = () => {
		handleMerge({ data: data, id: currentData?.id, replace: list });
		setData(null);
		setStep(0);
		setMerge(false);
		setList([]);
	}

	const handleAddOption = () => {
		const check = checkValid();
		if (check) {
			handleAdd({ ...data });
			setData(null);
			setStep(0);
			setMerge(false);
			setList([]);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleUpdateOption = () => {
		const check = checkValid();
		if (check) {
			handleUpdate({ ...data, id: currentData?.id });
			setData(null);
			setStep(0);
			setMerge(false);
			setList([]);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const checkValid = () => {
		let v = [], valid = true;
		if (mode == 'add') {
			(activePill?.variables || []).map((a) => {
				v.push(a.key);
			});
		} else {
			(activePill?.editVariables || activePill?.variables || []).map((a) => {
				v.push(a.key);
			});
		}
		v.map((item) => {
			if (!data[item]) {
				valid = false;
			}
		})
		return valid;
	}

	const textInputs = mode === 'add' ? activePill?.variables || [] : activePill?.editVariables || activePill?.variables || [];
	const mergeAvailable = !activePill?.noMerge;

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={step === 1 ? "md" : "sm"}
		>
			<DialogContent>
				<Typography variant='h6'>{step == 1 ? "" : currentData?.id ? "Update" : ("Add" + " option")}</Typography>
				{step === 0 ?
					<Box>
						{textInputs.map((item, index) => {
							return (
								<Box mt={1} key={index}>
									<Typography variant='subtitle2'>Enter {item?.label || ""} <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
									<TextField
										value={data?.[item.key] || ""}
										onChange={(e) => handleChange(item.key, e.target.value)}
										placeholder={"Please add a " + item.label + "!"}
										sx={{ width: "100%", background: "#FFFFFF", mt: 1 }}
										inputProps={{ paddingBottom: "100px" }}
										id={`${item.key}-input`}
									/>
								</Box>
							)
						})}
						{mergeAvailable
							?
							<FormControlLabel id='merge-check-btn' control={<Checkbox checked={merge} onChange={() => setMerge(prev => !prev)} />} label="Merge with other options" />
							:
							null
						}
					</Box>
					:
					<Box>
						<SettingsOptionsDataTable
							title={"Select the options you want to merge with:"}
							data={list2}
							columns={activePill.columns}
							currentPage={currentPage}
							maxPage={maxPage}
							searchText={searchText}
							selectionMode={true}
							handleSelection={setList}
							handlePageChange={handlePageChange}
							requestSearch={handleSearch}
							selectedTabledData={list}
							disabledRows={currentData?.id ? [currentData.id] : []}
						/>
					</Box>
				}
			</DialogContent>
			<DialogActions>
				{step === 1 ?
					<Button id='back-modal-btn' onClick={handleBack}>Go Back</Button>
					:
					<Button id='close-modal-btn' onClick={handleModalClose}>Close</Button>
				}
				{merge
					?
					step === 0
						?
						<Button id='next-modal-btn' onClick={handleNext} variant='contained'>Next</Button>
						:
						<Button id='merge-modal-btn' onClick={handleMergeOption} variant='contained'>{currentData?.id ? "Update" : "Add"} & Merge</Button>
					:
					<Button id='add-modal-btn' onClick={() => currentData?.id ? handleUpdateOption() : handleAddOption()} variant='contained'>{currentData?.id ? "Update" : "Add"}</Button>
				}
			</DialogActions>
		</Dialog>
	)
}

export default SettingCUModal;