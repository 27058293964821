import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const GroupUserDeleteModal = ({ open = false, handleClose, handleDelete, currentData = null }) => {

	const handleModalClose = () => {
		handleClose();
	}

	const handleDeleteOption = () => {
		handleDelete({ user_id: currentData.id });
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={"xs"}
		>
			<DialogContent>
				<Typography variant='h6'>Are you sure you want to remove this user?</Typography>
			</DialogContent>
			<DialogActions>
				<Button id='cancel-btn' onClick={handleModalClose}>Cancel</Button>
				<Button id='remove-btn' color='error' onClick={handleDeleteOption} variant='contained'>Remove</Button>
			</DialogActions>
		</Dialog>
	)
}

export default GroupUserDeleteModal;