import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import _ from 'lodash';

// icons
import Candidates from "../../../assets/image/cand.png";
import Rec from '../../../assets/image/rec.png';
import PenRec from '../../../assets/image/penRec.png';
import Approvals from "../../../assets/image/cr.png";
import SOW from "../../../assets/image/sow.png";
import Settings from "../../../assets/image/set.png";
import Exports from "../../../assets/image/export.png";
import Profiles from "../../../assets/image/roles.png";
import Reports from "../../../assets/image/report.png";
import RequestOptions from "../../../assets/image/requestOptions.png";
import Logs from "../../../assets/image/logs.png";
import { blue, grey, red } from '@mui/material/colors';
import { Delete, Edit } from '@mui/icons-material';
import SectionCUModal from './SectionCUModal';
import SectionDeleteModal from './SectionDeleteModal';
import { useDispatch, useSelector } from 'react-redux';
import { deletePermission, editPermission, getPermissionList } from '../../../store/UserManagement/actions';
import { toast } from 'react-toastify';
import { getIconFromIdentifier } from '../../../utils/userManagementHelper';

const SectionManagement = () => {
  const dispatch = useDispatch();
  const { permissionList = [], loading = false } = useSelector(state => state.userMagement);

  const [activeSection, setActiveSection] = useState(null);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowUpdateModal = (section) => {
    setActiveSection(section);
    setShowUpdateModal(true);
  };

  const handleClose = () => {
    setActiveSection(null);
    setShowUpdateModal(false);
  };

  const handleUpdate = (data) => {
    setShowUpdateModal(false);
    dispatch(editPermission(data, res => {
      if (res?.status) {
        toast.success('Permission updated successfully');
        dispatch(getPermissionList());
      } else {
        toast.error('Failed to update permission!');
      }
    }));
  };

  const handleShowDeleteModal = (section) => {
    setActiveSection(section);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setActiveSection(null);
    setShowDeleteModal(false);
  };

  const handleDelete = (data) => {
    setShowDeleteModal(false);
    dispatch(deletePermission(data, res => {
      if (res?.status) {
        toast.success('Permission removed successfully');
        dispatch(getPermissionList());
      } else {
        toast.error('Failed to remove permission!');
      }
    }));
  };

  return (
    <Box sx={{ width: '100%', p: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
      {permissionList?.map(section => {
        return (
          <Box key={section.id} sx={{ width: '32%', display: 'flex', flexDirection: 'row', alignItems: !section?.children?.length ? 'center' : 'flex-start', height: section?.children?.length ? 'auto' : '80px', border: `1px solid ${grey[300]}`, borderTop: `1px solid ${grey[300]}`, p: 2, gap: 2, borderRadius: 2 }}>
            <Box sx={{ width: 40, height: 40, bgcolor: grey[100], borderRadius: 2, p: '5px' }}>
              <img src={getIconFromIdentifier(section?.identifier)} style={{ width: '100%', height: '100%', objectFit: 'contain', filter: 'invert(48%) sepia(94%) saturate(746%) hue-rotate(187deg) brightness(80%) contrast(110%)' }} />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2" sx={{ fontSize: 16 }}>{section?.name || ""}</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                  <Tooltip id={`edit-btn-${section?.identifier}`} title='Edit'>
                    <IconButton onClick={() => handleShowUpdateModal(section)} sx={{ border: `1px solid ${grey[300]}` }}>
                      <Edit sx={{ fontSize: 20, color: blue[500] }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip id={`edit-btn-${section?.identifier}`} title='Remove'>
                    <IconButton onClick={() => handleShowDeleteModal(section)} sx={{ border: `1px solid ${grey[300]}` }}>
                      <Delete sx={{ fontSize: 20, color: red[500] }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              {section?.children?.length ?
                <Box container sx={{ width: '100%', py: 1, mt: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 1 }}>
                  {section?.children?.map(child => {
                    return (
                      <Box sx={{ width: '100%', bgcolor: grey[100], borderRadius: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', px: 2, py: 1 }}>
                        <Typography variant="subtitle2" sx={{ fontSize: 14 }}>{child?.name || ""}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
                          <Tooltip id={`edit-btn-${child?.identifier}`} title='Edit'>
                            <IconButton onClick={() => handleShowUpdateModal(child)} sx={{ border: `1px solid ${grey[300]}` }}>
                              <Edit sx={{ fontSize: 16, color: blue[500] }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip id={`edit-btn-${child?.identifier}`} title='Remove'>
                            <IconButton onClick={() => handleShowDeleteModal(child)} sx={{ border: `1px solid ${grey[300]}` }}>
                              <Delete sx={{ fontSize: 16, color: red[500] }} />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                    )
                  })}
                </Box>
                :
                null
              }
            </Box>
          </Box>
        )
      })}
      {showUpdateModal ?
        <SectionCUModal open={showUpdateModal} handleClose={handleClose} currentData={activeSection} handleUpdate={handleUpdate} />
        :
        null
      }
      {showDeleteModal ?
        <SectionDeleteModal open={showDeleteModal} handleClose={handleCloseDeleteModal} currentData={activeSection} handleDelete={handleDelete} />
        :
        null
      }
    </Box>
  )
}

export default SectionManagement;