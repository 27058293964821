import React, { useEffect, useState } from "react";
import { FormHelperText, Grid, Typography } from "@mui/material";
import Select, { components } from "react-select";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

////////////////////////////////////////////////////
// This component will let user select multiple options
// in the select field. User can also search for an 
// option to check if it is available or not
////////////////////////////////////////////////////

function MultiSelectWithChipDropdown({
  id = "",
  getter,
  setter,
  inputName,
  form,
  value,
  handleSelection,
  fieldName,
  setFields,
  options: selectOptions,
  helperText = "",
  callback = () => { },
  setRemoveOption = () => { },
  returnType = "OBJECT",
  validateForm,
  setFormError,
  required,
  disabled,
  ...rest
}) {
  const [selection, setSelection] = useState(value);
  const [exceedLimit, setExceedLimit] = useState(false);
  const [validationError, setValidationError] = useState(false);

  const handleChange = (value, action) => {
    if (action?.action === "remove-value") {
      setRemoveOption(action?.removedValue?.value)
    }
    if (fieldName === "supplierDiversityForm") {
      const pName = "sdfEntires"
      setFields((prevValue) => {
        const updatedValue = {
          ...prevValue,
          [pName]: value?.map((i, j) => {
            return i.value;
          })
        }
        return updatedValue
      });
      setFields((prevValue) => {
        prevValue[fieldName] = value;
        return {
          ...prevValue,
        };
      });
      setExceedLimit(false);
    }
    if (fieldName === "insuranceEntries") {
      setFields((prevValue) => {
        const updatedValue = {
          ...prevValue,
          [fieldName]: value?.map((i, j) => {
            return i.value;
          })
        }
        return updatedValue
      });
      setExceedLimit(false);
    }
    if (fieldName !== "insuranceEntries" && fieldName !== "supplierDiversityForm" && value.length < 11) {
      setSelection(value);
      callback(value);
      setFields((prevValue) => {
        prevValue[fieldName] = value;
        return {
          ...prevValue,
        };
      });
      setExceedLimit(false);
    } else {
      setExceedLimit(true);
    }
  };

  useEffect(() => {
    if (required && validateForm) {
      setValidationError(
        selection === undefined || selection === null || selection?.length === 0
      );
      if (required && setFormError) {
        setFormError((prev) => {
          const findExistingField = prev?.findIndex(
            (index) => index.input === fieldName
          );

          if (findExistingField !== -1) {
            const update = prev?.map((index) => {
              if (index.input === fieldName) {
                index.isError =
                  selection === undefined ||
                  selection === null ||
                  selection?.length === 0;
              }
              return index;
            });
            return update;
          } else {
            return [
              ...prev,
              {
                input: fieldName,
                inputName: inputName,
                isError:
                  selection === undefined ||
                  selection === null ||
                  selection?.length === 0,
              },
            ];
          }
        });
      }
    }
  }, [selection, validateForm]);

  useEffect(() => {
    setSelection(value);
  }, [value]);

  const checkIfProperOptions =
    selection &&
    selection.length > 0 &&
    selection[0] !== null &&
    typeof selection[0] === "object";

  const MultiValue = props => {
    let found = null;
    (selectOptions || []).map((item) => {
      if (item.value === props.data.value) {
        found = item;
        return;
      }
    });
    return (
      <components.MultiValue {...props}>
        {found ? found.label : props.data.value}
      </components.MultiValue>
    )
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: 14,
              lineHeight: 2,
              display: "block",
              color: "#1a1a1a",
            }}
          >
            {inputName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            isMulti
            name="colors"
            value={checkIfProperOptions && selection?.length > 0 && selection}
            options={selectOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleChange}
            isDisabled={disabled}
            components={{ MultiValue }}
            id={id}
          />
          <FormHelperText>{helperText}</FormHelperText>
          {validationError && required && (
            <FormHelperText error>
              Please select {inputName.toLowerCase()}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default MultiSelectWithChipDropdown;
