import { GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_FAILED, GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_IN_PROGRESS, GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_SUCCESS } from "../../constants/store";
import api from "../../config/https";
import { encodeQueryData } from "../../utils/consultantConstants";

export const fetchAllPendingRecruitmentDraftsNotifications = () => async (dispatch) => {
  try {
    const payload = {
      offset:0,
      limit:100,
      contractor:[],
      contractor_status:[],
      entity:[],
      client:[],
      // sortKey:"",
      // sortValue:"asc"
    };
    dispatch({
      type: GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_IN_PROGRESS,
    });
    let res = await api.get('/masterAdmin/candidates/pendingRecuitmentDraft'+encodeQueryData(payload));
    if (res?.data?.status && res?.data?.data) {
      let data=res.data.data;
      let list=data?.result || [];
      dispatch({
        type: GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_SUCCESS,
        payload: {count:data?.count || list.length},
      });
    }else{
      dispatch({
        type: GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_FAILED,
    });
  }
};