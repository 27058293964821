import {
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILED,
  GET_CLIENT_REQUESTED,
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
} from "../../../constants/store";
import api from "../../../config/https";

export const getClientAction = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CLIENT_REQUESTED,
      payload: {
        loading: true,
      },
    });
    dispatch({
      type: SET_LOADING_TRUE,
    });
    let res = await api.get("/masterAdmin/clients");
    if (res && res.data && res.data.status) {
      dispatch({
        type: GET_CLIENT_SUCCESS,
        payload: {
          client: res.data.data.data,
          getSuccess: true,
        },
      });
      dispatch({
        type: SET_LOADING_FALSE,
      });
      setTimeout(() => {
        dispatch({
          type: GET_CLIENT_SUCCESS,
          payload: {
            client: res.data.data.data,
            getSuccess: false,
          },
        });
      }, 2000);
    } else {
      dispatch({
        type: GET_CLIENT_FAILED,
        payload: {
          error: true,
          loading: true,
        },
      });
      dispatch({
        type: SET_LOADING_FALSE,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_CLIENT_FAILED,
      payload: ["Error Occured"],
    });
    dispatch({
      type: SET_LOADING_FALSE,
    });
  }
};
