import { Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ComposedChart, Line, LabelList } from 'recharts';
import { COLORS, extractComboDataPoints } from '../../utils/chartHelper';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import NoDataCard from './noDataCard';
import CustomTooltip from './CustomTooltip';
import RenderCustomBarLabel from './customBarLabel';
import RenderCustomLineLabel from './customLineLabel';
import CustomizedAxisTick from './customAxisTick';
import calculateSize from 'calculate-size';

const ComboCard = ({ data = [], visualizationData = null, title = "", showAnimation = true }) => {

  const [axesB, setAxesB] = useState([]);
  const [axesL, setAxesL] = useState([]);
  const [list, setList] = useState([]);
  const [xName, setXName] = useState("");
  const [yName, setYName] = useState("");
  const [chartTitle, setTitle] = useState("");
  const [size, setSize] = useState(null);

  useEffect(() => {
    const dataPoints = extractComboDataPoints(data, visualizationData, title);
    setAxesB(dataPoints?.axesB || []);
    setAxesL(dataPoints?.axesL || []);
    setList(dataPoints?.list || []);
    setXName(dataPoints?.xName || "");
    setYName(dataPoints?.yName || "");
    setTitle(dataPoints?.title || title);
    setSize(dataPoints?.size || null);
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  const yNameSize = calculateSize(yName || "", {
    font: 'Poppins',
    fontSize: '14px',
  });

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <Box sx={{ width: '100%', height: '85%', display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', mt: 0.5 }}>
        {yName ?
          <div style={{ whiteSpace: 'normal', verticalAlign: 'bottom', width: 40 }}>
            <span style={{ transformOrigin: 'left', transform: "translate(50%," + (yNameSize.width / 2 + yNameSize.height / 2) + "px) rotate(-90deg)", display: 'block', textWrap: 'nowrap', fontSize: 14 }}>{yName}</span>
          </div>
          :
          null
        }
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            data={list}
            margin={{
              top: 0,
              right: yName ? 10 : 0,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis height={size?.height ? (size?.width > 100 ? 75 : size.height) : 25} dataKey={axesB[0]?.dataKey || null} tick={<CustomizedAxisTick rotate={size?.width > 100} />} interval={0} />
            <YAxis yAxisId='left' allowDataOverflow={true} orientation='right' />
            <YAxis yAxisId='right' allowDataOverflow={true} orientation='left' />
            {
              axesB?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Bar isAnimationActive={showAnimation} yAxisId='right' scale='linear' unit={item?.unit || null} name={item.displayName} legendType='circle' key={`bar-chart-gp-${index}`} dataKey={item.dataKey} fill={COLORS[index]}>
                      <LabelList dataKey={item.dataKey} content={<RenderCustomBarLabel shadow={false} unit={item?.unit || ''} />} />
                    </Bar>
                  )
                }
                return null;
              })
            }
            {
              axesL?.map((item, index) => {
                return (
                  <Line isAnimationActive={showAnimation} yAxisId='left' unit={item?.unit || null} name={item.displayName} legendType='circle' key={`line-chart-gp-${index}`} dataKey={item.dataKey} stroke={COLORS[index]} strokeWidth={2} >
                    <LabelList dataKey={item.dataKey} content={<RenderCustomLineLabel unit={item?.unit || ''} />} />
                  </Line>
                )
              })
            }
            <Tooltip content={<CustomTooltip />} />
            <Legend align="left" verticalAlign='top' wrapperStyle={{ paddingBottom: 20 }} />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
      <Typography noWrap sx={{ textAlign: 'center', fontSize: 14 }}>{xName}</Typography>
    </Box>
  )
}

export default ComboCard;