import { useEffect, useState } from "react";
import { Box, Button, Chip, Tooltip, Typography } from "@mui/material"
import AppBar from "../../../components/AppBar";
import SearchBar from "../../../components/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { OverlayLoader, TabNav, TabPanel } from "../Settings/SettingsScreen";
import { userRoleArray, userRoleLabels } from "../../../constants/userRoles";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { green, grey, red } from "@mui/material/colors";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PermissionsManagement from "./PermissionsManagement";
import UserCModal from "./UserCModal";
import UserStatusToggleModal from "./UserStatusToggleModal";
import SectionCUModal from "./SectionCUModal";
import SectionManagement from "./SectionManagement";
import { addNewPermission, addPermissionUser, getPermissionList, getPermissionUserList, toggleUserStatusPermission, updateUserPermission } from "../../../store/UserManagement/actions";
import { toast } from "react-toastify";
import { useDebounce } from "../../../utils/useDebounce";
import { useLocation } from "react-router-dom";

const UserManagement = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const ids = (location?.pathname || "").split("/");
  const identifier = ids.length ? ids[1] : "";
  const { currentUser = null } = useSelector(state => state.auth);
  const { loading = false, userList = [], loadingUsers = false } = useSelector(state => state.userMagement);
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: identifier });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [showPermissionSidebar, setShowPermissionSidebar] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUserStatusModal, setShowUserStatusModal] = useState(false);
  const [activePill, setActivePill] = useState(null);
  const [showAddPermissionModal, setShowAddPermissionModal] = useState(false);

  const searchText = useDebounce(searchTerm, 300);

  useEffect(() => {
    dispatch(getPermissionList());
  }, []);

  useEffect(() => {
    if (activeTab === 0) {
      setActivePill(userRoleArray[0]);
    };
    setActiveUser(null);
    setShowPermissionSidebar(false);
    setSearchTerm('');
  }, [activeTab]);

  useEffect(() => {
    dispatch(getPermissionUserList({ search: searchText }));
  }, [searchText]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleAddForm = () => {
    setShowAddModal(true);
  };

  const handleCloseAddForm = () => {
    setShowAddModal(false);
  };

  const handleAddUser = (data = null) => {
    setShowAddModal(false);
    dispatch(addPermissionUser(data, res => {
      if (res.status) {
        toast.success('New user added successfully');
        dispatch(getPermissionUserList());
      } else {
        toast.error('Failed to add user!');
      }
    }));
  };

  const handleSettingSelect = (e) => {
    setActivePill(e);
    setShowPermissionSidebar(false);
  };

  const handleShowUserStatusModal = (user) => {
    setShowUserStatusModal(true);
    setActiveUser(user);
  };

  const handleCloseUserStatusModal = () => {
    setShowUserStatusModal(false);
    setActiveUser(null);
  };

  const handleUserStatus = (data) => {
    setShowUserStatusModal(false);
    dispatch(toggleUserStatusPermission(data, res => {
      if (res.status) {
        toast.success('User status updated successfully');
        dispatch(getPermissionUserList());
      } else {
        toast.error('Failed to toggle user status!');
      }
    }));
  };

  const handleShowUserPermissions = (user) => {
    setActiveUser(user);
    setShowPermissionSidebar(true);
  };

  const onClosePermissions = () => {
    setActiveUser(null);
    setShowPermissionSidebar(false);
  };

  const handleSaveUserPermission = (data = null) => {
    setActiveUser(null);
    dispatch(updateUserPermission(data, res => {
      if (res.status) {
        toast.success('User updated successfully');
        dispatch(getPermissionUserList());
      } else {
        toast.error('Failed to update user!');
      }
    }));
  };

  const handleShowAddPermission = () => {
    setShowAddPermissionModal(true);
  };

  const handleCloseAddPermissionModal = () => {
    setShowAddPermissionModal(false);
  };

  const handleAddPermission = (data = null) => {
    setShowAddPermissionModal(false);
    dispatch(addNewPermission(data, res => {
      if (res?.status) {
        toast.success('Permission added successfully');
        dispatch(getPermissionList());
      } else {
        toast.error('Failed to add permission!');
      }
    }));
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        const data = params.row;
        return (
          <Box>
            <Typography variant="subtitle2" sx={{ color: '#000' }}>{data?.name || ''}</Typography>
            <Typography variant="subtitle2" sx={{ color: grey[700] }}>{data?.email || ""}</Typography>
          </Box>
        )
      }
    },
    {
      field: "is_active",
      headerName: "Status",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        const data = params.row;
        if (data?.is_active) {
          return (
            <Tooltip id={data?.id} title='Toggle user status'>
              <Button onClick={() => handleShowUserStatusModal(data)} sx={{ bgcolor: green[100], borderRadius: 10, textTransform: 'none', p: 0, py: 0.5, '&:hover': { background: green[100] } }}>
                <Typography variant="subtitle2" sx={{ color: green[700], fontSize: 12 }}>Active</Typography>
              </Button>
            </Tooltip>
          )
        }
        return (
          <Tooltip id={data?.id} title='Toggle user status'>
            <Button onClick={() => handleShowUserStatusModal(data)} sx={{ bgcolor: red[100], borderRadius: 10, textTransform: 'none', p: 0, py: 0.5, '&:hover': { background: red[100] } }}>
              <Typography variant="subtitle2" sx={{ color: red[700], fontSize: 12 }}>Inactive</Typography>
            </Button>
          </Tooltip>
        )
      }
    },
    {
      field: "role",
      headerName: "Role",
      minWidth: 150,
      flex: 1,
      valueFormatter: params => params?.value ? userRoleLabels?.[params?.value] : "",
    },
    {
      field: "created_at",
      headerName: "Date Added",
      minWidth: 120,
      flex: 1,
      renderCell: (params) => {
        const data = params.row;
        return (
          <Box>
            <Typography variant="subtitle2" sx={{ color: '#000' }}>{data?.created_at ? moment(data.created_at).format('DD-MM-YYYY') : ''}</Typography>
            <Typography variant="subtitle2" sx={{ color: grey[700] }}>{data?.created_at ? moment(data.created_at).format('hh:mm a') : ''}</Typography>
          </Box>
        )
      }
    },
    {
      headerName: "Actions",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        const rows = params.row;
        if (hasWritePermission) {
          return (
            <Tooltip id={'tooltip-view-' + (rows?.email || "")} title="Review">
              <Button
                aria-label='view'
                size='small'
                color='primary'
                id={'view-' + (rows?.email || "")}
                sx={{ display: 'flex', flexDirection: 'row', gap: 1, textTransform: 'none' }}
                onClick={() => handleShowUserPermissions(rows)}
                variant="outlined"
              >
                <VisibilityIcon fontSize='small' />
                <Typography variant="subtitle2">View Permissions</Typography>
              </Button>
            </Tooltip>
          )
        } else return null;
      }
    },
  ];

  return (
    <Box sx={{ width: '100%', height: '100svh', bgcolor: '#fff', position: 'relative', p: 2, position: 'relative', overflow: 'hidden' }}>
      <AppBar appBarTitle='User Management' >
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
          {activeTab === 0 ?
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <SearchBar
                placeholder={"Search with Name or Email"}
                setSearchTerm={(value) => setSearchTerm(value)}
                value={searchTerm}
              />
            </Box>
            :
            null
          }
          {hasWritePermission ?
            activeTab ?
              <Button variant="contained" sx={{ ml: 'auto' }} onClick={handleShowAddPermission}>Add new permission</Button>
              :
              <Button variant="contained" sx={{ ml: 'auto' }} onClick={handleAddForm}>Add new user</Button>
            :
            null
          }
        </Box>
      </AppBar>
      <Box
        id="recruitment-list-box"
        sx={{
          background: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 2,
          mt: 2,
          width: "100%",
          position: 'relative',
          height: 'calc(100svh - 120px)',
          overflowX: 'hidden'
        }}>
        <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', px: 2 }}>
          <TabNav list={['Users', 'Permission Builder']} value={activeTab} handleChange={handleChangeTab} />
        </Box>
        <TabPanel value={activeTab} index={0} id='user-management'>
          {searchTerm ?
            null
            :
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2, pr: 2, }}>
              <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', overflowX: 'auto', gap: 1, width: '100%' }}>
                {((userRoleArray || [])).map((set, index) => {
                  return (
                    <Chip key={"chip-" + index} id={`chip-${index + 1}`} color="primary" label={set?.label || ""} p={1} variant={activePill?.id === set?.id ? "filled" : "outlined"} onClick={() => handleSettingSelect(set)} />
                  )
                })}
              </Box>
            </Box>
          }
          <Box sx={{ width: '100%', height: `calc(100svh - ${searchTerm ? 180 : 240}px)`, overflowY: 'auto' }}>
            {searchTerm ?
              <List data={userList} columns={columns} maxPage={1} currentPage={1} />
              :
              <List data={userList.filter(item => item?.role === activePill?.id)} columns={columns} maxPage={1} currentPage={1} />
            }
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1} id='user-management'>
          <Box sx={{ width: '100%', height: 'calc(100svh - 180px)', overflowY: 'auto' }}>
            <SectionManagement />
          </Box>
        </TabPanel>
      </Box>
      <UserCModal open={showAddModal} handleClose={handleCloseAddForm} handleAdd={handleAddUser} />
      <PermissionsManagement currentData={activeUser} showPermissionSidebar={activeUser && showPermissionSidebar} onClosePermissions={onClosePermissions} onSave={handleSaveUserPermission} />
      {activeUser && showUserStatusModal ?
        <UserStatusToggleModal open={showUserStatusModal} currentData={activeUser} handleClose={handleCloseUserStatusModal} handleStatus={handleUserStatus} />
        :
        null
      }
      {showAddPermissionModal ?
        <SectionCUModal open={showAddPermissionModal} handleClose={handleCloseAddPermissionModal} handleAdd={handleAddPermission} />
        :
        null
      }
      {loading || loadingUsers ? <OverlayLoader /> : null}
    </Box>
  )
};

const List = ({ data = [], columns = [] }) => {
  return (
    <DataGrid
      rows={data}
      columns={columns}
      pagination
      pageSize={10}
      rowsPerPageOptions={[10]}
      density="comfortable"
      disableSelectionOnClick
      disableRowSelectionOnClick
      sx={{
        px: 2,
        border: 0,
        height: "100%",
        "& .MuiDataGrid-columnHeaderTitle": {
          color: "#1089FF",
        },
        "& .MuiDataGrid-cell": {
          margin: 0,
          border: 0,
          width: 300,
        },
        "& .MuiDataGrid-row": {
          margin: 0,
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
      }}
    />
  )
}

export default UserManagement;