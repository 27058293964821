import { Box } from '@mui/material';
import Markdown from 'react-markdown'

const VisualCard = ({ visualData = null }) => {
  let text = visualData?.text || "";
  let textAlign = visualData[`text.align_horizontal`] || 'start';
  let verticalAlign = visualData[`text.align_vertical`] || 'start';
  if (verticalAlign === 'middle') {
    verticalAlign = 'center';
  }
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: verticalAlign,
        p: 2,
        color: '#475569',
        textAlign: textAlign,
      }}>
      <Markdown>{text}</Markdown>
    </Box>
  )
}

export default VisualCard;