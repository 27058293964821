import axios from "axios";
import { BACKEND_URL } from "../constants/constants";
import { jwtDecode } from "jwt-decode";
import dayjs from 'dayjs'

const api = axios.create({
  baseURL: BACKEND_URL,
});

// Request interceptor
api.interceptors.request.use(
  async (config) => {
    let userData = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : null;
    const token = userData?.token;
    const refresh_token = userData?.refresh_token;
    const sectionId = localStorage.getItem("sectionId");
    const regionCode = localStorage.getItem('master-data-region-code') || process.env.REACT_APP_REGION || null;
    if (token && config) {
      config.headers["Authorization"] = token;
      config.headers["X-CALLING-SECTION-ID"] = sectionId;
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["X-CALLING-REGION-CODE"] = regionCode || 'US';
    if (token && refresh_token) {
      try {
        const user = jwtDecode(token);
        const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
        if (!isExpired) return config;
        const res = await axios.post(`${BACKEND_URL}/masterAdmin/user/refreshToken`, {
          refresh_token: refresh_token,
        });
        let newToken = res?.data?.data?.token?.token;
        let permisson_matrix = res?.data?.data?.token?.permisson_matrix || [];
        let regionsList = res?.data?.data?.token?.regions || [];
        userData.token = newToken;
        userData.permisson_matrix = permisson_matrix;
        userData.regions = regionsList;
        localStorage.setItem("userDetails", JSON.stringify(userData));
        config.headers['Authorization'] = newToken;
      } catch (error) {
        console.log(error);
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.data?.message === "Invalid token!") {
      localStorage.removeItem('userDetails');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
