import { GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED, GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_IN_PROGRESS, GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_SUCCESS, GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED, GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_IN_PROGRESS, GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_SUCCESS, GET_PENDING_RECRUITMENT_DRAFT_LIST_FAILED, GET_PENDING_RECRUITMENT_DRAFT_LIST_IN_PROGRESS, GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_FAILED, GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_IN_PROGRESS, GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_SUCCESS, GET_PENDING_RECRUITMENT_DRAFT_LIST_SUCCESS } from "../../constants/store";
import { convertToOptions } from "../../utils/consultantConstants";

// The initial state
export const initialState = {
  loading: true,
  list:[],
  options:[],
  maxPage:0,
  currentPage: 0,
  pendingCount: 0,
};

function pendingRecruitmentDraftReducer(state = initialState, action){
  switch (action.type) {
    // options
    case GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading:true,
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_SUCCESS: {
      let convertedOptions = convertToOptions(action?.payload?.options || {});
      const newState = {
        ...state,
        loading:false,
        options:{
          ...state.options,
          ...convertedOptions
        },
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED: {
      const newState = {
        ...state,
        loading:false,
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading:true,
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_SUCCESS: {
      const newState = {
        ...state,
        loading:false,
        options:{
          ...state.options,
          ...action.payload.options
        },
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED: {
      const newState = {
        ...state,
        loading:false,
      };
      return newState;
    }

    // pending recruitment list
    case GET_PENDING_RECRUITMENT_DRAFT_LIST_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_LIST_SUCCESS: {
      const newState = {
          ...state,
          loading: false,
          list: action.payload.data,
          maxPage:action.payload.maxPage,
          currentPage:action.payload.currentPage,
          pendingCount: action.payload.maxPage,
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_LIST_FAILED: {
      const newState = {
        ...state,
        error: true,
        loading: false,
        list:action.payload.reset?[]:state.list
      };
      return newState;
    }
    // pending notifications
    case GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_IN_PROGRESS: {
      const newState = {
        ...state,
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_SUCCESS: {
      const newState = {
          ...state,
          pendingCount: action.payload.maxPage,
      };
      return newState;
    }
    case GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_FAILED: {
      const newState = {
        ...state,
      };
      return newState;
    }
    default:
      return state;
  }
}
export default pendingRecruitmentDraftReducer;
