import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";
import SearchableDropdown from '../../../components/SearchableDropdown';
import { getUserList } from '../../../store/Reports/action';
import { convertToOptionList } from '../../../constants/config';

const GroupUserCUModal = ({ open = false, handleClose, handleAdd }) => {
	const [data, setData] = useState(null);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(false);

	const handleModalClose = () => {
		setData(null);
		handleClose();
	}

	const handleAddOption = () => {
		const check = checkValid();
		if (check) {
			handleAdd({ ...data });
			setData(null);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const checkValid = () => {
		let valid = true;
		if (!data?.user_id) {
			valid = false;
		}
		return valid;
	}

	const handleSearch = async (e) => {
		if (e?.target?.value) {
			setLoading(true);
			let res = await getUserList({ searchText: e.target.value });
			if (res?.status) {
				var newOptions = convertToOptionList(res?.data?.data || [], 'name');
				setOptions(newOptions);
			}
			setLoading(false);
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth="xs"
		>
			<DialogContent>
				<Typography variant='h6'>Add New User</Typography>
				<Grid container>
					<Grid item xs={12}>
						<Typography variant='subtitle2' sx={{ mb: 1 }}>Name <span style={{ color: 'red' }}>*</span></Typography>
						<SearchableDropdown
							loading={loading}
							options={options}
							label={'Search user...'}
							handleInputChange={handleSearch}
							handleChange={(e, v, r) => { handleChange('user_id', v.id) }}
							fields={['email']}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button id='close-btn' onClick={handleModalClose}>Close</Button>
				<Button id='add-btn' onClick={handleAddOption} variant='contained'>Add</Button>
			</DialogActions>
		</Dialog>
	)
}

export default GroupUserCUModal;