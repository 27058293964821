import { Box, Button, CircularProgress, Grid, IconButton, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import AppBar from "../../../components/AppBar";
import { useEffect, useState } from "react";
import { blue, grey, red } from "@mui/material/colors";
import DraggableComponent from "../../../components/Draggable";
import { formatDashboardName } from "../../../utils/chartHelper";
import { useDispatch, useSelector } from "react-redux";
import { addNewReportGroup, createDashboardUser, deleteDashboardUser, deleteReportGroup, getDashboardById, getDashboardUsers, getDashboards, getReportGroupById, getReportGroups, getUserDashboards, updateReportGroupDashboardPermissions } from "../../../store/Reports/action";
import { toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import GroupCUModal from "./GroupCUModal";
import ClearIcon from '@mui/icons-material/Clear';
import GroupDeleteModal from "./GroupDeleteModal";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { DataGrid } from "@mui/x-data-grid";
import GroupUserCUModal from "./GroupUserCUModal";
import DeleteIcon from '@mui/icons-material/Delete';
import GroupUserDeleteModal from "./GroupUserDeleteModal";
import moment from "moment";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import _ from "lodash";
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

const initialTabs = [
  {
    id: 1,
    name: "Dashboards",
  },
  {
    id: 2,
    name: "Permissions"
  },
];

const ReportScreen = () => {
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: getCurrentPageIdentifier() });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  const dashboards = useSelector((state) => state.reports.dashboards);
  const userDashboards = useSelector((state) => state.reports.userDashboards);
  const activeDashboards = useSelector((state) => state.reports.activeDashboards);
  const groups = useSelector((state) => state.reports.groups);
  const cards = useSelector((state) => state.reports.cards);
  const loading = useSelector((state) => state.reports.loading);
  const users = useSelector((state) => state.reports.users);

  let finalTabs = [];
  if (hasWritePermission) {
    finalTabs = [...initialTabs];
  } else {
    finalTabs = [initialTabs[0]];
  }

  const [tabs, setTabs] = useState(finalTabs);
  const [activeTab, setActiveTab] = useState(0);
  const [activeDashboard, setActiveDashboard] = useState(null);
  const [activeGroup, setActiveGroup] = useState(null);
  const [currentGroupData, setCurrentGroupData] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [visibleDashboards, setVisibleDashboards] = useState([]);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [showUserAddModal, setShowUserAddModal] = useState(false);
  const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);

  useEffect(() => {
    if (activeTab === 0) {
      dispatch(getUserDashboards());
    } else if (activeTab === 1) {
      dispatch(getReportGroups());
    }
  }, [activeTab]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
    setActiveDashboard(null);
    setActiveGroup(null);
  };

  const handleNavigate = (dashboard) => {
    if (activeDashboards[dashboard.id]) {
      let dashboardData = activeDashboards[dashboard.id] || null;
      setActiveDashboard({
        ...dashboardData,
        tabs: dashboardData?.tabs ? dashboardData.tabs.sort((a, b) => a.position > b.position ? 1 : -1) : [],
      });
    } else {
      dispatch(getDashboardById({ id: dashboard.id }, res => {
        if (res.status) {
          setActiveDashboard({
            ...res.data,
            tabs: res.data?.tabs ? res.data.tabs.sort((a, b) => a.position > b.position ? 1 : -1) : [],
          });
        } else {
          toast.error(res.message);
        }
      }));
    }
  }

  const handleNavigateGroup = (group) => {
    dispatch(getDashboards());
    dispatch(getReportGroupById({ id: group.id }, res => {
      if (res.status) {
        let d = res?.data || [];
        dispatch(getDashboardUsers({ id: group.id }));
        setActiveGroup(group);
        setVisibleDashboards(d.map(item => item.dashboard_id));
      } else {
        toast.error(res.message);
      }
    }));
  }

  const handleBack = () => {
    setActiveDashboard(null);
    setActiveGroup(null);
  }

  const handleShowAddNewGroup = () => {
    setShowAddModal(true);
    setCurrentGroupData(null);
  }

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setCurrentGroupData(null);
  }

  const handleAddNewGroup = (e) => {
    setShowAddModal(false);
    dispatch(addNewReportGroup(e, (res) => {
      if (res.status) {
        toast.success("Successfully, added a new group", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getReportGroups());
      } else {
        toast.error(res?.message || 'Failed to create a new group!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleShowDeleteGroup = (e) => {
    setShowDeleteModal(true);
    setCurrentGroupData(e);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrentGroupData(null);
  }

  const handleDeleteGroup = (e) => {
    setShowDeleteModal(false);
    dispatch(deleteReportGroup(e, (res) => {
      if (res.status) {
        toast.success("Group removed successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setCurrentGroupData(null);
        dispatch(getReportGroups());
      } else {
        toast.error('Failed to remove this group!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleMarkDashboardVisible = (id = "", visibility = true) => {
    let list = [...visibleDashboards];
    if (visibility) {
      list.push(id);
    } else {
      list = list.filter(item => item !== id);
    }
    const payload = {
      "group_id": activeGroup?.id,
      "dashboard_ids": list
    };
    dispatch(updateReportGroupDashboardPermissions(payload, (res) => {
      if (res.status) {
        toast.success("Updated dashboard permissions successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setVisibleDashboards(list);
      } else {
        toast.error('Faied to update dashboard permissions!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleShowAddUser = () => {
    setShowUserAddModal(true);
  }

  const handleCloseAddUser = () => {
    setShowUserAddModal(false);
  }

  const handleAddNewUser = (e) => {
    setShowUserAddModal(false);
    let payload = {
      group_id: activeGroup.id,
      ...e,
    }
    dispatch(createDashboardUser(payload, (res) => {
      if (res.status) {
        toast.success("Successfully, added a new user", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getDashboardUsers({ id: activeGroup.id }));
      } else {
        toast.error((res?.message || "").includes('Duplicate') ? 'User already exist!' : res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleShowDeleteUser = (e) => {
    setShowUserDeleteModal(true);
    setCurrentUserData(e);
  }

  const handleCloseUserDeleteModal = () => {
    setShowUserDeleteModal(false);
    setCurrentUserData(null);
  }

  const handleDeleteUser = (e) => {
    setShowUserDeleteModal(false);
    const payload = {
      group_id: activeGroup.id,
      ...e,
    }
    dispatch(deleteDashboardUser(payload, (res) => {
      if (res.status) {
        toast.success("User removed successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setCurrentUserData(null);
        dispatch(getDashboardUsers({ id: activeGroup.id }));
      } else {
        toast.error('Failed to remove this user!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  let usersColumns = [
    {
      field: "id",
      flex: 1,
      headerName: "ID",
      maxWidth: 80,
    },
    {
      field: "name",
      flex: 1,
      headerName: "Name",
      minWidth: 150,
    },
    {
      field: "email",
      flex: 1,
      headerName: "Email",
      minWidth: 150,
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Joined",
      minWidth: 150,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      flex: 1,
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id={`delete-tooltip-${rows?.id}`} title='Remove'>
                <IconButton id={`delete-btn-${rows?.id}`} aria-label='delete' size='small' color='error' onClick={() => handleShowDeleteUser(rows)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      {activeDashboard?.id || activeGroup?.id
        ?
        null
        :
        <AppBar appBarTitle="Reports" />
      }
      <Box
        sx={{
          background: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 2,
          mt: activeDashboard?.id || activeGroup?.id ? 0 : 2,
          width: "100%",
          position: 'relative',
          pb: 2,
          height: 'calc(100svh -120px)'
        }}
      >
        <Box sx={{ display: activeDashboard?.id || activeGroup?.id ? "none" : "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', pl: 2, zIndex: 999 }}>
          <TabNav value={activeTab} handleChange={handleChangeTab} tabs={tabs} />
        </Box>
        <TabPanel value={activeTab} index={0}>
          {
            activeDashboard?.id
              ?
              <DraggableComponent dashboard={activeDashboard} handleBack={handleBack} />
              :
              <Box sx={{ width: '100%', height: 'calc(100svh - 180px)', overflowY: 'scroll' }}>
                {userDashboards?.length ?
                  <Grid container gap={2} p={2} sx={{ width: '100%' }}>
                    {userDashboards?.map((dashboard, dashboardIndex) => {
                      return (
                        <Grid item xs={2.5} xl={2} key={`dashboard-view-${dashboard.id}`} sx={{ background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '10px', p: 3, width: '100%', height: '270px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden' }}>
                          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Box sx={{ borderRadius: '50%', bgcolor: blue[500], width: '80px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography sx={{ fontSize: '30px', fontWeight: 'bold', color: 'white', textTransform: 'uppercase' }}>{dashboard.name[0]}</Typography>
                            </Box>
                            <Typography noWrap sx={{ width: '100%', fontSize: '20px', fontWeight: 'medium', color: 'black', mt: 5, textAlign: 'center' }}>{formatDashboardName(dashboard?.name)}</Typography>
                          </Box>
                          <Button id={`view-btn-${dashboardIndex}`} onClick={() => handleNavigate(dashboard)}>View</Button>
                        </Grid>
                      )
                    })}
                  </Grid>
                  :
                  <Box sx={{ p: 2, my: 2, mx: 'auto', width: '98%', height: '200px', bgcolor: grey[100], borderRadius: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Box sx={{ width: 35, height: 35, color: '#475569' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                      {loading ? <CircularProgress size={20} color="primary" /> : null}
                      <Typography sx={{ fontSize: '14px', color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{loading ? `Retrieving all available dashboard...` : `No dashboard found`}</Typography>
                    </Box>
                  </Box>
                }
              </Box>
          }
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          {
            activeGroup?.id
              ?
              <Box sx={{ width: '100%', mt: 2, height: 'calc(100svh - 70px)', overflowY: 'scroll' }}>
                <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', pl: 2 }}>
                  <Box id='back-btn' onClick={handleBack} sx={{ bgcolor: 'white', border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 2, width: 35, height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', color: blue[600], pr: 0.5, mr: 2, cursor: 'pointer' }}>
                    <ArrowBackIosNewIcon />
                  </Box>
                  <Typography sx={{ fontWeight: 'medium', fontSize: '24px', color: '#475569', fontFamily: 'Poppins', fontWeight: 600 }}>{formatDashboardName(activeGroup.name)}</Typography>
                </Box>
                {
                  dashboards?.length
                    ?
                    <Grid container gap={2} p={2} sx={{ width: '100%', overflowX: 'auto' }}>
                      {dashboards?.map((dashboard, dashboardIndex) => {
                        return (
                          <Grid item xs={2.5} xl={2} key={`dashboard-view-${dashboard.id}`} sx={{ background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '10px', p: 3, width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden', position: 'relative' }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                              <Box sx={{ borderRadius: '50%', bgcolor: blue[500], width: '80px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{ fontSize: '30px', fontWeight: 'bold', color: 'white', textTransform: 'uppercase' }}>{dashboard.name[0]}</Typography>
                              </Box>
                              <Typography noWrap sx={{ width: '100%', fontSize: '20px', fontWeight: 'medium', color: 'black', mt: 5, textAlign: 'center' }}>{formatDashboardName(dashboard?.name)}</Typography>
                            </Box>
                            {
                              visibleDashboards.includes(dashboard.id)
                                ?
                                <IconButton id={`visibility-btn-${dashboardIndex}`} onClick={() => handleMarkDashboardVisible(dashboard.id, false)} sx={{ position: 'absolute', right: 10, top: 10, color: blue[600] }}>
                                  <VisibilityIcon />
                                </IconButton>
                                :
                                <IconButton id={`visibility-btn-${dashboardIndex}`} onClick={() => handleMarkDashboardVisible(dashboard.id, true)} sx={{ position: 'absolute', right: 10, top: 10, color: grey[400] }}>
                                  <VisibilityOffIcon />
                                </IconButton>
                            }
                          </Grid>
                        )
                      })}
                    </Grid>
                    :
                    <Box sx={{ p: 2, mt: 2, mx: 'auto', width: '98%', height: '200px', bgcolor: grey[100], borderRadius: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Box sx={{ width: 35, height: 35, color: '#475569' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                        {loading ? <CircularProgress size={20} color="primary" /> : null}
                        <Typography sx={{ fontSize: '14px', color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{loading ? `Retrieving all available dashboard...` : `No dashboard found`}</Typography>
                      </Box>
                    </Box>
                }
                <Box sx={{ width: '100%', mt: 2, px: 2 }}>
                  <Box sx={{ width: '100%', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                    <Typography sx={{ fontWeight: 'medium', fontSize: '24px', color: '#475569', my: 2 }}>User List</Typography>
                    <Button id='add-btn' onClick={handleShowAddUser} variant="contained" startIcon={<AddIcon />}>
                      Add New
                    </Button>
                  </Box>
                  <DataGrid
                    rows={users}
                    columns={usersColumns}
                    pagination
                    density="comfortable"
                    disableSelectionOnClick
                    disableRowSelectionOnClick
                    rowsPerPageOptions={[]}
                    headerHeight={24}
                    autoHeight={true}
                    sx={{
                      border: 0,
                      "& .MuiDataGrid-columnHeaderTitle": {
                        color: "#1089FF",
                      },
                      "& .MuiDataGrid-cell": {
                        margin: 0,
                        border: 0,
                        width: 300,
                      },
                      "& .MuiDataGrid-row": {
                        margin: 0,
                      },
                      "& .MuiDataGrid-iconSeparator": {
                        display: "none",
                      },
                    }}
                  />
                </Box>
              </Box>
              :
              <Box sx={{ width: '100%', height: 'calc(100svh - 180px)', overflowY: 'scroll' }}>
                <Grid container gap={2} p={2} sx={{ width: '100%' }}>
                  {groups.map((group, groupIndex) => {
                    return (
                      <Grid item xs={2.5} xl={2} key={`dashboard-view-${group.id}`} sx={{ background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '10px', p: 3, width: '100%', height: '270px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden', position: 'relative' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                          <Box sx={{ borderRadius: '50%', bgcolor: blue[500], width: '80px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Typography sx={{ fontSize: '30px', fontWeight: 'bold', color: 'white', textTransform: 'uppercase' }}>{group.name[0]}</Typography>
                          </Box>
                          <Typography noWrap sx={{ width: '100%', fontSize: '24px', fontWeight: 'medium', color: 'black', mt: 5, textAlign: 'center' }}>{group?.name || ""}</Typography>
                        </Box>
                        <Button id={`view-btn-${groupIndex}`} onClick={() => handleNavigateGroup(group)}>View</Button>
                        <IconButton id={`delete-btn-${groupIndex}`} onClick={() => handleShowDeleteGroup(group)} sx={{ position: 'absolute', right: 0, top: 0, color: red[400] }}>
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    )
                  })}
                  <Grid id='add-btn' onClick={handleShowAddNewGroup} item xs={2.5} xl={2} sx={{ background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '10px', p: 3, width: '100%', height: '270px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', cursor: 'pointer' }}>
                    <AddIcon sx={{ fontSize: 40, color: blue[600] }} />
                  </Grid>
                </Grid>
              </Box>
          }
        </TabPanel>
      </Box>
      {
        showAddModal
          ?
          <GroupCUModal
            open={showAddModal}
            handleClose={handleCloseAddModal}
            handleAdd={handleAddNewGroup}
          />
          :
          null
      }
      {
        showDeleteModal
          ?
          <GroupDeleteModal
            open={showDeleteModal}
            handleClose={handleCloseDeleteModal}
            handleDelete={handleDeleteGroup}
            currentData={currentGroupData}
          />
          :
          null
      }
      {
        showUserAddModal
          ?
          <GroupUserCUModal
            open={showUserAddModal}
            handleClose={handleCloseAddUser}
            handleAdd={handleAddNewUser}
          />
          :
          null
      }
      {
        showUserDeleteModal
          ?
          <GroupUserDeleteModal
            open={showUserDeleteModal}
            handleClose={handleCloseUserDeleteModal}
            handleDelete={handleDeleteUser}
            currentData={currentUserData}
          />
          :
          null
      }
      {loading && userDashboards?.length ? <OverlayLoader /> : null}
    </Box>
  )
}


const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4 }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

export const TabNav = ({ value = 0, handleChange, tabs = [] }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label='report-tabs'
    >
      {
        tabs.map((tab, tabIndex) => {
          return (
            <Tab
              key={`tab-${tabIndex}`}
              label={tab?.name || `Tab ${tabIndex + 1}`}
              {...a11yProps(tabIndex)}
            />
          )
        })
      }
    </Tabs>
  )
};

export const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <Box
      role='tabpanel'
      hidden={value !== index}
      id={`change-request-tabpanel-${index}`}
      aria-labelledby={`change-request-tab-${index}`}
      {...other}
    >
      {value === index ?
        <Box sx={{ width: '100%', position: 'relative' }}>
          {children}
        </Box>
        :
        null
      }
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `change-request-tab-${index}`,
    "aria-controls": `change-request-tabpanel-${index}`,
  };
}

export default ReportScreen;