import { Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import DividerComponent from '../../../../../components/Divider/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Cancel } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { Fragment, useEffect, useState } from 'react';
import LocalSearchableDropdown from '../../../../../components/LocalSearchableDropdown';
import { convertSearchedListToOptions, extractValuableOptions, getOptionFromList } from '../../../../../utils/consultantConstants';
import api from '../../../../../config/https';
import { toast } from 'react-toastify';

const InputTypeDropdown = ({ activeSelection = null, requestVariable = null, setRequestVariable = () => { }, handleChange = () => { }, data = null, handleRemoveTooltip = () => { }, handleRemoveRequestVariables = () => { }, handleAddRequestVariable = () => { }, }) => {
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [values, setValues] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionValue, setOptionValue] = useState(null);

  useEffect(() => {
    if (activeSelection?.variant === 'searchable') {
      setTimeout(() => {
        setShowDropdown(true);
      }, 100);
    }
  }, [activeSelection]);

  useEffect(() => {
    setValues(null);
    setSelectedOptions([]);
    setLoading(false);
  }, [data]);

  const handleSearch = async (value = "") => {
    setLoading(true);
    let url = `/masterAdmin/constant?fieldType=${data.searchVariable}&search=${value}`;
    if (data?.searchVariable === 'vendor') {
      url = `/masterAdmin/vendor?vendorName=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'vendor_name', 'id');
        setOptions(d?.options || []);
      }
      setLoading(false);
    } else if (data?.searchVariable === 'businessUnit' || data?.searchVariable === 'clients' || data?.searchVariable === 'endclients' || data?.searchVariable === 'domains' || data?.searchVariable === 'hr') {
      url = `/masterAdmin/${data.searchVariable}?search=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'name', 'id');
        setOptions(d?.options || []);
      }
      setLoading(false);
    } else if (data?.searchVariable === 'sdf' || data?.searchVariable === 'insurance') {
      toast.error('Search functionality not avaialble!');
      setLoading(false);
    } else {
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
        setOptions(d?.options || []);
      }
      setLoading(false);
    }
  }

  const handleChangeValue = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }));
  }

  const handleAddOption = () => {
    if (optionValue?.label && optionValue?.value) {
      let options = data?.options || [];
      options.push(optionValue);
      handleChange('options', options);
      setOptionValue(null);
    } else {
      if (!optionValue?.label) {
        toast.error('Please add a label!');
      } else {
        toast.error('Please enter a value!');
      }
    }
  }

  const handleRemoveOption = (value = "") => {
    let options = data?.options || [];
    options = options.filter(item => item?.value !== value);
    handleChange('options', options);
  }

  const handleAddTooltip = () => {
    if (optionValue?.label && optionValue?.key) {
      let options = data?.tooltips || [];
      options.push({ ...optionValue, type: optionValue?.type || 'text' });
      handleChange('tooltips', options);
      setOptionValue(null);
    } else {
      if (!optionValue?.label) {
        toast.error('Please add a label!');
      } else {
        toast.error('Please enter a value!');
      }
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Configuration</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Label <span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            value={data?.label || ""}
            onChange={(e) => handleChange('label', e.target.value)}
            placeholder={"Please enter a label!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'input-label'}
            name={'input-label'}
          />
          <FormControlLabel control={<Checkbox id={'input-checkbox'} name={'input-checkbox'} checked={data?.confidential || false} onChange={() => handleChange('confidential', !data?.confidential)} />} label='Add checkbox to this label' />
        </Grid>
        {
          data?.confidential
            ?
            <Fragment>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Checkbox Variable <span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                  value={data?.confidentialKey || ""}
                  onChange={(e) => handleChange('confidentialKey', e.target.value)}
                  placeholder={"Please enter a confidential variable!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  type={'text'}
                  id={'input-checkbox-variable'}
                  name={'input-checkbox-variable'}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Checkbox Label <span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                  value={data?.confidentialLabel || ""}
                  onChange={(e) => handleChange('confidentialLabel', e.target.value)}
                  placeholder={"Please enter a confidential label!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  type={'text'}
                  id={'input-checkbox-label'}
                  name={'input-checkbox-label'}
                />
              </Grid>
            </Fragment>
            :
            null
        }
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Variable Name <span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            value={data?.variable || ""}
            onChange={(e) => handleChange('variable', e.target.value)}
            placeholder={"Please enter a variable name!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'input-variable'}
            name={'input-variable'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>System required field</Typography>
          <ButtonGroup sx={{ mt: 1 }}>
            <Button id={'required-btn'} name={'required-btn'} onClick={() => handleChange('systemRequired', true)} variant={data?.systemRequired ? 'contained' : 'outlined'}>Yes</Button>
            <Button id={'not-required-btn'} name={'not-required-btn'} onClick={() => handleChange('systemRequired', false)} variant={!data?.systemRequired ? 'contained' : 'outlined'}>No</Button>
          </ButtonGroup>
        </Grid>
        {
          activeSelection?.variant === 'static'
            ?
            <Grid item xs={12}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>Options <span style={{ color: 'red' }}>*</span></Typography>
              <Box sx={{ my: 1 }}>
                {data?.options?.map((option, optionIndex) => {
                  return (
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, bgcolor: grey[50], p: 1, borderRadius: 2, my: 1, color: '#1976D2' }}>
                      <IconButton id={'remove-option-btn-' + (optionIndex + 1)} name={'remove-option-btn' + (optionIndex + 1)} color="error" onClick={() => handleRemoveOption(option?.value)}>
                        <Cancel />
                      </IconButton>
                      <Typography variant='subtitle2' sx={{ fontSize: 16, color: '#000000DE' }} noWrap>{option?.label} :</Typography>
                      <Typography variant='subtitle2' sx={{ fontSize: 16 }} noWrap>{option?.value}</Typography>
                    </Box>
                  )
                })}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <TextField id={'add-option-label'} name={'add-option-label'} label='Label' sx={{ background: "#FFFFFF" }} value={optionValue?.label || ""} onChange={e => setOptionValue(prev => ({ ...prev, label: e.target.value }))} />
                <TextField id={'add-option-value'} name={'add-option-value'} label='Value' sx={{ background: "#FFFFFF" }} value={optionValue?.value || ""} onChange={e => setOptionValue(prev => ({ ...prev, value: e.target.value }))} />
                <Button id={'add-option-btn'} name={'add-option-btn'} onClick={handleAddOption} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                  <AddCircleIcon />
                  <Typography variant='subtitle2'>Add</Typography>
                </Button>
              </Box>
            </Grid>
            :
            null
        }
        {
          activeSelection?.variant === 'searchable'
            ?
            <Fragment>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Search Variable<span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                  value={data?.searchVariable || ""}
                  onChange={(e) => handleChange('searchVariable', e.target.value)}
                  placeholder={"Please enter a search variable!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  type={'text'}
                  id={'input-search-variable'}
                  name={'input-search-variable'}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Allow multiple selection</Typography>
                <ButtonGroup sx={{ mt: 1 }}>
                  <Button
                    id={'input-multiple-btn'}
                    name={'input-multiple-btn'}
                    onClick={() => {
                      setShowDropdown(false);
                      handleChange('multiple', true);
                      setTimeout(() => {
                        setShowDropdown(true);
                      }, 100);
                    }}
                    variant={data?.multiple ? 'contained' : 'outlined'}>Yes</Button>
                  <Button
                    id={'input-single-btn'}
                    name={'input-single-btn'}
                    onClick={() => {
                      setShowDropdown(false);
                      handleChange('multiple', false);
                      setTimeout(() => {
                        setShowDropdown(true);
                      }, 100);
                    }}
                    variant={!data?.multiple ? 'contained' : 'outlined'}>No</Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Allow request new options</Typography>
                <ButtonGroup sx={{ mt: 1 }}>
                  <Button id={'input-request-btn'} name={'input-request-btn'} onClick={() => handleChange('request', true)} variant={data?.request ? 'contained' : 'outlined'}>Yes</Button>
                  <Button id={'no-input-request-btn'} name={'no-input-request-btn'} onClick={() => handleChange('request', false)} variant={!data?.request ? 'contained' : 'outlined'}>No</Button>
                </ButtonGroup>
              </Grid>
              {data?.request
                ?
                <Grid item xs={12}>
                  <Typography variant='subtitle2' sx={{ mb: 1 }}>Request Variables</Typography>
                  <Box sx={{ my: 1 }}>
                    {data?.requestVariables?.map((option, optionIndex) => {
                      return (
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, bgcolor: grey[50], p: 1, borderRadius: 2, my: 1, color: '#1976D2' }}>
                          <IconButton id={'remove-request-option-btn' + (optionIndex + 1)} name={'remove-request-option-btn' + (optionIndex + 1)} color="error" onClick={() => handleRemoveRequestVariables(option?.key)}>
                            <Cancel />
                          </IconButton>
                          <Typography variant='subtitle2' sx={{ fontSize: 16, color: '#000000DE' }} noWrap>{option?.label} :</Typography>
                          <Typography variant='subtitle2' sx={{ fontSize: 16 }} noWrap>{option?.key}</Typography>
                        </Box>
                      )
                    })}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <TextField id={'add-request-label'} name={'add-request-label'} label='Label' sx={{ background: "#FFFFFF" }} value={requestVariable?.label || ""} onChange={e => setRequestVariable(prev => ({ ...prev, label: e.target.value }))} />
                    <TextField id={'add-request-key'} name={'add-request-key'} label='Key' sx={{ background: "#FFFFFF" }} value={requestVariable?.key || ""} onChange={e => setRequestVariable(prev => ({ ...prev, key: e.target.value }))} />
                    <Button id={'add-request-option-btn'} name={'add-request-option-btn'} onClick={handleAddRequestVariable} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                      <AddCircleIcon />
                      <Typography variant='subtitle2'>Add</Typography>
                    </Button>
                  </Box>
                </Grid>
                :
                null
              }
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Show tooltip</Typography>
                <ButtonGroup sx={{ mt: 1 }}>
                  <Button id={'show-tooltip-btn'} name={'show-tooltip-btn'} onClick={() => handleChange('showTooltip', true)} variant={data?.showTooltip ? 'contained' : 'outlined'}>Yes</Button>
                  <Button id={'show-no-tooltip-btn'} name={'show-no-tooltip-btn'} onClick={() => handleChange('showTooltip', false)} variant={!data?.showTooltip ? 'contained' : 'outlined'}>No</Button>
                </ButtonGroup>
              </Grid>
              {data?.showTooltip
                ?
                <Grid item xs={12}>
                  <Typography variant='subtitle2' sx={{ mb: 1 }}>Tooltips</Typography>
                  <Box sx={{ my: 1 }}>
                    {data?.tooltips?.map((option, optionIndex) => {
                      return (
                        <Box key={optionIndex} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, bgcolor: grey[50], p: 1, borderRadius: 2, my: 1, color: '#1976D2' }}>
                          <IconButton id={'remove-tooltip-btn' + (optionIndex + 1)} name={'remove-tooltip-btn' + (optionIndex + 1)} color="error" onClick={() => handleRemoveTooltip(option?.key)}>
                            <Cancel />
                          </IconButton>
                          <Typography variant='subtitle2' sx={{ fontSize: 16, color: '#000000DE' }} noWrap>{option?.label} :</Typography>
                          <Typography variant='subtitle2' sx={{ fontSize: 16 }} noWrap>{option?.key}</Typography>
                          <Typography variant='subtitle2' sx={{ fontSize: 16, color: 'red' }} noWrap>({option?.type})</Typography>
                        </Box>
                      )
                    })}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <TextField id={'add-tooltip-label'} name={'add-tooltip-label'} label='Label' sx={{ background: "#FFFFFF" }} value={optionValue?.label || ""} onChange={e => setOptionValue(prev => ({ ...prev, label: e.target.value }))} />
                    <TextField id={'add-tooltip-value'} name={'add-tooltip-value'} label='Value' sx={{ background: "#FFFFFF" }} value={optionValue?.key || ""} onChange={e => setOptionValue(prev => ({ ...prev, key: e.target.value }))} />
                    <FormControl>
                      <Select
                        value={optionValue?.type || "text"}
                        sx={{ width: 100, background: "#FFFFFF" }}
                        onChange={(e) => setOptionValue(prev => ({ ...prev, type: e.target.value }))}
                        id={'add-tooltip-type'}
                        name={'add-tooltip-type'}
                      >
                        <MenuItem value={'text'}>Text</MenuItem>
                        <MenuItem value={'date'}>Date</MenuItem>
                      </Select>
                    </FormControl>
                    <Button id={'add-tooltip-btn'} name={'add-tooltip-btn'} onClick={handleAddTooltip} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                      <AddCircleIcon />
                      <Typography variant='subtitle2'>Add</Typography>
                    </Button>
                  </Box>
                </Grid>
                :
                null
              }
            </Fragment>
            :
            null
        }
      </Grid>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2'>Preview</Typography>
      <Grid container spacing={2}>
        {data?.label
          ?
          <Grid item xs={4}>
            <Typography variant='subtitle2' sx={{ my: 1 }}>{data?.label || ""} {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
            {
              activeSelection?.variant === 'static'
                ?
                <FormControl fullWidth>
                  <Select
                    value={values?.[data.variable] || ""}
                    sx={{ width: "100%", background: "#FFFFFF" }}
                    displayEmpty
                    onChange={(e) => handleChangeValue(data?.variable, e?.target?.value)}
                    id={data.variable}
                    name={data.variable}
                  >
                    <MenuItem disabled value="">{"Please select a " + (data?.label || "").toLowerCase()}</MenuItem>
                    {(extractValuableOptions(data?.options || [])).map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                :
                showDropdown
                  ?
                  <LocalSearchableDropdown
                    id={data.variable}
                    multiple={data?.multiple || false}
                    value={data?.multiple ? values?.[data.variable] || [] : values?.[data.variable] || null}
                    label={data?.label || ""}
                    addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
                    options={options}
                    handleChange={(v) => {
                      if (data?.multiple) {
                        let oldValues = values?.[data.variable] || [];
                        let intersection = [];
                        if (v.length > oldValues.length) {
                          intersection = v.filter(x => !oldValues.includes(x));
                          if (intersection.length) {
                            const option = getOptionFromList(options, intersection[0]);
                            if (option?.value) {
                              setSelectedOptions(prev => {
                                let op = [...prev, option];
                                let list = [...new Map(op.map(item => [item.value, item])).values()]
                                return list;
                              });
                            }
                          }
                        } else {
                          intersection = oldValues.filter(x => !v.includes(x));
                          if (intersection.length) {
                            setSelectedOptions(prev => prev.filter(item => item.value !== intersection[0]));
                          }
                        }
                        handleChangeValue(data.variable, v);
                      } else {
                        handleChangeValue(data.variable, v?.value);
                        if (v?.value) {
                          setSelectedOptions(prev => {
                            let op = [...prev, v];
                            let list = [...new Map(op.map(item => [item.value, item])).values()];
                            return list;
                          });
                        } else {
                          setSelectedOptions([]);
                        }
                      }
                    }}
                    add={data?.request || false}
                    loading={loading}
                    handleInputChange={e => handleSearch(e)}
                    matchingOptions={selectedOptions}
                  />
                  :
                  <TextField placeholder={`Search ${data?.label || ""}...`} disabled style={{ width: '100%', background: '#fff' }} />
            }
            {data?.confidential
              ?
              <FormControlLabel control={<Checkbox id={data.confidentialKey} name={data.confidentialKey} checked={data?.[data.confidentialKey]} onChange={() => handleChange(data.confidentialKey, !data?.[data.confidentialKey])} />} label={"Please check if " + data?.confidentialLabel || ""} />
              :
              null
            }
          </Grid>
          :
          null
        }
      </Grid>
    </Box>
  )
}

export default InputTypeDropdown;