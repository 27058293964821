import { Box, Typography } from '@mui/material';
import { COLORS } from '../../utils/chartHelper';

const CustomTooltip = (props) => {
  const { active, payload, label, hiddenList = [], stackType = "" } = props;
  if (active && payload && payload.length) {
    let total = 0;
    if (stackType === 'normalized') {
      total = payload?.length ? payload[0]?.payload?.grandTotal || 0 : 0;
    } else {
      total = payload.reduce((acc, next) => acc + parseInt((isNaN(next?.value) ? 0 : next.value)), 0);
    }
    let totalLabel = "";
    return (
      <Box sx={{ backgroundColor: 'rgba(59, 72, 80, 0.9)', display: 'flex', flexDirection: 'column', padding: 2, borderRadius: 2 }}>
        {label ? <Typography variant='subtitle2' noWrap sx={{ color: '#fff', py: 0.5, px: 1, fontSize: 16 }}>{label}</Typography> : null}
        {payload.map((p, pIndex) => {
          let { unit = "", name, value } = p;
          let key = stackType === 'normalized' ? (p?.dataKey || "").replace("normalized-", '') : p?.dataKey;
          if (stackType === 'normalized' && p?.payload) {
            Object.keys(p?.payload)?.forEach(k => {
              if (k === key) {
                value = p?.payload?.[k] || 0;
              }
            });
          }
          if (value && !hiddenList.includes(name)) {
            let finalValue = value || 0;
            if (unit === '$') {
              finalValue = unit + "" + value;
              totalLabel = unit + "" + total;
            } else if (unit) {
              finalValue = value + "" + unit;
              totalLabel = total + "" + unit;
            }
            if (isNaN(value)) {
              value = 0;
            }
            let percentage = (value / total * 100).toFixed(2) + '%';
            const color = p?.payload?.fill || p?.stroke || p?.fill || null;
            return (
              <Box key={pIndex} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5, py: 0.5, px: 1, }}>
                <Box sx={{ width: 10, height: 10, borderRadius: 4, bgcolor: color || COLORS[pIndex + 1] }} />
                <Typography noWrap variant='subtitle2' sx={{ color: '#fff', flex: 1 }}>{name}</Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff', width: 40, textAlign: 'right' }}>:</Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff', whiteSpace: 'nowrap', width: 100, textAlign: 'right' }}>{totalLabel}</Typography>
                <Typography variant='subtitle2' sx={{ color: '#fff', whiteSpace: 'nowrap', width: 100, textAlign: 'right' }}>{finalValue}</Typography>
                {percentage ? <Typography variant='subtitle2' sx={{ color: '#fff', whiteSpace: 'nowrap', display: payload.length > 1 ? 'block' : 'none', width: 100, textAlign: 'right' }}>{percentage}</Typography> : null}
              </Box>
            )
          }
          return null;
        })}
        {payload.length > 1
          ?
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5, background: 'rgba(59, 72, 80, 1)', py: 0.5, px: 1, borderRadius: 1, mt: 1 }}>
            <Typography noWrap variant='subtitle2' sx={{ color: '#fff', flex: 1 }}>Total</Typography>
            <Typography variant='subtitle2' sx={{ color: '#fff', width: 40, textAlign: 'right' }}>=</Typography>
            <Typography variant='subtitle2' sx={{ color: '#fff', width: 40, textAlign: 'right' }}>{totalLabel}</Typography>
            <Typography variant='subtitle2' sx={{ color: '#fff', whiteSpace: 'nowrap', width: 100, textAlign: 'right' }}>{totalLabel || total}</Typography>
            <Typography variant='subtitle2' sx={{ color: '#fff', whiteSpace: 'nowrap', display: payload.length > 1 ? 'block' : 'none', width: 100, textAlign: 'right' }}>100%</Typography>
          </Box>
          :
          null
        }
      </Box>
    );
  }
  return null;
};

export default CustomTooltip;