import { Box, Typography } from '@mui/material';
import { extractBarDataPoints } from '../../utils/chartHelper';
import { useEffect, useState } from 'react';
import { green, grey, red } from '@mui/material/colors';
import NoDataCard from './noDataCard';

const SmartScalarCard = ({ data = [], visualizationData = null, title }) => {

  const [info, setInfo] = useState(null);
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    const dataPoints = extractBarDataPoints(data, visualizationData, title);
    let list = dataPoints?.list || [];
    const newDataPoints = list.slice(list.length - 2, list.length);
    let axes = dataPoints?.axes || [];
    const name = axes[0]?.dataKey || "";
    let dataKey = axes[axes.length - 1]?.dataKey || "";
    const newInfo = {
      name: newDataPoints[newDataPoints.length - 1]?.[name] || "",
      dataKey: newDataPoints?.[newDataPoints.length - 1]?.[dataKey] || "",
      previousName: newDataPoints[0]?.[name] || "",
      previousDatakey: newDataPoints[0]?.[dataKey] || "",
      unit: axes[0]?.unit || "",
    }
    setInfo(newInfo);
    setTitle(dataPoints?.title || "");
  }, [data, visualizationData, title]);

  if (!info?.dataKey) {
    return <NoDataCard />
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ fontSize: '40px', fontWeight: 'bold', color: '#475569', textAlign: 'center' }}>{info?.dataKey || ""}</Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569', textAlign: 'center' }}>{info?.name || ""}</Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {
            info?.dataKey > info?.previousDatakey
              ?
              <Box sx={{ width: 14, height: 14, color: green[400], mb: 0.5 }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-arrow-up"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 20V4m0 0l6 6m-6-6l-6 6" /></svg>
              </Box>
              :
              <Box sx={{ width: 14, height: 14, color: red[400], mb: 0.5 }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-arrow-down"><g xmlns="http://www.w3.org/2000/svg" strokeLinecap="round" strokeWidth="2"><path d="M12 4v16M6 14l6 6 6-6" /></g></svg>
              </Box>
          }
          <Typography sx={{ fontSize: '14px', fontWeight: 'medium', alignItems: 'center', color: info?.dataKey > info?.previousDatakey ? green[400] : red[400] }}>{((((info?.previousDatakey || "") - (info?.dataKey || "")) / (info?.previousDatakey || "")) * 100).toFixed(2)}%</Typography>
          <Box sx={{ width: 4, height: 4, borderRadius: 2, bgcolor: grey[400], mx: 0.5 }} />
          <Typography sx={{ fontSize: '14px', fontWeight: 'medium', alignItems: 'center', color: '#475569' }}>previous</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 'medium', alignItems: 'center', mx: 0.5, color: '#475569' }}>{info?.unit || ""}:</Typography>
          <Typography sx={{ fontSize: '14px', fontWeight: 'medium', alignItems: 'center', color: grey[600] }} className='text-xs font-medium text-gray-400 text-center'>{info?.previousDatakey || ""}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default SmartScalarCard;