import api from "../../config/https";
import { GET_PERMISSION_LIST_FAILED, GET_PERMISSION_LIST_IN_PROGRESS, GET_PERMISSION_LIST_SUCCESS, GET_PERMISSION_USER_LIST_FAILED, GET_PERMISSION_USER_LIST_IN_PROGRESS, GET_PERMISSION_USER_LIST_SUCCESS, PERMISSION_OPERATION_FAILED, PERMISSION_OPERATION_IN_PROGRESS, PERMISSION_OPERATION_SUCCESS, USER_PERMISSION_OPERATION_FAILED, USER_PERMISSION_OPERATION_IN_PROGRESS, USER_PERMISSION_OPERATION_SUCCESS } from "../../constants/store";
import { formatSectionData } from "../../utils/userManagementHelper";

export const getPermissionList = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PERMISSION_LIST_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/user/getAllSections`);
    if (res?.data?.status) {
      dispatch({
        type: GET_PERMISSION_LIST_SUCCESS,
        payload: formatSectionData(res?.data?.data?.sections),
      });
    } else {
      dispatch({
        type: GET_PERMISSION_LIST_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_PERMISSION_LIST_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const addNewPermission = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: PERMISSION_OPERATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/user/addPageSection`, payload);
    if (res?.data?.status) {
      dispatch({
        type: PERMISSION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: PERMISSION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: PERMISSION_OPERATION_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const editPermission = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: PERMISSION_OPERATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/user/editPageSection`, payload);
    if (res?.data?.status) {
      dispatch({
        type: PERMISSION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: PERMISSION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: PERMISSION_OPERATION_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const deletePermission = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: PERMISSION_OPERATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/user/deletePageSection/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: PERMISSION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: PERMISSION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: PERMISSION_OPERATION_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const getPermissionUserList = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PERMISSION_USER_LIST_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/user/?search=${payload?.search || ''}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_PERMISSION_USER_LIST_SUCCESS,
        payload: res?.data?.data?.data || [],
      });
    } else {
      dispatch({
        type: GET_PERMISSION_USER_LIST_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_PERMISSION_USER_LIST_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const addPermissionUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PERMISSION_OPERATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/user`, payload);
    if (res?.data?.status) {
      dispatch({
        type: USER_PERMISSION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: USER_PERMISSION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: USER_PERMISSION_OPERATION_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const updateUserPermission = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PERMISSION_OPERATION_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/user/updateRoleAndPermission/${payload.id}`, payload.data);
    if (res?.data?.status) {
      dispatch({
        type: USER_PERMISSION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: USER_PERMISSION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: USER_PERMISSION_OPERATION_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const toggleUserStatusPermission = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: USER_PERMISSION_OPERATION_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/user/${payload.id}`, payload.data);
    if (res?.data?.status) {
      dispatch({
        type: USER_PERMISSION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: USER_PERMISSION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: USER_PERMISSION_OPERATION_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};