// PERSONAL INFO REDUCER CONSTANTS
export const PRESERVE_CONTRACT_DETAIL_IN_PROGRESS =
  "PRESERVE_CONTRACT_DETAIL_IN_PROGRESS";
export const PRESERVE_CONTRACT_DETAIL_SUCCESS =
  "PRESERVE_CONTRACT_DETAIL_SUCCESS";
export const PRESERVE_CONTRACT_DETAIL_FAILED =
  "PRESERVE_CONTRACT_DETAIL_FAILED";

// GET PERSONAL INFO REDUCER CONSTANTS
export const GET_PRESERVE_CONTRACT_DETAIL_IN_PROGRESS =
  "GET_PRESERVE_CONTRACT_DETAIL_IN_PROGRESS";
export const GET_PRESERVE_CONTRACT_DETAIL_SUCCESS =
  "GET_PRESERVE_CONTRACT_DETAIL_SUCCESS";
export const GET_PRESERVE_CONTRACT_DETAIL_FAILED =
  "GET_PRESERVE_CONTRACT_DETAIL_FAILED";

// PERSONAL INFO REDUCER CONSTANTS
export const INFO_CREATE_IN_PROGRESS = "INFO_CREATE_IN_PROGRESS";
export const INFO_CREATE_SUCCESS = "INFO_CREATE_SUCCESS";
export const INFO_CREATE_FAILED = "INFO_CREATE_FAILED";

// CREATE CLIENT CONSTANTS
export const CREATE_CLIENT_REQUESTED = "CREATE_CLIENT_REQUESTED";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILED = "CREATE_CLIENT_FAILED";

// GET CLIENT CONSTANTS
export const GET_CLIENT_REQUESTED = "GET_CLIENT_REQUESTED";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILED = "GET_CLIENT_FAILED";

// GET END CLIENT CONSTANTS
export const GET_END_CLIENT_REQUESTED = "GET_END_CLIENT_REQUESTED";
export const GET_END_CLIENT_SUCCESS = "GET_END_CLIENT_SUCCESS";
export const GET_END_CLIENT_FAILED = "GET_END_CLIENT_FAILED";

// GET VENDOR LIST
export const GET_VENDOR_LIST = "GET_VENDOR_LIST";
export const GET_VENDOR_LIST_SUCCESS = "GET_VENDOR_LIST_SUCCESS";
export const GET_VENDOR_LIST_FAILED = "GET_VENDOR_LIST_FAILED";

// GET VENDOR SDF & INSURANCE
export const GET_VENDOR_SDF_AND_INSURANCE_LIST = "GET_VENDOR_SDF_AND_INSURANCE_LIST";
export const GET_VENDOR_SDF_AND_INSURANCE_LIST_SUCCESS = "GET_VENDOR_SDF_AND_INSURANCE_LIST_SUCCESS";
export const GET_VENDOR_SDF_AND_INSURANCE_LIST_FAILED = "GET_VENDOR_SDF_AND_INSURANCE_LIST_FAILED";

// CREATE DOMAIN CONSTANTS
export const CREATE_DOMAIN_REQUESTED = "CREATE_DOMAIN_REQUESTED";
export const CREATE_DOMAIN_SUCCESS = "CREATE_DOMAIN_SUCCESS";
export const CREATE_DOMAIN_FAILED = "CREATE_DOMAIN_FAILED";

// GET DOMAIN CONSTANTS
export const GET_DOMAIN_REQUESTED = "GET_DOMAIN_REQUESTED";
export const GET_DOMAIN_SUCCESS = "GET_DOMAIN_SUCCESS";
export const GET_DOMAIN_FAILED = "GET_DOMAIN_FAILED";

// CREATE HR CONSTANTS
export const CREATE_HR_REQUESTED = "CREATE_HR_REQUESTED";
export const CREATE_HR_SUCCESS = "CREATE_HR_SUCCESS";
export const CREATE_HR_FAILED = "CREATE_HR_FAILED";

// GET HR CONSTANTS
export const GET_HR_REQUESTED = "GET_HR_REQUESTED";
export const GET_HR_SUCCESS = "GET_HR_SUCCESS";
export const GET_HR_FAILED = "GET_HR_FAILED";

// CREATE HR CONSTANTS
export const CREATE_BUSINESS_UNIT_REQUESTED = "CREATE_BUSINESS_UNIT_REQUESTED";
export const CREATE_BUSINESS_UNIT_SUCCESS = "CREATE_BUSINESS_UNIT_SUCCESS";
export const CREATE_BUSINESS_UNIT_FAILED = "CREATE_BUSINESS_UNIT_FAILED";

// CREATE REFERRAL
export const CREATE_REFERRAL_REQUESTED = "CREATE_REFERRAL_REQUESTED";
export const CREATE_REFERRAL_SUCCESS = "CREATE_REFERRAL_SUCCESS"
export const CREATE_REFERRAL_FAILED = "CREATE_REFERRAL_FAILED"

// GET HR CONSTANTS
export const GET_BUSINESS_UNIT_REQUESTED = "GET_BUSINESS_UNIT_REQUESTED";
export const GET_BUSINESS_UNIT_SUCCESS = "GET_BUSINESS_UNIT_SUCCESS";
export const GET_BUSINESS_UNIT_FAILED = "GET_BUSINESS_UNIT_FAILED";

// Get Referral 
export const GET_REFERRAL_REQUESTED = "GET_REFERRAL_REQUESTED"
export const GET_REFERRAL_SUCCESS = "GET_REFERRAL_SUCCESS"
export const GET_REFERRAL_FAILED = "GET_REFERRAL_FAILED"



// CREATE CANDIDATE CONSTANTS
export const CREATE_CANDIDATE_REQUESTED = "CREATE_CANDIDATE_REQUESTED";
export const CREATE_CANDIDATE_SUCCESS = "CREATE_CANDIDATE_SUCCESS";
export const CREATE_CANDIDATE_FAILED = "CREATE_CANDIDATE_FAILED";

// GET CANDIDATE CONSTANTS
export const GET_CANDIDATE_REQUESTED = "GET_CANDIDATE_REQUESTED";
export const GET_CANDIDATE_SUCCESS = "GET_CANDIDATE_SUCCESS";
export const GET_CANDIDATE_FAILED = "GET_CANDIDATE_FAILED";

// LIST CANDIDATE CONSTANTS
export const CANDIDATE_LIST_IN_PROGRESS = "CANDIDATE_LIST_IN_PROGRESS";
export const CANDIDATE_LIST_SUCCESS = "CANDIDATE_LIST_SUCCESS";
export const CANDIDATE_LIST_FAILED = "CANDIDATE_LIST_FAILED";

// GET CONSULTANT CONSTANTS
export const GET_CONSULTANT_INFORMATION_REQUESTED =
  "GET_CONSULTANT_INFORMATION_REQUESTED";
export const GET_CONSULTANT_INFORMATION_SUCCESS =
  "GET_CONSULTANT_INFORMATION_SUCCESS";
export const GET_CONSULTANT_INFORMATION_FAILED =
  "GET_CONSULTANT_INFORMATION_FAILED";

// LIST CONSULTANT CONSTANTS
export const SAVE_CONSULTANT_INFORMATION_IN_PROGRESS =
  "SAVE_CONSULTANT_INFORMATION_IN_PROGRESS";
export const SAVE_CONSULTANT_INFORMATION_SUCCESS =
  "SAVE_CONSULTANT_INFORMATION_SUCCESS";
export const SAVE_CONSULTANT_INFORMATION_FAILED =
  "SAVE_CONSULTANT_INFORMATION_FAILED";

// GET CONTRACT CONSTANTS
export const GET_CONTRACT_REQUESTED = "GET_CONTRACT_REQUESTED";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
export const GET_CONTRACT_FAILED = "GET_CONTRACT_FAILED";

// LIST CONTRACT CONSTANTS
export const SAVE_CONTRACT_IN_PROGRESS = "SAVE_CONTRACT_IN_PROGRESS";
export const SAVE_CONTRACT_SUCCESS = "SAVE_CONTRACT_SUCCESS";
export const SAVE_CONTRACT_FAILED = "SAVE_CONTRACT_FAILED";

// GET PAYMENTS CONSTANTS
export const GET_PAYMENTS_REQUESTED = "GET_PAYMENTS_REQUESTED";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_FAILED = "GET_PAYMENTS_FAILED";

// LIST PAYMENTS CONSTANTS
export const SAVE_PAYMENTS_IN_PROGRESS = "SAVE_PAYMENTS_IN_PROGRESS";
export const SAVE_PAYMENTS_SUCCESS = "SAVE_PAYMENTS_SUCCESS";
export const SAVE_PAYMENTS_FAILED = "SAVE_PAYMENTS_FAILED";

// GET HUMAN_RESOURCE CONSTANTS
export const GET_HUMAN_RESOURCE_REQUESTED = "GET_HUMAN_RESOURCE_REQUESTED";
export const GET_HUMAN_RESOURCE_SUCCESS = "GET_HUMAN_RESOURCE_SUCCESS";
export const GET_HUMAN_RESOURCE_FAILED = "GET_HUMAN_RESOURCE_FAILED";

// LIST HUMAN_RESOURCE CONSTANTS
export const SAVE_HUMAN_RESOURCE_IN_PROGRESS =
  "SAVE_HUMAN_RESOURCE_IN_PROGRESS";
export const SAVE_HUMAN_RESOURCE_SUCCESS = "SAVE_HUMAN_RESOURCE_SUCCESS";
export const SAVE_HUMAN_RESOURCE_FAILED = "SAVE_HUMAN_RESOURCE_FAILED";
// miscellaneous
// GET MISCELLANEOUS CONSTANTS
export const GET_MISCELLANEOUS_REQUESTED = "GET_MISCELLANEOUS_REQUESTED";
export const GET_MISCELLANEOUS_SUCCESS = "GET_MISCELLANEOUS_SUCCESS";
export const GET_MISCELLANEOUS_FAILED = "GET_MISCELLANEOUS_FAILED";

// LIST MISCELLANEOUS CONSTANTS
export const FETCH_CONTRACTS_IN_PROGRESS = "FETCH_CONTRACTS_IN_PROGRESS";
export const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";
export const FETCH_CONTRACTS_FAILED = "FETCH_CONTRACTS_FAILED";

// GET _CANDIDAT CONSTANTS
export const FETCH_CANDIDATE_IN_PROGRESS = "FETCH_CANDIDATE_IN_PROGRESS";
export const FETCH_CANDIDATE_SUCCESS = "FETCH_CANDIDATE_SUCCESS";
export const FETCH_CANDIDATE_FAILED = "FETCH_CANDIDATE_FAILED";

// UPDATE _CANDIDATE CONSTANTS
export const UPDATE_CANDIDATE_IN_PROGRESS = "UPDATE_CANDIDATE_IN_PROGRESS";
export const UPDATE_CANDIDATE_SUCCESS = "UPDATE_CANDIDATE_SUCCESS";
export const UPDATE_CANDIDATE_FAILED = "UPDATE_CANDIDATE_FAILED";

// GET CONTRACT CONSTANTS
export const FETCH_CONTRACT_IN_PROGRESS = "FETCH_CONTRACT_IN_PROGRESS";
export const FETCH_CONTRACT_SUCCESS = "FETCH_CONTRACT_SUCCESS";
export const FETCH_CONTRACT_FAILED = "FETCH_CONTRACT_FAILED";

// ADD CONTRACT CONSTANTS
export const ADD_CONTRACT_IN_PROGRESS = "ADD_CONTRACT_IN_PROGRESS";
export const ADD_CONTRACT_SUCCESS = "ADD_CONTRACT_SUCCESS";
export const ADD_CONTRACT_FAILED = "ADD_CONTRACT_FAILED";

// UPDATE CONTRACT CONSTANTS
export const UPDATE_CONTRACT_IN_PROGRESS = "UPDATE_CONTRACT_IN_PROGRESS";
export const UPDATE_CONTRACT_SUCCESS = "UPDATE_CONTRACT_SUCCESS";
export const UPDATE_CONTRACT_FAILED = "UPDATE_CONTRACT_FAILED";

// UPDATE CONTRACT STATUS CONSTANTS
export const UPDATE_CONTRACT_STATUS_IN_PROGRESS = "UPDATE_CONTRACT_STATUS_IN_PROGRESS";
export const UPDATE_CONTRACT_STATUS_SUCCESS = "UPDATE_CONTRACT_STATUS_SUCCESS";
export const UPDATE_CONTRACT_STATUS_FAILED = "UPDATE_CONTRACT_STATUS_FAILED";

// UPDATE REFERRAL CONSTANTS
export const UPDATE_REFERRAL_REQUESTED = "UPDATE_REFERRAL_REQUESTED";
export const UPDATE_REFERRAL_SUCCESS = "UPDATE_REFERRAL_SUCCESS";
export const UPDATE_REFERRAL_FAILED = "UPDATE_REFERRAL_FAILED";

// SEARCH CANDIDATE CONSTANTS
export const CANDIDATE_SEARCH_IN_PROGRESS = "CANDIDATE_SEARCH_IN_PROGRESS";
export const CANDIDATE_SEARCH_SUCCESS = "CANDIDATE_SEARCH_SUCCESS";
export const CANDIDATE_SEARCH_FAILED = "CANDIDATE_SEARCH_FAILED";

// CR OPTIONS
export const GET_CR_TAB_OPTIONS_IN_PROGRESS = "GET_CR_TAB_OPTIONS_IN_PROGRESS";
export const GET_CR_TAB_OPTIONS_SUCCESS = "GET_CR_TAB_OPTIONS_SUCCESS";
export const GET_CR_TAB_OPTIONS_FAILED = "GET_CR_TAB_OPTIONS_FAILED";
export const GET_CR_SPECIAL_OPTIONS_IN_PROGRESS = "GET_CR_SPECIAL_OPTIONS_IN_PROGRESS";
export const GET_CR_SPECIAL_OPTIONS_SUCCESS = "GET_CR_SPECIAL_OPTIONS_SUCCESS";
export const GET_CR_SPECIAL_OPTIONS_FAILED = "GET_CR_SPECIAL_OPTIONS_FAILED";

// CHANGE REQUEST LIST
export const CHANGE_REQUEST_LIST_IN_PROGRESS = "CHANGE_REQUEST_LIST_IN_PROGRESS";
export const CHANGE_REQUEST_LIST_SUCESS = "CHANGE_REQUEST_LIST_SUCESS";
export const CHANGE_REQUEST_LIST_FAILED = "CHANGE_REQUEST_LIST_FAILED";

// CHANGE REQUEST INFORMATION
export const CHANGE_REQUEST_INFO_IN_PROGRESS =
  "CHANGE_REQUEST_INFO_IN_PROGRESS";
export const CHANGE_REQUEST_INFO_SUCESS = "CHANGE_REQUEST_INFO_SUCESS";
export const CHANGE_REQUEST_INFO_FAILED = "CHANGE_REQUEST_INFO_FAILED";

// APPROVE DISAPPROVE REQUEST
export const APPROVE_DISAPPROVE_REQUEST_PROGRESS =
  "APPROVE_DISAPPROVE_REQUEST_PROGRESS";
export const APPROVE_DISAPPROVE_REQUEST_SUCCESS =
  "APPROVE_DISAPPROVE_REQUEST_SUCCESS";
export const APPROVE_DISAPPROVE_REQUEST_FAILED =
  "APPROVE_DISAPPROVE_REQUEST_FAILED";

// ADMIN GET USERS LIST
export const GET_USERS_LIST_IN_PROGRESS = "GET_USERS_LIST_IN_PROGRESS";
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILED = "GET_USERS_LIST_FAILED";

// ADMIN CREATE USERS LIST
export const CREATE_USER_IN_PROGRESS = "CREATE_USER_IN_PROGRESS";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";

// ADMIN SEARCH USERS LIST
export const SEARCH_USER_IN_PROGRESS = "SEARCH_USER_IN_PROGRESS";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILED = "SEARCH_USER_FAILED";

// ADMIN UPDATE USERS LIST
export const UPDATE_USER_IN_PROGRESS = "UPDATE_USER_IN_PROGRESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

// GET REVISION BY CONTRACR ID
export const GET_CONTRACT_REVISION_IN_PROGRESS =
  "GET_CONTRACT_REVISION_IN_PROGRESS";
export const GET_CONTRACT_REVISION_SUCCESS = "GET_CONTRACT_REVISION_SUCCESS";
export const GET_CONTRACT_REVISION_FAILED = "GET_CONTRACT_REVISION_FAILED";

// LODER CONTSTATNTS
export const SET_LOADING_TRUE = "SET_LOADING_TRUE";
export const SET_LOADING_FALSE = "SET_LOADING_FALSE";

//  COLLAPSIBLE
export const TOGGLE_COLLAPSIBLE = "TOGGLE_COLLAPSIBLE";
export const SET_COLLAPSIBLE_TRUE = "SET_COLLAPSIBLE_TRUE";
export const SET_COLLAPSIBLE_FALSE = "SET_COLLAPSIBLE_FALSE";

//  CHANGE_REQUEST_PENDING_NOTIFICATION
export const CR_NOTIFICATION_IN_PROGRESS = "CR_NOTIFICATION_IN_PROGRESS";
export const CR_NOTIFICATION_SUCCESS = "CR_NOTIFICATION_SUCCESS";
export const CR_NOTIFICATION_FAILED = "CR_NOTIFICATION_FAILED";

//  CREATE_SETTING_CONSTANTS
export const CREATE_SETTING_CONSTANTS_IN_PROGRESS = "CREATE_SETTING_CONSTANTS_IN_PROGRESS";
export const CREATE_SETTING_CONSTANTS_SUCCESS = "CREATE_SETTING_CONSTANTS_SUCCESS";
export const CREATE_SETTING_CONSTANTS_FAILED = "CREATE_SETTING_CONSTANTS_FAILED";

//  UPDATE_SETTING_CONSTANTS
export const UPDATE_SETTING_CONSTANTS_IN_PROGRESS = "UPDATE_SETTING_CONSTANTS_IN_PROGRESS";
export const UPDATE_SETTING_CONSTANTS_SUCCESS = "UPDATE_SETTING_CONSTANTS_SUCCESS";
export const UPDATE_SETTING_CONSTANTS_FAILED = "UPDATE_SETTING_CONSTANTS_FAILED";

//  DELETE_SETTING_CONSTANTS
export const DELETE_SETTING_CONSTANTS_IN_PROGRESS = "DELETE_SETTING_CONSTANTS_IN_PROGRESS";
export const DELETE_SETTING_CONSTANTS_SUCCESS = "DELETE_SETTING_CONSTANTS_SUCCESS";
export const DELETE_SETTING_CONSTANTS_FAILED = "DELETE_SETTING_CONSTANTS_FAILED";

//  GET_SETTING_CONSTANTS
export const GET_SETTING_CONSTANTS_IN_PROGRESS = "GET_SETTING_CONSTANTS_IN_PROGRESS";
export const GET_SETTING_CONSTANTS_SUCCESS = "GET_SETTING_CONSTANTS_SUCCESS";
export const GET_SETTING_CONSTANTS_FAILED = "GET_SETTING_CONSTANTS_FAILED";

//  GET_SETTING_CONSTANTS2
export const GET_SETTING_CONSTANTS2_IN_PROGRESS = "GET_SETTING_CONSTANTS2_IN_PROGRESS";
export const GET_SETTING_CONSTANTS2_SUCCESS = "GET_SETTING_CONSTANTS2_SUCCESS";
export const GET_SETTING_CONSTANTS2_FAILED = "GET_SETTING_CONSTANTS2_FAILED";

//  GET_SETTING_VENDOR
export const GET_SETTING_VENDOR_IN_PROGRESS = "GET_SETTING_VENDOR_IN_PROGRESS";
export const GET_SETTING_VENDOR_SUCCESS = "GET_SETTING_VENDOR_SUCCESS";
export const GET_SETTING_VENDOR_FAILED = "GET_SETTING_VENDOR_FAILED";

//  CREATE_VENDOR_SETTING_CONSTANTS
export const CREATE_VENDOR_SETTING_CONSTANTS_IN_PROGRESS = "CREATE_VENDOR_SETTING_CONSTANTS_IN_PROGRESS";
export const CREATE_VENDOR_SETTING_CONSTANTS_SUCCESS = "CREATE_VENDOR_SETTING_CONSTANTS_SUCCESS";
export const CREATE_VENDOR_SETTING_CONSTANTS_FAILED = "CREATE_VENDOR_SETTING_CONSTANTS_FAILED";

//  GET_SETTING_CONSTANTS
export const GET_VENDOR_SETTING_CONSTANTS_IN_PROGRESS = "GET_VENDOR_SETTING_CONSTANTS_IN_PROGRESS";
export const GET_VENDOR_SETTING_CONSTANTS_SUCCESS = "GET_VENDOR_SETTING_CONSTANTS_SUCCESS";
export const GET_VENDOR_SETTING_CONSTANTS_FAILED = "GET_VENDOR_SETTING_CONSTANTS_FAILED";

//  GET_SETTING_CONSTANTS2
export const GET_VENDOR_SETTING_CONSTANTS2_IN_PROGRESS = "GET_VENDOR_SETTING_CONSTANTS2_IN_PROGRESS";
export const GET_VENDOR_SETTING_CONSTANTS2_SUCCESS = "GET_VENDOR_SETTING_CONSTANTS2_SUCCESS";
export const GET_VENDOR_SETTING_CONSTANTS2_FAILED = "GET_VENDOR_SETTING_CONSTANTS2_FAILED";

// GET_SETTING_AGENCIES
export const GET_SETTING_AGENCIES_IN_PROGRESS = "GET_SETTING_AGENCIES_IN_PROGRESS";
export const GET_SETTING_AGENCIES_SUCCESS = "GET_SETTING_AGENCIES_SUCCESS";
export const GET_SETTING_AGENCIES_FAILED = "GET_SETTING_AGENCIES_FAILED";

//  EXPORT_REPORT
export const EXPORT_REPORT_IN_PROGRESS = "EXPORT_REPORT_IN_PROGRESS";
export const EXPORT_REPORT_SUCCESS = "EXPORT_REPORT_SUCCESS";
export const EXPORT_REPORT_FAILED = "EXPORT_REPORT_FAILED";

//  DOWNLOAD_REPORT
export const DOWNLOAD_REPORT_IN_PROGRESS = "DOWNLOAD_REPORT_IN_PROGRESS";
export const DOWNLOAD_REPORT_SUCCESS = "DOWNLOAD_REPORT_SUCCESS";
export const DOWNLOAD_REPORT_FAILED = "DOWNLOAD_REPORT_FAILED";

//  GET_DOWNLOAD_REPORT_HISTORY
export const GET_DOWNLOAD_REPORT_HISTORY_IN_PROGRESS = "GET_DOWNLOAD_REPORT_HISTORY_IN_PROGRESS";
export const GET_DOWNLOAD_REPORT_HISTORY_SUCCESS = "GET_DOWNLOAD_REPORT_HISTORY_SUCCESS";
export const GET_DOWNLOAD_REPORT_HISTORY_FAILED = "GET_DOWNLOAD_REPORT_HISTORY_FAILED";

// GET_TAB_OPTIONS
export const GET_TAB_OPTIONS_IN_PROGRESS = "GET_TAB_OPTIONS_IN_PROGRESS";
export const GET_TAB_OPTIONS_SUCCESS = "GET_TAB_OPTIONS_SUCCESS";
export const GET_TAB_OPTIONS_FAILED = "GET_TAB_OPTIONS_FAILED";

// GET_TAB_OPTIONS
export const GET_SPECIAL_OPTIONS_IN_PROGRESS = "GET_SPECIAL_OPTIONS_IN_PROGRESS";
export const GET_SPECIAL_OPTIONS_SUCCESS = "GET_SPECIAL_OPTIONS_SUCCESS";
export const GET_SPECIAL_OPTIONS_FAILED = "GET_SPECIAL_OPTIONS_FAILED";

// GET_TAB_OPTIONS
export const GET_SPECIAL_VENDOR_SDF_INSURANCE_OPTIONS_IN_PROGRESS = "GET_SPECIAL_VENDOR_SDF_INSURANCE_OPTIONS_IN_PROGRESS";
export const GET_SPECIAL_VENDOR_SDF_INSURANCE_OPTIONS_SUCCESS = "GET_SPECIAL_VENDOR_SDF_INSURANCE_OPTIONS_SUCCESS";
export const GET_SPECIAL_VENDOR_SDF_INSURANCE_OPTIONS_FAILED = "GET_SPECIAL_VENDOR_SDF_INSURANCE_OPTIONS_FAILED";

// GET USER CONFIGURATION
export const GET_USER_CONFIGURATION_IN_PROGRESS = "GET_USER_CONFIGURATION_IN_PROGRESS";
export const GET_USER_CONFIGURATION_SUCCESS = "GET_USER_CONFIGURATION_SUCCESS";
export const GET_USER_CONFIGURATION_FAILED = "GET_USER_CONFIGURATION_FAILED";

// GET_CONTRACT_INFO
export const GET_CONTRACT_INFORMATION_IN_PROGRESS = "GET_CONTRACT_INFORMATION_IN_PROGRESS";
export const GET_CONTRACT_INFORMATION_SUCCESS = "GET_CONTRACT_INFORMATION_SUCCESS";
export const GET_CONTRACT_INFORMATION_FAILED = "GET_CONTRACT_INFORMATION_FAILED";

// CLEAR_CONTRACT_INFORMATION_INFO
export const CLEAR_CONTRACT_INFORMATION_SUCCESS = "CLEAR_CONTRACT_INFORMATION_SUCCESS";
export const CLEAR_CONTRACT_INFORMATION_FAILED = "CLEAR_CONTRACT_INFORMATION_FAILED";

// ADD_CONTRACT_INFORMATION_INFO
export const ADD_CONTRACT_INFORMATION_IN_PROGRESS = "ADD_CONTRACT_INFORMATION_IN_PROGRESS";
export const ADD_CONTRACT_INFORMATION_SUCCESS = "ADD_CONTRACT_INFORMATION_SUCCESS";
export const ADD_CONTRACT_INFORMATION_FAILED = "ADD_CONTRACT_INFORMATION_FAILED";

// GET_RECRUITMENT_DRAFT_FILTER_OPTIONS
export const GET_RECRUITMENT_DRAFT_FILTER_OPTIONS_IN_PROGRESS = "GET_RECRUITMENT_DRAFT_FILTER_OPTIONS_IN_PROGRESS";
export const GET_RECRUITMENT_DRAFT_FILTER_OPTIONS_SUCCESS = "GET_RECRUITMENT_DRAFT_FILTER_OPTIONS_SUCCESS";
export const GET_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED = "GET_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED";

// GET_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS
export const GET_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_IN_PROGRESS = "GET_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_IN_PROGRESS";
export const GET_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_SUCCESS = "GET_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_SUCCESS";
export const GET_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED = "GET_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED";

// GET_RECRUITMENT_DRAFT_LIST_IN_PROGRESS
export const GET_RECRUITMENT_DRAFT_LIST_IN_PROGRESS = "GET_RECRUITMENT_DRAFT_LIST_IN_PROGRESS";
export const GET_RECRUITMENT_DRAFT_LIST_SUCCESS = "GET_RECRUITMENT_DRAFT_LIST_SUCCESS";
export const GET_RECRUITMENT_DRAFT_LIST_FAILED = "GET_RECRUITMENT_DRAFT_LIST_FAILED";


// GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS
export const GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_IN_PROGRESS = "GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_IN_PROGRESS";
export const GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_SUCCESS = "GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_SUCCESS";
export const GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED = "GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED";

// GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS
export const GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_IN_PROGRESS = "GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_IN_PROGRESS";
export const GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_SUCCESS = "GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_SUCCESS";
export const GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED = "GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED";

// GET_RECRUITMENT_DRAFT_LIST
export const GET_PENDING_RECRUITMENT_DRAFT_LIST_IN_PROGRESS = "GET_PENDING_RECRUITMENT_DRAFT_LIST_IN_PROGRESS";
export const GET_PENDING_RECRUITMENT_DRAFT_LIST_SUCCESS = "GET_PENDING_RECRUITMENT_DRAFT_LIST_SUCCESS";
export const GET_PENDING_RECRUITMENT_DRAFT_LIST_FAILED = "GET_PENDING_RECRUITMENT_DRAFT_LIST_FAILED";

// GET_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS
export const GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_IN_PROGRESS = "GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_IN_PROGRESS";
export const GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_SUCCESS = "GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_SUCCESS";
export const GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_FAILED = "GET_PENDING_RECRUITMENT_DRAFT_LIST_NOTIFICATIONS_FAILED";

// GET_DOCUMENTS_FOR_CONTRACT
export const GET_DOCUMENTS_FOR_CONTRACT_IN_PROGRESS = "GET_DOCUMENTS_FOR_CONTRACT_IN_PROGRESS";
export const GET_DOCUMENTS_FOR_CONTRACT_SUCCESS = "GET_DOCUMENTS_FOR_CONTRACT_SUCCESS";
export const GET_DOCUMENTS_FOR_CONTRACT_FAILED = "GET_DOCUMENTS_FOR_CONTRACT_FAILED";

// GET_SOW_OPTIONS
export const GET_SOW_OPTIONS_IN_PROGRESS = "GET_SOW_OPTIONS_IN_PROGRESS";
export const GET_SOW_OPTIONS_SUCCESS = "GET_SOW_OPTIONS_SUCCESS";
export const GET_SOW_OPTIONS_FAILED = "GET_SOW_OPTIONS_FAILED";

// GET_SOW
export const GET_SOW_IN_PROGRESS = "GET_SOW_IN_PROGRESS";
export const GET_SOW_SUCCESS = "GET_SOW_SUCCESS";
export const GET_SOW_FAILED = "GET_SOW_FAILED";

// SOW NOTIFICATION
export const GET_SOW_NOTIFICATIONS_IN_PROGRESS = "GET_SOW_NOTIFICATIONS_IN_PROGRESS";
export const GET_SOW_NOTIFICATIONS_SUCCESS = "GET_SOW_NOTIFICATIONS_SUCCESS";
export const GET_SOW_NOTIFICATIONS_FAILED = "GET_SOW_NOTIFICATIONS_FAILED";

// ADD_SOW
export const ADD_SOW_IN_PROGRESS = "ADD_SOW_IN_PROGRESS";
export const ADD_SOW_SUCCESS = "ADD_SOW_SUCCESS";
export const ADD_SOW_FAILED = "ADD_SOW_FAILED";

// GET_SOW_REVISIONS
export const GET_SOW_REVISIONS_IN_PROGRESS = "GET_SOW_REVISIONS_IN_PROGRESS";
export const GET_SOW_REVISIONS_SUCCESS = "GET_SOW_REVISIONS_SUCCESS";
export const GET_SOW_REVISIONS_FAILED = "GET_SOW_REVISIONS_FAILED";

// GET_REPORT_USER_DASHBOARDS
export const GET_REPORT_USER_DASHBOARDS_IN_PROGRESS = "GET_REPORT_USER_DASHBOARDS_IN_PROGRESS";
export const GET_REPORT_USER_DASHBOARDS_SUCCESS = "GET_REPORT_USER_DASHBOARDS_SUCCESS";
export const GET_REPORT_USER_DASHBOARDS_FAILED = "GET_REPORT_USER_DASHBOARDS_FAILED";

// GET_REPORT_DASHBOARDS
export const GET_REPORT_DASHBOARDS_IN_PROGRESS = "GET_REPORT_DASHBOARDS_IN_PROGRESS";
export const GET_REPORT_DASHBOARDS_SUCCESS = "GET_REPORT_DASHBOARDS_SUCCESS";
export const GET_REPORT_DASHBOARDS_FAILED = "GET_REPORT_DASHBOARDS_FAILED";

// GET_REPORT_DASHBOARD_INFO
export const GET_REPORT_DASHBOARD_INFO_IN_PROGRESS = "GET_REPORT_DASHBOARD_INFO_IN_PROGRESS";
export const GET_REPORT_DASHBOARD_INFO_SUCCESS = "GET_REPORT_DASHBOARD_INFO_SUCCESS";
export const GET_REPORT_DASHBOARD_INFO_FAILED = "GET_REPORT_DASHBOARD_INFO_FAILED";

// GET_REPORT_GROUPS
export const GET_REPORT_GROUPS_IN_PROGRESS = "GET_REPORT_GROUPS_IN_PROGRESS";
export const GET_REPORT_GROUPS_SUCCESS = "GET_REPORT_GROUPS_SUCCESS";
export const GET_REPORT_GROUPS_FAILED = "GET_REPORT_GROUPS_FAILED";

// GET_CREATE_REPORT_GROUP
export const CREATE_REPORT_GROUP_IN_PROGRESS = "CREATE_REPORT_GROUP_IN_PROGRESS";
export const CREATE_REPORT_GROUP_SUCCESS = "CREATE_REPORT_GROUP_SUCCESS";
export const CREATE_REPORT_GROUP_FAILED = "CREATE_REPORT_GROUP_FAILED";

// GET_REPORT_GROUP_INFO
export const GET_REPORT_GROUP_INFO_IN_PROGRESS = "GET_REPORT_GROUP_INFO_IN_PROGRESS";
export const GET_REPORT_GROUP_INFO_SUCCESS = "GET_REPORT_GROUP_INFO_SUCCESS";
export const GET_REPORT_GROUP_INFO_FAILED = "GET_REPORT_GROUP_INFO_FAILED";

// GET_DASHBOARD_USERS
export const GET_DASHBOARD_USERS_IN_PROGRESS = "GET_DASHBOARD_USERS_PROGRESS";
export const GET_DASHBOARD_USERS_SUCCESS = "GET_RDASHBOARD_USERS_SUCCESS";
export const GET_DASHBOARD_USERS_FAILED = "GET_DASHBOARD_USERS_FAILED";

// CREATE_DASHBOARD_USER
export const CREATE_DASHBOARD_USER_IN_PROGRESS = "CREATE_DASHBOARD_USER_PROGRESS";
export const CREATE_DASHBOARD_USER_SUCCESS = "CREATE_DASHBOARD_USER_SUCCESS";
export const CREATE_DASHBOARD_USER_FAILED = "CREATE_DASHBOARD_USER_FAILED";

// GET_DASHBOARD_DROPDOWN_OPTIONS
export const GET_DASHBOARD_DROPDOWN_OPTIONS_IN_PROGRESS = "GET_DASHBOARD_DROPDOWN_OPTIONS_IN_PROGRESS";
export const GET_DASHBOARD_DROPDOWN_OPTIONS_SUCCESS = "GET_DASHBOARD_DROPDOWN_OPTIONS_SUCCESS";
export const GET_DASHBOARD_DROPDOWN_OPTIONS_FAILED = "GET_DASHBOARD_DROPDOWN_OPTIONS_FAILED";

// STORE_DASHBOARD_FILTER
export const STORE_DASHBOARD_FILTER_IN_PROGRESS = "STORE_DASHBOARD_FILTER_IN_PROGRESS";
export const STORE_DASHBOARD_FILTER_SUCCESS = "STORE_DASHBOARD_FILTER_SUCCESS";
export const STORE_DASHBOARD_FILTER_FAILED = "STORE_DASHBOARD_FILTER_FAILED";

// GET_DASHBOARD_CARD_INFO
export const GET_DASHBOARD_CARD_INFO_IN_PROGRESS = "GET_DASHBOARD_CARD_INFO_PROGRESS";
export const GET_DASHBOARD_CARD_INFO_SUCCESS = "GET_DASHBOARD_CARD_INFO_SUCCESS";
export const GET_DASHBOARD_CARD_INFO_FAILED = "GET_DASHBOARD_CARD_INFO_FAILED";

// REQUEST OPTIONS
export const REQUEST_OPTIONS_LIST_IN_PROGRESS = "REQUEST_OPTIONS_LIST_IN_PROGRESS";
export const REQUEST_OPTIONS_LIST_SUCESS = "REQUEST_OPTIONS_LIST_SUCESS";
export const REQUEST_OPTIONS_LIST_FAILED = "REQUEST_OPTIONS_LIST_FAILED";

// APPROVE REQUEST OPTION
export const APPROVE_REQUEST_OPTION_IN_PROGRESS = "APPROVE_REQUEST_OPTION_IN_PROGRESS";
export const APPROVE_REQUEST_OPTION_SUCESS = "APPROVE_REQUEST_OPTION_SUCESS";
export const APPROVE_REQUEST_OPTION_FAILED = "APPROVE_REQUEST_OPTION_FAILED";

// GET_PENDING_REQUEST_OPTIONS
export const GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_IN_PROGRESS = "GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_IN_PROGRESS";
export const GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_SUCCESS = "GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_SUCCESS";
export const GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_FAILED = "GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_FAILED";

// GET CONFIGURATION FIELDS
export const GET_CONFIGURATION_FIELDS_IN_PROGRESS = "GET_CONFIGURATION_FIELDS_IN_PROGRESS";
export const GET_CONFIGURATION_FIELDS_SUCCESS = "GET_CONFIGURATION_FIELDS_SUCCESS";
export const GET_CONFIGURATION_FIELDS_FAILED = "GET_CONFIGURATION_FIELDS_FAILED";

// CONFIGURATION FIELD
export const CONFIGURATION_FIELD_IN_PROGRESS = "CONFIGURATION_FIELD_IN_PROGRESS";
export const CONFIGURATION_FIELD_SUCCESS = "CONFIGURATION_FIELD_SUCCESS";
export const CONFIGURATION_FIELD_FAILED = "CONFIGURATION_FIELD_FAILED";

// GET CONFIGURATION GROUPS
export const GET_CONFIGURATION_GROUPS_IN_PROGRESS = "GET_CONFIGURATION_GROUPS_IN_PROGRESS";
export const GET_CONFIGURATION_GROUPS_SUCCESS = "GET_CONFIGURATION_GROUPS_SUCCESS";
export const GET_CONFIGURATION_GROUPS_FAILED = "GET_CONFIGURATION_GROUPS_FAILED";

// CONFIGURATION GROUP
export const CONFIGURATION_GROUP_IN_PROGRESS = "CONFIGURATION_GROUP_IN_PROGRESS";
export const CONFIGURATION_GROUP_SUCCESS = "CONFIGURATION_GROUP_SUCCESS";
export const CONFIGURATION_GROUP_FAILED = "CONFIGURATION_GROUP_FAILED";

// GET CONFIGURATION USERS
export const GET_CONFIGURATION_USERS_IN_PROGRESS = "GET_CONFIGURATION_USERS_IN_PROGRESS";
export const GET_CONFIGURATION_USERS_SUCCESS = "GET_CONFIGURATION_USERS_SUCCESS";
export const GET_CONFIGURATION_USERS_FAILED = "GET_CONFIGURATION_USERS_FAILED";

// CONFIGURATION USER
export const CONFIGURATION_USER_IN_PROGRESS = "CONFIGURATION_USER_IN_PROGRESS";
export const CONFIGURATION_USER_SUCCESS = "CONFIGURATION_USER_SUCCESS";
export const CONFIGURATION_USER_FAILED = "CONFIGURATION_USER_FAILED";

// GET ALL FORM CONFIGURATIONS
export const GET_ALL_FORM_CONFIGURATIONS_IN_PROGRESS = "GET_ALL_FORM_CONFIGURATIONS_IN_PROGRESS";
export const GET_ALL_FORM_CONFIGURATIONS_SUCCESS = "GET_ALL_FORM_CONFIGURATIONS_SUCCESS";
export const GET_ALL_FORM_CONFIGURATIONS_FAILED = "GET_ALL_FORM_CONFIGURATIONS_FAILED";

// GET ALL FORM CONFIGURATIONS FOR ALL REGION
export const GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_IN_PROGRESS = "GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_IN_PROGRESS";
export const GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_SUCCESS = "GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_SUCCESS";
export const GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_FAILED = "GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_FAILED";

// GET FORM CONFIGURATIONS
export const GET_FORM_CONFIGURATIONS_IN_PROGRESS = "GET_FORM_CONFIGURATIONS_IN_PROGRESS";
export const GET_FORM_CONFIGURATIONS_SUCCESS = "GET_FORM_CONFIGURATIONS_SUCCESS";
export const GET_FORM_CONFIGURATIONS_FAILED = "GET_FORM_CONFIGURATIONS_FAILED";

// CONFIGURATION USER
export const FORM_CONFIGURATION_IN_PROGRESS = "FORM_CONFIGURATION_IN_PROGRESS";
export const FORM_CONFIGURATION_SUCCESS = "FORM_CONFIGURATION_SUCCESS";
export const FORM_CONFIGURATION_FAILED = "FORM_CONFIGURATION_FAILED";

// GET REVISION HISOTRY
export const GET_REVISION_HISTORY_IN_PROGRESS = "GET_REVISION_HISTORY_IN_PROGRESS";
export const GET_REVISION_HISTORY_SUCCESS = "GET_REVISION_HISTORY_SUCCESS";
export const GET_REVISION_HISTORY_FAILED = "GET_REVISION_HISTORY_FAILED";

// GET CANDIDATE INFORMATION
export const GET_CANDIDATE_INFORMATION_IN_PROGRESS = "GET_CANDIDATE_INFORMATION_IN_PROGRESS";
export const GET_CANDIDATE_INFORMATION_SUCCESS = "GET_CANDIDATE_INFORMATION_SUCCESS";
export const GET_CANDIDATE_INFORMATION_FAILED = "GET_CANDIDATE_INFORMATION_FAILED";

// CONTRACT CHANGES CHECK 
export const CONTRACT_CHANGES_CHECK_IN_PROGRESS = "CONTRACT_CHANGES_CHECK_IN_PROGRESS";
export const CONTRACT_CHANGES_CHECK_SUCCESS = "CONTRACT_CHANGES_CHECK_SUCCESS";
export const CONTRACT_CHANGES_CHECK_FAILED = "CONTRACT_CHANGES_CHECK_FAILED";

// FETCH APPLICATION LOGS
export const FETCH_APPLICATION_LOGS_IN_PROGRESS = "FETCH_APPLICATION_LOGS_IN_PROGRESS";
export const FETCH_APPLICATION_LOGS_SUCESS = "FETCH_APPLICATION_LOGS_SUCESS";
export const FETCH_APPLICATION_LOGS_FAILED = "FETCH_APPLICATION_LOGS_FAILED";

// FETCH SINGLE APPLICATION LOG
export const FETCH_SINGLE_APPLICATION_LOG_IN_PROGRESS = "FETCH_SINGLE_APPLICATION_LOG_IN_PROGRESS";
export const FETCH_SINGLE_APPLICATION_LOGS_SUCESS = "FETCH_SINGLE_APPLICATION_LOG_SUCESS";
export const FETCH_SINGLE_APPLICATION_LOG_FAILED = "FETCH_SINGLE_APPLICATION_LOG_FAILED";

// GET REGIONS
export const GET_REGIONS_IN_PROGRESS = "GET_REGIONS_IN_PROGRESS";
export const GET_REGIONS_SUCCESS = "GET_REGIONS_SUCCESS";
export const GET_REGIONS_FAILED = "GET_REGIONS_FAILED";

// REGION OPERATION
export const REGION_OPERATION_IN_PROGRESS = "REGION_OPERATION_IN_PROGRESS";
export const REGION_OPERATION_SUCCESS = "REGION_OPERATION_SUCCESS";
export const REGION_OPERATION_FAILED = "REGION_OPERATION_FAILED";

// GET REGION USERS
export const GET_REGION_USERS_IN_PROGRESS = "GET_REGION_USERS_IN_PROGRESS";
export const GET_REGION_USERS_SUCCESS = "GET_REGION_USERS_SUCCESS";
export const GET_REGION_USERS_FAILED = "GET_REGION_USERS_FAILED";

// LOGIN USER
export const LOGIN_USER_IN_PROGRESS = "LOGIN_USER_IN_PROGRESS";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";

// LOGOUT USER
export const LOGOUT_USER_IN_PROGRESS = "LOGOUT_USER_IN_PROGRESS";
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";
export const LOGOUT_USER_FAILED = "LOGOUT_USER_FAILED";

// REFRESH USER TOKEN
export const REFRESH_LOGIN_USER_TOKEN_IN_PROGRESS = "REFRESH_LOGIN_USER_TOKEN_IN_PROGRESS";
export const REFRESH_LOGIN_USER_TOKEN_SUCCESS = "REFRESH_LOGIN_USER_TOKEN_SUCCESS";
export const REFRESH_LOGIN_USER_TOKEN_FAILED = "REFRESH_LOGIN_USER_TOKEN_FAILED";

// REFRESH USER
export const REFRESH_LOGIN_USER_IN_PROGRESS = "REFRESH_LOGIN_USER_IN_PROGRESS";
export const REFRESH_LOGIN_USER_SUCCESS = "REFRESH_LOGIN_USER_SUCCESS";
export const REFRESH_LOGIN_USER_FAILED = "REFRESH_LOGIN_USER_FAILED";

// GET PERMISSION LIST
export const GET_PERMISSION_LIST_IN_PROGRESS = "GET_PERMISSION_LIST_IN_PROGRESS";
export const GET_PERMISSION_LIST_SUCCESS = "GET_PERMISSION_LIST_SUCCESS";
export const GET_PERMISSION_LIST_FAILED = "GET_PERMISSION_LIST_FAILED";

// PERMISSION OPERATION
export const PERMISSION_OPERATION_IN_PROGRESS = "PERMISSION_OPERATION_IN_PROGRESS";
export const PERMISSION_OPERATION_SUCCESS = "PERMISSION_OPERATION_SUCCESS";
export const PERMISSION_OPERATION_FAILED = "PERMISSION_OPERATION_FAILED";

// GET PERMISSION USER LIST
export const GET_PERMISSION_USER_LIST_IN_PROGRESS = "GET_PERMISSION_USER_LIST_IN_PROGRESS";
export const GET_PERMISSION_USER_LIST_SUCCESS = "GET_PERMISSION_USER_LIST_SUCCESS";
export const GET_PERMISSION_USER_LIST_FAILED = "GET_PERMISSION_USER_LIST_FAILED";

// USER PERMISSION OPERATION
export const USER_PERMISSION_OPERATION_IN_PROGRESS = "USER_PERMISSION_OPERATION_IN_PROGRESS";
export const USER_PERMISSION_OPERATION_SUCCESS = "USER_PERMISSION_OPERATION_SUCCESS";
export const USER_PERMISSION_OPERATION_FAILED = "USER_PERMISSION_OPERATION_FAILED";