import { Box, Button, Typography, TextField, Dialog, DialogContent, DialogActions } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { toast } from "react-toastify";

const RequestOptionModal = ({ open = false, handleClose, handleRequest, currentData = null }) => {
  const textInputs = currentData?.requestVariables || [];

  const [data, setData] = useState(null);

  useEffect(() => {
    textInputs.slice(textInputs.length - 1, textInputs.length).forEach(item => {
      const key = item.key;
      setData(prev => ({
        ...prev,
        [key]: currentData.value
      }));
    });
  }, []);

  const handleChange = (name = '', value = '') => {
    setData(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const checkValid = () => {
    let v = [], valid = true;
    textInputs.forEach(item => {
      let key = item.key;
      if (!data[key]) {
        valid = false;
      }
    });
    return valid;
  }

  const handleSubmit = () => {
    const check = checkValid();
    if (check) {
      let name = currentData.variable;
      let optionsVariable = currentData.searchVariable;
      let url = `/masterAdmin/requestConstantAddition`;
      let payload = {
        url,
        data: {
          fieldType: optionsVariable,
          options: data.options,
        }
      };
      if (name === 'businessUnit') {
        url = `/masterAdmin/requestBusinessUnitAddition`;
        payload = {
          url,
          data,
        };
      }else if (optionsVariable === 'vendor') {
        url = `/masterAdmin/requestVendorAddition`;
        payload = {
          url,
          data,
        };
      } else if (name === 'client') {
        url = `/masterAdmin/requestClientAddition`;
        payload = {
          url,
          data: {
            name: data.name,
            is_end_client: false,
          }
        };
      } else if (name === 'endClient') {
        url = `/masterAdmin/requestClientAddition`;
        payload = {
          url,
          data: {
            name: data.name,
            is_end_client: true
          }
        };
      } else if (name === 'domain') {
        url = `/masterAdmin/requestDomainAddition`;
        payload = {
          url,
          data: {
            name: data.name,
          }
        };
      } else if (optionsVariable === 'hr') {
        url = `/masterAdmin/requestHrAddition`;
        payload = {
          url,
          data: {
            name: data.name,
          }
        };
      }
      handleRequest(name, payload);
    } else {
      toast.error('Please fill all the required fields!');
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='sm'
    >
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            <Box sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: '#86E49A', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
              <Box sx={{ width: 35, height: 35, color: '#0FB333' }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-check"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 14l4 4L19 8" /></svg>
              </Box>
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ fontSize: '25px', color: '#101828', fontWeight: 'medium' }}>Request a new option?</Typography>
              <Typography sx={{ fontSize: '16px', color: grey[600], fontWeight: 400 }}>Are you sure you want to request this option?</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '86%', mt: 2 }}>
            {textInputs.map((item, index) => {
              return (
                <Box mt={1} key={index} sx={{ width: '100%' }}>
                  <Typography variant='subtitle2'>Enter {(item?.label || "").toLowerCase()} <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
                  <TextField
                    value={data?.[item.key] || ""}
                    onChange={(e) => handleChange(item.key, e.target.value)}
                    placeholder={"Please add a " + (item?.label || "").toLowerCase() + "!"}
                    sx={{ width: "100%", background: "#FFFFFF", mt: 1 }}
                    inputProps={{ paddingBottom: "100px" }}
                    id={item.key}
                    name={item.key}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ bgcolor: '#F3F3F3', p: 2 }}>
        <Button id='form-request-btn' variant="contained" color="success" disableElevation sx={{ bgcolor: '#0FB333', '&:hover': { bgcolor: '#0FB333' }, }} onClick={handleSubmit}>
          Request
        </Button>
        <Button id='form-cancel-btn' variant="contained" disableElevation sx={{ bgcolor: '#fff', color: '#101828', '&:hover': { bgcolor: '#fff' }, }} onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default RequestOptionModal;