import React, { Fragment } from 'react';
import { Box, Grid, Typography, IconButton, Tooltip, Alert, AlertTitle, Chip } from '@mui/material';
import DividerComponent from './Divider/Divider';
import { extractMultiValueFromOption, extractValueFromOption } from '../utils/consultantConstants';
import EditIcon from '@mui/icons-material/Edit';
import { getCountryByCode, getStateByCode } from '../utils/ensureSelectOptions';
import WarningAmberIcon from "@mui/icons-material/Warning";
import _ from 'lodash';
import UploadDocuments from './UploadDocuments';
import { blue } from '@mui/material/colors';

const InformationSummary = ({ optionList = {}, tabs = [], data = {}, oldData = {}, handleChangeTab, postSaveDocuments = [], mode = 'edit' }) => {
  return (
    <Box>
      {mode === 'edit' && data?.contractStatus !== 'Recruitment Draft' && data !== null && _.isEqual(oldData, data) ?
        <Alert severity="warning" sx={{ mb: 2 }}>
          <AlertTitle>No changes were detected!</AlertTitle>
        </Alert>
        :
        null
      }
      {tabs.map((tab, tabIndex) => {
        return (
          <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', border: mode === 'edit' ? `1px solid ${blue[500]}` : 'none', borderRadius: 4, py: mode === 'edit' ? 2 : 0, px: mode === 'edit' ? 4 : 2, my: 2 }} key={tabIndex}>
            {mode === 'edit' ?
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <Tooltip id='edit' title='Edit'>
                  <IconButton onClick={() => handleChangeTab(tabIndex)} sx={{ backgroundColor: blue[50] }} aria-label='edit' size='medium' color='primary'>
                    <EditIcon fontSize='medium' />
                  </IconButton>
                </Tooltip>
                <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{tab?.name || ""}</Typography>
              </Box>
              :
              null
            }
            <Grid container spacing={2}>
              {(tab?.fields || [])?.map((item, index) => {
                if (item?.hidden) {
                  return null;
                } else if (item.type === 'divider') {
                  return (
                    <Grid item xs={12} key={index}>
                      <DividerComponent title={item?.label || ""} />
                    </Grid>
                  )
                } else if (item.type === 'text' || item.type === 'email' || item.type === 'phone' || item.type === 'date') {
                  return (
                    <Grid item xs={item?.size || 4} key={'input-' + index}>
                      <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                      <Typography variant='body1'>{data?.[item?.variable] || "Not added"}</Typography>
                      {item?.confidential && data?.[item?.confidential]
                        ? <Chip
                          sx={{ pl: 0.5 }}
                          icon={<WarningAmberIcon fontSize="small" />}
                          label={item?.confidentialLabel || ""}
                        />
                        : null}
                    </Grid>
                  )
                } else if (item.type === 'dropdown') {
                  return (
                    <Grid item xs={4} key={'input-' + index}>
                      <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                      <Typography variant='body1'>{extractValueFromOption(optionList?.[item?.variable] || item?.options || [], data?.[item?.variable] || "")}</Typography>
                      {item?.confidential && data?.[item?.confidential]
                        ? <Chip
                          sx={{ pl: 0.5 }}
                          icon={<WarningAmberIcon fontSize="small" />}
                          label={item?.confidentialLabel || ""}
                        />
                        : null}
                    </Grid>
                  )
                } else if (item.type === 'searchable-dropdown') {
                  return (
                    <Grid item xs={4} key={'input-' + index}>
                      <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                      {item?.multiple ?
                        <Typography variant='body1'>{extractMultiValueFromOption(optionList?.[item?.searchVariable] || item?.options || [], data?.[item?.variable] || [])}</Typography>
                        :
                        <Typography variant='body1'>{extractValueFromOption(optionList?.[item?.searchVariable] || item?.options || [], data?.[item?.variable] || "")}</Typography>
                      }
                      {item?.confidential && data?.[item?.confidential]
                        ? <Chip
                          sx={{ pl: 0.5 }}
                          icon={<WarningAmberIcon fontSize="small" />}
                          label={item?.confidentialLabel || ""}
                        />
                        : null}
                    </Grid>
                  )
                } else if (item.type === 'location') {
                  return (
                    <Fragment>
                      {!item?.address?.hidden && item?.address?.label
                        ?
                        <Grid item xs={4}>
                          <Typography variant='body1' sx={{ mb: 1, fontWeight: 600 }}>{item?.address?.label || ""} {item?.locationConfig?.address?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                          <Typography variant='body1'>{data?.[item?.address?.variable] || "Not added"}</Typography>
                        </Grid>
                        :
                        null
                      }
                      {!item?.country?.hidden && item?.country?.label
                        ?
                        <Grid item xs={4}>
                          <Typography variant='body1' sx={{ mb: 1, fontWeight: 600 }}>{item?.country?.label || ""} {item?.locationConfig?.country?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                          <Typography variant='body1'>{getCountryByCode(data?.[item?.country?.variable]) || "Not added"}</Typography>
                        </Grid>
                        :
                        null
                      }
                      {!item?.state?.hidden && item?.state?.label
                        ?
                        <Grid item xs={4}>
                          <Typography variant='body1' sx={{ mb: 1, fontWeight: 600 }}>{item?.state?.label || ""} {item?.locationConfig?.state?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                          <Typography variant='body1'>{getStateByCode(data?.[item?.country?.variable], data?.[item?.state?.variable]) || "Not added"}</Typography>
                        </Grid>
                        :
                        null
                      }
                      {!item?.city?.hidden && item?.city?.label
                        ?
                        <Grid item xs={4}>
                          <Typography variant='body1' sx={{ mb: 1, fontWeight: 600 }}>{item?.city?.label || ""} {item?.locationConfig?.city?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                          <Typography variant='body1'>{data?.[item?.city?.variable] || "Not added"}</Typography>
                        </Grid>
                        :
                        null
                      }
                      {!item?.zipCode?.hidden && item?.zipCode?.label
                        ?
                        <Grid item xs={4}>
                          <Typography variant='body1' sx={{ mb: 1, fontWeight: 600 }}>{item?.zipCode?.label || ""} {item?.locationConfig?.zipCode?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                          <Typography variant='body1'>{data?.[item?.zipCode?.variable] || "Not added"}</Typography>
                        </Grid>
                        :
                        null
                      }
                    </Fragment>
                  )
                } else if (item.type === 'plan') {
                  return (
                    <Grid item xs={12} key={'input-' + index}>
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                          {item?.multiple ?
                            <Typography variant='body1'>{extractMultiValueFromOption(optionList?.[item?.searchVariable] || item?.options || [], data?.[item?.variable] || [])}</Typography>
                            :
                            <Typography variant='body1'>{extractValueFromOption(optionList?.[item?.searchVariable] || item?.options || [], data?.[item?.variable] || "")}</Typography>
                          }
                        </Grid>
                        {
                          data?.[item.variable]
                            ?
                            optionList?.[item.searchVariable]?.map((selectedPlan, selectedPlanIndex) => {
                              return (
                                <Grid item xs={4}>
                                  <Typography variant='subtitle2' sx={{ mb: 1, fontWeight: 600 }}>{selectedPlan?.label || ""} Effective date {item?.systemRequired || item?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                                  <Typography variant='body1'>{data?.[item.variable + "" + selectedPlan?.label] || "Not selected"}</Typography>
                                </Grid>
                              )
                            })
                            :
                            null}
                      </Grid>

                    </Grid>
                  )
                } else if (item.type === 'upload') {
                  return (
                    <Grid item xs={12} key={index}>
                      <UploadDocuments
                        listOptions={item?.list || []}
                        data={data}
                        allowed={false}
                        postSave={data?.contractStatus !== 'Recruitment Draft' && data?.contractStatus !== 'Draft' ? true : false}
                        postSaveDocuments={postSaveDocuments}
                      />
                    </Grid>
                  )
                } else return null
              })}
            </Grid>
          </Box>
        )
      })}
    </Box>
  )
}

export default InformationSummary;