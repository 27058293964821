import { Fragment } from 'react';
import { Box, Grid, Typography, Chip, IconButton, TextField } from '@mui/material';
import { extractMultiValueFromOption, extractValueFromOption } from '../../../utils/consultantConstants';
import _ from 'lodash';
import { blue, green, red, grey } from '@mui/material/colors';
import DividerComponent from '../../../components/Divider/Divider';
import { getCountryByCode, getStateByCode } from '../../../utils/ensureSelectOptions';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const Revision = ({ showComment = false, showOnlyChanges = false, showCheck = false, optionList = {}, tabs = [], data = {}, compareData = null, checkedItems = [], handleCheck = () => { } }) => {
  const oldData = compareData?.finalObj || null;
  const oldOptions = compareData?.allOptions || null;

  return (
    <Box sx={{ width: '100%' }}>
      {tabs.map((tab, tabIndex) => {
        return (
          <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', borderRadius: 4, my: showOnlyChanges ? 1 : (tabIndex ? 4 : 2), }} key={tabIndex}>
            {!showOnlyChanges ?
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 2, bgcolor: blue[50], borderRadius: 2, mb: 2, }}>
                <Typography variant='subtitle2' sx={{ fontSize: 20, }}>{tab?.name || ""}</Typography>
              </Box>
              :
              null
            }
            <Grid container gap={2} sx={{ mt: showOnlyChanges ? 1 : null }}>
              {(tab?.fields || [])?.map((item, index) => {
                if (item?.hidden) {
                  return null;
                } else if (item.type === 'divider') {
                  if (showOnlyChanges) {
                    return null;
                  }
                  return (
                    <Grid item xs={12} key={index}>
                      <DividerComponent title={item?.label || ""} titleStyle={{ backgroundColor: grey[100] }} />
                    </Grid>
                  )
                } else if (item.type === 'text' || item.type === 'email' || item.type === 'phone' || item.type === 'date') {
                  let updated = oldData?.[item?.variable] != data?.[item?.variable];
                  let updatedChip = oldData?.[item?.confidential] != data?.[item?.confidential];
                  if (!updated && !updatedChip && showOnlyChanges) {
                    return null;
                  }
                  return (
                    <Grid item xs={3.9} key={'input-' + index} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                      {updated && showCheck ?
                        showComment ?
                          <Fragment>
                            {checkedItems.includes(item?.variable) ?
                              <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                              :
                              <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                            }
                          </Fragment>
                          :
                          <Fragment>
                            {checkedItems.includes(item?.variable) ?
                              <IconButton id={`check-btn-${item?.variable}`} onClick={() => handleCheck(item?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <CheckBoxIcon color='info' />
                              </IconButton>
                              :
                              <IconButton id={`check-btn-${item?.variable}`} onClick={() => handleCheck(item?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <CheckBoxOutlineBlankIcon color='info' />
                              </IconButton>
                            }
                          </Fragment>
                        :
                        null
                      }
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                        <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.label || ""}</Typography>
                        {updated || updatedChip
                          ?
                          <Chip
                            sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                            size='small'
                            label={'Updated'}
                          />
                          :
                          null
                        }
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                        {updated || updatedChip ?
                          <Box>
                            <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                            <Typography variant='subtitle2' sx={{ wordBreak: 'break-word' }}>{oldData?.[item?.variable] || "Not added"}</Typography>
                          </Box>
                          :
                          null
                        }
                        <Box>
                          {updated || updatedChip ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                          <Typography variant='subtitle2' sx={{ fontSize: 16, wordBreak: 'break-word' }}>{data?.[item?.variable] || "Not added"}</Typography>
                        </Box>
                      </Box>
                      {item?.confidential ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, mt: 1 }}>
                          {updatedChip ?
                            <Typography variant='subtitle2' sx={{ bgcolor: grey[200], textTransform: 'capitalize', px: 2, py: 0.5, borderRadius: 10 }}>{item?.confidentialLabel || ""}</Typography>
                            :
                            null
                          }
                          {showCheck && !showComment && updatedChip ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: grey[200], textTransform: 'capitalize', pl: 1, pr: 2, borderRadius: 10 }}>
                              {checkedItems.includes(item?.confidential) ?
                                <IconButton id={`check-btn-${item?.confidential}`} size='small' onClick={() => handleCheck(item?.confidential)}>
                                  <CheckBoxIcon color='info' />
                                </IconButton>
                                :
                                <IconButton id={`check-btn-${item?.confidential}`} size='small' onClick={() => handleCheck(item?.confidential)}>
                                  <CheckBoxOutlineBlankIcon color='info' />
                                </IconButton>
                              }
                              <Typography variant='subtitle2'>{(item?.confidentialLabel || "") + ` ${data?.[item?.confidential] ? 'added' : 'removed'}`}</Typography>
                            </Box>
                            :
                            <Typography variant='subtitle2' sx={{ color: '#fff', bgcolor: blue[500], textTransform: 'capitalize', px: 2, py: 0.5, borderRadius: 10, display: (updatedChip || data?.[item?.confidential]) ? 'block' : 'none' }}>{(item?.confidentialLabel || "") + ` ${data?.[item?.confidential] ? 'added' : 'removed'}`}</Typography>
                          }
                        </Box>
                        :
                        null
                      }
                    </Grid>
                  )
                } else if (item.type === 'dropdown') {
                  let updated = oldData?.[item?.variable] != data?.[item?.variable];
                  let updatedChip = oldData?.[item?.confidential] != data?.[item?.confidential];
                  if (!updated && !updatedChip && showOnlyChanges) {
                    return null;
                  }
                  return (
                    <Grid item xs={3.9} key={'input-' + index} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                      {updated && showCheck ?
                        showComment ?
                          <Fragment>
                            {checkedItems.includes(item?.variable) ?
                              <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                              :
                              <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                            }
                          </Fragment>
                          :
                          <Fragment>
                            {checkedItems.includes(item?.variable) ?
                              <IconButton id={`check-btn-${item?.variable}`} onClick={() => handleCheck(item?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <CheckBoxIcon color='info' />
                              </IconButton>
                              :
                              <IconButton id={`check-btn-${item?.variable}`} onClick={() => handleCheck(item?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <CheckBoxOutlineBlankIcon color='info' />
                              </IconButton>
                            }
                          </Fragment>
                        :
                        null
                      }
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                        <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.label || ""}</Typography>
                        {updated || updatedChip
                          ?
                          <Chip
                            sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                            size='small'
                            label={'Updated'}
                          />
                          :
                          null
                        }
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                        {updated || updatedChip ?
                          <Box>
                            <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                            <Typography variant='subtitle2'>{extractValueFromOption(oldOptions?.[item?.variable] || item?.options || [], oldData?.[item?.variable] || "")}</Typography>
                          </Box>
                          :
                          null
                        }
                        <Box>
                          {updated || updatedChip ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                          <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{extractValueFromOption(optionList?.[item?.variable] || item?.options || [], data?.[item?.variable] || "")}</Typography>
                        </Box>
                      </Box>
                      {item?.confidential ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, mt: 1 }}>
                          {updatedChip ?
                            <Typography variant='subtitle2' sx={{ bgcolor: grey[200], textTransform: 'capitalize', px: 2, py: 0.5, borderRadius: 10 }}>{item?.confidentialLabel || ""}</Typography>
                            :
                            null
                          }
                          {showCheck && !showComment && updatedChip ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: grey[200], textTransform: 'capitalize', pl: 1, pr: 2, borderRadius: 10 }}>
                              {checkedItems.includes(item?.confidential) ?
                                <IconButton id={`check-btn-${item?.confidential}`} size='small' onClick={() => handleCheck(item?.confidential)}>
                                  <CheckBoxIcon color='info' />
                                </IconButton>
                                :
                                <IconButton id={`check-btn-${item?.confidential}`} size='small' onClick={() => handleCheck(item?.confidential)}>
                                  <CheckBoxOutlineBlankIcon color='info' />
                                </IconButton>
                              }
                              <Typography variant='subtitle2'>{(item?.confidentialLabel || "") + ` ${data?.[item?.confidential] ? 'added' : 'removed'}`}</Typography>
                            </Box>
                            :
                            <Typography variant='subtitle2' sx={{ color: '#fff', bgcolor: blue[500], textTransform: 'capitalize', px: 2, py: 0.5, borderRadius: 10, display: (updatedChip || data?.[item?.confidential]) ? 'block' : 'none' }}>{(item?.confidentialLabel || "") + ` ${data?.[item?.confidential] ? 'added' : 'removed'}`}</Typography>
                          }
                        </Box>
                        :
                        null
                      }
                    </Grid>
                  )
                } else if (item.type === 'searchable-dropdown') {
                  let updated = false;
                  let updatedChip = oldData?.[item?.confidential] != data?.[item?.confidential];
                  if (item?.multiple) {
                    updated = !_.isEqual(_.sortBy(oldData?.[item?.variable] || []), _.sortBy((data?.[item?.variable] || [])));
                  } else {
                    updated = oldData?.[item?.variable] != data?.[item?.variable]
                  }
                  if (!updated && !updatedChip && showOnlyChanges) {
                    return null;
                  }
                  return (
                    <Grid item xs={3.9} key={'input-' + index} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                      {updated && showCheck ?
                        showComment ?
                          <Fragment>
                            {checkedItems.includes(item?.variable) ?
                              <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                              :
                              <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                            }
                          </Fragment>
                          :
                          <Fragment>
                            {checkedItems.includes(item?.variable) ?
                              <IconButton id={`check-btn-${item?.variable}`} onClick={() => handleCheck(item?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <CheckBoxIcon color='info' />
                              </IconButton>
                              :
                              <IconButton id={`check-btn-${item?.variable}`} onClick={() => handleCheck(item?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                <CheckBoxOutlineBlankIcon color='info' />
                              </IconButton>
                            }
                          </Fragment>
                        :
                        null
                      }
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                        <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.label || ""}</Typography>
                        {updated || updatedChip
                          ?
                          <Chip
                            sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                            size='small'
                            label={'Updated'}
                          />
                          :
                          null
                        }
                      </Box>
                      {item?.multiple ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                          {updated || updatedChip
                            ?
                            <Box>
                              <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                              <Typography variant='subtitle2'>{extractMultiValueFromOption(oldOptions?.[item?.searchVariable] || item?.options || [], oldData?.[item?.variable] || [])}</Typography>
                            </Box>
                            :
                            null
                          }
                          <Box>
                            {updated || updatedChip ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                            <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{extractMultiValueFromOption(optionList?.[item?.searchVariable] || item?.options || [], data?.[item?.variable] || [])}</Typography>
                          </Box>
                        </Box>
                        :
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                          {updated || updatedChip
                            ?
                            <Box>
                              <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                              <Typography variant='subtitle2'>{extractValueFromOption(oldOptions?.[item?.searchVariable] || item?.options || [], oldData?.[item?.variable] || "")}</Typography>
                            </Box>
                            :
                            null
                          }
                          <Box>
                            {updated || updatedChip ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                            <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{extractValueFromOption(optionList?.[item?.searchVariable] || item?.options || [], data?.[item?.variable] || "")}</Typography>
                          </Box>
                        </Box>
                      }
                      {item?.confidential ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2, mt: 1 }}>
                          {updatedChip ?
                            <Typography variant='subtitle2' sx={{ bgcolor: grey[200], textTransform: 'capitalize', px: 2, py: 0.5, borderRadius: 10 }}>{item?.confidentialLabel || ""}</Typography>
                            :
                            null
                          }
                          {showCheck && !showComment && updatedChip ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: grey[200], textTransform: 'capitalize', pl: 1, pr: 2, borderRadius: 10 }}>
                              {checkedItems.includes(item?.confidential) ?
                                <IconButton id={`check-btn-${item?.confidential}`} size='small' onClick={() => handleCheck(item?.confidential)}>
                                  <CheckBoxIcon color='info' />
                                </IconButton>
                                :
                                <IconButton id={`check-btn-${item?.confidential}`} size='small' onClick={() => handleCheck(item?.confidential)}>
                                  <CheckBoxOutlineBlankIcon color='info' />
                                </IconButton>
                              }
                              <Typography variant='subtitle2'>{(item?.confidentialLabel || "") + ` ${data?.[item?.confidential] ? 'added' : 'removed'}`}</Typography>
                            </Box>
                            :
                            <Typography variant='subtitle2' sx={{ color: '#fff', bgcolor: blue[500], textTransform: 'capitalize', px: 2, py: 0.5, borderRadius: 10, display: (updatedChip || data?.[item?.confidential]) ? 'block' : 'none' }}>{(item?.confidentialLabel || "") + ` ${data?.[item?.confidential] ? 'added' : 'removed'}`}</Typography>
                          }
                        </Box>
                        :
                        null
                      }
                    </Grid>
                  )
                } else if (item.type === 'location') {
                  let updatedAddress = oldData?.[item?.address?.variable] != data?.[item?.address?.variable];
                  let updatedCountry = oldData?.[item?.country?.variable] != data?.[item?.country?.variable];
                  let updatedState = oldData?.[item?.state?.variable] != data?.[item?.state?.variable];
                  let updatedCity = oldData?.[item?.city?.variable] != data?.[item?.city?.variable];
                  let updatedZipCode = oldData?.[item?.zipCode?.variable] != data?.[item?.zipCode?.variable];
                  return (
                    <Fragment>
                      {!item?.locationConfig?.address?.hidden && item?.address?.label
                        ?
                        (!updatedAddress && showOnlyChanges) ?
                          null
                          :
                          <Grid item xs={3.9} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                            {updatedAddress && showCheck ?
                              showComment ?
                                <Fragment>
                                  {checkedItems.includes(item?.address?.variable) ?
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                                    :
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                                  }
                                </Fragment>
                                :
                                <Fragment>
                                  {checkedItems.includes(item?.address?.variable) ?
                                    <IconButton id={`check-btn-${item?.address?.variable}`} onClick={() => handleCheck(item?.address?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxIcon color='info' />
                                    </IconButton>
                                    :
                                    <IconButton id={`check-btn-${item?.address?.variable}`} onClick={() => handleCheck(item?.address?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxOutlineBlankIcon color='info' />
                                    </IconButton>
                                  }
                                </Fragment>
                              :
                              null
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                              <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.address?.label || ""}</Typography>
                              {updatedAddress
                                ?
                                <Chip
                                  sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                                  size='small'
                                  label={'Updated'}
                                />
                                :
                                null
                              }
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                              {updatedAddress
                                ?
                                <Box>
                                  <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                                  <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{oldData?.[item?.address?.variable] || "Not added"}</Typography>
                                </Box>
                                :
                                null
                              }
                              <Box>
                                {updatedAddress ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                                <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{data?.[item?.address?.variable] || "Not added"}</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        :
                        null
                      }
                      {!item?.locationConfig?.country?.hidden && item?.country?.label
                        ?
                        (!updatedCountry && showOnlyChanges) ?
                          null
                          :
                          <Grid item xs={3.9} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                            {updatedCountry && showCheck ?
                              showComment ?
                                <Fragment>
                                  {checkedItems.includes(item?.country?.variable) ?
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                                    :
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                                  }
                                </Fragment>
                                :
                                <Fragment>
                                  {checkedItems.includes(item?.country?.variable) ?
                                    <IconButton id={`check-btn-${item?.country?.variable}`} onClick={() => handleCheck(item?.country?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxIcon color='info' />
                                    </IconButton>
                                    :
                                    <IconButton id={`check-btn-${item?.country?.variable}`} onClick={() => handleCheck(item?.country?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxOutlineBlankIcon color='info' />
                                    </IconButton>
                                  }
                                </Fragment>
                              :
                              null
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                              <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.country?.label || ""}</Typography>
                              {updatedCountry
                                ?
                                <Chip
                                  sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                                  size='small'
                                  label={'Updated'}
                                />
                                :
                                null
                              }
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                              {updatedCountry
                                ?
                                <Box>
                                  <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                                  <Typography variant='subtitle2'>{getCountryByCode(oldData?.[item?.country?.variable]) || "Not added"}</Typography>
                                </Box>
                                :
                                null
                              }
                              <Box>
                                {updatedCountry ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                                <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{getCountryByCode(data?.[item?.country?.variable]) || "Not added"}</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        :
                        null
                      }
                      {!item?.locationConfig?.state?.hidden && item?.state?.label
                        ?
                        (!updatedState && showOnlyChanges) ?
                          null
                          :
                          <Grid item xs={3.9} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                            {updatedState && showCheck ?
                              showComment ?
                                <Fragment>
                                  {checkedItems.includes(item?.state?.variable) ?
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                                    :
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                                  }
                                </Fragment>
                                :
                                <Fragment>
                                  {checkedItems.includes(item?.state?.variable) ?
                                    <IconButton id={`check-btn-${item?.state?.variable}`} onClick={() => handleCheck(item?.state?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxIcon color='info' />
                                    </IconButton>
                                    :
                                    <IconButton id={`check-btn-${item?.state?.variable}`} onClick={() => handleCheck(item?.state?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxOutlineBlankIcon color='info' />
                                    </IconButton>
                                  }
                                </Fragment>
                              :
                              null
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                              <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.state?.label || ""}</Typography>
                              {updatedState
                                ?
                                <Chip
                                  sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                                  size='small'
                                  label={'Updated'}
                                />
                                :
                                null
                              }
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                              {updatedState
                                ?
                                <Box>
                                  <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                                  <Typography variant='subtitle2'>{getStateByCode(oldData?.[item?.country?.variable], oldData?.[item?.state?.variable]) || "Not added"}</Typography>
                                </Box>
                                :
                                null
                              }
                              <Box>
                                {updatedState ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                                <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{getStateByCode(data?.[item?.country?.variable], data?.[item?.state?.variable]) || "Not added"}</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        :
                        null
                      }
                      {!item?.locationConfig?.city?.hidden && item?.city?.label
                        ?
                        (!updatedCity && showOnlyChanges) ?
                          null
                          :
                          <Grid item xs={3.9} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                            {updatedCity && showCheck ?
                              showComment ?
                                <Fragment>
                                  {checkedItems.includes(item?.city?.variable) ?
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                                    :
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                                  }
                                </Fragment>
                                :
                                <Fragment>
                                  {checkedItems.includes(item?.city?.variable) ?
                                    <IconButton id={`check-btn-${item?.city?.variable}`} onClick={() => handleCheck(item?.city?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxIcon color='info' />
                                    </IconButton>
                                    :
                                    <IconButton id={`check-btn-${item?.city?.variable}`} onClick={() => handleCheck(item?.city?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxOutlineBlankIcon color='info' />
                                    </IconButton>
                                  }
                                </Fragment>
                              :
                              null
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                              <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.city?.label || ""}</Typography>
                              {updatedCity
                                ?
                                <Chip
                                  sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                                  size='small'
                                  label={'Updated'}
                                />
                                :
                                null
                              }
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                              {updatedCity
                                ?
                                <Box>
                                  <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                                  <Typography variant='subtitle2'>{oldData?.[item?.city?.variable] || "Not added"}</Typography>
                                </Box>
                                :
                                null
                              }
                              <Box>
                                {updatedCity ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                                <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{data?.[item?.city?.variable] || "Not added"}</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        :
                        null
                      }
                      {!item?.locationConfig?.zipCode?.hidden && item?.zipCode?.label
                        ?
                        (!updatedZipCode && showOnlyChanges) ?
                          null
                          :
                          <Grid item xs={3.9} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                            {updatedZipCode && showCheck ?
                              showComment ?
                                <Fragment>
                                  {checkedItems.includes(item?.zipCode?.variable) ?
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                                    :
                                    <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                                  }
                                </Fragment>
                                :
                                <Fragment>
                                  {checkedItems.includes(item?.zipCode?.variable) ?
                                    <IconButton id={`check-btn-${item?.zipCode?.variable}`} onClick={() => handleCheck(item?.zipCode?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxIcon color='info' />
                                    </IconButton>
                                    :
                                    <IconButton id={`check-btn-${item?.zipCode?.variable}`} onClick={() => handleCheck(item?.zipCode?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                      <CheckBoxOutlineBlankIcon color='info' />
                                    </IconButton>
                                  }
                                </Fragment>
                              :
                              null
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                              <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.zipCode?.label || ""}</Typography>
                              {updatedZipCode
                                ?
                                <Chip
                                  sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                                  size='small'
                                  label={'Updated'}
                                />
                                :
                                null
                              }
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                              {updatedZipCode
                                ?
                                <Box>
                                  <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                                  <Typography variant='subtitle2'>{oldData?.[item?.zipCode?.variable] || "Not added"}</Typography>
                                </Box>
                                :
                                null
                              }
                              <Box>
                                {updatedZipCode ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                                <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{data?.[item?.zipCode?.variable] || "Not added"}</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        :
                        null
                      }
                    </Fragment>
                  )
                } else if (item.type === 'plan') {
                  let updated = false;
                  if (item?.multiple) {
                    updated = !_.isEqual(_.sortBy(oldData?.[item?.variable] || []), _.sortBy((data?.[item?.variable] || [])));
                  } else {
                    updated = oldData?.[item?.variable] != data?.[item?.variable]
                  }
                  if (!updated && showOnlyChanges) {
                    return null;
                  }
                  return (
                    <Grid item xs={12} key={'input-' + index}>
                      <Grid container gap={2}>
                        <Grid item xs={3.9} sx={{ p: 2, bgcolor: 'white', borderRadius: 2, position: 'relative' }}>
                          {updated && showCheck ?
                            showComment ?
                              <Fragment>
                                {checkedItems.includes(item?.variable) ?
                                  <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: green[500] }}>Approved</Typography>
                                  :
                                  <Typography variant="subtitle2" sx={{ position: 'absolute', top: 10, right: 10, fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: red[500] }}>Rejected</Typography>
                                }
                              </Fragment>
                              :
                              <Fragment>
                                {checkedItems.includes(item?.variable) ?
                                  <IconButton id={`check-btn-${item?.variable}`} onClick={() => handleCheck(item?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                    <CheckBoxIcon color='info' />
                                  </IconButton>
                                  :
                                  <IconButton id={`check-btn-${item?.variable}`} onClick={() => handleCheck(item?.variable)} sx={{ position: 'absolute', top: 0, right: 0 }}>
                                    <CheckBoxOutlineBlankIcon color='info' />
                                  </IconButton>
                                }
                              </Fragment>
                            :
                            null
                          }
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                            <Typography variant='body1' sx={{ fontWeight: 600 }}>{item?.label || ""}</Typography>
                            {updated
                              ?
                              <Chip
                                sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                                size='small'
                                label={'Updated'}
                              />
                              :
                              null
                            }
                          </Box>
                          {item?.multiple ?
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                              {updated
                                ?
                                <Box>
                                  <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                                  <Typography variant='subtitle2'>{extractMultiValueFromOption(oldOptions?.[item?.searchVariable] || item?.options || [], oldData?.[item?.variable] || [])}</Typography>
                                </Box>
                                :
                                null
                              }
                              <Box>
                                {updated ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                                <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{extractMultiValueFromOption(optionList?.[item?.searchVariable] || item?.options || [], data?.[item?.variable] || [])}</Typography>
                              </Box>
                            </Box>
                            :
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                              {updated
                                ?
                                <Box>
                                  <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                                  <Typography variant='subtitle2'>{extractValueFromOption(oldOptions?.[item?.searchVariable] || item?.options || [], oldData?.[item?.variable] || "")}</Typography>
                                </Box>
                                :
                                null
                              }
                              <Box>
                                {updated ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                                <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{extractValueFromOption(optionList?.[item?.searchVariable] || item?.options || [], data?.[item?.variable] || "")}</Typography>
                              </Box>
                            </Box>
                          }
                        </Grid>
                        {data?.[item.variable]
                          ?
                          optionList?.[item.searchVariable]?.map((selectedPlan, selectedPlanIndex) => {
                            let updatedDate = oldData?.[item.variable + "" + selectedPlan?.label]?.length && oldData?.[item.variable + "" + selectedPlan?.label] != data?.[item.variable + "" + selectedPlan?.label];
                            return (
                              <Grid key={'plant-date-' + selectedPlanIndex} item xs={3.9} sx={{ p: 2, bgcolor: 'white', borderRadius: 2 }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 2, mb: 1 }}>
                                  <Typography variant='body1' sx={{ fontWeight: 600 }}>{selectedPlan?.label || ""} Effective date</Typography>
                                  {updatedDate
                                    ?
                                    <Chip
                                      sx={{ color: 'white', bgcolor: blue[500], fontSize: 12, fontWeight: 600 }}
                                      size='small'
                                      label={'Updated'}
                                    />
                                    :
                                    null
                                  }
                                </Box>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
                                  {updatedDate ?
                                    <Box>
                                      <Typography variant='subtitle2' sx={{ fontSize: 12 }}>Previous</Typography>
                                      <Typography variant='subtitle2'>{oldData?.[item.variable + "" + selectedPlan?.label] || "Not selected"}</Typography>
                                    </Box>
                                    :
                                    null
                                  }
                                  <Box>
                                    {updatedDate ? <Typography variant='subtitle2' sx={{ fontSize: 12 }}>New</Typography> : null}
                                    <Typography variant='subtitle2' sx={{ fontSize: 16 }}>{data?.[item.variable + "" + selectedPlan?.label] || "Not selected"}</Typography>
                                  </Box>
                                </Box>
                              </Grid>
                            )
                          })
                          :
                          null}
                      </Grid>

                    </Grid>
                  )
                } else return null;
              })}
            </Grid>
          </Box>
        )
      })}
    </Box >
  )
}

export default Revision;