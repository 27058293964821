import { useEffect, useState } from 'react';
import { XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid, AreaChart, Area, LabelList } from 'recharts';
import { COLORS, extractBarDataPoints } from '../../utils/chartHelper';
import { Box, Typography } from '@mui/material';
import NoDataCard from './noDataCard';
import RenderCustomBarLabel from './customBarLabel';
import CustomTooltip from './CustomTooltip';
import CustomizedAxisTick from './customAxisTick';
import calculateSize from 'calculate-size';

const AreaCard = ({ data = null, visualizationData = null, title = "", showAnimation = true }) => {
  const [axes, setAxes] = useState([]);
  const [list, setList] = useState([]);
  const [xName, setXName] = useState("");
  const [yName, setYName] = useState("");
  const [chartTitle, setTitle] = useState("");
  const [size, setSize] = useState(null);

  useEffect(() => {
    const dataPoints = extractBarDataPoints(data, visualizationData);
    setList(dataPoints?.list || []);
    setAxes(dataPoints?.axes || []);
    setXName(dataPoints?.xName || "");
    setYName(dataPoints?.yName || "");
    setTitle(dataPoints?.title || title);
    setSize(dataPoints?.size || null);
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  const yNameSize = calculateSize(yName || "", {
    font: 'Poppins',
    fontSize: '14px',
  });

  const gap = size?.width ? size.width / 2 : 10;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <Box sx={{ width: '100%', height: xName ? '85%' : '95%', display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 0.5, position: 'relative' }}>
        {yName ?
          <div style={{ whiteSpace: 'normal', verticalAlign: 'bottom', width: 40 }}>
            <span style={{ transformOrigin: 'left', transform: "translate(50%," + (yNameSize.width / 2 + yNameSize.height / 2) + "px) rotate(-90deg)", display: 'block', textWrap: 'nowrap', fontSize: 14 }}>{yName}</span>
          </div>
          :
          null
        }
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={list}
            margin={{
              top: 0,
              right: gap > 40 ? 40 : gap,
              bottom: 0,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis padding={{ left: gap > 40 ? 40 : gap, right: gap > 100 ? 40 : gap }} height={size?.height ? (size?.width > 100 ? 80 : size.height) : 25} dataKey={axes[0]?.dataKey || null} tick={<CustomizedAxisTick rotate={size?.width > 100} />} interval={0} />
            <YAxis allowDataOverflow={true} />
            <Tooltip content={<CustomTooltip />} />
            <Legend align="left" verticalAlign='top' wrapperStyle={{ paddingBottom: gap / 2 > 20 ? 20 : gap / 2 }} />
            {
              axes?.map((item, index) => {
                if (index > 0) {
                  return (
                    <Area isAnimationActive={showAnimation} unit={item?.unit || null} legendType='line' key={`line-chart-gp-${index}`} dataKey={item.dataKey} stroke={COLORS[index]} fill={COLORS[index]} dot={true}>
                      <LabelList dataKey={item.dataKey} content={<RenderCustomBarLabel unit={item?.unit || ''} />} />
                    </Area>
                  )
                }
                return null;
              })
            }

          </AreaChart>
        </ResponsiveContainer>
      </Box>
      {xName ? <Typography noWrap sx={{ textAlign: 'center', fontSize: 14 }}>{xName}</Typography> : null}
    </Box>
  )
}

export default AreaCard;