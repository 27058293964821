import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";
import countries from 'flag-icons/country.json';
import LocalSearchableDropdown from '../../../../components/LocalSearchableDropdown';

const GroupCUModal = ({ open = false, handleClose, handleAdd }) => {
	const [data, setData] = useState(null);

	const handleModalClose = () => {
		setData(null);
		handleClose();
	}

	const handleAddOption = () => {
		const check = checkValid();
		if (check) {
			handleAdd({ ...data });
			setData(null);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const checkValid = () => {
		let valid = true;
		if (!data?.region_code) {
			valid = false;
		}
		return valid;
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth="xs"
		>
			<DialogContent>
				<Typography variant='h6'>Add New Region</Typography>
				<Box>
					<Typography variant='subtitle2' sx={{ mb: 1 }}>Choose a region <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
					<LocalSearchableDropdown
						label='a region'
						value={data?.region_code}
						options={countries.map(country => ({ value: (country?.code || "").toUpperCase(), label: country.name }))}
						handleChange={(v) => handleChange('region_code', v?.value)}
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button id='form-close-btn' onClick={handleModalClose}>Close</Button>
				<Button id='form-add-btn' onClick={handleAddOption} variant='contained'>Add"</Button>
			</DialogActions>
		</Dialog>
	)
}

export default GroupCUModal;