import { Box } from '@mui/material';

function DividerComponent({ title, titleStyle = {}, outerStyle = {} }) {
  return (
    <Box sx={{ position: "relative", marginTop: 2 }} style={{ ...outerStyle }}>
      <span style={{ fontSize: 18, fontWeight: 700, background: "#FFFFFF", zIndex: 2, display: "inline-block", position: "relative", paddingRight: 15, ...titleStyle }}>
        {title}
      </span>
      <span style={{ position: "absolute", left: 0, top: "50%", transform: "translateY(-50%)", height: 1, width: "100%", display: "block", background: "#d5d5d5", }}></span>
    </Box>
  )
}

export default DividerComponent;