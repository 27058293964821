import api from "../../config/https";
import {
  FETCH_APPLICATION_LOGS_FAILED,
  FETCH_APPLICATION_LOGS_IN_PROGRESS,
  FETCH_APPLICATION_LOGS_SUCESS,
  FETCH_SINGLE_APPLICATION_LOG_FAILED,
  FETCH_SINGLE_APPLICATION_LOG_IN_PROGRESS,
  FETCH_SINGLE_APPLICATION_LOGS_SUCESS,
} from "../../constants/store";
import { encodeQueryData2 } from "../../utils/consultantConstants";

export const applicationLogsPageLimit = 100;

export const fetchApplicationLogs = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_APPLICATION_LOGS_IN_PROGRESS,
    });
    let res = await api.get('/masterAdmin/log' + encodeQueryData2(payload));
    if (res?.data?.status && res?.data?.data) {
      let data = res.data.data;
      let list = data?.data || [];
      dispatch({
        type: FETCH_APPLICATION_LOGS_SUCESS,
        payload: { data: list, maxPage: data?.count ? Math.ceil(data?.count / applicationLogsPageLimit) : 1, currentPage: payload.pageNumber },
      });
    } else {
      dispatch({
        type: FETCH_APPLICATION_LOGS_FAILED,
      });
    }
    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({
      type: FETCH_APPLICATION_LOGS_FAILED,
    });
  }
};

export const fetchSingleApplicationLog = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SINGLE_APPLICATION_LOG_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/log/${payload.id}`);
    if (res?.data?.status && res?.data?.data) {
      let data = res.data.data;
      dispatch({
        type: FETCH_SINGLE_APPLICATION_LOGS_SUCESS,
        payload: { info: data._source },
      });
    } else {
      dispatch({
        type: FETCH_SINGLE_APPLICATION_LOG_FAILED,
      });
    }
    if (callback) {
      callback();
    }
  } catch (e) {
    dispatch({
      type: FETCH_SINGLE_APPLICATION_LOG_FAILED,
    });
  }
};