import { City, Country, State } from "country-state-city";
import { Zoom, toast } from "react-toastify";
import { isValidPhoneNumber } from 'react-phone-number-input';
import _ from "lodash";
import moment from "moment";
import { Box, Typography } from "@mui/material";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DialpadIcon from '@mui/icons-material/Dialpad';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PlaceIcon from '@mui/icons-material/Place';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import { green, grey, red } from "@mui/material/colors";
import { diff } from "deep-object-diff";

export const extractChangeVariables = (tabList = [], data = null, oldData = null) => {
  let variables = [];
  let diffInfo = diff(oldData, data);
  let diffKeys = !_.isEmpty(diffInfo) ? Object.keys(diffInfo) : [];
  tabList.forEach(tab => {
    tab?.fields.forEach(field => {
      if (field?.type !== 'upload' && field?.type !== 'divider') {
        if (field?.type === 'location') {
          let updatedAddress = oldData?.[field?.address?.variable] !== data?.[field?.address?.variable] && !field?.locationConfig?.address?.hidden;
          let updatedCountry = oldData?.[field?.country?.variable] !== data?.[field?.country?.variable] && !field?.locationConfig?.country?.hidden;;
          let updatedState = oldData?.[field?.state?.variable] !== data?.[field?.state?.variable] && !field?.locationConfig?.state?.hidden;;
          let updatedCity = oldData?.[field?.city?.variable] !== data?.[field?.city?.variable] && !field?.locationConfig?.city?.hidden;;
          let updatedZipCode = oldData?.[field?.zipCode?.variable] !== data?.[field?.zipCode?.variable] && !field?.locationConfig?.zipCode?.hidden;;
          if (field?.address?.variable && updatedAddress) {
            variables.push(field?.address?.variable);
          }
          if (field?.country?.variable && updatedCountry) {
            variables.push(field?.country?.variable);
          }
          if (field?.state?.variable && updatedState) {
            variables.push(field?.state?.variable);
          }
          if (field?.city?.variable && updatedCity) {
            variables.push(field?.city?.variable);
          }
          if (field?.zipCode?.variable && updatedZipCode) {
            variables.push(field?.zipCode?.variable);
          }
        } else if (!field?.hidden) {
          let updated = false;
          let updatedChip = false;
          if (field?.type === 'text' || field?.type === 'email' || field?.type === 'phone' || field?.type === 'date') {
            updated = oldData?.[field?.variable] !== data?.[field?.variable];
          } else if (field?.type === 'dropdown') {
            updated = oldData?.[field?.variable] !== data?.[field?.variable];
          } else if (field?.type === 'searchable-dropdown' || field?.type === 'plan') {
            updated = false;
            if (field?.multiple && oldData?.[field?.variable]?.length) {
              updated = !_.isEqual(_.sortBy(oldData?.[field?.variable] || []), _.sortBy((data?.[field?.variable] || [])));
            } else {
              updated = oldData?.[field?.variable] !== data?.[field?.variable]
            }
          }
          if (updated) {
            variables.push(field.variable);
          }
          if (field?.confidential) {
            updatedChip = oldData?.[field?.confidential] != data?.[field?.confidential];
            if (updatedChip) {
              variables.push(field.confidential);
            }
          }
        }
      }
    });
  });
  return diffKeys.filter(item => variables.includes(item));
}

export const statusColor = (status = "") => {
  switch (status) {
    case 'approved':
      return green[500];
    case 'rejected':
      return red[500];
    default:
      return grey[400];
  }
}

export const convertToRequiredTabFormat = (tabs = []) => {
  let newList = [];
  tabs.forEach((tab, tabIndex) => {
    let fields = [];
    tab?.fields?.forEach(field => {
      if (field?.type === 'divider') {
        fields.push({ ...field, tabId: tabIndex });
      } else {
        let f = field?.field_data?.length ? field.field_data[0] : null;
        if (f?.type) {
          let item = { ...f, ...field, tabId: tabIndex };
          delete item.field_data;
          fields.push(item);
        }
      }
    });
    newList.push({ ...tab, fields: fields });
  });
  return newList;
}

export const insertIntoArray = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
];

export const makeOptionsByFields = (list = []) => {
  let newList = [];
  list.forEach(item => {
    if (item?.confidential) {
      newList.push({ variable: item?.confidential || "", value: item?.confidential || "", label: "𝘊𝘩𝘦𝘤𝘬𝘣𝘰𝘹 : " + (item?.confidentialLabel || "") });
      newList.push(item);
    } else {
      if (item?.type === 'location') {
        // address
        if (item?.address?.label) {
          newList.push({ variable: item?.address?.variable || "", value: item?.address?.variable || "", label: "𝘓𝘰𝘤𝘢𝘵𝘪𝘰𝘯 : " + (item?.address?.label || "") });
        }
        // country
        if (item?.country?.label) {
          newList.push({ variable: item?.country?.variable || "", value: item?.country?.variable || "", label: "𝘓𝘰𝘤𝘢𝘵𝘪𝘰𝘯 : " + (item?.country?.label || "") });
        }
        // state
        if (item?.state?.label) {
          newList.push({ variable: item?.state?.variable || "", value: item?.state?.variable || "", label: "𝘓𝘰𝘤𝘢𝘵𝘪𝘰𝘯 : " + (item?.state?.label || "") });
        }
        // city
        if (item?.city?.label) {
          newList.push({ variable: item?.city?.variable || "", value: item?.city?.variable || "", label: "𝘓𝘰𝘤𝘢𝘵𝘪𝘰𝘯 : " + (item?.city?.label || "") });
        }
        // zip code
        if (item?.zipCode?.label) {
          newList.push({ variable: item?.zipCode?.variable || "", value: item?.zipCode?.variable || "", label: "𝘓𝘰𝘤𝘢𝘵𝘪𝘰𝘯 : " + (item?.zipCode?.label || "") });
        }
      } else {
        newList.push(item);
      }
    }
  });
  return newList;
}

export const getAllFieldsFromTabList = (tabs = []) => {
  let newList = [];
  tabs.forEach(tab => {
    tab?.fields?.forEach(field => {
      if (field?.type === 'divider') {
        newList.push({ ...field, value: field?.variable || "", label: "𝘗𝘢𝘨𝘦 𝘚𝘦𝘤𝘵𝘪𝘰𝘯 : " + (field?.label || "") });
      } else {
        newList.push({ ...field, value: field?.variable || "", label: (field?.label || "") });
      }
    });
  });
  return newList;
}

export const formComponentsTypes = [
  {
    label: 'Input',
    key: 'input',
    icon: <Box>
      <Typography variant='subtitle2' sx={{ fontSize: 8, lineHeight: '8px' }}>AB</Typography>
      <Typography variant='subtitle2' sx={{ fontSize: 8, lineHeight: '8px' }}>12</Typography>
    </Box>,
  },
  {
    label: 'Page Section',
    key: 'divider',
    icon: <Typography>-------</Typography>,
  },
];

export const formComponents = [
  {
    label: 'Text',
    key: 'text',
    icon: <Box>
      <Typography variant='subtitle2' sx={{ fontSize: 8, lineHeight: '8px' }}>AB</Typography>
      <Typography variant='subtitle2' sx={{ fontSize: 8, lineHeight: '8px' }}>12</Typography>
    </Box>,
    variants: [
      {
        label: 'Text',
        key: 'text',
        icon: <Box>
          <Typography variant='subtitle2' sx={{ fontSize: 8, lineHeight: '8px' }}>AB</Typography>
          <Typography variant='subtitle2' sx={{ fontSize: 8, lineHeight: '8px' }}>12</Typography>
        </Box>,
      },
      {
        label: 'Number',
        key: 'text',
        icon: <Box>
          <Typography variant='subtitle2' sx={{ fontSize: 8, lineHeight: '8px' }}>12</Typography>
          <Typography variant='subtitle2' sx={{ fontSize: 8, lineHeight: '8px' }}>34</Typography>
        </Box>,
      },
      {
        label: 'Email',
        key: 'email',
        icon: <AlternateEmailIcon sx={{ fontSize: 16 }} />,
      },
      {
        label: 'Phone',
        key: 'phone',
        icon: <DialpadIcon sx={{ fontSize: 16 }} />,
      },
      {
        label: 'Date',
        key: 'date',
        icon: <CalendarTodayIcon sx={{ fontSize: 16 }} />,
      },
    ],
  },
  {
    label: 'Dropdown',
    key: 'dropdown',
    icon: <ArrowDropDownCircleIcon sx={{ fontSize: 16 }} />,
    variants: [
      {
        label: 'Static',
        key: 'dropdown',
        icon: <ArrowDropDownCircleIcon sx={{ fontSize: 16 }} />,
      },
      {
        label: 'Searchable',
        key: 'searchable-dropdown',
        icon: <SearchIcon sx={{ fontSize: 16 }} />,
      },
    ],
  },
  {
    label: 'Location',
    icon: <PlaceIcon sx={{ fontSize: 16 }} />,
    variants: [
      {
        label: 'Location',
        key: 'location',
        icon: <PlaceIcon sx={{ fontSize: 16 }} />,
      },
    ],
  },
  {
    label: 'Plan',
    icon: <MedicalInformationIcon sx={{ fontSize: 16 }} />,
    variants: [
      {
        label: 'Plan',
        key: 'plan',
        icon: <MedicalInformationIcon sx={{ fontSize: 16 }} />,
      },
    ],
  },
  {
    label: 'Upload',
    icon: <CloudUploadIcon sx={{ fontSize: 16 }} />,
    variants: [
      {
        label: 'Upload',
        key: 'upload',
        icon: <CloudUploadIcon sx={{ fontSize: 16 }} />,
      },
    ],
  },
];

export const trimFilename = (fileName = "") => {
  let fileNameLength = fileName.length;
  if (fileNameLength < 30) {
    return fileName;
  } else {
    let extensionDelimiterIndex = fileName.lastIndexOf('.');
    let middleRemovedName = `${fileName.substring(0, 24)}...${fileName.substring(extensionDelimiterIndex - 3)}`
    return middleRemovedName;
  }
}

export const getLabelValueFromOptions = (value = "", options = []) => {
  for (let i = 0; i < options.length; i++) {
    if (options[i].value === value) {
      return options[i].label;
    }
  }
  return null;
};

export const renderRecruitmentDraftStatus = (status = "") => {
  if (status === 0 || status === 1) {
    return 'Recruitment Draft'
  } else {
    return null;
  }
}

export function encodeQueryData(data = null) {
  if (data) {
    let ret = [];
    for (let d in data) {
      if (_.isArray(data[d])) {
        let arr = [];
        data[d].forEach(item => {
          arr.push("'" + item.toString() + "'");
        });
        ret.push(d + '=' + arr.join(','));
      } else {
        ret.push(d + '=' + data[d].toString());
      }
    }
    return '?' + ret.join('&');
  }
  return '';
}

export function encodeQueryData2(data = null) {
  if (data) {
    let ret = [];
    for (let d in data) {
      if (_.isArray(data[d])) {
        let arr = [];
        data[d].forEach(item => {
          arr.push(item.toString());
        });
        ret.push(d + '=' + arr.join(','));
      } else {
        ret.push(d + '=' + data[d].toString());
      }
    }
    return '?' + ret.join('&');
  }
  return '';
}

export const extractValuableOptions = (data = []) => {
  let finalData = [];
  data.forEach((item) => {
    if (item.value && item.label) {
      finalData.push({ ...item, key: finalData.length + 1 });
    }
  });
  return finalData;
}

export const getValidConsultantObjects = (data = {}) => {
  const d = { ...data };

  // consultant information
  const personal = {
    name: d?.name || null,
    ssn: d?.ssn || null,
    phone: d?.phone || null,
    email: d?.email || null,
    gender: d?.gender || null,
    ethnicity: d?.ethnicity || null,
    maritalStatus: d?.maritalStatus || null,
    dateOfBirth: d?.dateOfBirth || null,
  };
  const emergencyContact = {
    name: d?.emergencyContactName || null,
    email: d?.emergencyContactEmail || null,
    phone: d?.emergencyContactPhone || null,
    relationship: d?.emergencyContactRelation || null
  };
  const location = {
    address: d?.address || null,
    country: d?.country || null,
    state: d?.state || null,
    city: d?.city || null,
    zipCode: d?.zipCode || null,
  };
  const legal = {
    eVerifyDate: d?.eVerifyDate || null,
    lcaStatus: d?.lcaStatus || null,
    workAuthorization: d?.workAuthorization || null,
    workAuthorizationExpiryDate: d?.workAuthorizationExpiryDate || null,
  };
  const skill = {
    primarySkills: d?.primarySkills || [],
    secondarySkills: d?.secondarySkills || []
  };
  const consultantInformation = {
    id: d.candidateId,
    status: d.contractStatus,
    ssn: d?.ssn || null,
    personal: personal,
    emergencyContact: emergencyContact,
    location: location,
    legal: legal,
    skill: skill
  };

  // contract
  const benefit = {
    benefitStatus: d?.benefitStatus === 'Yes' ? true : false,
    benefitPlan: d?.benefitStatus === 'Yes' ? d.benefitPlan : [],
    benefitEffectiveDate: d?.benefitStatus === 'Yes' ? d.benefitEffectiveDate.slice(0, (d?.benefitPlan || []).length) : [],
  };
  const businessEnitity = {
    entity: d?.entity || "",
    businessEntityLocation: d?.businessEntityLocation || "",
    businessUnitId: d?.businessUnit || ""
  };
  const client = {
    client: d?.client,
    endClient: d?.endClient,
    isRemote: d?.isRemote === 'Yes' ? true : false,
    projectAddress: d?.projectAddress || "",
    projectState: d?.projectState || "",
    projectCountry: d?.projectCountry || "",
    projectCity: d?.projectCity || "",
    domain: d?.domain,
    clientPortalId: d?.clientPortalId || "",
    clientPOEndDate: d?.clientPOEndDate || "",
    clientProjectManager: d?.clientProjectManager || "",
    clientProjectManagerEmail: d?.clientProjectManagerEmail || ""
  }
  const consultant = {
    startDate: d?.startDate || "",
    duration: d?.duration || "",
    tentativeEndDate: d?.tentativeEndDate || "",
    endDate: d?.endDate || "",
    jobTitle: d?.jobTitle || "",
    passThrough: d?.passThrough === 'Yes' ? true : false,
  }
  const vendor = {
    contractorStatus: d?.contractorStatus || "",
    contractorName: d?.contractorName || "",
    federalId: d?.federalId || "",
    vendorPOCName: d?.vendorPOCName || "",
    contractorNumber: d?.contractorNumber || "",
    contractorEmailId: d?.contractorEmailId || "",
    vendorAddress: d?.vendorAddress || "",
    vendorCountry: d?.vendorCountry || "",
    vendorState: d?.vendorState || "",
    vendorCity: d?.vendorCity || "",
    vendorZipCode: d?.vendorZipCode || "",
    diversityStatus: d?.diversityStatus === 'Yes' ? true : false,
    insuranceEntries: d?.insuranceEntries || [],
    sdfEntires: d?.supplierDiversityForm || [],
    sdf: "",
    sdfExpiryDate: "",
    sdfCertifyingAgencies: [],
    certificateOfInsurance: d?.certificateOfInsurance ? d.certificateOfInsurance === 'Yes' ? true : false : null,
    certificateOfInsuranceApprovedBy: [
      d.certificateOfInsuranceApprovedBy
    ],
    certificateOfInsuranceType: [],
    certificateOfInsuranceExpiryDate: [],
    agency: []
  }
  const contract = {
    plc: d?.plc || "",
    contractStatus: d.contractStatus,
    isConfidential: d.isConfidential,
    poPending: 0,
    effectiveFrom: d?.effectiveFrom || "",
    businessEntity: businessEnitity,
    consultant: consultant,
    vendor: vendor,
    benefit: benefit,
    client: client
  };

  // payments
  const payRates = {
    payRateCurrency: d?.payRateCurrency || "",
    payRate: d?.payRate || "",
    payRatePaymentBasis: d?.payRatePaymentBasis || "",
  };
  const clientRate = {
    clientRateCurrency: d?.clientRateCurrency || "",
    clientRate: d?.clientRate || "",
    clientRatePaymentBasis: d?.clientRatePaymentBasis || "",
  };
  const margin = {
    marginValue: d?.marginValue || ""
  };
  const paymentss = {
    invoicingTerm: d?.invoicingTerm || "",
    paymentTerm: d?.paymentTerm || "",
    paymentTermApprovalBy: [d.paymentTermApprovalBy],
    mspPortalFee: d?.mspPortalFee,
    mspFeeApproved: d?.mspFeeApproved,
    mspPortalFeeApprovalBy: [d?.mspPortalFeeApprovalBy],
    volumeDiscount: d?.volumeDiscount,
    volumeDiscountApproved: d?.volumeDiscountApproved,
    volumeDiscountApprovalBy: [d?.volumeDiscountApprovalBy],
    tenureDiscount: d?.tenureDiscount === 'Yes' ? true : false,
    tenureDiscountApprovalBy: [d?.tenureDiscountApprovalBy],
    earlyPaymentDiscount: d?.earlyPaymentDiscount,
    earlyPaymentWaive: d?.earlyPaymentWaive,
    earlyPaymentDiscountApprovalBy: [d?.earlyPaymentDiscountApprovalBy],
    bgvWaiver: d?.bgvWaiver === 'Yes' ? true : false,
    bgvApprovalBy: [d?.bgvApprovalBy]
  };
  const payments = {
    payRates: payRates,
    clientRate: clientRate,
    margin: margin,
    payments: paymentss
  };

  // human resources
  const humanResources = {
    recruiterIds: d?.recruiterIds || [],
    coeIds: d?.coeIds || [],
    ptOwnerIds: d?.ptOwnerIds || [],
    ptSupportIds: d?.ptSupportIds || [],
    tlIds: d?.tlIds || [],
    dalIds: d?.dalIds || [],
    sourcingTeamIds: d?.sourcingTeamIds || [],
    dmIds: d?.dmIds || [],
    addIds: d?.addIds || [],
    admId: d?.admId || [],
    atlIds: d?.atlIds || [],
    clientResourceManagerName: d?.clientResourceManagerName || "",
    clientResourceManagerEmail: d?.clientResourceManagerEmail || ""
  };

  // misc
  const miscellaneous = {
    brNumber: d?.brNumber || "",
    rateCardAdherance: d?.rateCardAdherance || "",
    sotNonSot: d?.sotNonSot || "",
    source: d?.source || "",
    subTrack: d?.subTrack || "",
    trackOnlyHCL: d?.trackOnlyHCL || "",
    documents: d?.documents || [],
    is_draft: d?.contractStatus === 'Draft' ? true : false,
  };
  const finalObj = {
    consultantInformation: consultantInformation,
    contract: contract,
    payments: payments,
    humanResources: humanResources,
    miscellaneous: miscellaneous,
  };
  return finalObj;
}

export const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  let finalObj = Object.assign({}, ...keyValues);
  return finalObj;
}

export const extractValueFromOption = (options = [], value = "") => {
  let found = null;
  options.forEach(option => {
    if (option.value == value) {
      found = option?.label || null;
    }
  })
  return found || 'Not selected';
}

export const extractMultiValueFromOption = (options = [], value = []) => {
  let found = [];
  value?.forEach(val => {
    const foundItem = options.find(option => val == option.value);
    if (foundItem && !found.includes(foundItem?.label)) found.push(foundItem?.label || "");
  });
  return found.length ? found.toString() : 'Not selected';
}

export const convertToOptions = (list = {}) => {
  let finalList = {};
  Object.keys(list).forEach(option => {
    let options = [];
    list[option].forEach((item, index) => {
      if (_.isString(item)) {
        if (item !== '') {
          options.push({ id: item?.id || (index + 1), value: item, label: item });
        }
      } else {
        if (item?.options !== '') {
          options.push({ id: item?.id, value: item.id, label: item.options });
        }
      }
    });
    finalList = {
      ...finalList,
      [option]: options,
    }
  });
  return finalList;
}

export const convertSpecialListToOptions = (list = [], variable = "", key = "") => {
  let finalList = {};
  let options = [];
  list.forEach(item => {
    if (item[key] !== '') {
      if (variable === 'hr') {
        options.push({ ...item, value: item.id, label: (item[key] + (item?.emp_id ? " - " + item?.emp_id : "")).toString() });
      } else if (variable === 'vendor') {
        options.push({ ...item, value: item[key], label: item[key].toString() });
      } else {
        options.push({ ...item, value: item.id, label: item[key].toString() });
      }
    }
  });
  finalList = {
    ...finalList,
    [variable]: options
  }
  return finalList;
}

export const convertSearchedListToOptions = (list = [], variable = "", key1 = "", key2 = "") => {
  let finalList = {};
  let options = [];
  list.forEach((item, index) => {
    if (item[key1] !== '') {
      if (variable === 'hr') {
        options.push({ id: item?.id || index + 1, value: item.id, label: (item[key1] + (item?.emp_id ? " - " + item?.emp_id : "")).toString() });
      } else if (variable === 'vendor') {
        options.push({ id: item?.id || index + 1, value: item[key1], label: (item?.[key1] || "").toString() });
      } else if (key1 === 'sdf') {
        options.push({ id: item?.id || index + 1, value: item[key2], label: (item?.sdf || "").toString(), agency: item.agency_name, date: item?.sdf_expiry_date });
      } else if (key1 === 'insurance') {
        options.push({ id: item?.id || index + 1, value: item[key2], label: (item?.cert_of_insurance_type || "").toString(), agency: item.agency_name, date: item?.cert_of_insurance_expiry_date });
      } else {
        options.push({ value: item[key2], label: (item?.[key1] || "").toString() });
      }
    }
  });
  finalList = {
    ...finalList,
    [variable]: options
  }
  return finalList;
}

export const countryOptions = Country.getAllCountries().map(country => {
  return { label: country.name, value: country.isoCode }
});

export const stateOptions = (country) => State.getStatesOfCountry(country).map(state => {
  return { label: state.name, value: state.isoCode }
});

export const cityOptions = (country, state) => City.getCitiesOfState(country, state).map(city => {
  return { label: city.name, value: city.name }
});

const toastProps = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: "light",
  transistion: Zoom
}

export const handleTabValidations = (data = {}, list = [], options = null, warn = true) => {
  for (let i = 0; i < list.length; i++) {
    if ((list[i]?.type !== 'divider' && list[i]?.type !== 'location') && (list[i]?.systemRequired || list[i]?.required) && !list[i]?.hidden) {
      const numberRegex = /^[0-9]+$/;
      const decimalNumberRegex = /^[0-9]*\.?[0-9]+$/;
      if (list[i]?.type === 'text' && list[i]?.configVariant === 'number' && !list[i]?.decimal && !numberRegex.test(data?.[list[i].variable])) {
        if (warn) {
          toast.warn('Please enter a valid ' + (list[i].label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (list[i]?.type === 'text' && list[i]?.configVariant === 'number' && list[i]?.decimal && !decimalNumberRegex.test(data?.[list[i].variable])) {
        if (warn) {
          toast.warn('Please enter a valid ' + (list[i].label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (list[i]?.type === 'date' && (data?.[list[i].variable] || "") === 'Invalid date') {
        if (warn) {
          toast.warn('Please enter a valid ' + (list[i].label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (list[i]?.type === 'phone' && !isValidPhoneNumber(data?.[list[i].variable] || "")) {
        if (warn) {
          toast.warn('Please enter a valid ' + (list[i].label || "").toLowerCase() + " number!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (list[i]?.type === 'email' && !validateEmail(data?.[list[i].variable] || "")) {
        if (warn) {
          toast.warn('Please enter a valid ' + (list[i].label || "").toLowerCase() + " address!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if ((list[i]?.type === 'dropdown' || list[i]?.type === 'searchable-dropdown') && !list[i]?.multiple && !data?.[list[i].variable]) {
        if (warn) {
          toast.warn('Please select a ' + (list[i].label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (list[i]?.type === 'searchable-dropdown' && list[i]?.multiple && !(data?.[list[i].variable] || []).length) {
        if (warn) {
          toast.warn('Please select a ' + (list[i].label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (!data?.[list[i].variable]) {
        if (warn) {
          toast.warn('Please enter ' + (list[i].label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      }
    } else if (list[i]?.type === 'location') {
      let item = list[i];
      let key1 = item?.address?.variable || "";
      let value1 = data[key1];
      let key2 = item?.country?.variable || "";
      let value2 = data[key2];
      let key3 = item?.state?.variable || "";
      let value3 = data[key3];
      let key4 = item?.city?.variable || "";
      let value4 = data[key4];
      let key5 = item?.zipCode?.variable || "";
      let value5 = data[key5];
      if (item?.address?.label && item?.address?.variable && item?.locationConfig?.address?.required && !item?.locationConfig?.address?.hidden && !value1) {
        let label = item?.address?.label || "";
        if (warn) {
          toast.warn('Please enter a ' + (label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (item?.country?.label && item?.country?.variable && item?.locationConfig?.country?.required && !item?.locationConfig?.country?.hidden && !value2) {
        let label = item?.country?.label || "";
        if (warn) {
          toast.warn('Please select a ' + (label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (item?.state?.label && item?.state?.variable && item?.locationConfig?.state?.required && !item?.locationConfig?.state?.hidden && !value3 && options?.[item?.state?.variable]?.length) {
        let label = item?.state?.label || "";
        if (warn) {
          toast.warn('Please select a ' + (label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (item?.city?.label && item?.city?.variable && item?.locationConfig?.city?.required && !item?.locationConfig?.city?.hidden && !value4 && options?.[item?.city?.variable]?.length) {
        let label = item?.city?.label || "";
        if (warn) {
          toast.warn('Please select a ' + (label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      } else if (item?.zipCode?.label && item?.zipCode?.variable && item?.locationConfig?.zipCode?.required && !item?.locationConfig?.zipCode?.hidden && !value5) {
        let label = item?.zipCode?.label || "";
        if (warn) {
          toast.warn('Please enter a ' + (label || "").toLowerCase() + "!", { ...toastProps });
        }
        let info = {
          tab: list[i]?.tabId,
          id: list[i].id,
        }
        return info;
      }
    }
  }
  return true;
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const contractStatusOptions = [
  {
    value: "Recruitment Draft",
    label: "Recruitment Draft",
  },
  {
    value: "Draft",
    label: "Draft",
  },
  {
    value: "In-Process",
    label: "In-Process",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Dropped",
    label: "Dropped",
  },
  {
    value: "In-Active",
    label: "In-Active",
  },
]

export const convertDataToContractInformationRequirement = (data = {}, existingData = {}, config = []) => {
  let finalObj = {}, options = {}, allOptions = {};
  config?.forEach(tab => {
    tab?.fields?.forEach(item => {
      if (item?.type !== 'divider' && item?.type !== 'upload') {
        if (item?.type === 'location') {
          if (item?.address?.label && item?.address?.variable) {
            let key = item?.address?.variable;
            finalObj[key] = data?.[key] || "";
          }
          if (item?.country?.label && item?.country?.variable) {
            let key = item?.country?.variable;
            finalObj[key] = data?.[key] || "";
          }
          if (item?.state?.label && item?.state?.variable) {
            let key = item?.state?.variable;
            finalObj[key] = data?.[key] || "";
            let countryKey = item?.country?.variable;
            if (finalObj[countryKey]) {
              options = {
                ...options,
                [key]: stateOptions(finalObj[countryKey]),
              }
            }
          }
          if (item?.city?.label && item?.city?.variable) {
            let key = item?.city?.variable;
            finalObj[key] = data?.[key] || "";
            let countryKey = item?.country?.variable;
            let stateKey = item?.state?.variable;
            if (finalObj[countryKey] && finalObj[stateKey]) {
              options = {
                ...options,
                [key]: cityOptions(finalObj[countryKey], finalObj[stateKey]),
              }
            }
          }
          if (item?.zipCode?.label && item?.zipCode?.variable) {
            let key = item?.zipCode?.variable;
            finalObj[key] = data?.[key] || "";
          }
        } else {
          if (item?.confidential) {
            let confidentialKey = item?.confidential;
            let value = data?.[confidentialKey];
            if (confidentialKey) {
              finalObj[confidentialKey] = value ? true : false;
            }
          }
          let key = item?.variable;
          let value = data?.[key];
          if (item?.type === 'searchable-dropdown') {
            let ops = [];
            if (item?.multiple) {
              finalObj[key] = value?.length ? value.map(item => item?.id) : [];
              if (item?.searchVariable === 'sdf' || item?.searchVariable === 'insurance') {
                ops = value?.length ? value.map(item => ({ value: item?.id, label: item?.name, agency: item?.agency, date: item?.date })) : [];
              } else if (item?.searchVariable === 'hr') {
                ops = value?.length ? value.map(item => ({ value: item?.hr_id, label: item?.name, agency: item?.agency, date: item?.date })) : [];
                finalObj[key] = value?.length ? value.map(item => item?.hr_id) : [];
              } else {
                ops = value?.length ? value.map(item => ({ value: item?.id, label: item?.name })) : [];
              }
            } else {
              finalObj[key] = value?.id || null;
              if (item?.searchVariable === 'sdf' || item?.searchVariable === 'insurance') {
                ops = value?.id ? [{ value: value?.id, label: value?.name, agency: value?.agency, date: value?.date }] : [];
              } else if (item?.searchVariable === 'hr') {
                ops = value?.id ? [{ value: value?.hr_id, label: value?.name, agency: value?.agency, date: value?.date }] : [];
                finalObj[key] = value?.hr_id || null;
              } else {
                ops = value?.id ? [{ value: value?.id, label: value?.name }] : [];
              }
            }
            let optionKey = item?.variable;
            let optionValues = options[optionKey] || [];
            let storeKey = item?.searchVariable;
            let storeValues = allOptions[storeKey] || [];
            options = {
              ...options,
              [optionKey]: [
                ...optionValues,
                ...ops,
              ],
            };
            allOptions = {
              ...allOptions,
              [storeKey]: [
                ...storeValues,
                ...ops,
              ],
            };
          } else if (item?.type === 'dropdown') {
            let yesNoList = false;
            let optionList = item?.options || [];
            optionList = optionList.map(o => (o?.label || "").toLowerCase());
            if (optionList.includes('yes') && optionList.includes('no')) {
              yesNoList = true;
            }
            if (yesNoList && value === 1) {
              finalObj[key] = 'Yes';
            } else if (yesNoList && value === 0) {
              finalObj[key] = 'No';
            } else {
              if (_.isObject(value)) {
                finalObj[key] = value != null ? value?.id.toString() : "";
              } else {
                finalObj[key] = value != null ? value.toString() : "";
              }

            }
          } else if (item?.type === 'date') {
            let dateValue = moment(value).format("YYYY-MM-DD");
            finalObj[key] = value && dateValue !== 'Invalid date' ? dateValue : null;
          } else if (item?.type === 'plan') {
            let plans = [];
            let ops = [];
            if (item?.multiple) {
              value?.forEach(val => {
                if (val?.id) {
                  let dateKey = item.variable + "" + (val?.name || "");
                  plans.push(val.id);
                  let dateValue = moment(val?.effective_from).format("YYYY-MM-DD");
                  finalObj[dateKey] = dateValue !== 'Invalid date' ? dateValue : null;
                }
              });
              finalObj[key] = plans;
              ops = value?.length ? value.map(item => ({ value: item?.id, label: item?.name })) : [];
            } else {
              finalObj[key] = value?.id || null;
              let dateKey = item.variable + "" + (value?.name || "");
              let dateValue = moment(value?.effective_from).format("YYYY-MM-DD");
              finalObj[dateKey] = dateValue !== 'Invalid date' ? dateValue : null;
              ops = value?.id ? [{ value: value?.id, label: value?.name }] : [];
            }
            let optionKey = item?.variable;
            let optionValues = options[optionKey] || [];
            let storeKey = item?.searchVariable;
            let storeValues = allOptions[storeKey] || [];
            options = {
              ...options,
              [optionKey]: [
                ...optionValues,
                ...ops,
              ],
            };
            allOptions = {
              ...allOptions,
              [storeKey]: [
                ...storeValues,
                ...ops,
              ],
            };
          } else {
            finalObj[key] = value || "";
          }
        }
      }
    })
  });
  finalObj = {
    ...finalObj,
    ...existingData,
  };
  const finalFormatedData = {
    finalObj,
    options,
    allOptions,
  }
  return finalFormatedData;
}

export const formatToCamelCase = (str = "") => {
  return str
    .replace(/\s(.)/g, a => {
      return a.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/, b => {
      return b.toLowerCase();
    });
}

export const getOptionFromList = (list = [], value = "") => {
  for (let i = 0; i < list.length; i++) {
    if (list[i]?.value === value) {
      return list[i];
    }
  }
  return null;
}