import React, { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getlogs } from '../../../store/Settings/action';
import { OverlayLoader } from '../Settings/SettingsScreen';
import { getRequestOptionsExportLogs } from '../../../store/RequestOptions/actions';
import { toast } from 'react-toastify';

const RequestOptionsExportModal = ({ open, handleModalClose }) => {
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("");
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setStartDate("");
		setEndDate("");
		setLoading(false);
		handleModalClose();
	}

	const handleDownload = async () => {
		setLoading(true);
		let res = await getRequestOptionsExportLogs(startDate, endDate);
		setLoading(false);
		if (res?.data?.url) {
			window.open(`${res.data.url}`, "_blank")
		} else {
			toast.error('Failed to generate file!');
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={"sm"}
		>
			<DialogTitle>Export Settings Data</DialogTitle>
			<DialogContent>
				{loading ? <OverlayLoader /> : null}
				<Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pt={2} gap={2}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							renderInput={(inputProps) =>
								<TextField
									label="Start Date"
									InputLabelProps={{ shrink: true }}
									type="date"
									variant="outlined"
									onChange={async (e) => {
										setStartDate(e.target.value);
									}}
									id='start-date-input'
								/>}
						/>
						<DatePicker
							renderInput={(inputProps) =>
								<TextField
									label="End Date"
									InputLabelProps={{ shrink: true }}
									type="date"
									variant="outlined"
									onChange={async (e) => {
										setEndDate(e.target.value);
									}}
									id='end-date-input'
								/>}
						/>
					</LocalizationProvider>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button id='close-modal-btn' onClick={handleClose}>Close</Button>
				<Button id='download-modal-btn' variant='contained' onClick={handleDownload}>Download</Button>
			</DialogActions>
		</Dialog>
	)
}

export default RequestOptionsExportModal;