import { GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_FAILED, GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_IN_PROGRESS, GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_SUCCESS } from "../../constants/store";

// The initial state
export const initialState = {
  loading: true,
  pendingCount: 0,
};

function pendingRequestOptionsNotificationReducer(state = initialState, action){
  switch (action.type) {
    // pending notifications
    case GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_SUCCESS: {
      const newState = {
          ...state,
          pendingCount: action.payload.count,
          loading: false,
      };
      return newState;
    }
    case GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    default:
      return state;
  }
}
export default pendingRequestOptionsNotificationReducer;
