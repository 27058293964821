import React, { useRef } from "react";
import AppBar from "../../../components/AppBar";
import SearchBar from "../../../components/SearchBar";
import StatusMaster from "./StatusMaster";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Box
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  listFilterCandidates,
} from "../../../store/actions/CandidateAction";
import SelectDropdown from "../../../components/MasterDataModule/SelectDropdown";
import { statusDropdown, statusDropdownConsultant } from "../../../constants/config";
import DateSelection from "../../../components/MasterDataModule/DateSelection";
import moment from "moment";
import { toast } from "react-toastify";
import TuneIcon from "@mui/icons-material/Tune";
import { API_BASE_URL } from "../../../constants/constants";
import { ensureSelectableOptions } from "../../../utils/ensureSelectOptions";
import axios from "axios";
import { ensureCreatableOptionArray } from "../../../helpers/ensureOptionArray";
import { getClientAction } from "../../../store/actions/ClientActions";
import MultiSelectWithChipDropdown from "../../../components/MasterDataModule/MultiSelectWithChipDropdown";
import _ from "lodash";
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

export default function CandidatesScreenMaster(props) {
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix, { identifier: getCurrentPageIdentifier() });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState(false);
  const [selectableOptions, setSelectableOptions] = useState([]);

  const [filteroptions, setFilterOptions] = useState({
    offset: 0,
    limit: 100,
    contractor: [],
    contract_status: [],
    contractor_status: [],
    entity: [],
    client: [],
    draft: false,
    search: "",
    sortKey: "can.updated_at",
    sortValue: "desc",
  });
  const contractData = useSelector((state) => {
    return state.contractDetails.contract;
  });
  const [contractStatus, setContractStatus] = useState({
    contractStatus: contractData?.contract_status,
  });
  const clientsData = useSelector((state) => state.clients.client);
  const ensureClientData =
    clientsData?.length > 0
      ? ensureCreatableOptionArray(clientsData, true)
      : [];
  const [statusDialog, setStatusDialog] = useState(false);
  const [effectiveDate, seteffectiveDate] = useState({
    effectiveFrom: contractData?.effectiveFrom || moment.utc().format("MM/DD/YYYY"),
  });

  const handleAddForm = () => {
    setStatusDialog(true);
  };

  const history = useHistory();

  useEffect(() => {
    if (
      contractData?.effectiveFrom &&
      moment.utc(contractData?.effectiveFrom).isValid
    ) {
      seteffectiveDate({
        effectiveFrom: moment.utc(contractData?.effectiveFrom).format(
          "MM/DD/YYYYY"
        ),
      });
    }
  }, [contractData]);

  useEffect(() => {
    getData();
    dispatch(getClientAction())
  }, []);

  const getData = async () => {
    await axios.get(`${API_BASE_URL}?tab_id=1`).then((res) => {
      if (res && res?.data) {
        const allSelectOptions = ensureSelectableOptions(res?.data);
        setSelectableOptions(allSelectOptions);
      }
    });
  }

  useEffect(() => {
    dispatch(
      listFilterCandidates({
        offset: 0,
        limit: 100,
        sortKey: "can.updated_at",
        sortValue: "desc",
        search: `${searchTerm}`,
        contractor:
          filteroptions?.contractor.map((item) => item?.value) || [],
        contract_status:
          filteroptions?.contract_status.map((item) => item?.label) || [],
        contractor_status:
          filteroptions?.contractor_status.map((item) => item?.value) || [],
        draft: filteroptions?.contract_status.some(
          (item) => item?.label === "Draft"
        ),
        entity: filteroptions?.entity.map((item) => item?.value) || [],
        client: filteroptions?.client.map((item) => item?.label) || [],
      })
    );
  }, [searchTerm]);

  const handleStatusDialogClose = () => {
    setStatusDialog(!statusDialog);
  };

  const handleStatusUpdates = () => {
    if (!contractStatus?.contractStatus) {
      setContractStatus({
        ...contractStatus,
        contractStatus: "",
      });
      toast.error("Please select contract status",
        { position: toast.POSITION.TOP_CENTER, toastId: "contractStatus" });
      return;
    } else {
      setStatusDialog(false);
      history.push({
        pathname: '/consultants/candidateform',
        state: {
          action: "add",
          editType: "candidate",
          status: contractStatus.contractStatus,
          effectiveDate: effectiveDate?.effectiveFrom || ""
        }
      });
    }
  };

  const filterCall = (filter) => {
    let d = filter || filteroptions
    const payload = {
      offset: d?.offset || 0,
      limit: d?.limit || 100,
      contractor: d?.contractor.map((item) => item?.value) || [],
      contract_status:
        d?.contract_status.map((item) => item?.label) || [],
      contractor_status:
        d?.contractor_status.map((item) => item?.value) || [],
      entity: d?.entity.map((item) => item?.value) || [],
      client: d?.client.map((item) => item?.label) || [],
      draft: d?.contract_status.some((item) => item?.label === "Draft"),
      search: `${searchTerm}`,
      sortKey: "can.updated_at",
      sortValue: "desc",
    };
    dispatch(listFilterCandidates(payload));
  }

  return (
    <Box sx={{ width: '100%', minHeight: '100svh', backgroundColor: '#fff', position: 'relative', p: 2 }}>
      <AppBar appBarTitle='Consultants' >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <SearchBar
              placeholder={"Search with Name, Email and PLC code"}
              onClick={() => { }}
              setSearchTerm={(searchTerm) => setSearchTerm(searchTerm)}
              value={searchTerm}
            />
            <Tooltip id="filter-tooltip" title="Filters">
              <IconButton
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  textTransform: 'none',
                  color: '#000',
                  gap: 2,
                }}
                onClick={() => {
                  setFilter((prev) => !prev);
                }}
                id="filter-btn"
              >
                <TuneIcon />
              </IconButton>
            </Tooltip>
          </Box>
          {hasWritePermission ?
            <Button variant="contained" onClick={handleAddForm}>Add new consultant</Button>
            :
            null
          }
        </Box>
      </AppBar>
      <StatusMaster
        searchTerm={searchTerm}
        filteroptions={filteroptions}
        setFilterOptions={setFilterOptions}
        filterCall={filterCall}
      />
      <Dialog
        open={statusDialog}
        onClose={handleStatusDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Confirm details</DialogTitle>
        <DialogContent style={{ minWidth: 550 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <SelectDropdown
                fieldName='contractStatus'
                setFields={setContractStatus}
                inputName='Status*'
                required={true}
                options={statusDropdownConsultant}
                readOnly={false}
                value={contractStatus?.contractStatus}
                validateForm={!contractStatus?.contractStatus}
                id='contract-status-dropdown'
              />
            </Grid>
            <Grid item xs={6}>
              <DateSelection
                name='Effective Date'
                setFields={seteffectiveDate}
                fieldName='effectiveFrom'
                fieldValue={effectiveDate?.effectiveFrom}
                validateForm={!effectiveDate?.effectiveFrom}
                id='date-selection-dropdown'
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusDialogClose}>Close</Button>
          <Button onClick={handleStatusUpdates} autoFocus>
            Next
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth='md'
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            height: "50%",
            margin: 0,
            borderRadius: 2,
          },
        }}
        open={filter}
        onClose={() => setFilter(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>Filter options</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MultiSelectWithChipDropdown
                    inputName='Contract Status'
                    fieldName='contract_status'
                    setFields={setFilterOptions}
                    fieldValue={filteroptions?.client}
                    value={filteroptions?.contract_status}
                    options={statusDropdown}
                    readOnly={false}
                    id='filter-contract-status-dropdown'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MultiSelectWithChipDropdown
                    inputName='Contractor Type'
                    fieldName='contractor_status'
                    setFields={setFilterOptions}
                    fieldValue={filteroptions?.contractor_status}
                    options={selectableOptions["contractStatusOptions"]}
                    value={filteroptions?.contractor_status}
                    readOnly={false}
                    id='filter-contract-type-dropdown'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MultiSelectWithChipDropdown
                    inputName='Entity'
                    fieldName='entity'
                    setFields={setFilterOptions}
                    fieldValue={filteroptions?.entity}
                    value={filteroptions?.entity}
                    options={selectableOptions["entityOptions"]}
                    readOnly={false}
                    id='filter-entity-dropdown'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MultiSelectWithChipDropdown
                    inputName='Client'
                    fieldName='client'
                    setFields={setFilterOptions}
                    fieldValue={filteroptions?.client}
                    value={filteroptions?.client}
                    options={ensureClientData}
                    readOnly={false}
                    id='filter-client-dropdown'
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MultiSelectWithChipDropdown
                    inputName='Vendor'
                    fieldName='contractor'
                    setFields={setFilterOptions}
                    fieldValue={filteroptions?.contractor}
                    value={filteroptions?.contractor}
                    options={selectableOptions["contractor"]}
                    readOnly={false}
                    id='filter-vendor-dropdown'
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            "& .MuiDialogActions-root": {
              padding: "20px 24px",
            },
          }}
        >
          <Button id='filter-close-btn' onClick={() => setFilter(false)}>Close</Button>
          <Button
            id='filter-clear-btn'
            variant="outlined"
            onClick={() => {
              setFilterOptions({
                offset: 0,
                limit: 100,
                contractor: [],
                contract_status: [],
                contractor_status: [],
                entity: [],
                client: [],
                sortKey: "can.updated_at",
                sortValue: "desc",
                draft: false,
              });
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setFilter(false);
              setFilterOptions({
                ...filteroptions,
                offset: 0,
                limit: 100,
                sortKey: "can.updated_at",
                sortValue: "desc",
              });
              filterCall();
            }}
            id='filter-apply-btn'
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
