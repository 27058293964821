import {
  CR_NOTIFICATION_FAILED,
  CR_NOTIFICATION_IN_PROGRESS,
  CR_NOTIFICATION_SUCCESS,
} from "../../../constants/store";

const initialState = {
  loading: false,
  success: false,
  error: false,
  changeRequestNotificationCount: 0,
};
function ChangeRequestNotificationReducer(state = initialState, action) {
  switch (action.type) {
    case CR_NOTIFICATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case CR_NOTIFICATION_SUCCESS: {
      const newState = {
        ...state,
        success: true,
        loading: false,
        changeRequestNotificationCount: action.payload.count,
      };
      return newState;
    }
    case CR_NOTIFICATION_FAILED: {
      const newState = {
        ...state,
        error: true,
        loading: false,
      };
      return newState;
    }

    default:
      return state;
  }
}

export default ChangeRequestNotificationReducer;
