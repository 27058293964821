export const getCurrentPageIdentifier = () => {
  const ids = (window?.location?.pathname || "").split("/");
  const id = ids.length ? ids[1] : "";
  if (id) {
    let oldId = localStorage.getItem("sectionId") || null;
    if (oldId !== id) {
      localStorage.setItem("sectionId", id);
    }
  };
  return id;
}