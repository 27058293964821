import {
  ADD_CONTRACT_INFORMATION_FAILED,
  ADD_CONTRACT_INFORMATION_IN_PROGRESS,
  ADD_CONTRACT_INFORMATION_SUCCESS,
  CLEAR_CONTRACT_INFORMATION_FAILED,
  CLEAR_CONTRACT_INFORMATION_SUCCESS,
  GET_CONTRACT_INFORMATION_FAILED,
  GET_CONTRACT_INFORMATION_IN_PROGRESS,
  GET_CONTRACT_INFORMATION_SUCCESS,
  GET_DOCUMENTS_FOR_CONTRACT_FAILED,
  GET_DOCUMENTS_FOR_CONTRACT_IN_PROGRESS,
  GET_DOCUMENTS_FOR_CONTRACT_SUCCESS,
  GET_USER_CONFIGURATION_FAILED,
  GET_USER_CONFIGURATION_IN_PROGRESS,
  GET_USER_CONFIGURATION_SUCCESS,
} from "../../constants/store";
import api from "../../config/https";
import { convertDataToContractInformationRequirement } from "../../utils/consultantConstants";
import _ from "lodash";

export const getUserConfiguration = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_CONFIGURATION_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/user/getGroupConfiguratorByUser/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_USER_CONFIGURATION_SUCCESS,
        payload: res?.data?.data?.userGroup?.configuration || []
      });
    } else {
      dispatch({
        type: GET_USER_CONFIGURATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_USER_CONFIGURATION_FAILED,
    });
  }
};


export const getContractInformation = (payload, callback) => async (dispatch) => {
  const existingData = payload?.data || {};
  const config = payload?.config || [];
  try {
    dispatch({
      type: GET_CONTRACT_INFORMATION_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/v2/contract/${payload.id}`);
    if (res && res.data && res.data.status) {
      const data = res?.data?.data || {};
      let cD = convertDataToContractInformationRequirement(data, existingData, config);
      let { finalObj, options, allOptions } = cD;
      dispatch({
        type: GET_CONTRACT_INFORMATION_SUCCESS,
        payload: {
          info: finalObj,
          options,
          allOptions,
        },
      });
    } else {
      dispatch({
        type: GET_CONTRACT_INFORMATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_CONTRACT_INFORMATION_FAILED,
    });
  }
};

export const uploadDocument = async (payload) => {
  try {
    let res = await api.post(`/masterAdmin/createDocumentForContract`, payload);
    return res;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const deleteDocument = async (payload) => {
  try {
    let res = await api.delete(`/masterAdmin/deleteDocument/${payload.id}`);
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const getDocumentForContract = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCUMENTS_FOR_CONTRACT_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/getDocumentForContract/${payload.id}`);
    if (res && res.data && res.data.status) {
      dispatch({
        type: GET_DOCUMENTS_FOR_CONTRACT_SUCCESS,
        payload: {
          documents: res?.data?.data?.documents || [],
        },
      });
    } else {
      dispatch({
        type: GET_DOCUMENTS_FOR_CONTRACT_FAILED,
      });
    }
    if (callback) {
      callback(res);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_DOCUMENTS_FOR_CONTRACT_FAILED,
    });
  }
};

export const requestOption = async (payload) => {
  try {
    let res = await api.post(payload.url, payload.data);
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const saveContractInformation = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CONTRACT_INFORMATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/v2/contract?contractId=${payload?.contractId || ""}&candidateId=${payload?.candidateId || ""}&onlyCandidate=${payload?.onlyCandidate || false}`, payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_SUCCESS,
      });
    } else {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: ADD_CONTRACT_INFORMATION_FAILED,
    });
  }
};


export const saveRecruitmentContractInformation = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CONTRACT_INFORMATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/v2/contract/recuitmentDraft?contractId=${payload?.contractId || ""}&candidateId=${payload?.candidateId || ""}&isNotRecuitmentDraft=${payload?.isNotRecuitmentDraft || false}`, payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_SUCCESS,
      });
    } else {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: ADD_CONTRACT_INFORMATION_FAILED,
    });
  }
};

export const sendRecruitmentContractInformation = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CONTRACT_INFORMATION_IN_PROGRESS,
    });
    let res = await api.put(`masterAdmin/contracts/recruitmentDraft/${payload?.contractId || ""}`);
    if (res && res.data && res.data.status) {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_SUCCESS,
      });
    } else {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: ADD_CONTRACT_INFORMATION_FAILED,
    });
  }
};

export const getContractReferralInformation = (payload, callback) => async (dispatch) => {
  const config = payload?.config || [];
  try {
    dispatch({
      type: GET_CONTRACT_INFORMATION_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/referral/${payload.id}`);
    if (res && res.data && res.data.status) {
      const data = res?.data?.data || {};
      let cD = convertDataToContractInformationRequirement(data, {}, config);
      let { finalObj, options, allOptions } = cD;
      dispatch({
        type: GET_CONTRACT_INFORMATION_SUCCESS,
        payload: {
          info: finalObj,
          options,
          allOptions,
        },
      });
    } else {
      dispatch({
        type: GET_CONTRACT_INFORMATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_CONTRACT_INFORMATION_FAILED,
    });
  }
};

export const addContractReferralInformation = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CONTRACT_INFORMATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/referral`, payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_SUCCESS,
      });
    } else {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: ADD_CONTRACT_INFORMATION_FAILED,
    });
  }
};

export const updateContractReferralInformation = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_CONTRACT_INFORMATION_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/referral/${payload.id}`, payload.data);
    if (res && res.data && res?.data?.status) {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_SUCCESS,
      });
    } else {
      dispatch({
        type: ADD_CONTRACT_INFORMATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: ADD_CONTRACT_INFORMATION_FAILED,
    });
  }
};

export const resetContractInformation = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_CONTRACT_INFORMATION_SUCCESS,
    });
  } catch (e) {
    console.log(e);
    dispatch({
      type: CLEAR_CONTRACT_INFORMATION_FAILED,
    });
  }
};
