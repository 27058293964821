import { Box, Grid ,Typography } from "@mui/material";
import React from "react";
import StarIcon from "@mui/icons-material/Star";
import { blue, grey } from "@mui/material/colors";
import moment from "moment";

const Release = ({description="",keyFeaturesTitle="",keyFeatures=[],type='',version='',releaseDate=""}) => {
  return (
    <Grid container spacing={2} my={2}>
      <Grid item xs={2.5} sx={{display:'flex',flexDirection:'column',alignItems:'flex-end',borderRight:'1px solid grey',pr:2}}>
        <Box
            sx={{
              bgcolor: grey[300],
              display: "flex",
              alignItems:'center',
              justifyContent: "flex-end",
              py:0.5,
              px:1,
              borderRadius:1,
              gap:1,
              flexWrap:'wrap'
            }}
          >
            <Typography variant="subtitle2">{type} release</Typography>
            <Typography sx={{color:'#1089FF'}} variant="body1">{version}</Typography>
          </Box>
          {releaseDate ? <Typography sx={{pt:0.5}} color='#1089FF' variant="subtitle2">{moment(releaseDate).format('MMMM Do YYYY')}</Typography> :null}
      </Grid>
      <Grid item xs={9.5}>
        <Typography variant="body1">{description}</Typography>
        <Box sx={{ pt: 2 }}>
          <Typography variant="h6">{keyFeaturesTitle}</Typography>
          {keyFeatures.map((keyFeature,index)=>{
            return (
              <Box sx={{ display: "flex", alignItems: "center", pt: 1 }} key={index}>
                <StarIcon sx={{color:'#1089FF',fontSize:16}} />
                <Typography sx={{ pl: 1 }} variant="body1">{keyFeature}</Typography>
              </Box>
            )
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Release;
