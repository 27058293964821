import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "../../../config/https";
import { useDispatch, useSelector } from "react-redux";
import { BACKEND_URL } from "../../../constants/constants";
import SelectDropdown from "../../../components/MasterDataModule/SelectDropdown";
import {
  statusDropdown,
} from "../../../constants/config";
import { convertToServerTime } from "../../../helpers/timezone";
import DateSelection from "../../../components/MasterDataModule/DateSelection";
import {
  updateContractStatus,
} from "../../../store/actions/ContractUpdateActions";
import { toast } from "react-toastify";
import { renderRecruitmentDraftStatus } from "../../../utils/consultantConstants";
import { ArrowBack } from "@mui/icons-material";
import { blue } from "@mui/material/colors";
import _ from "lodash";
import { resetContractInformation } from "../../../store/ConsultantForm/action";
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

function Contracts() {
  const history = useHistory();
  const { currentUser = null } = useSelector(state => state.auth);
  let identifier = getCurrentPageIdentifier();
  let statusMenus = [...statusDropdown];
  if (identifier === 'recruitmentDrafts') {
    statusMenus = statusMenus.filter(item => item.label === 'Recruitment Draft');
  } else if (identifier === 'pendingRecruitmentDrafts') {
    statusMenus = statusMenus.filter(item => item.label === 'Draft');
  } else {
    statusMenus = statusMenus.filter(item => item.label !== 'Recruitment Draft');
  }
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: identifier });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  const [candidateContracts, setCandidateContracts] = useState([]);
  const dispatch = useDispatch();
  let [isEditcontract, setIsEditcontract] = useState({
    isEdit: false,
    contract: {},
  });

  const contractData = useSelector((state) => {
    return state.contractDetails.contract;
  });

  const [contractStatus, setContractStatus] = useState({});
  const [statusOptions, setStatusOptions] = useState(statusMenus);

  const [effectiveDate, seteffectiveDate] = useState({
    effectiveFrom:
      contractData?.effectiveFrom || moment.utc().format("MM/DD/YYYY"),
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      contractData?.effectiveFrom &&
      moment.utc(contractData?.effectiveFrom).isValid
    ) {
      seteffectiveDate({
        effectiveFrom: moment
          .utc(contractData?.effectiveFrom)
          .format("MM/DD/YYYYY"),
      });
    }
    let status = "";
    if (identifier === 'recruitmentDrafts') {
      status = 'Recruitment Draft';
    } else if (identifier === 'pendingRecruitmentDrafts') {
      status = 'Draft';
    }
    setContractStatus({ contractStatus: status });
  }, [contractData]);

  const [statusDialog, setStatusDialog] = useState(false);
  useEffect(() => {
    getURLParams();
  }, []);

  const getURLParams = () => {
    const pathName = window?.location?.pathname || "";
    const getIdFromUrl = pathName?.substring(pathName.lastIndexOf("/") + 1);
    localStorage.setItem("contractCandidateId", getIdFromUrl);
  };

  const reOrderWithActiveStatus = (contracts) => {
    const getActiveContract = contracts?.filter(
      (contract) => contract.is_active
    );
    const getInActiveContract = contracts?.filter(
      (contract) => !contract.is_active
    );
    const reArrangedArray = [...getActiveContract, ...getInActiveContract];
    generateChild(reArrangedArray);
  };

  const fetchCandidateContracts = async (id) => {
    setLoading(true);
    let res = await api.get(
      `${BACKEND_URL}/masterAdmin/contracts/candidate/${id}`
    );
    if (res?.data?.data) {
      reOrderWithActiveStatus(res?.data?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    const pathName = window?.location?.pathname || "";
    const getIdFromUrl = pathName.substring(pathName.lastIndexOf("/") + 1);
    fetchCandidateContracts(getIdFromUrl);
    dispatch(resetContractInformation());
  }, []);

  const _handleAddContract = (contract) => {
    setStatusDialog(true);
    setIsEditcontract({ isEdit: false, contract });
  };

  const ContractCards = ({ setIsEditcontract, contract, allowedContractEdit = false }) => {
    const _handleEditContract = () => {
      setStatusDialog(true);
      setIsEditcontract({ isEdit: true, contract });
      if (identifier === 'recruitmentDrafts') {
        setContractStatus({ contractStatus: 'Recruitment Draft' });
      } else if (identifier === 'pendingRecruitmentDrafts') {
        setContractStatus({ contractStatus: 'Draft' });
      } else {
        setContractStatus({ contractStatus: contract?.contract_status || '' })
      }
    };

    const _handleRevisionContract = (id) => {
      history.push(`/${identifier}/revision/${contract.root_contract_id || id}/${id}`);
    };

    const _handleAddReferral = () => {
      history.push({
        pathname: `/${identifier}/referral`,
        state: {
          action: 'add',
          id: contract?.id || "",
          status: "Referral",
        }
      });
    };

    let allowedEdit = true;

    if (identifier === 'recruitmentDrafts' && contract?.recruitment_draft_status !== 0 && contract?.recruitment_draft_status !== 1) {
      allowedEdit = false;
    } else if (contract?.status === 4) {
      allowedEdit = false;
      if (contract?.createdById === currentUser?.id && contract?.contract_status !== 'Active') {
        allowedEdit = true;
      }
    }

    return (
      <React.Fragment>
        <CardContent
          style={{
            background:
              contract?.status === 5
                ? "#C5DFF8"
                : contract?.status === 1
                  ? blue[500]
                  : "#eff1f5",
            color: contract?.status === 1 ? "#FFFFFF" : "#FFFFFF",
            borderRadius: 4,
            position: "relative",
          }}
        >
          <Grid container>
            <Grid item xs={3}>
              <Typography
                color='text.secondary'
                gutterBottom
                style={{
                  color:
                    contract?.status === 5
                      ? "#000000"
                      : contract?.status === 1
                        ? "#FFFFFF"
                        : "",
                  fontWeight: 300,
                }}
              >
                <strong>Contract Status: </strong>
                <br />
                {renderRecruitmentDraftStatus(contract.recruitment_draft_status) || contract?.contract_status || ""}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color:
                    contract?.status === 5
                      ? "#000000"
                      : contract?.status === 1
                        ? "#FFFFFF"
                        : "",
                  fontWeight: 300,
                }}
                sx={{ mb: 1.5 }}
                color='text.secondary'
              >
                <strong>Contractor type: </strong>
                <br />
                {contract?.contractor_status}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color:
                    contract?.status === 5
                      ? "#000000"
                      : contract?.status === 1
                        ? "#FFFFFF"
                        : "",
                  fontWeight: 300,
                }}
                sx={{ mb: 1.5 }}
                color='text.secondary'
              >
                <strong>Business entity: </strong>
                <br />
                {contract?.entity}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{
                  color:
                    contract?.status === 5
                      ? "#000000"
                      : contract?.status === 1
                        ? "#FFFFFF"
                        : "",
                  fontWeight: 300,
                }}
                sx={{ mb: 1.5 }}
                color='text.secondary'
              >
                <strong>Job title: </strong>
                <br />
                {contract?.job_title}
              </Typography>
            </Grid>
          </Grid>

          <span
            style={{
              position: "absolute",
              right: 0,
              display: "inline-flex",
              padding: "3px 7px",
              borderTopLeftRadius: "3px",
              borderBottomLeftRadius: "3px",
              top: 15,
              background:
                contract?.status === 1
                  ? "#44ae2b"
                  : contract?.status === 5
                    ? "#44ae2b"
                    : "#cbcbcb",
            }}
          >
            {contract?.status === 1
              ? "Current"
              : contract?.status === 5
                ? "Current"
                : contract?.status === 4
                  ? "Pending"
                  : "Old"}
          </span>
          {contract?.has_referral && identifier !== 'recruitmentDrafts' && identifier !== 'pendingRecruitmentDrafts' ?
            <span
              style={{
                position: "absolute",
                right: 0,
                display: "inline-flex",
                padding: "3px 7px",
                borderTopLeftRadius: "3px",
                borderBottomLeftRadius: "3px",
                marginTop: "35px",
                top: 15,
                background: "#FFA41B",
              }}
            >
              Referral
            </span>
            :
            null
          }
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <Grid container>
            <Grid item xs={8} sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  color: contract?.status === 1 ? "#1a1a1a" : "",
                  fontSize: 14,
                }}
                sx={{ mb: 0 }}
                color='text.secondary'
              >
                <strong>Contract updated on: </strong>
                <em>
                  {contract?.created_at
                    ? convertToServerTime(
                      contract?.created_at,
                      "DD MMM YYYY, HH:MM A"
                    )
                    : convertToServerTime(new Date(), "DD MMM YYYY, HH:MM A")}
                </em>
              </Typography>
            </Grid>
            <Grid
              item
              xs={4}
              sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}
            >
              {contract?.contract_status === "Active" &&
                !contract?.has_referral ? (
                <>
                  {hasWritePermission && identifier !== 'recruitmentDrafts' && identifier !== 'pendingRecruitmentDrafts' ?
                    <Button
                      disabled={contract?.contract_status !== "Active"}
                      onClick={_handleAddReferral}
                      size='small'
                      style={{
                        color:
                          contract?.contract_status === "Active"
                            ? "#10316B"
                            : "#cbcbcb",
                      }}
                    >
                      Add Referral
                    </Button>
                    :
                    null
                  }
                </>
              ) : (
                <>
                  {contract?.has_referral && identifier !== 'recruitmentDrafts' && identifier !== 'pendingRecruitmentDrafts' ? (
                    <Button
                      onClick={() => history.push(`/${identifier}/view-referral/${contract?.id}`)}
                      size='small'
                      style={{
                        color: "#10316B",
                      }}
                      id={`view-referral-btn-${contract.id}`}
                    >
                      View Referral
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              )}
              <Button
                disabled={!allowedContractEdit || !allowedEdit || !hasWritePermission}
                onClick={_handleEditContract}
                size='small'
                style={{ color: allowedContractEdit && allowedEdit && hasWritePermission ? "#10316B" : "#cbcbcb", }}
                id={`edit-contract-btn-${contract.id}`}
              >
                Edit
              </Button>
              <Button
                size='small'
                onClick={() => _handleRevisionContract(contract.id)}
                style={{
                  color: "#10316B",
                }}
                id={`view-contract-btn-${contract.id}`}
              >
                View
              </Button>
              {/* <Button
                disabled={contract?.status !== 1}
                size="small"
                onClick={_handleDownloadContractReport}
                style={{
                  color: contract?.status === 1 ? "#10316B" : "#cbcbcb",
                }}
              >
                Download report
              </Button> */}
            </Grid>
          </Grid>
        </CardActions>
      </React.Fragment>
    );
  };

  const generateChild = (reArrangedArray) => {
    let contractsArray = reArrangedArray.sort((a, b) => new Date(a.created_at) > new Date(b.created_at) ? -1 : 1);
    let rootExist = [], noRootExist = [];
    contractsArray.map((rA) => {
      if (rA.root_contract_id) {
        rootExist.push(rA);
      } else {
        noRootExist.push(rA);
      }
    });
    for (let i = 0; i < noRootExist.length; i++) {
      for (let j = 0; j < rootExist.length; j++) {
        if (rootExist[j].root_contract_id === noRootExist[i].id) {
          noRootExist[i] = rootExist[j];
        }
      }
    };
    let newOptions = [...statusOptions];
    if (noRootExist.length) {
      const finalStatus = noRootExist[0].contract_status;
      if (finalStatus === 'In-Process' || finalStatus === 'Active' || finalStatus === 'In-Active' || finalStatus === 'Dropped') {
        newOptions = newOptions.filter(item => item.label !== 'Draft');
      }
    }
    setStatusOptions(newOptions);
    setCandidateContracts(noRootExist);
  };

  const handleStatusDialogClose = () => {
    setStatusDialog(!statusDialog);
    setIsEditcontract({ isEdit: false, contract: {} });
  };

  const updateDroppedStatus = (contractId, status) => {
    dispatch(updateContractStatus({ contractId, status })).then((res) => {
      if (res?.data?.message === "Success") {
        window.location.reload()
      } else {
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
          toastId: "contractStatus",
        });
      }
    });
  };

  const handleStatusUpdates = () => {
    const status = contractStatus?.contractStatus
      ? contractStatus?.contractStatus
      : isEditcontract?.contract?.contract_status;
    let path = `/${identifier}/candidateform`;
    if (!status || status === "") {
      setContractStatus({ ...contractStatus, contractStatus: "" });
      toast.error("Please select contract status", {
        position: toast.POSITION.TOP_CENTER,
        toastId: "contractStatus",
      });
    } else if (status === "Dropped") {
      handleStatusDialogClose(false);
      updateDroppedStatus(isEditcontract?.contract?.id, status);
    } else if (isEditcontract?.isEdit) {
      handleStatusDialogClose(false);
      const pathName = history.location.pathname;
      history.push({
        pathname: path,
        state: {
          action: "edit",
          editType: "contract",
          id: isEditcontract?.contract?.id || "",
          candidateId: pathName.substring(pathName.lastIndexOf("/") + 1),
          status: contractStatus?.contractStatus || isEditcontract?.contract?.contract_status || "Draft",
          effectiveDate: effectiveDate?.effectiveFrom || ""
        }
      });
    } else {
      handleStatusDialogClose(false);
      const pathName = history.location.pathname;
      history.push({
        pathname: path,
        state: {
          action: "add",
          editType: "contract",
          id: isEditcontract?.contract?.id || "",
          candidateId: pathName.substring(pathName.lastIndexOf("/") + 1),
          status: contractStatus?.contractStatus || "Draft",
          effectiveDate: effectiveDate?.effectiveFrom || ""
        }
      });
    }
  };

  const handleGoBack = () => {
    history.goBack();
  }

  return (
    <Box id="contract-screen" sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 1, zIndex: 999 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
          <IconButton id='exit-btn' onClick={handleGoBack} sx={{ width: 35, height: 35, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
            <ArrowBack />
          </IconButton>
          <Typography variant='subtitle2' sx={{ color: blue[500], fontSize: 20 }}>All contracts</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
          {identifier === 'recruitmentDrafts' || identifier === 'pendingRecruitmentDrafts' ?
            null
            :
            hasWritePermission ?
              <Button onClick={() => _handleAddContract(candidateContracts?.length ? candidateContracts[0] : null)} variant="contained" sx={{ textTransform: 'none', display: 'flex' }}><Typography variant="subtitle2">Add a new contract</Typography></Button>
              :
              null
          }
        </Box>
      </Box>
      <Box sx={{ marginTop: 2, }}>
        <Grid container spacing={2}>
          {candidateContracts && candidateContracts.length > 0 ?
            candidateContracts.map((contract, contractIndex) => {
              return (
                <Grid item xs={12}>
                  <Card
                    style={{
                      borderRadius: 4,
                    }}
                  >
                    <ContractCards
                      setIsEditcontract={setIsEditcontract}
                      contract={contract}
                      allowedContractEdit={contractIndex === 0}
                    />
                  </Card>
                </Grid>
              )
            }) : (
              <Grid item xs={12}>
                <NoContracts loading={loading} />
              </Grid>
            )}
        </Grid>
      </Box>
      <Dialog
        open={statusDialog}
        onClose={handleStatusDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>Confirm details</DialogTitle>
        <DialogContent style={{ minWidth: 550 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              {/* If contract status is any state otherthan draft, then Draft
              option should not be list in options*/}
              <SelectDropdown
                fieldName='contractStatus'
                setFields={setContractStatus}
                inputName='Contract status*'
                required={true}
                options={statusOptions}
                readOnly={false}
                value={contractStatus?.contractStatus}
              />
            </Grid>
            <Grid item xs={6}>
              <DateSelection
                name='Effective Date'
                setFields={seteffectiveDate}
                fieldName='effectiveFrom'
                fieldValue={effectiveDate?.effectiveFrom}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleStatusDialogClose}>Close</Button>
          <Button onClick={handleStatusUpdates} autoFocus>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
}

const NoContracts = ({ loading = false }) => {
  return (
    <Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', borderRadius: 2, p: 2, mt: 2 }}>
      <Box sx={{ width: 35, height: 35, color: '#475569' }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        {loading ? <CircularProgress size={20} color="primary" /> : null}
        <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{loading ? `Fetching contracts...` : `No contracts found`}</Typography>
      </Box>
    </Box>
  )
}

export default Contracts;
