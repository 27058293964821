import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { userRole } from "../../constants/userRoles";
import VEngageLogo from "../../assets/image/VEngageLogo.png";
import { Box, MenuItem, Select } from "@mui/material";
import { appRegions } from "../../constants/config";
import { useSelector, useDispatch } from "react-redux";
import { loginUser } from "../../store/Auth/actions";
import { blue } from "@mui/material/colors";

export default function Login() {
  const regionCode = localStorage.getItem("master-data-region-code") || process.env.REACT_APP_REGION || null;
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser = null, loading = false } = useSelector(state => state.auth);

  const [emailID, setEmailID] = useState("");
  const [password, setPassword] = useState("");
  const [currentRegion, setCurrentRegion] = useState(regionCode || 'US');

  useEffect(() => {
    if (currentUser !== null) {
      if (currentUser?.role === userRole.FINANCE) {
        history.push("/master/exports");
      } else if (currentUser?.role === userRole.ITEXECUTIVE) {
        history.push("/master/profile");
      } else if (currentUser?.role === userRole.RECRUITER || currentUser?.role === userRole.RECRUITERADMIN) {
        history.push("/recruitmentDrafts");
      } else if (currentUser?.role === userRole.SOWMANAGER) {
        history.push("/sow");
      } else {
        history.push("/");
      }
    }
  }, [currentUser]);

  const handleSignin = () => {
    const loginData = { email: emailID, password: password };
    if (emailID !== "" && password !== "") {
      localStorage.setItem('master-data-region-code', process.env.REACT_APP_REGION || 'US');
      dispatch(loginUser(loginData, res => {
        if (!res?.status) toast.error('Failed to log you in! Please try after sometime');
      }));
    } else {
      toast.error('Please fill all required details!');
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={{
          height: "100vh",
          backgroundColor: "#004DA7",
          borderRadius: "0px 30px 30px 0px",
          boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
            height: "calc(100vh - 64px)",
            overflowY: 'auto',
          }}
        >
          <Box sx={{ width: '100%', maxWidth: 360, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5 }}>
            <img
              src={VEngageLogo}
              width={100}
              height={100}
              alt='logo'
            />
            <Typography
              style={{
                color: "#ffffff",
                fontWeight: "bold",
                fontSize: "35px",
                fontFamily: "Roboto",
              }}
            >
              Log in
            </Typography>
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
              {process.env.REACT_APP_REGION
                ?
                null
                :
                <Box sx={{ width: '100%' }}>
                  <Typography variant='subtitle2' sx={{ mb: 1, color: '#fff' }}>Region <span style={{ color: 'red' }}>*</span></Typography>
                  <Select
                    sx={{ width: '100%', background: "#FFFFFF" }}
                    displayEmpty
                    value={currentRegion}
                    onChange={(e) => {
                      setCurrentRegion(e?.target?.value);
                    }}
                    id='log-region-input'
                  >
                    <MenuItem disabled value="">{"Please choose a region"}</MenuItem>
                    {(appRegions.map(item => ({ label: item?.name || "", value: item?.code || "" }))).map((option, index) => {
                      return (
                        <MenuItem key={index} value={option?.value || ""}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Box className={`fi fi-${(option?.value || "").toLowerCase()}`} sx={{ width: 30, height: 30, backgroundSize: 'contain' }}></Box>
                            <Typography variant="subtitle2" sx={{ fontSize: 14 }}>{option.label}</Typography>
                          </Box>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Box>
              }
              <Box sx={{ width: '100%' }}>
                <Typography variant='subtitle2' sx={{ mb: 1, color: '#fff' }}>Email address <span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                  id='log-email-input'
                  onChange={(e) => setEmailID(e.target.value)}
                  sx={{ width: "100%", background: "#FFFFFF", "& fieldset": { border: 'none' }, }}
                  value={emailID}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ width: '100%' }}>
                <Typography variant='subtitle2' sx={{ mb: 1, color: '#fff' }}>Password <span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                  id='log-password-input'
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{ width: "100%", background: "#FFFFFF", "& fieldset": { border: 'none' }, }}
                  value={password}
                  type="password"
                />
              </Box>
            </Box>
            <Button
              id='log-in-btn'
              variant='outlined'
              onClick={handleSignin}
              sx={{ width: '100%', bgcolor: '#fff', height: 50, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, '&:hover': { bgcolor: '#fff' } }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={20} /> : null}
              <Typography variant="subtitle2" sx={{ color: blue[500] }}>Log in</Typography>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid
        item
        xs={6}
        style={{
          display: "flex",
          height: "calc(100vh - 64px)",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src='contract.png'
          alt='contract_Illustration'
          style={{
            width: "80%",
            height: "80%",
            objectFit: "contain",
            margin: "0 auto",

          }}
        />
      </Grid>
    </Grid>
  );
}