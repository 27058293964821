import moment from "moment-timezone";

// Set the time zone Etc/UTC by default 
// Reference: https://stackoverflow.com/questions/56846456/momentjs-date-object-utc-by-default/56846747#56846747 
moment.tz.setDefault("Etc/UTC");

export const convertToServerTime = (date, format = "MM/DD/YYYY") => {
  const convertedDate = moment.utc(date).isValid()
    ? moment.utc(date).format(format)
    : null;
  return convertedDate;
};

// "2023-07-06T13:43:46.000Z"
// "2023-07-06T12:45:13.000Z"
