import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserConfiguration } from "../../../store/ConsultantForm/action";
import { contractStatusOptions, convertToRequiredTabFormat, getOptionFromList } from "../../../utils/consultantConstants";
import _ from "lodash";
import { TabNav } from "../../../components/ContractForm";
import { TabPanel } from "../Settings/SettingsScreen";
import DividerComponent from "../../../components/Divider/Divider";
import { blue, grey } from "@mui/material/colors";
import CancelIcon from '@mui/icons-material/Cancel';
import LocalSearchableDropdown from "../../../components/LocalSearchableDropdown";
import moment from "moment";
import api from "../../../config/https";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { requestExportAction } from "../../../store/actions/DownloadReportActions";
import { toast } from "react-toastify";

const dateRangeTypes = [
  {
    label: "Contracts starting in the range",
    value: "start_date",
  },
  {
    label: "Contracts ending in the range",
    value: "end_date",
  },
]

const ExportCustomReportModal = ({ open = false, onClose = () => { } }) => {
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);

  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [selectedItems, setSelectedItem] = useState([]);
  const [filters, setFilters] = useState({ date_range_type: "start_date" });
  const [loadingOptions, setLoadingOptions] = useState([]);
  const [options, setOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [markAll, setMarkAll] = useState(false);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    dispatch(getUserConfiguration({ id: currentUser?.id }, res => {
      if (res?.status) {
        let list = res?.data?.userGroup?.configuration || [];
        let foundItem = _.find(list, { varient: 'Active' });
        let tabs = convertToRequiredTabFormat(foundItem?.tabs || []);
        setTabs(tabs);
        setLoading(false);
      }
    }));
  }, [currentUser?.id]);

  useEffect(() => {
    if (markAll) {
      const list = extractVariables(tabs);
      setSelectedItem(list);
    }
  }, [markAll]);

  useEffect(() => {
    if (tabs?.length) {
      const list = extractVariables(tabs);
      if (selectedItems?.length === Object.keys(list)?.length) {
        setMarkAll(true);
      } else {
        setMarkAll(false);
      }
    }
  }, [selectedItems]);

  const extractVariables = (tabList = []) => {
    let variables = [];
    tabList.forEach(tab => {
      tab?.fields.forEach(field => {
        if (field?.type !== 'upload' && field?.type !== 'divider') {
          if (field?.type === 'location') {
            if (field?.address?.variable) {
              variables.push(field?.address?.variable);
            }
            if (field?.country?.variable) {
              variables.push(field?.country?.variable);
            }
            if (field?.state?.variable) {
              variables.push(field?.state?.variable);
            }
            if (field?.city?.variable) {
              variables.push(field?.city?.variable);
            }
            if (field?.zipCode?.variable) {
              variables.push(field?.zipCode?.variable);
            }
          } else {
            variables.push(field.variable);
            if (field?.confidential) {
              variables.push(field.confidential);
            }
          }
        }
      });
    });
    return variables;
  }

  const handleSearch = async (name = "", value = "", key) => {
    setLoadingOptions(prev => [...prev, name]);
    let url = `/masterAdmin/constant?fieldType=${name}&search=${value}`;
    let res = await api.get(url);
    if (res?.data?.status && res?.data?.data?.data?.length) {
      let list = res?.data?.data?.data || [];
      setOptions(prev => ({ ...prev, [key]: list.map(item => ({ ...item, value: item?.options, label: item?.options })) }));
    }
    setLoadingOptions(prev => prev.filter(item => item !== name));
  }

  const handleSearchCandidate = async (value = "", key) => {
    setLoadingOptions(prev => [...prev, key]);
    let url = `/masterAdmin/candidate/search/${value}`;
    let res = await api.get(url);
    if (res?.data?.status && res?.data?.data?.length) {
      let list = (res?.data?.data || []).slice(0, 100);
      setOptions(prev => ({ ...prev, [key]: list.map(item => ({ ...item, value: item?.id, label: item?.name })) }));
    }
    setLoadingOptions(prev => prev.filter(item => item !== key));
  }

  const handleChangeTab = (v) => {
    setActiveTab(v);
  }

  const handleToggleSelect = (itemToToggle = '') => {
    let list = JSON.parse(JSON.stringify(selectedItems));
    let newList = _.xor(list, [itemToToggle]);
    setSelectedItem(newList);
  }

  const handleFilter = (name = '', value = '') => {
    setFilters(prev => ({
      ...prev,
      [name]: value,
    }));
  }

  const handleResetFilter = () => {
    setFilters(null);
    setSelectedItem([]);
  };

  const handleApplyFilter = () => {
    setExporting(true);
    const payload = {
      id: filters?.id || [],
      startDate: filters?.date_range_type === "start_date" && filters?.startDate ? moment.utc(filters?.startDate).toISOString() : "",
      endDate: filters?.date_range_type === "start_date" && filters?.endDate ? moment.utc(filters?.endDate).toISOString() : "",
      startDateEnd: filters?.date_range_type === "end_date" && filters?.startDate ? moment.utc(filters?.startDate).toISOString() : "",
      endDateEnd: filters?.date_range_type === "end_date" && filters?.endDate ? moment.utc(filters?.endDate).toISOString() : "",
      active_month_date: moment.utc(new Date(new Date(filters?.active_month_date || '').getFullYear(), new Date(filters?.active_month_date || '').getMonth(), 1)).toISOString(),
      contract_status: filters?.contract_status || [],
      contractor_status: filters?.contractor_status || [],
      entity: filters?.entity || [],
      columnArray: selectedItems,
    };
    dispatch(requestExportAction(payload), res => {
      if (res?.status) {
        toast.success("Your custom report is being exported...");
      } else {
        toast.error("Failed to export report!");
      }
    });
    onClose('upload');
  };

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' && !exporting) {
      onClose();
    }
  }

  return (
    <Dialog maxWidth='lg' fullWidth open={open} onClose={handleClose}>
      <DialogContent>
        {exporting || loading ?
          <LoadingOptionsComponent exporting={exporting} />
          :
          <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, mb: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, bgcolor: blue[50], p: 1, borderRadius: 2, }}>
                <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <FilterListIcon sx={{ color: '#fff', fontSize: 20 }} />
                </Box>
                <Typography variant='subtitle2' sx={{ fontSize: 16, color: blue[500] }}>Customize your report</Typography>
              </Box>
              <IconButton id='close-btn' sx={{ bgcolor: grey[200] }} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Active month</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    views={["year", "month"]}
                    inputFormat='DD/MM/YYYY'
                    onChange={(e) => handleFilter('active_month_date', e)}
                    value={filters?.active_month_date ? new Date(new Date(filters?.active_month_date || '').getFullYear(), new Date(filters?.active_month_date || '').getMonth(), 1) : null}
                    renderInput={(inputProps) =>
                      <TextField
                        {...inputProps}
                        error={false}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        sx={{ width: "100%", background: "#FFFFFF" }}
                      />
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Contract status</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={filters?.contract_status || null}
                  options={contractStatusOptions}
                  handleChange={(v) => {
                    handleFilter('contract_status', v);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Contractor Type</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={filters?.contractor_status || null}
                  options={options?.['contractor_type'] || []}
                  matchingOptions={selectedOptions?.['contractor_type'] || []}
                  handleChange={(v) => {
                    let oldValues = filters?.['contractor_status'] || [];
                    let intersection = [];
                    if (v.length > oldValues.length) {
                      intersection = v.filter(x => !oldValues.includes(x));
                      if (intersection.length) {
                        const option = getOptionFromList(options['contractor_type'], intersection[0]);
                        if (option?.value) {
                          setSelectedOptions(prev => {
                            let oldOptions = prev?.['contractor_type'] || [];
                            let op = [...oldOptions, option];
                            let list = [...new Map(op.map(item => [item.value, item])).values()];
                            return ({
                              ...prev,
                              ['contractor_type']: list,
                            });
                          });
                        }
                      }
                    } else {
                      intersection = oldValues.filter(x => !v.includes(x));
                      if (intersection.length) {
                        setSelectedOptions(prev => {
                          let oldOptions = prev?.['contractor_type'] || [];
                          oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                          return ({
                            ...prev,
                            ['contractor_type']: oldOptions,
                          });
                        });
                      }
                    }
                    handleFilter('contractor_status', v);
                  }}
                  handleInputChange={e => handleSearch('contractor_type', e, 'contractor_type')}
                  loading={loadingOptions?.includes('contractor_type')}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">Entity</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={filters?.entity || null}
                  options={options?.['entity'] || []}
                  matchingOptions={selectedOptions?.['entity'] || []}
                  handleChange={(v) => {
                    let oldValues = filters?.['entity'] || [];
                    let intersection = [];
                    if (v.length > oldValues.length) {
                      intersection = v.filter(x => !oldValues.includes(x));
                      if (intersection.length) {
                        const option = getOptionFromList(options['entity'], intersection[0]);
                        if (option?.value) {
                          setSelectedOptions(prev => {
                            let oldOptions = prev?.['entity'] || [];
                            let op = [...oldOptions, option];
                            let list = [...new Map(op.map(item => [item.value, item])).values()];
                            return ({
                              ...prev,
                              ['entity']: list,
                            });
                          });
                        }
                      }
                    } else {
                      intersection = oldValues.filter(x => !v.includes(x));
                      if (intersection.length) {
                        setSelectedOptions(prev => {
                          let oldOptions = prev?.['entity'] || [];
                          oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                          return ({
                            ...prev,
                            ['entity']: oldOptions,
                          });
                        });
                      }
                    }
                    handleFilter('entity', v);
                  }}
                  handleInputChange={e => handleSearch('entity', e, 'entity')}
                  loading={loadingOptions?.includes('entity')}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant='subtitle2'>Candidates</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={filters?.id || []}
                  options={options?.['id'] || []}
                  matchingOptions={selectedOptions?.['id'] || []}
                  handleChange={(v) => {
                    let oldValues = filters?.['id'] || [];
                    let intersection = [];
                    if (v.length > oldValues.length) {
                      intersection = v.filter(x => !oldValues.includes(x));
                      if (intersection.length) {
                        const option = getOptionFromList(options['id'], intersection[0]);
                        if (option?.value) {
                          setSelectedOptions(prev => {
                            let oldOptions = prev?.['id'] || [];
                            let op = [...oldOptions, option];
                            let list = [...new Map(op.map(item => [item.value, item])).values()];
                            return ({
                              ...prev,
                              ['id']: list,
                            });
                          });
                        }
                      }
                    } else {
                      intersection = oldValues.filter(x => !v.includes(x));
                      if (intersection.length) {
                        setSelectedOptions(prev => {
                          let oldOptions = prev?.['id'] || [];
                          oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                          return ({
                            ...prev,
                            ['id']: oldOptions,
                          });
                        });
                      }
                    }
                    handleFilter('id', v);
                  }}
                  handleInputChange={e => handleSearchCandidate(e, 'id')}
                  fields={['contact_email']}
                  loading={loadingOptions?.includes('id')}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant='subtitle2'>Start Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(inputProps) =>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        value={filters?.startDate || ''}
                        variant="outlined"
                        sx={{ width: "100%", background: "#FFFFFF" }}
                        onChange={(e) => handleFilter('startDate', e?.target?.value)}
                      />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='subtitle2'>End Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    renderInput={(inputProps) =>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        value={filters?.endDate || ''}
                        variant="outlined"
                        sx={{ width: "100%", background: "#FFFFFF" }}
                        onChange={(e) => handleFilter('endDate', e?.target?.value)}
                      />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="subtitle2">User Report range</Typography>
                <LocalSearchableDropdown
                  value={filters?.date_range_type || null}
                  options={dateRangeTypes}
                  handleChange={(v) => {
                    handleFilter('date_range_type', v?.value);
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
              <Typography variant="subtitle2" sx={{ fontSize: 16, mt: 3, mb: 1 }}>Choose the options from below that you want to add into your custom report</Typography>
              <Button id='mark-all-btn' onClick={() => {
                if (markAll) {
                  setMarkAll(prev => !prev);
                  setSelectedItem([]);
                } else {
                  setMarkAll(prev => !prev);
                }
              }} sx={{ textTransform: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                {markAll ?
                  <CheckBoxIcon color='info' />
                  :
                  <CheckBoxOutlineBlankIcon color='info' />
                }
                <Typography variant="subtitle2" sx={{ fontSize: 16 }}>Choose all for export</Typography>
              </Button>
            </Box>
            <Box sx={{ width: '100%' }}>
              <TabNav value={activeTab} list={[...tabs.map(item => item?.name || "")]} handleChange={(e, v) => handleChangeTab(v)} />
              <Box id={'export-custom-tab-container-view'} sx={{ width: "100%", height: '25svh', overflowY: 'auto', px: 2, pt: 0.5, pb: 2, }}>
                {tabs?.map((tab, tabIndex) => {
                  return (
                    <Box key={`tab-panel-${tabIndex}`} sx={{ marginTop: 2 }}>
                      <TabPanel id="consultant-form" value={activeTab} index={tabIndex}>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 1, flexWrap: 'wrap' }}>
                          {(tab?.fields || [])?.map((field, fieldIndex) => {
                            return (
                              <RenderComponent
                                key={`input-field-${fieldIndex}`}
                                id={field.id}
                                index={fieldIndex}
                                item={field}
                                selectedItems={selectedItems}
                                handleToggleSelect={handleToggleSelect}
                              />
                            )
                          })}
                        </Box>
                      </TabPanel>
                    </Box>
                  )
                })}
              </Box>
            </Box>
          </Box>
        }
      </DialogContent>
      {exporting || loading ?
        null
        :
        <DialogActions sx={{ bgcolor: grey[50], p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <Button id='reset-btn' color='error' onClick={handleResetFilter}>Reset</Button>
            <Button id='export-btn' variant='outlined' onClick={handleApplyFilter}>Export</Button>
          </Box>
        </DialogActions>
      }
    </Dialog>
  )
}

const LoadingOptionsComponent = ({ exporting = false }) => {
  return (
    <Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', borderRadius: 2, p: 2, mt: 2 }}>
      <Box sx={{ width: 35, height: 35, color: '#475569' }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        <CircularProgress size={20} color="primary" />
        <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{exporting ? `Exporting you custom report` : `Retrieving available options...`}</Typography>
      </Box>
    </Box>
  )
}

const RenderComponent = ({ id = "", index = 0, item = null, selectedItems = [], handleToggleSelect = () => { } }) => {
  if (item?.type === 'divider' && item?.label !== 'Documents & Proofs') {
    return (
      <Box sx={{ width: '100%' }} id={id}>
        <DividerComponent title={item?.label || ""} outerStyle={{ marginTop: index === 0 ? 0 : 2 }} />
      </Box>
    )
  } else if (item?.type === 'text' || item?.type === 'email' || item?.type === 'phone' || item?.type === 'date' || item?.type === 'dropdown' || item?.type === 'searchable-dropdown') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        <Chip label={item?.label || ""} active={selectedItems.includes(item?.variable)} onSelect={() => handleToggleSelect(item?.variable)} onDelete={() => handleToggleSelect(item?.variable)} />
        {item?.confidential
          ?
          <Chip label={item?.confidentialLabel || ""} active={selectedItems.includes(item.confidential)} onSelect={() => handleToggleSelect(item?.confidential)} onDelete={() => handleToggleSelect(item?.confidential)} />
          :
          null
        }
      </Box>
    )
  } else if (item?.type === 'plan') {
    return (
      <Chip label={item?.variable || ""} active={selectedItems.includes(item?.variable)} onSelect={() => handleToggleSelect(item?.variable)} onDelete={() => handleToggleSelect(item?.variable)} />
    )
  } else if (item?.type === 'location') {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
        {item?.address?.label && item?.address?.variable ?
          <Chip label={item?.address?.label || ""} active={selectedItems.includes(item?.address?.variable)} onSelect={() => handleToggleSelect(item?.address?.variable)} onDelete={() => handleToggleSelect(item?.address?.variable)} />
          :
          null
        }
        {item?.country?.label && item?.country?.variable ?
          <Chip label={item?.country?.label || ""} active={selectedItems.includes(item?.country?.variable)} onSelect={() => handleToggleSelect(item?.country?.variable)} onDelete={() => handleToggleSelect(item?.country?.variable)} />
          :
          null
        }
        {item?.state?.label && item?.state?.variable ?
          <Chip label={item?.state?.label || ""} active={selectedItems.includes(item?.state?.variable)} onSelect={() => handleToggleSelect(item?.state?.variable)} onDelete={() => handleToggleSelect(item?.state?.variable)} />
          :
          null
        }
        {item?.city?.label && item?.city?.variable ?
          <Chip label={item?.city?.label || ""} active={selectedItems.includes(item?.city?.variable)} onSelect={() => handleToggleSelect(item?.city?.variable)} onDelete={() => handleToggleSelect(item?.city?.variable)} />
          :
          null
        }
        {item?.zipCode?.label && item?.zipCode?.variable ?
          <Chip label={item?.zipCode?.label || ""} active={selectedItems.includes(item?.zipCode?.variable)} onSelect={() => handleToggleSelect(item?.zipCode?.variable)} onDelete={() => handleToggleSelect(item?.zipCode?.variable)} />
          :
          null
        }
      </Box>
    )
  }
  return null;
}

export const Chip = ({ label = '', active = false, onSelect = () => { }, onDelete = () => { } }) => {
  return (
    <Box
      onClick={() => {
        if (!active) {
          onSelect();
        }
      }}
      id={`chip-${label}`}
      disabled={true}
      sx={{ cursor: active ? 'default' : 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: active ? blue[500] : grey[200], borderRadius: 10, py: active ? 0.25 : 0.5, pl: 2, pr: active ? 0.5 : 2 }}>
      <Typography variant="subtitle2" sx={{ fontSize: 14, color: active ? '#fff' : '#475569' }}>{label}</Typography>
      {active ?
        <IconButton id={`remove-btn-${label}`} size="small" onClick={onDelete}>
          <CancelIcon fontSize="small" sx={{ color: blue[100] }} />
        </IconButton>
        :
        null
      }
    </Box>
  )
}

export default ExportCustomReportModal;