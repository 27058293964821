import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";
import { extractValuableOptions, validateEmail } from '../../../utils/consultantConstants';
import { userRoleArray } from '../../../constants/userRoles';

const UserCModal = ({ open = false, handleClose, handleAdd }) => {
  const [data, setData] = useState(null);

  const handleModalClose = () => {
    setData(null);
    handleClose();
  }

  const handleAddUser = () => {
    const check = checkValid();
    if (check) {
      handleAdd({ ...data });
      setData(null);
    }
  }

  const handleChange = (name, value) => {
    setData(prev => {
      return {
        ...prev,
        [name]: value
      }
    });
  }

  const checkValid = () => {
    let valid = true;
    if (!data?.name) {
      toast.error('Please enter a username!');
      valid = false;
    } else if (!data?.email || !validateEmail(data?.email)) {
      toast.error('Please enter a valid email address!');
      valid = false;
    } else if (!data?.role) {
      toast.error('Please select a role!');
      valid = false;
    }
    return valid;
  }

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      aria-labelledby="add-item-modal"
      aria-describedby="add-item-modal-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Typography variant='h6'>Add New User</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Name <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              placeholder={"Please enter user name!"}
              sx={{ width: "100%", background: "#FFFFFF" }}
              type='text'
              value={data?.name || ""}
              onChange={(e) => handleChange('name', e?.target?.value)}
              id='user-name'
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Email <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              placeholder={"Please enter user name!"}
              sx={{ width: "100%", background: "#FFFFFF" }}
              type='text'
              value={data?.email || ""}
              onChange={(e) => handleChange('email', e?.target?.value)}
              id='user-email'
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Role <span style={{ color: 'red' }}>*</span></Typography>
            <FormControl fullWidth>
              <Select
                sx={{ width: "100%", background: "#FFFFFF" }}
                displayEmpty
                value={data?.role || ""}
                onChange={(e) => handleChange('role', e?.target?.value)}
                id='user-role'
              >
                <MenuItem disabled value="">{"Please select a user role!"}</MenuItem>
                {(extractValuableOptions((userRoleArray || []).map(item => ({ value: item?.id, label: item?.label })))).map((option, index) => {
                  return (
                    <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button id='form-cancel-btn' onClick={handleModalClose}>Close</Button>
        <Button id='form-add-btn' onClick={handleAddUser} variant='contained'>Add</Button>
      </DialogActions>
    </Dialog>
  )
}

export default UserCModal;