import { Fragment, useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Grid, IconButton, TextField, Typography } from '@mui/material';
import DividerComponent from '../../../../../components/Divider/Divider';
import LocalSearchableDropdown from '../../../../../components/LocalSearchableDropdown';
import { grey } from '@mui/material/colors';
import { Cancel } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import api from '../../../../../config/https';
import { convertSearchedListToOptions, getOptionFromList } from '../../../../../utils/consultantConstants';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const InputTypePlan = ({ handleChange = () => { }, data = null, setRequestVariable = () => { }, requestVariable = null, handleRemoveRequestVariables = () => { }, handleAddRequestVariable = () => { }, }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [values, setValues] = useState(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    setValues(null);
    setSelectedOptions([]);
    setLoading(false);
  }, [data]);

  const handleSearch = async (value = "") => {
    setLoading(true);
    let url = `/masterAdmin/constant?fieldType=${data.searchVariable}&search=${value}`;
    if (data?.searchVariable === 'vendor') {
      url = `/masterAdmin/vendor?vendorName=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
        setOptions(d?.options || []);
      }
      setLoading(false);
    } else if (data?.searchVariable === 'businessUnit' || data?.searchVariable === 'clients' || data?.searchVariable === 'endclients' || data?.searchVariable === 'domains' || data?.searchVariable === 'hr') {
      url = `/masterAdmin/${data.searchVariable}?search=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
        setOptions(d?.options || []);
      }
      setLoading(false);
    } else {
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
        setOptions(d?.options || []);
      }
      setLoading(false);
    }
  }

  const handleChangeValue = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }));
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Configuration</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Label <span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            value={data?.label || ""}
            onChange={(e) => handleChange('label', e.target.value)}
            placeholder={"Please enter a label!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'input-label'}
            name={'input-label'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Variable Name <span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            value={data?.variable || ""}
            onChange={(e) => handleChange('variable', e.target.value)}
            placeholder={"Please enter a variable name!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'input-variable'}
            name={'input-variable'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Search Variable<span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            value={data?.searchVariable || ""}
            onChange={(e) => handleChange('searchVariable', e.target.value)}
            placeholder={"Please enter a search variable!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'input-search-variable'}
            name={'input-search-variable'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Allow multiple selection</Typography>
          <ButtonGroup sx={{ mt: 1 }}>
            <Button
              id={'input-multiple-btn'}
              name={'input-multiple-btn'}
              onClick={() => {
                setShowDropdown(false);
                handleChange('multiple', true);
                setTimeout(() => {
                  setShowDropdown(true);
                }, 100);
              }}
              variant={data?.multiple ? 'contained' : 'outlined'}>Yes</Button>
            <Button
              id={'input-single-btn'}
              name={'input-single-btn'}
              onClick={() => {
                setShowDropdown(false);
                handleChange('multiple', false);
                setTimeout(() => {
                  setShowDropdown(true);
                }, 100);
              }}
              variant={!data?.multiple ? 'contained' : 'outlined'}>No</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>System required field</Typography>
          <ButtonGroup sx={{ mt: 1 }}>
            <Button id={'required-btn'} name={'required-btn'} onClick={() => handleChange('systemRequired', true)} variant={data?.systemRequired ? 'contained' : 'outlined'}>Yes</Button>
            <Button id={'not-required-btn'} name={'not-required-btn'} onClick={() => handleChange('systemRequired', false)} variant={!data?.systemRequired ? 'contained' : 'outlined'}>No</Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Allow request new options</Typography>
          <ButtonGroup sx={{ mt: 1 }}>
            <Button id={'input-request-btn'} name={'input-request-btn'} onClick={() => handleChange('request', true)} variant={data?.request ? 'contained' : 'outlined'}>Yes</Button>
            <Button id={'no-input-request-btn'} name={'no-input-request-btn'} onClick={() => handleChange('request', false)} variant={!data?.request ? 'contained' : 'outlined'}>No</Button>
          </ButtonGroup>
        </Grid>
        {data?.request
          ?
          <Grid item xs={12}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Request Variables</Typography>
            <Box sx={{ my: 1 }}>
              {data?.requestVariables?.map((option, optionIndex) => {
                return (
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, bgcolor: grey[50], p: 1, borderRadius: 2, my: 1, color: '#1976D2' }}>
                    <IconButton id={'remove-request-option-btn' + (optionIndex + 1)} name={'remove-request-option-btn' + (optionIndex + 1)} color="error" onClick={() => handleRemoveRequestVariables(option?.key)}>
                      <Cancel />
                    </IconButton>
                    <Typography variant='subtitle2' sx={{ fontSize: 16, color: '#000000DE' }} noWrap>{option?.label} :</Typography>
                    <Typography variant='subtitle2' sx={{ fontSize: 16 }} noWrap>{option?.key}</Typography>
                  </Box>
                )
              })}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <TextField id={'add-request-label'} name={'add-request-label'} label='Label' sx={{ background: "#FFFFFF" }} value={requestVariable?.label || ""} onChange={e => setRequestVariable(prev => ({ ...prev, label: e.target.value }))} />
              <TextField id={'add-request-key'} name={'add-request-key'} label='Key' sx={{ background: "#FFFFFF" }} value={requestVariable?.key || ""} onChange={e => setRequestVariable(prev => ({ ...prev, key: e.target.value }))} />
              <Button id={'add-request-option-btn'} name={'add-request-option-btn'} onClick={handleAddRequestVariable} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                <AddCircleIcon />
                <Typography variant='subtitle2'>Add</Typography>
              </Button>
            </Box>
          </Grid>
          :
          null
        }
      </Grid>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2'>Preview</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ my: 1 }}>{data?.label || ""} {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          {
            data?.label && data?.variable && data?.searchVariable && showDropdown
              ?
              <LocalSearchableDropdown
                id={data.variable}
                multiple={data?.multiple}
                value={data?.multiple ? values?.[data.variable] || [] : values?.[data.variable] || null}
                label={data?.label || ""}
                addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
                options={options}
                handleChange={(v) => {
                  if (data?.multiple) {
                    let oldValues = values?.[data.variable] || [];
                    let intersection = [];
                    if (v.length > oldValues.length) {
                      intersection = v.filter(x => !oldValues.includes(x));
                      if (intersection.length) {
                        const option = getOptionFromList(options, intersection[0]);
                        if (option?.value) {
                          setSelectedOptions(prev => {
                            let op = [...prev, option];
                            let list = [...new Map(op.map(item => [item.value, item])).values()]
                            return list;
                          });
                        }
                      }
                    } else {
                      intersection = oldValues.filter(x => !v.includes(x));
                      if (intersection.length) {
                        setSelectedOptions(prev => prev.filter(item => item.value !== intersection[0]));
                      }
                    }
                    handleChangeValue(data.variable, v);
                  } else {
                    handleChangeValue(data.variable, v?.value);
                    if (v?.value) {
                      setSelectedOptions(prev => {
                        let op = [...prev, v];
                        let list = [...new Map(op.map(item => [item.value, item])).values()];
                        return list;
                      });
                    } else {
                      setSelectedOptions([]);
                    }
                  }
                }}
                add={data?.request || false}
                loading={loading}
                handleInputChange={e => handleSearch(e)}
                matchingOptions={selectedOptions}
              />
              :
              null
          }
        </Grid>
        {
          data?.label && data?.variable && data?.searchVariable && showDropdown
            ?
            <Fragment>
              {selectedOptions.map((item, itemIndex) => {
                return (
                  <Grid item xs={4} key={itemIndex}>
                    <Typography variant='subtitle2' sx={{ my: 1 }}>{item?.label || ""} Effective date {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        renderInput={(inputProps) => {
                          let value = values?.[item?.value] || "";
                          return (
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              type="date"
                              value={value}
                              variant="outlined"
                              sx={{ width: "100%", background: "#FFFFFF" }}
                              onChange={(e) => handleChangeValue(item.value, e.target.value)}
                              id={item.value}
                              name={item.value}
                            />
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                )
              })}
            </Fragment>
            :
            null
        }
      </Grid>
    </Box>
  )
}

export default InputTypePlan;