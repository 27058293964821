import api from "../../config/https";
import { LOGIN_USER_FAILED, LOGIN_USER_IN_PROGRESS, LOGIN_USER_SUCCESS, LOGOUT_USER_FAILED, LOGOUT_USER_SUCCESS, REFRESH_LOGIN_USER_FAILED, REFRESH_LOGIN_USER_IN_PROGRESS, REFRESH_LOGIN_USER_SUCCESS, REFRESH_LOGIN_USER_TOKEN_FAILED, REFRESH_LOGIN_USER_TOKEN_IN_PROGRESS, REFRESH_LOGIN_USER_TOKEN_SUCCESS } from "../../constants/store";

export const verifyUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_USER_IN_PROGRESS,
    });
    const res = await api.post('/masterAdmin/user/verifyTOTP', payload);
    if (res?.data?.status) {
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: res?.data?.data || null,
      });
    } else {
      dispatch({
        type: LOGIN_USER_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: LOGIN_USER_FAILED,
    });
  }
};

export const loginUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_USER_IN_PROGRESS,
    });
    const res = await api.post('/masterAdmin/user/login', payload);
    if (res?.data?.status) {
      dispatch({
        type: LOGIN_USER_SUCCESS,
        payload: res?.data?.data || null,
      });
    } else {
      dispatch({
        type: LOGIN_USER_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: LOGIN_USER_FAILED,
    });
  }
};

export const logOutUser = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_USER_IN_PROGRESS,
    });
    dispatch({
      type: LOGOUT_USER_SUCCESS,
    });
    localStorage.removeItem('userDetails');
    if (callback) {
      callback(true);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: LOGOUT_USER_FAILED,
    });
  }
};

export const refreshUserToken = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: REFRESH_LOGIN_USER_TOKEN_IN_PROGRESS,
    });
    const res = await api.post('/masterAdmin/user/refreshToken', payload);
    if (res?.data?.status) {
      dispatch({
        type: REFRESH_LOGIN_USER_TOKEN_SUCCESS,
        payload: res?.data?.data?.token || null,
      });
    } else {
      dispatch({
        type: REFRESH_LOGIN_USER_TOKEN_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: REFRESH_LOGIN_USER_TOKEN_FAILED,
    });
  }
};

export const refreshUserInfo = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: REFRESH_LOGIN_USER_IN_PROGRESS,
    });
    const res = await api.post('/masterAdmin/user/getUserConfigDetails', payload);
    if (res?.data?.status) {
      dispatch({
        type: REFRESH_LOGIN_USER_SUCCESS,
        payload: res?.data?.data || null,
      });
    } else {
      dispatch({
        type: REFRESH_LOGIN_USER_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: REFRESH_LOGIN_USER_FAILED,
    });
  }
};