import { Box, Typography } from '@mui/material';
import { extractBarDataPoints } from '../../utils/chartHelper';
import { useEffect, useState } from 'react';
import { green } from '@mui/material/colors';
import NoDataCard from './noDataCard';

const ProgressCard = ({ data = [], visualizationData = null, title = "" }) => {
  const [info, setInfo] = useState({ current: 0, end: 0, progress: 0 });
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    const dataPoints = extractBarDataPoints(data, visualizationData, title);
    const list = dataPoints?.list || [];
    let item = list.length ? list[0] : null;
    let end = visualizationData?.['progress.goal'] || 0;
    let current = item?.name || 0;
    let progress = ((current / end) * 100).toFixed(0);
    setInfo({ current, end, progress });
    setTitle(dataPoints?.title || "");
  }, [data, visualizationData, title]);

  if (!info?.end) {
    return <NoDataCard />
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <Box sx={{ width: '100%', height: '55%', bgcolor: green[100], borderRadius: 2, mt: 3, position: 'relative' }}>
        <Typography sx={{ position: 'absolute', left: 0, top: '-30%', color: '#475569' }}>0</Typography>
        <Typography sx={{ position: 'absolute', right: 0, top: '-30%', color: '#475569' }}>{info?.end || 0}</Typography>
        <Typography sx={{ position: 'absolute', right: 0, bottom: '-30%', color: '#475569' }}>Goal</Typography>
        <Box sx={{ width: info.progress + '%', height: '100%', bgcolor: green[600], borderRadius: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ color: green[100], fontWeight: 600, fontSize: '40px', textAlign: 'center' }}>{info?.current || 0}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ProgressCard;