import { Fragment, useEffect, useState } from 'react';
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, FormControl, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import StorageIcon from '@mui/icons-material/Storage';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { blue, grey, orange, red } from '@mui/material/colors';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { convertSearchedListToOptions, extractValuableOptions, getOptionFromList, makeOptionsByFields } from '../../../../utils/consultantConstants';
import LocalSearchableDropdown from '../../../../components/LocalSearchableDropdown';
import PhoneNumberInput from '../../../../components/PhoneNumberInput/PhoneNumberInput';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Cancel, Delete } from '@mui/icons-material';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import RuleIcon from '@mui/icons-material/Rule';
import api from '../../../../config/https';
import { toast } from 'react-toastify';
import _ from "lodash";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '6px',
  overflow: 'hidden',
  background: '#fff',
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#fff !important',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    marginTop: 20,
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FormRules = ({ open = false, handleClose = () => { }, currentData = null, fields = [], handleUpdate = () => { } }) => {
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [required, setRequired] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [hidden, setHidden] = useState(false);
  // address
  const [required1, setRequired1] = useState(false);
  const [disabled1, setDisabled1] = useState(false);
  const [hidden1, setHidden1] = useState(false);
  // country
  const [required2, setRequired2] = useState(false);
  const [disabled2, setDisabled2] = useState(false);
  const [hidden2, setHidden2] = useState(false);
  // state
  const [required3, setRequired3] = useState(false);
  const [disabled3, setDisabled3] = useState(false);
  const [hidden3, setHidden3] = useState(false);
  // city
  const [required4, setRequired4] = useState(false);
  const [disabled4, setDisabled4] = useState(false);
  const [hidden4, setHidden4] = useState(false);
  // zip code
  const [required5, setRequired5] = useState(false);
  const [disabled5, setDisabled5] = useState(false);
  const [hidden5, setHidden5] = useState(false);
  const [expanded, setExpanded] = useState(null);

  const [fieldVisibility, setFieldVisibility] = useState(true);
  const [requiredField, setRequiredField] = useState(false);
  // address
  const [fieldVisibility1, setFieldVisibility1] = useState(true);
  const [requiredField1, setRequiredField1] = useState(false);
  // country 
  const [fieldVisibility2, setFieldVisibility2] = useState(true);
  const [requiredField2, setRequiredField2] = useState(false);
  // state
  const [fieldVisibility3, setFieldVisibility3] = useState(true);
  const [requiredField3, setRequiredField3] = useState(false);
  // city
  const [fieldVisibility4, setFieldVisibility4] = useState(true);
  const [requiredField4, setRequiredField4] = useState(false);
  // zip code
  const [fieldVisibility5, setFieldVisibility5] = useState(true);
  const [requiredField5, setRequiredField5] = useState(false);

  const [showNewCond, setShowNewCond] = useState(false);
  const [conditionIfs, setConditionIfs] = useState([]);
  const [conditions, setConditions] = useState([]);

  useEffect(() => {
    setConditions(currentData?.rules || []);
    if (currentData?.type === 'location') {
      // address
      setRequired1(currentData?.locationConfig?.address?.required || false);
      setDisabled1(currentData?.locationConfig?.address?.disabled || false);
      setHidden1(currentData?.locationConfig?.address?.hidden || false);
      // country
      setRequired2(currentData?.locationConfig?.country?.required || false);
      setDisabled2(currentData?.locationConfig?.country?.disabled || false);
      setHidden2(currentData?.locationConfig?.country?.hidden || false);
      // state
      setRequired3(currentData?.locationConfig?.state?.required || false);
      setDisabled3(currentData?.locationConfig?.state?.disabled || false);
      setHidden3(currentData?.locationConfig?.state?.hidden || false);
      // city
      setRequired4(currentData?.locationConfig?.city?.required || false);
      setDisabled4(currentData?.locationConfig?.city?.disabled || false);
      setHidden4(currentData?.locationConfig?.city?.hidden || false);
      // zip code
      setRequired5(currentData?.locationConfig?.zipCode?.required || false);
      setDisabled5(currentData?.locationConfig?.zipCode?.disabled || false);
      setHidden5(currentData?.locationConfig?.zipCode?.hidden || false);
    } else {
      setRequired(currentData?.required || false);
      setDisabled(currentData?.disabled || false);
      setHidden(currentData?.hidden || false);
    }
  }, [currentData]);

  const handleCopyFromPrevious = () => {
    let foundIndex = _.findIndex(fields, { variable: currentData?.variable });
    if (foundIndex !== -1) {
      const fieldData = fields?.[foundIndex - 1] || null;
      let rules = fieldData?.rules || [];
      setConditions(rules.map(item => ({ ...item, variable: currentData?.variable })));
      setRequired(fieldData?.required || false);
      setDisabled(fieldData?.disabled || false);
      setHidden(fieldData?.hidden || false);
      setExpanded(null);
    } else {
      toast.error('No previous data found!');
    }
  }

  const handleShowAddNewCond = () => {
    setShowNewCond(true);
    setExpanded(null);
    let item = { key: "", value: "", type: 'equalTo' };
    setConditionIfs([item]);
  }

  const handleCloseCond = () => {
    setExpanded(null);
    setShowNewCond(false);
    setConditionIfs([]);
  }

  const handleSaveCond = (index = "") => {
    let check = checkValidation(conditionIfs);
    if (check) {
      let rules = JSON.parse(JSON.stringify(conditions));
      let conds = [];
      conditionIfs.forEach(item => {
        let foundItem = _.find(fields, { variable: item.key });
        if (foundItem?.type === 'searchable-dropdown' || foundItem?.type === 'plan') {
          let sKey = foundItem?.searchVariable || "";
          let ops = selectedOptions?.[sKey] || [];
          let foundIndex = _.findIndex(ops, { value: item.value });
          conds.push({ ...item, label: ops?.[foundIndex]?.label || "", });
        } else {
          conds.push({ ...item, label: item.value, });
        }
      });
      if (currentData?.type === 'location') {
        rules[index] = {
          conditions: conds,
          variable: currentData?.address?.variable || currentData?.country?.variable || currentData?.state?.variable || currentData?.city?.variable || currentData?.zipCode?.variable,
          visibility1: fieldVisibility1,
          requiredField1: fieldVisibility1 && requiredField1,
          visibility2: fieldVisibility2,
          requiredField2: fieldVisibility2 && requiredField2,
          visibility3: fieldVisibility3,
          requiredField3: fieldVisibility3 && requiredField3,
          visibility4: fieldVisibility4,
          requiredField4: fieldVisibility4 && requiredField4,
          visibility5: fieldVisibility5,
          requiredField5: fieldVisibility5 && requiredField5,
        };
      } else {
        rules[index] = {
          conditions: conds,
          variable: currentData?.variable,
          visibility: fieldVisibility,
          requiredField: fieldVisibility && requiredField
        };
      }
      setConditions(rules);
      setExpanded(null);
    }
  }

  const handleAddNewCond = () => {
    let check = checkValidation(conditionIfs);
    if (check) {
      let rules = JSON.parse(JSON.stringify(conditions));
      let conds = [];
      conditionIfs.forEach(item => {
        let foundItem = _.find(fields, { variable: item.key });
        if (foundItem?.type === 'searchable-dropdown' || foundItem?.type === 'plan') {
          let sKey = foundItem?.searchVariable || "";
          let ops = selectedOptions?.[sKey] || [];
          let foundIndex = _.findIndex(ops, { value: item.value });
          conds.push({ ...item, label: ops?.[foundIndex]?.label || "", });
        } else {
          conds.push({ ...item, label: item.value, });
        }
      });
      if (currentData?.type === 'location') {
        rules.push({
          conditions: conds,
          variable: currentData?.address?.variable || currentData?.country?.variable || currentData?.state?.variable || currentData?.city?.variable || currentData?.zipCode?.variable,
          visibility1: fieldVisibility1,
          requiredField1: fieldVisibility1 && requiredField1,
          visibility2: fieldVisibility2,
          requiredField2: fieldVisibility2 && requiredField2,
          visibility3: fieldVisibility3,
          requiredField3: fieldVisibility3 && requiredField3,
          visibility4: fieldVisibility4,
          requiredField4: fieldVisibility4 && requiredField4,
          visibility5: fieldVisibility5,
          requiredField5: fieldVisibility5 && requiredField5,
        });
      } else {
        rules.push({
          conditions: conds,
          variable: currentData?.variable,
          visibility: fieldVisibility,
          requiredField: fieldVisibility && requiredField
        });
      }
      setConditions(rules);
      setShowNewCond(false);
      setExpanded(null);
      setConditionIfs([]);
      setFieldVisibility(true);
      setRequiredField(false);
    }
  }

  const checkValidation = (list = []) => {
    if (!list.length) {
      toast.error('Please add a if condition!');
      return false;
    } else if (list.length) {
      let valid = true;
      list.some(item => {
        if (!item?.key) {
          toast.error('Please select a field type!');
          valid = false;
          return true;
        } else if ((item?.type === 'equalTo' || item?.type === 'notEqualTo') && !item?.value) {
          toast.error('Please add a value!');
          valid = false;
          return true;
        }
      });
      return valid;
    }
  }

  const handleRemoveCond = (index = "") => {
    let conditionList = JSON.parse(JSON.stringify(conditions));
    conditionList.splice(index, 1);
    setConditions(conditionList);
    setConditionIfs([]);
    setExpanded(null);
  }

  const handleToggleAccordian = (condition = null, index = null) => {
    if (expanded === index) {
      setExpanded(null);
      setShowNewCond(false);
      setConditionIfs([]);
      setFieldVisibility(true);
      setRequiredField(false);
    } else {
      condition?.conditions?.forEach(item => {
        let foundItem = _.find(fields, { variable: item.key });
        if (foundItem?.type === 'searchable-dropdown' || foundItem?.type === 'plan') {
          let list = selectedOptions?.[foundItem?.searchVariable] || [];
          let foundValues = _.find(list, { value: item.value });
          if (!foundValues) {
            list.push({ value: item.value, label: item.label })
          }
          setSelectedOptions(prev => ({ ...prev, [foundItem.searchVariable]: list }));
        }
      });
      setExpanded(index);
      setShowNewCond(false);
      setConditionIfs(condition?.conditions || []);
      if (currentData?.type === 'location') {
        // address
        setFieldVisibility1(condition?.visibility1 || false);
        setRequiredField1(condition?.requiredField1 || false);
        // country
        setFieldVisibility2(condition?.visibility2 || false);
        setRequiredField2(condition?.requiredField2 || false);
        // state
        setFieldVisibility3(condition?.visibility3 || false);
        setRequiredField3(condition?.requiredField3 || false);
        // city
        setFieldVisibility4(condition?.visibility4 || false);
        setRequiredField4(condition?.requiredField4 || false);
        // zip code
        setFieldVisibility5(condition?.visibility5 || false);
        setRequiredField5(condition?.requiredField5 || false);
      } else {
        setFieldVisibility(condition?.visibility || false);
        setRequiredField(condition?.requiredField || false);
      }
    }
  }

  const handleUpdateRules = () => {
    if (currentData?.type === 'location') {
      handleUpdate({
        ...currentData,
        locationConfig: {
          address: {
            required: required1,
            disabled: disabled1,
            hidden: hidden1,
          },
          country: {
            required: required2,
            disabled: disabled2,
            hidden: hidden2,
          },
          state: {
            required: required3,
            disabled: disabled3,
            hidden: hidden3,
          },
          city: {
            required: required4,
            disabled: disabled4,
            hidden: hidden4,
          },
          zipCode: {
            required: required5,
            disabled: disabled5,
            hidden: hidden5,
          },
        },
        required: false,
        disabled: false,
        hidden: false,
        rules: conditions
      });
    } else {
      handleUpdate({
        ...currentData,
        required: required,
        disabled: disabled,
        hidden: hidden,
        rules: conditions
      });
    }
  };

  const handleIfKey = (value = "", index = "") => {
    let ifList = JSON.parse(JSON.stringify(conditionIfs));
    let ifValues = ifList.map(item => item.key);
    if (!ifValues.includes(value)) {
      ifList[index] = { value: "", key: value, type: 'equalTo' };
      setConditionIfs(ifList);
    } else {
      toast.error('If condition already exist!');
    }
  }

  const handleIfValue = (value = "", index = "") => {
    let ifList = JSON.parse(JSON.stringify(conditionIfs));
    ifList[index] = { ...ifList[index], value: value }
    setConditionIfs(ifList);
  }

  const handleIfCheckType = (value = "", index = "") => {
    let ifList = JSON.parse(JSON.stringify(conditionIfs));
    ifList[index] = { ...ifList[index], type: value }
    setConditionIfs(ifList);
  }

  const handleRemoveIf = (index) => {
    let ifList = JSON.parse(JSON.stringify(conditionIfs));
    ifList.splice(index, 1);
    setConditionIfs(ifList);
  }

  const handleAddNewIf = () => {
    let item = { key: "", value: "", type: 'equalTo' };
    setConditionIfs(prev => ([...prev, item]));
  }

  let options = makeOptionsByFields(fields.filter(item => item?.type !== 'divider'));

  let ifOptions = [...options];

  if (currentData?.type === 'location') {
    // address
    if (currentData?.address?.label) {
      ifOptions = ifOptions.filter(item => item?.value !== currentData?.address?.variable);
    }
    // country
    if (currentData?.country?.label) {
      ifOptions = ifOptions.filter(item => item?.value !== currentData?.country?.variable);
    }
    // state
    if (currentData?.state?.label) {
      ifOptions = ifOptions.filter(item => item?.value !== currentData?.state?.variable);
    }
    // city
    if (currentData?.city?.label) {
      ifOptions = ifOptions.filter(item => item?.value !== currentData?.city?.variable);
    }
    // zip code
    if (currentData?.zipCode?.label) {
      ifOptions = ifOptions.filter(item => item?.value !== currentData?.zipCode?.variable);
    }
  } else {
    ifOptions = ifOptions.filter(item => item?.value !== currentData?.variable);
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-tab-modal"
      aria-describedby="add-tab-modal-description"
      fullWidth
      maxWidth={"lg"}
    >
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 2 }}>
            <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <RuleIcon sx={{ color: '#fff', fontSize: 20 }} />
            </Box>
            {currentData?.type === 'upload' ?
              <Typography variant='subtitle2' color={blue[500]}>Document Rules</Typography>
              :
              <Typography variant='subtitle2' color={blue[500]}>{currentData?.label} Rules</Typography>
            }
          </Box>
          <Box sx={{ width: '100%', my: 0.5, p: 1, border: '1px solid #c7c7c7', borderRadius: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 120, p: 1, bgcolor: blue[50], borderRadius: 2 }}>
              <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <StorageIcon sx={{ color: '#fff', fontSize: 20 }} />
              </Box>
              <Typography variant='subtitle2' color={blue[500]}>DEFAULT</Typography>
            </Box>
            <Box sx={{ width: '100%', mt: 1, px: 1, border: '1px solid ' + blue[100], borderRadius: 2 }}>
              {currentData?.systemRequired ?
                null
                :
                currentData?.type === 'location'
                  ?
                  <Fragment>
                    {currentData?.address?.label ?
                      <Box sx={{ p: 1, my: 0.5, display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                        <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.address?.label} :</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Required</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="address-required-btn" onClick={() => setRequired1(true)} variant={required1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="address-not-required-btn" onClick={() => setRequired1(false)} variant={!required1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Disabled</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="address-disabled-btn" onClick={() => setDisabled1(true)} variant={disabled1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="address-not-disabled-btn" onClick={() => setDisabled1(false)} variant={!disabled1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Visibility</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="address-visible-btn" onClick={() => setHidden1(false)} variant={!hidden1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Visible</Button>
                            <Button id="address-not-visible-btn" onClick={() => setHidden1(true)} variant={hidden1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Hidden</Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      :
                      null
                    }
                    {currentData?.country?.label ?
                      <Box sx={{ p: 1, my: 0.5, display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                        <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.country?.label || ""} :</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Required</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="country-required-btn" onClick={() => setRequired2(true)} variant={required2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="country-not-required-btn" onClick={() => setRequired2(false)} variant={!required2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Disabled</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="country-diabled-btn" onClick={() => setDisabled2(true)} variant={disabled2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="country-not-diabled-btn" onClick={() => setDisabled2(false)} variant={!disabled2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Visibility</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="country-visible-btn" onClick={() => setHidden2(false)} variant={!hidden2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Visible</Button>
                            <Button id="country-not-visible-btn" onClick={() => setHidden2(true)} variant={hidden2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Hidden</Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      :
                      null
                    }
                    {currentData?.state?.label ?
                      <Box sx={{ p: 1, my: 0.5, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.state?.label || ""} :</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Required</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="state-required-btn" onClick={() => setRequired3(true)} variant={required3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="state-not-required-btn" onClick={() => setRequired3(false)} variant={!required3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Disabled</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="state-disabled-btn" onClick={() => setDisabled3(true)} variant={disabled3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="state-not-disabled-btn" onClick={() => setDisabled3(false)} variant={!disabled3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Visibility</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="state-visible-btn" onClick={() => setHidden3(false)} variant={!hidden3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Visible</Button>
                            <Button id="state-not-visible-btn" onClick={() => setHidden3(true)} variant={hidden3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Hidden</Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      :
                      null
                    }
                    {currentData?.city?.label ?
                      <Box sx={{ p: 1, my: 0.5, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.city?.label || ""} :</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Required</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="city-required-btn" onClick={() => setRequired4(true)} variant={required4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="city-not-required-btn" onClick={() => setRequired4(false)} variant={!required4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Disabled</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="city-disabled-btn" onClick={() => setDisabled4(true)} variant={disabled4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="city-not-disabled-btn" onClick={() => setDisabled4(false)} variant={!disabled4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Visibility</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="city-visible-btn" onClick={() => setHidden4(false)} variant={!hidden4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Visible</Button>
                            <Button id="city-not-visible-btn" onClick={() => setHidden4(true)} variant={hidden4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Hidden</Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      :
                      null
                    }
                    {currentData?.zipCode?.label ?
                      <Box sx={{ p: 1, my: 0.5, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                        <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.zipCode?.label || ""} :</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Required</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="zip-code-required-btn" onClick={() => setRequired5(true)} variant={required5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="zip-code-not-required-btn" onClick={() => setRequired5(false)} variant={!required5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Disabled</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="zip-code-disabled-btn" onClick={() => setDisabled5(true)} variant={disabled5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                            <Button id="zip-code-not-disabled-btn" onClick={() => setDisabled5(false)} variant={!disabled5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                          </ButtonGroup>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Typography variant='subtitle2'>Visibility</Typography>
                          <ButtonGroup disabled={currentData?.systemRequired}>
                            <Button id="zip-code-visible-btn" onClick={() => setHidden5(false)} variant={!hidden5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Visible</Button>
                            <Button id="zip-code-not-visible-btn" onClick={() => setHidden5(true)} variant={hidden5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Hidden</Button>
                          </ButtonGroup>
                        </Box>
                      </Box>
                      :
                      null
                    }
                  </Fragment>
                  :
                  <Box sx={{ p: 1, my: 0.5, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                      <Typography variant='subtitle2'>Required</Typography>
                      <ButtonGroup disabled={currentData?.systemRequired}>
                        <Button id="required-btn" onClick={() => setRequired(true)} variant={required ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                        <Button id="not-required-btn" onClick={() => setRequired(false)} variant={!required ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                      </ButtonGroup>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                      <Typography variant='subtitle2'>Disabled</Typography>
                      <ButtonGroup disabled={currentData?.systemRequired}>
                        <Button id="disabled-btn" onClick={() => setDisabled(true)} variant={disabled ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                        <Button id="not-disabled-btn" onClick={() => setDisabled(false)} variant={!disabled ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                      </ButtonGroup>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                      <Typography variant='subtitle2'>Visibility</Typography>
                      <ButtonGroup disabled={currentData?.systemRequired}>
                        <Button id="visible-btn" onClick={() => setHidden(false)} variant={!hidden ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Visible</Button>
                        <Button id="not-visible-btn" onClick={() => setHidden(true)} variant={hidden ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Hidden</Button>
                      </ButtonGroup>
                    </Box>
                  </Box>
              }
              {currentData?.systemRequired ?
                <Box sx={{ mb: 1, mt: 1.2 }}>
                  <Typography variant='subtitle2' color={orange[500]}>This is a system required fields, visibility functionality not available</Typography>
                </Box>
                :
                null
              }
            </Box>
          </Box>
          <Box sx={{ width: 2, height: 20, ml: 5, bgcolor: blue[100] }} />
          <Box sx={{ width: '100%', my: 0.5, p: 1, border: '1px solid #c7c7c7', borderRadius: 2 }}>
            {conditions?.map((condition, conditionIndex) => {
              const list = expanded === conditionIndex ? conditionIfs : (condition?.conditions || []);
              return (
                <Fragment>
                  <Accordion key={`condition-${conditionIndex}`} expanded={expanded === conditionIndex} onChange={() => { }}>
                    <AccordionSummary
                      aria-controls={`panel-condition-${conditionIndex}`}
                      expandIcon={
                        <IconButton id={'accordian-btn-' + (conditionIndex + 1)} onClick={() => handleToggleAccordian(condition, conditionIndex)}>
                          <ArrowForwardIosSharpIcon sx={{ fontSize: 16, color: expanded === conditionIndex ? blue[500] : grey[500] }} />
                        </IconButton>
                      }
                    >
                      <Box sx={{ display: 'flex', flexDirection: expanded === conditionIndex ? 'column' : 'row', alignItems: expanded === conditionIndex ? 'start' : 'center', gap: 1, flexWrap: 'wrap' }}>
                        {list.map((ifCond, ifCondIndex) => {
                          let foundIfItem = _.find(options, { variable: ifCond?.key });
                          return (
                            <Box key={ifCondIndex} sx={{ my: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, height: 54 }}>
                              {ifCondIndex > 0
                                ?
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 0.5 }}>
                                  <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <LibraryAddIcon sx={{ color: '#fff', fontSize: 20, }} />
                                  </Box>
                                  <Typography variant='subtitle2' color={blue[500]}>AND</Typography>
                                </Box>
                                :
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 70, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 0.5 }}>
                                  <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <AltRouteIcon sx={{ color: '#fff', fontSize: 20, transform: 'rotate(180deg)' }} />
                                  </Box>
                                  <Typography variant='subtitle2' color={blue[500]}>IF</Typography>
                                </Box>
                              }
                              {
                                expanded !== conditionIndex
                                  ?
                                  <Typography variant="subtitle2" >{foundIfItem?.label || ""}</Typography>
                                  :
                                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 300, }}>
                                    <LocalSearchableDropdown id={'if-value-' + (conditionIndex + 1)} value={ifCond?.key || ""} handleChange={v => handleIfKey(v?.value, ifCondIndex)} label='field' options={ifOptions} />
                                  </Box>
                              }
                              {
                                expanded !== conditionIndex
                                  ?
                                  <Fragment>
                                    {
                                      ifCond?.type === 'equalTo'
                                        ?
                                        <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                          <HorizontalRuleIcon sx={{ color: '#fff', fontSize: 20, transform: 'translateY(6px)' }} />
                                          <HorizontalRuleIcon sx={{ color: '#fff', fontSize: 20, transform: 'translateY(-6px)' }} />
                                        </Box>
                                        :
                                        null
                                    }
                                    {
                                      ifCond?.type === 'notEqualTo'
                                        ?
                                        <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                          <HorizontalRuleIcon sx={{ color: '#fff', fontSize: 20, transform: 'translateY(6px)' }} />
                                          <HorizontalRuleIcon sx={{ color: '#fff', fontSize: 20, transform: 'translateY(-6px)' }} />
                                          <Box sx={{ position: 'absolute', width: 24, height: 2, bgcolor: '#fff', transform: 'rotate(-60deg)' }} />
                                        </Box>
                                        :
                                        null
                                    }
                                    {
                                      ifCond?.type === 'empty'
                                        ?
                                        <Box sx={{ px: 1, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                          <Typography variant='subtitle2' sx={{ color: '#fff', fontSize: 12 }}>empty</Typography>
                                        </Box>
                                        :
                                        null
                                    }
                                    {
                                      ifCond?.type === 'notEmpty'
                                        ?
                                        <Box sx={{ px: 1, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                          <Typography variant='subtitle2' sx={{ color: '#fff', fontSize: 12 }}>not empty</Typography>
                                        </Box>
                                        :
                                        null
                                    }
                                  </Fragment>
                                  :
                                  <ButtonGroup>
                                    <Button id={'if-type-equal-' + (conditionIndex + 1)} variant={ifCond?.type === 'equalTo' ? 'contained' : 'outlined'} onClick={() => handleIfCheckType('equalTo', ifCondIndex)} sx={{ width: 30, height: 30, bgcolor: ifCond?.type === 'equalTo' ? blue[500] : 'transparent', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                                      <HorizontalRuleIcon sx={{ color: ifCond?.type === 'equalTo' ? '#fff' : blue[500], fontSize: 20, transform: 'translateY(6px)' }} />
                                      <HorizontalRuleIcon sx={{ color: ifCond?.type === 'equalTo' ? '#fff' : blue[500], fontSize: 20, transform: 'translateY(-6px)' }} />
                                    </Button>
                                    <Button id={'if-type-not-equal-' + (conditionIndex + 1)} variant={ifCond?.type === 'notEqualTo' ? 'contained' : 'outlined'} onClick={() => handleIfCheckType('notEqualTo', ifCondIndex)} sx={{ width: 30, height: 30, bgcolor: ifCond?.type === 'notEqualTo' ? blue[500] : 'transparent', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                      <HorizontalRuleIcon sx={{ color: ifCond?.type === 'notEqualTo' ? '#fff' : blue[500], fontSize: 20, transform: 'translateY(6px)' }} />
                                      <HorizontalRuleIcon sx={{ color: ifCond?.type === 'notEqualTo' ? '#fff' : blue[500], fontSize: 20, transform: 'translateY(-6px)' }} />
                                      <Box sx={{ position: 'absolute', width: 24, height: 2, bgcolor: ifCond?.type === 'notEqualTo' ? '#fff' : blue[500], transform: 'rotate(-60deg)' }} />
                                    </Button>
                                    <Button id={'if-type-empty-' + (conditionIndex + 1)} variant={ifCond?.type === 'empty' ? 'contained' : 'outlined'} onClick={() => handleIfCheckType('empty', ifCondIndex)} sx={{ px: 1, height: 30, bgcolor: ifCond?.type === 'empty' ? blue[500] : 'transparent', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                      <Typography variant='subtitle2' sx={{ color: ifCond?.type === 'empty' ? '#fff' : blue[500], fontSize: 12 }}>empty</Typography>
                                    </Button>
                                    <Button id={'if-type-not-empty-' + (conditionIndex + 1)} variant={ifCond?.type === 'notEmpty' ? 'contained' : 'outlined'} onClick={() => handleIfCheckType('notEmpty', ifCondIndex)} sx={{ px: 1, height: 30, bgcolor: ifCond?.type === 'notEmpty' ? blue[500] : 'transparent', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                      <Typography variant='subtitle2' sx={{ color: ifCond?.type === 'notEmpty' ? '#fff' : blue[500], fontSize: 12 }}>not empty</Typography>
                                    </Button>
                                  </ButtonGroup>
                              }
                              {
                                expanded === conditionIndex
                                  ?
                                  <Fragment>
                                    {ifCond?.type !== 'empty' && ifCond?.type !== 'notEmpty' && ifCond?.key
                                      ?
                                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 220 }}>
                                        <RenderInput
                                          item={foundIfItem}
                                          data={{ [foundIfItem?.variable]: ifCond?.value }}
                                          handleChange={(name, value) => handleIfValue(value, ifCondIndex)}
                                          selectedOptions={selectedOptions}
                                          setSelectedOptions={setSelectedOptions}
                                        />
                                      </Box>
                                      :
                                      null
                                    }
                                  </Fragment>
                                  :
                                  <Typography variant='subtitle2' sx={{ color: blue[500], fontSize: 16 }}>{ifCond?.label || ""}</Typography>
                              }
                              {
                                expanded === conditionIndex && list.length > 1
                                  ?
                                  <Tooltip id={'removeField-' + (conditionIndex + 1)} title='Remove if condition'>
                                    <IconButton id={"remove-if-btn-" + (conditionIndex + 1)} color="error" onClick={() => handleRemoveIf(ifCondIndex)}>
                                      <Cancel fontSize='small' />
                                    </IconButton>
                                  </Tooltip>
                                  :
                                  null
                              }
                            </Box>
                          )
                        })}
                        {
                          expanded === conditionIndex && list?.[list.length - 1]?.key
                            ?
                            <Fragment>
                              <Box sx={{ width: 2, height: 20, ml: 2, bgcolor: blue[100] }} />
                              <Button id={"add-if-cond-btn-" + (conditionIndex + 1)} onClick={handleAddNewIf} variant='outlined' sx={{ mt: 0.5, width: 220, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                                <AddCircleIcon sx={{ fontSize: 20 }} />
                                <Typography variant='subtitle2'>Add a new if condition</Typography>
                              </Button>
                            </Fragment>
                            :
                            null
                        }
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 140, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 0.5 }}>
                        <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <AltRouteIcon sx={{ color: '#fff', fontSize: 20, transform: 'rotate(180deg)' }} />
                        </Box>
                        <Typography variant='subtitle2' color={blue[500]}>THEN</Typography>
                      </Box>
                      <Box sx={{ width: 2, height: 20, ml: 2, bgcolor: blue[100] }} />
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, my: 0.5, p: 1.5, border: '1px solid ' + blue[100], borderRadius: 2, position: 'relative', }}>
                        {currentData?.type === 'location'
                          ?
                          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {currentData?.address?.label ?
                              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                                <Typography variant='subtitle2' sx={{ color: blue[500], }}>{currentData?.address?.label || ""} :</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                  <Typography variant='subtitle2'>Visibility</Typography>
                                  <ButtonGroup>
                                    <Button
                                      id={currentData?.address?.variable + '-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility1(true);
                                        setRequiredField1(true);
                                      }}
                                      variant={fieldVisibility1 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Visible
                                    </Button>
                                    <Button
                                      id={currentData?.address?.variable + '-not-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility1(false);
                                        setRequiredField1(false);
                                      }}
                                      variant={!fieldVisibility1 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Hidden
                                    </Button>
                                  </ButtonGroup>
                                </Box>
                                {
                                  fieldVisibility1
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                      <Typography variant='subtitle2'>Required</Typography>
                                      <ButtonGroup>
                                        <Button id={currentData?.address?.variable + '-required-btn'} onClick={() => setRequiredField1(true)} variant={requiredField1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                        <Button id={currentData?.address?.variable + '-not-required-btn'} onClick={() => setRequiredField1(false)} variant={!requiredField1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                      </ButtonGroup>
                                    </Box>
                                    :
                                    null
                                }
                              </Box>
                              :
                              null
                            }
                            {currentData?.country?.label
                              ?
                              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                                <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.country?.label || ""} :</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                  <Typography variant='subtitle2'>Visibility</Typography>
                                  <ButtonGroup>
                                    <Button
                                      id={currentData?.country?.variable + '-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility2(true);
                                        setRequiredField2(true);
                                      }}
                                      variant={fieldVisibility2 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Visible
                                    </Button>
                                    <Button
                                      id={currentData?.country?.variable + '-not-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility2(false);
                                        setRequiredField2(false);
                                      }}
                                      variant={!fieldVisibility2 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Hidden
                                    </Button>
                                  </ButtonGroup>
                                </Box>
                                {
                                  fieldVisibility2
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                      <Typography variant='subtitle2'>Required</Typography>
                                      <ButtonGroup>
                                        <Button id={currentData?.country?.variable + '-required-btn'} onClick={() => setRequiredField2(true)} variant={requiredField2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                        <Button id={currentData?.country?.variable + '-not-required-btn'} onClick={() => setRequiredField2(false)} variant={!requiredField2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                      </ButtonGroup>
                                    </Box>
                                    :
                                    null
                                }
                              </Box>
                              :
                              null
                            }
                            {currentData?.state?.label
                              ?
                              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                                <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.state?.label || ""} :</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                  <Typography variant='subtitle2'>Visibility</Typography>
                                  <ButtonGroup>
                                    <Button
                                      id={currentData?.state?.variable + '-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility3(true);
                                        setRequiredField3(true);
                                      }}
                                      variant={fieldVisibility3 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Visible
                                    </Button>
                                    <Button
                                      id={currentData?.state?.variable + '-not-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility3(false);
                                        setRequiredField3(false);
                                      }}
                                      variant={!fieldVisibility3 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Hidden
                                    </Button>
                                  </ButtonGroup>
                                </Box>
                                {
                                  fieldVisibility3
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                      <Typography variant='subtitle2'>Required</Typography>
                                      <ButtonGroup>
                                        <Button id={currentData?.state?.variable + '-required-btn'} onClick={() => setRequiredField3(true)} variant={requiredField3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                        <Button id={currentData?.state?.variable + '-not-required-btn'} onClick={() => setRequiredField3(false)} variant={!requiredField3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                      </ButtonGroup>
                                    </Box>
                                    :
                                    null
                                }
                              </Box>
                              :
                              null
                            }
                            {currentData?.city?.label
                              ?
                              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.city?.label || ""} :</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                  <Typography variant='subtitle2'>Visibility</Typography>
                                  <ButtonGroup>
                                    <Button
                                      id={currentData?.city?.variable + '-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility4(true);
                                        setRequiredField4(true);
                                      }}
                                      variant={fieldVisibility4 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Visible
                                    </Button>
                                    <Button
                                      id={currentData?.city?.variable + '-not-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility4(false);
                                        setRequiredField4(false);
                                      }}
                                      variant={!fieldVisibility4 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Hidden
                                    </Button>
                                  </ButtonGroup>
                                </Box>
                                {
                                  fieldVisibility4
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                      <Typography variant='subtitle2'>Required</Typography>
                                      <ButtonGroup>
                                        <Button id={currentData?.city?.variable + '-required-btn'} onClick={() => setRequiredField4(true)} variant={requiredField4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                        <Button id={currentData?.city?.variable + '-not-required-btn'} onClick={() => setRequiredField4(false)} variant={!requiredField4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                      </ButtonGroup>
                                    </Box>
                                    :
                                    null
                                }
                              </Box>
                              :
                              null
                            }
                            {currentData?.zipCode?.label
                              ?
                              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                                <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.zipCode?.label || ""}</Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                  <Typography variant='subtitle2'>Visibility</Typography>
                                  <ButtonGroup>
                                    <Button
                                      id={currentData?.zipCode?.variable + '-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility5(true);
                                        setRequiredField5(true);
                                      }}
                                      variant={fieldVisibility5 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Visible
                                    </Button>
                                    <Button
                                      id={currentData?.zipCode?.variable + '-not-visible-btn'}
                                      onClick={() => {
                                        setFieldVisibility5(false);
                                        setRequiredField5(false);
                                      }}
                                      variant={!fieldVisibility5 ? 'contained' : 'outlined'}
                                      size='small'
                                      sx={{ textTransform: 'none' }}
                                    >
                                      Hidden
                                    </Button>
                                  </ButtonGroup>
                                </Box>
                                {
                                  fieldVisibility
                                    ?
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                      <Typography variant='subtitle2'>Required</Typography>
                                      <ButtonGroup>
                                        <Button id={currentData?.zipCode?.variable + '-required-btn'} onClick={() => setRequiredField5(true)} variant={requiredField5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                        <Button id={currentData?.zipCode?.variable + '-not-required-btn'} onClick={() => setRequiredField5(false)} variant={!requiredField5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                      </ButtonGroup>
                                    </Box>
                                    :
                                    null
                                }
                              </Box>
                              :
                              null
                            }
                          </Box>
                          :
                          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                              <Typography variant='subtitle2'>Field:</Typography>
                              <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.label || ""}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                              <Typography variant='subtitle2'>Visibility</Typography>
                              <ButtonGroup>
                                <Button
                                  id={currentData?.variable + '-visible-btn'}
                                  onClick={() => {
                                    setFieldVisibility(true);
                                    setRequiredField(true);
                                  }}
                                  variant={fieldVisibility ? 'contained' : 'outlined'}
                                  size='small'
                                  sx={{ textTransform: 'none' }}
                                >
                                  Visible
                                </Button>
                                <Button
                                  id={currentData?.variable + '-not-visible-btn'}
                                  onClick={() => {
                                    setFieldVisibility(false);
                                    setRequiredField(false);
                                  }}
                                  variant={!fieldVisibility ? 'contained' : 'outlined'}
                                  size='small'
                                  sx={{ textTransform: 'none' }}
                                >
                                  Hidden
                                </Button>
                              </ButtonGroup>
                            </Box>
                            {
                              fieldVisibility
                                ?
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                  <Typography variant='subtitle2'>Required</Typography>
                                  <ButtonGroup>
                                    <Button id={currentData?.variable + '-required-btn'} onClick={() => setRequiredField(true)} variant={requiredField ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                    <Button id={currentData?.variable + '-not-required-btn'} onClick={() => setRequiredField(false)} variant={!requiredField ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                  </ButtonGroup>
                                </Box>
                                :
                                null
                            }
                          </Box>
                        }
                      </Box>
                      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, mt: 2 }}>
                        <Tooltip id={'removeCond' + (conditionIndex + 1)} title="Remove condition">
                          <IconButton id={'remove-cond-btn-' + (conditionIndex + 1)} onClick={() => handleRemoveCond(conditionIndex)} size='small' variant='text' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, color: red[500] }}>
                            <Delete fontSize='small' />
                          </IconButton>
                        </Tooltip>
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                          <Button id={'close-cond-btn-' + (conditionIndex + 1)} onClick={handleCloseCond} size='small' variant='text' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>Cancel</Button>
                          <Button id={'save-cond-btn-' + (conditionIndex + 1)} onClick={() => handleSaveCond(conditionIndex)} size='small' variant='outlined' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>Save</Button>
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                  <Box sx={{ width: 2, height: 20, ml: 4, bgcolor: blue[100] }} />
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 80, p: 1, bgcolor: blue[50], borderRadius: 2, my: 0.5, }}>
                    <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <AltRouteIcon sx={{ color: '#fff', fontSize: 20, transform: 'rotate(180deg)' }} />
                    </Box>
                    <Typography variant='subtitle2' color={blue[500]}>OR</Typography>
                  </Box>
                  <Box sx={{ width: 2, height: 20, ml: 4, bgcolor: blue[100] }} />
                </Fragment>
              )
            })}
            {
              showNewCond
                ?
                <Accordion expanded={showNewCond} onChange={() => { }}>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 16 }} />}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {conditionIfs?.map((ifCond, ifCondIndex) => {
                        let foundItem = _.find(fields, { variable: ifCond?.key });
                        return (
                          <Box key={ifCondIndex} sx={{ my: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, height: 54 }}>
                            {ifCondIndex > 0
                              ?
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 0.5 }}>
                                <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <LibraryAddIcon sx={{ color: '#fff', fontSize: 20, }} />
                                </Box>
                                <Typography variant='subtitle2' color={blue[500]}>AND</Typography>
                              </Box>
                              :
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 70, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 0.5 }}>
                                <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                  <AltRouteIcon sx={{ color: '#fff', fontSize: 20, transform: 'rotate(180deg)' }} />
                                </Box>
                                <Typography variant='subtitle2' color={blue[500]}>IF</Typography>
                              </Box>
                            }
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 300, }}>
                              <LocalSearchableDropdown value={ifCond?.key || ""} handleChange={v => handleIfKey(v?.value, ifCondIndex)} label='field' options={ifOptions} />
                            </Box>
                            {ifCond?.key ?
                              <Fragment>
                                <ButtonGroup>
                                  <Button id={'if-type-equal'} variant={ifCond?.type === 'equalTo' ? 'contained' : 'outlined'} onClick={() => handleIfCheckType('equalTo', ifCondIndex)} sx={{ width: 30, height: 30, bgcolor: ifCond?.type === 'equalTo' ? blue[500] : 'transparent', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }}>
                                    <HorizontalRuleIcon sx={{ color: ifCond?.type === 'equalTo' ? '#fff' : blue[500], fontSize: 20, transform: 'translateY(6px)' }} />
                                    <HorizontalRuleIcon sx={{ color: ifCond?.type === 'equalTo' ? '#fff' : blue[500], fontSize: 20, transform: 'translateY(-6px)' }} />
                                  </Button>
                                  <Button id={'if-type-not-equal'} variant={ifCond?.type === 'notEqualTo' ? 'contained' : 'outlined'} onClick={() => handleIfCheckType('notEqualTo', ifCondIndex)} sx={{ width: 30, height: 30, bgcolor: ifCond?.type === 'notEqualTo' ? blue[500] : 'transparent', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
                                    <HorizontalRuleIcon sx={{ color: ifCond?.type === 'notEqualTo' ? '#fff' : blue[500], fontSize: 20, transform: 'translateY(6px)' }} />
                                    <HorizontalRuleIcon sx={{ color: ifCond?.type === 'notEqualTo' ? '#fff' : blue[500], fontSize: 20, transform: 'translateY(-6px)' }} />
                                    <Box sx={{ position: 'absolute', width: 24, height: 2, bgcolor: ifCond?.type === 'notEqualTo' ? '#fff' : blue[500], transform: 'rotate(-60deg)' }} />
                                  </Button>
                                  <Button id={'if-type-empty'} variant={ifCond?.type === 'empty' ? 'contained' : 'outlined'} onClick={() => handleIfCheckType('empty', ifCondIndex)} sx={{ px: 1, height: 30, bgcolor: ifCond?.type === 'empty' ? blue[500] : 'transparent', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                    <Typography variant='subtitle2' sx={{ color: ifCond?.type === 'empty' ? '#fff' : blue[500], fontSize: 12 }}>empty</Typography>
                                  </Button>
                                  <Button id={'if-type-not-empty'} variant={ifCond?.type === 'notEmpty' ? 'contained' : 'outlined'} onClick={() => handleIfCheckType('notEmpty', ifCondIndex)} sx={{ px: 1, height: 30, bgcolor: ifCond?.type === 'notEmpty' ? blue[500] : 'transparent', borderRadius: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textTransform: 'none' }}>
                                    <Typography variant='subtitle2' sx={{ color: ifCond?.type === 'notEmpty' ? '#fff' : blue[500], fontSize: 12 }}>not empty</Typography>
                                  </Button>
                                </ButtonGroup>
                                {(ifCond?.type !== 'empty' && ifCond?.type !== 'notEmpty')
                                  ?
                                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 300 }}>
                                    <RenderInput
                                      item={foundItem}
                                      data={{ [foundItem?.variable]: ifCond?.value }}
                                      handleChange={(name, value) => handleIfValue(value, ifCondIndex)}
                                      selectedOptions={selectedOptions}
                                      setSelectedOptions={setSelectedOptions}
                                    />
                                  </Box>
                                  :
                                  null
                                }
                              </Fragment>
                              :
                              null
                            }
                            {ifCondIndex > 0
                              ?
                              <Tooltip id='removeField' title='Remove if condition'>
                                <IconButton id={"remove-if-btn"} color="error" onClick={() => handleRemoveIf(ifCondIndex)}>
                                  <Cancel fontSize='small' />
                                </IconButton>
                              </Tooltip>
                              :
                              null
                            }
                          </Box>
                        )
                      })}
                      <Box sx={{ width: 2, height: 20, ml: 2, bgcolor: blue[100] }} />
                      <Button id={"add-if-cond-btn"} onClick={handleAddNewIf} variant='outlined' sx={{ mt: 0.5, width: 220, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                        <AddCircleIcon sx={{ fontSize: 20 }} />
                        <Typography variant='subtitle2'>Add a new if condition</Typography>
                      </Button>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 140, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 0.5 }}>
                      <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <AltRouteIcon sx={{ color: '#fff', fontSize: 20, transform: 'rotate(180deg)' }} />
                      </Box>
                      <Typography variant='subtitle2' color={blue[500]}>THEN</Typography>
                    </Box>
                    <Box sx={{ width: 2, height: 20, ml: 2, bgcolor: blue[100] }} />
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, my: 0.5, p: 1.5, border: '1px solid ' + blue[100], borderRadius: 2, position: 'relative', }}>
                      {currentData?.type === 'location'
                        ?
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                          {currentData?.address?.label ?
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                              <Typography variant='subtitle2' sx={{ color: blue[500], }}>{currentData?.address?.label || ""} :</Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant='subtitle2'>Visibility</Typography>
                                <ButtonGroup>
                                  <Button
                                    id={currentData?.address?.variable + '-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility1(true);
                                      setRequiredField1(true);
                                    }}
                                    variant={fieldVisibility1 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Visible
                                  </Button>
                                  <Button
                                    id={currentData?.address?.variable + '-not-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility1(false);
                                      setRequiredField1(false);
                                    }}
                                    variant={!fieldVisibility1 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Hidden
                                  </Button>
                                </ButtonGroup>
                              </Box>
                              {
                                fieldVisibility1
                                  ?
                                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                    <Typography variant='subtitle2'>Required</Typography>
                                    <ButtonGroup>
                                      <Button id={currentData?.address?.variable + '-required-btn'} onClick={() => setRequiredField1(true)} variant={requiredField1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                      <Button id={currentData?.address?.variable + '-not-required-btn'} onClick={() => setRequiredField1(false)} variant={!requiredField1 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                    </ButtonGroup>
                                  </Box>
                                  :
                                  null
                              }
                            </Box>
                            :
                            null
                          }
                          {currentData?.country?.label
                            ?
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                              <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.country?.label || ""} :</Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant='subtitle2'>Visibility</Typography>
                                <ButtonGroup>
                                  <Button
                                    id={currentData?.country?.variable + '-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility2(true);
                                      setRequiredField2(true);
                                    }}
                                    variant={fieldVisibility2 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Visible
                                  </Button>
                                  <Button
                                    id={currentData?.country?.variable + '-not-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility2(false);
                                      setRequiredField2(false);
                                    }}
                                    variant={!fieldVisibility2 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Hidden
                                  </Button>
                                </ButtonGroup>
                              </Box>
                              {
                                fieldVisibility2
                                  ?
                                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                    <Typography variant='subtitle2'>Required</Typography>
                                    <ButtonGroup>
                                      <Button id={currentData?.country?.variable + '-required-btn'} onClick={() => setRequiredField2(true)} variant={requiredField2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                      <Button id={currentData?.country?.variable + '-not-required-btn'} onClick={() => setRequiredField2(false)} variant={!requiredField2 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                    </ButtonGroup>
                                  </Box>
                                  :
                                  null
                              }
                            </Box>
                            :
                            null
                          }
                          {currentData?.state?.label
                            ?
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                              <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.state?.label || ""} :</Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant='subtitle2'>Visibility</Typography>
                                <ButtonGroup>
                                  <Button
                                    id={currentData?.state?.variable + '-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility3(true);
                                      setRequiredField3(true);
                                    }}
                                    variant={fieldVisibility3 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Visible
                                  </Button>
                                  <Button
                                    id={currentData?.state?.variable + '-not-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility3(false);
                                      setRequiredField3(false);
                                    }}
                                    variant={!fieldVisibility3 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Hidden
                                  </Button>
                                </ButtonGroup>
                              </Box>
                              {
                                fieldVisibility3
                                  ?
                                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                    <Typography variant='subtitle2'>Required</Typography>
                                    <ButtonGroup>
                                      <Button id={currentData?.state?.variable + '-required-btn'} onClick={() => setRequiredField3(true)} variant={requiredField3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                      <Button id={currentData?.state?.variable + '-not-required-btn'} onClick={() => setRequiredField3(false)} variant={!requiredField3 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                    </ButtonGroup>
                                  </Box>
                                  :
                                  null
                              }
                            </Box>
                            :
                            null
                          }
                          {currentData?.city?.label
                            ?
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                              <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.city?.label || ""} :</Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant='subtitle2'>Visibility</Typography>
                                <ButtonGroup>
                                  <Button
                                    id={currentData?.city?.variable + '-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility4(true);
                                      setRequiredField4(true);
                                    }}
                                    variant={fieldVisibility4 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Visible
                                  </Button>
                                  <Button
                                    id={currentData?.city?.variable + '-not-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility4(false);
                                      setRequiredField4(false);
                                    }}
                                    variant={!fieldVisibility4 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Hidden
                                  </Button>
                                </ButtonGroup>
                              </Box>
                              {
                                fieldVisibility4
                                  ?
                                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                    <Typography variant='subtitle2'>Required</Typography>
                                    <ButtonGroup>
                                      <Button id={currentData?.city?.variable + '-required-btn'} onClick={() => setRequiredField4(true)} variant={requiredField4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                      <Button id={currentData?.city?.variable + '-not-required-btn'} onClick={() => setRequiredField4(false)} variant={!requiredField4 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                    </ButtonGroup>
                                  </Box>
                                  :
                                  null
                              }
                            </Box>
                            :
                            null
                          }
                          {currentData?.zipCode?.label
                            ?
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
                              <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.zipCode?.label || ""}</Typography>
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant='subtitle2'>Visibility</Typography>
                                <ButtonGroup>
                                  <Button
                                    id={currentData?.zipCode?.variable + '-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility5(true);
                                      setRequiredField5(true);
                                    }}
                                    variant={fieldVisibility5 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Visible
                                  </Button>
                                  <Button
                                    id={currentData?.zipCode?.variable + '-not-visible-btn'}
                                    onClick={() => {
                                      setFieldVisibility5(false);
                                      setRequiredField5(false);
                                    }}
                                    variant={!fieldVisibility5 ? 'contained' : 'outlined'}
                                    size='small'
                                    sx={{ textTransform: 'none' }}
                                  >
                                    Hidden
                                  </Button>
                                </ButtonGroup>
                              </Box>
                              {
                                fieldVisibility5
                                  ?
                                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                    <Typography variant='subtitle2'>Required</Typography>
                                    <ButtonGroup>
                                      <Button id={currentData?.zipCode?.variable + '-required-btn'} onClick={() => setRequiredField5(true)} variant={requiredField5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                      <Button id={currentData?.zipCode?.variable + '-not-required-btn'} onClick={() => setRequiredField5(false)} variant={!requiredField5 ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                    </ButtonGroup>
                                  </Box>
                                  :
                                  null
                              }
                            </Box>
                            :
                            null
                          }
                        </Box>
                        :
                        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Typography variant='subtitle2'>Field:</Typography>
                            <Typography variant='subtitle2' sx={{ color: blue[500] }}>{currentData?.label || ""}</Typography>
                          </Box>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Typography variant='subtitle2'>Visibility</Typography>
                            <ButtonGroup>
                              <Button
                                id={currentData?.variable + '-visible-btn'}
                                onClick={() => {
                                  setFieldVisibility(true);
                                  setRequiredField(true);
                                }}
                                variant={fieldVisibility ? 'contained' : 'outlined'}
                                size='small'
                                sx={{ textTransform: 'none' }}
                              >
                                Visible
                              </Button>
                              <Button
                                id={currentData?.variable + '-not-visible-btn'}
                                onClick={() => {
                                  setFieldVisibility(false);
                                  setRequiredField(false);
                                }}
                                variant={!fieldVisibility ? 'contained' : 'outlined'}
                                size='small'
                                sx={{ textTransform: 'none' }}
                              >
                                Hidden
                              </Button>
                            </ButtonGroup>
                          </Box>
                          {
                            fieldVisibility5
                              ?
                              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant='subtitle2'>Required</Typography>
                                <ButtonGroup>
                                  <Button id={currentData?.variable + '-required-btn'} onClick={() => setRequiredField(true)} variant={requiredField ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>Yes</Button>
                                  <Button id={currentData?.variable + '-not-required-btn'} onClick={() => setRequiredField(false)} variant={!requiredField ? 'contained' : 'outlined'} size='small' sx={{ textTransform: 'none' }}>No</Button>
                                </ButtonGroup>
                              </Box>
                              :
                              null
                          }
                        </Box>
                      }
                    </Box>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', gap: 1 }}>
                      <Button id={'cancle-cond-btn'} onClick={handleCloseCond} size='small' variant='text' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mt: 2 }}>Cancel</Button>
                      <Button id={'save-cond-btn'} onClick={handleAddNewCond} size='small' variant='outlined' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mt: 2 }}>Save</Button>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                :
                <ButtonGroup>
                  <Button id={'add-new-cond-btn'} onClick={handleShowAddNewCond} variant='outlined' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                    <AddCircleIcon sx={{ fontSize: 20 }} />
                    <Typography variant='subtitle2'>Add new condition</Typography>
                  </Button>
                  {!conditions.length && currentData?.type !== 'location'
                    ?
                    <Button id={'copy-cond-btn'} onClick={handleCopyFromPrevious} variant='outlined' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                      <AddCircleIcon sx={{ fontSize: 20 }} />
                      <Typography variant='subtitle2'>Copy from previous input</Typography>
                    </Button>
                    :
                    null
                  }
                </ButtonGroup>
            }
          </Box>

        </Box>
      </DialogContent>
      <DialogActions>
        <Button id='form-cancel-btn' onClick={handleClose}>Cancel</Button>
        <Button id='form-update-btn' onClick={handleUpdateRules} variant='contained'>Update</Button>
      </DialogActions>
    </Dialog>
  )
}

const RenderInput = ({ item = null, data = null, selectedOptions = null, setSelectedOptions = () => { }, handleChange = () => { } }) => {
  const [options, setOptions] = useState(null);
  const [loading, setLoading] = useState([]);

  const handleSearch = async (name = "", value = "", key = "") => {
    setLoading(prev => [...prev, name]);
    let url = `/masterAdmin/constant?fieldType=${name}&search=${value}`;
    if (name === 'vendor') {
      url = `/masterAdmin/vendor?vendorName=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'id', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoading(prev => prev.filter(item => item !== name));
    } else if (name === 'businessUnit' || name === 'clients' || name === 'endclients' || name === 'domains' || name === 'hr') {
      url = `/masterAdmin/${name}?search=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'name', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoading(prev => prev.filter(item => item !== name));
    } else {
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoading(prev => prev.filter(item => item !== name));
    }
  }

  if (item?.type === 'text') {
    return (
      <Box sx={{ width: '100%' }}>
        <TextField
          placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
          sx={{ width: "100%", background: "#FFFFFF" }}
          inputProps={{ step: 0.01, maxLength: item?.textLength || 524288 }}
          required={item?.required || false}
          InputProps={{
            startAdornment: <InputAdornment position="start">{item?.prefilled || ""}</InputAdornment>,
          }}
          type={item?.decimal === 'decimal-number' ? 'number' : 'text'}
          value={data?.[item.variable] || ""}
          onChange={(e) => handleChange(item.variable, e?.target?.value)}
          id={item.variable}
          name={item.variable}
        />
      </Box>
    )
  } else if (item?.type === 'email') {
    return (
      <Box sx={{ width: '100%' }}>
        <TextField
          placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
          sx={{ width: "100%", background: "#FFFFFF" }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{item?.prefilled || ""}</InputAdornment>,
          }}
          type='email'
          value={data?.[item.variable] || ""}
          onChange={(e) => handleChange(item.variable, e?.target?.value)}
          id={item.variable}
          name={item.variable}
        />
      </Box>
    )
  } else if (item?.type === 'phone') {
    return (
      <Box sx={{ width: '100%' }}>
        <PhoneNumberInput
          value={data?.[item.variable] || ""}
          handleChange={(value) => handleChange(item.variable, value)}
          id={item.variable}
          name={item.variable}
        />
      </Box>
    )
  } else if (item?.type === 'date') {
    return (
      <Box sx={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(inputProps) =>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                variant="outlined"
                sx={{ width: "100%", background: "#FFFFFF" }}
                value={data?.[item.variable] || ""}
                onChange={(e) => handleChange(item.variable, e?.target?.value)}
                id={item.variable}
                name={item.variable}
              />}
          />
        </LocalizationProvider>
      </Box>
    )
  } else if (item?.type === 'dropdown') {
    return (
      <Box sx={{ width: '100%' }}>
        <FormControl fullWidth>
          <Select
            sx={{ width: "100%", background: "#FFFFFF" }}
            displayEmpty
            value={data?.[item.variable] || ""}
            onChange={(e) => handleChange(item?.variable, e?.target?.value)}
            id={item.variable}
          >
            <MenuItem disabled value="">{"Please select a " + (item?.label || "").toLowerCase()}</MenuItem>
            {(extractValuableOptions(item?.options || [])).map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>
    )
  } else if (item?.type === 'searchable-dropdown') {
    return (
      <Box sx={{ width: '100%', minWidth: 200 }}>
        <LocalSearchableDropdown
          id={item.variable}
          multiple={item?.multiple}
          label={item?.label}
          value={item?.multiple ? data?.[item.variable] || [] : data?.[item.variable] || null}
          handleChange={(v) => {
            if (item?.multiple) {
              let oldValues = data?.[data.variable] || [];
              let intersection = [];
              if (v.length > oldValues.length) {
                intersection = v.filter(x => !oldValues.includes(x));
                if (intersection.length) {
                  const option = getOptionFromList(options[item.variable], intersection[0]);
                  if (option?.value) {
                    setSelectedOptions(prev => {
                      let oldOptions = prev?.[item.searchVariable] || [];
                      let op = [...oldOptions, option];
                      let list = [...new Map(op.map(item => [item.value, item])).values()];
                      return ({
                        ...prev,
                        [item.searchVariable]: list,
                      });
                    });
                  }
                }
              } else {
                intersection = oldValues.filter(x => !v.includes(x));
                if (intersection.length) {
                  setSelectedOptions(prev => {
                    let oldOptions = prev?.[item.searchVariable] || [];
                    oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                    return ({
                      ...prev,
                      [item.searchVariable]: oldOptions,
                    });
                  });
                }
              }
              handleChange(item.variable, v);
            } else {
              handleChange(item.variable, v?.value);
              if (v?.value) {
                setSelectedOptions(prev => {
                  let oldOptions = prev?.[item.searchVariable] || [];
                  let op = [...oldOptions, v];
                  let list = [...new Map(op.map(item => [item.value, item])).values()];
                  return ({
                    ...prev,
                    [item.searchVariable]: list,
                  });
                });
              } else {
                setSelectedOptions(prev => ({
                  ...prev,
                  [item.searchVariable]: [],
                }));
              }
            }
          }}
          addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
          options={options?.[item.variable] || []}
          handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
          loading={loading.includes(item?.searchVariable)}
          matchingOptions={selectedOptions?.[item.searchVariable] || []}
          tooltipVariables={item?.tooltipVariables || []}
          showTooltip={item?.tooltipVariables?.length}
        />
      </Box>
    )
  } else if (item?.type === 'plan') {
    return (
      <Box sx={{ width: '100%' }}>
        <LocalSearchableDropdown
          id={item.variable}
          multiple={item?.multiple}
          label={item?.label}
          value={item?.multiple ? data?.[item.variable] || [] : data?.[item.variable] || null}
          handleChange={(v) => {
            if (item?.multiple) {
              let oldValues = data?.[item.variable] || [];
              let intersection = [];
              if (v.length > oldValues.length) {
                intersection = v.filter(x => !oldValues.includes(x));
                if (intersection.length) {
                  const option = getOptionFromList(options[item.variable], intersection[0]);
                  if (option?.value) {
                    setSelectedOptions(prev => {
                      let oldOptions = prev?.[item.searchVariable] || [];
                      let op = [...oldOptions, option];
                      let list = [...new Map(op.map(item => [item.value, item])).values()];
                      return ({
                        ...prev,
                        [item.searchVariable]: list,
                      });
                    });
                  }
                }
              } else {
                intersection = oldValues.filter(x => !v.includes(x));
                if (intersection.length) {
                  setSelectedOptions(prev => {
                    let oldOptions = prev?.[item.searchVariable] || [];
                    oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                    return ({
                      ...prev,
                      [item.searchVariable]: oldOptions,
                    });
                  });
                }
              }
              handleChange(item.variable, v);
            } else {
              if (v?.value) {
                setSelectedOptions(prev => {
                  let oldOptions = prev?.[item.searchVariable] || [];
                  let op = [...oldOptions, v];
                  let list = [...new Map(op.map(item => [item.value, item])).values()];
                  return ({
                    ...prev,
                    [item.searchVariable]: list,
                  });
                });
              } else {
                setSelectedOptions(prev => ({
                  ...prev,
                  [item.searchVariable]: [],
                }));
              }
              handleChange(item.variable, v?.value);
            }
          }}
          addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
          options={options?.[item.variable] || []}
          handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
          loading={loading.includes(item?.searchVariable)}
          matchingOptions={selectedOptions?.[item.searchVariable] || []}
        />
      </Box>
    )
  }
  return null;
}

export default FormRules;