import { PureComponent } from 'react';
import calculateSize from 'calculate-size';

class RenderCustomBarLabel extends PureComponent {
  render() {
    let { x, y, value, width, height, unit } = this.props;
    let finalLabel = value || "";
    let rotate = false;
    if (unit === '$') {
      finalLabel = unit + value;
    } else if (unit) {
      finalLabel = value + unit;
    }
    if (width < 60) {
      rotate = true;
    }
    const size = calculateSize(finalLabel, {
      font: 'Poppins',
      fontSize: '14px',
    });
    if (value) {
      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            fill='#334155'
            fontSize={14}
            fontFamily='Poppins'
            fontWeight='bold'
            transform={rotate ? `rotate(-90) translate(${-(height / 2) - (size.height / 2)},${(width / 2) + 5})` : `rotate(0) translate(${(width / 2) - (size.width / 2)},${(height / 2)})`}
          >
            {finalLabel}
          </text>
        </g>
      )
    }
    return null
  }
}

export default RenderCustomBarLabel;