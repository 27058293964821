import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { COLORS, extractBarDataPoints } from '../../utils/chartHelper';
import { Box, Typography } from '@mui/material';
import NoDataCard from './noDataCard';

const GaugeCard = ({ data = null, visualizationData = null, title = "", showAnimation = true }) => {
  const [list, setList] = useState([]);
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    const d = extractBarDataPoints(data, visualizationData, title);
    let newList = [];
    let dList = d?.list || [];
    if (dList.length) {
      newList.push({
        value: dList[0].name,
        name: 1,
        color: COLORS[newList.length],
      });
      newList.push({
        value: 100 - dList[0].name,
        name: 2,
        color: COLORS[newList.length],
      });
    }
    setList(newList);
    setTitle(d?.title || "");
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  return (
    <Box sx={{ width: '100%', height: '100%', pb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ width: '100%', height: '90%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              startAngle={220}
              endAngle={-40}
              innerRadius="60%"
              data={list}
              dataKey="value"
              labelLine={false}
              blendStroke
              cy="60%"
              cx='50%'
              outerRadius={'100%'}
              isAnimationActive={showAnimation}
            >
              {list.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {list.length ? <Typography sx={{ fontSize: '54px', fontWeight: 'bold', position: 'absolute', transform: 'translateY(40%)' }}>{list[0]?.value}</Typography> : null}
      </Box>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
    </Box>
  )
}

export default GaugeCard;