import { Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { blue, grey, red } from "@mui/material/colors";
import { formatDashboardName } from "../../../../utils/chartHelper";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddIcon from '@mui/icons-material/Add';
import GroupCUModal from "./GroupCUModal";
import GroupVisibilityToggleModal from "./GroupVisibilityToggleModal";
import ClearIcon from '@mui/icons-material/Clear';
import GroupDeleteModal from "./GroupDeleteModal";
import { DataGrid } from "@mui/x-data-grid";
import GroupUserCUModal from "./GroupUserCUModal";
import DeleteIcon from '@mui/icons-material/Delete';
import GroupUserDeleteModal from "./GroupUserDeleteModal";
import moment from "moment";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FormConfiguration from "./FormConfiguration";
import { TabNav, TabPanel } from "../SettingsScreen";
import { addConfigurationUser, createConfigurationGroup, deleteConfigurationGroup, deleteConfigurationUser, getConfigurationGroups, getConfigurationUsers, updateConfigurationGroup } from "../../../../store/Settings/action";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';

const FormDesigner = ({ hasWritePermission = false }) => {
  const dispatch = useDispatch();
  const { configurationGroupList = [], loadingConfigurationGroups = false, configurationUserList = [], loadingConfigurationUsers = false } = useSelector((state) => state.settingsConstants);

  const [activeTab, setActiveTab] = useState(0);
  const [activeGroup, setActiveGroup] = useState(null);
  const [currentGroupData, setCurrentGroupData] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentUserData, setCurrentUserData] = useState(null);
  const [showUserAddModal, setShowUserAddModal] = useState(false);
  const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);

  useEffect(() => {
    dispatch(getConfigurationGroups());
  }, []);

  useEffect(() => {
    if (activeGroup?.id && activeTab === 1) {
      dispatch(getConfigurationUsers({ group_id: activeGroup.id }));
    }
  }, [activeTab]);


  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleNavigateGroup = (group) => {
    setActiveGroup(group);
  }

  const handleBack = () => {
    setActiveGroup(null);
    setActiveTab(0);
  }

  const handleShowAddNewGroup = () => {
    setShowAddModal(true);
    setCurrentGroupData(null);
  }

  const handleCloseAddModal = () => {
    setShowAddModal(false);
    setCurrentGroupData(null);
  }

  const handleAddNewGroup = (e) => {
    setShowAddModal(false);
    dispatch(createConfigurationGroup(e, (res) => {
      if (res.status) {
        toast.success("Successfully, added a new group", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getConfigurationGroups());
      } else {
        toast.error((res?.message || "").includes('Duplicate') ? 'Group already exist!' : res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleShowEditGroup = (group) => {
    setShowAddModal(true);
    setCurrentGroupData(group);
  }

  const handleEditGroup = (e) => {
    setShowAddModal(false);
    dispatch(updateConfigurationGroup(e, (res) => {
      if (res.status) {
        toast.success("Updated group information successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getConfigurationGroups());
      } else {
        toast.error(res.message || "Failed to update group information", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleShowVisibilityModal = (group) => {
    setShowVisibilityModal(true);
    setCurrentGroupData(group);
  }

  const handleToggleVisibilityGroup = (e) => {
    setShowAddModal(false);
    dispatch(updateConfigurationGroup(e, (res) => {
      if (res.status) {
        setShowVisibilityModal(false);
        toast.success("Updated group information successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getConfigurationGroups());
      } else {
        toast.error(res.message || "Failed to update group information", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleCloseVisibilityModal = () => {
    setShowVisibilityModal(false);
    setCurrentGroupData(null);
  }

  const handleShowDeleteGroup = (e) => {
    setShowDeleteModal(true);
    setCurrentGroupData(e);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrentGroupData(null);
  }

  const handleDeleteGroup = (e) => {
    setShowDeleteModal(false);
    dispatch(deleteConfigurationGroup(e, (res) => {
      if (res.status) {
        toast.success("Group removed successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setCurrentGroupData(null);
        dispatch(getConfigurationGroups());
      } else {
        toast.error('Failed to remove this group!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleShowAddUser = () => {
    setShowUserAddModal(true);
  }

  const handleCloseAddUser = () => {
    setShowUserAddModal(false);
  }

  const handleAddNewUser = (e) => {
    setShowUserAddModal(false);
    let payload = {
      group_id: activeGroup.id,
      ...e,
    }
    dispatch(addConfigurationUser(payload, (res) => {
      if (res.status) {
        toast.success("Successfully, added a new user", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getConfigurationUsers({ group_id: activeGroup.id }));
      } else {
        toast.error((res?.message || "").includes('Duplicate') ? 'User already exist!' : res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleShowDeleteUser = (e) => {
    setShowUserDeleteModal(true);
    setCurrentUserData(e);
  }

  const handleCloseUserDeleteModal = () => {
    setShowUserDeleteModal(false);
    setCurrentUserData(null);
  }

  const handleDeleteUser = (e) => {
    setShowUserDeleteModal(false);
    const payload = {
      group_id: activeGroup.id,
      ...e,
    }
    dispatch(deleteConfigurationUser(payload, (res) => {
      if (res.status) {
        toast.success("User removed successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setCurrentUserData(null);
        dispatch(getConfigurationUsers({ group_id: activeGroup.id }));
      } else {
        toast.error('Failed to remove this user!', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  let usersColumns = [
    {
      field: "id",
      flex: 1,
      headerName: "ID",
      maxWidth: 80,
    },
    {
      field: "name",
      flex: 1,
      headerName: "Name",
      minWidth: 150,
    },
    {
      field: "email",
      flex: 1,
      headerName: "Email",
      minWidth: 150,
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Joined",
      minWidth: 150,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
  ];

  if (hasWritePermission) {
    usersColumns.push({
      flex: 1,
      headerName: "Actions",
      minWidth: 150,
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id='delete' title='Remove'>
                <IconButton aria-label='delete' size='small' color='error' onClick={() => handleShowDeleteUser(rows)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },)
  }

  return (
    <Box sx={{ pb: 4 }}>
      {loadingConfigurationGroups || loadingConfigurationUsers ? <OverlayLoader /> : null}
      {activeGroup?.id
          ?
          <Box sx={{ width: '100%', mt: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', px: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box onClick={handleBack} sx={{ bgcolor: 'white', border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 2, width: 35, height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', color: blue[600], pr: 0.5, mr: 2, cursor: 'pointer' }}>
                  <ArrowBackIosNewIcon />
                </Box>
                <Typography sx={{ fontWeight: 'medium', fontSize: '24px', color: '#475569', fontFamily: 'Poppins', fontWeight: 600 }}>{formatDashboardName(activeGroup.name)}</Typography>
              </Box>
              <Box sx={{ px: 2 }}>
                <TabNav list={['Configuration', 'Permissions']} id="configurator" value={activeTab} handleChange={handleChangeTab} />
              </Box>
            </Box>
            <TabPanel id="configurator" value={activeTab} index={0}>
              <FormConfiguration activeGroup={activeGroup} hasWritePermission={hasWritePermission} />
            </TabPanel>
            <TabPanel id="configurator" value={activeTab} index={1}>
              <Box sx={{ width: '100%', px: 2 }}>
                <Box sx={{ width: '100%', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                  <Typography sx={{ fontWeight: 'medium', fontSize: '20px', color: '#475569', my: 2 }}>User List</Typography>
                  {hasWritePermission
                    ?
                    <Button id='add-new-user-btn' onClick={handleShowAddUser} variant="contained" startIcon={<AddIcon />}>
                      Add New
                    </Button>
                    :
                    null
                  }
                </Box>
                <DataGrid
                  rows={configurationUserList}
                  columns={usersColumns}
                  pagination
                  density="comfortable"
                  disableSelectionOnClick
                  disableRowSelectionOnClick
                  rowsPerPageOptions={[]}
                  headerHeight={24}
                  autoHeight={true}
                  sx={{
                    border: 0,
                    "& .MuiDataGrid-columnHeaderTitle": {
                      color: "#1089FF",
                    },
                    "& .MuiDataGrid-cell": {
                      margin: 0,
                      border: 0,
                      width: 300,
                    },
                    "& .MuiDataGrid-row": {
                      margin: 0,
                    },
                    "& .MuiDataGrid-iconSeparator": {
                      display: "none",
                    },
                  }}
                />
              </Box>
            </TabPanel>
          </Box>
          :
          <Box sx={{ width: '100%' }}>
            <Grid container gap={2} p={2} sx={{ width: '100%' }}>
              {configurationGroupList.map((group, groupIndex) => {
                return (
                  <Grid item xs={2.5} xl={2} key={`dashboard-view-${group.id}`} sx={{ background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '10px', p: 3, width: '100%', height: '270px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', overflow: 'hidden', position: 'relative' }}>
                    <Box sx={{ cursor: 'default', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                      <Box sx={{ borderRadius: '50%', bgcolor: blue[500], width: '80px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '30px', fontWeight: 'bold', color: 'white', textTransform: 'uppercase' }}>{group.name[0]}</Typography>
                      </Box>
                      <Tooltip id={group.name} title={group.name} placement="top">
                        <Typography noWrap sx={{ width: '100%', fontSize: '24px', fontWeight: 'medium', color: 'black', mt: 5, textAlign: 'center' }}>{group?.name || ""}</Typography>
                      </Tooltip>
                    </Box>
                    <Button id={'view-grp-btn-' + (group?.name || "")} onClick={() => handleNavigateGroup(group)}>View</Button>
                    {hasWritePermission
                      ?
                      <Box sx={{ position: 'absolute', left: 0, top: 0, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Tooltip id={`toggle-visibility-grp-tooltip-${(group?.name || "")}`} title='Toggle visibility'>
                          <IconButton id={'toggle-visibility-grp-btn-' + (group?.name || "")} onClick={() => handleShowVisibilityModal(group)}>
                            {group.is_active ?
                              <VisibilityIcon sx={{ color: blue[500] }} />
                              :
                              <VisibilityOffIcon sx={{ color: grey[500] }} />
                            }
                          </IconButton>
                        </Tooltip>
                        <Box sx={{ display: group.is_active ? 'flex' : 'none', flexDirection: 'row', alignItems: 'center' }}>
                          <Tooltip id={`edit-grp-tooltip-${(group?.name || "")}`} title='Edit group name'>
                            <IconButton id={`edit-grp-btn-${(group?.name || "")}`} onClick={() => handleShowEditGroup(group)}>
                              <EditIcon sx={{ color: blue[500], fontSize: 20 }} />
                            </IconButton>
                          </Tooltip>
                          {/* <Tooltip id={`delete-grp-tooltip-${(group?.name || "")}`} title='Delete group'>
                            <IconButton id={`delete-grp-btn-${(group?.name || "")}`} onClick={() => handleShowDeleteGroup(group)}>
                              <ClearIcon sx={{ color: red[500] }}/>
                            </IconButton>
                          </Tooltip> */}
                        </Box>
                      </Box>
                      :
                      null
                    }
                  </Grid>
                )
              })}
              {hasWritePermission
                ?
                <Grid onClick={handleShowAddNewGroup} item xs={2.5} xl={2} sx={{ background: "#FFFFFF", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '10px', p: 3, width: '100%', height: '270px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', overflow: 'hidden', cursor: 'pointer' }}>
                  <AddIcon sx={{ fontSize: 40, color: blue[600] }} />
                </Grid>
                :
                null
              }
            </Grid>
          </Box>
      }
      {
        showAddModal
          ?
          <GroupCUModal
            open={showAddModal}
            handleClose={handleCloseAddModal}
            handleAdd={handleAddNewGroup}
            currentData={currentGroupData}
            handleUpdate={handleEditGroup}
          />
          :
          null
      }
      {showVisibilityModal ?
        <GroupVisibilityToggleModal
          open={showVisibilityModal}
          handleClose={handleCloseVisibilityModal}
          handleVisibility={handleToggleVisibilityGroup}
          currentData={currentGroupData}
        />
        :
        null
      }
      {
        showDeleteModal
          ?
          <GroupDeleteModal
            open={showDeleteModal}
            handleClose={handleCloseDeleteModal}
            handleDelete={handleDeleteGroup}
            currentData={currentGroupData}
          />
          :
          null
      }
      {
        showUserAddModal
          ?
          <GroupUserCUModal
            open={showUserAddModal}
            handleClose={handleCloseAddUser}
            handleAdd={handleAddNewUser}
          />
          :
          null
      }
      {
        showUserDeleteModal
          ?
          <GroupUserDeleteModal
            open={showUserDeleteModal}
            handleClose={handleCloseUserDeleteModal}
            handleDelete={handleDeleteUser}
            currentData={currentUserData}
          />
          :
          null
      }
    </Box>
  )
}


const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4 }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

export default FormDesigner;