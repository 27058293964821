import { Fragment, useEffect, useState } from "react";
import { Box, Button, ButtonGroup, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from "@mui/material";
import { Add, Cancel, Edit, } from "@mui/icons-material";
import { toast } from "react-toastify";
import TabCUModal from "./TabCUModal";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import calculateSize from 'calculate-size';
import FormModal from "./FormModal";
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { blue, green, orange, red, grey } from "@mui/material/colors";
import _ from "lodash";
import { TabNav, TabPanel } from "../SettingsScreen";
import FormRules from "./FormRules";
import { cityOptions, convertSearchedListToOptions, convertToRequiredTabFormat, countryOptions, extractValuableOptions, getAllFieldsFromTabList, getOptionFromList, insertIntoArray, stateOptions } from "../../../../utils/consultantConstants";
import api from "../../../../config/https";
import DividerComponent from "../../../../components/Divider/Divider";
import PhoneNumberInput from "../../../../components/PhoneNumberInput/PhoneNumberInput";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LocalSearchableDropdown from "../../../../components/LocalSearchableDropdown";
import RuleIcon from '@mui/icons-material/Rule';
import { useDispatch, useSelector } from "react-redux";
import { addConfigurationToGroup, getConfigurationByGroupID, updateConfigurationOfGroup } from "../../../../store/Settings/action";
import { statusDropdown } from "../../../../constants/config";
import FormCopyModal from "./FormCopyModal";

const FormConfiguration = ({ activeGroup = null, hasWritePermission = false }) => {
  const dispatch = useDispatch();
  const { formConfigurations = [], loadingFormConfigurations = false } = useSelector((state) => state.settingsConstants);

  const [tabs, setTabs] = useState([]);
  const [actualTabs, setActualTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [showCopyModal, setShowCopyModal] = useState(false);
  const [showTabModal, setShowTabModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [showFormMaker, setShowFormMaker] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);
  const [mode, setMode] = useState('view');
  const [showFormRulesModal, setShowFormRulesModal] = useState(false);
  const [currentRules, setCurrentRules] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState([]);
  const [options, setOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [fieldList, setFieldList] = useState([]);
  const [loadingTabs, setLoadingTabs] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState(statusDropdown[0].value);
  const [activeFields, setActiveFields] = useState([]);

  useEffect(() => {
    dispatch(getConfigurationByGroupID({ group_id: activeGroup.id }));
  }, []);

  useEffect(() => {
    setLoadingTabs(true);
    if (selectedVariant) {
      setTimeout(() => {
        let foundItem = _.find(formConfigurations, { varient: selectedVariant });
        let t = convertToRequiredTabFormat(foundItem?.tabs || []);
        setActualTabs(t);
        setTabs(t);
        setFieldList(t?.length ? (t[0]?.fields || []) : []);
        setLoadingTabs(false);
        setMode('view');
        setData(null);
        setActiveTab(0);
      }, 150);
    } else {
      setActualTabs([]);
      setTabs([]);
      setFieldList([]);
      setLoadingTabs(false);
      setData(null);
      setActiveTab(0);
    }
  }, [selectedVariant, formConfigurations]);

  useEffect(() => {
    let tabList = [...tabs];
    let list = [];
    tabList.forEach(tab => {
      (tab?.fields || []).forEach(field => {
        list.push(field.id);
      });
    });
    setActiveFields(list);
  }, [tabs]);

  useEffect(() => {
    handleApplyRules();
  }, [data, activeTab]);

  const handleShowEditConfiguration = () => {
    setMode('edit');
    setData(null);
    setTabs(actualTabs);
    setFieldList(actualTabs?.[activeTab]?.fields || []);
  }

  const handleCloseEditConfiguration = () => {
    setMode('view');
    setData(null);
    setTabs(actualTabs);
    setFieldList(actualTabs?.[activeTab]?.fields || []);
  }

  const handleSaveConfiguration = () => {
    let foundItem = _.find(formConfigurations, { varient: selectedVariant });
    let tabData = JSON.parse(JSON.stringify(tabs));
    tabData = tabData?.map(tabItem => ({
      name: tabItem?.name || "",
      variable: tabItem?.variable || "",
      fields: tabItem?.fields?.map(fieldItem => {
        if (fieldItem?.type === 'divider') {
          return fieldItem;
        } else {
          return ({
            id: fieldItem.id,
            locationConfig: fieldItem?.locationConfig || null,
            rules: fieldItem?.rules || [],
            disabled: fieldItem?.disabled || false,
            required: fieldItem?.required || false,
            hidden: fieldItem?.hidden || false
          });
        }
      })
    }));
    if (foundItem?.id) {
      dispatch(updateConfigurationOfGroup({ id: foundItem.id, data: { group_id: activeGroup.id, varient: selectedVariant, tabs: tabData } }, (res) => {
        if (res?.status) {
          toast.success('Configuration saved successfully');
          dispatch(getConfigurationByGroupID({ group_id: activeGroup.id }));
          setMode('view');
          setData(null);
        } else {
          toast.error('Failed to save configuration!');
        }
      }));
    } else {
      dispatch(addConfigurationToGroup({ group_id: activeGroup.id, varient: selectedVariant, tabs: tabData }, (res) => {
        if (res?.status) {
          toast.success('Configuration saved successfully');
          dispatch(getConfigurationByGroupID({ group_id: activeGroup.id }));
          setMode('view');
          setData(null);
        } else {
          toast.error('Failed to save configuration!');
        }
      }));
    }
  }

  const handleChangeActiveTab = (value) => {
    if (value !== activeTab) {
      setActiveTab(value);
      let f = tabs[value].fields
      setFieldList(f);
    }
  }

  const showAddCopyModal = () => {
    setShowCopyModal(true);
  }

  const handleCloseAddCopyModal = () => {
    setShowCopyModal(false);
  }

  const handleCopyConfiguration = (data) => {
    setShowCopyModal(false);
    setLoadingTabs(true);
    setTimeout(() => {
      setTabs(data);
      setActualTabs([]);
      setFieldList(data?.length ? (data[0]?.fields || []) : []);
      setLoadingTabs(false);
      setMode('edit');
      setActiveTab(0);
    }, 500);
  }

  const showAddTabModal = () => {
    setShowTabModal(true);
  }

  const handleHideTabModal = () => {
    setShowTabModal(false);
    setCurrentData(null);
  }

  const handleAddNewTab = (tab) => {
    let tempTabs = JSON.parse(JSON.stringify(tabs));
    tempTabs = tempTabs.filter(item => (item?.name || "").toLowerCase() !== (tab?.name || "").toLowerCase());
    if (tempTabs.length === tabs.length) {
      setShowTabModal(false);
      setTabs(prev => ([
        ...prev,
        { ...tab, id: tabs.length + 1 },
      ]));
      setFieldList([]);
      setActiveTab(tabs.length);
    } else {
      toast.error('Tab already exists!');
    }
    setMode('edit');
  }

  const handleRemoveTab = (name = "") => {
    setTabs(prev => prev.filter(item => item.name !== name));
    setTimeout(() => {
      setActiveTab(0);
      setFieldList(tabs?.[0]?.fields || []);
    }, 10);
  }

  const handleEditTab = (name = "", id = 0) => {
    setShowTabModal(true);
    setCurrentData({ name, id });
  }

  const handleUpdateTab = (tab) => {
    setShowTabModal(false);
    let tempTabs = JSON.parse(JSON.stringify(tabs));
    let foundItem = _.findIndex(tempTabs, { id: tab.id });
    tempTabs.splice(foundItem, 1, tab);
    setTabs(tempTabs);
  }

  const handleShowFormMaker = () => {
    setShowFormMaker(true);
    setCurrentContent(null);
  }

  const handleHideFormMakerModal = () => {
    setShowFormMaker(false);
    setCurrentContent(null);
  }

  const handleAddComponent = (items = [], section = null) => {
    setShowFormMaker(false);
    let tempTabs = JSON.parse(JSON.stringify(tabs));
    let fields = tempTabs[activeTab]?.fields || [];
    let sections = fields.filter(item => item?.type === 'divider');
    if (section && sections.length > 1) {
      let foundIndex = _.findIndex(sections, { id: section });
      if (foundIndex !== sections.length - 1) {
        let foundActualIndex = _.findIndex(fields, { id: sections[foundIndex + 1].id });
        _.reverse(items).forEach(item => {
          fields = insertIntoArray(fields, foundActualIndex, item);
        });
      } else {
        items.forEach(item => {
          fields.push(item);
        });
      }
    } else {
      items.forEach(item => {
        fields.push(item);
      });
    }
    tempTabs[activeTab].fields = fields;
    setTabs(tempTabs);
    setFieldList(fields);
  }

  const handleEditFormContent = (content) => {
    setCurrentContent(content);
    setShowFormMaker(true);
  };

  const handleUpdateComponent = (item) => {
    setShowFormMaker(false);
    let tempTabs = JSON.parse(JSON.stringify(tabs));
    let fields = tempTabs[activeTab]?.fields || [];
    let foundItem = _.findIndex(fields, { id: item.id });
    fields.splice(foundItem, 1, item);
    tempTabs[activeTab].fields = fields;
    setTabs(tempTabs);
    setCurrentContent(null);
    setFieldList(fields);
  }

  const handleRemoveFormContent = (id) => {
    let tempTabs = JSON.parse(JSON.stringify(tabs));
    let fields = tempTabs[activeTab]?.fields || [];
    fields = fields.filter(item => item.id !== id);
    tempTabs[activeTab].fields = fields;
    setTabs(tempTabs);
    setFieldList(fields);
  };

  const handleShowFormRulesModal = (value = null) => {
    setShowFormRulesModal(true);
    setCurrentRules(value);
  }

  const handleCloseFormRulesModal = () => {
    setShowFormRulesModal(false);
    setCurrentRules(null);
  }

  const handleUpdateRules = (payload = null) => {
    let tempTabs = [...tabs];
    let fields = tempTabs[activeTab]?.fields || [];
    let foundItem = _.findIndex(fields, { id: payload.id });
    fields.splice(foundItem, 1, payload);
    tempTabs[activeTab].fields = fields;
    setFieldList(fields);
    setTabs(tempTabs);
    setShowFormRulesModal(false);
    setCurrentRules(null);
  }

  const handleChange = (name = "", value = "") => {
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleApplyRules = () => {
    let foundItem = _.find(formConfigurations, { varient: selectedVariant });
    let tabList = convertToRequiredTabFormat(foundItem?.tabs || []);
    let conditions = [];
    tabList.forEach(tabItem => {
      tabItem?.fields?.forEach(field => {
        if (field?.rules?.length) {
          conditions.push(...field.rules);
        }
      });
    });
    let list = JSON.parse(JSON.stringify(tabs?.[activeTab]?.fields || []));
    conditions.forEach(conditionItem => {
      let conditionSatisfiedCount = 0;
      conditionItem?.conditions?.forEach(condition => {
        let conditionSatisfied = false;
        let key = condition?.key || "";
        let value = data?.[key] || "";
        if (condition?.type === 'equalTo') {
          conditionSatisfied = condition?.value === value;
        } else if (condition?.type === 'notEqualTo') {
          conditionSatisfied = condition?.value !== value;
        } else if (condition?.type === 'empty') {
          conditionSatisfied = !value;
        } else if (condition?.type === 'notEmpty') {
          conditionSatisfied = value ? true : false;
        }
        if (conditionSatisfied) {
          conditionSatisfiedCount++;
        }
      });
      if (conditionSatisfiedCount === conditionItem?.conditions?.length) {
        list?.forEach(item => {
          let variable = item?.variable || "";
          if (item?.type === 'location') {
            variable = item?.address?.variable || item?.country?.variable || item?.state?.variable || item?.city?.variable || item?.zipCode?.variable;
          }
          if ((conditionItem?.variable === variable || conditionItem?.variable === item.confidential) && !item.systemRequired) {
            if (item?.type === 'location') {
              let locationConfig = {};
              locationConfig.address = {
                hidden: !conditionItem.visibility1,
                required: conditionItem.requiredField1,
              };
              locationConfig.country = {
                hidden: !conditionItem.visibility2,
                required: conditionItem.requiredField2,
              };
              locationConfig.state = {
                hidden: !conditionItem.visibility3,
                required: conditionItem.requiredField3,
              };
              locationConfig.city = {
                hidden: !conditionItem.visibility4,
                required: conditionItem.requiredField4,
              };
              locationConfig.zipCode = {
                hidden: !conditionItem.visibility5,
                required: conditionItem.requiredField5,
              };
              item.locationConfig = locationConfig;
            } else {
              item.hidden = !conditionItem.visibility;
              item.required = conditionItem.requiredField;
            }
          }
        });
      }
    });
    setFieldList(list);
  };

  const handleSearch = async (name = "", value = "", key) => {
    setLoading(prev => [...prev, name]);
    let url = `/masterAdmin/constant?fieldType=${name}&search=${value}`;
    if (name === 'vendor') {
      url = `/masterAdmin/vendor?vendorName=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'vendor_name', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoading(prev => prev.filter(item => item !== name));
    } else if (name === 'businessUnit' || name === 'clients' || name === 'endclients' || name === 'domains' || name === 'hr') {
      url = `/masterAdmin/${name}?search=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        if (name === 'businessUnit') {
          let d = convertSearchedListToOptions(list, 'options', 'bu_owner', 'id');
          setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
        } else {
          let d = convertSearchedListToOptions(list, 'options', 'name', 'id');
          setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
        }
      }
      setLoading(prev => prev.filter(item => item !== name));
    } else if (name === 'sdf' || name === 'insurance') {
      if (data?.contractorName) {
        url = `/masterAdmin/${name}?vendorID=${data.contractorName}&search=${value}`;
        let res = await api.get(url);
        if (res?.data?.status && res?.data?.data?.data?.length) {
          let list = res?.data?.data?.data;
          if (name === 'insurance') {
            let d = convertSearchedListToOptions(list, 'options', 'cert_of_insurance_type', 'id');
            setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
          } else {
            let d = convertSearchedListToOptions(list, 'options', name, 'id');
            setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
          }
        }
      } else {
        toast.error('Please enter vendor name');
      }
      setLoading(prev => prev.filter(item => item !== name));
    } else {
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoading(prev => prev.filter(item => item !== name));
    }
  }

  const handleChangeLocationData = (name = "", value = "", belong = "", item) => {
    if (belong === 'country') {
      let op = stateOptions(value);
      setOptions(prev => ({ ...prev, [item.state.variable]: op, [item.city.variable]: [] }));
      setData(prev => ({ ...prev, [name]: value, [item.state.variable]: "", [item.city.variable]: "" }));
    } else if (belong === 'state') {
      let op = cityOptions(data[item.country.variable], value);
      setOptions(prev => ({ ...prev, [item.city.variable]: op }));
      setData(prev => ({ ...prev, [name]: value, [item.city.variable]: "" }));
    } else {
      setData(prev => ({ ...prev, [name]: value }));
    }
  }

  const allFields = getAllFieldsFromTabList(tabs);

  const statusList = [
    ...statusDropdown,
    {
      id: 6,
      value: "Referral",
      label: "Referral",
    },
  ];

  return (
    <Box sx={{ width: '100%', px: 2 }}>
      {loadingFormConfigurations ?
        null
        :
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, flex: '0 0 1', py: 2, overflowX: 'auto' }}>
          {statusList?.map((status, statusIndex) => {
            let active = selectedVariant === status?.value;
            return (
              <Box id={`${status.label}-btn`} onClick={() => setSelectedVariant(status.value)} key={`status-${statusIndex}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${blue[500]}`, bgcolor: active ? blue[500] : '#fff', px: 2, py: '6px', borderRadius: '8px', cursor: active ? 'default' : 'pointer' }}>
                <Typography noWrap variant="subtitle2" sx={{ color: active ? '#fff' : '#475569' }}>{status.label}</Typography>
              </Box>
            )
          })}
        </Box>
      }
      {loadingTabs || loadingFormConfigurations ?
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <CircularProgress size={16} />
          <Typography sx={{ ml: 1, color: '#475569', fontSize: '14px', fontWeight: 500 }}>Fetching configuration...</Typography>
        </Box>
        :
        <Fragment>
          <Box sx={{ width: '100%', display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
            {
              mode === 'edit'
                ?
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 160, p: 1, bgcolor: blue[50], borderRadius: 2 }}>
                  <Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
                    <EditIcon fontSize="small" />
                  </Box>
                  <Typography variant='subtitle2' color={blue[500]}>Edit Mode</Typography>
                </Box>
                :
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 160, p: 1, bgcolor: green[50], borderRadius: 2 }}>
                  <Box sx={{ width: 30, height: 30, bgcolor: green[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
                    <VisibilityIcon fontSize="small" />
                  </Box>
                  <Typography variant='subtitle2' color={green[500]}>Preview Mode</Typography>
                </Box>
            }
            {mode === 'edit'
              ?
              <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <Tooltip id='tooltip-save-config' title='Save'>
                  <IconButton id="save-config-btn" onClick={handleSaveConfiguration} sx={{ backgroundColor: green[50] }} aria-label='edit' size='medium' color='success'>
                    <SaveIcon fontSize='medium' />
                  </IconButton>
                </Tooltip>
                <Tooltip id='tooltip-exit-config' title='Exit'>
                  <IconButton id="exit-config-btn" onClick={handleCloseEditConfiguration} sx={{ backgroundColor: red[50] }} aria-label='edit' size='medium' color='error'>
                    <CloseIcon fontSize='medium' />
                  </IconButton>
                </Tooltip>
              </Box>
              :
              hasWritePermission ?
                <Tooltip id='tooltip-edit-config' title='Edit'>
                  <IconButton id="edit-config-btn" onClick={handleShowEditConfiguration} sx={{ backgroundColor: blue[50] }} aria-label='edit' size='medium' color='primary'>
                    <EditIcon fontSize='medium' />
                  </IconButton>
                </Tooltip>
                :
                null
            }
          </Box>
          {mode === 'edit' ? <Typography variant="subtitle2" sx={{ color: orange[500], mt: 1 }}>Visibility is only applicable in preview mode</Typography> : null}
          {tabs.length
            ?
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflowX: 'auto' }}>
              {mode === 'edit' && tabs.map((tab, tabIndex) => {
                const sizeCheck = calculateSize(tab?.name || "", {
                  font: 'Roboto',
                  fontSize: '15px',
                });
                return (
                  <Box id={`tab-${tab?.name}`} onClick={() => handleChangeActiveTab(tabIndex)} sx={{ flex: `0 0 ${sizeCheck.width + mode === 'edit' ? 100 : 0}px`, px: 2, py: 1, mb: 1, borderBottom: activeTab === tabIndex ? '2px solid #1976D2' : '2px solid transparent', cursor: activeTab === tabIndex ? 'default' : 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'nowrap' }}>
                    <Typography sx={{ fontWeight: 'medium', textTransform: 'uppercase', fontSize: 15, color: activeTab === tabIndex ? '#1976D2' : '#6f6f6f', mr: 1, whiteSpace: 'nowrap' }}>{tab?.name || ""}</Typography>
                    {mode === 'edit'
                      ?
                      <Fragment>
                        <Tooltip id={`tab-${tab?.name}-edit-tooltip`} title='Edit'>
                          <IconButton id={`tab-${tab?.name}-edit-btn`} color="primary" onClick={() => handleEditTab(tab?.name, tabIndex)}>
                            <Edit fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip id={`tab-${tab?.name}-remove-tooltip`} title='Remove'>
                          <IconButton id={`tab-${tab?.name}-remove-btn`} color="error" onClick={() => handleRemoveTab(tab?.name)}>
                            <Cancel fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      </Fragment>
                      :
                      null
                    }
                  </Box>
                )
              })}
              {mode === 'edit'
                ?
                <Tooltip id='addTab' title='Add new tab'>
                  <Button id="add-new-tab-btn" variant="outlined" sx={{ display: 'flex', flex: '0 0 20px', flexDirection: 'row', alignItems: 'center', mx: 0, gap: 1, mb: 1, }} onClick={showAddTabModal}>
                    <AddCircleIcon sx={{ fontSize: 24 }} />
                  </Button>
                </Tooltip>
                :
                null
              }
              {
                mode === 'view'
                  ?
                  <TabNav id="form-preview" list={tabs.map(item => item.name)} value={activeTab} handleChange={(e, v) => handleChangeActiveTab(v)} />
                  :
                  null
              }
            </Box>
            :
            <Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: grey[100], borderRadius: 2, p: 2, mt: 2 }}>
              <Box sx={{ width: 35, height: 35, color: '#475569' }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
              </Box>
              <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>No configuration found</Typography>
              {hasWritePermission ?
                <ButtonGroup>
                  <Button id="add-new-tab-btn" variant="outlined" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mx: 0, gap: 1, mb: 1, }} onClick={showAddTabModal}>
                    <AddCircleIcon sx={{ fontSize: 20 }} />
                    <Typography variant="subtitle2">Add new configuration</Typography>
                  </Button>
                  <Button id="copy-btn" variant="outlined" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mx: 0, gap: 1, mb: 1, }} onClick={showAddCopyModal}>
                    <AddCircleIcon sx={{ fontSize: 20 }} />
                    <Typography variant="subtitle2">Copy from previous</Typography>
                  </Button>
                </ButtonGroup>
                :
                null}
            </Box>
          }
          {tabs.map((tab, tabIndex) => {
            return (
              <Box key={`tab-panel-${tabIndex}`} sx={{ marginTop: 2 }}>
                <TabPanel id="form-preview" value={activeTab} index={tabIndex}>
                  <Grid container spacing={2}>
                    {fieldList.map((field, fieldIndex) => {
                      return (
                        <RenderComponent
                          key={fieldIndex}
                          item={field}
                          index={fieldIndex}
                          data={data}
                          loading={loading}
                          options={options}
                          mode={mode}
                          selectedOptions={selectedOptions}
                          setSelectedOptions={setSelectedOptions}
                          handleSearch={handleSearch}
                          handleChange={handleChange}
                          handleChangeLocationData={handleChangeLocationData}
                          handleEditFormContent={handleEditFormContent}
                          handleRemoveFormContent={handleRemoveFormContent}
                          handleEditRules={handleShowFormRulesModal}
                        />
                      )
                    })}
                  </Grid>
                </TabPanel>
              </Box>
            )
          })}
          {mode === 'edit' && tabs.length
            ?
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 100, p: 2, mt: 2, mx: 'auto', width: '100%', borderRadius: 2, bgcolor: blue[50] }}>
              <Button id="add-new-component-btn" variant="outlined" sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', m: 2, gap: 1, mb: 1, }} onClick={handleShowFormMaker}>
                <AddCircleIcon sx={{ fontSize: 20 }} />
                <Typography variant="subtitle2">Add new component</Typography>
              </Button>
            </Box>
            :
            null
          }
        </Fragment>
      }
      {showTabModal ?
        <TabCUModal
          open={showTabModal}
          currentData={currentData}
          handleClose={handleHideTabModal}
          handleAdd={handleAddNewTab}
          handleUpdate={handleUpdateTab}
        />
        :
        null
      }
      {
        showFormMaker
          ?
          <FormModal
            open={showFormMaker}
            currentData={currentContent}
            handleClose={handleHideFormMakerModal}
            handleAdd={handleAddComponent}
            handleUpdate={handleUpdateComponent}
            sectionOptions={fieldList.filter(item => item?.type === 'divider').map(item => ({ value: item.id, label: item.label }))}
            activeFields={activeFields}
          />
          :
          null
      }
      {
        showFormRulesModal
          ?
          <FormRules
            open={showFormRulesModal}
            currentData={currentRules}
            fields={allFields}
            handleClose={handleCloseFormRulesModal}
            handleUpdate={handleUpdateRules}
          />
          :
          null
      }
      {
        showCopyModal
          ?
          <FormCopyModal
            open={showCopyModal}
            activeVariant={selectedVariant}
            handleClose={handleCloseAddCopyModal}
            handleCopy={handleCopyConfiguration}
          />
          :
          null
      }
    </Box>
  )
}

export const RenderComponent = ({
  item = null,
  index = 0,
  data = null,
  loading = [],
  options = null,
  mode = 'view',
  selectedOptions = null,
  setSelectedOptions = () => { },
  handleChange = () => { },
  handleSearch = () => { },
  handleChangeLocationData = () => { },
  handleEditFormContent = () => { },
  handleRemoveFormContent = () => { },
  handleEditRules = () => { }
}) => {
  if (item.hidden && mode === 'view') {
    return null;
  } else if (item.type === 'divider') {
    return (
      <Grid item xs={12} key={index}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <DividerComponent title={item?.label || ""} />
          {mode === 'edit' ?
            <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Tooltip id={`${item?.label}-edit-tooltip`} title='Edit'>
                <IconButton id={`${item?.label}-edit-btn`} color="primary" onClick={() => handleEditFormContent(item)}>
                  <Edit fontSize='small' />
                </IconButton>
              </Tooltip>
              <Tooltip id={`${item?.label}-rules-tooltip`} title='Rules'>
                <IconButton id={`${item?.label}-edit-rule-btn`} color="primary" onClick={() => handleEditRules(item)}>
                  <RuleIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              <Tooltip id={`${item?.label}-remove-tooltip`} title='Remove'>
                <IconButton id={`${item?.label}-remove-btn` + (index + 1)} color="error" onClick={() => handleRemoveFormContent(item.id)}>
                  <Cancel fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item?.type === 'text') {
    return (
      <Grid item xs={4}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <TextField
            id={item.variable}
            name={item.variable}
            placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            inputProps={{ step: 0.01, maxLength: item?.textLength || 524288 }}
            required={item?.required || false}
            InputProps={{
              startAdornment: <InputAdornment position="start">{item?.prefix || ""}</InputAdornment>,
            }}
            type={item?.decimal ? 'number' : 'text'}
            value={data?.[item.variable] || ""}
            onChange={(e) => {
              let value = e?.target?.value || "";
              if (item?.configVariant === 'number') {
                const numberRegex = /^[0-9]+$/;
                if (item?.decimal) {
                  const decimalNumberRegex = /^[0-9]+\.?[0-9]*$/;
                  if (decimalNumberRegex.test(value) || value === '') {
                    handleChange(item.variable, value, item?.rules);
                  }
                } else if (numberRegex.test(value) || value === '') {
                  handleChange(item.variable, value, item?.rules);
                }
              } else {
                handleChange(item.variable, value, item?.rules);
              }
            }}
            disabled={item.disabled}
          />
          {item?.confidential
            ?
            <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
            :
            null
          }
          {mode === 'edit' ?
            <RenderEditActions item={item} index={index} handleEditRules={handleEditRules} handleRemoveFormContent={handleRemoveFormContent} />
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item?.type === 'email') {
    return (
      <Grid item xs={item?.size || 4} key={'input-' + index}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <TextField
            id={item.variable}
            name={item.variable}
            placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{item?.prefix || ""}</InputAdornment>,
            }}
            type='email'
            value={data?.[item.variable] || ""}
            onChange={(e) => handleChange(item.variable, e?.target?.value, item?.rules)}
            disabled={item.disabled}
          />
          {item?.confidential
            ?
            <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
            :
            null
          }
          {mode === 'edit' ?
            <RenderEditActions item={item} index={index} handleEditRules={handleEditRules} handleRemoveFormContent={handleRemoveFormContent} />
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item.type === 'phone') {
    return (
      <Grid item xs={4}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <PhoneNumberInput
            value={data?.[item.variable] || ""}
            handleChange={(value) => handleChange(item.variable, value, item?.rules)}
            disabled={item.disabled}
            id={item.variable}
            name={item.variable}
          />
          {item?.confidential
            ?
            <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
            :
            null
          }
          {mode === 'edit' ?
            <RenderEditActions item={item} index={index} handleEditRules={handleEditRules} handleRemoveFormContent={handleRemoveFormContent} />
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item.type === 'date') {
    return (
      <Grid item xs={4}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(inputProps) =>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  variant="outlined"
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  value={data?.[item.variable] || ""}
                  onChange={(e) => handleChange(item.variable, e?.target?.value, item?.rules)}
                  disabled={item.disabled}
                  id={item.variable}
                  name={item.variable}
                />}
            />
          </LocalizationProvider>
          {item?.confidential
            ?
            <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
            :
            null
          }
          {mode === 'edit' ?
            <RenderEditActions item={item} index={index} handleEditRules={handleEditRules} handleRemoveFormContent={handleRemoveFormContent} />
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item.type === 'dropdown') {
    return (
      <Grid item xs={4}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <FormControl fullWidth>
            <Select
              sx={{ width: "100%", background: "#FFFFFF" }}
              displayEmpty
              value={data?.[item.variable] || ""}
              onChange={(e) => handleChange(item?.variable, e?.target?.value, item?.rules)}
              disabled={item.disabled}
              id={item.variable}
              name={item.variable}
            >
              <MenuItem disabled value="">{"Please select a " + (item?.label || "").toLowerCase()}</MenuItem>
              {(extractValuableOptions(item?.options || [])).map((option, index) => {
                return (
                  <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          {item?.confidential
            ?
            <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
            :
            null
          }
          {mode === 'edit' ?
            <RenderEditActions item={item} index={index} handleEditRules={handleEditRules} handleRemoveFormContent={handleRemoveFormContent} />
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item?.type === 'searchable-dropdown') {
    return (
      <Grid item xs={4}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <LocalSearchableDropdown
            id={item.variable}
            multiple={item?.multiple}
            label={item?.label}
            value={item?.multiple ? data?.[item.variable] || [] : data?.[item.variable] || null}
            handleChange={(v) => {
              if (item?.multiple) {
                let oldValues = data?.[item.variable] || [];
                let intersection = [];
                if (v.length > oldValues.length) {
                  intersection = v.filter(x => !oldValues.includes(x));
                  if (intersection.length) {
                    const option = getOptionFromList(options[item.variable], intersection[0]);
                    if (option?.value) {
                      setSelectedOptions(prev => {
                        let oldOptions = prev?.[item.searchVariable] || [];
                        let op = [...oldOptions, option];
                        let list = [...new Map(op.map(item => [item.value, item])).values()];
                        return ({
                          ...prev,
                          [item.searchVariable]: list,
                        });
                      });
                    }
                  }
                } else {
                  intersection = oldValues.filter(x => !v.includes(x));
                  if (intersection.length) {
                    setSelectedOptions(prev => {
                      let oldOptions = prev?.[item.searchVariable] || [];
                      oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                      return ({
                        ...prev,
                        [item.searchVariable]: oldOptions,
                      });
                    });
                  }
                }
                handleChange(item.variable, v, item?.rules);
              } else {
                handleChange(item.variable, v?.value, item?.rules);
                if (v?.value) {
                  setSelectedOptions(prev => {
                    let oldOptions = prev?.[item.searchVariable] || [];
                    let op = [...oldOptions, v];
                    let list = [...new Map(op.map(item => [item.value, item])).values()];
                    return ({
                      ...prev,
                      [item.searchVariable]: list,
                    });
                  });
                } else {
                  setSelectedOptions(prev => ({
                    ...prev,
                    [item.searchVariable]: [],
                  }));
                }
              }
            }}
            addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
            options={options?.[item.variable] || []}
            handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
            add={item?.request || false}
            loading={loading.includes(item?.searchVariable)}
            matchingOptions={selectedOptions?.[item.searchVariable] || []}
            tooltipVariables={item?.tooltips || []}
            showTooltip={item?.tooltips?.length}
            disabled={item.disabled}
          />
          {item?.confidential
            ?
            <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
            :
            null
          }
          {mode === 'edit' ?
            <RenderEditActions item={item} index={index} handleEditRules={handleEditRules} handleRemoveFormContent={handleRemoveFormContent} />
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item?.type === 'location') {
    return (
      <Grid item xs={12}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Grid container spacing={2}>
            {(mode === 'edit' || !item?.locationConfig?.address?.hidden) && item?.address?.label
              ?
              <Grid item xs={12}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.address?.label || ""} {item?.locationConfig?.address?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                <TextField
                  value={data?.[item?.address?.variable] || ""}
                  onChange={(e) => handleChangeLocationData(item?.address?.variable, e?.target?.value)}
                  placeholder={"Please enter " + (item?.address?.label || "").toLowerCase() + "!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  disabled={item?.locationConfig?.address?.disabled}
                  id={item.address.variable}
                  name={item.address.variable}
                />
              </Grid>
              :
              null
            }
            {(mode === 'edit' || !item?.locationConfig?.country?.hidden) && item?.country?.label
              ?
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.country?.label || ""} {item?.locationConfig?.country?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                <LocalSearchableDropdown
                  value={data?.[item?.country?.variable] || null}
                  label={item?.country?.label || ""}
                  options={countryOptions}
                  handleChange={(v) => handleChangeLocationData(item?.country?.variable, v?.value, 'country', item)}
                  disabled={item?.locationConfig?.country.disabled}
                  id={item.country.variable}
                />
              </Grid>
              :
              null
            }
            {(mode === 'edit' || !item?.locationConfig?.state?.hidden) && item?.state?.label && options?.[item?.state?.variable]?.length
              ?
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.state?.label || ""} {item?.locationConfig?.state?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                <LocalSearchableDropdown
                  value={data?.[item?.state?.variable] || null}
                  label={item?.state?.label || ""}
                  options={options?.[item?.state?.variable] || []}
                  handleChange={(v) => handleChangeLocationData(item?.state?.variable, v?.value, 'state', item)}
                  disabled={item?.locationConfig?.state?.disabled}
                  id={item.state.variable}
                />
              </Grid>
              :
              null
            }
            {(mode === 'edit' || !item?.locationConfig?.city?.hidden) && item?.city?.label && options?.[item?.city?.variable]?.length
              ?
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.city?.label || ""} {item?.locationConfig?.city?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                <LocalSearchableDropdown
                  value={data?.[item?.city?.variable] || null}
                  label={item?.city?.label || ""}
                  options={options?.[item?.city?.variable] || []}
                  handleChange={(v) => handleChangeLocationData(item?.city?.variable, v?.value, 'city', item)}
                  disabled={item?.locationConfig?.city?.disabled}
                  id={item.city.variable}
                />
              </Grid>
              :
              null
            }
            {(mode === 'edit' || !item?.locationConfig?.zipCode?.hidden) && item?.zipCode?.label
              ?
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.zipCode?.label || ""} {item?.locationConfig?.zipCode?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                <TextField
                  value={data?.[item?.zipCode?.variable] || ""}
                  onChange={(e) => handleChangeLocationData(item?.zipCode?.variable, e?.target?.value, item)}
                  placeholder={"Please enter " + (item?.zipCode?.label || "").toLowerCase() + "!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  disabled={item?.locationConfig?.zipCode?.disabled}
                  id={item.zipCode.variable}
                  name={item.zipCode.variable}
                />
              </Grid>
              :
              null
            }
          </Grid>
          {mode === 'edit' ?
            <RenderEditActions item={item} index={index} handleEditRules={handleEditRules} handleRemoveFormContent={handleRemoveFormContent} />
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item?.type === 'plan') {
    return (
      <Grid item xs={12}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
              <LocalSearchableDropdown
                id={item.variable}
                multiple={item?.multiple}
                label={item?.label}
                value={item?.multiple ? (data?.[item.variable] || []) : data?.[item.variable] || null}
                handleChange={(v) => {
                  if (item?.multiple) {
                    let oldValues = data?.[item.variable] || [];
                    let intersection = [];
                    if (v.length > oldValues.length) {
                      intersection = v.filter(x => !oldValues.includes(x));
                      if (intersection.length) {
                        const option = getOptionFromList(options[item.variable], intersection[0]);
                        if (option?.value) {
                          setSelectedOptions(prev => {
                            let oldOptions = prev?.[item.searchVariable] || [];
                            let op = [...oldOptions, option];
                            let list = [...new Map(op.map(item => [item.value, item])).values()];
                            return ({
                              ...prev,
                              [item.searchVariable]: list,
                            });
                          });
                        }
                      }
                    } else {
                      intersection = oldValues.filter(x => !v.includes(x));
                      if (intersection.length) {
                        setSelectedOptions(prev => {
                          let oldOptions = prev?.[item.searchVariable] || [];
                          oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                          return ({
                            ...prev,
                            [item.searchVariable]: oldOptions,
                          });
                        });
                      }
                    }
                    handleChange(item.variable, v, item?.rules);
                  } else {
                    if (v?.value) {
                      setSelectedOptions(prev => {
                        let oldOptions = prev?.[item.searchVariable] || [];
                        let op = [...oldOptions, v];
                        let list = [...new Map(op.map(item => [item.value, item])).values()];
                        return ({
                          ...prev,
                          [item.searchVariable]: list,
                        });
                      });
                    } else {
                      setSelectedOptions(prev => ({
                        ...prev,
                        [item.searchVariable]: [],
                      }));
                    }
                    handleChange(item.variable, v?.value, item?.rules);
                  }
                }}
                addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
                options={options?.[item.variable] || []}
                handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
                add={item?.request || false}
                loading={loading.includes(item?.searchVariable)}
                matchingOptions={selectedOptions?.[item.searchVariable] || []}
                disabled={item.disabled}
              />
            </Grid>
            {
              data?.[item.variable]
                ?
                selectedOptions?.[item.searchVariable]?.map((selectedPlan, selectedPlanIndex) => {
                  return (
                    <Grid item xs={4} key={selectedPlanIndex}>
                      <Typography variant='subtitle2' sx={{ mb: 1 }}>{selectedPlan?.label || ""} Effective date {item?.systemRequired || item?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          renderInput={(inputProps) => {
                            return (
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                value={data?.[item.variable + "" + selectedPlan?.label] || ""}
                                variant="outlined"
                                sx={{ width: "100%", background: "#FFFFFF" }}
                                onChange={(e) => handleChange(item.variable + "" + selectedPlan?.label, e.target.value)}
                                id={item.variable + "" + selectedPlan?.label}
                                name={item.variable + "" + selectedPlan?.label}
                              />
                            )
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )
                })
                :
                null
            }
          </Grid>
          {mode === 'edit' ?
            <RenderEditActions item={item} index={index} handleEditRules={handleEditRules} handleRemoveFormContent={handleRemoveFormContent} />
            :
            null
          }
        </Box>
      </Grid>
    )
  } else if (item?.type === 'upload') {
    return (
      <Grid item xs={12}>
        <Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
          <Grid container spacing={2}>
            {item?.list?.map((option, optionIndex) => {
              return (
                <Grid item xs={10} key={optionIndex}>
                  <DividerComponent title={option?.title || ""} titleStyle={{ fontSize: 14, fontWeight: 500 }} outerStyle={{ marginTop: 0, }} />
                  <Box>
                    {option?.variants?.map((variant, varientIndex) => {
                      return (
                        <Box key={varientIndex}>
                          <Typography variant="subtitle2">{variant}</Typography>
                          <Typography variant="body1" color={blue[500]}>test_file.pdf</Typography>
                        </Box>
                      )
                    })}
                    {!item?.variants?.length ? <Typography variant="subtitle2">No files</Typography> : null}
                  </Box>
                  <Button variant='text' sx={{ width: 50, height: 50, bgcolor: blue[50], borderRadius: 50, minWidth: 'auto', mt: 2 }}>
                    <Add />
                  </Button>
                </Grid>
              )
            })}
          </Grid>
          {mode === 'edit' ?
            <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
              <Tooltip id={`${item?.list?.[0]?.title || 'upload'}-edit-rules-tooltip`} title='Rules'>
                <IconButton id={`${item?.list?.[0]?.title || 'upload'}-edit-rules-btn`} color="primary" onClick={() => handleEditRules(item)}>
                  <RuleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip id={`${item?.list?.[0]?.title || 'upload'}-remove-tooltip`} title='Remove'>
                <IconButton id={`${item?.list?.[0]?.title || 'upload'}-remove-btn`} color="error" onClick={() => handleRemoveFormContent(item.id)}>
                  <Cancel fontSize='small' />
                </IconButton>
              </Tooltip>
            </Box>
            :
            null
          }
        </Box>
      </Grid>
    )
  }
  return null;
}

const RenderEditActions = ({ item = null, index = 0, handleEditRules = () => { }, handleRemoveFormContent = () => { }, }) => {
  return (
    <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
      <Tooltip id={`${item.label}-edit-rule-tooltip`} title='Rules'>
        <IconButton id={`${item.label}-edit-rule-btn`} color="primary" onClick={() => handleEditRules(item)}>
          <RuleIcon />
        </IconButton>
      </Tooltip>
      <Tooltip id={`${item.label}-remove-tooltip`} title='Remove'>
        <IconButton id={`${item.label}-remove-btn`} color="error" onClick={() => handleRemoveFormContent(item.id)}>
          <Cancel fontSize='small' />
        </IconButton>
      </Tooltip>
    </Box>
  )
}

export default FormConfiguration;