import { Autocomplete, Box, Chip, CircularProgress, TextField, Tooltip, Typography, createFilterOptions } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { convertToServerTime } from '../helpers/timezone';
import { extractValuableOptions } from '../utils/consultantConstants';
import { useDebounce } from '../utils/useDebounce';
import _ from 'lodash';
import ErrorBoundary from './ErrorBoundary';
import { grey } from '@mui/material/colors';

const LocalSearchableDropdown = ({ id = 'local-searchable-dropdown', loading = false, addLabel = '+ Add', add = false, showTooltip = false, tooltipVariables = [], disabled = false, multiple = false, value = null, options = [], matchingOptions = [], label = "", handleChange = () => { }, size = "medium", showLabel = false, handleInputChange = () => { }, max = 10, fields = [] }) => {
  const autoC = useRef(null);
  const [selected, setSelected] = useState(multiple ? [] : null);
  const [searchText, setSearchText] = useState("");

  const debouncedValue = useDebounce(searchText, 100);

  useEffect(() => {
    var result = fetchValue();
    setSelected(result);
  }, [value]);

  useEffect(() => {
    if (debouncedValue && debouncedValue?.length) {
      if (handleInputChange) {
        handleInputChange(debouncedValue);
      }
    }
  }, [debouncedValue]);

  const handleSelection = (e, v, r) => {
    setSelected(v);
    if (multiple) {
      let result = [], newEntry = null;
      v.map(val => {
        if (val?.inputValue) {
          newEntry = val?.inputValue;
        }
        if (val?.value) {
          result.push(val.value)
        }
      });
      if (r === 'clear') {
        handleChange([]);
      } else {
        handleChange(result, newEntry);
      }
    } else {
      if (r === 'clear') {
        handleChange(v);
      } else {
        if (v.inputValue) {
          const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
          if (ele) ele.click();
        }
        handleChange(v);
      }
    }
  }

  const fetchValue = () => {
    let availableOptions = [], availableValues = [], unAvailableValues = [];
    if (multiple && value?.length) {
      options.map(option => {
        if (multiple) {
          if ((value || []).includes(option?.value)) {
            availableOptions.push(option);
            availableValues.push(option.value);
          }
        }
      });
      unAvailableValues = value.filter(item => !availableValues.includes(item));
      if (value?.length === availableOptions?.length) {
        return availableOptions;
      } else {
        if (matchingOptions?.length) {
          matchingOptions.forEach(mOption => {
            if (unAvailableValues.includes(mOption?.value)) {
              availableOptions.push(mOption);
            }
          });
        }
        return availableOptions;
      }
    } else if (!multiple && value) {
      options.map(option => {
        if (value === option?.value) {
          availableOptions.push(option);
          availableValues.push(option.value);
        }
      });
      if (!availableOptions?.length) {
        if (matchingOptions?.length) {
          matchingOptions.forEach(mOption => {
            if (mOption?.value === value) {
              availableOptions.push(mOption);
            }
          });
        }
      }
      if (availableOptions?.length) {
        return availableOptions[availableOptions.length - 1];
      } else {
        return null;
      }
    } else {
      return multiple ? [] : null;
    }
  }

  const InputChange = (e) => {
    setSearchText(e?.target?.value);
  }

  const filter = createFilterOptions();

  return (
    <ErrorBoundary>
      <Autocomplete
        id={id}
        size={size}
        multiple={multiple}
        disablePortal={false}
        limitTags={1}
        ref={autoC}
        options={_.uniqBy(extractValuableOptions(options), function (e) {
          return e.value;
        })}
        onInputChange={InputChange}
        onChange={handleSelection}
        fullWidth
        value={multiple ? (_.isArray(selected) ? selected : [selected]) : selected}
        sx={{ maxHeight: 100 }}
        getOptionDisabled={(options) => ((multiple && selected?.length >= max) ? true : false)}
        disabled={disabled}
        openOnFocus={true}
        blurOnSelect={true}
        clearOnBlur={!multiple}
        getOptionLabel={(option) => option?.label || ""}
        freeSolo
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting && add) {
            filtered.push({
              inputValue,
              label: `${addLabel} ${inputValue}`,
            });
          }
          return filtered;
        }}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Box display={'flex'} flexDirection={'row'}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography>
                    {option.label}
                  </Typography>
                  {fields.map((field, fieldIndex) => {
                    return (
                      <Typography key={fieldIndex} sx={{ fontSize: '12px', color: grey[700] }}>
                        {option[field]}
                      </Typography>
                    )
                  })}
                </Box>
              </Box>
            </li>
          );
        }}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option, index) => {
            if (showTooltip) {
              return (
                <Chip
                  key={`chip-option-${index}`}
                  size={size}
                  avatar={
                    <Tooltip
                      arrow
                      title={
                        <Box p={2}>
                          {tooltipVariables.map((item, index) => {
                            if (item.type === 'date') {
                              return (
                                <Box>
                                  <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'rgb(16, 49, 107)' }}>{item?.label || ""}</Typography>
                                  <Typography variant="body1" sx={{ fontSize: 14, color: '#000' }}>{convertToServerTime(option[item.key])}</Typography>
                                </Box>
                              )
                            } else {
                              return (
                                <Box>
                                  <Typography sx={{ fontSize: 16, fontWeight: 'bold', color: 'rgb(16, 49, 107)' }}>{item?.label || ""}</Typography>
                                  <Typography variant="body1" sx={{ fontSize: 14, color: '#000' }}>{option[item.key]}</Typography>
                                </Box>
                              )
                            }
                          })}
                        </Box>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            boxShadow: 1,
                            bgcolor: '#fff',
                            '& .MuiTooltip-arrow': {
                              color: '#fff',
                            },
                          },
                        },
                      }}
                    >
                      <InfoIcon sx={{ fontSize: 16, color: 'rgb(16, 49, 107)', cursor: 'pointer' }} />
                    </Tooltip>
                  }
                  {...getTagProps({ index })}
                  label={option.label}
                />
              )
            } else {
              return (
                <Chip key={`chip-option-${index}`} size={size} {...getTagProps({ index })} label={(option?.label || '')?.length > 15 ? (option?.label || "").slice(0, 15) + "..." : (option?.label || "")} />
              )
            }
          });
        }}
        renderInput={params => {
          const { InputProps, ...restParams } = params;
          const { startAdornment, ...restInputProps } = InputProps;
          return (
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', zIndex: 999, background: '#fff' }}>
              <TextField
                {...restParams}
                placeholder={((multiple && value?.length) || (!multiple && value)) ? "" : "Search " + label.toLowerCase() + "..."}
                label={showLabel ? label : ""}
                InputProps={{
                  ...restInputProps,
                  startAdornment: (
                    <Box style={{
                      maxHeight: 75,
                      overflowY: 'scroll',
                    }}
                    >
                      {startAdornment}
                    </Box>
                  ),
                }}
              />
              {loading ? <Box sx={{ position: 'absolute', right: 10, mt: 0.5, bgcolor: 'white' }}><CircularProgress size={20} color='primary' /></Box> : null}
            </Box>
          );
        }}
      />
    </ErrorBoundary>
  )
}

export default LocalSearchableDropdown;