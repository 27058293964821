import GridLayout from "react-grid-layout";
import { useState, useEffect, useRef, Fragment } from "react";
import { useSelector } from 'react-redux';
import useDeviceSize from "../utils/useDeviceSize";
import { convertLocalLayout, formatDashboardName, getDraggableLayout, getLayout } from "../utils/chartHelper";
import { Box, CircularProgress, Dialog, DialogContent, Grid, IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import ErrorBoundary from "./ErrorBoundary";
import DynamicCard from "./DynamicCard";
import { TabNav, TabPanel } from "../pages/MasterDataModule/Report/ReportsScreen";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Filters from "../pages/MasterDataModule/Report/Filters";
import VisualCard from "./VisualCard";
import _ from "lodash";
import jsPDF from "jspdf";
import exportAsImage from "./exportAsImage";
import moment from "moment";

const DraggableComponent = ({ dashboard = null, handleBack }) => {
  const ref = useRef(null);
  const containerRef = useRef(null);
  const { dashboardFilters, loadingCards = [] } = useSelector((state) => state.reports);

  const [width, height] = useDeviceSize();
  const [w, setWidth] = useState(1);
  const [layout, setLayout] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [draggable, setDraggable] = useState(false);
  const [activeFilters, setActiveFilters] = useState({});
  const [currentCaptureIndex, setCurrentCaptureIndex] = useState(null);
  const tabData = dashboard?.tabs || [];

  let localStorageName = `dashboard-${dashboard.id}-tab-${activeTab}`;

  useEffect(() => {
    let d;
    const localLayout = localStorage.getItem(localStorageName);
    if (draggable) {
      if (localLayout !== null) {
        d = convertLocalLayout(JSON.parse(localLayout), true);
      } else {
        d = getDraggableLayout(dashboard?.dashcards || []);
      }
    } else {
      if (localLayout !== null) {
        d = convertLocalLayout(JSON.parse(localLayout), false);
      } else {
        d = getLayout(dashboard?.dashcards || []);
      }
    }
    setLayout(d);
    if (ref?.current && ref?.current?.offsetWidth) {
      setWidth(ref.current.offsetWidth);
    }
  }, [width, dashboard, activeTab, draggable, localStorageName]);

  useEffect(() => {
    if (dashboardFilters[dashboard?.id]) {
      setActiveFilters(dashboardFilters[dashboard.id])
    }
  }, [dashboardFilters, dashboard?.id]);

  const handleLayoutChange = (e) => {
    setLayout(e);
    if (draggable) {
      localStorage.setItem(`${localStorageName}`, JSON.stringify(e));
    }
  }

  const handleTabChange = (id = null) => {
    if (id !== activeTab) {
      setActiveTab(id);
      setDraggable(false);
    }
  }

  const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const handleExport = async () => {
    const currentIndex = activeTab;
    let pdf = null;
    if (tabData?.length) {
      for (let i = 0; i < tabData.length; i++) {
        setCurrentCaptureIndex(i);
        setActiveTab(i);
        await delay(1000);
        const divWidth = containerRef?.current?.clientWidth || 0;
        const divHeight = containerRef?.current?.clientHeight || 0;
        if (pdf !== null) {
          pdf.addPage([divWidth, divHeight], 'p');
        } else {
          pdf = new jsPDF("p", "px", [divWidth, divHeight]);
        }
        let imageData = await exportAsImage(containerRef.current, '', true);
        const marginX = 20;
        pdf.addImage(imageData, 'JPEG', marginX, 0, divWidth - 40, divHeight);
      }
    } else {
      const divWidth = containerRef?.current?.clientWidth || 0;
      const divHeight = containerRef?.current?.clientHeight || 0;
      setCurrentCaptureIndex(0);
      pdf = new jsPDF("p", "px", [divWidth, divHeight]);
      let width = pdf.internal.pageSize.getWidth();
      let height = pdf.internal.pageSize.getHeight();
      let ratio = 1;
      if (divHeight > divWidth) {
        ratio = divWidth / divHeight;
        width = height * ratio;
      } else {
        ratio = divHeight / divWidth;
        height = width * ratio;
      }
      let imageData = await exportAsImage(containerRef.current, '', true);
      const marginX = (pdf.internal.pageSize.getWidth() - (width - 40)) / 2;
      pdf.addImage(imageData, 'JPEG', marginX, 0, width - 40, height);
    }
    pdf.save(dashboard?.name + '-' + moment(new Date()).format('MMMM Do YYYY, h:mm:ss a') + ".pdf");
    setActiveTab(currentIndex);
    setCurrentCaptureIndex(null);
  }

  return (
    <Box sx={{ width: '100%' }} ref={ref}>
      <Grid container sx={{ px: 2, mt: 1 }} spacing={2}>
        <Grid item xs={11} sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <Box onClick={handleBack} sx={{ bgcolor: 'white', border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 2, width: 45, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', color: blue[600], pr: 0.5, mr: 2, cursor: 'pointer' }}>
            <ArrowBackIosNewIcon />
          </Box>
          <Typography sx={{ fontWeight: 600, fontSize: '24px', color: '#475569' }}>{formatDashboardName(dashboard?.name)}</Typography>
          <Filters dashboard_id={dashboard?.id} filterParameters={dashboard?.parameters} activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
          <Box className='applied-filter' sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', overflow: 'auto', gap: 1, flex: 1 }}>
            {dashboard?.parameters?.map((fil, filIndex) => {
              let id = fil.id;
              if (activeFilters[id]) {
                let value = activeFilters[id];
                return (
                  <Box key={`active-filter-${filIndex}`} sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1, gap: 1, bgcolor: grey[50] }}>
                    <Typography sx={{ whiteSpace: 'nowrap' }} variant="subtitle2" color={blue[500]}>{fil?.name || ""}:</Typography>
                    {_.isArray(value) ?
                      <Typography sx={{ whiteSpace: 'nowrap' }} variant="subtitle2">{value[0]} {value.length - 1 > 0 ? `+${value.length - 1}` : ``}</Typography>
                      :
                      <Typography sx={{ whiteSpace: 'nowrap' }} >{value}</Typography>
                    }
                  </Box>
                )
              }
              return null;
            })}
          </Box>
        </Grid>
        <Grid item xs={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          {draggable
            ?
            <Tooltip id='close' title='Exit'>
              <IconButton sx={{ backgroundColor: red[50] }} aria-label='edit' size='medium' color='error' onClick={() => setDraggable(false)}>
                <CloseIcon fontSize='medium' />
              </IconButton>
            </Tooltip>
            :
            <Tooltip id='edit' title='Edit'>
              <IconButton sx={{ backgroundColor: blue[50] }} aria-label='edit' size='medium' color='primary' onClick={() => setDraggable(true)}>
                <EditIcon fontSize='medium' />
              </IconButton>
            </Tooltip>
          }
          {loadingCards.length ?
            <Tooltip id='edit' title='Export'>
              <IconButton sx={{ backgroundColor: green[50] }} aria-label='edit' size='medium' color='primary'>
                <CircularProgress size={24} sx={{ color: green[500] }} />
              </IconButton>
            </Tooltip>
            :
            <Tooltip id='edit' title='Export'>
              <IconButton sx={{ backgroundColor: green[50] }} aria-label='edit' size='medium' color='primary' onClick={handleExport}>
                <FileDownloadIcon fontSize='medium' sx={{ color: green[500] }} />
              </IconButton>
            </Tooltip>
          }

        </Grid>
      </Grid>
      {tabData.length
        ?
        <Box ref={containerRef} sx={{ width: '100%', pt: currentCaptureIndex !== null ? 2 : 0, position: 'relative', }}>
          <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', pl: 2, }}>
            <TabNav value={activeTab} handleChange={(e, val) => handleTabChange(val)} tabs={tabData} />
          </Box>
          {tabData?.map((tab, tabIndex) => {
            return (
              <Box sx={{ width: '100%', display: activeTab === tabIndex ? 'block' : 'none', opacity: activeTab === tabIndex ? 1 : 0, transition: 'all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)' }} key={tabIndex}>
                <GridLayout
                  className="layout"
                  layout={layout}
                  cols={24}
                  width={w}
                  rowHeight={54}
                  onLayoutChange={handleLayoutChange}
                >
                  {
                    (dashboard?.dashcards || []).filter(dashCardItem => dashCardItem.dashboard_tab_id === tab?.id).map((d, index) => {
                      if (d?.card_id) {
                        return (
                          <Box key={d?.id} sx={{ width: '100%', height: '100%' }}>
                            <ErrorBoundary>
                              <DynamicCard
                                title={d?.card?.name}
                                visualization_settings={Object.keys(d?.visualization_settings).length ? d?.visualization_settings : d?.card?.visualization_settings}
                                card={d?.card}
                                dashcard_id={d?.id}
                                card_id={d?.card?.id}
                                dashboard_id={dashboard.id}
                                parameters={d?.parameter_mappings}
                                activeFilters={activeFilters}
                                filters={dashboard?.parameters}
                                showAnimation={currentCaptureIndex === null}
                              />
                            </ErrorBoundary>
                          </Box>
                        )
                      } else {
                        return (
                          <Box key={d?.id} sx={{ width: '100%', height: '100%' }}>
                            <ErrorBoundary>
                              <VisualCard visualData={d?.visualization_settings} />
                            </ErrorBoundary>
                          </Box>
                        )
                      }
                    })
                  }
                </GridLayout>
              </Box>
            )
          })}
        </Box>
        :
        <Box ref={containerRef} sx={{ width: '100%', pt: currentCaptureIndex !== null ? 2 : 0 }}>
          <GridLayout
            className="layout"
            layout={layout}
            cols={24}
            width={w}
            rowHeight={54}
            onLayoutChange={handleLayoutChange}
          >
            {
              (dashboard?.dashcards || []).map((d, index) => {
                if (d?.card_id) {
                  return (
                    <Box key={d?.id} sx={{ width: '100%', height: '100%' }}>
                      <ErrorBoundary>
                        <DynamicCard
                          title={d?.card?.name}
                          visualization_settings={Object.keys(d?.visualization_settings).length ? d?.visualization_settings : d?.card?.visualization_settings}
                          card={d?.card}
                          dashcard_id={d?.id}
                          card_id={d?.card?.id}
                          dashboard_id={dashboard.id}
                          parameters={d?.parameter_mappings}
                          activeFilters={activeFilters}
                          filters={dashboard?.parameters}
                          showAnimation={currentCaptureIndex === null}
                        />
                      </ErrorBoundary>
                    </Box>
                  )
                } else {
                  return (
                    <Box key={d?.id} sx={{ width: '100%', height: '100%' }}>
                      <ErrorBoundary>
                        <VisualCard visualData={d?.visualization_settings} />
                      </ErrorBoundary>
                    </Box>
                  )
                }
              })
            }
          </GridLayout>
        </Box>
      }
      <Dialog maxWidth="sm" fullWidth open={currentCaptureIndex !== null}>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3 }}>
            <Typography variant="subtitle2" sx={{ color: blue[500], fontSize: 20 }}>Exporting Report</Typography>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
              {tabData.length ?
                <Fragment>
                  <CircularProgress size={60} disableShrink={true} />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: blue[500] }}>
                      {`${Math.round((currentCaptureIndex + 1) / tabData.length * 100)}%`}
                    </Typography>
                  </Box>
                </Fragment>
                :
                <CircularProgress size={60} />
              }
            </Box>
            {tabData?.length ? <Typography variant="subtitle2" sx={{ color: grey[700] }}>Capturing {tabData[currentCaptureIndex]?.name || ''} tab ...</Typography> : <Typography variant="subtitle2" sx={{ color: grey[700] }}>Capturing current report ...</Typography>}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
};

export default DraggableComponent;
