import { GET_SOW_NOTIFICATIONS_FAILED, GET_SOW_NOTIFICATIONS_IN_PROGRESS, GET_SOW_NOTIFICATIONS_SUCCESS } from "../../constants/store";
import api from "../../config/https";

export const fetchAllPendingSOWNotifications = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SOW_NOTIFICATIONS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/getSow?extraction_status=2`);
    if (res?.data?.status && res?.data?.data) {
      let data=res.data.data;
      let list=data?.data || [];
      dispatch({
        type: GET_SOW_NOTIFICATIONS_SUCCESS,
        payload: {count:data?.totalCount || list.length},
      });
    }else{
      dispatch({
        type: GET_SOW_NOTIFICATIONS_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_SOW_NOTIFICATIONS_FAILED,
    });
  }
};