import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';

export default function ProtectedRoute({ children, ...rest }) {
  const { currentUser = null } = useSelector(state => state.auth);

  if (!currentUser) {
    return <Redirect
      to={{
        pathname: "/login",
        state: { from: window?.location?.pathname }
      }}

    />
  };
  return <Route {...rest}>{children}</Route>;
}