import { Tooltip, ResponsiveContainer, FunnelChart, Funnel, LabelList } from 'recharts';
import { extractFunnelDataPoints } from '../../utils/chartHelper';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import NoDataCard from './noDataCard';

const FunnelCard = ({ data = [], visualizationData = null, title = "", showAnimation = true }) => {

  const [axes, setAxes] = useState([]);
  const [list, setList] = useState([]);
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    const dataPoints = extractFunnelDataPoints(data, visualizationData, title);
    setList(dataPoints?.list || []);
    setAxes(dataPoints?.axes || []);
    setTitle(dataPoints?.title || "");
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <Box sx={{ width: '100%', height: '90%' }}>
        <ResponsiveContainer width='100%' height='100%'>
          <FunnelChart
            layout='horizontal'
            margin={{
              top: 5,
              right: 40,
              bottom: 15,
              left: 30,
            }}
          >
            <Tooltip />
            {
              axes.length
                ?
                <Funnel isAnimationActive={showAnimation} dataKey={axes[axes.length - 1].dataKey} data={list}>
                  <LabelList
                    position="right"
                    fill="#000"
                    stroke="none"
                    dataKey="name"
                    offset="40"
                  />
                </Funnel>
                :
                null
            }
          </FunnelChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default FunnelCard;