import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Switch, TextField, Typography, styled } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TuneIcon from "@mui/icons-material/Tune";

const SOWFilter = ({ open = false, handleClose, filter = {}, handleResetFilter, handleApplyFilter, handleFilterChange }) => {

  const options = useSelector((state) => state.sow.options);

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const fromNow30 = moment(new Date(), "DD-MM-YYYY").add(30, 'days').format('YYYY-MM-DD');
  const endDate = moment(filter?.endDateRangeEnd).format("YYYY-MM-DD");

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-item-modal"
      aria-describedby="add-item-modal-description"
      fullWidth
      maxWidth={"md"}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><TuneIcon />Filters</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant='subtitle2'>Start Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={(inputProps) =>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    disabled={false}
                    value={filter?.endDateRangeStart || ""}
                    variant="outlined"
                    sx={{ width: "100%", background: "#FFFFFF" }}
                    onChange={(e) => {
                      handleFilterChange('endDateRangeStart', e.target.value);
                    }}
                    id='start-date-input'
                  />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='subtitle2'>End Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={(inputProps) =>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    disabled={false}
                    value={filter?.endDateRangeEnd || ""}
                    variant="outlined"
                    sx={{ width: "100%", background: "#FFFFFF" }}
                    onChange={(e) => {
                      handleFilterChange('endDateRangeEnd', e.target.value);
                    }}
                    id='end-date-input'
                  />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} checked={fromNow30 === endDate} onChange={(e) => handleFilterChange('endDateRangeEnd', e?.target?.checked ? fromNow30 : null)} />}
              label="Show statement of work's expiring in 30 days"
              inputProps={{ 'aria-label': 'controlled' }}
              id='show-expiry-30-btn'
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button id='close-btn' variant='outlined' color='error' onClick={handleResetFilter}>Reset</Button>
        <Button id='clear-btn' onClick={handleClose}>Close</Button>
        <Button id='apply-btn' variant='outlined' onClick={handleApplyFilter}>Apply</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SOWFilter;