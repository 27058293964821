import { combineReducers } from "redux";
import clientReducer from "./ClientReducers";
import contractDetailsReducer from "./ContractDetailsReducers";
import candidateReducers from "./CandidateReducers";
import changeRequestReducer from "./ChangeRequestReducers";
import userReducer from "./userReducers";
import revisionHistoryReducer from "./ContractRevisionReducers";
import ChangeRequestNotificationReducer from "./ChangeRequestNotification";
import downloadReducer from "./DownloadReducers";
import consultantFormReducer from "../ConsultantForm/reducer";
import recruitmentDraftReducer from "../Recruitment/reducer";
import pendingRecruitmentDraftReducer from "../PendingRecruitment/reducer";
import pendingRecruitmentDraftNotificationReducer from "../PendingRecruitmentNotification/reducer";
import settingConstantsReducer from "../Settings/reducer";
import sowReducer from "../Sow/reducer";
import reportsReducer from "../Reports/reducer";
import requestOptionsReducer from "../RequestOptions/reducer";
import pendingRequestOptionsNotificationReducer from "../PendingRequestOptionsNotification/reducer";
import pendingSOWNotificationReducer from "../PendingSOWNotification/reducer";
import contractReducer from "../Contract/reducer";
import applicationLogsReducer from "../ApplicationLogs/reducer";
import authReducer from "../Auth/reducer";
import userManagementReducer from "../UserManagement/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  recruitmentDraft: recruitmentDraftReducer,
  pendingRecruitmentDraft: pendingRecruitmentDraftReducer,
  pendingRecruitmentDraftNotification: pendingRecruitmentDraftNotificationReducer,
  consultantForm: consultantFormReducer,
  candidate: candidateReducers,
  contractDetails: contractDetailsReducer,
  clients: clientReducer,
  changeRequest: changeRequestReducer,
  users: userReducer,
  revisionHistory: revisionHistoryReducer,
  changeRequestNotification: ChangeRequestNotificationReducer,
  settingsConstants: settingConstantsReducer,
  downloadReports: downloadReducer,
  sow: sowReducer,
  pendingSOWNotification: pendingSOWNotificationReducer,
  reports: reportsReducer,
  requestOptions: requestOptionsReducer,
  pendingRequestOptionsNotification: pendingRequestOptionsNotificationReducer,
  contract: contractReducer,
  applicationLogs: applicationLogsReducer,
  userMagement: userManagementReducer,
});

export default rootReducer;
