import moment from "moment";
import { capitalizeString } from "./ensureSelectOptions";
import calculateSize from 'calculate-size';
import _ from "lodash";

export const STACKEDCOLORS = ['#509ee3', '#87bcec', '#227fd2', '#88bf4d', '#a7d07c', '#689636', '#a989c5', '#c8b4da', '#8a5eb0', '#ef8c8c', '#f7c4c4', '#e75454', '#f9d45c'];

export const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#e75454'];

export const colorScale = ['#bfdbfe', '#60a5fa', '#2563eb', '#1e40af', '#1e3a8a'];

export const formatDashboardName = (value = "") => {
  try {
    let name = value;
    name = name.split('_');
    name = name.map(n => capitalizeString(n));
    name = name.join(' ');
    return name;
  } catch (error) {
    return value || "";
  }
}

export const getLayout = (list = []) => {
  let newLayout = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i]?.id) {
      newLayout.push({
        i: (list[i]?.id || "").toString(),
        x: list[i].col || 0,
        y: list[i].row || 0,
        w: list[i].size_x || 0,
        h: list[i].size_y || 0,
        static: true,
      });
    }
  }
  return newLayout;
};

export const getDraggableLayout = (list = []) => {
  let newLayout = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i]?.id) {
      newLayout.push({
        i: (list[i]?.id || "").toString(),
        x: list[i].col || 0,
        y: list[i].row || 0,
        w: list[i].size_x || 0,
        h: list[i].size_y || 0,
      });
    }
  }
  return newLayout;
};

export const convertLocalLayout = (data = [], draggable = true) => {
  let newLayout = [];
  data.forEach(item => {
    newLayout.push({ ...item, static: !draggable });
  });
  return newLayout;
};

export const extractAxis = (list = []) => {
  let newList = [];
  const [firstItem, ...rest] = list;
  const lastItem = rest.pop();
  if (list.length > 1) {
    newList.push(firstItem);
    newList.push(lastItem);
  } else {
    newList.push(firstItem);
  }
  return newList;
}

export const extractLineAxis = (list = []) => {
  let newList = [];
  for (let i = 0; i < list.length; i++) {
    let unit = list[i].semantic_type === 'type/GrossMargin' ? '$' : '';
    newList.push({
      dataKey: list[i].name,
      displayName: list[i].display_name,
      type: list[i].effective_type,
      unit: list[i]?.unit || unit,
      range: list[i]?.binning_info?.bin_width || 0
    });
  }
  return newList;
}

export const extractPieDataPoints = (data = null, visualizationData = [], title = "") => {
  const columns = data?.cols || [];
  const rows = data?.rows || [];
  const evaluatedKeys = extractLineAxis(columns);
  let chartTitle = visualizationData?.['card.title'] || title;
  let newList = [];
  rows.forEach((row) => {
    const [...rowValues] = row;
    let item = {};
    evaluatedKeys.forEach((evaluatedKey, index) => {
      const label = evaluatedKey.dataKey;
      let value = rowValues[index];
      if (evaluatedKey.type === 'type/DateTime' || evaluatedKey.type === 'type/Date') {
        if (evaluatedKey.unit === 'month') {
          value = moment(value).format('MMMM YYYY');
        } else if (evaluatedKey.unit === 'year') {
          value = moment(value).format('YYYY');
        } else {
          value = moment(value).format('MMM DD,YYYY');
        }
      }
      if (typeof value === 'number') {
        if (!Number.isInteger(value)) {
          value = value.toFixed(2);
        }
      }
      item[label] = value || "";
    });
    newList.push(item);
  });
  let identityKey = evaluatedKeys[0]?.dataKey;
  let valueKey = evaluatedKeys[evaluatedKeys.length - 1]?.dataKey;
  let formatedList = [];
  let total = 0;
  const groupData = _.groupBy(newList, (item) => {
    return item[identityKey];
  });
  Object.keys(groupData).forEach(groupDataKey => {
    let obj = {
      'name': capitalizeString(groupDataKey),
      [valueKey]: null
    };
    groupData[groupDataKey].forEach(item => {
      total = parseFloat(total.toFixed(2));
      obj[valueKey] = obj[valueKey] ? obj[valueKey] + parseFloat((item?.[valueKey] || 0).toFixed(2)) : parseFloat((item?.[valueKey] || 0).toFixed(2));
      total += parseFloat((item?.[valueKey] || 0).toFixed(2));
    });
    formatedList.push(obj);
  });
  let finalList = [];
  if (formatedList.length > 4) {
    formatedList.sort((a, b) => a[valueKey] < b[valueKey] ? 1 : -1).forEach((item, index) => {
      if (index < 4) {
        finalList.push(item);
      }
    });
    finalList = finalList.sort((a, b) => a.name > b.name ? 1 : -1);
    finalList.push({ name: 'Others', [valueKey]: total - finalList.reduce((acc, next) => acc + next[valueKey], 0) });
  } else {
    finalList = formatedList.sort((a, b) => a.name > b.name ? 1 : -1);
  }
  const d = {
    list: finalList,
    axes: evaluatedKeys,
    total: total,
    title: chartTitle,
  }
  return d;
}

export const extractLineDataPoints = (data = null, visualizationData = [], title = '') => {
  let newList = [];
  let gD = visualizationData?.['graph.dimensions'] || [];
  let gM = visualizationData?.['graph.metrics'] || [];
  let gSO = visualizationData?.['graph.series_order'] || [];
  let series_settings = visualizationData?.['series_settings'] || null;
  let column_settings = visualizationData?.['column_settings'] || null;
  let xName = visualizationData?.['graph.x_axis.title_text'] || false;
  let yName = visualizationData?.['graph.y_axis.title_text'] || false;
  let chartTitle = visualizationData?.['card.title'] || title;
  const columns = data?.cols || [];
  let evaluatedKeys = extractLineAxis(columns);
  evaluatedKeys = evaluatedKeys.map(item => ({ ...item, displayName: series_settings?.[item.dataKey]?.title || item.displayName, chartType: series_settings?.[item.dataKey]?.display || "line" }));
  if (!_.isEmpty(column_settings)) {
    evaluatedKeys = evaluatedKeys.map(item => {
      let key = `["name","${item.dataKey}"]`;
      if (column_settings[key]?.prefix) {
        item.unit = column_settings[key]?.prefix;
      }
      if (column_settings[key]?.column_title) {
        item.displayName = column_settings[key].column_title;
      }
      return item;
    });
  };
  if (evaluatedKeys.length && xName) {
    evaluatedKeys[0].displayName = xName;
  }
  const rows = data?.rows || [];
  rows.forEach((row) => {
    const [...rowValues] = row;
    let item = {};
    evaluatedKeys.forEach((evaluatedKey, index) => {
      const label = evaluatedKey.dataKey;
      let value = rowValues[index];
      if (evaluatedKey.type === 'type/DateTime' || evaluatedKey.type === 'type/Date') {
        if (evaluatedKey.unit === 'month') {
          value = moment(value).format('MMMM YYYY');
        } else if (evaluatedKey.unit === 'year') {
          value = moment(value).format('YYYY');
        } else {
          value = moment(value).format('MMM DD,YYYY');
        }
      }
      if (typeof value === 'number') {
        if (!Number.isInteger(value)) {
          value = value.toFixed(2);
        }
      }
      item[label] = value || "";
    });
    newList.push(item);
  });
  let sizeKey = evaluatedKeys?.[0]?.dataKey;
  if (evaluatedKeys?.length > 2) {
    let nameKey = evaluatedKeys?.[0]?.dataKey || "";
    let identityKey2 = evaluatedKeys?.[evaluatedKeys.length - 2]?.dataKey || "";
    sizeKey = identityKey2;
    let valueKey = gM?.[0] || '';
    const groupData = _.groupBy(newList, (item) => {
      return item[identityKey2];
    });
    let groupedList = [];
    Object.keys(groupData).forEach(key => {
      let d = {
        [identityKey2]: key,
      };
      (groupData?.[key] || []).forEach(item => {
        let name = item[nameKey];
        d[name] = item[valueKey];
      });
      gSO?.forEach(gS => {
        let gSKey = gS?.key || "";
        d[gSKey] = d[gSKey] || 0;
      });
      groupedList.push(d);
    });
    newList = [...groupedList];
  }
  let size = {
    width: 0,
    height: 0,
  };
  newList.forEach(item => {
    if (gD.length) {
      let finalLabel = item[sizeKey];
      const sizeCheck = calculateSize(finalLabel, {
        font: 'Poppins',
        fontSize: '14px',
      });
      if (size.width < sizeCheck.width) {
        size = sizeCheck;
      }
    }
  });
  if (!yName) {
    yName = evaluatedKeys[evaluatedKeys.length - 1]?.displayName || "";
  }
  let n = evaluatedKeys?.[0] || {};
  const d = {
    list: newList,
    axes: evaluatedKeys.filter(item => item.chartType === 'line'),
    xName,
    yName,
    title: chartTitle,
    size,
    groups: gSO.map(item => ({ ...n, 'dataKey': item.key, 'displayName': item?.name || n?.displayName || '' }))
  }
  return d;
}

export const extractPivotDataPoints = (data = null, visualizationData = [], title = "") => {
  let newList = [];
  const columns = data?.cols || [];
  const rows = data?.rows || [];
  let column_settings = visualizationData?.['column_settings'] || null;
  let chartTitle = visualizationData?.['card.title'] || title;
  let evaluatedKeys = extractLineAxis(columns);
  if (!_.isEmpty(column_settings)) {
    evaluatedKeys = evaluatedKeys.map(item => {
      let key = `["name","${item.dataKey}"]`;
      if (column_settings[key]?.prefix) {
        item.unit = column_settings[key]?.prefix;
      }
      if (column_settings[key]?.column_title) {
        item.displayName = column_settings[key].column_title;
      }
      return item;
    });
  };
  const headers = evaluatedKeys.map(item => item.displayName);
  newList.push(headers);
  newList = [...newList, ...rows]
  let d = {
    list: newList,
    title: chartTitle,
  };
  return d;
}

export const extractComboDataPoints = (data = null, visualizationData = [], title = '') => {
  let newList = [];
  let gD = visualizationData?.['graph.dimensions'] || [];
  let xName = visualizationData?.['graph.x_axis.title_text'] || false;
  let yName = visualizationData?.['graph.y_axis.title_text'] || false;
  let series_settings = visualizationData?.['series_settings'] || null;
  let column_settings = visualizationData?.['column_settings'] || null;
  let chartTitle = visualizationData?.['card.title'] || title;
  const columns = data?.cols || [];
  const rows = data?.rows || [];
  let evaluatedKeys = extractLineAxis(columns);
  evaluatedKeys = evaluatedKeys.map(item => ({ ...item, displayName: series_settings?.[item.dataKey]?.title || item.displayName, chartType: series_settings?.[item.dataKey]?.display || "bar" }));
  if (!_.isEmpty(column_settings)) {
    evaluatedKeys = evaluatedKeys.map(item => {
      let key = `["name","${item.dataKey}"]`;
      if (column_settings[key]?.prefix) {
        item.unit = column_settings[key]?.prefix;
      }
      if (column_settings[key]?.column_title) {
        item.displayName = column_settings[key].column_title;
      }
      return item;
    })
  };
  let evaluatedKeysB = [...evaluatedKeys];
  evaluatedKeysB = evaluatedKeysB.filter(item => item.chartType === 'bar');
  let evaluatedKeysL = [...evaluatedKeys];
  evaluatedKeysL = evaluatedKeysL.filter(item => item.chartType === 'line');
  rows.forEach((row) => {
    const [...rowValues] = row;
    let item = {};
    evaluatedKeys.forEach((evaluatedKey, index) => {
      const label = evaluatedKey.dataKey;
      let value = rowValues[index];
      if (evaluatedKey.type === 'type/DateTime' || evaluatedKey.type === 'type/Date') {
        if (evaluatedKey.unit === 'month') {
          value = moment(value).format('MMMM YYYY');
        } else if (evaluatedKey.unit === 'year') {
          value = moment(value).format('YYYY');
        } else {
          value = moment(value).format('MMM DD,YYYY');
        }
      }
      if (typeof value === 'number') {
        if (!Number.isInteger(value)) {
          value = value.toFixed(2);
        }
      }
      item[label] = value;
    });
    newList.push(item);
  });
  let size = {
    width: 0,
    height: 0,
  };
  newList.forEach(item => {
    if (gD.length) {
      let key = gD[0];
      let finalLabel = item[key];
      const sizeCheck = calculateSize(finalLabel, {
        font: 'Poppins',
        fontSize: '14px',
      });
      if (size.width < sizeCheck.width) {
        size = sizeCheck;
      }
    }
  });
  const d = {
    list: newList,
    axesB: evaluatedKeysB,
    axesL: evaluatedKeysL,
    series: evaluatedKeys.filter(item => !gD.includes(item?.dataKey)),
    xName,
    yName,
    title: chartTitle,
    size,
  }
  return d;
};

export const extractBarDataPoints = (data = null, visualizationData = [], title = '') => {
  let newList = [];
  const stacked = visualizationData?.['stackable.stack_type'] === 'stacked' || visualizationData?.['stackable.stack_type'] === 'normalized';
  let gD = visualizationData?.['graph.dimensions'] || [];
  let gM = visualizationData?.['graph.metrics'] || [];
  const seriesSettings = visualizationData?.['series_settings'] || {};
  let column_settings = visualizationData?.['column_settings'] || null;
  let xName = visualizationData?.['graph.x_axis.title_text'] || false;
  let yName = visualizationData?.['graph.y_axis.title_text'] || false;
  let chartTitle = visualizationData?.['card.title'] || title;
  const columns = data?.cols || [];
  let evaluatedKeys = extractLineAxis(columns);
  evaluatedKeys = evaluatedKeys.map(item => ({ ...item, displayName: seriesSettings?.[item.dataKey]?.title || item.displayName, chartType: seriesSettings?.[item.dataKey]?.display || "bar" }));
  const keys = [...gD, ...gM];
  evaluatedKeys = evaluatedKeys.map(item => ({ ...item, position: keys.indexOf(item.dataKey) }));
  if (!_.isEmpty(column_settings)) {
    evaluatedKeys = evaluatedKeys.map(item => {
      let key = `["name","${item.dataKey}"]`;
      if (column_settings[key]?.prefix) {
        item.unit = column_settings[key]?.prefix;
      }
      if (column_settings[key]?.column_title) {
        item.displayName = column_settings[key].column_title;
      }
      return item;
    })
  };
  if (evaluatedKeys.length && xName) {
    evaluatedKeys[0].displayName = xName;
  }
  const rows = data?.rows || [];
  rows.forEach((row) => {
    const [...rowValues] = row;
    let item = {};
    evaluatedKeys.forEach((evaluatedKey, index) => {
      const label = evaluatedKey.dataKey;
      let value = rowValues[index];
      if (evaluatedKey.type === 'type/DateTime' || evaluatedKey.type === 'type/Date') {
        if (evaluatedKey.unit === 'month') {
          value = moment(value).format('MMMM YYYY');
        } else if (evaluatedKey.unit === 'year') {
          value = moment(value).format('YYYY');
        } else {
          value = moment(value).format('MMM DD,YYYY');
        }
      }
      if (typeof value === 'number') {
        if (!Number.isInteger(value)) {
          value = value.toFixed(2);
        }
      }
      item[label] = value;
    });
    newList.push(item);
  });
  let size = {
    width: 0,
    height: 0,
  };
  newList.forEach(item => {
    if (gD.length) {
      let key = gD[0];
      let finalLabel = item[key];
      const sizeCheck = calculateSize(finalLabel, {
        font: 'Poppins',
        fontSize: '14px',
      });
      if (size.width < sizeCheck.width) {
        size = sizeCheck;
      }
    }
  });
  let stackedGroups = [], legends = [];
  if (stacked) {
    let identityKey = gD[0];
    const groupData = _.groupBy(newList, (item) => {
      return item[identityKey];
    });
    let identityKey2 = gD[gD.length - 1];
    const groupData2 = _.groupBy(newList, (item) => {
      return item[identityKey2];
    });
    legends = Object.keys(groupData2);
    Object.keys(groupData).forEach(gK => {
      let item = {
        [identityKey]: gK,
      };
      let total = 0;
      groupData[gK].forEach(gItem => {
        let key1 = gD[gD.length - 1];
        let key2 = gM[gM.length - 1];
        item = {
          ...item,
          [gItem[key1]]: (gItem[key2] || 0),
        };
        total = total + parseInt(gItem[key2] || 0);
      });
      item.total = total;
      stackedGroups.push(item);
    });
    let tempList = [];
    stackedGroups.forEach((item, itemIndex) => {
      let tempItem = {};
      let others = 0;
      legends.forEach((legend, legendIndex) => {
        let value = item[legend];
        if (legendIndex < 12) {
          tempItem[legend] = value || 0;
        } else if (value) {
          others = others + value;
        }
      });
      tempItem.Others = others;
      tempItem.total = item.total;
      tempItem[identityKey] = item[identityKey];
      tempList.push(tempItem);
    });
    legends = legends.slice(0, 12);
    legends.push('Others');
    stackedGroups = tempList;
    if (visualizationData?.['stackable.stack_type'] === 'normalized') {
      let normalizedList = [];
      stackedGroups.forEach(stackItem => {
        let tempItem = {};
        Object.keys(stackItem).forEach(stackKey => {
          let value = stackItem[stackKey];
          if (stackKey !== identityKey && stackKey !== 'total') {
            tempItem[stackKey] = value;
            tempItem['normalized-' + stackKey] = parseFloat((value / stackItem.total * 100).toFixed(2));
          }
        });
        tempItem[identityKey] = stackItem[identityKey];
        tempItem.grandTotal = stackItem.total;
        normalizedList.push(tempItem);
      });
      stackedGroups = normalizedList;
    }
  }
  if (!yName) {
    yName = evaluatedKeys[evaluatedKeys.length - 1]?.displayName || "";
  }
  let finalData = {
    list: stacked ? stackedGroups : newList,
    axes: evaluatedKeys.filter(item => item.chartType === 'bar').sort((a, b) => a.position > b.position ? 1 : -1),
    xName,
    yName,
    title: chartTitle,
    size,
    stacked: legends,
    stackedType: visualizationData?.['stackable.stack_type']
  }
  return finalData;
}

function generateScales(maxNumber) {
  const step = Math.ceil(maxNumber / 4);
  const scales = [];

  for (let i = 0; i < 5; i++) {
    const scaleValue = Math.round(i * step);
    scales.push(scaleValue);
  }

  return scales;
}

export const extractMapMarkerDataPoints = (data = null, visualizationData = [], title = "") => {
  let newList = [];
  const columns = data?.cols || [];
  let chartTitle = visualizationData?.['card.title'] || title;
  const evaluatedKeys = extractLineAxis(columns);
  const rows = data?.rows || [];
  rows.forEach((row) => {
    const [...rowValues] = row;
    let item = {};
    let invalidValue = false;
    evaluatedKeys.forEach((evaluatedKey, index) => {
      const label = evaluatedKey.dataKey;
      let value = rowValues[index];
      if (evaluatedKey.type === 'type/DateTime' || evaluatedKey.type === 'type/Date') {
        if (evaluatedKey.unit === 'month') {
          value = moment(value).format('MMMM YYYY');
        } else if (evaluatedKey.unit === 'year') {
          value = moment(value).format('YYYY');
        } else {
          value = moment(value).format('MMM DD,YYYY');
        }
        if (value === 'Invalid date') {
          invalidValue = true;
        }
      }
      item[label] = value;
    });
    if (!invalidValue) {
      newList.push(item);
    }
  });
  const formatedGroup = [];
  newList.forEach((item, index) => {
    formatedGroup.push({
      name: 'item-' + index + 1,
      count: item?.count || 0,
      latLng: [item.LATITUDE, item.LONGITUDE]
    });
  })
  const d = {
    list: formatedGroup,
    title: chartTitle,
  }
  return d;
}

export const extractMapDataPoints = (data = null, visualizationData = [], title = "") => {
  let newList = [];
  const mapState = visualizationData?.['map.region'];
  let chartTitle = visualizationData?.['card.title'] || title;
  const columns = data?.cols || [];
  const evaluatedKeys = extractLineAxis(columns);
  const rows = data?.rows || [];
  rows.forEach((row) => {
    const [...rowValues] = row;
    let item = {};
    let invalidValue = false;
    evaluatedKeys.forEach((evaluatedKey, index) => {
      const label = evaluatedKey.dataKey;
      let value = rowValues[index];
      if (evaluatedKey.type === 'type/DateTime' || evaluatedKey.type === 'type/Date') {
        if (evaluatedKey.unit === 'month') {
          value = moment(value).format('MMMM YYYY');
        } else if (evaluatedKey.unit === 'year') {
          value = moment(value).format('YYYY');
        } else {
          value = moment(value).format('MMM DD,YYYY');
        }
        if (value === 'Invalid date') {
          invalidValue = true;
        }
      }
      if (typeof value === 'number') {
        if (!Number.isInteger(value)) {
          value = value.toFixed(2);
        }
      }
      item[label] = value;
    });
    if (!invalidValue) {
      newList.push(item);
    }
  });
  const identityKey = evaluatedKeys[0]?.dataKey || "";
  const valueKey = evaluatedKeys[evaluatedKeys.length - 1]?.dataKey || "";
  let formatedGroup = {}, max = 0;
  newList.forEach((newListItem) => {
    let label = newListItem[identityKey];
    let value = newListItem[valueKey];
    if (mapState === 'us_states') {
      formatedGroup = {
        ...formatedGroup,
        ['US-' + label]: value,
      }
    } else {
      formatedGroup = {
        ...formatedGroup,
        [label]: value,
      }
    }

    if (max < value) {
      max = value;
    }
  });
  let d = {
    list: formatedGroup,
    evaluatedKeys,
    max,
    scales: generateScales(max),
    title: chartTitle,
  }
  return d;
}

export const extractWaterfallDataPoints = (data = null, visualizationData = [], title = "") => {
  let d = extractBarDataPoints(data, visualizationData, title);
  const list = d?.list || [];
  let newList = [];
  let total = 0;
  let axes = d?.axes || [];
  let identityKey = axes[axes.length - 1].dataKey;
  let label = axes[0]?.dataKey || "";
  list.forEach((item, index) => {
    if (index > 0) {
      newList.push({
        ...item,
        [identityKey + '2']: total,
      });
    } else {
      newList.push({
        ...item,
        [identityKey + '2']: total,
      });
    }
    total += item[identityKey];
  });
  newList.push({ [label]: 'Total', [identityKey]: total, [identityKey + '2']: 0, fill: COLORS[2] });
  let finalData = {
    ...d,
    list: newList,
  }
  return finalData;
};

export const extractFunnelDataPoints = (data = null, visualizationData = [], title = "") => {
  const d = extractBarDataPoints(data, visualizationData, title);
  let newList = d?.list || [];
  newList.forEach((item, index) => {
    item.fill = colorScale[index];
  });
  let finalData = {
    ...d,
    list: newList,
  }
  return finalData;
}

export const extractTableDataPoints = (data = null, visualizationData = [], title = '') => {
  let newList = [];
  const columns = data?.cols || [];
  let column_settings = visualizationData?.['column_settings'] || null;
  let table_columns = visualizationData?.['table.columns'] || [];
  let chartTitle = visualizationData?.['card.title'] || title;
  let evaluatedKeys = extractLineAxis(columns);
  if (!_.isEmpty(column_settings)) {
    evaluatedKeys = evaluatedKeys.map(item => {
      let key = `["name","${item.dataKey}"]`;
      if (column_settings[key]?.prefix) {
        item.unit = column_settings[key]?.prefix;
      }
      if (column_settings[key]?.column_title) {
        item.displayName = column_settings[key].column_title;
      }
      return item;
    });
  };
  const rows = data?.rows || [];
  rows.forEach((row) => {
    const [...rowValues] = row;
    let item = {};
    evaluatedKeys.forEach((evaluatedKey, index) => {
      const label = evaluatedKey.dataKey;
      let value = rowValues[index];
      if (evaluatedKey.type === 'type/DateTime' || evaluatedKey.type === 'type/Date') {
        if (evaluatedKey.unit === 'month') {
          value = moment(value).format('MMMM YYYY');
        } else if (evaluatedKey.unit === 'year') {
          value = moment(value).format('YYYY');
        } else {
          value = moment(value).format('MMM DD,YYYY');
        }
      }
      if (typeof value === 'number') {
        if (!Number.isInteger(value)) {
          value = value.toFixed(2);
        }
      }
      item[label] = value || '-';
    });
    newList.push(item);
  });
  if (table_columns.length) {
    let newEvaluatedKeys = [];
    table_columns.forEach(tc => {
      let key1 = tc?.name || "";
      evaluatedKeys.forEach(eK => {
        let key2 = eK?.dataKey || "";
        if (key2 === key1) {
          newEvaluatedKeys.push(eK);
        }
      });
    });
    evaluatedKeys = [...newEvaluatedKeys];
  }
  let finalData = {
    list: newList,
    title: chartTitle,
    axes: evaluatedKeys,
  }
  return finalData;
}

export const extractMetabaseOptions = (list = []) => {
  let newList = [];
  list.forEach(item => {
    if (_.isArray(item)) {
      item.forEach(it => {
        newList.push(it);
      });
    } else {
      newList.push(item);
    }
  });
  return newList.filter(item => item !== null).map(item => ({ value: item, label: item }));
}