import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const SectionDeleteModal = ({ open = false, handleClose = () => { }, handleDelete = () => { }, currentData = null }) => {

	const handleSectionDelete = () => {
		handleDelete({ id: currentData.id });
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullWidth
			maxWidth={"xs"}
		>
			<DialogContent>
				<Typography variant='h6'>Are you sure you want to delete this section?</Typography>
			</DialogContent>
			<DialogActions>
				<Button id='form-cancel-btn' onClick={handleClose}>Cancel</Button>
				<Button id='form-add-btn' onClick={handleSectionDelete} variant='contained' color='error'>Delete</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SectionDeleteModal;