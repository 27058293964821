import React, { memo, useEffect, useState } from "react";
import { worldMill } from '@react-jvectormap/world';
import { VectorMap } from "@react-jvectormap/core";
import { usMill } from "@react-jvectormap/unitedstates";
import { colorScale, extractMapDataPoints, extractMapMarkerDataPoints } from '../../utils/chartHelper';
import { Box, CircularProgress, Typography } from "@mui/material";
import NoDataCard from "./noDataCard";

const MapCard = ({ data = null, visualizationData = null, title = "" }) => {
  const [list, setList] = useState({});
  const [list2, setList2] = useState([]);
  const [scales, setScales] = useState([]);
  const [max, setMax] = useState(100);
  const [loading, setLoading] = useState(true);
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    setLoading(true);
    if (visualizationData?.['map.type'] === 'region') {
      const d = extractMapDataPoints(data, visualizationData, title);
      setList(d?.list || {});
      setScales(d?.scales || []);
      setMax(d?.max || 100);
      setTitle(d?.title || "");
    } else {
      const d = extractMapMarkerDataPoints(data, visualizationData, title);
      setList2(d?.list || []);
      setTitle(d?.title || "");
    }
    setLoading(false);
  }, [data, visualizationData, title]);

  const mapInitialConfig = {
    lat: visualizationData['map.center_latitude'] || "",
    lng: visualizationData['map.center_longitude'] || "",
    scale: visualizationData['map.zoom'] || 1,
    animate: true,
  }

  if (!list2.length && !scales.length) {
    return <NoDataCard />
  }

  if (visualizationData?.['map.type'] === 'region' && visualizationData?.['map.region'] === 'us_states') {
    return (
      <Box sx={{ width: '100%', height: '100%' }}>
        <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
        {
          loading
            ?
            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
            :
            <Box sx={{ width: '100%', mx: 'auto', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ width: '100%', height: '100%' }}>
                <VectorMap
                  map={usMill}
                  containerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  backgroundColor="#fff"
                  regionsSelectable={true}
                  series={{
                    regions: [
                      {
                        scale: colorScale,
                        values: list,
                        min: 0,
                        max: max,
                      },
                    ],
                  }}
                  onRegionTipShow={function reginalTip(event, label, code) {
                    return label.html(`
                      <div style="padding:10px 7px;width:150px;display:flex;flex-direction:row;align-items:center;justify-content:center;gap:5px;">
                        <div style="width:40%;display:flex;flex-direction:column;align-items:end;gap:5px;">
                          <p className="text-slate-400 font-light text-sm">State:</p>
                          <p className="text-slate-400 font-light text-sm">Count:</p>
                        </div>
                        <div style="width:60%;display:flex;flex-direction:column;align-items:start;gap:5px;">
                          <p className="font-normal text-white text-sm">${label.html()}</p>
                          <p className="font-normal text-white text-sm">${list?.[code] || 0}</p>
                        </div>
                      </div>
                    `);
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '150px', ml: 5 }}>
                {
                  scales.map((scale, i) => {
                    if (i < scales.length - 1) {
                      return (
                        <Box key={i} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, my: 1 }}>
                          <Box sx={{ width: 15, height: 15, borderRadius: 15, bgcolor: colorScale[i] }} className={`w-4 h-4 rounded-[50%]`} />
                          <Typography>{scale}-{scales[i + 1] - 1}</Typography>
                        </Box>
                      )
                    } else {
                      return (
                        <Box key={i} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, my: 1 }}>
                          <Box sx={{ width: 15, height: 15, borderRadius: 15, bgcolor: colorScale[i] }} className={`w-4 h-4 rounded-[50%]`} />
                          <Typography>{scale - 1}+</Typography>
                        </Box>
                      )
                    }
                  })
                }
              </Box>
            </Box>
        }
      </Box>
    )
  } else if (visualizationData?.['map.type'] === 'region') {
    return (
      <Box sx={{ width: '100%', height: '100%' }}>
        {list.length}
        <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
        {
          loading
            ?
            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
            :
            <Box sx={{ width: '100%', mx: 'auto', height: '95%', p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', bgcolor: '#f0f9ff', borderRadius: 2 }}>
              <Box sx={{ width: 'calc(100% - 120px)', height: '100%' }}>
                <VectorMap
                  map={worldMill}
                  containerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  backgroundColor="transparent"
                  series={{
                    regions: [
                      {
                        scale: colorScale,
                        values: list,
                        min: 0,
                        max: max,
                      },
                    ],
                  }}
                  onRegionTipShow={function reginalTip(event, label, code) {
                    return label.html(`
                      <div style="padding:10px 7px;width:180px;display:flex;flex-direction:row;align-items:center;justify-content:center;gap:5px;">
                        <div style="width:40%;display:flex;flex-direction:column;align-items:end;gap:5px;">
                          <p className="text-slate-400 font-light text-sm">Country:</p>
                          <p className="text-slate-400 font-light text-sm">Count:</p>
                        </div>
                        <div style="width:60%;display:flex;flex-direction:column;align-items:start;gap:5px;">
                          <p className="font-normal text-white text-sm">${label.html()}</p>
                          <p className="font-normal text-white text-sm">${list?.[code] || 0}</p>
                        </div>
                      </div>
                    `);
                  }}
                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '110px' }}>
                {
                  scales.map((scale, i) => {
                    if (i < scales.length - 1) {
                      return (
                        <Box key={i} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, my: 1 }}>
                          <Box sx={{ width: 15, height: 15, borderRadius: 15, bgcolor: colorScale[i] }} />
                          <Typography sx={{ color: '#475569', fontWeight: 500, fontSize: '14px' }}>{scale}-{scales[i + 1] - 1}</Typography>
                        </Box>
                      )
                    } else {
                      return (
                        <Box key={i} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, my: 1 }}>
                          <Box sx={{ width: 15, height: 15, borderRadius: 15, bgcolor: colorScale[i] }} />
                          <Typography sx={{ color: '#475569', fontWeight: 500, fontSize: '14px' }}>{scale - 1}+</Typography>
                        </Box>
                      )
                    }
                  })
                }
              </Box>
            </Box>
        }
      </Box>
    )
  } else {
    return (
      <Box sx={{ width: '100%', height: '100%' }}>
        <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
        {
          loading
            ?
            <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
            :
            <Box sx={{ width: '100%', mx: 'auto', height: '90%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 2, borderRadius: 2, overflow: 'hidden' }}>
              <Box sx={{ width: '100%', height: '100%' }}>
                <VectorMap
                  map={worldMill}
                  containerStyle={{
                    width: "100%",
                    height: "100%",
                  }}
                  backgroundColor="#bfdbfe"
                  markers={list2}
                  markerStyle={{
                    initial: {
                      fill: '#60a5fa',
                      stroke: '#1e40af',
                      "fill-opacity": 0.75,
                      "strokeWidth": 1,
                      "stroke-opacity": 0.5,
                      r: 5
                    },
                  }}
                  focusOn={mapInitialConfig}
                  onMarkerTipShow={function markerTip(event, label, code) {
                    return label.html(`
                      <div style="padding:10px 7px;width:120px;display:flex;flex-direction:row;align-items:center;justify-content:center;gap:5px;">
                        <div style="display:flex;flex-direction:column;align-items:end;gap:5px;">
                          <p className="text-slate-400 font-light text-sm">Count:</p>
                        </div>
                        <div style="display:flex;flex-direction:column;align-items:start;gap:5px;">
                          <p className="font-normal text-white text-sm">${list2[code]?.count || 0}</p>
                        </div>
                      </div>
                    `);
                  }}
                />
              </Box>
            </Box>
        }
      </Box>
    )
  }
}

export default memo(MapCard);