import { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";
import LocalSearchableDropdown from '../../../components/LocalSearchableDropdown';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getLabelValueFromOptions } from '../../../utils/consultantConstants';
import { useDispatch, useSelector } from 'react-redux';
import { addNewSOWClient, addNewSOWDuration, addNewSOWRole, addSOWDuration, getSOWDropDownOptions } from '../../../store/Sow/action';
import _ from 'lodash';
import moment, { duration } from 'moment';

const SOWCUModal = ({ open = false, handleClose, handleAdd, currentData = null }) => {
	const dispatch = useDispatch();
	const op = useSelector((state) => state.sow.options);
	const loading = useSelector((state) => state.sow.loading);
	const [data, setData] = useState(null);
	const [loadingClient, setLoadingClient] = useState(false);
	const [loadingRole, setLoadingRole] = useState(false);
	const [loadingDuration, setLoadingDuration] = useState(false);
	const [options, setOptions] = useState(op);
	const [noEntries, setNoEntries] = useState([]);

	useEffect(() => {
		if (currentData?.id) {
			let newOptions = { ...options }, newNoEntries = [];
			let duration = null;
			(newOptions?.duration || []).forEach(o => {
				if (o.label.includes(currentData?.sow_duration)) {
					duration = o.value;
				}
			});
			if (duration === null && currentData?.sow_duration) {
				duration = Date.now();
				newOptions.duration = [...newOptions.duration, { value: duration, label: currentData.sow_duration }];
				newNoEntries.push({ type: 'duration', value: currentData.sow_duration });
			};
			let entity = null;
			(newOptions?.entity || []).forEach(o => {
				let checkValue = currentData?.extracted_entity || "";
				checkValue = checkValue.split(" ");
				checkValue = checkValue?.length ? checkValue[0] : "";
				if (checkValue && o.label.includes(checkValue)) {
					entity = o.value;
				}
			});
			let client = null;
			(newOptions?.clients || []).forEach(o => {
				let checkValue = currentData?.extracted_client || "";
				checkValue = checkValue.split(" ");
				checkValue = checkValue?.length ? checkValue[0] : "";
				if (checkValue && o.label.includes(checkValue)) {
					client = o.value;
				}
			});
			if (client === null && currentData?.extracted_client) {
				client = Date.now();
				newOptions.clients = [...newOptions.clients, { value: client, label: currentData.extracted_client }];
				newNoEntries.push({ type: 'client', value: currentData.extracted_client });
			};
			let roles = [];
			let extracted_roles = currentData?.extracted_roles || "";
			extracted_roles = extracted_roles.split(',');
			if (extracted_roles?.length) {
				extracted_roles.forEach(r => {
					if (r) {
						let role = Date.now();
						newOptions.roles = [...newOptions.roles, { value: role, label: r }];
						roles.push(role);
						newNoEntries.push({ type: 'role', value: r, matchId: role });
					};
				});
			}
			let d = {
				project_title: currentData?.project_title || "",
				sow_start_date: currentData?.sow_start_date || "",
				sow_end_date: currentData?.sow_end_date || "",
				sow_duration: duration,
				entity_id: entity,
				client_id: client,
				roles,
			};
			setData(d);
			setOptions(newOptions);
			setNoEntries(newNoEntries);
		}
	}, [currentData]);

	useEffect(() => {
		if (data?.sow_start_date && data?.sow_duration && !data?.sow_end_date) {
			let gape = (getLabelValueFromOptions(data.sow_duration, options.duration) || "").split(" ");
			let consultantEndDate = moment
				.utc(data?.sow_start_date, "YYYY-MM-DD")
				.add(gape[0].replace("+", ""), gape[1]).format("YYYY-MM-DD");
			handleChange('sow_end_date', consultantEndDate);
		}
	}, [data]);

	const handleModalClose = () => {
		setData(null);
		handleClose();
	}

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const handleAddClient = (name = "", value = "") => {
		setLoadingClient(true);
		const payload = {
			name: value
		}
		dispatch(addNewSOWClient(payload, res => {
			if (res?.status && res?.data?.id) {
				dispatch(getSOWDropDownOptions((res2) => {
					setOptions(prev => ({ ...prev, clients: res2?.options?.clients || prev.clients }));
					setLoadingClient(false);
					handleChange(name, res.data.id);
				}));
			} else {
				setLoadingClient(false);
				handleChange(name, "");
				toast.error('Failed to add new client!');
			}
		}));
	}

	const handleAddRole = (name = "", oldEntries = [], value = "") => {
		setLoadingRole(true);
		const payload = {
			name: value
		}
		dispatch(addNewSOWRole(payload, res => {
			if (res?.status && res?.data?.id) {
				dispatch(getSOWDropDownOptions((res2) => {
					setOptions(prev => ({ ...prev, roles: res2?.options?.roles || prev.roles }));
					setLoadingRole(false);
					handleChange(name, [...oldEntries, res.data.id]);
				}));
			} else {
				setLoadingRole(false);
				handleChange(name, "");
				toast.error('Failed to add new role!');
			}
		}));
	}

	const handleAddDuration = (name = "", value = "") => {
		setLoadingDuration(true);
		const payload = {
			options: value,
			fieldType: 'sow_duration',
		}
		dispatch(addNewSOWDuration(payload, res => {
			if (res?.status && res?.data?.id) {
				dispatch(getSOWDropDownOptions((res2) => {
					setOptions(prev => ({ ...prev, duration: res2?.options?.duration || prev.duration }));
					setLoadingDuration(false);
					handleChange(name, res.data.id);
				}));
			} else {
				setLoadingDuration(false);
				handleChange(name, "");
				toast.error('Failed to add new client!');
			}
		}));
	}

	const checkValid = () => {
		let valid = true;
		if (!data?.project_title || !data?.client_id || !data?.entity_id || !data?.sow_start_date) {
			valid = false;
		}
		return valid;
	}

	const handleAddData = async () => {
		const check = checkValid();
		if (check) {
			let payload = {
				...data,
				id: currentData.id,
				sow_duration: (data?.sow_duration || "").toString(),
			};
			if (noEntries.length) {
				let done = 0;
				for (let i = 0; i < noEntries.length; i++) {
					let type = noEntries[i].type;
					let value = noEntries[i].value;
					let matchId = noEntries[i].matchId;
					if (type === 'duration') {
						const dPayload = {
							options: value,
							fieldType: 'sow_duration',
						}
						let dRes = await addSOWDuration(dPayload);
						if (dRes?.status && dRes?.data?.id) {
							payload = {
								...payload,
								sow_duration: (dRes.data.id).toString(),
							}
						}
					} else if (type === 'client') {
						const cPayload = {
							options: value,
							fieldType: 'sow_duration',
						}
						let cRes = await addSOWDuration(cPayload);
						if (cRes?.status && cRes?.data?.id) {
							payload = {
								...payload,
								client_id: cRes.data.id
							}
						}
					} else if (type === 'role') {
						const rPayload = {
							options: value,
							fieldType: 'sow_duration',
						}
						let rRes = await addSOWDuration(rPayload);
						if (rRes?.status && rRes?.data?.id) {
							payload.roles = payload.roles.filter(item => item !== matchId);
							payload = {
								...payload,
								roles: [...payload.roles, rRes.data.id]
							}
						}
					}
					done++;
					if (done === noEntries.length) {
						handleAdd(payload);
					}
				}
			} else {
				handleAdd(payload);
			}
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle>{currentData?.parent_id ? 'Add new revision' : 'Add a new statement of work'}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>Project name <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
						<TextField
							value={data?.project_title || ""}
							onChange={(e) => {
								handleChange('project_title', e.target.value);
							}}
							placeholder={"Please enter project name!"}
							sx={{ width: "100%", background: "#FFFFFF" }}
							inputProps={{ paddingBottom: "100px" }}
							type={'text'}
							id='project-name-input'
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='subtitle2'>Entity <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
						<LocalSearchableDropdown
							value={data?.entity_id || null}
							label="Entity"
							options={options?.entity || []}
							handleChange={(v) => handleChange('entity_id', v.value)}
							id='enitiy-input'
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='subtitle2'>Client <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
						<LocalSearchableDropdown
							value={data?.client_id || null}
							label="Client"
							options={options?.clients || []}
							handleChange={(v) => {
								if (v?.value) {
									handleChange('client_id', v.value);
								} else if (v?.inputValue) {
									handleAddClient('client_id', v.inputValue);
								}
							}}
							disabled={loadingClient}
							add={true}
							loading={loadingClient}
							id='client-input'
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='subtitle2'>Roles</Typography>
						<LocalSearchableDropdown
							multiple={true}
							value={_.isArray(data?.roles) ? data.roles : []}
							label="Roles"
							options={options?.roles || []}
							handleChange={(v, newEntry) => {
								if (newEntry) {
									handleAddRole('roles', v, newEntry);
								} else {
									handleChange('roles', v);
								}
							}}
							disabled={loadingRole}
							add={true}
							loading={loadingRole}
							id='roles-input'
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='subtitle2'>Duration</Typography>
						<LocalSearchableDropdown
							value={data?.sow_duration || null}
							label="Duration"
							options={options?.duration || []}
							handleChange={(v) => {
								if (v?.value) {
									handleChange('sow_duration', v.value)
								} else if (v?.inputValue) {
									handleAddDuration('sow_duration', v.inputValue);
								}
							}}
							disabled={loadingDuration}
							add={true}
							loading={loadingDuration}
							id='duration-input'
						/>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='subtitle2'>Start Date <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								renderInput={(inputProps) =>
									<TextField
										InputLabelProps={{ shrink: true }}
										type="date"
										disabled={false}
										value={data?.sow_start_date || ""}
										variant="outlined"
										sx={{ width: "100%", background: "#FFFFFF" }}
										onChange={(e) => {
											handleChange('sow_start_date', e.target.value);
										}}
										id='start-date-input'
									/>}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={6}>
						<Typography variant='subtitle2'>End Date</Typography>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								renderInput={(inputProps) =>
									<TextField
										InputLabelProps={{ shrink: true }}
										type="date"
										disabled={false}
										value={data?.sow_end_date || ""}
										variant="outlined"
										sx={{ width: "100%", background: "#FFFFFF" }}
										onChange={(e) => {
											handleChange('sow_end_date', e.target.value);
										}}
										id='end-date-input'
									/>}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button id='close-btn' onClick={handleModalClose}>Close</Button>
				<Button id='submit-btn' startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} disabled={loading} onClick={handleAddData} variant='contained'>Submit</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SOWCUModal;