import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const SOWDeleteModal = ({ open = false, handleClose, handleDelete, handleDeleteLatest, currentData = null }) => {

	const handleModalClose = () => {
		handleClose();
	}

	const handleDeleteDoc = () => {
		handleDelete({ id: currentData?.id });
	}

	const handleDeleteLastestDoc = () => {
		handleDeleteLatest({ id: currentData?.id });
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={"sm"}
		>
			<DialogTitle>Delete statement of work</DialogTitle>
			<DialogContent>
				<Typography variant='body1'>Are you sure you want to remove this statement of work?</Typography>
			</DialogContent>
			<DialogActions>
				<Button id='close-btn' onClick={handleModalClose}>No</Button>
				{currentData?.parent_id ? <Button id='delete-last-btn' onClick={handleDeleteLastestDoc} variant='outlined' color='error'>Delete Last Revision</Button> : null}
				<Button id='delete-btn' onClick={handleDeleteDoc} variant='contained' color='error'>{currentData?.parent_id ? "Delete All" : "Yes"}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SOWDeleteModal;