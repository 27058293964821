import moment from "moment";

const statusDropdownConsultant = [
  {
    id: 4,
    value: "draft",
    label: "Draft",
  },
  {
    id: 2,
    value: "inprocess",
    label: "In-Process",
  },
  {
    id: 0,
    value: "active",
    label: "Active",
  },
  {
    id: 1,
    value: "inactive",
    label: "In-Active",
  },
];
const statusDropdown = [
  {
    id: 6,
    value: "Recruitment Draft",
    label: "Recruitment Draft",
  },
  {
    id: 5,
    value: "Draft",
    label: "Draft",
  },
  {
    id: 2,
    value: "In-Process",
    label: "In-Process",
  },
  {
    id: 0,
    value: "Active",
    label: "Active",
  },
  {
    id: 4,
    value: "Dropped",
    label: "Dropped",
  },
  {
    id: 1,
    value: "In-Active",
    label: "In-Active",
  },
];

const genderDropdown = [
  {
    id: 0,
    value: "male",
    label: "Male",
  },
  {
    id: 1,
    value: "female",
    label: "Female",
  },
  {
    id: 2,
    value: "other",
    label: "Other",
  },
];
const maritalStatusDropdown = [
  {
    id: 0,
    value: "single",
    label: "Single",
  },
  {
    id: 1,
    value: "married",
    label: "Married",
  },
  {
    id: 2,
    value: "widowed",
    label: "Widowed",
  },
  {
    id: 3,
    value: "divorced",
    label: "Divorced",
  },
  {
    id: 4,
    value: "separated",
    label: "Separated",
  },
  {
    id: 5,
    value: "registered-partnership",
    label: "Registered partnership",
  },
];

const yesNoDrowdown = [
  {
    id: 0,
    value: true,
    label: "Yes",
  },
  {
    id: 1,
    value: false,
    label: "No",
  },
];

const approvedByList = [
  {
    id: 1,
    name: "Vinay",
  },
  {
    id: 2,
    name: "Suresh",
  },
  {
    id: 3,
    name: "Sidd",
  },
];

export const vendorPill={
  id: 1,
  name: "Vendor",
  callName: "Vendor",
  apiFieldName: "vendor",
  variables:[{label:"name",key:"vendorName"}],
  columns:[
    {
      field: "vendor_name",
      flex: 1,
      headerName: "Name",
      minWidth: 250,
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Created",
      minWidth: 200,
      valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      field: "updated_at",
      flex: 1,
      headerName: "Last Updated",
      minWidth: 200,
      valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
  ]
};

const vendorSettingsOptions = [
  {
    id: 1,
    name: "Insurance",
    callName: "Insurance",
    apiFieldName: "insurance",
    variables:[{label:"insurance agency",key:"agencyID",type:"searchable-drodown"},{label:"insurance type",key:"insuranceType"}],
    editVariables:[{label:"insurance type",key:"insuranceType"},{label:'expiry date',key:'expiryDate',type:"date"}],
    columns:[
      {
        field: "cert_of_insurance_type",
        flex: 1,
        headerName: "Insurance Type",
        minWidth: 250,
      },
      {
        field: "agency_name",
        flex: 1,
        headerName: "Agency",
        minWidth: 250,
      },
      {
        field: "cert_of_insurance_expiry_date",
        flex: 1,
        headerName: "Expiry Date",
        minWidth: 250,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 2,
    name: "SDF",
    callName: "SDF",
    apiFieldName: "sdf",
    variables:[{label:"sdf agency",key:"agencyID",type:"searchable-drodown"},{label:"sdf",key:"sdf"}],
    editVariables:[{label:"sdf",key:"sdf"},{label:'expiry date',key:'expiryDate',type:"date"}],
    columns:[
      {
        field: "sdf",
        flex: 1,
        headerName: "SDF",
        minWidth: 250,
      },
      {
        field: "agency_name",
        flex: 1,
        headerName: "Agency",
        minWidth: 250,
      },
      {
        field: "sdf_expiry_date",
        flex: 1,
        headerName: "Expiry Date",
        minWidth: 250,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
];

const settingsOptions = [
  {
    id: 1,
    name: "Human resource",
    callName: "Human resource",
    apiFieldName: "hr",
    isConstant: false,
    isVisible: true,
    variables:[{label:"employee ID",key:"emp_id"},{label:"Role",key:"role"},{label:"name",key:"name"}],
    editVariables:[{label:"employee ID",key:"emp_id"},{label:"Role",key:"role"},{label:"name",key:"name"}],
    specialType:true,
    columns:[
      {
        field: "emp_id",
        flex: 1,
        headerName: "Employee ID",
        minWidth: 120,
      },
      {
        field: "role",
        flex: 1,
        headerName: "Role",
        minWidth: 250,
      },
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 2,
    name: "Domains",
    callName: "Domain",
    apiFieldName: "domains",
    postApiFieldName: "domain",
    isConstant: false,
    isVisible: false,
    variables:[{label:"name",key:"name"}],
    specialType:true,
    columns:[
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 3,
    name: "Business unit",
    callName: "Business unit",
    apiFieldName: "businessUnit",
    isConstant: false,
    isVisible: false,
    variables:[{label:"Business Id",key:"bu_id"},{label:"name",key:"bu_owner"}],
    specialType:true,
    columns:[
      {
        field: "bu_id",
        flex: 1,
        headerName: "ID",
        minWidth: 120,
      },
      {
        field: "bu_owner",
        flex: 1,
        headerName: "Name",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 41,
    name: "Clients",
    callName: "Client",
    apiFieldName: "clients",
    postApiFieldName:'client',
    isConstant: false,
    isVisible: false,
    variables:[{label:"client name",key:"name"}],
    specialType:true,
    columns:[
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 42,
    name: "End Clients",
    callName: "End Client",
    apiFieldName: "endClients",
    postApiFieldName:"client",
    isConstant: false,
    isVisible: false,
    variables:[{label:"end client name",key:"name"}],
    specialType:true,
    columns:[
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 5,
    name: "Job title",
    callName: "Job title",
    apiFieldName: "constant?fieldType=job_title",
    isConstant: true,
    isVisible: false,
    variables:[{label:"job Title",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Job Title",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 6,
    name: "Skills",
    callName: "Skill",
    apiFieldName: "constant?fieldType=skill",
    isConstant: true,
    isVisible: false,
    variables:[{label:"skill",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Skill",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 7,
    name: "Work authorization type",
    callName: "Work authorization type",
    apiFieldName: "constant?fieldType=wa_type",
    isConstant: true,
    isVisible: false,
    variables:[{label:"work authorization type",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Type",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 8,
    name: "Benefit Plan",
    callName: "Benefit Plan",
    apiFieldName: "constant?fieldType=benefit_plan",
    isConstant: true,
    isVisible: false,
    variables:[{label:"benifit plan name",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Plan",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 9,
    name: "Ethnicity",
    callName: "Ethnicity",
    apiFieldName: "constant?fieldType=ethnicity",
    isConstant: true,
    isVisible: false,
    variables:[{label:"ethnicity",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Ethnicity",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 10,
    name: "Emergency contact relation",
    callName: "Emergency contact relation",
    apiFieldName: "constant?fieldType=emergency_contact_relation",
    isConstant: true,
    isVisible: false,
    variables:[{label:"emergency contact relation",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Relation",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 11,
    name: "Duration",
    callName: "Duration",
    apiFieldName: "constant?fieldType=duration",
    isConstant: true,
    isVisible: false,
    variables:[{label:"duration",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Duration",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 12,
    name: "Contract type",
    callName: "Contract type",
    apiFieldName: "constant?fieldType=contractor_type",
    isConstant: true,
    isVisible: false,
    variables:[{label:"contract type",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Type",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 13,
    name: "Entity",
    callName: "Entity",
    apiFieldName: "constant?fieldType=entity",
    isConstant: true,
    isVisible: false,
    variables:[{label:"entity",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Entity",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 14,
    name: "Entity Location",
    callName: "Entity Location",
    apiFieldName: "constant?fieldType=entity_loc",
    isConstant: true,
    isVisible: false,
    variables:[{label:"location",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Location",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  // {
  //   id: 16,
  //   name: "Contractor",
  //   callName: "Contractor",
  //   apiFieldName: "constant?fieldType=contractor",
  //   isConstant: true,
  //   isVisible: false,
  //   getDataAction: getSettingConstantsAction,
  //   createDataAction: createSettingConstantsAction,
  //   variables:[{label:"contractor name",key:"options"}],
  //   columns:[
  //     {
  //       field: "options",
  //       flex: 1,
  //       headerName: "Name",
  //       minWidth: 250,
  //     },
  //     {
  //       field: "created_at",
  //       flex: 1,
  //       headerName: "Created",
  //       minWidth: 200,
  //       valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
  //     },
  //     {
  //       field: "updated_at",
  //       flex: 1,
  //       headerName: "Last Updated",
  //       minWidth: 200,
  //       valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
  //     },
  //   ]
  // },
  
  {
    id: 18,
    name: "Currency",
    callName: "Currency",
    apiFieldName: "constant?fieldType=curr",
    isConstant: true,
    isVisible: false,
    variables:[{label:"currency",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Currency",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 19,
    name: "Payment basis",
    callName: "Payment basis",
    apiFieldName: "constant?fieldType=basis",
    isConstant: true,
    isVisible: false,
    variables:[{label:"payment basis",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Payment Basis",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 20,
    name: "Invoicing Term",
    callName: "Invoicing Term",
    apiFieldName: "constant?fieldType=invoice_terms",
    isConstant: true,
    isVisible: false,
    variables:[{label:"invoicing term",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Term",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 21,
    name: "Payment Term",
    callName: "Payment Term",
    apiFieldName: "constant?fieldType=pay_terms",
    isConstant: true,
    isVisible: false,
    variables:[{label:"payment term",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Term",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 22,
    name: "Source",
    callName: "Source",
    apiFieldName: "constant?fieldType=src",
    isConstant: true,
    isVisible: false,
    variables:[{label:"source type",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Source",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  
  {
    id: 24,
    name: "Track",
    callName: "Track",
    apiFieldName: "constant?fieldType=track",
    isConstant: true,
    isVisible: false,
    variables:[{label:"tracker name",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Tracker",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 25,
    name: "Insurance Agency",
    callName: "Insurance Agency",
    apiFieldName: "constant?fieldType=agency",
    isConstant: true,
    isVisible: false,
    variables:[{label:"insurance agency name",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Agency Name",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 26,
    name: "SDF Agency",
    callName: "SDF Agency",
    apiFieldName: "constant?fieldType=sdf_agency",
    isConstant: true,
    isVisible: false,
    variables:[{label:"sdf agency name",key:"options"}],
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Agency Name",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 27,
    name: "SOW Roles",
    callName: "SOW Roles",
    apiFieldName: "getSowRoles",
    postApiFieldName:'createSowRole',
    isConstant: false,
    isVisible: true,
    variables:[{label:"name",key:"name"}],
    noMerge:true,
    specialType:true,
    columns:[
      {
        field: "name",
        flex: 1,
        headerName: "Name",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
  {
    id: 28,
    name: "SOW Durations",
    callName: "SOW Durations",
    apiFieldName: "constant?fieldType=sow_duration",
    isConstant: true,
    isVisible: true,
    variables:[{label:"duration",key:"options"}],
    noMerge:true,
    columns:[
      {
        field: "options",
        flex: 1,
        headerName: "Duration",
        minWidth: 250,
      },
      {
        field: "created_at",
        flex: 1,
        headerName: "Created",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
      {
        field: "updated_at",
        flex: 1,
        headerName: "Last Updated",
        minWidth: 200,
        valueFormatter: params => params.value? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
      },
    ]
  },
];

const vendorSettingOptions = [
  {
    id: 17,
    name: "Supplier Diversity Form (SDF)",
    apiFieldName: "sdf",
    isConstant: true,
    isVisible: false,
  },
  {
    id: 23,
    name: "Certificate of insurance type",
    apiFieldName: "cert_of_insurance_type",
    isConstant: true,
    isVisible: false,
  },
  
];

export const renameKeys=(obj, newKeys)=>{
  const keyValues = Object.keys(obj).map(key => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
}

export const convertToOptionList=(list=[],fieldName="")=>{
    let newList=[];
    if(fieldName){
      list.map((item)=>{
        newList.push({...item,label:item[fieldName]})
      });
    }
    return newList;
}

export {
  statusDropdownConsultant,
  statusDropdown,
  genderDropdown,
  maritalStatusDropdown,
  yesNoDrowdown,
  approvedByList,
  settingsOptions,
  vendorSettingOptions,
  vendorSettingsOptions
};

export const getFieldName=(fieldName='')=>{
  let f= fieldName;
  if(f==='business_unit'){
    f='businessUnit'
  }else if(f==='domain'){
    f='domains';
  }else if(f==='client'){
    f='clients';
  }
  let settingsValue=settingsOptions.filter(item=>(item?.apiFieldName || "").replace('constant?fieldType=','') === f);
  if(f==='vendor'){
    settingsValue=[vendorPill];
  }
  return settingsValue?.length ? settingsValue[settingsValue.length-1]?.name : f;
}

export const getFieldValues=(fieldName='')=>{
  let f= fieldName;
  if(f==='business_unit'){
    f='businessUnit';
  }else if(f==='domain'){
    f='domains';
  }else if(f==='client'){
    f='clients';
  }
  let settingsValue=settingsOptions.filter(item=>(item?.apiFieldName || "").replace('constant?fieldType=','') === f);
  if(f==='vendor'){
    settingsValue=[vendorPill];
  }
  return settingsValue.length ? settingsValue[settingsValue.length-1] : null;
}

export const appRegions=[
  {
    name:'United States of America',
    code:'US',
  },
  {
    name:'United Arab Emirates',
    code:'AE',
  },
]