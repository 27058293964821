import {
  GET_DOWNLOAD_REPORT_HISTORY_IN_PROGRESS,
  GET_DOWNLOAD_REPORT_HISTORY_SUCCESS,
  GET_DOWNLOAD_REPORT_HISTORY_FAILED,
  DOWNLOAD_REPORT_FAILED,
  DOWNLOAD_REPORT_IN_PROGRESS,
  DOWNLOAD_REPORT_SUCCESS,
} from "../../../constants/store";

export const initialState = {
  reports: {},
  loading: false,
  error: false,
  complete: false,
  createSuccess: false,
  getSuccess: false,
};

function downloadReducer(state = initialState, action) {
  switch (action.type) {
    case DOWNLOAD_REPORT_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };

      return newState;
    }
    case DOWNLOAD_REPORT_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case DOWNLOAD_REPORT_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case GET_DOWNLOAD_REPORT_HISTORY_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };

      return newState;
    }
    case GET_DOWNLOAD_REPORT_HISTORY_SUCCESS: {
      const newState = {
        ...state,
        complete: true,
        createSuccess: action.payload.createSuccess,
        reports: action.payload.reports,
      };
      return newState;
    }
    case GET_DOWNLOAD_REPORT_HISTORY_FAILED: {
      const newState = {
        ...state,
        error: true,
      };
      return newState;
    }
    default:
      return state;
  }
}

export default downloadReducer;
