import { Box } from '@mui/material';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import CustomPhoneNumber from './phoneNumber';

const PhoneNumberInput = ({ id = "", name = "", value = "", handleChange, disabled = false }) => {
  return (
    <Box>
      <PhoneInput
        id={id}
        name={name}
        defaultCountry="US"
        placeholder="Enter phone number"
        value={value}
        onChange={handleChange}
        inputComponent={CustomPhoneNumber}
        disabled={disabled}
      />
    </Box>
  )
}

export default PhoneNumberInput;