import { useState, useEffect } from "react";
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Add, Cancel, Edit } from "@mui/icons-material";
import DividerComponent from "../../../../components/Divider/Divider";
import PhoneNumberInput from "../../../../components/PhoneNumberInput/PhoneNumberInput";
import { cityOptions, convertSearchedListToOptions, countryOptions, extractValuableOptions, getOptionFromList, stateOptions } from "../../../../utils/consultantConstants";
import LocalSearchableDropdown from "../../../../components/LocalSearchableDropdown";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputMakerModal from "./InputMaker/InputMakerModal";
import { formComponents } from '../../../../utils/consultantConstants';
import { blue, grey } from "@mui/material/colors";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import api from "../../../../config/https";
import _ from "lodash";
import { createConfigurationField, deleteConfigurationField, getConfigurationFields, updateConfigurationField } from "../../../../store/Settings/action";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import InputDeleteModal from "./InputDeleteModal";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const InputDesigner = ({ hasWritePermission = false }) => {
  const dispatch = useDispatch();
  const { configurationList = [], loadingConfiguration = false } = useSelector((state) => state.settingsConstants);

  const [showFormMaker, setShowFormMaker] = useState(false);
  const [currentContent, setCurrentContent] = useState(null);
  const [expanded, setExpanded] = useState(null);
  const [options, setOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getConfigurationFields());
  }, []);

  const handleShowFormMaker = () => {
    setShowFormMaker(true);
    setCurrentContent(null);
  }

  const handleHideFormMakerModal = () => {
    setShowFormMaker(false);
    setCurrentContent(null);
  }

  const handleAddNewInput = (data = null) => {
    let foundItem = _.findIndex(configurationList, { label: data?.label, variable: data?.variable });
    if (foundItem === -1) {
      setShowFormMaker(false);
      dispatch(createConfigurationField(data, (res) => {
        if (res?.status) {
          toast.success('New input field added successfully!');
          dispatch(getConfigurationFields());
        } else {
          toast.error('Failed to add new input field!')
        }
      }));
    } else {
      toast.error("This field already exists. Please choose a unique label and variable name!");
    }
  }

  const handleEditFormContent = (content = null) => {
    setCurrentContent(content);
    setShowFormMaker(true);
  }

  const handleRemoveFormContent = (content = null) => {
    setCurrentContent(content);
    setShowDeleteModal(true);
  }

  const handleHideDeleteModal = () => {
    setShowDeleteModal(false);
    setCurrentContent(null);
  }

  const handleDeleteFormContent = (id) => {
    setShowDeleteModal(false);
    dispatch(deleteConfigurationField({ id: id }, (res) => {
      if (res?.status) {
        setCurrentContent(null);
        toast.success('Input field removed successfully!');
        dispatch(getConfigurationFields());
      } else {
        toast.error('Failed to remove input field!')
      }
    }));
  }

  const handleUpdateFormContent = (content = null) => {
    let foundItem = _.find(configurationList, { label: content?.label, variable: content?.variable });
    if (!foundItem || foundItem?.id === content?.id) {
      setShowFormMaker(false);
      dispatch(updateConfigurationField(content, (res) => {
        if (res?.status) {
          setCurrentContent(null);
          toast.success('Input field updated successfully!');
          dispatch(getConfigurationFields());
        } else {
          toast.error('Failed to update input field!')
        }
      }));
    } else {
      toast.error("This field already exists. Please choose a unique label and variable name!");
    }
  }

  const handleChangeAccordian = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleSearch = async (name = "", value = "", key) => {
    setLoading(prev => [...prev, name]);
    let url = `/masterAdmin/constant?fieldType=${name}&search=${value}`;
    if (name === 'vendor') {
      url = `/masterAdmin/vendor?vendorName=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'vendor_name', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoading(prev => prev.filter(item => item !== name));
    } else if (name === 'businessUnit' || name === 'clients' || name === 'endclients' || name === 'domains' || name === 'hr') {
      url = `/masterAdmin/${name}?search=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'name', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoading(prev => prev.filter(item => item !== name));
    } else if (name === 'sdf' || name === 'insurance') {
      if (data?.contractorName) {
        url = `/masterAdmin/${name}?vendorID=${data.contractorName}&search=${value}`;
        let res = await api.get(url);
        if (res?.data?.status && res?.data?.data?.data?.length) {
          let list = res?.data?.data?.data;
          if (name === 'insurance') {
            let d = convertSearchedListToOptions(list, 'options', 'cert_of_insurance_type', 'id');
            setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
          } else {
            let d = convertSearchedListToOptions(list, 'options', name, 'id');
            setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
          }
        }
      } else {
        toast.error('Please enter vendor name');
      }
      setLoading(prev => prev.filter(item => item !== name));
    } else {
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoading(prev => prev.filter(item => item !== name));
    }
  }

  const handleChange = (name, value) => {
    setData(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeLocationData = (name = "", value = "", belong = "", item) => {
    if (belong === 'country') {
      let op = stateOptions(value);
      setOptions(prev => ({ ...prev, [item.state.variable]: op, [item.city.variable]: [] }));
      setData(prev => ({ ...prev, [name]: value, [item.state.variable]: "", [item.city.variable]: "" }));
    } else if (belong === 'state') {
      let op = cityOptions(data[item.country.variable], value);
      setOptions(prev => ({ ...prev, [item.city.variable]: op }));
      setData(prev => ({ ...prev, [name]: value, [item.city.variable]: "" }));
    } else {
      setData(prev => ({ ...prev, [name]: value }));
    }
  }

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', my: 0, gap: 1 }}>
        <Typography variant="h6">Input List</Typography>
        {hasWritePermission ?
          <Button id={'add-input-btn'} name={'add-input-btn'} sx={{ display: 'flex', flex: '0 0 180px', flexDirection: 'row', alignItems: 'center', gap: 1 }} onClick={handleShowFormMaker}>
            <AddCircleIcon />
            <Typography sx={{ fontWeight: 'medium', textTransform: 'uppercase', fontSize: 15, }}>Add new input</Typography>
          </Button>
          :
          null
        }
      </Box>
      <DividerComponent titleStyle={{ fontSize: 16, fontWeight: 500, color: blue[500] }} outerStyle={{ marginTop: 2, }} />
      {formComponents.map((formComponent, formComponentIndex) => {
        return formComponent.variants.map((variant, varientIndex) => {
          let items = configurationList.filter(field => field?.configType === (formComponent?.label).toLowerCase() && field?.configVariant === (variant?.label).toLowerCase());
          return (
            <Accordion expanded={expanded === `panel${formComponentIndex + "" + varientIndex}`} onChange={handleChangeAccordian(`panel${formComponentIndex + "" + varientIndex}`)} key={varientIndex}>
              <AccordionSummary aria-controls={`panel-${formComponentIndex + "" + varientIndex}-content`} id={`panel-${formComponentIndex + "" + varientIndex}-header`}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <Typography color={blue[500]} variant="subtitle1">{formComponent?.label || ""}:</Typography>
                    <Typography variant="subtitle2">{variant?.label || ""}</Typography>
                  </Box>
                  <Typography color={blue[500]} variant="subtitle2">{items?.length || 0} input field(s)</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {
                    expanded === `panel${formComponentIndex + "" + varientIndex}` && configurationList.map((field, fieldIndex) => {
                      if (field?.configType === (formComponent?.label).toLowerCase() && field?.configVariant === (variant?.label).toLowerCase()) {
                        return (
                          <RenderComponent
                            hasWritePermission={hasWritePermission}
                            key={fieldIndex}
                            item={field}
                            index={fieldIndex}
                            data={data}
                            loading={loading}
                            options={options}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            handleChange={handleChange}
                            handleSearch={handleSearch}
                            handleChangeLocationData={handleChangeLocationData}
                            handleEditFormContent={handleEditFormContent}
                            handleRemoveFormContent={handleRemoveFormContent}
                          />
                        )
                      }
                    })
                  }
                  {
                    !items?.length
                      ?
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color={grey[500]}>No components found!</Typography>
                      </Grid>
                      :
                      null
                  }
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        })
      })
      }
      {showFormMaker ? <InputMakerModal open={showFormMaker} handleClose={handleHideFormMakerModal} handleAdd={handleAddNewInput} currentData={currentContent} handleUpdate={handleUpdateFormContent} /> : null}
      {showDeleteModal ? <InputDeleteModal open={showDeleteModal} handleClose={handleHideDeleteModal} handleDelete={handleDeleteFormContent} currentData={currentContent} /> : null}
      {loadingConfiguration ? <OverlayLoader /> : null}
    </Box>
  )
}

const RenderComponent = ({ hasWritePermission = false, item = null, index = 0, data = null, options = null, loading = [], handleEditFormContent = () => { }, handleRemoveFormContent = () => { }, handleChange = () => { }, selectedOptions = [], setSelectedOptions = () => { }, handleSearch = () => { }, handleChangeLocationData = () => { }, handleChangePlan = () => { } }) => {
  if (item?.type === 'text') {
    return (
      <Grid item xs={4}>
        <Box className='form-maker-box'>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <TextField
            placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            inputProps={{ step: 0.01, maxLength: item?.textLength || 524288 }}
            required={item?.systemRequired || false}
            InputProps={{
              startAdornment: <InputAdornment position="start">{item?.prefix || ""}</InputAdornment>,
            }}
            type={item?.decimal ? 'number' : 'text'}
            value={data?.[item.variable] || ""}
            onChange={(e) => {
              let value = e?.target?.value || "";
              if (item?.configVariant === 'number') {
                const numberRegex = /^[0-9]+$/;
                if (item?.decimal) {
                  const decimalNumberRegex = /^[0-9]+\.?[0-9]*$/;
                  if (decimalNumberRegex.test(value) || value === '') {
                    handleChange(item.variable, value);
                  }
                } else if (numberRegex.test(value) || value === '') {
                  handleChange(item.variable, value);
                }
              } else {
                handleChange(item.variable, value);
              }
            }}
            id={item.variable}
            name={item.variable}
          />
          {item?.confidential
            ?
            <FormControlLabel control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={item?.confidentialLabel || ""} />
            :
            null
          }
          <ActionBox hasWritePermission={hasWritePermission} item={item} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  } else if (item?.type === 'email') {
    return (
      <Grid item xs={item?.size || 4} key={'input-' + index}>
        <Box className='form-maker-box'>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <TextField
            placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            InputProps={{
              startAdornment: <InputAdornment position="start">{item?.prefix || ""}</InputAdornment>,
            }}
            type='email'
            value={data?.[item.variable] || ""}
            onChange={(e) => handleChange(item.variable, e?.target?.value)}
            id={item.variable}
            name={item.variable}
          />
          {item?.confidential
            ?
            <FormControlLabel control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={item?.confidentialLabel || ""} />
            :
            null
          }
          <ActionBox hasWritePermission={hasWritePermission} item={item} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  } else if (item.type === 'phone') {
    return (
      <Grid item xs={4}>
        <Box className='form-maker-box'>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <PhoneNumberInput
            id={item.variable}
            name={item.variable}
            value={data?.[item.variable] || ""}
            handleChange={(value) => handleChange(item.variable, value)}
          />
          {item?.confidential
            ?
            <FormControlLabel control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={item?.confidentialLabel || ""} />
            :
            null
          }
          <ActionBox hasWritePermission={hasWritePermission} item={item} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  } else if (item.type === 'date') {
    return (
      <Grid item xs={4}>
        <Box className='form-maker-box'>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(inputProps) =>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  variant="outlined"
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  value={data?.[item.variable] || ""}
                  onChange={(e) => handleChange(item.variable, e?.target?.value)}
                  id={item.variable}
                  name={item.variable}
                />}
            />
          </LocalizationProvider>
          {item?.confidential
            ?
            <FormControlLabel control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={item?.confidentialLabel || ""} />
            :
            null
          }
          <ActionBox hasWritePermission={hasWritePermission} item={item} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  } else if (item.type === 'dropdown') {
    return (
      <Grid item xs={4}>
        <Box className='form-maker-box'>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <FormControl fullWidth>
            <Select
              sx={{ width: "100%", background: "#FFFFFF" }}
              displayEmpty
              value={data?.[item.variable] || ""}
              onChange={(e) => handleChange(item?.variable, e?.target?.value)}
              id={item.variable}
              name={item.variable}
            >
              <MenuItem disabled value="">{"Please select a " + (item?.label || "").toLowerCase()}</MenuItem>
              {(extractValuableOptions(item?.options || [])).map((option, index) => {
                return (
                  <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
          {item?.confidential
            ?
            <FormControlLabel control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={item?.confidentialLabel || ""} />
            :
            null
          }
          <ActionBox hasWritePermission={hasWritePermission} item={item} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  } else if (item?.type === 'searchable-dropdown') {
    return (
      <Grid item xs={4}>
        <Box className='form-maker-box'>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
          <LocalSearchableDropdown
            id={item.variable}
            multiple={item?.multiple}
            label={item?.label}
            value={item?.multiple ? data?.[item.variable] || [] : data?.[item.variable] || null}
            handleChange={(v) => {
              if (item?.multiple) {
                let oldValues = data?.[item.variable] || [];
                let intersection = [];
                if (v.length > oldValues.length) {
                  intersection = v.filter(x => !oldValues.includes(x));
                  if (intersection.length) {
                    const option = getOptionFromList(options[item.variable], intersection[0]);
                    if (option?.value) {
                      setSelectedOptions(prev => {
                        let oldOptions = prev?.[item.searchVariable] || [];
                        let op = [...oldOptions, option];
                        let list = [...new Map(op.map(item => [item.value, item])).values()];
                        return ({
                          ...prev,
                          [item.searchVariable]: list,
                        });
                      });
                    }
                  }
                } else {
                  intersection = oldValues.filter(x => !v.includes(x));
                  if (intersection.length) {
                    setSelectedOptions(prev => {
                      let oldOptions = prev?.[item.searchVariable] || [];
                      oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                      return ({
                        ...prev,
                        [item.searchVariable]: oldOptions,
                      });
                    });
                  }
                }
                handleChange(item.variable, v);
              } else {
                handleChange(item.variable, v?.value);
                if (v?.value) {
                  setSelectedOptions(prev => {
                    let oldOptions = prev?.[item.searchVariable] || [];
                    let op = [...oldOptions, v];
                    let list = [...new Map(op.map(item => [item.value, item])).values()];
                    return ({
                      ...prev,
                      [item.searchVariable]: list,
                    });
                  });
                } else {
                  setSelectedOptions(prev => ({
                    ...prev,
                    [item.searchVariable]: [],
                  }));
                }
              }
            }}
            addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
            options={options?.[item.variable] || []}
            handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
            add={item?.request || false}
            loading={loading.includes(item?.searchVariable)}
            matchingOptions={selectedOptions?.[item.searchVariable] || []}
            tooltipVariables={item?.tooltipVariables || []}
            showTooltip={item?.tooltipVariables?.length}
          />
          {item?.confidential
            ?
            <FormControlLabel control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={item?.confidentialLabel || ""} />
            :
            null
          }
          <ActionBox hasWritePermission={hasWritePermission} item={item} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  } else if (item?.type === 'location') {
    return (
      <Grid item xs={12}>
        <Box className='form-maker-box'>
          <Grid container spacing={2}>
            {item?.address?.label
              ?
              <Grid item xs={12}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.address?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                <TextField
                  value={data?.[item?.address?.variable] || ""}
                  onChange={(e) => handleChangeLocationData(item?.address?.variable, e?.target?.value)}
                  placeholder={"Please enter " + (item?.address?.label || "").toLowerCase() + "!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  id={item.address.variable}
                  name={item.address.variable}
                />
              </Grid>
              :
              null
            }
            {item?.country?.label
              ?
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.country?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                <LocalSearchableDropdown
                  value={data?.[item?.country?.variable] || null}
                  label={item?.country?.label || ""}
                  options={countryOptions}
                  handleChange={(v) => handleChangeLocationData(item?.country?.variable, v?.value, 'country', item)}
                  id={item.country.variable}
                />
              </Grid>
              :
              null
            }
            {item?.state?.label && options?.[item?.state?.variable]?.length
              ?
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.state?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                <LocalSearchableDropdown
                  value={data?.[item?.state?.variable] || null}
                  label={item?.state?.label || ""}
                  options={options?.[item?.state?.variable] || []}
                  handleChange={(v) => handleChangeLocationData(item?.state?.variable, v?.value, 'state', item)}
                  id={item.state.variable}
                />
              </Grid>
              :
              null
            }
            {item?.city?.label && options?.[item?.city?.variable]?.length
              ?
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.city?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                <LocalSearchableDropdown
                  value={data?.[item?.city?.variable] || null}
                  label={item?.city?.label || ""}
                  options={options?.[item?.city?.variable] || []}
                  handleChange={(v) => handleChangeLocationData(item?.city?.variable, v?.value, 'city', item)}
                  id={item.city.variable}
                />
              </Grid>
              :
              null
            }
            {item?.zipCode?.label
              ?
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.zipCode?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                <TextField
                  value={data?.[item?.zipCode?.variable] || ""}
                  onChange={(e) => handleChangeLocationData(item?.zipCode?.variable, e?.target?.value, item)}
                  placeholder={"Please enter " + (item?.zipCode?.label || "").toLowerCase() + "!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  id={item.zipCode.variable}
                  name={item.zipCode.variable}
                />
              </Grid>
              :
              null
            }
          </Grid>
          <ActionBox hasWritePermission={hasWritePermission} item={item} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  } else if (item?.type === 'plan') {
    return (
      <Grid item xs={12}>
        <Box className='form-maker-box'>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
              <LocalSearchableDropdown
                id={item.variable}
                multiple={item?.multiple}
                label={item?.label}
                value={item?.multiple ? data?.[item.variable] || [] : data?.[item.variable] || null}
                handleChange={(v) => {
                  if (item?.multiple) {
                    let oldValues = data?.[item.variable] || [];
                    let intersection = [];
                    if (v.length > oldValues.length) {
                      intersection = v.filter(x => !oldValues.includes(x));
                      if (intersection.length) {
                        const option = getOptionFromList(options[item.variable], intersection[0]);
                        if (option?.value) {
                          setSelectedOptions(prev => {
                            let oldOptions = prev?.[item.searchVariable] || [];
                            let op = [...oldOptions, option];
                            let list = [...new Map(op.map(item => [item.value, item])).values()];
                            return ({
                              ...prev,
                              [item.searchVariable]: list,
                            });
                          });
                        }
                      }
                    } else {
                      intersection = oldValues.filter(x => !v.includes(x));
                      if (intersection.length) {
                        setSelectedOptions(prev => {
                          let oldOptions = prev?.[item.searchVariable] || [];
                          oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                          return ({
                            ...prev,
                            [item.searchVariable]: oldOptions,
                          });
                        });
                      }
                    }
                    handleChange(item.variable, v);
                  } else {
                    if (v?.value) {
                      setSelectedOptions(prev => {
                        let oldOptions = prev?.[item.searchVariable] || [];
                        let op = [...oldOptions, v];
                        let list = [...new Map(op.map(item => [item.value, item])).values()];
                        return ({
                          ...prev,
                          [item.searchVariable]: list,
                        });
                      });
                    } else {
                      setSelectedOptions(prev => ({
                        ...prev,
                        [item.searchVariable]: [],
                      }));
                    }
                    handleChange(item.variable, v?.value);
                  }
                }}
                addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
                options={options?.[item.variable] || []}
                handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
                add={item?.request || false}
                loading={loading.includes(item?.searchVariable)}
                matchingOptions={selectedOptions?.[item.searchVariable] || []}
              />
            </Grid>
            {
              data?.[item.variable]
                ?
                selectedOptions?.[item.searchVariable]?.map((selectedPlan, selectedPlanIndex) => {
                  return (
                    <Grid item xs={4} key={selectedPlanIndex}>
                      <Typography variant='subtitle2' sx={{ mb: 1 }}>{selectedPlan?.label || ""} Effective date {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          renderInput={(inputProps) => {
                            return (
                              <TextField
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                value={data?.[item.variable + "" + selectedPlan?.label] || ""}
                                variant="outlined"
                                sx={{ width: "100%", background: "#FFFFFF" }}
                                onChange={(e) => handleChange(item.variable + "" + selectedPlan?.label, e.target.value)}
                                id={item.variable + "" + selectedPlan?.label}
                                name={item.variable + "" + selectedPlan?.label}
                              />
                            )
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  )
                })
                :
                null
            }
          </Grid>
          <ActionBox hasWritePermission={hasWritePermission} item={item} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  } else if (item?.type === 'upload') {
    return (
      <Grid item xs={12}>
        <Box className='form-maker-box'>
          <Grid container spacing={2}>
            {item?.list?.map((option, optionIndex) => {
              return (
                <Grid item xs={10} key={optionIndex}>
                  <DividerComponent title={option?.title || ""} titleStyle={{ fontSize: 14, fontWeight: 500 }} outerStyle={{ marginTop: 0, }} />
                  <Box>
                    {option?.variants?.map((variant, varientIndex) => {
                      return (
                        <Box key={varientIndex}>
                          <Typography variant="subtitle2">{variant}</Typography>
                          <Typography variant="body1" color={blue[500]}>test_file.pdf</Typography>
                        </Box>
                      )
                    })}
                    {!item?.variants?.length ? <Typography variant="subtitle2">No files</Typography> : null}
                  </Box>
                  <Button variant='text' sx={{ width: 50, height: 50, bgcolor: blue[50], borderRadius: 50, minWidth: 'auto', mt: 2 }}>
                    <Add />
                  </Button>
                </Grid>
              )
            })}
          </Grid>
          <ActionBox hasWritePermission={hasWritePermission} item={{ ...item, variable: item?.list?.[0]?.title || "" }} handleEditFormContent={handleEditFormContent} handleRemoveFormContent={handleRemoveFormContent} />
        </Box>
      </Grid>
    )
  }
  return null;
}

const ActionBox = ({ hasWritePermission = false, item = null, handleEditFormContent = () => { }, handleRemoveFormContent = () => { } }) => {
  if (hasWritePermission) {
    return (
      <Box sx={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <IconButton id={item.variable + '-edit-btn'} color="primary" className='form-maker-remove-button' onClick={() => handleEditFormContent(item)}>
          <Edit />
        </IconButton>
        <IconButton id={item.variable + '-delete-btn'} color="error" className='form-maker-remove-button' onClick={() => handleRemoveFormContent(item)}>
          <Cancel />
        </IconButton>
      </Box>
    )
  }
  return null;
}

const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4, }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

export default InputDesigner;