import { useEffect, useState } from 'react';
import { Box, CircularProgress, Button, Typography, IconButton, Tooltip, TextField, Tabs, Tab } from '@mui/material';
import AppBar from '../../../components/AppBar';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { approveRequestedOption, disapproveRequestedOption, fetchAllRequestsOptions, requestOptionsPageLimit } from '../../../store/RequestOptions/actions';
import DisapproveModal from './DisapproveModal';
import ApproveModal from './ApproveModal';
import { getFieldName } from '../../../constants/config';
import { toast } from "react-toastify";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RequestOptionsExportModal from './RequestOptionsExportModal';
import _ from 'lodash';
import { getCurrentPageIdentifier } from '../../../utils/appHelper';

const RequestOptionsScreen = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: getCurrentPageIdentifier() });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  const requests = useSelector((state) => state.requestOptions.list);
  const requestsLoading = useSelector((state) => state.requestOptions.loading);
  const maxPage = useSelector((state) => state.requestOptions.maxPage);
  const currentPage = useSelector((state) => state.requestOptions.currentPage);

  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openDisApprove, setOpenDisApprove] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);

  useEffect(() => {
    const pathname = location?.pathname || "";
    const ids = pathname.split("/");
    const id = ids.length > 1 ? ids[ids.length - 1] : "";
    if (id) {
      setActiveTab(parseInt(id));
    }
  }, [location.pathname]);

  useEffect(() => {
    setSearchText("");
    dispatch(fetchAllRequestsOptions({ status: activeTab, page: 0, searchText: "" }));
  }, [activeTab]);

  const handleChangeTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handlePageChange = (e) => {
    dispatch(fetchAllRequestsOptions({ status: activeTab, page: e, searchText: searchText }));
  };

  const handleSearch = (searchValue) => {
    setSearchText(searchValue);
    dispatch(fetchAllRequestsOptions({ status: activeTab, page: currentPage, searchText: searchText }));
  }

  const handleShowModal = (data) => {
    setShowModal(true);
    setCurrentData(data);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentData(null);
  }

  const handleShowApproveModal = (data) => {
    setOpenApproveModal(true);
    setCurrentData(data);
  }

  const handleCloseApproveModal = () => {
    setOpenApproveModal(false);
  }

  const handleApprove = (data) => {
    setOpenApproveModal(false);
    dispatch(approveRequestedOption(data, (res) => {
      if (res.status) {
        toast.success('Approved request successfully');
        dispatch(fetchAllRequestsOptions({ status: activeTab, page: currentPage, searchText: searchText }));
      } else {
        toast.error('Failed to approve request!');
      }
    }));
  }

  const handleShowDisapproveModal = (data = null) => {
    setOpenDisApprove(true);
    setCurrentData(data);
  }

  const handleCloseDisapproveModal = () => {
    setOpenDisApprove(false);
  }

  const handleDisapprove = (data) => {
    setOpenDisApprove(false);
    setOpenApproveModal(false);
    dispatch(disapproveRequestedOption(data, (res) => {
      if (res.status) {
        toast.success('Disapproved request successfully');
        dispatch(fetchAllRequestsOptions({ status: activeTab, page: currentPage, searchText: searchText }));
      } else {
        toast.error('Failed to disapprove request!');
      }
    }));
  }

  let columns = [
    {
      field: "options",
      flex: 1,
      headerName: "Option",
      minWidth: 150,
    },
    {
      field: "field_type",
      flex: 1,
      headerName: "Category",
      minWidth: 150,
      renderCell: (params) => {
        let rows = params.row;
        if (rows?.field_type === 'client' && (rows?.is_client === null || rows?.is_client === 0)) {
          return <span>{getFieldName('endClients')}</span>
        }
        return <span>{rows.field_type ? getFieldName(rows.field_type) : ""}</span>
      }
    },
    {
      field: "requestor_name",
      flex: 1,
      headerName: "Requested by",
      minWidth: 200,
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Creation Date",
      minWidth: 200,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
  ];

  if (hasWritePermission) {
    columns = [...columns,
    {
      field: 'Actions',
      minWidth: 100,
      height: 150,
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Tooltip id={`approve-tooltip-${rows?.id}`} title='Approve'>
              <Button
                color='success'
                disabled={false}
                size='small'
                variant='contained'
                onClick={(event) => {
                  handleShowApproveModal(rows);
                }}
                sx={{ bgcolor: '#0FB333', width: 60, height: 35, '&:hover': { bgcolor: '#0FB333' }, }}
                id={`approve-btn-${rows?.id}`}
              >
                <CheckIcon fontSize='small' />
              </Button>
            </Tooltip>
            <Tooltip id={`disapprove-tooltip-${rows?.id}`} title='Disapprove'>
              <Button
                color='error'
                disabled={false}
                size='small'
                variant='contained'
                disableElevation
                onClick={(event) => {
                  handleShowDisapproveModal(rows);
                }}
                id={`disapprove-btn-${rows?.id}`}
                style={{ width: 60, height: 35 }}
              >
                <CloseIcon fontSize='small' />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
    ]
  } else {
    columns = [...columns,
    {
      field: 'Actions',
      minWidth: 100,
      height: 150,
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Tooltip id={`view-tooltip-${rows?.id}`} title='View'>
              <Button
                color='primary'
                disabled={false}
                size='small'
                variant='contained'
                onClick={(event) => {
                  handleShowModal(rows);
                }}
                id={`view-btn-${rows?.id}`}
                style={{ width: 60, height: 35 }}
              >
                <VisibilityIcon fontSize='small' />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
    ]
  }

  let approvalColumns = [
    {
      field: "options",
      flex: 1,
      headerName: "Option",
      minWidth: 150,
    },
    {
      field: "field_type",
      flex: 1,
      headerName: "Category",
      minWidth: 150,
      renderCell: (params) => {
        let rows = params.row;
        if (rows?.field_type === 'client' && (rows?.is_client === null || rows?.is_client === 0)) {
          return <span>{getFieldName('endClients')}</span>
        }
        return <span>{rows.field_type ? getFieldName(rows.field_type) : ""}</span>
      }
    },
    {
      field: "requestor_name",
      flex: 1,
      headerName: "Requested by",
      minWidth: 200,
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Requested Date",
      minWidth: 200,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      field: "approver_name",
      flex: 1,
      headerName: "Approved by",
      minWidth: 250,
    },
    {
      field: "updated_at",
      flex: 1,
      headerName: "Approval Date",
      minWidth: 250,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      field: 'Actions',
      minWidth: 100,
      height: 150,
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Tooltip id={`view-tooltip-${rows?.id}`} title='View'>
              <Button
                color='primary'
                disabled={false}
                size='small'
                variant='contained'
                onClick={(event) => {
                  handleShowModal(rows);
                }}
                id={`view-btn-${rows?.id}`}
                style={{ width: 60, height: 35 }}
              >
                <VisibilityIcon fontSize='small' />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  let declineColumns = [
    {
      field: "options",
      flex: 1,
      headerName: "Option",
      minWidth: 150,
    },
    {
      field: "field_type",
      flex: 1,
      headerName: "Category",
      minWidth: 150,
      renderCell: (params) => {
        let rows = params.row;
        if (rows?.field_type === 'client' && (rows?.is_client === null || rows?.is_client === 0)) {
          return <span>{getFieldName('endClients')}</span>
        }
        return <span>{rows.field_type ? getFieldName(rows.field_type) : ""}</span>
      }
    },
    {
      field: "requestor_name",
      flex: 1,
      headerName: "Requested by",
      minWidth: 200,
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Requested Date",
      minWidth: 200,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      field: "approver_name",
      flex: 1,
      headerName: "Disapproved by",
      minWidth: 250,
    },
    {
      field: "updated_at",
      flex: 1,
      headerName: "Disapproval Date",
      minWidth: 250,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      field: 'Actions',
      minWidth: 100,
      height: 150,
      flex: 1,
      headerName: "Actions",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Tooltip id={`view-tooltip-${rows?.id}`} title='View'>
              <Button
                color='primary'
                disabled={false}
                size='small'
                variant='contained'
                onClick={(event) => {
                  handleShowModal(rows);
                }}
                id={`view-btn-${rows?.id}`}
                style={{ width: 60, height: 35 }}
              >
                <VisibilityIcon fontSize='small' />
              </Button>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <AppBar appBarTitle="Requested Options" >
        {hasWritePermission ?
          <Button id='export-btn' onClick={() => setShowExportModal(true)} variant="contained" startIcon={<FileDownloadIcon />}>
            Export
          </Button>
          :
          null
        }
      </AppBar>
      <Box
        sx={{
          background: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 2,
          mt: 2,
          width: "100%",
          position: 'relative',
          pb: 2,
          height: 'calc(100svh - 120px)'
        }}
      >
        {requestsLoading ? <OverlayLoader /> : null}
        <Box sx={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', pl: 2, zIndex: 999 }}>
          <TabNav value={activeTab} handleChange={handleChangeTab} />
          <QuickSearchToolbar value={searchText} onChange={(e) => handleSearch(e.target.value)} clearSearch={() => handleSearch("")} />
        </Box>
        <TabPanel value={activeTab} index={0}>
          <RequestDataGrid data={requests} columns={columns} maxPage={maxPage} currentPage={currentPage} handlePageChange={handlePageChange} />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <RequestDataGrid data={requests} columns={declineColumns} maxPage={maxPage} currentPage={currentPage} handlePageChange={handlePageChange} />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <RequestDataGrid data={requests} columns={approvalColumns} maxPage={maxPage} currentPage={currentPage} handlePageChange={handlePageChange} />
        </TabPanel>
      </Box>
      {openApproveModal
        ?
        <ApproveModal open={openApproveModal} handleClose={handleCloseApproveModal} currentData={currentData} handleApprove={handleApprove} handleDisapprove={handleDisapprove} />
        :
        null
      }
      {openDisApprove
        ?
        <DisapproveModal open={openDisApprove} handleClose={handleCloseDisapproveModal} currentData={currentData} handleDisapprove={handleDisapprove} />
        :
        null
      }
      {showModal
        ?
        <ApproveModal open={showModal} handleClose={handleCloseModal} currentData={currentData} mode='view' />
        :
        null
      }
      {showExportModal ? <RequestOptionsExportModal open={showExportModal} handleModalClose={() => setShowExportModal(false)} /> : null}
    </Box>
  )
}

const QuickSearchToolbar = ({ value = "", onChange, clearSearch }) => {

  return (
    <Box display="flex" flexDirection='row' alignItems="center" justifyContent="space-between" pl={1} pr={3} m={2} >
      <TextField
        variant="standard"
        value={value}
        onChange={onChange}
        placeholder="Search"
        InputProps={{
          startAdornment: <SearchIcon color='primary' fontSize="medium" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: value ? 'visible' : 'hidden' }}
              onClick={clearSearch}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
        sx={{ px: 2, py: 1, width: 300 }}
      />
    </Box>
  );
}

const RequestDataGrid = ({ data, columns, maxPage, currentPage = 0, handlePageChange }) => {
  return (
    <Box sx={{ width: '100%', height: 'calc(100svh - 210px)' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pagination
        paginationMode='server'
        pageSize={requestOptionsPageLimit}
        rowCount={maxPage}
        page={currentPage}
        rowsPerPageOptions={[requestOptionsPageLimit]}
        onPageChange={handlePageChange}
        density="comfortable"
        disableSelectionOnClick
        disableRowSelectionOnClick
        sx={{
          px: 2,
          border: 0,
          height: "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#1089FF",
          },
          "& .MuiDataGrid-cell": {
            margin: 0,
            border: 0,
            width: 300,
          },
          "& .MuiDataGrid-row": {
            margin: 0,
          },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}

const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4 }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

const TabNav = ({ value, handleChange }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label='change-request-tabs'
    >
      <Tab
        label='Pending'
        {...a11yProps(0)}
      />
      <Tab
        label='Disapproved'
        {...a11yProps(1)}
      />
      <Tab
        label='Approved'
        {...a11yProps(2)}
      />
    </Tabs>
  )
};

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`change-request-tabpanel-${index}`}
      aria-labelledby={`change-request-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ position: 'relative' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `change-request-tab-${index}`,
    "aria-controls": `change-request-tabpanel-${index}`,
  };
}


export default RequestOptionsScreen;