import { useState } from "react";
import { Box, Button, Typography, TextField, Dialog, DialogContent, DialogActions } from '@mui/material';
import { grey } from '@mui/material/colors';
import { toast } from "react-toastify";

const DisapproveModal = ({ open = false, handleClose, handleDisapprove, currentData = null }) => {
  const [reason, setReason] = useState("");

  const handleSubmit=()=>{
    if(!reason){
      toast.error('Please enter a reason!');
    }else{
      handleDisapprove({id:currentData.id,comment:reason});
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='sm'
    >
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
            <Box sx={{width:60,height:60,borderRadius:'50%',bgcolor:'#fdeded',display:'flex',alignItems:'center',justifyContent:'center',mr:2}}>
              <Box sx={{ width: 35, height: 35, color: '#DB2F2F' }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-exclamation-triangle"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20z" /></svg>
              </Box>
            </Box>
            <Box sx={{mt:2}}>
              <Typography sx={{ fontSize: '25px', color:'#101828', fontWeight:'medium' }}>Disapprove Request ?</Typography>
              <Typography sx={{ fontSize: '16px', color: grey[600], fontWeight: 400 }}>Are you sure you want to disapprove this request ?</Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '86%',mt:2 }}>
            <Typography variant="subtitle2" sx={{color:'#101828'}}>Add comment</Typography>
            <TextField
              value={reason}
              onChange={e => setReason(e.target.value)}
              placeholder="Please add a reason of disapproval!"
              multiline={true}
              rows={4}
              sx={{ width: "100%", background: "#FFFFFF" }}
              autoFocus={true}
              inputProps={{ maxLength: 500, paddingBottom: "100px" }}
              id='disapprove-input'
            />
            {reason.length ? <Typography sx={{ margin: "0 0 0 auto",color:'#101828' }}>{reason.length}/{500}</Typography> : null}
            <Typography color='error' variant='subtitle2' mt={2}>This action cannot be undone.</Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ bgcolor: '#F3F3F3', p: 2 }}>
        <Button id='disapprove-modal-btn' variant="contained" color="error" disableElevation onClick={handleSubmit}>
          Disapprove
        </Button>
        <Button id='cancel-modal-btn' variant="contained" disableElevation sx={{ bgcolor: '#fff', color: '#101828', '&:hover': { bgcolor: '#fff' }, }} onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DisapproveModal;