const regex = {
  ////////////////////////////////////////////////////
  // Define all input validation regex here.
  ////////////////////////////////////////////////////
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  ssn: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{2}[-\s\.]?[0-9]{4,7}$/,
  phoneNumber: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/,
  federalId: "",
};

export default regex;
