import { Box, Button, Chip, CircularProgress, Grid, IconButton, TextField, Typography } from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { Fragment, useEffect, useState } from "react";
import { capitalizeString } from "../../../utils/ensureSelectOptions";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchApplicationLogs, fetchSingleApplicationLog } from "../../../store/ApplicationLogs/actions";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import ReactJson from 'react-json-view';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LoginIcon from '@mui/icons-material/Login';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import CallMergeIcon from '@mui/icons-material/CallMerge';

const ApplicationLogById = () => {
	const location = useLocation();
	const ids = (location?.pathname || "").split("/");
	const id = ids.length > 1 ? ids[ids.length - 1] : "";
	const dispatch = useDispatch();

	const { info = null, loading = false, } = useSelector(state => state.applicationLogs);
	const [initialLoading, setInitialLoading] = useState(true);

	useEffect(() => {
		if (id) {
			dispatch(fetchSingleApplicationLog({ id }, () => {
				setInitialLoading(false);
			}));
		}
	}, [id]);

	const handleCopy = (copy) => {
		navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
	}

	return (
		<Box id="applications-logs-screen" sx={{ ml: "7%", width: "92%", position: 'relative', py: 4, px: 2 }}>
			<Box sx={{ width: '100%', p: 2, bgcolor: '#fff', border: `1px solid ${grey[300]}`, borderRadius: 3 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
					<Box>
						<Typography variant="h6" sx={{ color: '#475569' }}>Application Log</Typography>
						<Typography variant="subtitle1" sx={{ fontSize: 14, color: grey[500] }}>ID: {id || ""}</Typography>
					</Box>
					{initialLoading || loading ?
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
							<CircularProgress size={20} color="primary" />
							<Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>Fetching log...</Typography>
						</Box>
						:
						null
					}
				</Box>
				{initialLoading ?
					null
					:
					<Fragment>
						<Box sx={{ width: '100%', borderTop: `1px solid ${grey[300]}`, p: 2, display: 'flex', flexDirection: 'row', gap: 2 }}>
							<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569', ml: 2 }}>Action</Typography>
							</Box>
							<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>Module</Typography>
							</Box>
							<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>Timestamp</Typography>
							</Box>
							<Box sx={{ width: '20%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>User</Typography>
							</Box>
							<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>Source ID</Typography>
							</Box>
							<Box sx={{ width: '40%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>Details</Typography>
							</Box>
						</Box>
						{info ?
							<Fragment>
								<LogData data={info} />
								<Box sx={{ width: '100%', borderTop: `1px solid ${grey[300]}`, pt: 2 }}>
									<Typography variant="subtitle2" sx={{ color: '#000', fontSize: 16 }}>Comments</Typography>
									<Typography variant="subtitle2" sx={{ color: '#000', wordBreak: "break-word" }}>{info?.comments || "No comment found"}</Typography>
									<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
										{info?.before_data ?
											<Box sx={{ width: '50%' }}>
												<Typography variant="subtitle2" sx={{ color: '#000', fontSize: 16, mb: 1 }}>Before</Typography>
												<ReactJson enableClipboard={handleCopy} name='data' src={info?.before_data} theme="monokai" style={{ padding: '20px', borderRadius: '10px' }} />
											</Box>
											:
											null
										}
										{info?.after_data ?
											<Box sx={{ width: '50%' }}>
												<Typography variant="subtitle2" sx={{ color: '#000', fontSize: 16, mb: 1 }}>After</Typography>
												<ReactJson enableClipboard={handleCopy} name='data' src={info?.after_data} theme="monokai" style={{ padding: '20px', borderRadius: '10px' }} />
											</Box>
											:
											null
										}
									</Box>
								</Box>
							</Fragment>
							:
							<Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', borderRadius: 2, p: 2, mt: 2 }}>
								<Box sx={{ width: 35, height: 35, color: '#475569' }}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
								</Box>
								<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
									{loading ? <CircularProgress size={20} color="primary" /> : null}
									<Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>No log found</Typography>
								</Box>
							</Box>
						}
					</Fragment>
				}
			</Box>
		</Box>
	)
}

const LogData = ({ data = null }) => {
	return (
		<Box sx={{ width: '100%', p: 2, borderTop: `1px solid ${grey[300]}`, display: 'flex', flexDirection: 'row', gap: 2 }}>
			<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
				<RenderAction data={data?.action_type} />
			</Box>
			<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data?.module_type || "")}</Typography>
			</Box>
			<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{data?.created_at ? moment(data.created_at).format('YYYY-MM-DD') : ''}</Typography>
				<Typography variant="subtitle2" sx={{ color: grey[700] }}>{data?.created_at ? moment(data.created_at).format('hh:mm a') : ''}</Typography>
			</Box>
			<Box sx={{ width: '20%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{data?.created_by_user_name || ''}</Typography>
				<Typography variant="subtitle2" sx={{ color: grey[700] }}>{data?.created_by_user_email || ''}</Typography>
			</Box>
			<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{data?.source_id || ""}</Typography>
			</Box>
			<Box sx={{ width: '40%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ cursor: 'pointer', color: '#000', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1 }} >{data?.comments || ""}</Typography>
			</Box>
		</Box>
	)
}

const RenderAction = ({ data = "" }) => {
	if (data.toLowerCase().includes('create')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<AddCircleIcon fontSize="medium" sx={{ color: blue[500], transform: 'translateX(5px)' }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('update')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<ChangeCircleIcon fontSize="medium" sx={{ color: orange[500], transform: 'translateX(5px)' }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('error')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<ErrorIcon fontSize="medium" sx={{ color: red[500], transform: 'translateX(5px)' }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('delete')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<DeleteForeverIcon fontSize="medium" sx={{ color: red[300] }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('login')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<LoginIcon fontSize="medium" sx={{ color: green[500] }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('upload')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<CloudCircleIcon fontSize="medium" sx={{ color: blue[300] }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('merge')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<CallMergeIcon fontSize="medium" sx={{ color: green[300] }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	}
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
			<Typography noWrap variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
		</Box>
	)
}

export default ApplicationLogById;