import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import { blue } from '@mui/material/colors';
import React from 'react'

function SearchableDropdown({ id = 'searchable-dropdown', loading = false, options = [], label = "", handleInputChange, handleChange, fields = [] }) {
  return (
    <Box>
      <Autocomplete
        disablePortal={false}
        id={id}
        options={options}
        onInputChange={handleInputChange}
        onChange={handleChange}
        loading={loading}
        fullWidth
        openOnFocus={true}
        blurOnSelect={true}
        clearOnBlur={true}
        sx={{ maxHeight: 100 }}
        renderInput={(params) => <TextField {...params} placeholder={label} />}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Box display={'flex'} flexDirection={'row'}>
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography color={'text.primary'} sx={{ fontSize: '14px', fontFamily: 'Poppins' }}>
                    {option.label}
                  </Typography>
                  {fields.map((field, fieldIndex) => {
                    return (
                      <Typography key={fieldIndex} color={'text.secondary'} sx={{ fontSize: '12px', fontFamily: 'Poppins' }}>
                        {option[field]}
                      </Typography>
                    )
                  })}
                </Box>
              </Box>
            </li>
          );
        }}
      />
    </Box>
  )
}

export default SearchableDropdown;