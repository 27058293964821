import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { COLORS, extractWaterfallDataPoints } from '../../utils/chartHelper';
import { Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import NoDataCard from './noDataCard';
import CustomizedAxisTick from './customAxisTick';
import CustomTooltip from './CustomTooltip';
import RenderCustomBarLabel from './customBarLabel';

const WaterfallCard = ({ data = [], visualizationData = null, title = "", showAnimation = true }) => {
  const [axes, setAxes] = useState([]);
  const [list, setList] = useState([]);
  const [chartTitle, setTitle] = useState("");
  const [size, setSize] = useState(null);

  useEffect(() => {
    const dataPoints = extractWaterfallDataPoints(data, visualizationData, title);
    setList(dataPoints?.list || []);
    setAxes(dataPoints?.axes || []);
    setTitle(dataPoints?.title || title);
    setSize(dataPoints?.size || null);
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  const gap = size?.width ? size.width / 2 : 10;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={list}
          margin={{
            top: 0,
            right: gap > 40 ? 40 : gap,
            bottom: 0,
            left: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis padding={{ left: gap > 40 ? 40 : gap, right: gap > 100 ? 40 : gap }} height={size?.height ? (size?.width > 100 ? 80 : size.height) : 25} dataKey={axes[0]?.dataKey || null} tick={<CustomizedAxisTick rotate={size?.width > 100} />} interval={0} />
          <YAxis allowDataOverflow={true} />
          <Tooltip content={<CustomTooltip hiddenList={[axes[axes.length - 1].dataKey + '2']} />} />
          <Legend align="left" verticalAlign='top' wrapperStyle={{ paddingBottom: gap / 2 > 20 ? 20 : gap / 2 }} />
          {
            axes.map((group, groupIndex) => {
              if (groupIndex > 0) {
                return (
                  <Fragment>
                    <Bar isAnimationActive={showAnimation} stackId={'waterfall'} unit={group?.unit || null} legendType='circle' dataKey={group.dataKey + '2'} fill='transparent' />
                    <Bar isAnimationActive={showAnimation} stackId={'waterfall'} unit={group?.unit || null} legendType='circle' dataKey={group.dataKey} fill={COLORS[groupIndex]}>
                      <LabelList dataKey={group.dataKey} content={<RenderCustomBarLabel unit={group?.unit || ''} />} />
                    </Bar>
                  </Fragment>
                )
              }
              return null;
            })
          }
        </BarChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default WaterfallCard;