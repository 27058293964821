import { Box, Button, FormControl, FormControlLabel, IconButton, MenuItem, Select, styled, Switch, Typography } from "@mui/material";
import { userRoleArray, userRoleLabels } from "../../../constants/userRoles";
import { extractValuableOptions } from "../../../utils/consultantConstants";
import CloseIcon from '@mui/icons-material/Close';
import { grey, orange } from "@mui/material/colors";
import { Fragment, useEffect, useRef, useState } from "react";
import { Info } from "@mui/icons-material";
import _ from "lodash";
import { useSelector } from "react-redux";
import { formatPermissionData, formatPermissionPayloadData, getIconFromIdentifier } from "../../../utils/userManagementHelper";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const PermissionsManagement = ({ showPermissionSidebar = false, currentData = null, onClosePermissions = () => { }, onSave = () => { } }) => {
  const permissionContainerRef = useRef();
  const { permissionList = [], loading = false } = useSelector(state => state.userMagement);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (currentData !== null) {
      const permissions = formatPermissionData(currentData?.permission_matrix);
      setData({
        ...currentData,
        permissions: permissions,
      });
      if (permissionContainerRef?.current) {
        permissionContainerRef?.current?.scroll(0, 0);
      }
    };
  }, [currentData]);

  const handleChange = (name, value) => {
    setData(prev => {
      return {
        ...prev,
        [name]: value
      }
    });
  }

  const handleChangePermission = (name = '', key = '', value = '', parent = '') => {
    let permissions = data?.permissions || {};
    let currentValue = permissions?.[name] || {};
    if (parent) {
      let item = _.find(permissionList, { identifier: parent }) || null;
      let parentValue = permissions?.[parent] || {};
      let finalValue = false;
      if (item?.children?.length) {
        item?.children?.forEach(child => {
          if (child?.identifier === name) {
            if (value === true) {
              finalValue = true;
            };
          } else {
            let childKeyValue = permissions?.[child?.identifier]?.[key] || false;
            if (childKeyValue === true) {
              finalValue = true;
            };
          }
        });
      };
      permissions = {
        ...permissions,
        [parent]: {
          ...parentValue,
          [key]: finalValue,
        }
      }
    } else {
      let item = _.find(permissionList, { identifier: name }) || null;
      if (item?.children?.length) {
        item?.children?.forEach(child => {
          let childValue = permissions?.[child?.identifier] || {};
          permissions = {
            ...permissions,
            [child.identifier]: {
              ...childValue,
              [key]: value,
            }
          }
        })
      }
    }
    permissions = {
      ...permissions,
      [name]: {
        ...currentValue,
        [key]: value,
      },
    };
    setData(prev => ({
      ...prev,
      permissions: permissions,
    }));
  };

  const handleSave = () => {
    let oldPermissions = formatPermissionData(currentData?.permission_matrix);
    let newPermissions = data?.permissions;
    let permission_matrix = formatPermissionPayloadData(newPermissions, permissionList);
    onSave({ id: currentData?.id, data: { update_permission: !_.isEqual(oldPermissions, newPermissions), role: data?.role, permission_matrix: permission_matrix } });
  };

  return (
    <Box sx={{ position: 'absolute', top: 0, right: showPermissionSidebar ? 0 : '-100%', width: '100%', maxWidth: 500, height: '100svh', bgcolor: '#fff', borderLeft: `1px solid ${grey[300]}`, transition: 'all 0.5s ease-in-out' }}>
      <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', pl: 3, pr: 2, py: 2, borderBottom: `1px solid ${grey[300]}` }}>
        <Typography variant="subtitle2" sx={{ fontSize: 16 }}>{data?.email ? `User Permissions` : `${userRoleLabels?.[data?.role] || ''} Role Permissions`}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClosePermissions}
          sx={{ color: '#000' }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box ref={permissionContainerRef} sx={{ px: 3, py: 2, height: 'calc(100% - 135px)', overflow: 'auto' }}>
        {data?.email ?
          <Fragment>
            <Typography variant="subtitle2" sx={{ fontSize: 16 }}>{data?.name}</Typography>
            <Typography variant="subtitle1" sx={{ fontSize: 12 }}>{data?.email}</Typography>
          </Fragment>
          :
          <Box sx={{ bgcolor: orange[50], display: 'flex', flexDirection: 'row', px: 1, py: 1, gap: 0.5, borderRadius: 1 }}>
            <Info sx={{ color: orange[800], fontSize: 20, mt: '2px' }} />
            <Typography variant="subtitle2" sx={{ fontSize: 14, color: orange[800], wordBreak: 'break-word' }}>This permissions will affect all the users belonging to "{userRoleLabels?.[data?.role] || ''}" role</Typography>
          </Box>
        }
        <Box sx={{ width: '100%', mt: 2 }}>
          {data?.email ?
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${grey[300]}` }}>
              <Box sx={{ width: 100, display: 'flex', flexDirection: 'row', alignItems: 'center', p: 2 }}>
                <Typography variant="subtitle2" sx={{ fontSize: 14 }}>User Role</Typography>
              </Box>
              <Box sx={{ height: 75, width: '1px', bgcolor: grey[300] }} />
              <Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', px: 2, py: 1 }}>
                <FormControl fullWidth>
                  <Select
                    sx={{ width: "100%", background: "#FFFFFF" }}
                    displayEmpty
                    value={data?.role || ""}
                    onChange={(e) => handleChange('role', e?.target?.value)}
                    id='user-role'
                  >
                    <MenuItem disabled value="">{"Please select a user role!"}</MenuItem>
                    {(extractValuableOptions((userRoleArray || []).map(item => ({ value: item?.id, label: item?.label })))).map((option, index) => {
                      return (
                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
            :
            null
          }
          {permissionList?.map(permissionItem => {
            return (
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', border: `1px solid ${grey[300]}`, borderTop: data?.email ? 'none' : `1px solid ${grey[300]}`, px: 2, pt: 2, pb: 1, gap: 2 }}>
                <Box sx={{ width: 40, height: 40, bgcolor: grey[100], borderRadius: 2, p: '5px' }}>
                  <img src={getIconFromIdentifier(permissionItem?.identifier)} style={{ width: '100%', height: '100%', objectFit: 'contain', filter: 'invert(48%) sepia(94%) saturate(746%) hue-rotate(187deg) brightness(80%) contrast(110%)' }} />
                </Box>
                <Box sx={{ width: 'calc(100% - 50px)' }}>
                  <Typography variant="subtitle2" sx={{ ml: '5px' }}>{permissionItem?.name || ""}</Typography>
                  <Box container sx={{ width: '100%', bgcolor: grey[100], py: 1, mt: 1, borderRadius: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 2 }}>
                    <Box sx={{ width: 80, px: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle1" sx={{ fontSize: 13, fontWeight: 500 }}>Read</Typography>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 0 }} checked={data?.permissions?.[permissionItem?.identifier]?.read || false} onChange={(e) => handleChangePermission(permissionItem?.identifier, 'read', e?.target?.checked)} />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        id={`${permissionItem.identifier}-read-permission-btn`}
                        sx={{ p: 0, m: 0 }}
                      />
                    </Box>
                    <Box sx={{ width: 80, px: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, borderLeft: `1px solid ${grey[300]}` }}>
                      <Typography variant="subtitle1" sx={{ fontSize: 13, fontWeight: 500 }}>Write</Typography>
                      <FormControlLabel
                        control={<IOSSwitch sx={{ m: 0 }} checked={data?.permissions?.[permissionItem?.identifier]?.write || false} onChange={(e) => handleChangePermission(permissionItem?.identifier, 'write', e?.target?.checked)} />}
                        inputProps={{ 'aria-label': 'controlled' }}
                        id={`${permissionItem.identifier}-write-permission-btn`}
                        sx={{ p: 0, m: 0 }}
                      />
                    </Box>
                  </Box>
                  {permissionItem?.children?.length ?
                    <Box container sx={{ width: '100%', bgcolor: grey[100], mt: 1, borderRadius: 1, display: 'flex', flexDirection: 'column', gap: 1 }}>
                      {permissionItem?.children?.map(child => {
                        return (
                          <Box sx={{ width: '100%', pl: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Typography variant="subtitle2" sx={{ width: 150 }}>{child?.name || ""}</Typography>
                            <Typography variant="subtitle2" sx={{ width: 10, textAlign: 'center' }}>:</Typography>
                            <Box container sx={{ width: '100%', bgcolor: grey[100], py: 1, borderRadius: 1, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: 2 }}>
                              <Box sx={{ width: 80, px: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                                <Typography variant="subtitle1" sx={{ fontSize: 13, fontWeight: 500 }}>Read</Typography>
                                <FormControlLabel
                                  control={<IOSSwitch sx={{ m: 0 }} checked={data?.permissions?.[child?.identifier]?.read || false} onChange={(e) => handleChangePermission(child?.identifier, 'read', e?.target?.checked, permissionItem?.identifier)} />}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  id={`${permissionItem.identifier}-read-permission-btn`}
                                  sx={{ p: 0, m: 0 }}
                                />
                              </Box>
                              <Box sx={{ width: 80, px: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, borderLeft: `1px solid ${grey[300]}` }}>
                                <Typography variant="subtitle1" sx={{ fontSize: 13, fontWeight: 500 }}>Write</Typography>
                                <FormControlLabel
                                  control={<IOSSwitch sx={{ m: 0 }} checked={data?.permissions?.[child?.identifier]?.write || false} onChange={(e) => handleChangePermission(child?.identifier, 'write', e?.target?.checked, permissionItem?.identifier)} />}
                                  inputProps={{ 'aria-label': 'controlled' }}
                                  id={`${permissionItem.identifier}-write-permission-btn`}
                                  sx={{ p: 0, m: 0 }}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )
                      })}
                    </Box>
                    :
                    null
                  }
                </Box>
              </Box>
            )
          })}
        </Box>
      </Box>
      <Box sx={{ height: '65px', bgcolor: '#f8f8f8', borderTop: `1px solid ${grey[300]}`, width: '100%', flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', px: 3 }}>
        <Button onClick={handleSave} variant="contained">
          Save Changes
        </Button>
      </Box>
    </Box>
  )
}

export default PermissionsManagement;