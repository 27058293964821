import { APPROVE_REQUEST_OPTION_FAILED, APPROVE_REQUEST_OPTION_IN_PROGRESS, APPROVE_REQUEST_OPTION_SUCESS, REQUEST_OPTIONS_LIST_FAILED, REQUEST_OPTIONS_LIST_IN_PROGRESS, REQUEST_OPTIONS_LIST_SUCESS } from "../../constants/store";
  
  export const initialState = {
    list: [],
    single: {},
    loading: false,
    error: false,
    maxPage:0,
    currentPage:0
  };
  
  function requestOptionsReducer(state = initialState, action) {
    switch (action.type) {
      case REQUEST_OPTIONS_LIST_IN_PROGRESS: {
        const newState = {
          ...state,
          loading: true,
        };
        return newState;
      }
      case REQUEST_OPTIONS_LIST_SUCESS: {
        const newState = {
            ...state,
            loading: false,
            list: action.payload.data,
            maxPage:action.payload.maxPage,
            currentPage:action.payload.currentPage
        };
        return newState;
      }
      case REQUEST_OPTIONS_LIST_FAILED: {
        const newState = {
          ...state,
          error: true,
          loading: false,
          list:action.payload.reset?[]:state.list
        };
        return newState;
      }
      // APPROVE REQUEST OPTION
      case APPROVE_REQUEST_OPTION_IN_PROGRESS: {
        const newState = {
          ...state,
          loading: true,
        };
        return newState;
      }
      case APPROVE_REQUEST_OPTION_SUCESS: {
        const newState = {
            ...state,
            loading: false,
        };
        return newState;
      }
      case APPROVE_REQUEST_OPTION_FAILED: {
        const newState = {
          ...state,
          loading: false,
        };
        return newState;
      }
      default:
        return state;
    }
  }
  
  export default requestOptionsReducer;
  