
import { useState } from "react";
import { Box, InputBase } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

function SearchBar({ id = 'app-bar-search', searchTerm, setSearchTerm, placeholder = "" }) {
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1, width: focused ? 240 : 40, height: focused ? 40 : 30, bgcolor: '#fff', borderRadius: 10, ml: 1, overflow: 'hidden', px: focused ? 1 : 0, transition: 'all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)' }}>
      <SearchIcon />
      <InputBase value={searchTerm} onKeyUp={(e) => setSearchTerm(e.target.value)} id={id} onFocus={onFocus} onBlur={onBlur} placeholder={focused ? placeholder : ''} sx={{ position: focused ? 'relative' : 'absolute', left: 0, height: 0, width: '100%', height: '100%', opacity: focused ? 1 : 0 }} inputProps={{ style: { cursor: focused ? 'text' : 'pointer' } }} />
    </Box>
  );
}

export default SearchBar;
