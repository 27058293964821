import { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { COLORS, extractPieDataPoints } from '../../utils/chartHelper';
import { Box, Typography } from '@mui/material';
import NoDataCard from './noDataCard';
import CustomTooltip from './CustomTooltip';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = outerRadius + 10;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#475569" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontWeight='bold' fontSize={14}>
      {`${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

const PieCard = ({ data = null, visualizationData = null, title = "", showAnimation = true }) => {
  const [list, setList] = useState([]);
  const [axes, setAxes] = useState([]);
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    const d = extractPieDataPoints(data, visualizationData, title);
    setList(d?.list || []);
    setAxes(d?.axes || []);
    setTitle(d?.title || "");
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  return (
    <Box sx={{ width: '100%', height: '100%', pb: 2 }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <ResponsiveContainer width="100%" height="95%">
        <PieChart>
          <Legend align="left" verticalAlign='top' wrapperStyle={{ paddingBottom: 20 }} />
          <Tooltip content={<CustomTooltip />} />
          <Pie
            data={list}
            cx="50%"
            cy="50%"
            dataKey={axes[axes.length - 1]?.dataKey || ""}
            paddingAngle={4}
            outerRadius={'95%'}
            innerRadius={'40%'}
            legendType='circle'
            startAngle={90}
            endAngle={-270}
            minAngle={1}
            label={renderCustomizedLabel}
            labelLine={false}
            isAnimationActive={showAnimation}
          >
            {list.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index + 1]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </Box>
  )
}

export default PieCard;