import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const GroupVisibilityToggleModal = ({ open = false, handleClose, handleVisibility, currentData = null }) => {

	const handleModalClose = () => {
		handleClose();
	}

	const handleGroupVisibility = () => {
		handleVisibility({ id: currentData.id, data: { name: currentData.name, is_active: !currentData.is_active } });
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			fullWidth
			maxWidth={"sm"}
		>
			<DialogContent>
				<Typography variant='h6'>Are you sure you want to change this group visibility?</Typography>
				{currentData?.is_active ?
					<Alert severity='error' sx={{mt:2}}>
						<AlertTitle>
							<Typography variant='subtitle2'>This remove all the users from this group and mark it in-active!</Typography>
						</AlertTitle>
					</Alert>
					:
					null
				}
			</DialogContent>
			<DialogActions>
				<Button id='form-cancel-btn' onClick={handleModalClose}>Cancel</Button>
				<Button id='form-add-btn' onClick={handleGroupVisibility} variant='contained'>{currentData?.is_active ? `Mark in-active` : `Mark active`}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default GroupVisibilityToggleModal;