import { CONTRACT_CHANGES_CHECK_FAILED, CONTRACT_CHANGES_CHECK_IN_PROGRESS, CONTRACT_CHANGES_CHECK_SUCCESS, GET_CANDIDATE_INFORMATION_FAILED, GET_CANDIDATE_INFORMATION_IN_PROGRESS, GET_CANDIDATE_INFORMATION_SUCCESS, GET_REVISION_HISTORY_FAILED, GET_REVISION_HISTORY_IN_PROGRESS, GET_REVISION_HISTORY_SUCCESS } from "../../constants/store";
import api from "../../config/https";
import _ from "lodash";
import { convertDataToContractInformationRequirement } from "../../utils/consultantConstants";

export const getRevisionHistory = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVISION_HISTORY_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/v2/contracts/revisions/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_REVISION_HISTORY_SUCCESS,
        payload: res?.data?.data || []
      });
    } else {
      dispatch({
        type: GET_REVISION_HISTORY_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_REVISION_HISTORY_FAILED,
    });
  }
};

export const fetchContractChange = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONTRACT_CHANGES_CHECK_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/v2/contracts/contract/${payload}`);
    if (res?.data?.status) {
      dispatch({
        type: CONTRACT_CHANGES_CHECK_SUCCESS,
        payload: (res?.data?.data || []).reverse(),
      });
    } else {
      dispatch({
        type: CONTRACT_CHANGES_CHECK_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONTRACT_CHANGES_CHECK_FAILED,
    });
  }
};

export const getCandidateInformation = (payload, callback) => async (dispatch) => {
  const config = payload?.config || [];
  try {
    dispatch({
      type: GET_CANDIDATE_INFORMATION_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/v2/contract/${payload.id}`);
    if (res && res.data && res.data.status) {
      const data = res?.data?.data || {};
      let cD = convertDataToContractInformationRequirement(data, {}, config);
      let { finalObj, options, allOptions } = cD;
      dispatch({
        type: GET_CANDIDATE_INFORMATION_SUCCESS,
        payload: {
          info: finalObj,
          options,
          allOptions,
        },
      });
    } else {
      dispatch({
        type: GET_CANDIDATE_INFORMATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_CANDIDATE_INFORMATION_FAILED,
    });
  }
};

export const getContractReferralHistory = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REVISION_HISTORY_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/referral/contract/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_REVISION_HISTORY_SUCCESS,
        payload: (res?.data?.data || []).reverse(),
      });
    } else {
      dispatch({
        type: GET_REVISION_HISTORY_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    console.log(e);
    dispatch({
      type: GET_REVISION_HISTORY_FAILED,
    });
  }
};