import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  DOWNLOAD_REPORT_IN_PROGRESS,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_FAILED,
  GET_DOWNLOAD_REPORT_HISTORY_IN_PROGRESS,
  GET_DOWNLOAD_REPORT_HISTORY_SUCCESS,
  GET_DOWNLOAD_REPORT_HISTORY_FAILED,
} from "../../../constants/store";
import api from "../../../config/https";

export const requestExportAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: DOWNLOAD_REPORT_IN_PROGRESS,
    });
    let res = await api.post(
      `/masterAdmin/downloadContractDataByColumn`, payload
    );
    if (res && res.data && res.data.status) {
      dispatch({
        type: DOWNLOAD_REPORT_SUCCESS,
      });
    } else {
      dispatch({
        type: DOWNLOAD_REPORT_FAILED,
      });
    }
    if (callback) {
      callback(res?.data);
    }
  } catch (e) {
    dispatch({
      type: DOWNLOAD_REPORT_FAILED,
    });
  }
};

export const getDownloadReportsActionsNew = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOWNLOAD_REPORT_HISTORY_IN_PROGRESS,
      payload: {
        loading: true
      }
    });
    dispatch({
      type: SET_LOADING_TRUE
    });
    let res = await api.get(`/masterAdmin/getDownloadHistory`)
    if (res && res?.data) {
      dispatch({
        type: GET_DOWNLOAD_REPORT_HISTORY_SUCCESS,
        payload: {
          loading: false,
          createSuccess: true,
          reports: res?.data?.data?.response,
        },
      });
      setTimeout(() => {
        dispatch({
          type: GET_DOWNLOAD_REPORT_HISTORY_SUCCESS,
          payload: {
            createSuccess: false,
            reports: res?.data?.data?.response,
          },
        });
        dispatch({
          type: SET_LOADING_FALSE,
        });
      }, 1500);
    } else {
      dispatch({
        type: GET_DOWNLOAD_REPORT_HISTORY_FAILED,
        payload: {
          error: true,
          loading: true,
        },
      });
      dispatch({
        type: SET_LOADING_FALSE,
      });
    }
  } catch (e) {

  }
}
