import { FETCH_APPLICATION_LOGS_FAILED, FETCH_APPLICATION_LOGS_IN_PROGRESS, FETCH_APPLICATION_LOGS_SUCESS, FETCH_SINGLE_APPLICATION_LOG_FAILED, FETCH_SINGLE_APPLICATION_LOG_IN_PROGRESS, FETCH_SINGLE_APPLICATION_LOGS_SUCESS } from "../../constants/store";
  
  export const initialState = {
    list: [],
    loading: false,
    error: false,
    maxPage:0,
    currentPage:0,
    info:null,
  };
  
  function applicationLogsReducer(state = initialState, action) {
    switch (action.type) {
      // fetch all
      case FETCH_APPLICATION_LOGS_IN_PROGRESS: {
        const newState = {
          ...state,
          loading: true,
        };
        return newState;
      }
      case FETCH_APPLICATION_LOGS_SUCESS: {
        const newState = {
            ...state,
            loading: false,
            list: action.payload.data,
            maxPage:action.payload.maxPage,
            currentPage:action.payload.currentPage
        };
        return newState;
      }
      case FETCH_APPLICATION_LOGS_FAILED: {
        const newState = {
          ...state,
          loading: false,
        };
        return newState;
      }
      // fetch single
      case FETCH_SINGLE_APPLICATION_LOG_IN_PROGRESS: {
        const newState = {
          ...state,
          loading: true,
        };
        return newState;
      }
      case FETCH_SINGLE_APPLICATION_LOGS_SUCESS: {
        const newState = {
            ...state,
            loading: false,
            info: action.payload.info,
        };
        return newState;
      }
      case FETCH_SINGLE_APPLICATION_LOG_FAILED: {
        const newState = {
          ...state,
          loading: false,
        };
        return newState;
      }
      default:
        return state;
    }
  }
  
  export default applicationLogsReducer;
  