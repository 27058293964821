import React, { useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextfieldComponent from "../TextfieldComponent";
import { FormHelperText } from "@mui/material";
import { convertToServerTime } from "../../helpers/timezone";

////////////////////////////////////////////////////
// This component will let user select dates
////////////////////////////////////////////////////

function DateSelection({
  setFields = () => { },
  fieldName,
  setPersonalInformation,
  name,
  fieldValue,
  validateForm,
  setFormError,
  required,
  minDate = "",
  maxDate = "",
  disabled,
  id = "",
  handleDateSelection = () => { },
}) {
  const [value, setValue] = useState({});
  const [validationError, setValidationError] = useState(false);
  useEffect(() => {
    setValue(convertToServerTime(fieldValue) || null);
  }, [fieldValue]);

  useEffect(() => {
    if (fieldName === "active_month_date") {
      setValue(new Date(
        new Date(value).getFullYear(),
        new Date(value).getMonth(),
        1
      ));
    }
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
    setFields((prevValue) => {
      if (prevValue) {
        prevValue[fieldName] =
          newValue !== "Invalid date"
            ? newValue !== ""
              ? newValue !== null
                ? newValue?.format("MM/DD/YYYY")
                : ""
              : ""
            : "";
        return {
          ...prevValue,
        };
      }
    });
    handleDateSelection(newValue?.format("MM/DD/YYYY"));
  };

  useEffect(() => {
    if (required && validateForm) {
      setValidationError(fieldValue && fieldValue !== "" ? false : true);
      setTimeout(() => {
        if (required && setFormError) {
          setFormError((prev) => {
            const findExistingField = prev?.findIndex(
              (index) => index.input === fieldName
            );
            if (findExistingField !== -1) {
              const update = prev?.map((index) => {
                if (index.input === fieldName) {
                  index.isError =
                    fieldValue && fieldValue !== "" ? false : true;
                }
                return index;
              });
              return update;
            } else {
              return [
                ...prev,
                {
                  input: fieldName,
                  isError: fieldValue && fieldValue !== "" ? false : true,
                },
              ];
            }
          });
        }
      }, 1000);
    }
  }, [fieldValue, validateForm]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        ////////////////////////////////////////////////////
        // Format should be in MM/DD/YYYY format across all
        // date selection
        ////////////////////////////////////////////////////
        inputFormat='MM/DD/YYYY'
        value={
          fieldName === "active_month_date"
            ? new Date(
              new Date(value).getFullYear(),
              new Date(value).getMonth(),
              1
            )
            : value
        }
        onChange={handleChange}
        name={fieldName}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        views={
          fieldName === "active_month_date"
            ? ["year", "month"]
            : ["year", "month", "day"]
        }
        renderInput={(params) => (
          <TextfieldComponent
            {...params}
            error={false}
            setFields={setPersonalInformation}
            name={name}
            fieldName={fieldName}
            id={id}
          />
        )}
      />
      {validationError && required && (
        <FormHelperText error>
          Please select {name.toLowerCase()}
        </FormHelperText>
      )}
    </LocalizationProvider>
  );
}

export default DateSelection;
