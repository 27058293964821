import { GET_PERMISSION_LIST_FAILED, GET_PERMISSION_LIST_IN_PROGRESS, GET_PERMISSION_LIST_SUCCESS, GET_PERMISSION_USER_LIST_FAILED, GET_PERMISSION_USER_LIST_IN_PROGRESS, GET_PERMISSION_USER_LIST_SUCCESS, PERMISSION_OPERATION_FAILED, PERMISSION_OPERATION_IN_PROGRESS, PERMISSION_OPERATION_SUCCESS, USER_PERMISSION_OPERATION_FAILED, USER_PERMISSION_OPERATION_IN_PROGRESS, USER_PERMISSION_OPERATION_SUCCESS } from "../../constants/store";

export const initialState = {
  loading: false,
  permissionList: [],
  loadingUsers: false,
  userList: [],
};

function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    // GET PERMISSION LIST
    case GET_PERMISSION_LIST_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_PERMISSION_LIST_SUCCESS: {
      const newState = {
        ...state,
        permissionList: action.payload,
        loading: false,
      };
      return newState;
    }
    case GET_PERMISSION_LIST_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // PERMISSION OPERATION
    case PERMISSION_OPERATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case PERMISSION_OPERATION_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case PERMISSION_OPERATION_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // GET PERMISSION USER LIST
    case GET_PERMISSION_USER_LIST_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingUsers: true,
      };
      return newState;
    }
    case GET_PERMISSION_USER_LIST_SUCCESS: {
      const newState = {
        ...state,
        userList: action.payload,
        loadingUsers: false,
      };
      return newState;
    }
    case GET_PERMISSION_USER_LIST_FAILED: {
      const newState = {
        ...state,
        loadingUsers: false,
      };
      return newState;
    }
    // USER PERMISSION OPERATION
    case USER_PERMISSION_OPERATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingUsers: true,
      };
      return newState;
    }
    case USER_PERMISSION_OPERATION_SUCCESS: {
      const newState = {
        ...state,
        loadingUsers: false,
      };
      return newState;
    }
    case USER_PERMISSION_OPERATION_FAILED: {
      const newState = {
        ...state,
        loadingUsers: false,
      };
      return newState;
    }
    default:
      return state;
  }
}

export default userManagementReducer;