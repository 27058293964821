import { GET_SOW_FAILED, GET_SOW_IN_PROGRESS, GET_SOW_SUCCESS, GET_SOW_OPTIONS_FAILED, GET_SOW_OPTIONS_IN_PROGRESS, GET_SOW_OPTIONS_SUCCESS, ADD_SOW_IN_PROGRESS, ADD_SOW_SUCCESS, ADD_SOW_FAILED, GET_SOW_REVISIONS_FAILED, GET_SOW_REVISIONS_SUCCESS, GET_SOW_REVISIONS_IN_PROGRESS } from "../../constants/store";
import api from "../../config/https";
import { convertSpecialListToOptions, encodeQueryData } from "../../utils/consultantConstants";

export const sowListLimit = 50;

export const getSOWDropDownOptions = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SOW_OPTIONS_IN_PROGRESS,
    });
    let res1 = await api.get('/masterAdmin/constant?fieldType=entity');
    let res2 = await api.get('/masterAdmin/clients');
    let res3 = await api.get('/masterAdmin/constant?fieldType=sow_duration');
    let res4 = await api.get('/masterAdmin/getSowRoles');
    if (res1?.data?.status && res2?.data?.status && res3?.data?.status && res4?.data?.status) {
      let data1 = convertSpecialListToOptions(res1?.data?.data?.data || [], 'entity', 'options');
      let data2 = convertSpecialListToOptions(res2?.data?.data?.data || [], 'clients', 'name');
      let data3 = convertSpecialListToOptions(res3?.data?.data?.data || [], 'duration', 'options');
      let data4 = convertSpecialListToOptions(res4?.data?.data?.data || [], 'roles', 'name');
      dispatch({
        type: GET_SOW_OPTIONS_SUCCESS,
        payload: {
          options: {
            ...data1,
            ...data2,
            ...data3,
            ...data4,
          }
        }
      });
      if (callback) {
        callback({
          options: {
            ...data1,
            ...data2,
            ...data3,
            ...data4,
          }
        });
      }
    }
  } catch (error) {
    dispatch({
      type: GET_SOW_OPTIONS_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const getSOWClientsAsPerEntity = async (payload) => {
  try {
    let res = await api.get(`/masterAdmin/getClientByEntityId/${payload.id}`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
}

export const getSOWs = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SOW_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/getSow` + encodeQueryData(payload));
    if (res?.data?.status) {
      const data = res?.data?.data;
      const list = data?.sows || [];
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: GET_SOW_SUCCESS,
        payload: {
          list: list,
          maxPage: data?.totalCount || list.length,
          currentPage: payload.page,
        }
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: GET_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const uploadSOW = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/addSow`, payload);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const addSOW = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/createSow`, payload);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const updateSOW = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/editSow`, payload);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const updateSOWStatus = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/updateSowStatus`, payload);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const deleteSOW = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.delete(`/masterAdmin/deleteSow/${payload?.id || ""}`);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const deleteSOWRevision = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.delete(`/masterAdmin/deleteSowRevision/${payload?.id || ""}`);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const addNewSOWClient = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.post("/masterAdmin/client", payload);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const addNewSOWRole = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.post("/masterAdmin/createSowRole", payload);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const addNewSOWDuration = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SOW_IN_PROGRESS,
    });
    let res = await api.post("/masterAdmin/constant", payload);
    if (res?.data?.status) {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_SUCCESS,
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: ADD_SOW_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: ADD_SOW_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};

export const addSOWDuration = async (payload) => {
  try {
    let res = await api.post("/masterAdmin/constant", payload);
    return res.data;
  } catch (e) {
    return false;
  }
};

export const getSOWRevisions = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SOW_REVISIONS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/getSowRevisionsById/${payload.id}`);
    if (res?.data?.status) {
      const data = res?.data?.data || [];
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: GET_SOW_REVISIONS_SUCCESS,
        payload: {
          list: data,
        }
      });
    } else {
      if (callback) {
        callback(res.data);
      }
      dispatch({
        type: GET_SOW_REVISIONS_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_SOW_REVISIONS_FAILED,
    });
    if (callback) {
      callback({ status: false });
    }
  }
};
