import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LineCard from './Charts/lineCard';
import PieCard from './Charts/pieCard';
import BarCard from './Charts/barCard';
import TableCard from './Charts/tableCard';
import PivotCard from './Charts/pivotCard';
import ComboCard from './Charts/comboCard';
import ScalarCard from './Charts/scalarCard';
import SmartScalarCard from './Charts/smartscalarCard';
import RowCard from './Charts/rowCard';
import AreaCard from './Charts/areaCard';
import ScatterCard from './Charts/scatterCard';
import GaugeCard from './Charts/gaugeCard';
import ProgressCard from './Charts/progressCard';
import MapCard from './Charts/mapCard';
import WaterfallCard from './Charts/waterfallCard';
import FunnelCard from './Charts/funnelCard';
import { Box, CircularProgress, Typography } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { getDashboardCardById } from '../store/Reports/action';
import _ from 'lodash';

const DynamicCard = ({ title = "", card_id = "", dashcard_id = "", dashboard_id = "", card = null, visualization_settings = null, parameters = [], activeFilters = null, filters = [], showAnimation = true }) => {
  const dispatch = useDispatch();
  const cards = useSelector((state) => state.reports.cards);
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [currentFilters, setCurrentFilters] = useState(null);

  useEffect(() => {
    let p = parameters?.length ? parameters.map(item => item.parameter_id) : [];
    let change = false;
    p.forEach(item => {
      if (!_.isEqual(currentFilters?.[item], activeFilters?.[item])) {
        change = true;
      }
    });
    if (cards[card_id]) {
      change = false;
      let cardParameters = cards[card_id]?.json_query?.parameters || [];
      p.forEach(item => {
        let cardItem;
        cardParameters.forEach(cItem => {
          if (cItem.id === item) {
            cardItem = cItem?.value;
            if (cItem.type === 'string/=') {
              cardItem = cItem?.value?.length ? cItem.value[0] : "";
            }
          }
        });
        if (!_.isEqual(cardItem, activeFilters?.[item])) {
          change = true;
        }
      });
    } else {
      change = true;
    }
    if (!change) {
      setLoading(false);
    } else {
      handleInit();
      setCurrentFilters(activeFilters);
    }
  }, [activeFilters]);

  const handleInit = async () => {
    setLoading(true);
    setIsError(false);
    let params = [];
    parameters?.forEach(p => {
      if (activeFilters?.[p.parameter_id]) {
        let foundType = filters.filter(item => item.id === p.parameter_id);
        let type = foundType?.length ? foundType[0]?.type : null
        params.push({
          value: activeFilters?.[p.parameter_id] || null,
          id: p?.parameter_id || "",
          target: p?.target || null,
          type: type,
        });
      }
    });
    dispatch(getDashboardCardById({ parameters: params, dashboard_id: dashboard_id, dashcard_id: dashcard_id, card_id: card_id }, (res) => {
      if (res?.status && res?.data?.status === 'completed') {
        setLoading(false);
      } else {
        setIsError(true);
        setLoading(false);
      }
    }));
  }

  const renderCard = (type = "") => {
    let data = cards[card_id] || null;
    switch (type) {
      case 'line':
        return <LineCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'pie':
        return <PieCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'bar':
        return <BarCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'table':
        return <TableCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'pivot':
        return <PivotCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'combo':
        return <ComboCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'scalar':
        return <ScalarCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'smartscalar':
        return <SmartScalarCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'row':
        return <RowCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'area':
        return <AreaCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'scatter':
        return <ScatterCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'gauge':
        return <GaugeCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'progress':
        return <ProgressCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'map':
        return <MapCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'waterfall':
        return <WaterfallCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      case 'funnel':
        return <FunnelCard data={data?.data} visualizationData={visualization_settings} title={title} showAnimation={showAnimation} />
      default:
        return null
    }
  }

  if (isError) {
    return (
      <Box sx={{ width: '100%', height: '100%', bgcolor: 'white', border: "1px solid rgba(0, 0, 0, 0.12)", display: 'flex', flexDirection: 'column', p: 2, overflow: 'hidden', borderRadius: 2 }}>
        <Box sx={{ width: '100%', height: '100%', bgcolor: grey[100], borderRadius: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Box sx={{ width: 30, height: 30, color: red[400] }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-exclamation-triangle"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20z" /></svg>
          </Box>
          <Typography sx={{ fontSize: '12px', mt: 2, color: red[400], textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>Error fetching card information</Typography>
        </Box>
      </Box>
    )
  }

  if (loading) {
    return (
      <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', bgcolor: 'white', border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 2 }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ width: '100%', height: '100%', bgcolor: 'white', border: "1px solid rgba(0, 0, 0, 0.12)", display: 'flex', flexDirection: 'column', p: 2, borderRadius: 2 }}>
      {renderCard(card?.display)}
    </Box>
  )
}

export default DynamicCard;