import { useState, useEffect } from "react";
import { Box, TextField, Typography } from "@mui/material";
import { enforceFormat } from "../helpers/inputFormat";
import regex from "../constants/regex";

function TextfieldComponent(props) {
  const {
    setFields,
    fieldName,
    required = false,
    validate = () => { },
    enableNumberFormatting = {},
    type,
    fieldValue,
    validateForm,
    setFormError,
    name,
    disabled,
    multiline = false,
    maxRows = 4,
    placeholder = ""
  } = props;

  const hasDiscount = name.toLowerCase().includes("discount");
  const prefillState = fieldValue
    ? type === "number"
      ? parseInt(fieldValue)
      : fieldValue
    : "";

  const [value, setValue] = useState(prefillState);
  const [mounted, setMounted] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [validationMessage, setValidationMessage] = useState(
    `Please enter ${name.toLowerCase()}`
  );

  const checkIfHasValue = (value) => {
    return !(value && value !== undefined && value !== null && value !== "");
  };

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
  }, []);

  useEffect(() => {
    if (prefillState && enableNumberFormatting.method) {
      const formattedValue = enableNumberFormatting.method(prefillState, {});
      setValue(formattedValue);
    } else {
      setValue(prefillState);
    }
  }, [fieldValue, enableNumberFormatting]);

  useEffect(() => {
    if (required && validateForm) {
      const hasNoValue = checkIfHasValue(fieldValue);
      setValidationError(hasNoValue);
      if (setFormError) {
        setFormError((prev) => {
          const existingFieldIndex = prev?.findIndex(
            (index) => index.input === fieldName
          );
          if (existingFieldIndex !== -1) {
            return prev.map((index) =>
              index.input === fieldName
                ? { ...index, isError: hasNoValue }
                : index
            );
          } else {
            return [
              ...prev,
              {
                input: fieldName,
                inputName: name,
                isError: hasNoValue,
              },
            ];
          }
        });
      }
    }
  }, [fieldValue, validateForm, value, required, setFormError, fieldName, name]);

  const handleTextfieldChange = (event) => {
    const { name, value } = event.target;
    if (enableNumberFormatting.enable) {
      const formattedValue = enableNumberFormatting.method(value, event);
      setValue(formattedValue);
    } else {
      setValue(value);
    }

    setFields((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleTextfieldBlur = () => {
    if (required) {
      const isValid = type !== "email"
        ? !checkIfHasValue(value)
        : !checkIfHasValue(value) && regex.email.test(String(value).toLowerCase());

      setValidationError(!isValid);
      validate(!isValid);

      if (type === "email") {
        setValidationMessage(`Please enter a valid ${name.toLowerCase()}`);
      }
    } else if (value && type === "email") {
      const isValidEmail = regex.email.test(String(value).toLowerCase());
      setValidationMessage(`Please enter a valid ${name.toLowerCase()}`);
      setValidationError(!isValidEmail);
      validate(!isValidEmail);
    } else {
      setValidationError(false);
      validate(false);
    }
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Typography variant="subtitle2" color="textPrimary">
        {name}
      </Typography>
      <TextField
        {...props}
        value={value}
        name={fieldName}
        placeholder={placeholder || `Please enter ${name.toLowerCase()}`}
        size="small"
        fullWidth
        variant="outlined"
        InputProps={{
          step: type === "number" ? 0.01 : undefined,
        }}
        onChange={(e) => {
          if (hasDiscount && type === "number" && isNaN(e.target.value)) {
            e.preventDefault();
            return;
          }
          handleTextfieldChange(e);
        }}
        onBlur={handleTextfieldBlur}
        onKeyDown={(e) =>
          (type === "number" || enableNumberFormatting.type === "number") &&
          enforceFormat(e)
        }
        rows={multiline ? maxRows : 1}
        multiline={multiline}
        error={validationError && required}
        helperText={validationError && required ? validationMessage : ""}
        disabled={disabled}
      />
    </Box>
  );
}

export default TextfieldComponent;