import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@mui/material';
import CsvDownload from "react-json-to-csv";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getlogs } from '../../../store/Settings/action';
import { OverlayLoader } from './SettingsScreen';
import { DataGrid } from "@mui/x-data-grid";
import moment from 'moment';
import SearchIcon from '@mui/icons-material/Search';

const ExportModal = ({ open, handleModalClose }) => {
	const [logData, setLogData] = useState([]);
	const [startDate, setStartDate] = useState("")
	const [endDate, setEndDate] = useState("");
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setStartDate("");
		setEndDate("");
		setLoading(false);
		setLogData([]);
		handleModalClose();
	}

	const getLogs = async () => {
		setLoading(true);
		let res = await getlogs(startDate, endDate);
		setLoading(false);
		setLogData(res.result);
	}

	let columns = [
		{
			field: "action_type",
			flex: 1,
			headerName: "Action Type",
			minWidth: 250,
		},
		{
			field: "module_type",
			flex: 1,
			headerName: "Module",
			minWidth: 250,
		},
		{
			field: "sub_module_type",
			flex: 1,
			headerName: "Sub-module",
			minWidth: 250,
		},
		{
			field: "source_name",
			flex: 1,
			headerName: "Data",
			minWidth: 250,
		},
		{
			field: "created_by_user_name",
			flex: 1,
			headerName: "Created By Name",
			minWidth: 250,
		},
		{
			field: "created_by_user_email",
			flex: 1,
			headerName: "Created By Email",
			minWidth: 250,
		},
		{
			field: "created_at",
			flex: 1,
			headerName: "Created At",
			minWidth: 200,
			valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
		},
	]

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={logData?.length ? "lg" : "sm"}
		>
			<DialogTitle>Export Settings Data</DialogTitle>
			<DialogContent>
				{loading ? <OverlayLoader /> : null}
				<Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" pt={2} gap={2}>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							renderInput={(inputProps) =>
								<TextField
									label="Start Date"
									InputLabelProps={{ shrink: true }}
									type="date"
									variant="outlined"
									onChange={async (e) => {
										setStartDate(e.target.value);
									}}
								/>}
						/>
						<DatePicker
							renderInput={(inputProps) =>
								<TextField
									label="End Date"
									InputLabelProps={{ shrink: true }}
									type="date"
									variant="outlined"
									onChange={async (e) => {
										setEndDate(e.target.value);
									}}
								/>}
						/>
					</LocalizationProvider>
					<IconButton size='large' sx={{ bgcolor: "#1089FF" }} onClick={getLogs}>
						<SearchIcon sx={{ color: '#fff' }} fontSize="medium" />
					</IconButton>
				</Box>
				{logData?.length
					?
					<DataGrid
						rows={logData}
						columns={columns}
						getRowId={(row) => row.created_at}
						density="comfortable"
						disableSelectionOnClick
						disableRowSelectionOnClick
						initialState={{
							sorting: {
								sortModel: [{ field: 'created_at', sort: 'desc' }],
							},
						}}
						sx={{
							px: 2,
							border: 0,
							height: "65vh",
							"& .MuiDataGrid-columnHeaderTitle": {
								color: "#1089FF",
							},
							"& .MuiDataGrid-cell": {
								margin: 0,
								border: 0,
								width: 300,
							},
							"& .MuiDataGrid-row": {
								margin: 0,
							},
							"& .MuiDataGrid-iconSeparator": {
								display: "none",
							},
						}}
					/>
					:
					null
				}
			</DialogContent>
			<DialogActions>
				<Button id='close-modal-btn' onClick={handleClose}>Close</Button>
				{logData?.length ?
					<CsvDownload
						id='export-btn'
						data={logData}
						filename="settings_log.csv"
						delimiter=","
						headers={['Action Type', 'Module', 'Sub Module', 'Created By Name', 'Created By Email', 'Data', 'Created At']}
						style={{
							border: " none",
							backgroundColor: "#1976D2",
							borderRadius: "6px",
							display: "inline-block",
							cursor: "pointer",
							color: "#ffffff",
							fontSize: "15px",
							fontWeight: "bold",
							padding: "10px 24px",
						}}
					>
						Export
					</CsvDownload>
					:
					null
				}
			</DialogActions>
		</Dialog>
	)
}

export default ExportModal;