import {
  ADD_CONTRACT_INFORMATION_FAILED,
  ADD_CONTRACT_INFORMATION_IN_PROGRESS,
  ADD_CONTRACT_INFORMATION_SUCCESS,
  CLEAR_CONTRACT_INFORMATION_FAILED,
  CLEAR_CONTRACT_INFORMATION_SUCCESS,
  GET_CONTRACT_INFORMATION_FAILED,
  GET_CONTRACT_INFORMATION_IN_PROGRESS,
  GET_CONTRACT_INFORMATION_SUCCESS,
  GET_DOCUMENTS_FOR_CONTRACT_FAILED,
  GET_DOCUMENTS_FOR_CONTRACT_IN_PROGRESS,
  GET_DOCUMENTS_FOR_CONTRACT_SUCCESS,
  GET_USER_CONFIGURATION_FAILED,
  GET_USER_CONFIGURATION_IN_PROGRESS,
  GET_USER_CONFIGURATION_SUCCESS,
} from "../../constants/store";

// The initial state
export const initialState = {
  loading: false,
  optionList: {},
  allOptions: {},
  info: {},
  oldInfo: {},
  documents: [],
  configurations: [],
};

function consultantFormReducer(state = initialState, action) {
  switch (action.type) {
    // GET CONFIGURATION
    case GET_USER_CONFIGURATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_USER_CONFIGURATION_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        configurations: action?.payload || [],
      };
      return newState;
    }
    case GET_USER_CONFIGURATION_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // GET CONTRACT INFORMATION
    case GET_CONTRACT_INFORMATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_CONTRACT_INFORMATION_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        info: action.payload.info,
        optionList: action.payload.options,
        allOptions: action.payload.allOptions,
      };
      return newState;
    }
    case GET_CONTRACT_INFORMATION_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // SAVE CONTRACT INFORMATION
    case ADD_CONTRACT_INFORMATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case ADD_CONTRACT_INFORMATION_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case ADD_CONTRACT_INFORMATION_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // GET DOCUMENTS
    case GET_DOCUMENTS_FOR_CONTRACT_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_DOCUMENTS_FOR_CONTRACT_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        documents: action?.payload?.documents || [],
      };
      return newState;
    }
    case GET_DOCUMENTS_FOR_CONTRACT_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // CLEAR CONTRACT INFORMATION
    case CLEAR_CONTRACT_INFORMATION_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        info: null,
        optionList: null,
        allOptions: null,
      };
      return newState;
    }
    case CLEAR_CONTRACT_INFORMATION_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    default:
      return state;
  }
}
export default consultantFormReducer;
