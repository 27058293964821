import { GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_FAILED, GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_IN_PROGRESS, GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_SUCCESS } from "../../constants/store";
import api from "../../config/https";
import { requestOptionsPageLimit } from "../RequestOptions/actions";

export const fetchAllPendingRequestOptionsNotifications = () => async (dispatch) => {
  try {
    const payload = {
      status:0,
      searchText:"",
      page:0,
    };
    dispatch({
      type: GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/settings/getAllData?status=${payload.status}&offset=${payload.page*requestOptionsPageLimit}&limit=${requestOptionsPageLimit}&search=${payload.searchText}`);
    if (res?.data?.status && res?.data?.data) {
      let data=res.data.data;
      let list=data?.data || [];
      dispatch({
        type: GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_SUCCESS,
        payload: {count:data?.totalCount || list.length},
      });
    }else{
      dispatch({
        type: GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_FAILED,
      });
    }
  } catch (e) {
    dispatch({
      type: GET_PENDING_REQUEST_OPTIONS_NOTIFICATIONS_FAILED,
    });
  }
};