import html2canvas from "html2canvas";

const exportAsImage = async (element, imageFileName, onlyImage = false) => {
  const canvas = await html2canvas(element, {
    logging: true,
    allowTaint: false,
    useCORS: true,
    width: element.scrollWidth,
    height: element.scrollHeight,
    scrollX: -window.scrollX,
    scrollY: -window.scrollY,
  });
  const image = canvas.toDataURL('image/jpeg', 1);
  if (!onlyImage) {
    downloadImage(image, imageFileName);
  } else {
    return image;
  }
}

const downloadImage = (blob, fileName) => {
  const fakeLink = window.document.createElement("a");
  fakeLink.style = "display:none;";
  fakeLink.download = fileName;

  fakeLink.href = blob;

  document.body.appendChild(fakeLink);
  fakeLink.click();
  document.body.removeChild(fakeLink);

  fakeLink.remove();
};

export default exportAsImage;