import "./App.css";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import Login from "./pages/Login/Login";
import SSOLogin from "./pages/Login/SSOLogin";
import ProtectedRoute from "./components/ProtectedRoutes";
import CandidatesScreenMaster from "./pages/MasterDataModule/Consultants/CandidatesScreenMaster";
import CandidateDetailsScreen from "./pages/MasterDataModule/Consultants/CandidateDetailsScreen";
import Contracts from "./pages/MasterDataModule/Contracts/Contracts";
import ChangeRequestScreen from "./pages/MasterDataModule/MasterApprovalsContainer/ChangeRequestScreen";
import MasterExportsScreen from "./pages/MasterDataModule/MasterExportsContainer/MasterExportsScreen";
import NotFound from "./pages/NotFound";
import SettingsScreen from "./pages/MasterDataModule/Settings/SettingsScreen";
import ContractForm from "./components/ContractForm";
import Recruitment from "./pages/MasterDataModule/Recruitment/Recruitment";
import PendingRecruitmentDraft from "./pages/MasterDataModule/PendingRecruitmentDrafts/PendingRecruitmentDrafts";
import ReleaseLogs from "./pages/MasterDataModule/ReleaseLogs/ReleaseLogs";
import ErrorBoundary from "./components/ErrorBoundary";
import SOWScreen from "./pages/MasterDataModule/SOW/SOWScreen";
import ReportScreen from "./pages/MasterDataModule/Report/ReportsScreen";
import RequestOptionsScreen from "./pages/MasterDataModule/RequestOptions/RequestOptionsScreen";
import ContractRevisionHistoryScreen from "./pages/MasterDataModule/ContractRevisionHistory/ContractRevisionHistoryScreen";
import ApplicationLogsScreen from "./pages/MasterDataModule/ApplicationLogs/ApplicationLogsScreen";
import ApplicationLogById from "./pages/MasterDataModule/ApplicationLogs/ApplicationLogsById";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { grey } from "@mui/material/colors";
import SideBar from "./components/SideBar";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RefferalForm from "./pages/MasterDataModule/Contracts/RefferalForm";
import ViewReferral from "./pages/MasterDataModule/Contracts/ViewReferral";
import UserManagement from "./pages/MasterDataModule/UserManagement/UserManagement";
import { useEffect } from "react";
import { getCurrentPageIdentifier } from "./utils/appHelper";
import ReviewChangeRequestScreen from "./pages/MasterDataModule/MasterApprovalsContainer/ReviewChangeRequestScreen";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";

function App() {
  const history = useHistory();
  const location = useLocation();
  const pathname = location?.pathname || "";
  let theme = createTheme({
    typography: {
      fontFamily: 'Roboto',
    },
  });

  theme = responsiveFontSizes(theme);

  const { currentUser = null } = useSelector(state => state.auth);

  useEffect(() => {
    getCurrentPageIdentifier();
    const permissions = currentUser?.permisson_matrix || [];
    const firstPermission = permissions?.[0] || null;
    if (firstPermission) {
      let firstRoute = _.find(routes, { permission: firstPermission?.identifier }) || null;
      if (firstRoute && (pathname === '' || pathname === '/')) {
        history.push(firstRoute.path);
      }
    }
  }, [pathname]);

  const consultantRoutes = [
    {
      permission: "consultants",
      path: "/consultants",
      component: <CandidatesScreenMaster />,
      exact: true,
    },
    {
      permission: "consultants",
      path: "/consultants/view-candidate/:id",
      component: <CandidateDetailsScreen />,
    },
    {
      permission: "consultants",
      path: "/consultants/candidateform",
      component: <ErrorBoundary><ContractForm /></ErrorBoundary>,
    },
    {
      permission: "consultants",
      path: "/consultants/contracts/:id",
      component: <Contracts />,
    },
    {
      permission: "consultants",
      path: "/consultants/revision/:id1/:id2",
      component: <ContractRevisionHistoryScreen />,
    },
    {
      permission: "consultants",
      path: "/consultants/referral",
      component: <ErrorBoundary><RefferalForm /></ErrorBoundary>,
    },
    {
      permission: "consultants",
      path: "/consultants/view-referral/:id",
      component: <ViewReferral />,
    },
  ];

  const recruitmentRoutes = [
    {
      permission: "recruitmentDrafts",
      path: "/recruitmentDrafts",
      component: <Recruitment />,
      exact: true,
    },
    {
      permission: "recruitmentDrafts",
      path: "/recruitmentDrafts/candidateform",
      component: <ErrorBoundary><ContractForm /></ErrorBoundary>,
    },
    {
      permission: "recruitmentDrafts",
      path: "/recruitmentDrafts/contracts/:id",
      component: <Contracts />,
    },
    {
      permission: "recruitmentDrafts",
      path: "/recruitmentDrafts/revision/:id1/:id2",
      component: <ContractRevisionHistoryScreen />,
    },
  ];

  const pendingRecruitmentRoutes = [
    {
      permission: "pendingRecruitmentDrafts",
      path: "/pendingRecruitmentDrafts",
      component: <PendingRecruitmentDraft />,
      exact: true,
    },
    {
      permission: "pendingRecruitmentDrafts",
      path: "/pendingRecruitmentDrafts/candidateform",
      component: <ErrorBoundary><ContractForm /></ErrorBoundary>,
    },
    {
      permission: "pendingRecruitmentDrafts",
      path: "/pendingRecruitmentDrafts/contracts/:id",
      component: <Contracts />,
    },
    {
      permission: "pendingRecruitmentDrafts",
      path: "/pendingRecruitmentDrafts/revision/:id1/:id2",
      component: <ContractRevisionHistoryScreen />,
    },
  ];

  const routes = [
    ...consultantRoutes,
    ...recruitmentRoutes,
    ...pendingRecruitmentRoutes,
    {
      permission: "approvals",
      path: "/approvals",
      component: <ChangeRequestScreen />,
      exact: true,
    },
    {
      permission: "approvals",
      path: "/approvals/change-request-review/:id1/:id2",
      component: <ReviewChangeRequestScreen />,
    },
    {
      permission: "requestOptions",
      path: "/requestOptions/:id",
      component: <RequestOptionsScreen />,
    },
    {
      permission: "sow",
      path: "/sow/:id",
      component: <SOWScreen />,
    },
    {
      permission: "reports",
      path: "/reports",
      component: <ReportScreen />,
    },
    {
      permission: "dropdownSettings",
      path: "/dropdownSettings",
      component: <SettingsScreen />,
    },
    {
      permission: "vendorSettings",
      path: "/vendorSettings",
      component: <SettingsScreen />,
    },
    {
      permission: "inputDesigner",
      path: "/inputDesigner",
      component: <SettingsScreen />,
    },
    {
      permission: "formDesigner",
      path: "/formDesigner",
      component: <SettingsScreen />,
    },
    {
      permission: "regionBuilder",
      path: "/regionBuilder",
      component: <SettingsScreen />,
    },
    {
      permission: "financeExport",
      path: "/financeExport",
      component: <MasterExportsScreen />,
    },
    {
      permission: "userManagement",
      path: "/userManagement",
      component: <UserManagement />,
    },
    {
      permission: "logs",
      path: "/logs",
      exact: true,
      component: <ApplicationLogsScreen />,
    },
    {
      permission: "logs",
      path: "/logs/:id",
      exact: true,
      component: <ApplicationLogById />,
    },
  ];

  // eslint-disable-next-line array-callback-return
  const renderedRoutes = routes.map((route, index) => {
    if (currentUser != null) {
      if (currentUser?.permisson_matrix?.some((item) => item.identifier === route.permission)) {
        return (
          <ProtectedRoute exact={index === 0 || route.exact} path={route.path} key={index}>
            {route.component}
          </ProtectedRoute>
        );
      }
    } else {
      return (
        <Redirect
          key={index}
          to={{
            pathname: "/login",
          }}
        />
      )
    }
  });

  return (
    <div style={{ width: '100%', height: '100svh', display: 'flex', flexDirection: 'row', overflow: 'hidden' }}>
      {pathname !== '/login' && pathname !== '/oldlogin' && !pathname.includes('/view-candidate') ?
        <SideBar />
        :
        null
      }
      <div style={{ width: pathname !== '/login' && pathname !== '/oldlogin' && !pathname.includes('/view-candidate') ? 'calc(100% - 100px)' : '100%', height: '100svh', overflowY: 'auto', bgcolor: grey[200] }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Route path="/login">
              <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                <SSOLogin />
              </GoogleOAuthProvider>
            </Route>
            <Route path="/oldlogin">
              <Login />
            </Route>
            <ProtectedRoute exact path="/releases">
              <ReleaseLogs />
            </ProtectedRoute>
            {renderedRoutes}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </ThemeProvider>
      </div>
      <ToastContainer />
    </div>
  );
}

export default withRouter(App);
