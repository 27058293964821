import { CONTRACT_CHANGES_CHECK_FAILED, CONTRACT_CHANGES_CHECK_IN_PROGRESS, CONTRACT_CHANGES_CHECK_SUCCESS, GET_CANDIDATE_INFORMATION_FAILED, GET_CANDIDATE_INFORMATION_IN_PROGRESS, GET_CANDIDATE_INFORMATION_SUCCESS, GET_REVISION_HISTORY_FAILED, GET_REVISION_HISTORY_IN_PROGRESS, GET_REVISION_HISTORY_SUCCESS } from "../../constants/store";

// The initial state
export const initialState = {
  loadingHistory: false,
  list: [],
  candidate: null,
  optionList:{},
  allOptions:{},
};

function contractReducer(state = initialState, action) {
  switch (action.type) {
    // GET REVISION HISTORY
    case GET_REVISION_HISTORY_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingHistory: true,
      };
      return newState;
    }
    case GET_REVISION_HISTORY_SUCCESS: {
      const newState = {
        ...state,
        loadingHistory: false,
        list: (action?.payload || []).reverse(),
      };
      return newState;
    }
    case GET_REVISION_HISTORY_FAILED: {
      const newState = {
        ...state,
        loadingHistory: false,
      };
      return newState;
    }
    // GET CANDIDATE INFORMATION
    case GET_CANDIDATE_INFORMATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingHistory: true,
      };
      return newState;
    }
    case GET_CANDIDATE_INFORMATION_SUCCESS: {
      const newState = {
        ...state,
        loadingHistory: false,
        candidate: action?.payload || null,
        candidate:action.payload.info,
        optionList:action.payload.options,
        allOptions:action.payload.allOptions,
      };
      return newState;
    }
    case GET_CANDIDATE_INFORMATION_FAILED: {
      const newState = {
        ...state,
        loadingHistory: false,
      };
      return newState;
    }
    // CONTRACT CHANGES CHECK
    case CONTRACT_CHANGES_CHECK_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingHistory: true,
      };
      return newState;
    }
    case CONTRACT_CHANGES_CHECK_SUCCESS: {
      const newState = {
        ...state,
        loadingHistory: false,
      };
      return newState;
    }
    case CONTRACT_CHANGES_CHECK_FAILED: {
      const newState = {
        ...state,
        loadingHistory: false,
      };
      return newState;
    }
    default:
      return state;
  }
}
export default contractReducer;
