import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

function AppBar({ children, appBarTitle = "" }) {
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, py: 2, px: 4, bgcolor: grey[200], borderRadius: 2, mb: 1, mx: 'auto' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
        <Typography sx={{ color: '#475569', fontSize: 20, fontWeight: 'bold' }}>{appBarTitle}</Typography>
      </Box>
      <Box style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: "flex-end", gap: "10px", flex: 1, }}>
        {children}
      </Box>
    </Box>
  );
}

export default AppBar;
