import api from "../../../config/https";
import {
  CR_NOTIFICATION_IN_PROGRESS,
  CR_NOTIFICATION_FAILED,
  CR_NOTIFICATION_SUCCESS,
} from "../../../constants/store";

export const fetchChangeRequestNotification = () => async (dispatch) => {
  try {
    dispatch({
      type: CR_NOTIFICATION_IN_PROGRESS,
      payload: [],
    });
    let res = await api.get(`/masterAdmin/contracts/changes?status=pending&pageNumber=0&pageSize=20&search=`);
    if (res?.data?.data?.totalCount) {
      dispatch({
        type: CR_NOTIFICATION_SUCCESS,
        payload: { count: res.data.data.totalCount },
      });
    }
  } catch (e) {
    dispatch({
      type: CR_NOTIFICATION_FAILED,
      payload: ["Error Occured"],
    });
  }
};
