import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";

const GroupCUModal = ({ open = false, handleClose, handleAdd, handleUpdate, currentData = null }) => {
	const [data, setData] = useState(null);

	const handleModalClose = () => {
		setData(null);
		handleClose();
	}

	const handleAddOption = () => {
		const check = checkValid();
		if (check) {
			handleAdd({ ...data });
			setData(null);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleUpdateOption = () => {
		const check = checkValid();
		if (check) {
			handleUpdate({ ...data, id: currentData?.id });
			setData(null);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const checkValid = () => {
		let valid = true;
		if (!data?.name) {
			valid = false;
		}
		return valid;
	}

	const textInputs = [{ label: 'Group Name', key: 'name' }];

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth="xs"
		>
			<DialogContent>
				<Typography variant='h6'>{currentData?.id ? "Update Group" : "Add New Group"}</Typography>
				<Box>
					{textInputs.map((item, index) => {
						return (
							<Box mt={1} key={index}>
								<Typography variant='subtitle2'>Enter {item?.label || ""} <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
								<TextField
									value={data?.[item.key] || ""}
									onChange={(e) => handleChange(item.key, e.target.value)}
									placeholder={"Please add a " + item.label + "!"}
									sx={{ width: "100%", background: "#FFFFFF", mt: 1 }}
									inputProps={{ paddingBottom: "100px" }}
								/>
							</Box>
						)
					})}
				</Box>
			</DialogContent>
			<DialogActions>
				<Button id='close-btn' onClick={handleModalClose}>Close</Button>
				<Button id='submit-btn' onClick={() => currentData?.id ? handleUpdateOption() : handleAddOption()} variant='contained'>{currentData?.id ? "Update" : "Add"}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default GroupCUModal;