import { Box, Typography } from '@mui/material';
import { extractBarDataPoints } from '../../utils/chartHelper';
import { useEffect, useState } from 'react';
import NoDataCard from './noDataCard';

const ScalarCard = ({ data = [], visualizationData = null, title }) => {
  const [list, setList] = useState([]);
  const [axes, setAxes] = useState([]);
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    const dataPoints = extractBarDataPoints(data, visualizationData, title);
    setList(dataPoints?.list || []);
    setAxes(dataPoints?.axes || []);
    setTitle(dataPoints?.title || "");
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  return (
    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      {axes.length && list.length && list.map((item, index) => {
        const name = axes[0]?.dataKey || "";
        return (
          <Typography key={index} sx={{ fontSize: '40px', fontWeight: 'bold', color: '#475569', textAlign: 'center', fontFamily: 'Poppins' }} className='my-2 text-4xl font-bold text-slate-600 text-center'>{(item?.[name] || 0).toLocaleString('en-US')}</Typography>
        )
      })}
      <Typography sx={{ fontSize: '14px', fontWeight: 'medium', color: '#475569', textAlign: 'center', fontFamily: 'Poppins' }} className='text-center text-slate-600 text-lg font-medium'>{chartTitle}</Typography>
    </Box>
  )
}

export default ScalarCard;