import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import { blue, grey } from '@mui/material/colors';
import DownloadIcon from '@mui/icons-material/Download';

const SOWRevisions = ({ open = false, handleClose, list = [] }) => {

  const columns = [
    {
      field: "project_title",
      flex: 1,
      headerName: "Project Title",
      minWidth: 150,
      renderCell: (params) => {
        const data = params.row;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography >{data?.project_title || ""}</Typography>
            {!data.parent_id ? <Typography variant='subtitle2' sx={{ color: grey[500], ml: 0.2 }}>(initial)</Typography> : null}
          </Box>
        )
      }
    },
    {
      field: "client_name",
      flex: 1,
      headerName: "Client",
      minWidth: 100,
    },
    {
      field: "entity_name",
      flex: 1,
      headerName: "Entity",
      minWidth: 100,
    },
    {
      field: "sow_start_date",
      flex: 1,
      headerName: "Start Date",
      minWidth: 150,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY") : "",
    },
    {
      field: "sow_end_date",
      flex: 1,
      headerName: "End Date",
      minWidth: 150,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY") : "",
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Created",
      minWidth: 200,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      minWidth: 50,
      height: 150,
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id='download' title='Download'>
                <IconButton id={`download-btn-${rows?.id}`} disabled={!rows?.url} aria-label='download' size='small' color='success' onClick={() => {
                  if (rows?.url) {
                    window.open(rows.url, "_blank", "noreferrer");
                  }
                }}>
                  <DownloadIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        )
      },
    }
  ];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-item-modal"
      aria-describedby="add-item-modal-description"
      fullWidth
      maxWidth={"lg"}
    >
      <DialogTitle>Revision History</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DataGrid
              rows={list}
              columns={columns}
              getRowId={(row) => row.created_at}
              density="comfortable"
              disableSelectionOnClick
              disableRowSelectionOnClick
              initialState={{
                sorting: {
                  sortModel: [{ field: 'created_at', sort: 'desc' }],
                },
              }}
              sx={{
                px: 2,
                border: 0,
                height: "65vh",
                "& .MuiDataGrid-columnHeaderTitle": {
                  color: "#1089FF",
                },
                "& .MuiDataGrid-cell": {
                  margin: 0,
                  border: 0,
                  width: 300,
                },
                "& .MuiDataGrid-row": {
                  margin: 0,
                },
                "& .MuiDataGrid-iconSeparator": {
                  display: "none",
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button id='close-btn' onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SOWRevisions;