const ensureCreatableOptionArray = (arr, useId = false) => {
  if (!arr || arr.length < 0) return [];
  const ensureOptiontData =
    arr &&
    arr.length > 0 &&
    arr.map((option) => {
      return {
        label: option?.name,
        // value: useId ? option.id : convertToLowercase.join("-").toLowerCase(),
        value: useId ? option?.id : option?.name,
      };
    });
  return ensureOptiontData;
};

export {
  ensureCreatableOptionArray,
};
