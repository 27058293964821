import api from "../../../config/https";
import axios from 'axios';
import {
  CHANGE_REQUEST_LIST_IN_PROGRESS,
  CHANGE_REQUEST_LIST_SUCESS,
  CHANGE_REQUEST_LIST_FAILED,
  CHANGE_REQUEST_INFO_IN_PROGRESS,
  CHANGE_REQUEST_INFO_SUCESS,
  CHANGE_REQUEST_INFO_FAILED,
  APPROVE_DISAPPROVE_REQUEST_PROGRESS,
  APPROVE_DISAPPROVE_REQUEST_SUCCESS,
  APPROVE_DISAPPROVE_REQUEST_FAILED,
  GET_CR_TAB_OPTIONS_SUCCESS,
  GET_CR_TAB_OPTIONS_IN_PROGRESS,
  GET_CR_TAB_OPTIONS_FAILED,
  GET_CR_SPECIAL_OPTIONS_FAILED,
  GET_CR_SPECIAL_OPTIONS_SUCCESS,
  GET_CR_SPECIAL_OPTIONS_IN_PROGRESS,
} from "../../../constants/store";
import { API_BASE_URL } from "../../../constants/constants";
import { convertSpecialListToOptions } from "../../../utils/consultantConstants";

export const requestApprovalPageLimit = 20;


export const getTabOptions = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CR_TAB_OPTIONS_IN_PROGRESS,
    });
    let res1 = await axios.get(`${API_BASE_URL}?tab_id=${0}`);
    let res2 = await axios.get(`${API_BASE_URL}?tab_id=${1}`);
    let res3 = await axios.get(`${API_BASE_URL}?tab_id=${2}`);
    let res4 = await axios.get(`${API_BASE_URL}?tab_id=${3}`);
    let data1 = res1?.data || {};
    let data2 = res2?.data || {};
    let data3 = res3?.data || {};
    let data4 = res4?.data || {};
    if (Object.keys(data1).length !== 0 && Object.keys(data2).length !== 0 && Object.keys(data3).length !== 0 && Object.keys(data4).length !== 0) {
      dispatch({
        type: GET_CR_TAB_OPTIONS_SUCCESS,
        payload: {
          options: {
            ...data1,
            ...data2,
            ...data3,
            ...data4
          }
        }
      });
      if (callback) {
        callback({
          options: {
            ...data1,
            ...data2,
            ...data3,
            ...data4,
          }
        });
      }
    } else {
      dispatch({
        type: GET_CR_TAB_OPTIONS_FAILED,
      });
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    dispatch({
      type: GET_CR_TAB_OPTIONS_FAILED,
    });
  }
};

export const getSpecialOptions = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CR_SPECIAL_OPTIONS_IN_PROGRESS,
    });
    let res1 = await api.get(`/masterAdmin/businessUnit`);
    let res2 = await api.get(`/masterAdmin/vendor`);
    let res3 = await api.get(`/masterAdmin/clients`);
    let res4 = await api.get(`/masterAdmin/domains`);
    let res5 = await api.get(`/masterAdmin/hr`);
    let res6 = await api.get(`/masterAdmin/endClients`);
    if (res1?.data?.status && res2?.data?.status && res3?.data?.status && res4?.data?.status && res5?.data?.status) {
      let data1 = convertSpecialListToOptions(res1?.data?.data?.data || [], 'businessUnit', 'bu_id');
      let data2 = convertSpecialListToOptions(res2?.data?.data?.data || [], 'vendor', 'vendor_name');
      let data3 = convertSpecialListToOptions(res3?.data?.data?.data || [], 'clients', 'name');
      let data4 = convertSpecialListToOptions(res4?.data?.data?.data || [], 'domains', 'name');
      let data5 = convertSpecialListToOptions(res5?.data?.data?.data || [], 'hr', 'name');
      let data6 = convertSpecialListToOptions(res6?.data?.data?.data || [], 'endclients', 'name');
      dispatch({
        type: GET_CR_SPECIAL_OPTIONS_SUCCESS,
        payload: {
          options: {
            ...data1,
            ...data2,
            ...data3,
            ...data4,
            ...data5,
            ...data6,
          }
        }
      });
      if (callback) {
        callback({
          options: {
            ...data1,
            ...data2,
            ...data3,
            ...data4,
            ...data5,
            ...data6,
          }
        });
      }
    } else {
      dispatch({
        type: GET_CR_SPECIAL_OPTIONS_FAILED,
      });
      if (callback) {
        callback();
      }
    }
  } catch (e) {
    dispatch({
      type: GET_CR_SPECIAL_OPTIONS_FAILED,
    });
  }
};

export const fetchAllChangeRequests = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_REQUEST_LIST_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/contracts/changes?status=${payload.status}&pageNumber=${payload.page}&pageSize=${requestApprovalPageLimit}&search=${payload.searchText}`);
    if (res?.data?.status && res?.data?.data) {
      let data = res.data.data;
      let list = data?.result || [];
      dispatch({
        type: CHANGE_REQUEST_LIST_SUCESS,
        payload: { data: list, maxPage: data?.totalCount || list.length, currentPage: payload.page },
      });
    } else {
      dispatch({
        type: CHANGE_REQUEST_LIST_FAILED,
        payload: { reset: payload.page === 0 },
      });
    }
  } catch (e) {
    dispatch({
      type: CHANGE_REQUEST_LIST_FAILED,
      payload: { reset: payload.page === 0 },
    });
  }
};

export const fetchChangeRequestInfo = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CHANGE_REQUEST_INFO_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/v2/contracts/contract/${payload}`);
    if (res?.data?.status) {
      dispatch({
        type: CHANGE_REQUEST_INFO_SUCESS,
        payload: (res?.data?.data || []).reverse(),
      });
    } else {
      dispatch({
        type: CHANGE_REQUEST_INFO_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CHANGE_REQUEST_INFO_FAILED,
    });
  }
};

export const changeRequestApproveDisapprove = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_DISAPPROVE_REQUEST_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/contract/status/${payload.id}`, {
      status: payload.status,
      comment: payload?.comment || "",
      reject_contract: payload?.reject_contract || null,
      approved_contract: payload?.approved_contract || null,
    });
    if (res?.data?.status) {
      dispatch({
        type: APPROVE_DISAPPROVE_REQUEST_SUCCESS,
      });
    } else {
      dispatch({
        type: APPROVE_DISAPPROVE_REQUEST_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: APPROVE_DISAPPROVE_REQUEST_FAILED,
    });
    console.log(e);
  }
};
