import { ArrowBack } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, Tab, Tabs, Typography } from "@mui/material";
import { blue, green, grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { fetchContractChange, getContractReferralHistory } from "../../../store/Contract/action";
import moment from "moment";
import { getUserConfiguration } from "../../../store/ConsultantForm/action";
import { TabPanel } from "../Settings/SettingsScreen";
import { convertDataToContractInformationRequirement, convertToRequiredTabFormat, statusColor } from "../../../utils/consultantConstants";
import _ from "lodash";
import Revision from "../ContractRevisionHistory/Revision";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EditIcon from '@mui/icons-material/Edit';
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

function ViewReferral() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);
  const identifier = getCurrentPageIdentifier();
  let hasPermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: identifier });
  if (foundItem) {
    hasPermission = (foundItem?.actions || []).includes('READ');
  }
  const { list = [], loadingHistory = false } = useSelector((state) => state.contract);
  const { configurations = [], loading = false, } = useSelector((state) => state.consultantForm);
  const [activeTab, setActiveTab] = useState(null);
  const [configuration, setConfiguration] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [data, setData] = useState(null);
  const [compareData, setCompareData] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [showOnlyChanges, setShowOnlyChanges] = useState(false);

  const ids = (location?.pathname || "").split("/");
  const id = ids.length > 1 ? ids[ids.length - 1] : "";

  useEffect(() => {
    if (id && hasPermission) {
      dispatch(getUserConfiguration({ id: currentUser?.id }, () => {
        dispatch(getContractReferralHistory({ id: id }));
      }));
    }
  }, [hasPermission]);

  useEffect(() => {
    if (list?.length) {
      handleChangeTab(0);
    }
  }, [list, id]);

  useEffect(() => {
    handleApplyRules();
  }, [data]);

  const handleApplyRules = () => {
    let tabList = JSON.parse(JSON.stringify(configuration));
    let conditions = [];
    tabList.forEach(tabItem => {
      (tabItem?.fields || []).forEach(field => {
        if (field?.rules?.length) {
          conditions.push(...field.rules);
        }
      });
    });
    conditions.forEach(conditionItem => {
      let conditionSatisfiedCount = 0;
      conditionItem?.conditions?.forEach(condition => {
        let conditionSatisfied = false;
        let key = condition?.key || "";
        let value = data?.[key] || "";
        if (condition?.type === 'equalTo') {
          conditionSatisfied = condition?.value == value;
        } else if (condition?.type === 'notEqualTo') {
          conditionSatisfied = condition?.value != value;
        } else if (condition?.type === 'empty') {
          conditionSatisfied = !value;
        } else if (condition?.type === 'notEmpty') {
          conditionSatisfied = value ? true : false;
        }
        if (conditionSatisfied) {
          conditionSatisfiedCount++;
        }
      });
      if (conditionSatisfiedCount === conditionItem?.conditions?.length) {
        tabList.forEach(tabItem => {
          (tabItem?.fields || []).forEach(item => {
            let variable = item?.variable || "";
            if (item?.type === 'location') {
              variable = item?.address?.variable || item?.country?.variable || item?.state?.variable || item?.city?.variable || item?.zipCode?.variable;
            }
            if ((conditionItem?.variable === variable || conditionItem?.variable === item.confidential) && !item.systemRequired) {
              if (item?.type === 'location') {
                let locationConfig = {};
                locationConfig.address = {
                  hidden: !conditionItem.visibility1,
                  required: conditionItem.requiredField1,
                };
                locationConfig.country = {
                  hidden: !conditionItem.visibility2,
                  required: conditionItem.requiredField2,
                };
                locationConfig.state = {
                  hidden: !conditionItem.visibility3,
                  required: conditionItem.requiredField3,
                };
                locationConfig.city = {
                  hidden: !conditionItem.visibility4,
                  required: conditionItem.requiredField4,
                };
                locationConfig.zipCode = {
                  hidden: !conditionItem.visibility5,
                  required: conditionItem.requiredField5,
                };
                item.locationConfig = locationConfig;
              } else {
                item.hidden = !conditionItem.visibility;
                item.required = conditionItem.requiredField;
              }
            }
          })
        })
      }
    });
    setTabs(tabList);
  };

  const handleGoBack = () => {
    history.goBack();
  }

  const handleChangeTab = (v) => {
    setActiveTab(v);
    let foundItem = _.find(configurations, { varient: 'Referral' });
    let tabList = convertToRequiredTabFormat(foundItem?.tabs || []);
    let cD = convertDataToContractInformationRequirement(list?.[v], {}, tabList);
    setTabs([]);
    setConfiguration([]);
    if (v < (list?.length - 1)) {
      let cD2 = convertDataToContractInformationRequirement(list?.[v + 1], {}, tabList);
      setCompareData(cD2);
      setTabs(tabList);
      setConfiguration(tabList);
      setData(cD.finalObj);
      setSelectedOptions(cD.allOptions);
    } else {
      setCompareData(cD);
      setTabs(tabList);
      setConfiguration(tabList);
      setData(cD.finalObj);
      setSelectedOptions(cD.allOptions);
      setShowOnlyChanges(false);
    }
  }

  const handleEdit = () => {
    history.push({
      pathname: `/${identifier}/referral`,
      state: {
        action: 'edit',
        id: list?.[0]?.id || "",
        contractId: id,
        status: "Referral",
      }
    });
  }

  return (
    <Box id="revision-history-screen" sx={{ width: '100%', height: '100svh', bgcolor: '#fff', position: 'relative', pt: 2, px: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 1, zIndex: 999 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
          <IconButton id='exit-btn' onClick={handleGoBack} sx={{ width: 35, height: 35, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
            <ArrowBack />
          </IconButton>
          <Typography variant='subtitle2' sx={{ color: blue[500], fontSize: 20 }}>Referral Revision History</Typography>
        </Box>
        <Box>
          <Button id='submit-btn' onClick={handleEdit} variant='contained' sx={{ textTransform: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <EditIcon fontSize="small" />
            <Typography variant='subtitle2'>Edit</Typography>
          </Button>
        </Box>
      </Box>
      <Box sx={{ bgcolor: grey[100], border: "1px solid rgba(0, 0, 0, 0.05)", borderRadius: 2, mt: 2, position: 'relative' }}>
        <Box sx={{ px: 2, pt: 1, }}>
          {activeTab !== null ? <TabNav id='revision-history' list={list} value={activeTab} handleChange={(e, v) => handleChangeTab(v)} /> : null}
        </Box>
        <Box id={'revision-history-view'} sx={{ width: "100%", height: 'calc(100svh - 180px)', overflowY: 'auto', px: 2, pt: 0.5, }}>
          {hasPermission ?
            list?.map((tab, tabIndex) => {
              return (
                <Box key={`tab-panel-${tabIndex}`} sx={{ marginTop: 2, width: '100%' }}>
                  <TabPanel id="revision-history" value={activeTab} index={tabIndex}>
                    {tabIndex + 1 === list?.length
                      ?
                      null
                      :
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                        {showOnlyChanges ?
                          <Box id='show-changes-btn' onClick={() => setShowOnlyChanges(false)} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 210, p: 1, bgcolor: green[50], borderRadius: 2 }}>
                            <Box sx={{ width: 30, height: 30, bgcolor: green[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
                              <VisibilityIcon fontSize="small" />
                            </Box>
                            <Typography variant='subtitle2' color={green[500]}>Showing only changes</Typography>
                          </Box>
                          :
                          <Box id='show-changes-btn' onClick={() => setShowOnlyChanges(true)} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 210, p: 1, bgcolor: grey[200], borderRadius: 2 }}>
                            <Box sx={{ width: 30, height: 30, bgcolor: grey[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
                              <VisibilityOffIcon fontSize="small" />
                            </Box>
                            <Typography variant='subtitle2' color={grey[500]}>Showing all details</Typography>
                          </Box>
                        }
                      </Box>
                    }
                    <Revision showOnlyChanges={showOnlyChanges} data={data} compareData={compareData} tabs={tabs} optionList={selectedOptions} />
                  </TabPanel>
                </Box>
              )
            })
            :
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ width: 35, height: 35, color: '#475569' }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
              </Box>
              <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>No permissions found</Typography>
            </Box>
          }
        </Box>
      </Box>
      {loading || loadingHistory ? <OverlayLoader /> : null}
    </Box>
  )
}

const TabNav = ({ id = "", list = [], value, handleChange }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label={id + '-tabs'}
      indicatorColor='primary'
      sx={{ pointerEvents: 'pointer' }}
      variant={"scrollable"}
      scrollButtons={"on"}
    >
      {list.map((l, index) => {
        return (
          <Tab
            key={`${id}-${index}`}
            label={
              <Box sx={{ textTransform: 'none', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontSize: 12 }}>Version {(list.length - (index))}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
                  <Typography variant="subtitle2">{moment(l.createdAt).format('MMM Do, YYYY')}</Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: 12 }}>{moment(l.createdAt).format('hh:mm a')}</Typography>
                </Box>
              </Box>
            }
            {...a11yProps(id, index)}
          />
        )
      })}
    </Tabs>
  )
};

const a11yProps = (id = "", index = 0) => {
  return {
    id: `${id}-tab-${index}`,
    "aria-controls": `${id}-tabpanel-${index}`,
  };
}

const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100svh', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4, }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

export default ViewReferral;