import React, { Fragment, useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { statusDropdown } from '../../../../constants/config';
import { blue, grey } from '@mui/material/colors';
import { RenderComponent } from './FormConfiguration';
import { useDispatch, useSelector } from 'react-redux';
import { convertToRequiredTabFormat } from '../../../../utils/consultantConstants';
import _ from 'lodash';
import { OverlayLoader, TabNav, TabPanel } from '../SettingsScreen';
import { getAllRegionConfigurations } from '../../../../store/Settings/action';
import countries from 'flag-icons/country.json';

const statusList = [
	...statusDropdown,
	{
		id: 6,
		value: "Referral",
		label: "Referral",
	},
];

const FormCopyModal = ({ open = false, handleClose = () => { }, handleCopy = () => { } }) => {
	const dispatch = useDispatch();
	const { allRegionFormConfigurations = [], loadingAllConfigurations = false } = useSelector((state) => state.settingsConstants);

	const [selectedVariant, setSelectedVariant] = useState(null);
	const [selectedRegion, setSelectedRegion] = useState(null);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [tabs, setTabs] = useState([]);
	const [fieldList, setFieldList] = useState([]);
	const [activeTab, setActiveTab] = useState(0);
	const [groups, setGroups] = useState([]);
	const [regions, setRegions] = useState([]);

	useEffect(() => {
		dispatch(getAllRegionConfigurations(res => {
			let list = res?.data || [];
			const regionData = _.groupBy(list, (item) => {
				return item?.region;
			});
			if (Object.keys(regionData)?.length) {
				setRegions(Object.keys(regionData));
				setSelectedRegion(Object.keys(regionData)[0]);
			}
		}));
	}, []);

	useEffect(() => {
		let list = (allRegionFormConfigurations || []).filter(item => item?.region === selectedRegion);
		const groupData = _.groupBy(list, (item) => {
			return item?.group_name;
		});
		if (Object.keys(groupData)?.length) {
			setGroups(Object.keys(groupData).map(item => ({ id: item, name: item })));
			setSelectedGroup(Object.keys(groupData)[0]);
			setSelectedVariant(statusList[0].value);
		}
	}, [selectedRegion]);

	useEffect(() => {
		if (selectedVariant && selectedGroup && allRegionFormConfigurations?.length) {
			let foundItem = _.find(allRegionFormConfigurations, { varient: selectedVariant, group_name: selectedGroup, region: selectedRegion });
			let t = convertToRequiredTabFormat(foundItem?.tabs || []);
			setTabs(t);
			setFieldList(t?.length ? (t[0]?.fields || []) : []);
		} else {
			setTabs([]);
			setFieldList([]);
		}
	}, [selectedGroup, selectedVariant]);

	const handleModalClose = () => {
		handleClose();
	}

	const handleApply = () => {
		handleCopy(tabs);
	}

	const handleChangeActiveTab = (value) => {
		if (value !== activeTab) {
			setActiveTab(value);
			let f = tabs[value].fields
			setFieldList(f);
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={"lg"}
		>
			<DialogContent>
				{loadingAllConfigurations ? <OverlayLoader /> : null}
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 220, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 2 }}>
					<Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<ContentCopyIcon sx={{ color: '#fff', fontSize: 20 }} />
					</Box>
					<Typography variant='subtitle2' color={blue[500]}>COPY CONFIGURATION</Typography>
				</Box>
				<Typography variant='h6'>Are you sure you want to copy this configuration?</Typography>
				<Typography variant='subtitle2'>Regions</Typography>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, flex: '0 0 1', pt: 1, pb: 2, overflowX: 'auto' }}>
					{regions?.map((region, regionIndex) => {
						let active = selectedRegion === region;
						let name = _.find(countries, { code: (region || "").toLowerCase() })?.name || region;
						return (
							<Box id={region} onClick={() => setSelectedRegion(region)} key={`region-${regionIndex}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${blue[500]}`, bgcolor: active ? blue[500] : '#fff', px: 2, py: '6px', borderRadius: '8px', cursor: active ? 'default' : 'pointer' }}>
								<Typography noWrap variant="subtitle2" sx={{ color: active ? '#fff' : '#475569' }}>{name || ""}</Typography>
							</Box>
						)
					})}
				</Box>
				<Typography variant='subtitle2'>Groups</Typography>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, flex: '0 0 1', pt: 1, pb: 2, overflowX: 'auto' }}>
					{groups?.map((group, groupIndex) => {
						let active = selectedGroup === group?.id;
						return (
							<Box id={group.name} onClick={() => setSelectedGroup(group?.id)} key={`group-${groupIndex}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${blue[500]}`, bgcolor: active ? blue[500] : '#fff', px: 2, py: '6px', borderRadius: '8px', cursor: active ? 'default' : 'pointer' }}>
								<Typography noWrap variant="subtitle2" sx={{ color: active ? '#fff' : '#475569' }}>{group?.name || ""}</Typography>
							</Box>
						)
					})}
				</Box>
				<Typography variant='subtitle2'>Variant</Typography>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, flex: '0 0 1', pt: 1, pb: 2, overflowX: 'auto' }}>
					{statusList?.map((status, statusIndex) => {
						let active = selectedVariant === status?.value;
						return (
							<Box id={status.label} onClick={() => setSelectedVariant(status.value)} key={`status-${statusIndex}`} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', border: `1px solid ${blue[500]}`, bgcolor: active ? blue[500] : '#fff', px: 2, py: '6px', borderRadius: '8px', cursor: active ? 'default' : 'pointer' }}>
								<Typography noWrap variant="subtitle2" sx={{ color: active ? '#fff' : '#475569' }}>{status.label}</Typography>
							</Box>
						)
					})}
				</Box>
				{tabs?.length ?
					<Fragment>
						<TabNav id="form-preview" list={tabs.map(item => item.name)} value={activeTab} handleChange={(e, v) => handleChangeActiveTab(v)} />
						{tabs.map((tab, tabIndex) => {
							return (
								<Box key={`tab-panel-${tabIndex}`}>
									<TabPanel id="form-preview" value={activeTab} index={tabIndex}>
										<Grid container spacing={2}>
											{fieldList.map((field, fieldIndex) => {
												return (
													<RenderComponent
														key={fieldIndex}
														item={field}
														index={fieldIndex}
													/>
												)
											})}
										</Grid>
									</TabPanel>
								</Box>
							)
						})}
					</Fragment>
					:
					<Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: grey[100], borderRadius: 2, p: 2 }}>
						<Box sx={{ width: 35, height: 35, color: '#475569' }}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
						</Box>
						<Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>No configuration found</Typography>
					</Box>
				}
			</DialogContent>
			<DialogActions>
				<Button id={'form-cancel-btn'} onClick={handleModalClose}>Cancel</Button>
				<Button id={'form-copy-btn'} onClick={handleApply} variant='contained'>Copy</Button>
			</DialogActions>
		</Dialog>
	)
}

export default FormCopyModal;