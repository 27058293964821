import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import AppBar from "../../../components/AppBar";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import TuneIcon from "@mui/icons-material/Tune";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDispatch, useSelector } from "react-redux";
import { fetchAllRecruitmentDrafts, getRecruitmentFilterOptions, getRecruitmentSpecilaFilterOptions } from "../../../store/Recruitment/action";
import SearchBar from "../../../components/SearchBar";
import LocalSearchableDropdown from "../../../components/LocalSearchableDropdown";
import _ from "lodash";
import { contractStatusOptions, renderRecruitmentDraftStatus } from "../../../utils/consultantConstants";
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

const initialFilter = {
  offset: 0,
  limit: 100,
  contractor: [],
  contract_status: [],
  contractor_status: [],
  entity: [],
  client: [],
  // sortKey:"",
  // sortValue:"asc"
}

const Recruitment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);
  const identifier = getCurrentPageIdentifier();
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: identifier });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  const recruitmentDrafts = useSelector((state) => state.recruitmentDraft.list);
  const loading = useSelector((state) => state.recruitmentDraft.loading);
  const maxPage = useSelector((state) => state.recruitmentDraft.maxPage);
  const currentPage = useSelector((state) => state.recruitmentDraft.currentPage);
  const options = useSelector((state) => state.recruitmentDraft.options);

  const [searchTerm, setSearchTerm] = useState("");
  const [modal, openModal] = useState(false);
  const [effectiveDate, setEffectiveDate] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [newFilter, setNewFilter] = useState(initialFilter);
  const [filter, setFilter] = useState(initialFilter);

  useEffect(() => {
    dispatch(fetchAllRecruitmentDrafts(initialFilter));
    dispatch(getRecruitmentFilterOptions(() => {
      dispatch(getRecruitmentSpecilaFilterOptions(() => { }))
    }));
  }, []);

  useEffect(() => {
    if (searchTerm !== "") {
      dispatch(fetchAllRecruitmentDrafts({
        ...filter,
        offset: 0,
        search: searchTerm,
      }));
    } else {
      dispatch(fetchAllRecruitmentDrafts({
        ...filter,
        offset: 0,
      }));
    }
  }, [searchTerm]);

  const handleShowFilter = () => {
    setNewFilter(filter);
    setShowFilter(true);
  }

  const handleFilter = (name, value) => {
    setNewFilter(prev => {
      return { ...prev, [name]: value || [] };
    });
  }

  const handleResetFilter = () => {
    setFilter(initialFilter);
    dispatch(fetchAllRecruitmentDrafts(initialFilter));
    setShowFilter(false);
  }

  const handleCloseFilter = () => {
    setNewFilter(filter);
    setShowFilter(false);
  }

  const handleApplyFilter = () => {
    setFilter(newFilter);
    dispatch(fetchAllRecruitmentDrafts(newFilter));
    setShowFilter(false);
  }

  const handlePageChange = (e) => {
    setFilter(prev => {
      return { ...prev, offset: e }
    });
    dispatch(fetchAllRecruitmentDrafts({
      ...filter,
      offset: e
    }));
  }

  const handleAddForm = () => {
    openModal(true);
  };

  const handleModalClose = () => {
    openModal(false);
  }

  const handleNext = () => {
    if (effectiveDate) {
      openModal(false);
      history.push({
        pathname: `/${identifier}/candidateform`,
        state: {
          action: "add",
          editType: "candidate",
          status: 'Recruitment Draft',
          effectiveDate: effectiveDate
        }
      });
    } else {
      toast.error('Please choose a effective date!');
    }
  }

  const handleView = (e) => {
    history.push(`/${identifier}/contracts/${e.id}`);
  }

  const columns = [
    {
      field: "contract_status",
      flex: 1,
      headerName: "Contract Status",
      minWidth: 150,
      renderCell: (params) => {
        const rows = params.row;
        return renderRecruitmentDraftStatus(rows?.recruitment_draft_status) || params.value;
      },
    },
    {
      field: "contractor_status",
      flex: 1,
      headerName: "Contractor Type",
      minWidth: 150,
    },
    { field: "entity", flex: 1, headerName: "Entity", minWidth: 120 },
    { field: "name", flex: 1, headerName: "Name", minWidth: 180 },
    { field: "contact_email", flex: 1.5, headerName: "Email", minWidth: 225 },
    {
      field: "contact_phone",
      flex: 1,
      headerName: "Phone number",
      minWidth: 150,
      renderCell: (params) => {
        return formatPhoneNumberIntl(params?.value || "") || params.value;
      },
    },
    {
      minWidth: 50,
      height: 150,
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id={`view-contract-tooltip-${rows?.id}`} title='View'>
                <IconButton aria-label='edit' size='small' color='primary' onClick={() => handleView(rows)} id={`view-contract-btn-${rows?.id}`}>
                  <VisibilityIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Box sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <AppBar appBarTitle='Recruitments' >
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchBar
              placeholder={"Search with Name, Email and PLC code"}
              onClick={() => { }}
              setSearchTerm={(value) => setSearchTerm(value)}
              value={searchTerm}
            />
            <Box onClick={handleShowFilter} sx={{ display: 'flex', alignItems: 'center', ml: 1, cursor: 'pointer' }}>
              <TuneIcon sx={{ color: "#000" }} />
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 500,
                  ml: 1,
                  color: "#000",
                }}
              >
                Filter
              </Typography>
            </Box>
          </Box>
          {hasWritePermission ?
            <Button variant="contained" onClick={handleAddForm}>Add new consultant</Button>
            :
            null
          }
        </Box>
      </AppBar>
      <Box
        id="recruitment-list-box"
        sx={{
          background: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 2,
          mt: 2,
          width: "100%",
          position: 'relative',
          pb: 2,
          height: 'calc(100svh - 120px)'
        }}>
        {recruitmentDrafts?.length ?
          <List data={recruitmentDrafts} columns={columns} maxPage={maxPage} currentPage={currentPage} handlePageChange={handlePageChange} />
          :
          <NoConsultants loading={loading} />
        }
      </Box>
      {showFilter ?
        <Dialog
          open={showFilter}
          onClose={handleCloseFilter}
          aria-labelledby="recruitment-modal-filter"
          aria-describedby="recruitment-modal-filter-description"
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Filter options</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Contract Status</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={newFilter?.contract_status || []}
                  options={contractStatusOptions}
                  handleChange={(e) => handleFilter('contract_status', e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Contractor Type</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={newFilter?.contractor_status || []}
                  options={options?.contractStatusOptions || []}
                  handleChange={(e) => handleFilter('contractor_status', e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Entity</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={newFilter?.entity || []}
                  options={options?.entityOptions || []}
                  handleChange={(e) => handleFilter('entity', e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Client</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={newFilter?.client || []}
                  options={options?.clients || []}
                  handleChange={(e) => handleFilter('client', e)}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Vendor</Typography>
                <LocalSearchableDropdown
                  multiple={true}
                  value={newFilter?.contractor || []}
                  options={options?.vendor || []}
                  handleChange={(e) => handleFilter('contractor', e)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {!_.isEqual(initialFilter, filter) ? <Button variant="outlined" onClick={handleResetFilter}>Reset</Button> : null}
            <Button variant="text" onClick={handleCloseFilter}>Close</Button>
            <Button variant="contained" onClick={handleApplyFilter}>Apply</Button>
          </DialogActions>
        </Dialog>
        :
        null}
      {modal ?
        <Dialog
          open={modal}
          onClose={handleModalClose}
          aria-labelledby="recruitment-modal"
          aria-describedby="recruitment-modal-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Effective Date <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                renderInput={(inputProps) =>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    type="date"
                    value={effectiveDate}
                    variant="outlined"
                    sx={{ width: "100%", background: "#FFFFFF" }}
                    onChange={(e) => setEffectiveDate(e.target.value)}
                  />}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleModalClose}>Close</Button>
            <Button onClick={handleNext} variant='contained'>Next</Button>
          </DialogActions>
        </Dialog>
        :
        null}
      {loading && recruitmentDrafts?.length ? <OverlayLoader /> : null}
    </Box>
  )
}

const NoConsultants = ({ loading = false }) => {
  return (
    <Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', borderRadius: 2, p: 2, mt: 2 }}>
      <Box sx={{ width: 35, height: 35, color: '#475569' }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        {loading ? <CircularProgress size={20} color="primary" /> : null}
        <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{loading ? `Fetching consultants...` : `No consultants found`}</Typography>
      </Box>
    </Box>
  )
}

const OverlayLoader = () => {
  return (
    <Box sx={{
      position: 'fixed',
      top: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 999,
      width: "100%",
      height: '100vh',
      backgroundColor: 'rgba(255,255,255,0.5)',
      borderRadius: 4
    }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

const List = ({ data, columns, maxPage, currentPage = 0, handlePageChange }) => {
  return (
    <DataGrid
      rows={data}
      columns={columns}
      pagination
      paginationMode='server'
      pageSize={100}
      rowCount={maxPage}
      page={currentPage}
      rowsPerPageOptions={[100]}
      onPageChange={handlePageChange}
      density="comfortable"
      disableSelectionOnClick
      disableRowSelectionOnClick
      sx={{
        px: 2,
        border: 0,
        height: "100%",
        "& .MuiDataGrid-columnHeaderTitle": {
          color: "#1089FF",
        },
        "& .MuiDataGrid-cell": {
          margin: 0,
          border: 0,
          width: 300,
        },
        "& .MuiDataGrid-row": {
          margin: 0,
        },
        "& .MuiDataGrid-iconSeparator": {
          display: "none",
        },
      }}
    />
  )
}


export default Recruitment;