import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, IconButton, TextField, Typography } from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import { Fragment, useEffect, useState } from "react";
import { capitalizeString } from "../../../utils/ensureSelectOptions";
import moment from "moment";
import SearchIcon from '@mui/icons-material/Search';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ErrorIcon from '@mui/icons-material/Error';
import LoginIcon from '@mui/icons-material/Login';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import CallMergeIcon from '@mui/icons-material/CallMerge';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CloseIcon from '@mui/icons-material/Close';
import ReactJson from 'react-json-view';
import FilterListIcon from '@mui/icons-material/FilterList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LocalSearchableDropdown from "../../../components/LocalSearchableDropdown";
import CancelIcon from '@mui/icons-material/Cancel';
import { useDispatch, useSelector } from "react-redux";
import { applicationLogsPageLimit, fetchApplicationLogs } from "../../../store/ApplicationLogs/actions";
import _ from "lodash";
import { useDebounce } from "../../../utils/useDebounce";
import CsvDownload from "react-json-to-csv";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

const modules = [
	{ label: 'Business unit', value: 'businessUnit' },
	{ label: 'Candidate', value: 'candidate' },
	{ label: 'Client', value: 'client' },
	{ label: 'Statement of work', value: 'sow' },
	{ label: 'Contract', value: 'contract' },
	{ label: 'Domain', value: 'domain' },
	{ label: 'Export', value: 'export' },
	{ label: 'HR', value: 'hr' },
	{ label: 'Logs', value: 'logs' },
	{ label: 'Referral', value: 'referral' },
	{ label: 'Setting', value: 'setting' },
	{ label: 'Configurator field', value: 'configurator_field' },
	{ label: 'User', value: 'user' },
	{ label: 'Section', value: 'section' },
	{ label: 'Vendor', value: "vendor" },
	{ label: 'Work auth history', value: 'workAuthHistory' },
];

const actionList = [
	{ label: 'Error', value: 'error' },
	{ label: 'Create', value: 'create' },
	{ label: 'Update', value: 'update' },
	{ label: 'Delete', value: 'delete' },
	{ label: 'Upload', value: 'upload' },
	{ label: 'Merge', value: 'merge' },
];

const now = moment(new Date(), "DD-MM-YYYY").format('YYYY-MM-DD');
const ago7Days = moment(new Date(), "DD-MM-YYYY").subtract(7, 'days').format('YYYY-MM-DD');

const initialFilters = { date_range_start: ago7Days, date_range_end: now };

const ApplicationLogsScreen = () => {
	const { currentUser = null } = useSelector(state => state.auth);
	const permisson_matrix = currentUser?.permisson_matrix || [];
	let hasWritePermission = false;
	let foundItem = _.find(permisson_matrix, { identifier: getCurrentPageIdentifier() });
	if (foundItem) {
		hasWritePermission = (foundItem?.actions || []).includes('WRITE');
	}
	const dispatch = useDispatch();

	const { list = [], loading = false, maxPage = 0, currentPage = 0 } = useSelector(state => state.applicationLogs);
	const [initialLoading, setInitialLoading] = useState(list?.length ? false : true);
	const [activeDetailIndex, setActiveDetailIndex] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [showFilter, setShowFilter] = useState(false);
	const [filters, setFilter] = useState(initialFilters);
	const [page, setPage] = useState(1);

	const debouncedSearchText = useDebounce(searchText, 300);

	useEffect(() => {
		dispatch(fetchApplicationLogs({ ...filters, search: searchText, pageNumber: 0, pageSize: applicationLogsPageLimit }, () => {
			setInitialLoading(false);
		}));
	}, [debouncedSearchText, filters]);

	useEffect(() => {
		setPage(currentPage + 1);
	}, [currentPage]);

	const handleActiveDetailsIndex = (val = null) => {
		if (activeDetailIndex === val) {
			setActiveDetailIndex(null);
		} else {
			setActiveDetailIndex(val);
		}
	}

	const handleChangeSearchText = (value = "") => {
		setSearchText(value);
	}

	const handleShowFilter = () => {
		setShowFilter(true);
	}

	const handleCloseFilter = () => {
		setShowFilter(false);
	}

	const handleResetFilter = () => {
		setShowFilter(false);
		setFilter(initialFilters);
	}

	const handleApplyFilter = (filterData) => {
		setFilter(filterData);
		setShowFilter(false);
	}

	const handlePageChange = (value) => {
		dispatch(fetchApplicationLogs({ ...filters, searchText: searchText, pageNumber: value, pageSize: applicationLogsPageLimit }));
	}

	const handleRemoveFilter = (type = "", val = "") => {
		setFilter(prev => ({
			...prev,
			[type]: prev[type].filter(item => item !== val),
		}));
	}

	const handleSetPageValue = (e) => {
		const re = /^[0-9\b]+$/;
		if ((e?.target.value === '' || re.test(e?.target?.value)) && e?.target?.value <= maxPage) {
			setPage(e?.target?.value);
		}
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && page && page > 0) {
			handlePageChange(page - 1);
		}
	}

	const convertList = () => {
		let newList = [];
		const nameConversionList = {
			action_type: 'Action',
			module_type: 'Module',
			created_at: 'Date',
			created_by_user_name: 'Name',
			created_by_user_email: 'Email',
			source_id: 'Source ID',
			comments: 'Comment',
			id: 'Logger ID',
		}
		list.forEach(listItem => {
			let item = {};
			let url = window.location.origin + '/application-logs/' + listItem.id;
			Object.keys(nameConversionList).forEach(key => {
				let keyName = nameConversionList[key];
				let value = key === 'created_at' ? moment(listItem[key]).format('YYYY-MM-DD, hh:mm a') : listItem[key];
				item[keyName] = value;
			});
			item['URL'] = url;
			newList.push(item);
		});
		return newList;
	}

	return (
		<Box id="applications-logs-screen" sx={{ width: "100%", position: 'relative', p: 2 }}>
			<Box sx={{ width: '100%', p: 2, bgcolor: '#fff', border: `1px solid ${grey[300]}`, borderRadius: 3 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', }}>
					<Box>
						<Typography variant="h6" sx={{ color: '#475569' }}>Application Log</Typography>
						<Typography variant="subtitle1" sx={{ fontSize: 14, color: grey[500] }}>Track user activity, including actions, timestamps and details.</Typography>
					</Box>
					{initialLoading || loading ?
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
							<CircularProgress size={20} color="primary" />
							<Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{initialLoading ? `Retrieving logs for the past seven days.` : `Fetching logs...`}</Typography>
						</Box>
						:
						(list?.length && hasWritePermission) ?
							<CsvDownload
								data={convertList()}
								filename={`Application-logs~${moment(new Date(filters?.date_range_start)).format('MMMM Do, YYYY')} - ${moment(new Date(filters?.date_range_end)).format('MMMM Do, YYYY')}.csv`}
								delimiter=","
								headers={['Action', 'Module', 'Date', 'Name', 'Email', 'Source ID', 'Comment', 'Logger ID', 'URL']}
								style={{
									border: 'none',
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									gap: 1, padding: '10px 12px',
									backgroundColor: blue[500],
									color: '#fff',
									cursor: 'pointer',
									borderRadius: 5
								}}>
								<FileDownloadIcon fontSize="small" />
								<Typography variant="subtitle2">Export CSV</Typography>
							</CsvDownload>
							:
							null
					}

				</Box>
				{initialLoading ?
					null
					:
					<Fragment>
						<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 1, borderTop: `1px solid ${grey[300]}`, }}>
							<SearchArea searchText={searchText} handleChange={handleChangeSearchText} />
							<FilterArea filters={filters} handleShowFilter={handleShowFilter} handleRemoveFilter={handleRemoveFilter} />
						</Box>
						<Box sx={{ width: '100%', borderTop: `1px solid ${grey[300]}`, p: 2, display: 'flex', flexDirection: 'row', gap: 2 }}>
							<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569', ml: 2 }}>Action</Typography>
							</Box>
							<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>Module</Typography>
							</Box>
							<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>Timestamp</Typography>
							</Box>
							<Box sx={{ width: '20%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>User</Typography>
							</Box>
							<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>Source ID</Typography>
							</Box>
							<Box sx={{ width: '40%', overflowX: 'auto', px: 1 }}>
								<Typography variant="subtitle2" sx={{ fontSize: 14, color: '#475569' }}>Details</Typography>
							</Box>
						</Box>
						<Box sx={{ width: '100%', maxHeight: 'calc(100svh - 310px)', overflowY: 'auto', borderTop: `1px solid ${grey[300]}`, borderBottom: `1px solid ${grey[300]}`, }}>
							{list?.length
								?
								list?.map((log, logIndex) => {
									return (
										<LogData key={logIndex} data={log} index={logIndex} activeDetailIndex={activeDetailIndex} handleActiveDetailsIndex={handleActiveDetailsIndex} />
									)
								})
								:
								<Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', borderRadius: 2, p: 2, mt: 2 }}>
									<Box sx={{ width: 35, height: 35, color: '#475569' }}>
										<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
									</Box>
									<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
										{loading ? <CircularProgress size={20} color="primary" /> : null}
										<Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>No logs found</Typography>
									</Box>
								</Box>
							}
						</Box>
						{maxPage > 0 ?
							<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', px: 2, pt: 2 }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
									<Typography variant="subtitle2" sx={{ color: '#475569' }}>Page</Typography>
									<input id='page-input' disabled={loading} type="text" pattern="\d{1,5}" value={page} onChange={handleSetPageValue} onKeyDown={handleKeyPress} style={{ width: 40, height: 30, textAlign: 'center', border: `1px solid ${grey[300]}`, borderRadius: 4, color: '#475569', fontWeight: 600, fontSize: 14 }} placeholder="0" />
									<Typography variant="subtitle2" sx={{ color: '#475569' }}>of {maxPage}</Typography>
								</Box>
								<IconButton id="previous-btn" disabled={currentPage === 0 || loading} onClick={() => handlePageChange(currentPage - 1)}>
									<KeyboardArrowLeftIcon />
								</IconButton>
								<IconButton id="next-btn" disabled={(currentPage + 1) === maxPage || loading} onClick={() => handlePageChange(currentPage + 1)}>
									<KeyboardArrowRightIcon />
								</IconButton>
							</Box>
							:
							null
						}
					</Fragment>
				}
			</Box>
			{showFilter ? <FilterModal filters={filters} onClose={handleCloseFilter} handleResetFilter={handleResetFilter} handleApplyFilter={handleApplyFilter} /> : null}
		</Box>
	)
}

const SearchArea = ({ searchText = "", handleChange = () => { } }) => {
	return (
		<Box sx={{ width: '100%', p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, color: grey[400] }}>
			<SearchIcon sx={{ color: '#475569' }} />
			<TextField value={searchText} onChange={(e) => handleChange(e?.target?.value)} placeholder="Search activities..." variant="standard" size="small" sx={{ width: '100%', transform: 'translateY(1.2px)' }} InputProps={{ disableUnderline: true, style: { color: '#475569' } }} />
		</Box>
	)
}

const FilterArea = ({ filters = null, handleShowFilter = () => { }, handleRemoveFilter = () => { } }) => {
	return (
		<Box sx={{ p: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
			<IconButton id='filter-btn' onClick={handleShowFilter}>
				<FilterListIcon fontSize='small' />
			</IconButton>
			{(filters?.module_type || []).length ?
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, border: `1.5px solid ${blue[500]}`, borderRadius: 10, px: 0.5, py: 0.5, pl: 1.5 }}>
					<Typography id='modules-btn' onClick={handleShowFilter} noWrap variant="subtitle2" sx={{ cursor: 'pointer', fontSize: 12 }}>Modules</Typography>
					{filters?.module_type?.map((module, moduleIndex) => {
						const convertedData = _.find(modules, { value: module });
						return (
							<Button key={moduleIndex} id={`module-btn-${moduleIndex}`} onClick={() => handleRemoveFilter('module_type', module)} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5, bgcolor: blue[500], color: '#fff', px: 1, py: 0.5, borderRadius: 10, textTransform: 'none', '&:hover': { bgcolor: blue[500] } }}>
								<Typography noWrap variant="subtitle2" sx={{ fontSize: 12, transform: 'translateY(1px)' }}>{convertedData?.label || ''}</Typography>
								<CancelIcon fontSize="small" />
							</Button>
						)
					})}
				</Box>
				:
				null
			}
			{(filters?.action_type || []).length ?
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, border: `1.5px solid ${blue[500]}`, borderRadius: 10, px: 0.5, py: 0.5, pl: 1.5 }}>
					<Typography id="action-btn" onClick={handleShowFilter} noWrap variant="subtitle2" sx={{ cursor: 'pointer', fontSize: 12 }}>Actions</Typography>
					{filters?.action_type?.map((action, actionIndex) => {
						const convertedData = _.find(actionList, { value: action });
						return (
							<Button id={`action-btn-${actionIndex}`} key={actionIndex} onClick={() => handleRemoveFilter('action_type', action)} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5, bgcolor: blue[500], color: '#fff', px: 1, py: 0.5, borderRadius: 10, textTransform: 'none', '&:hover': { bgcolor: blue[500] } }}>
								<Typography noWrap variant="subtitle2" sx={{ fontSize: 12, transform: 'translateY(1px)' }}>{convertedData?.label || ''}</Typography>
								<CancelIcon fontSize="small" />
							</Button>
						)
					})}
				</Box>
				:
				null
			}
			{filters?.date_range_start && filters?.date_range_end ?
				<Button id="date-btn" onClick={handleShowFilter} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, bgcolor: blue[500], color: '#fff', px: 1.5, py: 1, borderRadius: 10, textTransform: 'none', '&:hover': { bgcolor: blue[500] } }}>
					<CalendarMonthIcon fontSize="small" />
					<Typography noWrap variant="subtitle2" sx={{ fontSize: 12, transform: 'translateY(1px)' }}>{`${moment(new Date(filters?.date_range_start)).format('MMMM Do, YYYY')} - ${moment(new Date(filters?.date_range_end)).format('MMMM Do, YYYY')}`}</Typography>
				</Button>
				:
				null
			}
		</Box>
	)
}

const LogData = ({ data, index = 0, activeDetailIndex = null, handleActiveDetailsIndex = () => { } }) => {
	return (
		<Box sx={{ width: '100%', borderTop: index ? `1px solid ${grey[300]}` : 'none', p: 2, display: 'flex', flexDirection: 'row', gap: 2 }}>
			<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
				<RenderAction data={data?.action_type} />
			</Box>
			<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data?.module_type || "")}</Typography>
			</Box>
			<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{data?.created_at ? moment(data.created_at).format('YYYY-MM-DD') : ''}</Typography>
				<Typography variant="subtitle2" sx={{ color: grey[700] }}>{data?.created_at ? moment(data.created_at).format('hh:mm a') : ''}</Typography>
			</Box>
			<Box sx={{ width: '20%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{data?.created_by_user_name || ''}</Typography>
				<Typography variant="subtitle2" sx={{ color: grey[700] }}>{data?.created_by_user_email || ''}</Typography>
			</Box>
			<Box sx={{ width: '10%', overflowX: 'auto', px: 1 }}>
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{data?.source_id || ""}</Typography>
			</Box>
			<Box sx={{ width: '40%', overflowX: 'auto', px: 1 }}>
				<Typography id={`view-btn-${index + 1}`} onClick={() => handleActiveDetailsIndex(index)} variant="subtitle2" sx={{ cursor: 'pointer', color: '#000', display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }} >{data?.comments || ""}</Typography>
				{activeDetailIndex === index ? <ExpandModal data={data} onClose={() => handleActiveDetailsIndex(null)} /> : null}
			</Box>
		</Box>
	)
}

const RenderAction = ({ data = "" }) => {
	if (data.toLowerCase().includes('create')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<AddCircleIcon fontSize="medium" sx={{ color: blue[500], transform: 'translateX(5px)' }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('update')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<ChangeCircleIcon fontSize="medium" sx={{ color: orange[500], transform: 'translateX(5px)' }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('error')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<ErrorIcon fontSize="medium" sx={{ color: red[500], transform: 'translateX(5px)' }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('delete')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<DeleteForeverIcon fontSize="medium" sx={{ color: red[300] }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('login')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<LoginIcon fontSize="medium" sx={{ color: green[500] }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('upload')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<CloudCircleIcon fontSize="medium" sx={{ color: blue[300] }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	} else if (data.toLowerCase().includes('merge')) {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
				<CallMergeIcon fontSize="medium" sx={{ color: green[300] }} />
				<Typography variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
			</Box>
		)
	}
	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
			<Typography noWrap variant="subtitle2" sx={{ color: '#000' }}>{capitalizeString(data || "")}</Typography>
		</Box>
	)
}

const ExpandModal = ({ data = null, onClose = () => { } }) => {

	const handleCopy = (copy) => {
		navigator.clipboard.writeText(JSON.stringify(copy.src, null, '\t'));
	}

	return (
		<Dialog open={true} onClose={onClose} maxWidth="lg" fullWidth>
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 8,
					top: 8,
					color: grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<Typography variant="subtitle2" sx={{ color: '#000', fontSize: 16 }}>Comments</Typography>
				<Typography variant="subtitle2" sx={{ color: '#000', wordBreak: "break-word" }}>{data?.comments || ""}</Typography>
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 2, mt: 2 }}>
					{data?.before_data ?
						<Box sx={{ width: '50%' }}>
							<Typography variant="subtitle2" sx={{ color: '#000', fontSize: 16, mb: 1 }}>Before</Typography>
							<ReactJson enableClipboard={handleCopy} name='data' src={data?.before_data} theme="monokai" style={{ padding: '20px', borderRadius: '10px' }} />
						</Box>
						:
						null
					}
					{data?.after_data ?
						<Box sx={{ width: '50%' }}>
							<Typography variant="subtitle2" sx={{ color: '#000', fontSize: 16, mb: 1 }}>After</Typography>
							<ReactJson enableClipboard={handleCopy} name='data' src={data?.after_data} theme="monokai" style={{ padding: '20px', borderRadius: '10px' }} />
						</Box>
						:
						null
					}
				</Box>
			</DialogContent>
		</Dialog>
	)
}

const FilterModal = ({ filters = null, onClose = () => { }, handleResetFilter = () => { }, handleApplyFilter = () => { } }) => {
	const [filterData, setFilterData] = useState(filters);

	const setFilter = (type = "", val = "") => {
		setFilterData(prev => ({
			...prev,
			[type]: val,
		}));
	}

	return (
		<Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
			<DialogContent>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, mb: 2 }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, bgcolor: blue[50], p: 1, borderRadius: 2, }}>
						<Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<FilterListIcon sx={{ color: '#fff', fontSize: 20 }} />
						</Box>
						<Typography variant='subtitle2' color={blue[500]}>Filters</Typography>
					</Box>
					<IconButton id='close-btn' onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mb: 2, bgcolor: blue[50], p: 1, borderRadius: 2, }}>
					<Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<CalendarMonthIcon sx={{ color: '#fff', fontSize: 20 }} />
					</Box>
					<Typography variant='subtitle2' color={blue[500]}>Date Range</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography variant='subtitle2' sx={{ mb: 1 }}>Start Date</Typography>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								renderInput={(inputProps) =>
									<TextField
										InputLabelProps={{ shrink: true }}
										type="date"
										value={filterData?.date_range_start || null}
										variant="outlined"
										sx={{ width: "100%", background: "#FFFFFF" }}
										onChange={(e) => setFilter('date_range_start', e.target.value)}
									/>}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={4}>
						<Typography variant='subtitle2' sx={{ mb: 1 }}>End Date</Typography>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								renderInput={(inputProps) =>
									<TextField
										InputLabelProps={{ shrink: true }}
										type="date"
										value={filterData?.date_range_end || null}
										variant="outlined"
										sx={{ width: "100%", background: "#FFFFFF" }}
										onChange={(e) => setFilter('date_range_end', e.target.value)}
									/>}
							/>
						</LocalizationProvider>
					</Grid>
				</Grid>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mb: 2, bgcolor: blue[50], p: 1, borderRadius: 2, mt: 2 }}>
					<Box sx={{ width: 30, height: 30, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<ViewModuleIcon sx={{ color: '#fff', fontSize: 20 }} />
					</Box>
					<Typography variant='subtitle2' color={blue[500]}>Type</Typography>
				</Box>
				<Grid container spacing={2}>
					<Grid item xs={4}>
						<Typography variant='subtitle2' sx={{ mb: 1 }}>Modules</Typography>
						<LocalSearchableDropdown
							multiple={true}
							label={'Modules'}
							options={modules}
							value={filterData?.module_type || []}
							handleChange={(v) => setFilter('module_type', v)}
						/>
					</Grid>
					<Grid item xs={4}>
						<Typography variant='subtitle2' sx={{ mb: 1 }}>Actions</Typography>
						<LocalSearchableDropdown
							multiple={true}
							label={'Actions'}
							options={actionList}
							value={filterData?.action_type || []}
							handleChange={(v) => setFilter('action_type', v)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions sx={{ bgcolor: grey[50], p: 2 }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
					<Button id='reset-btn' color='error' onClick={handleResetFilter}>Reset</Button>
					<Button id='apply-btn' variant='outlined' onClick={() => handleApplyFilter(filterData)}>Apply</Button>
				</Box>
			</DialogActions>
		</Dialog>
	)
}

export default ApplicationLogsScreen;