const isNumericInput = (event) => {
  const key = event.keyCode;
  const inputChar = String.fromCharCode(key);

  // Allow numbers, number pad, and the period (decimal point)
  return (
    (key >= 48 && key <= 57) || // Numbers
    (key >= 96 && key <= 105) || // Number pad
    key === 190 || // Period (decimal point)
    inputChar === '.' // Allow the period (decimal point) character
  );
};

const isModifierKey = (event) => {
  const key = event.keyCode;
  return (
    event.shiftKey === true ||
    key === 35 ||
    key === 36 || // Allow Shift, Home, End
    key === 8 ||
    key === 9 ||
    key === 13 ||
    key === 46 ||
    (key === 190 && event.key !== '.') || // Allow Backspace, Tab, Enter, Delete, and period (except for '.')
    (key > 36 && key < 41) || // Allow left, up, right, down
    // Allow Ctrl/Command + A,C,V,X,Z
    ((event.ctrlKey === true || event.metaKey === true) &&
      (key === 65 || key === 67 || key === 86 || key === 88 || key === 90))
  );
};


const enforceFormat = (event) => {
  const inputChar = event.key;
  // Input must be of a valid number format or a modifier key, and not longer than ten digits
  if (!isNumericInput(event) && !isModifierKey(event)) {
    event.preventDefault();
  }
};

const formatToPhone = (directValue, event) => {
  if (isModifierKey(event)) {
    return;
  }
  let formattedNumber = "";
  const input = directValue.replace(/\D/g, "").substring(0, 14); // First ten digits of input only
  const first = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  const lastMost = input.substring(10, 14);

  if (input.length > 10) {
    formattedNumber = `${first}-${middle}-${last}-${lastMost}`;
  } else if (input.length > 6) {
    formattedNumber = `${first}-${middle}-${last}`;
  } else if (input.length > 3) {
    formattedNumber = `${first}-${middle}`;
  } else if (input.length > 0) {
    formattedNumber = `${first}`;
  }
  return formattedNumber;
};

const formatToSSN = (directValue, event) => {
  if (!directValue && isModifierKey(event)) {
    return;
  }
  let formattedNumber = "";
  const input = directValue.replace(/\W/g, "").substring(0, 9); // Up to nine digits of input
  const lastFour = input.substring(input.length - 4); // Extract the last four digits

  if (input.length > 0) {
    formattedNumber = `${lastFour}`;
  }
  return formattedNumber;
};


const formatFederalID = (directValue, event) => {
  if (!directValue && isModifierKey(event)) {
    return;
  }
  let formattedNumber = "";
  const input = directValue.replace(/\W/g, "").substring(0, 15); // Limit input to 15 characters

  if (input.length > 9 && input.length <= 15) {
    const first = input.substring(0, 3);
    const middle1 = input.substring(3, 7);
    const middle2 = input.substring(7, 11);
    const middle3 = input.substring(11, 15);
    formattedNumber = `${first}-${middle1}-${middle2}-${middle3}`;
  } else if (input.length <= 9) {
    const first = input.substring(0, 2);
    const middle1 = input.substring(2, 9);
    formattedNumber = `${first}-${middle1}`;
  } else {
    formattedNumber = input;
  }

  return formattedNumber;
};


export {
  enforceFormat,
  formatToPhone,
  formatToSSN,
  formatFederalID,
};
