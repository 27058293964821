import {
    CHANGE_REQUEST_LIST_IN_PROGRESS,
    CHANGE_REQUEST_LIST_SUCESS,
    CHANGE_REQUEST_LIST_FAILED,
    CHANGE_REQUEST_INFO_IN_PROGRESS,
    CHANGE_REQUEST_INFO_SUCESS,
    CHANGE_REQUEST_INFO_FAILED,
    APPROVE_DISAPPROVE_REQUEST_PROGRESS,  
    APPROVE_DISAPPROVE_REQUEST_SUCCESS,
    APPROVE_DISAPPROVE_REQUEST_FAILED,
    GET_CR_TAB_OPTIONS_IN_PROGRESS,
    GET_CR_TAB_OPTIONS_SUCCESS,
    GET_CR_TAB_OPTIONS_FAILED,
    GET_CR_SPECIAL_OPTIONS_IN_PROGRESS,
    GET_CR_SPECIAL_OPTIONS_SUCCESS,
    GET_CR_SPECIAL_OPTIONS_FAILED,
  } from "../../../constants/store";
import { convertToOptions } from "../../../utils/consultantConstants";
  
  export const initialState = {
    list: [],
    single: {},
    loading: false,
    maxPage:0,
    currentPage:0,
    options:{},
  };
  
  function changeRequestReducer(state = initialState, action) {
    switch (action.type) {
      case GET_CR_TAB_OPTIONS_IN_PROGRESS: {
        const newState = {
          ...state,
          loading:true,
        };
        return newState;
      }
      case GET_CR_TAB_OPTIONS_SUCCESS: {
        let convertedOptions = convertToOptions(action?.payload?.options || {});
        const newState = {
          ...state,
          loading:false,
          options:{
            ...state.options,
            ...convertedOptions
          },
        };
        return newState;
      }
      case GET_CR_TAB_OPTIONS_FAILED: {
        const newState = {
          ...state,
          loading:false,
        };
        return newState;
      }
      case GET_CR_SPECIAL_OPTIONS_IN_PROGRESS: {
        const newState = {
          ...state,
          loading:true,
        };
        return newState;
      }
      case GET_CR_SPECIAL_OPTIONS_SUCCESS: {
        const newState = {
          ...state,
          loading:false,
          options:{
            ...state.options,
            ...action.payload.options
          },
        };
        return newState;
      }
      case GET_CR_SPECIAL_OPTIONS_FAILED: {
        const newState = {
          ...state,
          loading:false,
        };
        return newState;
      }
      case CHANGE_REQUEST_LIST_IN_PROGRESS: {
        const newState = {
          ...state,
          loading: true,
        };
        return newState;
      }
      case CHANGE_REQUEST_LIST_SUCESS: {
        const newState = {
            ...state,
            loading: false,
            list: action.payload.data,
            maxPage:action.payload.maxPage,
            currentPage:action.payload.currentPage
        };
        return newState;
      }
      case CHANGE_REQUEST_LIST_FAILED: {
        const newState = {
          ...state,
          loading: false,
        };
        return newState;
      }
  
      // GET
      case CHANGE_REQUEST_INFO_IN_PROGRESS: {
        const newState = {
          ...state,
          loading: true,
        };
  
        return newState;
      }
      case CHANGE_REQUEST_INFO_SUCESS: {
        const single = action.payload;
        const newState = {
          ...state,
          loading: false,
        };
  
        return newState;
      }
      case CHANGE_REQUEST_INFO_FAILED: {
        const newState = {
          ...state,
          loading: false,
        };
        return newState;
      }

      //REQUEST
      case APPROVE_DISAPPROVE_REQUEST_PROGRESS: {
        const newState = {
          ...state,
          loading: true,
        };
        return newState;
      }
      case APPROVE_DISAPPROVE_REQUEST_SUCCESS: {
        const newState = {
          ...state,
          loading: false,
        };
        return newState;
      }

      case APPROVE_DISAPPROVE_REQUEST_FAILED: {
        const newState = {
          ...state,
          loading: false,
        };
        return newState;
      }
      default:
        return state;
    }
  }
  
  export default changeRequestReducer;
  