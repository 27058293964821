import { ArrowBack } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Button, CircularProgress, IconButton, Tab, Tabs, TextField, Typography } from "@mui/material";
import { blue, green, grey, } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { getRevisionHistory } from "../../../store/Contract/action";
import moment from "moment";
import { getUserConfiguration } from "../../../store/ConsultantForm/action";
import { TabPanel } from "../Settings/SettingsScreen";
import { convertDataToContractInformationRequirement, convertToRequiredTabFormat, extractChangeVariables, statusColor } from "../../../utils/consultantConstants";
import _ from "lodash";
import Revision from "./Revision";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { toast } from "react-toastify";
import { changeRequestApproveDisapprove, fetchChangeRequestInfo } from "../../../store/actions/ChangeRequestActions";
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

const maxLength = 500;

function ReviewChangeRequestScreen() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);
  let hasPermission = false;
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: getCurrentPageIdentifier() });
  if (foundItem) {
    hasPermission = (foundItem?.actions || []).includes('READ');
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  const { list = [], loadingHistory = false } = useSelector((state) => state.contract);
  const { configurations = [], loading = false, documents = [] } = useSelector((state) => state.consultantForm);
  const infoLoading = useSelector((state) => state.changeRequest.loading) || false;
  const [activeTab, setActiveTab] = useState(null);
  const [configuration, setConfiguration] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [data, setData] = useState(null);
  const [compareData, setCompareData] = useState(null);
  const [postSaveDocuments, setPostSaveDocuments] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [showOnlyChanges, setShowOnlyChanges] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [showComment, setShowComment] = useState(false);
  const [markAll, setMarkAll] = useState(false);
  const [reason, setReason] = useState("");

  const ids = (location?.pathname || "").split("/");
  const id1 = ids.length > 1 ? ids[ids.length - 2] : "";
  const id2 = ids.length > 1 ? ids[ids.length - 1] : "";

  useEffect(() => {
    if (id1 && id2 && hasPermission) {
      dispatch(getUserConfiguration({ id: currentUser?.id }, () => {
        dispatch(getRevisionHistory({ id: id1 }));
      }));
    }
  }, [hasPermission]);

  useEffect(() => {
    if (documents?.length) {
      setPostSaveDocuments(documents);
    }
  }, [documents]);

  useEffect(() => {
    if (list?.length && id2) {
      let findIndex = _.findIndex(list, item => item.contractId == id2);
      if (findIndex > -1) {
        handleChangeTab(findIndex);
      }
    }
  }, [list, id2]);

  useEffect(() => {
    handleApplyRules();
  }, [data, configuration]);

  useEffect(() => {
    if (markAll) {
      const list = extractChangeVariables(tabs, data, compareData.finalObj);
      setCheckedItems(list);
    }
  }, [markAll]);

  useEffect(() => {
    if (tabs?.length) {
      const list = extractChangeVariables(tabs, data, compareData?.finalObj);
      if (checkedItems?.length === Object.keys(list)?.length) {
        setMarkAll(true);
      } else {
        setMarkAll(false);
      }
    }
  }, [checkedItems]);

  const handleApplyRules = () => {
    let tabList = JSON.parse(JSON.stringify(configuration));
    let conditions = [];
    tabList.forEach(tabItem => {
      (tabItem?.fields || []).forEach(field => {
        if (field?.rules?.length) {
          conditions.push(...field.rules);
        }
      });
    });
    conditions.forEach(conditionItem => {
      let conditionSatisfiedCount = 0;
      conditionItem?.conditions?.forEach(condition => {
        let conditionSatisfied = false;
        let key = condition?.key || "";
        let value = data?.[key] || "";
        if (condition?.type === 'equalTo') {
          conditionSatisfied = condition?.value == value;
        } else if (condition?.type === 'notEqualTo') {
          conditionSatisfied = condition?.value != value;
        } else if (condition?.type === 'empty') {
          conditionSatisfied = !value;
        } else if (condition?.type === 'notEmpty') {
          conditionSatisfied = value ? true : false;
        }
        if (conditionSatisfied) {
          conditionSatisfiedCount++;
        }
      });
      if (conditionSatisfiedCount === conditionItem?.conditions?.length) {
        tabList.forEach(tabItem => {
          (tabItem?.fields || []).forEach(item => {
            let variable = item?.variable || "";
            if (item?.type === 'location') {
              variable = item?.address?.variable || item?.country?.variable || item?.state?.variable || item?.city?.variable || item?.zipCode?.variable;
            }
            if ((conditionItem?.variable === variable || conditionItem?.variable === item.confidential) && !item.systemRequired) {
              if (item?.type === 'location') {
                let locationConfig = {};
                locationConfig.address = {
                  hidden: !conditionItem.visibility1,
                  required: conditionItem.requiredField1,
                };
                locationConfig.country = {
                  hidden: !conditionItem.visibility2,
                  required: conditionItem.requiredField2,
                };
                locationConfig.state = {
                  hidden: !conditionItem.visibility3,
                  required: conditionItem.requiredField3,
                };
                locationConfig.city = {
                  hidden: !conditionItem.visibility4,
                  required: conditionItem.requiredField4,
                };
                locationConfig.zipCode = {
                  hidden: !conditionItem.visibility5,
                  required: conditionItem.requiredField5,
                };
                item.locationConfig = locationConfig;
              } else {
                item.hidden = !conditionItem.visibility;
                item.required = conditionItem.requiredField;
              }
            }
          })
        })
      }
    });
    setTabs(tabList);
  };

  const handleGoBack = () => {
    history.goBack();
  }

  const handleChangeTab = (v) => {
    setActiveTab(v);
    let d = list[v];
    setTabs([]);
    if (v === (list.length - 1)) {
      setShowOnlyChanges(false);
    } else {
      setShowOnlyChanges(true);
    }
    dispatch(fetchChangeRequestInfo(d?.contractId, (res) => {
      if (res?.data?.length) {
        let dataArr = res?.data || [];
        let foundItem = _.find(configurations, { varient: dataArr[0]?.contractStatus || '' });
        let tabList = convertToRequiredTabFormat(foundItem?.tabs || []);
        let cD = convertDataToContractInformationRequirement(dataArr[0], {}, tabList);
        setTabs(tabList);
        setConfiguration(tabList);
        if (dataArr?.length > 1) {
          let foundItem2 = _.find(configurations, { varient: dataArr[1]?.contractStatus || '' });
          let tabList2 = convertToRequiredTabFormat(foundItem2?.tabs || []);
          let cD2 = convertDataToContractInformationRequirement(dataArr[1], {}, tabList2);
          setCompareData(cD);
          setTabs(tabList2);
          setConfiguration(tabList2);
          setData(cD2.finalObj);
          setSelectedOptions(cD2.allOptions);
        } else {
          setCompareData(cD);
          setTabs(tabList);
          setConfiguration(tabList);
          setData(cD.finalObj);
          setSelectedOptions(cD.allOptions);
        }
      }
    }));
  }

  const handleCheck = (value = "") => {
    if (checkedItems.includes(value)) {
      setCheckedItems(prev => prev.filter(item => item !== value));
    } else {
      setCheckedItems(prev => ([...prev, value]));
    }
  }

  const handleNext = () => {
    setShowComment(true);
  }

  const handleBack = () => {
    setShowComment(false);
  }

  const handleSubmit = () => {
    let payload = null;
    let contractId = list[activeTab].contractId;
    if (markAll) {
      payload = {
        id: contractId,
        status: 1,
        comment: "",
        reject_contract: null,
        approved_contract: null
      };
    } else if (!checkedItems.length) {
      payload = {
        id: contractId,
        status: 2,
        comment: reason,
        reject_contract: null,
        approved_contract: null
      };
    } else {
      if (!reason?.length) {
        toast.error('Please add a disapproval comment!');
        return false;
      } else {
        let oldData = compareData?.finalObj || null;
        const contract = extractContractPayload(oldData, tabs);
        let approved_contract = { ...contract };
        checkedItems.forEach(key => {
          approved_contract[key] = data[key];
        });
        const list = extractChangeVariables(tabs, data, oldData);
        let unCheckItems = list.filter(item => !checkedItems.includes(item));
        let reject_contract = { ...contract };
        unCheckItems.forEach(key => {
          reject_contract[key] = data[key];
        });
        payload = {
          id: contractId,
          status: 1,
          comment: reason,
          reject_contract: reject_contract,
          approved_contract: approved_contract
        };
      };
    }
    dispatch(changeRequestApproveDisapprove(payload, (res) => {
      if (res?.status) {
        toast.success(payload?.status === 1 ? 'Changes approved successfully' : 'Changes disapproved successfully');
        history.goBack();
      } else {
        toast.error(payload?.status === 1 ? 'Failed to approve changes!' : 'Failed to disapprove changes!');
      }
    }));
  };

  const extractContractPayload = (data = null, tabs = []) => {
    let contract = {};
    let item = {};
    tabs?.forEach(tab => {
      tab?.fields?.forEach(field => {
        if (field?.type !== 'divider' && field?.type !== 'upload' && field?.variable) {
          if (field?.type === 'location') {
            let addressKey = field?.address?.variable;
            if (addressKey) {
              let addressValue = !field?.locationConfig?.address?.hidden ? data?.[addressKey] : null;
              item[addressKey] = addressValue;
            }
            let countryKey = field?.country?.variable;
            if (countryKey) {
              let countryValue = !field?.locationConfig?.country?.hidden ? data?.[countryKey] : null;
              item[countryKey] = countryValue;
            }
            let stateKey = field?.state?.variable;
            if (stateKey) {
              let stateValue = !field?.locationConfig?.state?.hidden ? data?.[stateKey] : null;
              item[stateKey] = stateValue;
            }
            let cityKey = field?.city?.variable;
            if (cityKey) {
              let cityValue = !field?.locationConfig?.city?.hidden ? data?.[cityKey] : null;
              item[cityKey] = cityValue;
            }
            let zipCodeKey = field?.zipCode?.variable;
            if (zipCodeKey) {
              let zipCodeValue = !field?.locationConfig?.zipCode?.hidden ? data?.[zipCodeKey] : null;
              item[zipCodeKey] = zipCodeValue;
            }
          } else if (field?.type === 'plan') {
            let key = field?.variable;
            if (key) {
              let d = {};
              selectedOptions?.[field.searchVariable]?.forEach(op => {
                let planValue = data?.[field.variable + "" + op?.label] || {};
                d = {
                  ...d,
                  [op.value]: planValue,
                };
              });
              item[key] = !field?.hidden ? d : {};
            }
          } else {
            let key = field?.variable;
            let value = data?.[key] || null;
            if (key) {
              if (field?.multiple) {
                item[key] = !field?.hidden && value !== null ? value : [];
              } else {
                item[key] = !field?.hidden ? value : null;
              }
            }
            let cKey = field?.confidential;
            let cValue = data?.[cKey] || null;
            if (field?.confidential && field?.confidentialLabel) {
              item[cKey] = !field?.hidden ? cValue : null;
            }
            if (field?.type === 'dropdown') {
              let yesNoList = false;
              let optionList = field?.options || [];
              optionList = optionList.map(o => (o?.label || "").toLowerCase());
              if (optionList.includes('yes') && optionList.includes('no')) {
                yesNoList = true;
              }
              if (yesNoList && value == 'Yes') {
                item[key] = 1;
              } else if (yesNoList && value == 'No') {
                item[key] = 0;
              }
            }
          }
        }
      });
      contract = { ...item };
    });
    return contract;
  }

  const diffList = extractChangeVariables(tabs, data, compareData?.finalObj);

  return (
    <Box id="revision-history-screen" sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 1, zIndex: 999 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
          <IconButton id='exit-btn' onClick={handleGoBack} sx={{ width: 35, height: 35, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
            <ArrowBack />
          </IconButton>
          <Typography variant='subtitle2' sx={{ color: blue[500], fontSize: 20 }}>Review Change Requests</Typography>
        </Box>
      </Box>
      <Box sx={{ bgcolor: grey[100], border: "1px solid rgba(0, 0, 0, 0.05)", borderRadius: 2, mt: 2, position: 'relative' }}>
        <Box sx={{ px: 2, pt: 1 }}>
          {activeTab !== null ? <TabNav id='revision-history' list={list} value={activeTab} handleChange={(e, v) => handleChangeTab(v)} /> : null}
        </Box>
        <Box id={'revision-history-view'} sx={{ width: "100%", height: '75svh', overflowY: 'auto', px: 2, pt: 0.5, pb: 2 }}>
          {hasPermission ?
            list?.map((tab, tabIndex) => {
              return (
                <Box key={`tab-panel-${tabIndex}`} sx={{ marginTop: 2, width: '100%' }}>
                  <TabPanel id="revision-history" value={activeTab} index={tabIndex}>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
                        {
                          showOnlyChanges
                            ?
                            <Box id='show-btn' onClick={() => setShowOnlyChanges(false)} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 200, p: 1, bgcolor: green[50], borderRadius: 2 }}>
                              <Box sx={{ width: 30, height: 30, bgcolor: green[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
                                <VisibilityIcon fontSize="small" />
                              </Box>
                              <Typography variant='subtitle2' color={green[500]}>Showing only changes</Typography>
                            </Box>
                            :
                            <Box id='show-btn' onClick={() => setShowOnlyChanges(true)} sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: 200, p: 1, bgcolor: grey[200], borderRadius: 2 }}>
                              <Box sx={{ width: 30, height: 30, bgcolor: grey[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
                                <VisibilityOffIcon fontSize="small" />
                              </Box>
                              <Typography variant='subtitle2' color={grey[500]}>Showing all details</Typography>
                            </Box>
                        }
                      </Box>
                      {hasWritePermission && tab?.pendingApprove === 'pending'
                        ?
                        showComment
                          ?
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Button id='back-btn' onClick={handleBack} variant="outlined">Back</Button>
                            <Button id='submit-btn' onClick={handleSubmit} variant="contained" color="success">Submit</Button>
                          </Box>
                          :
                          markAll
                            ?
                            <Button id='submit-btn' onClick={handleSubmit} variant="contained" color="success">Submit</Button>
                            :
                            <Button id='next-btn' onClick={handleNext} variant="outlined">Next</Button>
                        :
                        null
                      }
                    </Box>
                    {hasWritePermission && tab?.pendingApprove === 'pending' ?
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
                        <Typography variant='subtitle2' sx={{ color: grey[700], fontSize: 20, ml: 1, mt: 2 }}>{showComment ? `Please add a reason of disapproval` : `Choose option(s) you want to approve`}</Typography>
                        {!showComment && Object.keys(diffList).length ?
                          <Button id='mark-all-btn' onClick={() => {
                            if (markAll) {
                              setMarkAll(prev => !prev);
                              setCheckedItems([]);
                            } else {
                              setMarkAll(prev => !prev);
                            }
                          }} sx={{ textTransform: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            {markAll ?
                              <CheckBoxIcon color='info' />
                              :
                              <CheckBoxOutlineBlankIcon color='info' />
                            }
                            <Typography variant="subtitle2" sx={{ fontSize: 16 }}>Mark all for approval</Typography>
                          </Button>
                          :
                          null
                        }
                      </Box>
                      :
                      null
                    }
                    {showComment ?
                      <Alert severity={'error'} sx={{ width: '100%', borderRadius: 2, mt: 2, pt: 2, pb: 2 }}>
                        <AlertTitle>These changes were marked disapproved for the following reason(s) :</AlertTitle>
                        <TextField
                          value={reason}
                          onChange={e => setReason(e.target.value)}
                          placeholder="Reason of disapproval!"
                          multiline={true}
                          maxRows={6}
                          sx={{ width: "100%", background: "#FFFFFF", mt: 1 }}
                          autoFocus={true}
                          inputProps={{ maxLength: maxLength, paddingBottom: "100px" }}
                        />
                        {reason.length ? <Typography variant='subtitle2' sx={{ mt: 1 }}>{reason.length}/{maxLength}</Typography> : null}
                      </Alert>
                      :
                      null
                    }
                    {tab?.pendingApprove === 'rejected' ?
                      <Alert severity={'error'} sx={{ width: '100%', borderRadius: 2, mt: 2, pt: 2, pb: 2 }}>
                        <AlertTitle>These changes were marked disapproved for the following reason(s) :</AlertTitle>
                        {tab?.comment ? <Typography variant='subtitle2' sx={{ mt: 1 }}>{tab?.comment}</Typography> : null}
                      </Alert>
                      :
                      null
                    }
                    <Revision
                      showComment={showComment}
                      showOnlyChanges={showOnlyChanges}
                      showCheck={hasWritePermission && tab?.pendingApprove === 'pending'}
                      data={data}
                      compareData={compareData}
                      tabs={tabs}
                      optionList={selectedOptions}
                      postSaveDocuments={postSaveDocuments}
                      checkedItems={checkedItems}
                      handleCheck={handleCheck}
                    />
                  </TabPanel>
                </Box>
              )
            })
            :
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ width: 35, height: 35, color: '#475569' }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
              </Box>
              <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>No permissions found</Typography>
            </Box>
          }
        </Box>
      </Box>
      {loading || loadingHistory || infoLoading ? <OverlayLoader /> : null}
    </Box>
  )
}

const TabNav = ({ id = "", list = [], value, handleChange }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label={id + '-tabs'}
      indicatorColor='primary'
      sx={{ pointerEvents: 'pointer' }}
      variant={"scrollable"}
      scrollButtons={"on"}
    >
      {list.map((l, index) => {
        return (
          <Tab
            key={`${id}-${index}`}
            label={
              <Box sx={{ textTransform: 'none', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                  <Typography variant="subtitle2" sx={{ fontSize: 12 }}>Version {(list.length - (index))}</Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: 12, textTransform: 'capitalize', color: 'white', px: 1, borderRadius: 4, bgcolor: statusColor(l?.pendingApprove) }}>{l?.pendingApprove || ""}</Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 0.5 }}>
                  <Typography variant="subtitle2">{moment(l.contractCreatedAt).format('MMM Do, YYYY')}</Typography>
                  <Typography variant="subtitle2" sx={{ fontSize: 12 }}>{moment(l.contractCreatedAt).format('hh:mm a')}</Typography>
                </Box>
              </Box>
            }
            {...a11yProps(id, index)}
          />
        )
      })}
    </Tabs>
  )
};

const a11yProps = (id = "", index = 0) => {
  return {
    id: `${id}-tab-${index}`,
    "aria-controls": `${id}-tabpanel-${index}`,
  };
}

const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100svh', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4, }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

export default ReviewChangeRequestScreen;