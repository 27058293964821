import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import LocalSearchableDropdown from "../../../components/LocalSearchableDropdown";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getOptions, storeDashboardFilter } from "../../../store/Reports/action";
import { blue, grey, red } from "@mui/material/colors";
import moment from "moment";
import FilterListIcon from '@mui/icons-material/FilterList';
import _, { drop } from "lodash";

const Filters = ({ dashboard_id, filterParameters = [], activeFilters = {}, setActiveFilters }) => {
  const dispatch = useDispatch();
  const { dropdownOptions, loadingDropdown } = useSelector((state) => state.reports);
  const [isError, setIsError] = useState(false);
  const [doneLoadingList, setDoneLoadingList] = useState([]);
  const [currentFilters, setCurrentFilters] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    handleInit();
  }, []);

  useEffect(() => {
    let c = 0;
    Object.keys(activeFilters).forEach(key => {
      if (_.isArray(activeFilters[key])) {
        if (activeFilters[key].length) {
          c++;
        }
      } else {
        if (activeFilters[key]) {
          c++
        }
      }
    });
    setFilterCount(c);
  }, [activeFilters])

  const handleInit = () => {
    let error = false;
    let filters = {};
    for (let i = 0; i < filterParameters.length; i++) {
      let f = filterParameters[i];
      if (f?.default?.length && f?.type === 'string/contains') {
        if (f?.default.length) {
          filters = {
            ...filters,
            [f.id]: f.default[0]
          }
        }
      } else if (f?.default?.length && f?.type === 'number/>=') {
        if (f?.default) {
          filters = {
            ...filters,
            [f.id]: f.default
          }
        }
      } else if (f?.type === 'string/=') {
        if (f?.default) {
          filters = {
            ...filters,
            [f.id]: f.default
          }
        }
      }
      if (!dropdownOptions?.[f.id]?.length) {
        dispatch(getOptions({ dashboard_id: dashboard_id, option_id: f.id }, (res) => {
          if (!res.status) {
            error = true;
          }
          setDoneLoadingList(prev => ([
            ...prev,
            f.id,
          ]));
        }));
      } else {
        setDoneLoadingList(prev => ([
          ...prev,
          f.id,
        ]));
      }
    }
    setActiveFilters(filters);
    setIsError(error);
  }

  const handleShowFilter = () => {
    setShowFilter(true);
    setCurrentFilters(activeFilters);
  }

  const handleHideFilter = () => {
    setShowFilter(false);
    setCurrentFilters(activeFilters);
  }

  const handleResetFilter = () => {
    setShowFilter(false);
    setCurrentFilters({});
    setActiveFilters({});
    dispatch(storeDashboardFilter({ filters: {}, id: dashboard_id }));
  }

  const handleChange = (name = undefined, value = undefined) => {
    setCurrentFilters(prev => (
      {
        ...prev,
        [name]: value,
      }
    ));
  }

  const handleChangeDate = (name = undefined, type = undefined, value = undefined) => {
    let finaDate = "";
    let dates = currentFilters[name] ? currentFilters[name].split('~') : ('~').split('~');
    let startDate = dates[0];
    let endDate = dates[1];
    if (type === 'startDate') {
      finaDate = value + '~' + (endDate ? endDate : moment(new Date()).format('YYYY-MM-DD'));
    } else {
      finaDate = startDate + '~' + value;
    }
    setCurrentFilters(prev => (
      {
        ...prev,
        [name]: finaDate,
      }
    ));
  }

  const handleApplyFilter = () => {
    setActiveFilters(currentFilters);
    setShowFilter(false);
    dispatch(storeDashboardFilter({ filters: currentFilters, id: dashboard_id }));
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      <Box sx={{ position: 'relative', ml: 1, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <IconButton id='filter-icon' onClick={handleShowFilter} sx={{ bgcolor: grey[50] }}>
          <FilterListIcon />
        </IconButton>
        {filterCount ?
          <Box sx={{ px: 0.5, bgcolor: blue[500], position: 'absolute', left: 25, top: -5, borderRadius: 2 }}>
            <Typography sx={{ fontSize: 10, color: '#fff', fontWeight: 600 }}>{filterCount}</Typography>
          </Box>
          :
          null
        }
      </Box>
      <Dialog maxWidth="lg" fullWidth open={showFilter} onClose={handleHideFilter}>
        <DialogContent>
          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', mb: 1 }}>
            <Typography sx={{ fontSize: 24, fontWeight: 500, mr: 1 }}>Filters</Typography>
            {
              loadingDropdown
                ?
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={16} />
                  <Typography sx={{ ml: 1, color: '#475569', fontSize: '14px', fontWeight: 500 }}>Fetching filters...</Typography>
                </Box>
                :
                null
            }
            {
              isError
                ?
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ width: 20, height: 20, color: red[400] }}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-exclamation-triangle"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20z" /></svg>
                  </Box>
                  <Typography sx={{ ml: 1, color: '#475569', fontSize: '14px', fontWeight: 500 }}>Failed to fetch filters!</Typography>
                </Box>
                :
                null
            }
          </Box>
          <Grid container spacing={2} sx={{ width: '100%' }}>
            {
              filterParameters?.map((filterParameter, filterParameterIndex) => {
                let id = filterParameter.id;
                if (filterParameter.type === 'date/all-options') {
                  let dates = currentFilters?.[id] ? currentFilters?.[id].split('~') : ('~').split('~');
                  let startDate = dates[0] || "";
                  let endDate = dates[1] || "";
                  return (
                    <Grid item xs={endDate ? 6 : 3} key={`report-filter-${filterParameterIndex}`}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container gap={endDate ? 2 : 0}>
                          <Grid item xs={endDate ? 5 : 12}>
                            <DatePicker
                              renderInput={(inputProps) =>
                                <TextField
                                  label={'Start Date'}
                                  InputLabelProps={{ ...inputProps, shrink: true }}
                                  type="date"
                                  value={startDate}
                                  sx={{ width: "100%", background: "#FFFFFF" }}
                                  onChange={(e) => handleChangeDate(filterParameter?.id, 'startDate', e?.target?.value || undefined)}
                                  size="small"
                                />}
                            />
                          </Grid>
                          {endDate
                            ?
                            <Grid item xs={5}>
                              <DatePicker
                                renderInput={(inputProps) =>
                                  <TextField
                                    label={'End Date'}
                                    InputLabelProps={{ ...inputProps, shrink: true }}
                                    type="date"
                                    value={endDate}
                                    sx={{ width: "100%", background: "#FFFFFF" }}
                                    onChange={(e) => handleChangeDate(filterParameter?.id, 'endDate', e?.target?.value || undefined)}
                                    size="small"
                                  />}
                              />
                            </Grid>
                            :
                            null}
                        </Grid>
                      </LocalizationProvider>
                    </Grid>
                  )
                } else if (dropdownOptions?.[id]?.length) {
                  return (
                    <Grid item xs={3} key={`report-filter-${filterParameterIndex}`}>
                      <LocalSearchableDropdown
                        value={currentFilters?.[id] || []}
                        label={filterParameter?.name || ""}
                        options={dropdownOptions?.[id] || []}
                        handleChange={(v) => handleChange(filterParameter?.id, v.length ? v : undefined)}
                        size="small"
                        showLabel={true}
                        multiple={true}
                        disabled={!doneLoadingList.includes(filterParameter?.id)}
                        loading={!doneLoadingList.includes(filterParameter?.id)}
                        max={10}
                      />
                    </Grid>
                  )
                } else {
                  let value = currentFilters?.[id];
                  if (filterParameter?.type === 'number/>=') {
                    return (
                      <Grid item xs={3} key={`report-filter-${filterParameterIndex}`}>
                        <TextField
                          value={value ? value[0] : ""}
                          onChange={(e) => handleChange(filterParameter.id, e?.target?.value ? [parseInt(e.target.value)] : undefined)}
                          placeholder={filterParameter?.name || ""}
                          sx={{ width: "100%", background: "#FFFFFF" }}
                          size="small"
                          autoComplete="off"
                          label={filterParameter?.name || ""}
                          type="number"
                        />
                      </Grid>
                    )
                  }
                  return (
                    <Grid item xs={3} key={`report-filter-${filterParameterIndex}`}>
                      <TextField
                        value={currentFilters?.[id] || ""}
                        onChange={(e) => handleChange(filterParameter.id, e?.target?.value || undefined)}
                        placeholder={filterParameter?.name || ""}
                        sx={{ width: "100%", background: "#FFFFFF" }}
                        size="small"
                        autoComplete="off"
                        label={filterParameter?.name || ""}
                        type="search"
                      />
                    </Grid>
                  )
                }
              })
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button id='reset-btn' variant="outlined" color="error" onClick={handleResetFilter}>
            Reset
          </Button>
          <Button id='close-btn' onClick={handleHideFilter}>
            Close
          </Button>
          <Button id='apply-btn' variant="contained" onClick={handleApplyFilter}>
            Apply Filters
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Filters;