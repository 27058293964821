import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Stack, IconButton, Tooltip, CircularProgress, Box, Typography, } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import _ from "lodash";
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

export default function StatusMaster(props) {
  const { filteroptions, filterCall, setFilterOptions } = props;
  const history = useHistory();
  const { currentUser = null } = useSelector(state => state.auth);
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix, { identifier: getCurrentPageIdentifier() });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  const list = useSelector((state) => state?.candidate?.list);
  const max = useSelector((state) => state?.candidate?.max);
  const loading = useSelector((state) => state?.candidate?.loading);

  const [page, setPage] = useState(0);

  const ActionButtons = ({ params }) => {
    const contractId = params?.row?.contract_id;
    const candidateId = params?.row?.id;
    return (
      <Stack direction='row' alignItems='center' spacing={1}>
        <Tooltip id={`view-contract-tooltip-${params?.row?.id}`} title='View'>
          <IconButton
            aria-label='view'
            size='small'
            color='primary'
            onClick={() => {
              if (hasWritePermission) {
                history.push(`/consultants/contracts/${candidateId}`);
              } else {
                window.open(`/consultants/view-candidate/${params?.row?.contract_status || 'Draft'}/${contractId}`, '_blank');
              }
            }}
            id={`view-contract-btn-${params?.row?.id}`}
          >
            <VisibilityIcon fontSize='small' />
          </IconButton>
        </Tooltip>
        {params?.row?.is_draft !== 1 &&
          hasWritePermission &&
          params?.row?.contract_id ?
          <Tooltip id={`edit-contract-tooltip-${params?.row?.id}`} title='Edit'>
            <IconButton
              aria-label='edit'
              size='small'
              color='primary'
              onClick={() => {
                history.push({
                  pathname: '/consultants/candidateform',
                  state: {
                    action: "edit",
                    editType: "candidate",
                    id: params?.row?.contract_id,
                    candidateId: candidateId,
                    status: params?.row?.contract_status,
                  }
                });
              }}
              id={`edit-contract-btn-${params?.row?.id}`}
            >
              <BorderColorIcon fontSize='small' />
            </IconButton>
          </Tooltip>
          :
          null
        }
      </Stack>
    );
  };

  useEffect(() => {
    let offsetVal = 0;
    // set offset value for pagination
    if (page !== 0) {
      offsetVal = page * 100;
    }
    const d = {
      ...filteroptions,
      offset: offsetVal,
      limit: 100,
    };
    setFilterOptions(d);
    filterCall(d);
  }, [page]);

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  const columns = [
    {
      field: "contract_status",
      flex: 1,
      headerName: "Contract Status",
      minWidth: 150,
    },
    {
      field: "contractor_status",
      flex: 1,
      headerName: "Contractor Type",
      minWidth: 150,
    },
    { field: "entity", flex: 1, headerName: "Entity", minWidth: 120 },
    { field: "name", flex: 1, headerName: "Name", minWidth: 180 },
    { field: "contact_email", flex: 1.5, headerName: "Email", minWidth: 225 },
    {
      field: "contact_phone",
      flex: 1,
      headerName: "Phone number",
      minWidth: 150,
      renderCell: (params) => {
        return formatPhoneNumberIntl(params?.value || "") || params.value;
      },
    },
    {
      field: "creator_name",
      flex: 1,
      headerName: "Created By",
      minWidth: 150,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      minWidth: 150,
      renderCell: (params) => {
        return <ActionButtons params={params} />;
      },
    },
  ];

  return (
    <Box
      id="consultant-list-box"
      sx={{
        background: "#FFFFFF",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: 2,
        mt: 2,
        width: "100%",
        position: 'relative',
        pb: 2,
        height: 'calc(100svh - 120px)'
      }}
    >
      {list?.length ? (
        <DataGrid
          rows={list || []}
          sx={{
            px: 2,
            border: 0,
            height: "100%",
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "#1089FF",
            },
            "& .MuiDataGrid-cell": {
              margin: 0,
              border: 0,
              width: 300,
            },
            "& .MuiDataGrid-row": {
              margin: 0,
            },
            "& .MuiDataGrid-iconSeparator": {
              display: "none",
            },
          }}
          columns={columns}
          density='comfortable'
          disableSelectionOnClick
          pagination
          paginationMode='server'
          pageSize={100}
          rowCount={max}
          rowsPerPageOptions={[100]}
          onPageChange={handlePageChange}
          page={page}
        />
      ) : (
        <NoConsultants loading={loading} />
      )}
      {loading && list?.length ? <OverlayLoader /> : null}
    </Box>
  );
}

export const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4 }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

const NoConsultants = ({ loading = false }) => {
  return (
    <Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', borderRadius: 2, p: 2, mt: 2 }}>
      <Box sx={{ width: 35, height: 35, color: '#475569' }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        {loading ? <CircularProgress size={20} color="primary" /> : null}
        <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{loading ? `Fetching consultants...` : `No consultants found`}</Typography>
      </Box>
    </Box>
  )
}
