import {
  GET_CLIENT_FAILED,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_REQUESTED,
} from "../../../constants/store";

export const initialState = {
  client: {},
  loading: false,
  error: false,
  complete: false,
  createSuccess: false,
  getSuccess: false,
};

function clientReducer(state = initialState, action) {
  switch (action.type) {
    // GET CLIENT
    case GET_CLIENT_REQUESTED: {
      const newState = {
        ...state,
        loading: true,
        error: false,
      };

      return newState;
    }
    case GET_CLIENT_SUCCESS: {
      const client = action.payload;
      const newState = {
        ...state,
        ...client,
        getSuccess: action.payload.getSuccess,
      };

      return newState;
    }
    case GET_CLIENT_FAILED: {
      const newState = {
        ...state,
        error: true,
      };
      return newState;
    }
    default:
      return state;
  }
}

export default clientReducer;
