import { GET_SOW_FAILED, GET_SOW_IN_PROGRESS, GET_SOW_SUCCESS, GET_SOW_OPTIONS_FAILED, GET_SOW_OPTIONS_IN_PROGRESS, GET_SOW_OPTIONS_SUCCESS, ADD_SOW_IN_PROGRESS, ADD_SOW_SUCCESS, ADD_SOW_FAILED, GET_SOW_REVISIONS_IN_PROGRESS, GET_SOW_REVISIONS_SUCCESS, GET_SOW_REVISIONS_FAILED } from "../../constants/store";

export const initialState = {
  maxPage:0,
  currentPage:0,
  loading: false,
  list:[],
  options:{},
  revList:[],
};

function sowReducer(state = initialState, action) {
  switch (action.type) {
    case GET_SOW_OPTIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_SOW_OPTIONS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        options:{
          ...action.payload.options
        }
      };
      return newState;
    }
    case GET_SOW_OPTIONS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case GET_SOW_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_SOW_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        list: action.payload.list,
        maxPage: action.payload.maxPage,
        currentPage: action.payload.currentPage,
      };
      return newState;
    }
    case GET_SOW_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case ADD_SOW_IN_PROGRESS:{
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case ADD_SOW_SUCCESS:{
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case ADD_SOW_FAILED:{
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case GET_SOW_REVISIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_SOW_REVISIONS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        revList: action.payload.list,
      };
      return newState;
    }
    case GET_SOW_REVISIONS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    default:
      return state;
  }
}

export default sowReducer;
