export const extractTabIdFromName = (name = "") => {
  switch (name) {
    case 'dropdownSettings':
      return 0;
    case 'vendorSettings':
      return 1;
    case 'inputDesigner':
      return 2;
    case 'formDesigner':
      return 3;
    case 'regionBuilder':
      return 4;
    default:
      return 0;
  }
}

export const extractTabNameFromId = (id = null) => {
  switch (id) {
    case 0:
      return 'dropdownSettings';
    case 1:
      return 'vendorSettings';
    case 2:
      return 'inputDesigner';
    case 3:
      return 'formDesigner';
    case 4:
      return 'regionBuilder';
    default:
      return 'dropdownSettings';
  }
}