import { GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED, GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_IN_PROGRESS, GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_SUCCESS, GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED, GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_IN_PROGRESS, GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_SUCCESS, GET_PENDING_RECRUITMENT_DRAFT_LIST_FAILED, GET_PENDING_RECRUITMENT_DRAFT_LIST_IN_PROGRESS, GET_PENDING_RECRUITMENT_DRAFT_LIST_SUCCESS } from "../../constants/store";
import axios from "axios";
import api from "../../config/https";
import { API_BASE_URL } from "../../constants/constants";
import { convertSpecialListToOptions, encodeQueryData } from "../../utils/consultantConstants";

export const getPendingRecruitmentFilterOptions = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_IN_PROGRESS,
    });
    let res = await axios.get(`${API_BASE_URL}?tab_id=${1}`);
    let data= res?.data || {};
    if(Object.keys(data).length !== 0){
      dispatch({
        type: GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_SUCCESS,
        payload: {
          options:{
            ...data,
          }
        }
      });
    } else {
      dispatch({
        type: GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED,
      });
    }
    if(callback){
      callback();
    }
  } catch (e) {
    dispatch({
      type: GET_PENDING_RECRUITMENT_DRAFT_FILTER_OPTIONS_FAILED,
    });
  }
};

export const getPendingRecruitmentSpecilaFilterOptions = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_IN_PROGRESS,
    });
    let res1 = await api.get(`/masterAdmin/vendor`);
    let res2 = await api.get(`/masterAdmin/clients`);
    if (res1?.data?.status && res2?.data?.status) {
      let data1 = convertSpecialListToOptions(res1?.data?.data?.data  || [],'vendor','vendor_name');
      let data2 = convertSpecialListToOptions(res2?.data?.data?.data  || [],'clients','name');
      dispatch({
        type: GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_SUCCESS,
        payload: {
          options: {
            ...data1,
            ...data2,
          }
        }
      });
    } else {
      dispatch({
        type: GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED,
      });
    }
    if(callback){
      callback();
    }
  } catch (e) {
    dispatch({
      type: GET_PENDING_RECRUITMENT_DRAFT_FILTER_SPECIAL_OPTIONS_FAILED,
    });
  }
};

export const fetchAllPendingRecruitmentDrafts = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_PENDING_RECRUITMENT_DRAFT_LIST_IN_PROGRESS,
    });
    let res = await api.get('/masterAdmin/candidates/pendingRecuitmentDraft'+encodeQueryData(payload));
    if (res?.data?.status && res?.data?.data) {
      let data=res.data.data;
      let list=data?.result || [];
      dispatch({
        type: GET_PENDING_RECRUITMENT_DRAFT_LIST_SUCCESS,
        payload: {data:list,maxPage:data?.count || list.length, currentPage:payload.offset},
      });
    }else{
      dispatch({
        type: GET_PENDING_RECRUITMENT_DRAFT_LIST_FAILED,
        payload: {reset:payload.page===0},
      });
    }
  } catch (e) {
    dispatch({
      type: GET_PENDING_RECRUITMENT_DRAFT_LIST_FAILED,
      payload: {reset:payload.page===0},
    });
  }
};