import { PureComponent } from "react";
import calculateSize from 'calculate-size';

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload, rotate } = this.props;
    let finalLabel = payload?.value || "";
    const size = calculateSize(finalLabel, {
      font: 'Poppins',
      fontSize: '14px',
    });
    return (
      <g transform={`translate(${x},${y})`}>
        {rotate
          ?
          <text x={0} y={0} dy={0} textAnchor="end" fill="#666" transform="rotate(-45)" fontWeight='bold' fontSize={13}>
            {finalLabel?.length > 15 ? finalLabel.slice(0, 12) + '...' : finalLabel}
          </text>
          :
          <text x={0} y={0} textAnchor="end" fill="#666" transform={`translate(${size.width / 2},10)`} fontWeight='bold' fontSize={13}>
            {finalLabel?.length > 15 ? finalLabel.slice(0, 12) + '...' : finalLabel}
          </text>
        }
      </g>
    );
  }
}

export default CustomizedAxisTick;