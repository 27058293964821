import { Country, State } from "country-state-city";
import _ from "lodash";
import { getValidConsultantObjects, renameKeys } from "./consultantConstants";
import moment from "moment";

export const buildContractData = (oldData = {}, newData = {}, keys = [], formOptions = {}) => {
  const newKeys = {
    effective_from: 'effectiveFrom',
    contract_status: 'contractStatus',
    entity_location: 'businessEntityLocation',
    business_unit_id: 'businessUnit',
    contractor_status: "contractorStatus",
    is_confidential: 'isConfidential',
    contractor: 'contractorName',
    fed_id: 'federalId',
    vendor_poc_name: 'vendorPOCName',
    contractor_phone: 'contractorNumber',
    contractor_email: 'contractorEmailId',
    vendor_address: 'vendorAddress',
    vendor_country_id: 'vendorCountry',
    vendor_state_id: 'vendorState',
    vendor_city_id: 'vendorCity',
    vendor_zip: 'vendorZipCode',
    diversity_status: 'diversityStatus',
    sdfs: 'supplierDiversityForm',
    has_cert_of_insurance: 'certificateOfInsurance',
    benefits: 'benefitPlan',
    start_date: 'startDate',
    tentative_end_date: 'tentativeEndDate',
    end_date: 'endDate',
    job_title: 'jobTitle',
    is_passthrough: 'passThrough',
    client_id: 'client',
    end_client_id: 'endClient',
    is_remote: 'isRemote',
    address: 'projectAddress',
    country_id: 'projectCountry',
    state_id: 'projectState',
    city_id: 'projectCity',
    domain_id: 'domain',
    client_po_end_date: 'clientPOEndDate',
    client_portal_id: 'clientPortalId',
    reporting_manager: 'clientProjectManager',
    reporting_manager_email: 'clientProjectManagerEmail',
    pay_rate_currency: 'payRateCurrency',
    pay_rate: 'payRate',
    pay_rate_basis: 'payRatePaymentBasis',
    client_rate_currency: 'clientRateCurrency',
    client_rate: 'clientRate',
    client_rate_basis: 'clientRatePaymentBasis',
    margin: 'marginValue',
    invoice_terms: 'invoicingTerm',
    payment_terms: 'paymentTerm',
    msp_fee_approved: 'mspFeeApproved',
    msp_fee: 'mspPortalFee',
    vol_discount_approved: 'volumeDiscountApproved',
    vol_discount: 'volumeDiscount',
    tenure_discount_waived: 'tenureDiscount',
    discounted_early_payment: 'earlyPaymentDiscount',
    early_payment_discount: 'earlyPaymentWaive',
    bgv_waived: 'bgvWaiver',
    resource_manager: 'clientResourceManagerName',
    resource_manager_email: 'clientResourceManagerEmail',
    track: 'trackOnlyHCL',
    sub_track: 'subTrack',
    sot_non_sot: "sotNonSot",
    rate_card_adherence: "rateCardAdherance",
    br_number: "brNumber",
  };

  let contractObj = {};
  contractObj = { ...oldData };
  keys.forEach(key => {
    contractObj[key] = newData[key];
  });
  if (_.isString(contractObj.entity)) {
    let requiredOptions = formOptions?.entityOptions || [];
    for (let i = 0; i < requiredOptions.length; i++) {
      if (requiredOptions[i].label === contractObj.entity) {
        contractObj.entity = requiredOptions[i].id;
        break;
      }
    }
  }
  if (_.isString(contractObj.entity_location)) {
    let requiredOptions = formOptions?.entityLocationOptions || [];
    for (let i = 0; i < requiredOptions.length; i++) {
      if (requiredOptions[i].label === contractObj.entity_location) {
        contractObj.entity_location = requiredOptions[i].id;
        break;
      }
    }
  }
  contractObj.is_confidential = contractObj.is_confidential ? true : false;
  if (_.isString(contractObj.duration)) {
    let requiredOptions = formOptions?.durationOptions || [];
    for (let i = 0; i < requiredOptions.length; i++) {
      if (requiredOptions[i].label === contractObj.duration) {
        contractObj.duration = requiredOptions[i].id;
        break;
      }
    }
  }
  // contractObj.tentative_end_date = contractObj.tentative_end_date ? moment(contractObj.tentative_end_date).format("YYYY-MM-DD") : null;
  // contractObj.start_date = contractObj.start_date ? moment(contractObj.start_date).format("YYYY-MM-DD") : null;
  // contractObj.end_date = contractObj.end_date ? moment(contractObj.end_date).format("YYYY-MM-DD") : null;
  contractObj.is_passthrough = contractObj.is_passthrough ? 'Yes' : 'No';
  contractObj.client_id = contractObj.client_id || "";
  contractObj.end_client_id = contractObj.end_client_id || "";
  contractObj.is_remote = contractObj.is_remote ? 'Yes' : 'No';
  contractObj.domain_id = contractObj.domain_id || "";
  // contractObj.client_po_end_date = contractObj.client_po_end_date ? moment(contractObj.client_po_end_date).format("YYYY-MM-DD") : null;
  contractObj.diversity_status = contractObj.diversity_status ? 'Yes' : 'No';
  contractObj.po_pending = contractObj.po_pending ? 'Yes' : 'No';
  contractObj.sdfs = (contractObj?.sdfs || []).length ? contractObj.sdfs.map(sdf => {
    return sdf.binding_id
  }) : [];
  contractObj.has_cert_of_insurance = contractObj.has_cert_of_insurance === null ? null : contractObj.has_cert_of_insurance ? 'Yes' : 'No';
  contractObj.insuranceEntries = (contractObj?.insuranceEntries || []).length ? contractObj.insuranceEntries.map(insurance => {
    return insurance.binding_id
  }) : [];
  contractObj.certificateOfInsuranceApprovedBy = (contractObj?.certificateOfInsuranceApprovedBy || []).length ? (contractObj.certificateOfInsuranceApprovedBy[0]?.hr_id || 1) : '';
  contractObj.benefitStatus = (contractObj?.benefits || []).length ? 'Yes' : 'No';
  contractObj.benefitPlan = (contractObj?.benefits || []).map(benefit => {
    return benefit.plan
  });
  contractObj.benefitEffectiveDate = (contractObj?.benefits || []).map(benefit => {
    return benefit.effective_from ? moment(benefit.effective_from).format("YYYY-MM-DD") : null;
  });
  contractObj.paymentTermApprovalBy = (contractObj?.paymentTermApprovalBy || []).length ? (contractObj.paymentTermApprovalBy[0]?.hr_id || 1) : '';
  contractObj.msp_fee_approved = contractObj.msp_fee_approved === null ? null : contractObj.msp_fee_approved ? 'Yes' : 'No';
  contractObj.mspPortalFeeApprovalBy = (contractObj?.mspPortalFeeApprovalBy || []).length ? (contractObj.mspPortalFeeApprovalBy[0]?.hr_id || 1) : '';
  contractObj.vol_discount_approved = contractObj.vol_discount_approved === null ? null : contractObj.vol_discount_approved ? 'Yes' : 'No';
  contractObj.volumeDiscountApprovalBy = (contractObj?.volumeDiscountApprovalBy || []).length ? (contractObj.volumeDiscountApprovalBy[0]?.hr_id || 1) : '';
  contractObj.tenure_discount_waived = contractObj?.tenure_discount_waived ? 'Yes' : 'No';
  contractObj.tenureDiscountApprovalBy = (contractObj?.tenureDiscountApprovalBy || []).length ? (contractObj.tenureDiscountApprovalBy[0]?.hr_id || 1) : '';
  contractObj.discounted_early_payment = contractObj.discounted_early_payment === null ? null : contractObj.discounted_early_payment ? 'Yes' : 'No';
  contractObj.earlyPaymentDiscountApprovalBy = (contractObj?.earlyPaymentDiscountApprovalBy || []).length ? (contractObj.earlyPaymentDiscountApprovalBy[0]?.hr_id || 1) : '';
  contractObj.bgv_waived = contractObj?.bgv_waived ? 'Yes' : 'No';
  contractObj.bgvApprovalBy = (contractObj?.bgvApprovalBy || []).length ? (contractObj.bgvApprovalBy[0]?.hr_id || 1) : '';
  contractObj.recruiterIds = contractObj.recruiterIds.map(item => item.hr_id);
  contractObj.coeIds = contractObj.coeIds.map(item => item.hr_id);
  contractObj.sourcingTeamIds = contractObj.sourcingTeamIds.map(item => item.hr_id);
  contractObj.ptOwnerIds = contractObj.ptOwnerIds.map(item => item.hr_id);
  contractObj.ptSupportIds = contractObj.ptSupportIds.map(item => item.hr_id);
  contractObj.tlIds = contractObj.tlIds.map(item => item.hr_id);
  contractObj.dalIds = contractObj.dalIds.map(item => item.hr_id);
  contractObj.dmIds = contractObj.dmIds.map(item => item.hr_id);
  contractObj.addIds = contractObj.addIds.map(item => item.hr_id);
  contractObj.admId = contractObj.admId.map(item => item.hr_id);
  contractObj.atlIds = contractObj.atlIds.map(item => item.hr_id);
  let finalObj = renameKeys(contractObj, newKeys);
  let finalData = getValidConsultantObjects(finalObj);
  return finalData;
}

export const ensureWorkAuthOptions = (arrayName, array) => {
  const modifiedArray =
    array &&
    array.length > 0 &&
    array.map((arr) => {
      // Remove spaces from valus
      const removeSpace = arr.split(" ").join("-").toLowerCase();
      return {
        value: removeSpace,
        label: arr,
      };
    });
  return modifiedArray;
};
export const ensureDurationOptions = (arrayName, array) => {
  const modifiedArray =
    array &&
    array.length > 0 &&
    array.map((arr) => {
      // Remove spaces from valus
      const removeSpace = arr.split(" ").join("-").toLowerCase();
      return {
        value: removeSpace,
        label: arr,
      };
    });
  return modifiedArray;
};

export const ensureSelectableOptions = (array) => {
  const allSelectOptions = {};
  for (var key in array) {
    const removeBlankOptions =
      array[key] &&
      array[key].length > 0 &&
      array[key].filter(
        (arr) => arr && arr !== "" && arr !== undefined && arr !== null
      );
    const modifiedArray =
      removeBlankOptions &&
      removeBlankOptions.length > 0 &&
      removeBlankOptions.map((arr) => {
        // Remove spaces from values
        if (arr) {
          return {
            value: arr?.options || arr?.value || arr,
            label: arr?.options || arr?.label || arr,
          };
        }
      });
    allSelectOptions[key] = modifiedArray || [];
  }
  return allSelectOptions;
};

export const getCountryByCode = (value = "") => {
  var c = Country.getCountryByCode(value);
  return c?.name || value;
}

export const getStateByCode = (value1 = "", value2 = "") => {
  var s = State.getStateByCodeAndCountry(value2, value1);
  return s?.name || value2;
}

export const capitalizeString = (s) => {
  return s && s[0].toUpperCase() + s.slice(1);
}

export const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
