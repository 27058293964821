import { CREATE_DASHBOARD_USER_FAILED, CREATE_DASHBOARD_USER_IN_PROGRESS, CREATE_DASHBOARD_USER_SUCCESS, CREATE_REPORT_GROUP_FAILED, CREATE_REPORT_GROUP_IN_PROGRESS, CREATE_REPORT_GROUP_SUCCESS, GET_DASHBOARD_CARD_INFO_FAILED, GET_DASHBOARD_CARD_INFO_IN_PROGRESS, GET_DASHBOARD_CARD_INFO_SUCCESS, GET_DASHBOARD_DROPDOWN_OPTIONS_FAILED, GET_DASHBOARD_DROPDOWN_OPTIONS_IN_PROGRESS, GET_DASHBOARD_DROPDOWN_OPTIONS_SUCCESS, GET_DASHBOARD_USERS_FAILED, GET_DASHBOARD_USERS_IN_PROGRESS, GET_DASHBOARD_USERS_SUCCESS, GET_REPORT_DASHBOARDS_FAILED, GET_REPORT_DASHBOARDS_IN_PROGRESS, GET_REPORT_DASHBOARDS_SUCCESS, GET_REPORT_DASHBOARD_INFO_FAILED, GET_REPORT_DASHBOARD_INFO_IN_PROGRESS, GET_REPORT_DASHBOARD_INFO_SUCCESS, GET_REPORT_GROUPS_FAILED, GET_REPORT_GROUPS_IN_PROGRESS, GET_REPORT_GROUPS_SUCCESS, GET_REPORT_GROUP_INFO_FAILED, GET_REPORT_GROUP_INFO_IN_PROGRESS, GET_REPORT_GROUP_INFO_SUCCESS, GET_REPORT_USER_DASHBOARDS_FAILED, GET_REPORT_USER_DASHBOARDS_IN_PROGRESS, GET_REPORT_USER_DASHBOARDS_SUCCESS, STORE_DASHBOARD_FILTER_FAILED, STORE_DASHBOARD_FILTER_IN_PROGRESS, STORE_DASHBOARD_FILTER_SUCCESS } from "../../constants/store";
import api from "../../config/https";
import axios from "axios";

export const getUserDashboards = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORT_USER_DASHBOARDS_IN_PROGRESS,
    });
    let res = await api.get('masterAdmin/metabase/getDashboardForUser');
    if (res?.data?.status) {
      dispatch({
        type: GET_REPORT_USER_DASHBOARDS_SUCCESS,
        payload: {
          list: res?.data?.data || [],
        }
      });
    } else {
      dispatch({
        type: GET_REPORT_USER_DASHBOARDS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: GET_REPORT_DASHBOARDS_FAILED,
    });
  }
};

export const getDashboards = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORT_DASHBOARDS_IN_PROGRESS,
    });
    let res = await api.get('masterAdmin/metabase/dashboard');
    if (res?.data?.status) {
      dispatch({
        type: GET_REPORT_DASHBOARDS_SUCCESS,
        payload: {
          list: res?.data?.data || [],
        }
      });
    } else {
      dispatch({
        type: GET_REPORT_DASHBOARDS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: GET_REPORT_DASHBOARDS_FAILED,
    });
  }
};

export const getDashboardById = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORT_DASHBOARD_INFO_IN_PROGRESS,
    });
    let res = await api.get(`masterAdmin/metabase/dashboard/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_REPORT_DASHBOARD_INFO_SUCCESS,
        payload: {
          info: res?.data?.data || null,
          id: payload.id,
        }
      });
    } else {
      dispatch({
        type: GET_REPORT_DASHBOARD_INFO_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_REPORT_DASHBOARD_INFO_FAILED,
    });
  }
};

export const getDashboardCardById = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_CARD_INFO_IN_PROGRESS,
      payload: {
        cardId: payload.card_id,
      }
    });
    let res = await api.post(`masterAdmin/metabase/card`, payload);
    let data = res?.data?.data;
    if (res?.data?.status && data.status === 'completed') {
      dispatch({
        type: GET_DASHBOARD_CARD_INFO_SUCCESS,
        payload: {
          cardInfo: { ...data, timestamp: Date.now() },
          cardId: payload.card_id,
        }
      });
    } else {
      dispatch({
        type: GET_DASHBOARD_CARD_INFO_FAILED,
        payload: {
          cardId: payload.card_id,
        }
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_CARD_INFO_FAILED,
      payload: {
        cardId: payload.card_id,
      }
    });
    console.log(error);
  }
};

export const getReportGroups = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORT_GROUPS_IN_PROGRESS,
    });
    let res = await api.get('masterAdmin/user/getAllGroup');
    if (res?.data?.status) {
      dispatch({
        type: GET_REPORT_GROUPS_SUCCESS,
        payload: {
          list: res?.data?.data || [],
        }
      });
    } else {
      dispatch({
        type: GET_REPORT_GROUPS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: GET_REPORT_GROUPS_FAILED,
    });
  }
};

export const addNewReportGroup = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_REPORT_GROUP_IN_PROGRESS,
    });
    let res = await api.post('masterAdmin/user/createGroup', payload);
    if (res?.data?.status) {
      dispatch({
        type: CREATE_REPORT_GROUP_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_REPORT_GROUP_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: CREATE_REPORT_GROUP_FAILED,
    });
  }
};

export const deleteReportGroup = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_REPORT_GROUP_IN_PROGRESS,
    });
    let res = await api.delete(`masterAdmin/user/deleteGroup/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: CREATE_REPORT_GROUP_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_REPORT_GROUP_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: CREATE_REPORT_GROUP_FAILED,
    });
  }
};

export const getReportGroupById = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REPORT_GROUP_INFO_IN_PROGRESS,
    });
    let res = await api.get(`masterAdmin/user/getDashboardPermissionForGroup/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_REPORT_GROUP_INFO_SUCCESS,
        payload: {
          info: res?.data?.data || null,
        }
      });
    } else {
      dispatch({
        type: GET_REPORT_GROUP_INFO_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_REPORT_GROUP_INFO_FAILED,
    });
  }
};

export const updateReportGroupDashboardPermissions = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_REPORT_GROUP_IN_PROGRESS,
    });
    let res = await api.post(`masterAdmin/user/addOrEditDashboardPermissionToGroup`, payload);
    if (res?.data?.status) {
      dispatch({
        type: CREATE_REPORT_GROUP_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_REPORT_GROUP_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: CREATE_REPORT_GROUP_FAILED,
    });
  }
};

export const getDashboardUsers = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_USERS_IN_PROGRESS,
    });
    let res = await api.get(`masterAdmin/user?status=true&group_id=${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_DASHBOARD_USERS_SUCCESS,
        payload: {
          list: res?.data?.data?.data || [],
        }
      });
    } else {
      dispatch({
        type: GET_DASHBOARD_USERS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: GET_DASHBOARD_USERS_FAILED,
    });
  }
};

export const getUserList = async (payload) => {
  try {
    let res = await api.get(`masterAdmin/user?status=true&search=${payload.searchText}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const createDashboardUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_DASHBOARD_USER_IN_PROGRESS,
    });
    let res = await api.post(`masterAdmin/user/addUserToGroup`, payload);
    if (res?.data?.status) {
      dispatch({
        type: CREATE_DASHBOARD_USER_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_DASHBOARD_USER_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: CREATE_DASHBOARD_USER_FAILED,
    });
  }
};

export const deleteDashboardUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_DASHBOARD_USER_IN_PROGRESS,
    });
    let res = await api.delete(`masterAdmin/user/removeUserFromGroup`, { data: payload });
    if (res?.data?.status) {
      dispatch({
        type: CREATE_DASHBOARD_USER_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_DASHBOARD_USER_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    dispatch({
      type: CREATE_DASHBOARD_USER_FAILED,
    });
  }
};

export const getOptions = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DASHBOARD_DROPDOWN_OPTIONS_IN_PROGRESS,
    });
    let res = await api.get(`masterAdmin/metabase/dashboard/${payload.dashboard_id}/params/${payload.option_id}/values`);
    if (res?.data?.status) {
      dispatch({
        type: GET_DASHBOARD_DROPDOWN_OPTIONS_SUCCESS,
        payload: {
          options: res?.data?.data?.values || [],
          id: payload.option_id,
        }
      });
    } else {
      dispatch({
        type: GET_DASHBOARD_DROPDOWN_OPTIONS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_DASHBOARD_DROPDOWN_OPTIONS_FAILED,
    });
  }
};

export const storeDashboardFilter = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: STORE_DASHBOARD_FILTER_IN_PROGRESS,
    });
    if (payload?.id) {
      dispatch({
        type: STORE_DASHBOARD_FILTER_SUCCESS,
        payload: {
          filters: payload.filters,
          id: payload.id,
        }
      });
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: STORE_DASHBOARD_FILTER_FAILED,
    });
  }
};