import { useEffect, useState } from "react";
import AppBar from "../../../components/AppBar";
import { settingsOptions as settingsArray } from "../../../constants/config";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getDownloadReportsActionsNew } from "../../../store/actions/DownloadReportActions";
import DownloadIcon from "@mui/icons-material/Download";
import { BarLoader } from "react-spinners";
import { DataGrid } from "@mui/x-data-grid";
import ExportCustomReportModal from "./ExportConfiguration";
import _ from "lodash";
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

export const filterConstantOptions = (optionName) => {
  const isConstant =
    settingsArray &&
    settingsArray.filter((setting) => setting.apiFieldName === optionName);
  return isConstant?.[0].isConstant || false;
};

export default function MasterExportsScreen(props) {
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: getCurrentPageIdentifier() });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  const downloadReports = useSelector((state) => state.downloadReports.reports);
  const loading = useSelector((state) => state.downloadReports.loading);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getDownloadReportsActionsNew());
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const emptyOrNull = (downloadReports || [])?.filter(
        (report) =>
          !report.filename ||
          report.filename === "" ||
          report.export_status === 0 ||
          report.export_status === 2
      );
      if (emptyOrNull?.length > 0) {
        dispatch(getDownloadReportsActionsNew());
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [downloadReports]);

  const handleShowCustom = () => {
    setOpen(true);
  }

  const handleClose = (val = null) => {
    setOpen(false);
    if (val) {
      dispatch(getDownloadReportsActionsNew());
      setTimeout(() => {
        dispatch(getDownloadReportsActionsNew());
      }, 2000);
    }
  }

  return (
    <Box sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <AppBar appBarTitle='Exports' >
        {hasWritePermission ?
          <>
            <Button id='export-btn' variant="contained" onClick={handleShowCustom}>Export</Button>
          </>
          :
          null
        }
      </AppBar>
      <Box
        sx={{
          background: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 2,
          mt: 2,
          width: "100%",
          position: 'relative',
          pb: 2,
          height: 'calc(100svh - 120px)'
        }}
      >
        {downloadReports?.length ?
          <DataGrid
            rows={downloadReports}
            columns={[
              {
                field: "created_at",
                headerName: "Requested on",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <>
                      {params?.value &&
                        moment
                          .utc(params?.value)
                          .local()
                          .format("MMM, DD YYYY hh:mm A")}
                    </>
                  );
                },
              },
              {
                field: "start_date",
                headerName: "Start Date",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <>
                      {params?.value &&
                        moment.utc(params?.value).format("MMM, DD YYYY")}
                    </>
                  );
                },
              },
              {
                field: "end_date",
                headerName: "End Date",
                flex: 1,
                renderCell: (params) => {
                  return (
                    <>
                      {params?.value &&
                        moment.utc(params?.value).format("MMM, DD YYYY")}
                    </>
                  );
                },
              },
              {
                field: "export_status",
                headerName: "Status",
                flex: 1,
                renderCell: (params) => {
                  const rows = params.row;
                  return (
                    <>
                      {params?.value === 0 ? (
                        <>
                          <BarLoader color='#10316B' width={135} />
                        </>
                      ) : params?.value === 1 ? (
                        <>
                          {hasWritePermission ?
                            <Button
                              id={`download-btn-${rows?.id}`}
                              style={{
                                color: "#10316B",
                                fontSize: 14,
                              }}
                              onClick={() =>
                                window.open(
                                  `${params?.row?.filename}`,
                                  "_blank"
                                )
                              }
                              variant='outlined'
                              endIcon={<DownloadIcon />}
                            >
                              Download
                            </Button>
                            :
                            null
                          }
                        </>
                      ) : params?.value === 2 ? (
                        <>Report Requested</>
                      ) : (
                        <>Report Request Failed</>
                      )}
                    </>
                  );
                },
              },
            ]}
            pagination
            pageSize={50}
            rowsPerPageOptions={[50]}
            disableSelectionOnClick
            sx={{
              px: 2,
              border: 0,
              height: '100%',
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "#1089FF",
              },
              "& .MuiDataGrid-cell": {
                margin: 0,
                border: 0,
                width: 300,
              },
              "& .MuiDataGrid-row": {
                margin: 0,
              },
              "& .MuiDataGrid-iconSeparator": {
                display: "none",
              },
            }}
          />
          :
          <NoExports loading={loading} />
        }
      </Box>
      {open ?
        <ExportCustomReportModal open={open} onClose={handleClose} />
        :
        null
      }
    </Box>
  );
}

const NoExports = ({ loading = false }) => {
  return (
    <Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', borderRadius: 2, p: 2, mt: 2 }}>
      <Box sx={{ width: 35, height: 35, color: '#475569' }}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        {loading ? <CircularProgress size={20} color="primary" /> : null}
        <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{loading ? `Retrieving previous exported reports...` : `No exported reports found`}</Typography>
      </Box>
    </Box>
  )
}
