import {
  CREATE_SETTING_CONSTANTS_IN_PROGRESS,
  CREATE_SETTING_CONSTANTS_SUCCESS,
  CREATE_SETTING_CONSTANTS_FAILED,
  UPDATE_SETTING_CONSTANTS_IN_PROGRESS,
  UPDATE_SETTING_CONSTANTS_SUCCESS,
  UPDATE_SETTING_CONSTANTS_FAILED,
  DELETE_SETTING_CONSTANTS_IN_PROGRESS,
  DELETE_SETTING_CONSTANTS_SUCCESS,
  DELETE_SETTING_CONSTANTS_FAILED,
  GET_SETTING_CONSTANTS_IN_PROGRESS,
  GET_SETTING_CONSTANTS_SUCCESS,
  GET_SETTING_CONSTANTS_FAILED,
  GET_SETTING_VENDOR_FAILED,
  GET_SETTING_VENDOR_SUCCESS,
  GET_SETTING_VENDOR_IN_PROGRESS,
  GET_VENDOR_SETTING_CONSTANTS_IN_PROGRESS,
  GET_VENDOR_SETTING_CONSTANTS_SUCCESS,
  GET_VENDOR_SETTING_CONSTANTS_FAILED,
  GET_SETTING_AGENCIES_SUCCESS,
  GET_SETTING_AGENCIES_IN_PROGRESS,
  GET_SETTING_AGENCIES_FAILED,
  GET_SETTING_CONSTANTS2_FAILED,
  GET_SETTING_CONSTANTS2_IN_PROGRESS,
  GET_SETTING_CONSTANTS2_SUCCESS,
  GET_VENDOR_SETTING_CONSTANTS2_SUCCESS,
  GET_VENDOR_SETTING_CONSTANTS2_IN_PROGRESS,
  GET_VENDOR_SETTING_CONSTANTS2_FAILED,
  GET_CONFIGURATION_FIELDS_IN_PROGRESS,
  GET_CONFIGURATION_FIELDS_SUCCESS,
  GET_CONFIGURATION_FIELDS_FAILED,
  CONFIGURATION_FIELD_IN_PROGRESS,
  CONFIGURATION_FIELD_SUCCESS,
  CONFIGURATION_FIELD_FAILED,
  GET_CONFIGURATION_GROUPS_IN_PROGRESS,
  GET_CONFIGURATION_GROUPS_SUCCESS,
  GET_CONFIGURATION_GROUPS_FAILED,
  CONFIGURATION_GROUP_IN_PROGRESS,
  CONFIGURATION_GROUP_SUCCESS,
  CONFIGURATION_GROUP_FAILED,
  CONFIGURATION_USER_IN_PROGRESS,
  CONFIGURATION_USER_SUCCESS,
  CONFIGURATION_USER_FAILED,
  GET_CONFIGURATION_USERS_FAILED,
  GET_CONFIGURATION_USERS_SUCCESS,
  GET_CONFIGURATION_USERS_IN_PROGRESS,
  GET_FORM_CONFIGURATIONS_IN_PROGRESS,
  GET_FORM_CONFIGURATIONS_SUCCESS,
  GET_FORM_CONFIGURATIONS_FAILED,
  FORM_CONFIGURATION_IN_PROGRESS,
  FORM_CONFIGURATION_SUCCESS,
  FORM_CONFIGURATION_FAILED,
  GET_ALL_FORM_CONFIGURATIONS_IN_PROGRESS,
  GET_ALL_FORM_CONFIGURATIONS_SUCCESS,
  GET_ALL_FORM_CONFIGURATIONS_FAILED,
  GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_IN_PROGRESS,
  GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_SUCCESS,
  GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_FAILED,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_IN_PROGRESS,
  GET_REGIONS_FAILED,
  REGION_OPERATION_IN_PROGRESS,
  REGION_OPERATION_SUCCESS,
  REGION_OPERATION_FAILED,
  GET_REGION_USERS_IN_PROGRESS,
  GET_REGION_USERS_FAILED,
  GET_REGION_USERS_SUCCESS,
} from "../../constants/store";
import api from "../../config/https";
import { capitalizeString } from "../../utils/ensureSelectOptions";

export const settingListLimit = 50;

export const createSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let urlPath = (payload?.type || "").includes('constant') ? 'constant' : payload?.type;
    let data = (payload?.type || "").includes('constant') ? { ...payload.data, fieldType: (payload.type || "").replace('constant?fieldType=', '') } : payload.data;
    if (payload?.callName === 'End Client') {
      data = {
        ...data,
        is_end_client: true
      }
    }
    let res = await api.post("/masterAdmin/" + urlPath, data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const updateSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let urlPath = (payload?.type || "").includes('constant') ? 'constant' : payload?.type;
    let data = (payload?.type || "").includes('constant') ? { ...payload.data, fieldType: (payload.type || "").replace('constant?fieldType=', '') } : payload.data;
    let res = await api.put("/masterAdmin/" + urlPath, data);
    if (res?.data?.status) {
      dispatch({
        type: UPDATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: UPDATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: UPDATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const deleteSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let urlPath = (payload?.type || "").includes('constant') ? 'constant' : payload?.type;
    let data = (payload?.type || "").includes('constant') ? { ...payload.data, fieldType: (payload.type || "").replace('constant?fieldType=', '') } : payload.data;
    let res = await api.delete("/masterAdmin/" + urlPath, { data: data });
    if (res?.data?.status) {
      dispatch({
        type: DELETE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: DELETE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: DELETE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const mergeSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let urlPath = (payload?.type || "").includes('constant') ? 'constantMerge' : "merge" + capitalizeString(payload?.type || "");
    let data = (payload?.type || "").includes('constant') ? { ...payload.data, fieldType: (payload.type || "").replace('constant?fieldType=', '') } : payload.data;
    let res = await api.put("/masterAdmin/" + urlPath, data);
    if (res?.data?.status) {
      dispatch({
        type: UPDATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: UPDATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: UPDATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const getSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let path = `${payload.type}?offset=${payload.page * settingListLimit}&limit=${settingListLimit}&search=${payload.searchText}`;
    if (payload.type.includes('?')) {
      path = `${payload.type}&offset=${payload.page * settingListLimit}&limit=${settingListLimit}&search=${payload.searchText}`;
    }
    let res = await api.get(`/masterAdmin/` + path);
    if (res?.data?.status) {
      const data = res?.data?.data;
      const list = data?.data || [];
      dispatch({
        type: GET_SETTING_CONSTANTS_SUCCESS,
        payload: {
          list: list,
          maxPage: data?.totalCount || list.length,
          currentPage: payload.page,
        }
      });
    } else {
      dispatch({
        type: GET_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const getSettingConstantsAction2 = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SETTING_CONSTANTS2_IN_PROGRESS,
    });
    let path = `${payload.type}?offset=${payload.page * settingListLimit}&limit=${settingListLimit}&search=${payload.searchText}`;
    if (payload.type.includes('?')) {
      path = `${payload.type}&offset=${payload.page * settingListLimit}&limit=${settingListLimit}&search=${payload.searchText}`;
    }
    let res = await api.get(`/masterAdmin/` + path);
    if (res?.data?.status) {
      const data = res?.data?.data;
      const list = data?.data || [];
      dispatch({
        type: GET_SETTING_CONSTANTS2_SUCCESS,
        payload: {
          list: list,
          maxPage: data?.totalCount || list.length,
          currentPage: payload.page,
        }
      });
    } else {
      dispatch({
        type: GET_SETTING_CONSTANTS2_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_SETTING_CONSTANTS2_FAILED,
    });
  }
};

export const createSettingVendorsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let res = await api.post("/masterAdmin/vendor", payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const updateSettingVendorsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let res = await api.put("/masterAdmin/vendor", payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const mergeSettingVendorsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let res = await api.post("/masterAdmin/mergeVendors", payload);
    if (res && res.data && res.data.status) {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const getSettingVendorsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SETTING_VENDOR_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/vendor?offset=${payload.page * settingListLimit}&limit=${settingListLimit}&vendorName=${payload.searchText}`);
    if (res?.data?.status) {
      const data = res?.data?.data;
      const list = data?.data || [];
      dispatch({
        type: GET_SETTING_VENDOR_SUCCESS,
        payload: {
          list: list,
          maxPage: data?.totalCount || list.length,
          currentPage: payload.page,
        }
      });
    } else {
      dispatch({
        type: GET_SETTING_VENDOR_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_SETTING_VENDOR_FAILED,
    });
  }
};

export const createVendorSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let res = await api.post("/masterAdmin/" + payload.type, payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: CREATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CREATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const updateVendorSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let res = await api.put("/masterAdmin/" + payload.type, payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: UPDATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: UPDATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: UPDATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const deleteVendorSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let res = await api.delete("/masterAdmin/" + payload.type, payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: DELETE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: DELETE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: DELETE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const mergeVendorSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let urlPath = "merge" + capitalizeString(payload?.type || "");
    let res = await api.put("/masterAdmin/" + urlPath, payload.data);
    if (res && res.data && res.data.status) {
      dispatch({
        type: UPDATE_SETTING_CONSTANTS_SUCCESS,
      });
    } else {
      dispatch({
        type: UPDATE_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: UPDATE_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const getVendorSettingConstantsAction = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VENDOR_SETTING_CONSTANTS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/${payload.type}?vendorID=${payload.id}&offset=${payload.page * settingListLimit}&limit=${settingListLimit}&search=${payload.searchText}`);
    if (res?.data?.status) {
      const data = res?.data?.data;
      const list = data?.data || [];
      dispatch({
        type: GET_VENDOR_SETTING_CONSTANTS_SUCCESS,
        payload: {
          list: list,
          maxPage: data?.totalCount || list.length,
          currentPage: payload.page,
        }
      });
    } else {
      dispatch({
        type: GET_VENDOR_SETTING_CONSTANTS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_VENDOR_SETTING_CONSTANTS_FAILED,
    });
  }
};

export const getVendorMergeList = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VENDOR_SETTING_CONSTANTS2_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/vendor?offset=${payload.page * settingListLimit}&limit=${settingListLimit}&vendorName=${payload.searchText}`);
    if (res?.data?.status) {
      const data = res?.data?.data;
      const list = data?.data || [];
      dispatch({
        type: GET_VENDOR_SETTING_CONSTANTS2_SUCCESS,
        payload: {
          list: list,
          maxPage: data?.totalCount || list.length,
          currentPage: payload.page,
        }
      });
    } else {
      dispatch({
        type: GET_VENDOR_SETTING_CONSTANTS2_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_VENDOR_SETTING_CONSTANTS2_FAILED,
    });
  }
};

export const getVendorSettingConstantsAction2 = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_VENDOR_SETTING_CONSTANTS2_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/${payload.type}?vendorID=${payload.id}&offset=${payload.page * settingListLimit}&limit=${settingListLimit}&search=${payload.searchText}`);
    if (res?.data?.status) {
      const data = res?.data?.data;
      const list = data?.data || [];
      dispatch({
        type: GET_VENDOR_SETTING_CONSTANTS2_SUCCESS,
        payload: {
          list: list,
          maxPage: data?.totalCount || list.length,
          currentPage: payload.page,
        }
      });
    } else {
      dispatch({
        type: GET_VENDOR_SETTING_CONSTANTS2_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_VENDOR_SETTING_CONSTANTS2_FAILED,
    });
  }
};

export const getSettingAgencies = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SETTING_AGENCIES_IN_PROGRESS,
    });
    let urlPath = payload.type === 'insurance' ? 'agency' : 'sdf_agency';
    let res = await api.get(`/masterAdmin/constant?fieldType=${urlPath}&search=${payload.searchText}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_SETTING_AGENCIES_SUCCESS,
        payload: res?.data?.data || []
      });
    } else {
      dispatch({
        type: GET_SETTING_AGENCIES_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_SETTING_AGENCIES_FAILED,
    });
  }
};

export const getsdf = async (payload) => {
  try {
    let res = await api.get(`/masterAdmin/sdf?vendorID=${payload}`)
    if (res && res.data && res.data.status) {
      return res?.data
    }
  } catch (e) {
    console.log(e)
  }
}

export const getInsurance = async (payload) => {
  try {
    let res = await api.get(`/masterAdmin/insurance?vendorID=${payload}`)
    if (res && res.data && res.data.status) {
      return res?.data
    }
  } catch (e) {
    console.log(e)
  }
}

export const getlogs = async (startdate, enddate) => {
  try {
    let res = await api.get(`/masterAdmin/log/settings/elastic?date_range_start=${startdate}&date_range_end=${enddate}`)
    return res?.data?.data;
  } catch (e) {
    console.log(e)
  }
}

// INPUT DESIGNER

export const getConfigurationFields = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONFIGURATION_FIELDS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/getConfiguratorField`);
    if (res?.data?.status) {
      dispatch({
        type: GET_CONFIGURATION_FIELDS_SUCCESS,
        payload: res?.data?.data || []
      });
    } else {
      dispatch({
        type: GET_CONFIGURATION_FIELDS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_CONFIGURATION_FIELDS_FAILED,
    });
  }
};

export const createConfigurationField = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_FIELD_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/createConfiguratorField`, payload);
    if (res?.data?.status) {
      dispatch({
        type: CONFIGURATION_FIELD_SUCCESS,
      });
    } else {
      dispatch({
        type: CONFIGURATION_FIELD_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONFIGURATION_FIELD_FAILED,
    });
  }
};

export const updateConfigurationField = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_FIELD_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/updateConfiguratorField/${payload.id}`, payload);
    if (res?.data?.status) {
      dispatch({
        type: CONFIGURATION_FIELD_SUCCESS,
      });
    } else {
      dispatch({
        type: CONFIGURATION_FIELD_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONFIGURATION_FIELD_FAILED,
    });
  }
};

export const deleteConfigurationField = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_FIELD_IN_PROGRESS,
    });
    let res = await api.delete(`/masterAdmin/deleteConfiguratorField/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: CONFIGURATION_FIELD_SUCCESS,
      });
    } else {
      dispatch({
        type: CONFIGURATION_FIELD_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONFIGURATION_FIELD_FAILED,
    });
  }
};


// FORM DESIGNER

export const getConfigurationGroups = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONFIGURATION_GROUPS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/user/getAllUserGroupConfigurator?search=${payload?.searchText || ""}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_CONFIGURATION_GROUPS_SUCCESS,
        payload: res?.data?.data || []
      });
    } else {
      dispatch({
        type: GET_CONFIGURATION_GROUPS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_CONFIGURATION_GROUPS_FAILED,
    });
  }
};

export const createConfigurationGroup = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_GROUP_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/user/createGroupConfigurator`, payload);
    if (res?.data?.status) {
      dispatch({
        type: CONFIGURATION_GROUP_SUCCESS,
      });
    } else {
      dispatch({
        type: CONFIGURATION_GROUP_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONFIGURATION_GROUP_FAILED,
    });
  }
};

export const updateConfigurationGroup = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_GROUP_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/user/editGroupConfigurator/${payload.id}`, payload.data);
    if (res?.data?.status) {
      dispatch({
        type: CONFIGURATION_GROUP_SUCCESS,
      });
    } else {
      dispatch({
        type: CONFIGURATION_GROUP_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONFIGURATION_GROUP_FAILED,
    });
  }
};

export const deleteConfigurationGroup = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_GROUP_IN_PROGRESS,
    });
    let res = await api.delete(`/masterAdmin/user/deleteUserGroupConfigurator/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: CONFIGURATION_GROUP_SUCCESS,
      });
    } else {
      dispatch({
        type: CONFIGURATION_GROUP_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONFIGURATION_GROUP_FAILED,
    });
  }
};

export const getAllConfigurations = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_FORM_CONFIGURATIONS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/user/getAllConfigurationOfGroupConfigurator`);
    if (res?.data?.status) {
      dispatch({
        type: GET_ALL_FORM_CONFIGURATIONS_SUCCESS,
        payload: res?.data?.data || []
      });
    } else {
      dispatch({
        type: GET_ALL_FORM_CONFIGURATIONS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_ALL_FORM_CONFIGURATIONS_FAILED,
    });
  }
};

export const getConfigurationByGroupID = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_FORM_CONFIGURATIONS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/user/getConfigurationOfGroupConfiguratorByGroupId/${payload.group_id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_FORM_CONFIGURATIONS_SUCCESS,
        payload: res?.data?.data || []
      });
    } else {
      dispatch({
        type: GET_FORM_CONFIGURATIONS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_FORM_CONFIGURATIONS_FAILED,
    });
  }
};

export const addConfigurationToGroup = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: FORM_CONFIGURATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/user/addConfigurationToGroupConfigurator`, payload);
    if (res?.data?.status) {
      dispatch({
        type: FORM_CONFIGURATION_SUCCESS,
      });
    } else {
      dispatch({
        type: FORM_CONFIGURATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: FORM_CONFIGURATION_FAILED,
    });
  }
};

export const updateConfigurationOfGroup = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: FORM_CONFIGURATION_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/user/editConfigurationToGroupConfigurator/${payload.id}`, payload.data);
    if (res?.data?.status) {
      dispatch({
        type: FORM_CONFIGURATION_SUCCESS,
      });
    } else {
      dispatch({
        type: FORM_CONFIGURATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: FORM_CONFIGURATION_FAILED,
    });
  }
};

export const getConfigurationUsers = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CONFIGURATION_USERS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/user/getAllUserOfGroupConfigurator/${payload.group_id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_CONFIGURATION_USERS_SUCCESS,
        payload: res?.data?.data || []
      });
    } else {
      dispatch({
        type: GET_CONFIGURATION_USERS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_CONFIGURATION_USERS_FAILED,
    });
  }
};

export const addConfigurationUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_USER_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/user/addUserToGroupConfigurator`, payload);
    if (res?.data?.status) {
      dispatch({
        type: CONFIGURATION_USER_SUCCESS,
      });
    } else {
      dispatch({
        type: CONFIGURATION_USER_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONFIGURATION_USER_FAILED,
    });
  }
};

export const deleteConfigurationUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: CONFIGURATION_USER_IN_PROGRESS,
    });
    let res = await api.delete(`/masterAdmin/user/removeUserFromGroupConfigurator`, { data: payload });
    if (res?.data?.status) {
      dispatch({
        type: CONFIGURATION_USER_SUCCESS,
      });
    } else {
      dispatch({
        type: CONFIGURATION_USER_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: CONFIGURATION_USER_FAILED,
    });
  }
};

export const getAllRegionConfigurations = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/user/getAllConfigurationOfGroupConfiguratorWithoutRegion`);
    if (res?.data?.status) {
      dispatch({
        type: GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_SUCCESS,
        payload: res?.data?.data || []
      });
    } else {
      dispatch({
        type: GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_FAILED,
    });
  }
};

export const createRegion = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: REGION_OPERATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/region/create`, payload);
    if (res?.data?.status) {
      dispatch({
        type: REGION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: REGION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: REGION_OPERATION_FAILED,
    });
  }
};

export const getRegions = (callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REGIONS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/region`);
    if (res?.data?.status) {
      dispatch({
        type: GET_REGIONS_SUCCESS,
        payload: res?.data?.data?.regions || []
      });
    } else {
      dispatch({
        type: GET_REGIONS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_REGIONS_FAILED,
    });
  }
};

export const updateRegion = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: REGION_OPERATION_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/region/update/${payload.id}`, payload.data);
    if (res?.data?.status) {
      dispatch({
        type: REGION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: REGION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: REGION_OPERATION_FAILED,
    });
  }
};

export const getRegionUsers = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: GET_REGION_USERS_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/region/getAllUser/${payload.id}`);
    if (res?.data?.status) {
      dispatch({
        type: GET_REGION_USERS_SUCCESS,
        payload: res?.data?.data?.users || []
      });
    } else {
      dispatch({
        type: GET_REGION_USERS_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: GET_REGION_USERS_FAILED,
    });
  }
};

export const addRegionnUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: REGION_OPERATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/region/addUser`, payload);
    if (res?.data?.status) {
      dispatch({
        type: REGION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: REGION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: REGION_OPERATION_FAILED,
    });
  }
};

export const deleteRegionUser = (payload, callback) => async (dispatch) => {
  try {
    dispatch({
      type: REGION_OPERATION_IN_PROGRESS,
    });
    let res = await api.post(`/masterAdmin/region/removeUser`, payload);
    if (res?.data?.status) {
      dispatch({
        type: REGION_OPERATION_SUCCESS,
      });
    } else {
      dispatch({
        type: REGION_OPERATION_FAILED,
      });
    }
    if (callback) {
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: REGION_OPERATION_FAILED,
    });
  }
};