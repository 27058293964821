import { Fragment, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import AppBar from "../../../components/AppBar";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Tabs, Tab, Breadcrumbs, Button, Chip, CircularProgress, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { renameKeys, settingsOptions, vendorPill, vendorSettingsOptions } from "../../../constants/config";
import { useDispatch, useSelector } from 'react-redux';
import { createSettingConstantsAction, createSettingVendorsAction, createVendorSettingConstantsAction, deleteSettingConstantsAction, deleteVendorSettingConstantsAction, getSettingConstantsAction, getSettingVendorsAction, getVendorSettingConstantsAction, mergeSettingConstantsAction, mergeSettingVendorsAction, mergeVendorSettingConstantsAction, settingListLimit, updateSettingConstantsAction, updateSettingVendorsAction, updateVendorSettingConstantsAction } from "../../../store/Settings/action";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingCUModal from './Dropdown/SettingCUModal';
import SettingDeleteModal from './Dropdown/SettingsDeleteModal';
import { toast } from "react-toastify";
import VendorSettingUModal from './Vendor/VendorSettingUModal';
import VendorCUModal from './Vendor/VendorCUModal';
import ExportModal from './ExportModal';
import VendorSettingsDeleteModal from './Vendor/VendorSettingsDeleteModal';
import VendorSettingCModal from './Vendor/VendorSettingCModal';
import InputDesigner from './InputDesigner/InputDesigner';
import FormDesigner from './FormDesigner/FormDesigner';
import { extractTabIdFromName, extractTabNameFromId } from '../../../utils/settingsHelper';
import _ from 'lodash';
import RegionBuilder from './RegionBuilder/RegionBuilder';
import { getCurrentPageIdentifier } from '../../../utils/appHelper';

const tabList = [
  { id: 'dropdownSettings', name: 'Dropdown' },
  { id: 'vendorSettings', name: 'Vendor' },
  { id: 'inputDesigner', name: 'Input Designer' },
  { id: 'formDesigner', name: 'Form Designer' },
  { id: 'regionBuilder', name: 'Region Builder' },
];

const SettingsScreen = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pathname = location?.pathname || "";
  const ids = pathname.split("/");
  const id = ids.length > 1 ? ids[ids.length - 1] : "";
  const foundId = extractTabIdFromName(id);
  const { currentUser = null } = useSelector(state => state.auth);
  let identifier = getCurrentPageIdentifier();
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: identifier });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  let permisson_matrix_ids = (currentUser?.permisson_matrix || [])?.map(item => item.identifier);
  const settingsData = useSelector((state) => state.settingsConstants);
  const maxPage = useSelector((state) => state.settingsConstants.maxPage);
  const currentPage = useSelector((state) => state.settingsConstants.currentPage);
  const [activeTab, setActiveTab] = useState(null);
  const [title, setTitle] = useState("");
  const [activePill, setActivePill] = useState(null);
  const [showDropdownModal, setShowDropdownModal] = useState(false);
  const [showDropdownDeleteModal, setShowDropdownDeleteModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [activeVendorPill, setActiveVendorPill] = useState(null);
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [showVendorSettingsAddModal, setShowVendorSettingsAddModal] = useState(false);
  const [showVendorSettingsModal, setShowVendorSettingsModal] = useState(false);
  const [showVendorSettingsDeleteModal, setShowVendorSettingsDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (id) {
      let list = [], idList = [];
      if (permisson_matrix_ids.includes('dropdownSettings')) {
        list.push(tabList[0]);
        idList.push(0);
      }
      if (permisson_matrix_ids.includes('vendorSettings')) {
        list.push(tabList[1]);
        idList.push(1);
      }
      if (permisson_matrix_ids.includes('inputDesigner')) {
        list.push(tabList[2]);
        idList.push(2);
      }
      if (permisson_matrix_ids.includes('formDesigner')) {
        list.push(tabList[3]);
        idList.push(3);
      }
      if (permisson_matrix_ids.includes('regionBuilder')) {
        list.push(tabList[4]);
        idList.push(4);
      }
      setTabs(list);
      if (idList.includes(foundId)) {
        handleChangeTab(foundId);
      }
    }
  }, [location.pathname]);

  const handleChangeTab = (newValue) => {
    setSearchText("");
    setActiveTab(newValue);
    setCurrentData(null);
    setSelectedVendor(null);
    if (newValue === 0) {
      setActiveVendorPill(null);
      handleSettingSelect(settingsOptions[0]);
    } else if (newValue === 1) {
      dispatch(getSettingVendorsAction({ searchText: '', page: 0 }));
      setActiveVendorPill(vendorPill);
    }
  };

  const handleChangePath = (event, value) => {
    history.push(`/${extractTabNameFromId(value)}`);
  }

  const handleSettingSelect = (e) => {
    setActivePill(e);
    setTitle(e?.callName || "");
    setSearchText("");
    dispatch(getSettingConstantsAction({ type: e.apiFieldName, searchText: '', page: 0 }));
    setCurrentData(null);
  };

  const handleDropdownPageChange = (pageNo) => {
    dispatch(getSettingConstantsAction({ type: activePill.apiFieldName, searchText: searchText, page: pageNo }));
  };

  const handleDropdownSearch = (value = "") => {
    setSearchText(value);
    dispatch(getSettingConstantsAction({ type: activePill.apiFieldName, searchText: value, page: 0 }));
  };

  const handleVendorPageChange = (pageNo) => {
    dispatch(getSettingVendorsAction({ searchText: searchText, page: pageNo }));
  };

  const handleVendorSearch = (value = "") => {
    setSearchText(value);
    dispatch(getSettingVendorsAction({ searchText: value, page: 0 }));
  };

  const handleVendorSettingsPageChange = (pageNo) => {
    dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: activeVendorPill.apiFieldName, searchText: '', page: pageNo }));
  };

  const handleVendorSettingsSearch = (value = "") => {
    setSearchText(value);
    dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: activeVendorPill.apiFieldName, searchText: value, page: currentPage }));
  };

  const handleShowAddModal = () => {
    setCurrentData(null);
    setShowDropdownModal(true);
  }

  const handleCloseModal = () => {
    setCurrentData(null);
    setShowDropdownModal(false);
  }

  const handleCloseDeleteModal = () => {
    setCurrentData(null);
    setShowDropdownDeleteModal(false);
  }

  const handleShowEditOption = (e) => {
    setCurrentData(e);
    setShowDropdownModal(true);
  }

  const handleShowDeleteOption = (e) => {
    setCurrentData(e);
    setShowDropdownDeleteModal(true);
  }

  const handleAddOption = (e) => {
    setShowDropdownModal(false);
    dispatch(createSettingConstantsAction({ type: activePill?.postApiFieldName || activePill.apiFieldName, callName: activePill.callName, data: e }, (res) => {
      if (res.status) {
        toast.success("Successfully, added a new option.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getSettingConstantsAction({ type: activePill.apiFieldName, searchText: '', page: 0 }));
      } else {
        toast.error((res?.message || "").includes('Duplicate') ? 'Duplicate option cannot be added' : res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleUpdateOption = (e) => {
    setShowDropdownModal(false);
    dispatch(updateSettingConstantsAction({ type: activePill?.postApiFieldName || activePill.apiFieldName, data: e }, (res) => {
      if (res.status) {
        toast.success("Successfully, updated the option.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getSettingConstantsAction({ type: activePill.apiFieldName, searchText: '', page: 0 }));
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleDeleteOption = (e) => {
    setShowDropdownDeleteModal(false);
    dispatch(deleteSettingConstantsAction({ type: activePill?.postApiFieldName || activePill.apiFieldName, data: { id: e.id } }, (res) => {
      if (res.status) {
        toast.success("Successfully, deleted the option.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getSettingConstantsAction({ type: activePill.apiFieldName, searchText: '', page: 0 }));
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleMergeOption = (e) => {
    setShowDropdownModal(false);
    if (e?.id) {
      if (e?.data?.name !== currentData.name) {
        setCurrentData(null);
        dispatch(updateSettingConstantsAction({ type: activePill?.postApiFieldName || activePill.apiFieldName, data: { ...e.data, id: e.id } }, (res) => {
          if (res.status) {
            dispatch(mergeSettingConstantsAction({ type: activePill?.postApiFieldName || activePill.apiFieldName, data: { id: e.id, replace: e?.replace || [] } }, (res) => {
              if (res.status) {
                toast.success("Successfully, updated and merged the option.", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                });
                dispatch(getSettingConstantsAction({ type: activePill.apiFieldName, searchText: '', page: 0 }));
              } else {
                toast.error(res.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                });
              }
            }));
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          }
        }));
      } else {
        dispatch(mergeSettingConstantsAction({ type: activePill?.postApiFieldName || activePill.apiFieldName, data: { id: e.id, replace: e.replace } }, (res) => {
          if (res.status) {
            toast.success("Successfully, merged the option.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            dispatch(getSettingConstantsAction({ type: activePill.apiFieldName, searchText: '', page: 0 }));
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          }
        }));
      }
    } else {
      dispatch(createSettingConstantsAction({ type: activePill?.postApiFieldName || activePill.apiFieldName, callName: activePill.callName, data: e.data }, (res) => {
        if (res.status && res?.data?.id) {
          dispatch(mergeSettingConstantsAction({ type: activePill?.postApiFieldName || activePill.apiFieldName, data: { id: res.data.id, replace: e?.replace || [] } }, (res) => {
            if (res.status) {
              toast.success("Successfully, added and merged the new option.", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              dispatch(getSettingConstantsAction({ type: activePill.apiFieldName, searchText: '', page: 0 }));
            } else {
              toast.error(res.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
            }
          }));
        } else {
          toast.error((res?.message || "").includes('Duplicate') ? 'Duplicate option cannot be added' : "Error adding new option", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      }));
    }
  }

  // VENDOR SETTINGS 

  const handleShowVendorAddModal = () => {
    setCurrentData(null);
    setShowVendorModal(true);
  }

  const handleShowVendorView = (e) => {
    setSearchText("");
    setSelectedVendor(e);
    setActiveVendorPill(vendorSettingsOptions[0]);
    setTitle(vendorSettingsOptions[0]?.callName || "");
    dispatch(getVendorSettingConstantsAction({ id: e.id, type: vendorSettingsOptions[0].apiFieldName, searchText: '', page: 0 }));
    setCurrentData(null);
  }

  const handleShowVendorEdit = (e) => {
    setCurrentData(renameKeys(e, { vendor_name: "vendorName" }));
    setShowVendorModal(true);
  }

  const handleVendorSettingSelect = (e) => {
    setSearchText("");
    setActiveVendorPill(e);
    setTitle(e?.callName || "");
    dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: e.apiFieldName, searchText: '', page: 0 }));
    setCurrentData(null);
  };

  const handleVendorCloseModal = () => {
    setCurrentData(null);
    setShowVendorModal(false);
  }

  const handleAddVendor = (e, mergeData) => {
    setShowVendorModal(false);
    dispatch(createSettingVendorsAction({ data: e }, (res) => {
      if (res.status) {
        if (mergeData?.replace?.length) {
          if (res?.data?.insertId) {
            dispatch(mergeSettingVendorsAction({ ...mergeData, id: res.data.insertId }, (res2) => {
              if (res2?.status) {
                toast.success("New vendor added successfully!", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                });
                dispatch(getSettingVendorsAction({ searchText: searchText, page: 0 }));
              } else {
                toast.error("Failed to merge vendors!", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                });
                dispatch(getSettingVendorsAction({ searchText: searchText, page: 0 }));
              }
            }));
          } else {
            toast.error("Failed to add new vendor!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            dispatch(getSettingVendorsAction({ searchText: searchText, page: 0 }));
          }
        } else {
          toast.success("New vendor added successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          dispatch(getSettingVendorsAction({ searchText: searchText, page: 0 }));
        }
      } else {
        toast.error((res?.message || "").includes('Duplicate') ? 'Vendor already exist!' : res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleUpdateVendor = (e, mergeData) => {
    setShowVendorModal(false);
    dispatch(updateSettingVendorsAction({ data: e }, (res) => {
      if (res.status) {
        if (mergeData?.replace?.length) {
          dispatch(mergeSettingVendorsAction(mergeData, (res2) => {
            if (res2?.status) {
              toast.success("Vendor details updated successfully", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              dispatch(getSettingVendorsAction({ searchText: searchText, page: 0 }));
            } else {
              toast.error("Failed to merge vendors!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              dispatch(getSettingVendorsAction({ searchText: searchText, page: 0 }));
            }
          }));
        } else {
          toast.success("Vendor details updated successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
          dispatch(getSettingVendorsAction({ searchText: searchText, page: 0 }));
        }
      } else {
        toast.error((res?.message || "").includes('Duplicate') ? 'Vendor already exist!' : res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleVendorViewBack = () => {
    setSearchText("");
    setSelectedVendor(null);
    dispatch(getSettingVendorsAction({ searchText: "", page: 0 }));
    setActiveVendorPill(vendorPill);
  }

  // Vendor Settings

  const handleShowVendorSettingsAddModal = () => {
    setCurrentData(null);
    setShowVendorSettingsAddModal(true);
  }

  const handleVendorSettingsCloseModal = () => {
    setCurrentData(null);
    setShowVendorSettingsModal(false);
    setShowVendorSettingsAddModal(false);
  }

  const handleShowVendorAgencyEditOption = (e) => {
    if (activeVendorPill.apiFieldName === 'insurance') {
      setCurrentData(renameKeys(e, { cert_of_insurance_expiry_date: "expiryDate", cert_of_insurance_type: "insuranceType" }));
    } else if (activeVendorPill.apiFieldName === 'sdf') {
      setCurrentData(renameKeys(e, { sdf_expiry_date: "expiryDate", sdf: "sdf" }));
    } else {
      setCurrentData(e);
    }
    setShowVendorSettingsModal(true);
  }

  const handleShowVendorAgencyDeleteOption = (e) => {
    setCurrentData(e);
    setShowVendorSettingsDeleteModal(true);
  }

  const handleCloseVendorSettingsDeleteModal = () => {
    setCurrentData(null);
    setShowVendorSettingsDeleteModal(false);
  }

  const handleAddVendorSettingsOption = (e) => {
    setShowVendorSettingsAddModal(false);
    dispatch(createVendorSettingConstantsAction({ type: activeVendorPill?.postApiFieldName || activeVendorPill.apiFieldName, data: { ...e, vendorID: selectedVendor.id } }, (res) => {
      if (res.status) {
        toast.success("Successfully, added a new option.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: activeVendorPill.apiFieldName, searchText: '', page: 0 }));
      } else {
        toast.error((res?.message || "").includes('Duplicate') ? 'Duplicate option cannot be added' : res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleUpdateVendorSettingsOption = (e) => {
    setShowVendorSettingsModal(false);
    dispatch(updateVendorSettingConstantsAction({ type: activeVendorPill?.postApiFieldName || activeVendorPill.apiFieldName, data: { ...e, vendorID: selectedVendor.id } }, (res) => {
      if (res.status) {
        toast.success("Successfully, updated the option.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: activeVendorPill.apiFieldName, searchText: '', page: 0 }));
      } else {
        toast.error((res?.message || "").includes('Duplicate') ? 'Duplicate option cannot be added' : res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleVendorDeleteOption = (e) => {
    setShowVendorSettingsDeleteModal(false);
    dispatch(deleteVendorSettingConstantsAction({ type: activeVendorPill?.postApiFieldName || activeVendorPill.apiFieldName, data: { data: { id: e.id } } }, (res) => {
      if (res.status) {
        toast.success("Successfully, deleted the option.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: activeVendorPill.apiFieldName, searchText: '', page: 0 }));
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      }
    }));
  }

  const handleMergeVendorSettingsOption = (e) => {
    setShowVendorSettingsModal(false);
    if (e?.id) {
      if (e?.data?.name !== currentData.name) {
        setCurrentData(null);
        dispatch(updateVendorSettingConstantsAction({ type: activeVendorPill?.postApiFieldName || activeVendorPill.apiFieldName, data: { ...e, vendorID: selectedVendor.id } }, (res) => {
          if (res.status) {
            dispatch(mergeVendorSettingConstantsAction({ type: activeVendorPill?.postApiFieldName || activeVendorPill.apiFieldName, data: { id: e.id, replace: e?.replace || [] } }, (res) => {
              if (res.status) {
                toast.success("Successfully, updated and merged the option.", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                });
                dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: activeVendorPill.apiFieldName, searchText: '', page: 0 }));
              } else {
                toast.error(res.message, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000,
                });
              }
            }));
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          }
        }));
      } else {
        dispatch(mergeVendorSettingConstantsAction({ type: activeVendorPill?.postApiFieldName || activeVendorPill.apiFieldName, data: { id: e.id, replace: e?.replace || [] } }, (res) => {
          if (res.status) {
            toast.success("Successfully, merged the option.", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
            dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: activeVendorPill.apiFieldName, searchText: '', page: 0 }));
          } else {
            toast.error(res.message, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000,
            });
          }
        }));
      }
    } else {
      dispatch(createVendorSettingConstantsAction({ type: activeVendorPill?.postApiFieldName || activeVendorPill.apiFieldName, data: { ...e.data, vendorID: selectedVendor.id } }, (res) => {
        if (res.status && res?.data?.id) {
          dispatch(mergeVendorSettingConstantsAction({ type: activeVendorPill?.postApiFieldName || activeVendorPill.apiFieldName, data: { id: res.data.id, replace: e?.replace || [] } }, (res) => {
            if (res.status) {
              toast.success("Successfully, added and merged the new option.", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              dispatch(getVendorSettingConstantsAction({ id: selectedVendor.id, type: activeVendorPill.apiFieldName, searchText: '', page: 0 }));
            } else {
              toast.error(res.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
            }
          }));
        } else {
          toast.error((res?.message || "").includes('Duplicate') ? 'Duplicate option cannot be added' : "Error adding new option", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      }));
    }
  }

  let actionColumns = [
    {
      minWidth: 100,
      height: 150,
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id={`edit-tooltip-${rows?.id}`} title='Edit'>
                <IconButton id={`edit-btn-${rows?.id}`} aria-label='edit' size='small' color='primary' onClick={() => handleShowEditOption(rows)}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item>
              <Tooltip id={`delete-tooltip-${rows?.id}`} title='Delete'>
                <IconButton id={`delete-btn-${rows?.id}`} aria-label='delete' size='small' color='error' onClick={() => handleShowDeleteOption(rows)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
						</Grid> */}
          </Grid>
        );
      },
    },
  ];

  let vendorActionColumns = [
    {
      minWidth: 50,
      height: 150,
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id={`view-tooltip-${rows?.id}`} title='View'>
                <IconButton id={`view-btn-${rows?.id}`} aria-label='view' size='small' color='primary' onClick={() => handleShowVendorView(rows)}>
                  <VisibilityIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
            {hasWritePermission ?
              <Grid item>
                <Tooltip id={`edit-tooltip-${rows?.id}`} title='Edit'>
                  <IconButton id={`edit-btn-${rows?.id}`} aria-label='edit' size='small' color='primary' onClick={() => handleShowVendorEdit(rows)}>
                    <EditIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
              </Grid>
              :
              null
            }
          </Grid>
        );
      },
    },
  ];

  let vendorAgencyActionColumns = [
    {
      minWidth: 50,
      height: 150,
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id={`edit-tooltip-${rows?.id}`} title='Edit'>
                <IconButton id={`edit-btn-${rows?.id}`} aria-label='edit' size='small' color='primary' onClick={() => handleShowVendorAgencyEditOption(rows)}>
                  <EditIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item>
              <Tooltip id={`delete-tooltip-${rows?.id}`} title='Delete'>
                <IconButton id={`delete-btn-${rows?.id}`} aria-label='delete' size='small' color='error' onClick={() => handleShowVendorAgencyDeleteOption(rows)}>
                  <DeleteIcon fontSize='small' />
                </IconButton>
              </Tooltip>
						</Grid> */}
          </Grid>
        );
      },
    },
  ];

  const vendorColumns = activeVendorPill?.columns || [];

  return (
    <Box sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <AppBar appBarTitle="Settings">
        {activeTab === 0 || activeTab === 1
          ?
          <Button id='export-btn' onClick={() => setShowExportModal(true)} variant="contained" startIcon={<FileDownloadIcon />}>
            Export
          </Button>
          :
          null
        }
      </AppBar>
      <Box
        sx={{
          background: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 2,
          mt: 2,
          width: "100%",
          position: 'relative',
          pb: 2,
          height: activeTab < 2 ? 'calc(100svh - 120px)' : 'auto',
          minHeight: 'calc(100svh - 120px)'
        }}
      >
        {settingsData.loading ? <OverlayLoader /> : null}
        <Box sx={{ pl: 2 }}>
          <TabNav list={tabs?.map(item => item?.name)} id="settings" value={activeTab} handleChange={handleChangePath} />
        </Box>
        <TabPanel id="settings" value={activeTab} index={0}>
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', overflowX: 'auto', gap: 1 }}>
            {(settingsOptions || []).map((set, index) => {
              return (
                <Chip key={"chip-" + index} id={`chip-${index + 1}`} color="primary" label={set?.name || ""} p={1} variant={activePill?.id === set?.id ? "filled" : "outlined"} onClick={() => handleSettingSelect(set)} />
              )
            })}
          </Box>
          {activePill?.id ?
            <SettingsOptionsDataTable
              title={title}
              data={settingsData?.list || []}
              columns={hasWritePermission ? [...activePill.columns, ...actionColumns] : [...activePill.columns]}
              handleToggleAddModal={handleShowAddModal}
              add={hasWritePermission}
              maxPage={maxPage}
              currentData={currentPage}
              searchText={searchText}
              handlePageChange={handleDropdownPageChange}
              requestSearch={handleDropdownSearch}
              height='calc(100svh - 240px)'
            />
            : null}
        </TabPanel>
        <TabPanel id="settings" value={activeTab} index={1}>
          {!selectedVendor?.id
            ?
            <SettingsOptionsDataTable
              title={'Vendor'}
              data={settingsData?.vendorList || []}
              columns={[...vendorColumns, ...vendorActionColumns]}
              handleToggleAddModal={handleShowVendorAddModal}
              add={hasWritePermission}
              maxPage={maxPage}
              currentData={currentPage}
              searchText={searchText}
              handlePageChange={handleVendorPageChange}
              requestSearch={handleVendorSearch}
              height='calc(100svh - 170px)'
            />
            :
            <Box>
              <Box px={2} pt={2} display="flex" flexDirection="row" justifyContent="ceneter" alignItems="center">
                <Tooltip id='edit' title='Edit'>
                  <IconButton id='back-btn' aria-label='edit' size='small' color='primary' onClick={handleVendorViewBack}>
                    <ArrowBackIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
                <Breadcrumbs separator="›">
                  <Typography variant='caption' color="#1976D2" sx={{ fontSize: 14 }}>Vendor List</Typography>
                  <Typography variant='caption' color="#1976D2" sx={{ fontSize: 14 }}>{selectedVendor?.vendor_name || ""}</Typography>
                </Breadcrumbs>
              </Box>
              <Grid container spacing={1} p={2}>
                {(vendorSettingsOptions || []).map((set, index) => {
                  return (
                    <Grid item key={"chip-" + index}>
                      <Chip id={`chip-${index + 1}`} color="primary" label={set?.name || ""} p={1} variant={activeVendorPill?.id === set?.id ? "filled" : "outlined"} onClick={() => handleVendorSettingSelect(set)} />
                    </Grid>
                  )
                })}
              </Grid>
              {activeVendorPill?.id ?
                <SettingsOptionsDataTable
                  title={title}
                  data={settingsData?.list || []}
                  columns={hasWritePermission ? [...activeVendorPill.columns, ...vendorAgencyActionColumns] : [...activeVendorPill.columns]}
                  handleToggleAddModal={handleShowVendorSettingsAddModal}
                  add={hasWritePermission}
                  maxPage={maxPage}
                  currentData={currentPage}
                  searchText={searchText}
                  handlePageChange={handleVendorSettingsPageChange}
                  requestSearch={handleVendorSettingsSearch}
                  height='calc(100svh - 280px)'
                />
                : null}
            </Box>
          }
        </TabPanel>
        <TabPanel id="settings" value={activeTab} index={2}>
          <InputDesigner hasWritePermission={hasWritePermission} />
        </TabPanel>
        <TabPanel id="settings" value={activeTab} index={3}>
          <FormDesigner hasWritePermission={hasWritePermission} />
        </TabPanel>
        <TabPanel id="settings" value={activeTab} index={4}>
          <RegionBuilder hasWritePermission={hasWritePermission} />
        </TabPanel>
      </Box>
      {/* Export */}
      {showExportModal ? <ExportModal open={showExportModal} handleModalClose={() => setShowExportModal(false)} /> : null}
      {/* Dropdown */}
      {showDropdownModal ?
        <SettingCUModal
          open={showDropdownModal}
          mode={currentData?.id ? 'edit' : 'add'}
          currentData={currentData}
          handleClose={handleCloseModal}
          handleAdd={handleAddOption}
          handleUpdate={handleUpdateOption}
          handleMerge={handleMergeOption}
          activePill={activePill}
        />
        :
        null
      }
      <SettingDeleteModal open={showDropdownDeleteModal} currentData={currentData} handleClose={handleCloseDeleteModal} handleDelete={handleDeleteOption} />
      {/* Vendor */}
      {showVendorModal ?
        <VendorCUModal
          open={showVendorModal}
          mode={currentData?.id ? 'edit' : 'add'}
          currentData={currentData}
          handleClose={handleVendorCloseModal}
          handleAdd={handleAddVendor}
          handleUpdate={handleUpdateVendor}
          activePill={activeVendorPill}
        />
        :
        null
      }
      {showVendorSettingsAddModal ?
        <VendorSettingCModal
          open={showVendorSettingsAddModal}
          handleClose={handleVendorSettingsCloseModal}
          handleAdd={handleAddVendorSettingsOption}
          activePill={activeVendorPill}
        />
        :
        null
      }
      {showVendorSettingsModal ?
        <VendorSettingUModal
          open={showVendorSettingsModal}
          mode={currentData?.id ? 'edit' : 'add'}
          currentData={currentData}
          handleClose={handleVendorSettingsCloseModal}
          handleAdd={handleAddVendorSettingsOption}
          handleUpdate={handleUpdateVendorSettingsOption}
          handleMerge={handleMergeVendorSettingsOption}
          activePill={activeVendorPill}
          vendorId={selectedVendor?.id}
        />
        :
        null
      }
      {showVendorSettingsDeleteModal ? <VendorSettingsDeleteModal open={showVendorSettingsDeleteModal} currentData={currentData} handleClose={handleCloseVendorSettingsDeleteModal} handleDelete={handleVendorDeleteOption} /> : null}
    </Box>
  )
}

const QuickSearchToolbar = ({ value = "", onChange, clearSearch, title = "", add = true, selectionMode = false, handleToggleAddModal }) => {

  return (
    <Box display="flex" flexDirection='row' alignItems="center" justifyContent="space-between" pl={1} pr={3} mt={2} >
      <Box display="flex" flexDirection='row' alignItems="center">
        <Typography mr={2} variant="h6">{title + (!selectionMode ? " List" : "")}</Typography>
        <TextField
          variant="standard"
          value={value}
          onChange={onChange}
          placeholder="Search"
          InputProps={{
            startAdornment: <SearchIcon color='primary' fontSize="medium" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: value ? 'visible' : 'hidden' }}
                id="search-bar-input"
                onClick={clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
          sx={{ px: 2, py: 1 }}
        />
      </Box>
      {!selectionMode && add ?
        <Button id='add-new-btn' onClick={handleToggleAddModal} variant="contained" startIcon={<AddIcon />}>
          Add New {title}
        </Button>
        :
        null}
    </Box>
  );
}

export const SettingsOptionsDataTable = ({ title = "", height = "", add = true, data = [], columns = [], searchText = "", requestSearch = () => { }, selectionMode = false, handleToggleAddModal = () => { }, selectedTabledData = [], handleSelection = () => { }, maxPage, currentPage, handlePageChange = () => { }, disabledRows = [] }) => {
  return (
    <Box sx={{ width: '100%', height: 'calc(100svh - 210px)' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pagination
        paginationMode='server'
        pageSize={settingListLimit}
        rowCount={maxPage}
        page={currentPage}
        rowsPerPageOptions={[settingListLimit]}
        onPageChange={handlePageChange}
        checkboxSelection={selectionMode}
        onSelectionModelChange={handleSelection}
        density="comfortable"
        disableSelectionOnClick
        disableRowSelectionOnClick
        keepNonExistentRowsSelected
        selectionModel={selectedTabledData}
        isRowSelectable={(params) => !disabledRows.includes(params.row.id)}
        components={{ Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            value: searchText,
            onChange: (event) => requestSearch(event.target.value),
            clearSearch: () => requestSearch(""),
            add,
            selectionMode,
            title,
            handleToggleAddModal
          },
        }}
        sx={{
          px: 2,
          border: 0,
          height: height || "100%",
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#1089FF",
          },
          "& .MuiDataGrid-cell": {
            margin: 0,
            border: 0,
            width: 300,
          },
          "& .MuiDataGrid-row": {
            margin: 0,
          },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}

export const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4 }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

export const TabNav = ({ disabledList = [], id = "", list = [], value, handleChange }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label={id + '-tabs'}
      indicatorColor='primary'
      sx={{ pointerEvents: 'pointer' }}
      variant={"scrollable"}
      scrollButtons={"on"}
    >
      {list.map((l, index) => {
        return (
          <Tab
            disabled={disabledList.includes(l)}
            key={`${id}-${index}`}
            label={l}
            {...a11yProps(id, index)}
          />
        )
      })}
    </Tabs>
  )
};

export const TabPanel = ({ children, id = "", value, index = 0, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ position: 'relative' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (id = "", index = 0) => {
  return {
    id: `${id}-tab-${index}`,
    "aria-controls": `${id}-tabpanel-${index}`,
  };
}

export default SettingsScreen;