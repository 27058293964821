import React, { useMemo, useState } from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { toast } from "react-toastify";
import {useDropzone} from 'react-dropzone';
import CloseIcon from "@mui/icons-material/Close";
import { red } from '@mui/material/colors';
import { trimFilename } from '../../../utils/consultantConstants';
import { useSelector } from 'react-redux';
import _ from 'lodash';

const baseStyle = {
  padding: '20px',
  borderWidth: 2,
  borderRadius: 10,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '250px',
  display:'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const SOWUploadModal=({open=false, handleClose=()=>{}, handleUpload=()=>{}, currentData=null })=>{
	const loading = useSelector((state) => state.sow.loading);
	const [file,setFile] = useState(null);

	const handleModalClose=()=>{
		handleClose();
	}

	const onDrop = (acceptedFiles) => {
    if(acceptedFiles.length>0){
      const uploadedFile = acceptedFiles[acceptedFiles.length-1];
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => {
        let fileSize = (uploadedFile.size / (1024*1024)).toFixed(2);
        var uploadInput = document.getElementById('file-upload-input');
        uploadInput.value = '';
        if(fileSize<=5){
          let fileInfo = {
            name: trimFilename(uploadedFile.name),
            value: reader.result,
            fileObj: uploadedFile,
          };
          if (uploadedFile.type === "application/pdf") {
            setFile({
              type: "pdf",
              ...fileInfo,
            });
          } else if (
            uploadedFile.type === "image/jpeg" ||
            uploadedFile.type === "image/png"
          ) {
            setFile({
              type: "jpg",
              ...fileInfo,
            });
          } else if (
            uploadedFile.type ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            uploadedFile.type === "application/vnd.ms-excel"
          ) {
            setFile({
              type: "excel",
              ...fileInfo,
            });
          } else if (uploadedFile.type === "text/csv") {
            setFile({
              type: "csv",
              ...fileInfo,
            });
          } else if (
            uploadedFile.type === "application/msword" ||
            uploadedFile.type ===
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            setFile({
              type: "doc",
              ...fileInfo,
            });
          } else {
            toast.error('Document format not supported!');
          }
        }else{
          toast.error('Document size should not exceed 5 MB!');
        }
      };
    }
  };

	const handleRemoveFile=()=>{
    setFile(null);
  };

	const handleUploadData=()=>{
		if(file?.value){
			let payload={
        parent_id:null,
				base64:file?.value || "",
        document_name:file?.name || "",
      };
      if(currentData?.id){
        payload={
          ...payload,
          parent_id:currentData.id,
        }
      }
      handleUpload(payload);
		}else{
			toast.error('Please choose a file!');
		}
	}

	const {getRootProps, getInputProps,isFocused,isDragAccept, isDragReject} = useDropzone({onDrop,maxFile:1});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
  		maxWidth="md"
		>
			<DialogTitle>{currentData?.id?`Add a revision of statement of work`:`Add a new statement of work`}</DialogTitle>
			<DialogContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Typography variant='subtitle2'>Statement of work <Typography display="inline" sx={{color:'red'}}>*</Typography></Typography>
						<Box sx={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',mt:1}} {...getRootProps({style})}>
							<input id="file-upload-input" {...getInputProps()} />
							{
								file?.name?
								<Box sx={{borderColor: '#00e676',borderStyle: 'dashed',borderWidth:2,p:2,borderRadius:2,display:'flex',alignItems:'center',justifyContent:'center'}}>
									<Typography variant='body1' color='#000'>{file?.name || ""}</Typography>
									<Button variant='text' sx={{px:0,minWidth:'auto',color:red[500]}}  onClick={handleRemoveFile}>
										<CloseIcon/>
									</Button>
								</Box>
								:
								<Box sx={{display:'flex',flexDirection:'column',alignItems:'center'}}>
									<Typography variant='subtitle2'>Drag 'n' drop file here, or click to select a file</Typography>
									<Typography variant='subtitle2'>Supported Formats: Pdf, Excel, Image, CSV & Word.</Typography>
								</Box>
							}
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
        <Button onClick={handleModalClose}>Close</Button>
				<Button startIcon={loading? <CircularProgress sx={{color:'white'}} size={20}/> : null} disabled={loading} onClick={handleUploadData} variant='contained'>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SOWUploadModal;