import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const UserStatusToggleModal = ({ open = false, handleClose = () => { }, handleStatus = () => { }, currentData = null }) => {

	const handleModalClose = () => {
		handleClose();
	}

	const handleUserStatus = () => {
		handleStatus({ id: currentData.id, data: { status: !currentData.is_active } });
	};

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			fullWidth
			maxWidth={"xs"}
		>
			<DialogContent>
				<Typography variant='h6'>Are you sure you want to make this user {currentData?.is_active ? 'in-active' : 'active'}?</Typography>
				{currentData?.is_active ?
					<Alert severity='warning' sx={{ mt: 2 }}>
						<AlertTitle>
							<Typography variant='subtitle2'>Disabling will not delete the user, but it will revoke all access of the user temperorily. You can enable the user whenever it is necessary.</Typography>
						</AlertTitle>
					</Alert>
					:
					null
				}
			</DialogContent>
			<DialogActions>
				<Button id='form-cancel-btn' onClick={handleModalClose}>Cancel</Button>
				<Button id='form-add-btn' onClick={handleUserStatus} variant='contained'>{currentData?.is_active ? `Mark in-active` : `Mark active`}</Button>
			</DialogActions>
		</Dialog>
	)
}

export default UserStatusToggleModal;