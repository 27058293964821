import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";
import { SettingsOptionsDataTable, TabNav, TabPanel } from '../SettingsScreen';
import { useDispatch, useSelector } from 'react-redux';
import { getVendorMergeList, getVendorSettingConstantsAction2 } from '../../../../store/Settings/action';
import { vendorSettingsOptions } from '../../../../constants/config';
import _ from 'lodash';

const VendorCUModal = ({ open = false, mode = 'add', handleClose, handleAdd, handleUpdate, currentData = null, activePill = null }) => {
	const dispatch = useDispatch();
	const list2 = useSelector((state) => state.settingsConstants.list2);
	const maxPage = useSelector((state) => state.settingsConstants.maxPage2);
	const currentPage = useSelector((state) => state.settingsConstants.currentPage2);

	const [data, setData] = useState(null);
	const [step, setStep] = useState(0);
	const [merge, setMerge] = useState(false);
	const [list, setList] = useState([]);
	const [activeVendor, setActiveVendor] = useState(null);
	const [insuranceList, setInsuranceList] = useState([]);
	const [sdfList, setSDFList] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [activeTab, setActiveTab] = useState(0);
	const [vendorList, setVendorList] = useState(null);

	useEffect(() => {
		let d = {};
		if (mode == 'add') {
			(activePill?.variables || []).map((a) => {
				if (currentData?.[a.key]) {
					d[a.key] = "";
				}
			});
		} else {
			(activePill?.editVariables || activePill?.variables || []).map((a) => {
				if (currentData?.[a.key]) {
					d[a.key] = currentData[a.key];
				}
			});
			setList(prev => ([...prev, currentData.id]));
			setVendorList(prev => ({ ...prev, [currentData.id]: { ...currentData, vendor_name: currentData?.vendorName || "" } }));
		}
		setData(d);
	}, [currentData]);

	useEffect(() => {
		setSearchText("");
		if (step === 1) {
			dispatch(getVendorMergeList({ type: activePill.apiFieldName, searchText: '', page: 0 }));
		} else if (step === 2) {
			setActiveTab(0);
			setActiveVendor(list[0]);
			dispatch(getVendorSettingConstantsAction2({ id: list[0], type: vendorSettingsOptions[activeTab].apiFieldName, searchText: "", page: 0 }));
		}
	}, [step]);

	useEffect(() => {
		if (activeVendor) {
			setSearchText("");
			dispatch(getVendorSettingConstantsAction2({ id: activeVendor, type: vendorSettingsOptions[activeTab].apiFieldName, searchText: "", page: 0 }));
		}
	}, [activeVendor]);

	const handleSelectFromList = (e) => {
		let diff = _.differenceWith(e, list, _.isEqual);
		setList(e);
		diff.forEach(item => {
			let foundItem = _.find(list2, { id: item });
			if (foundItem?.id) {
				setVendorList(prev => ({ ...prev, [foundItem.id]: foundItem }));
			}
		});
	}

	const handleChangeTab = (event, newValue) => {
		setSearchText("");
		setActiveTab(newValue);
		dispatch(getVendorSettingConstantsAction2({ id: activeVendor, type: vendorSettingsOptions[newValue].apiFieldName, searchText: "", page: 0 }));
	};

	const handleModalClose = () => {
		setData(null);
		handleClose();
	}

	const handleNext = () => {
		const check = checkValid();
		if (check) {
			if (step === 1) {
				if (list.length > 1) {
					setStep(prev => prev + 1);
				} else {
					toast.error('Please choose atleast one item!');
				}
			} else {
				setStep(prev => prev + 1);
			}
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
		setSearchText("");
	}

	const handleBack = () => {
		setStep(prev => prev - 1);
		setSearchText("");
	}

	const handleAddOption = () => {
		const check = checkValid();
		if (check) {
			let payload = {
				replace: list,
				sdf_ids: sdfList,
				insurance_ids: insuranceList
			}
			handleAdd(data, payload);
			setData(null);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleUpdateOption = () => {
		const check = checkValid();
		if (check) {
			let payload = {
				id: currentData.id,
				replace: list.filter(item => item !== currentData.id),
				sdf_ids: sdfList,
				insurance_ids: insuranceList
			}
			handleUpdate({ ...data, id: currentData.id }, payload);
			setData(null);
		} else {
			toast.error("Please fill all required fields", {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 2000,
			});
		}
	}

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const checkValid = () => {
		let v = [], valid = true;
		if (mode == 'add') {
			(activePill?.variables || []).map((a) => {
				v.push(a.key);
			});
		} else {
			(activePill?.editVariables || activePill?.variables || []).map((a) => {
				v.push(a.key);
			});
		}
		v.map((item) => {
			if (!data[item]) {
				valid = false;
			}
		})
		return valid;
	}

	const handlePageChange = (pageNo) => {
		dispatch(getVendorMergeList({ type: activePill.apiFieldName, searchText: searchText, page: pageNo }));
	}

	const handlePageChangeSDF = (pageNo) => {
		dispatch(getVendorMergeList({ type: activePill.apiFieldName, searchText: searchText, page: pageNo }));
	}

	const handlePageChangeInsurance = (pageNo) => {
		dispatch(getVendorMergeList({ type: activePill.apiFieldName, searchText: searchText, page: pageNo }));
	}

	const handleSearch = (value = "") => {
		setSearchText(value);
		dispatch(getVendorMergeList({ type: activePill.apiFieldName, searchText: value, page: 0 }));
	}

	const handleSearchInsurance = (value = "") => {
		setSearchText(value);
		dispatch(getVendorSettingConstantsAction2({ id: activeVendor, type: vendorSettingsOptions[activeTab].apiFieldName, searchText: value, page: 0 }));
	}

	const handleSearchSDF = (value = "") => {
		setSearchText(value);
		dispatch(getVendorSettingConstantsAction2({ id: activeVendor, type: vendorSettingsOptions[activeTab].apiFieldName, searchText: value, page: 0 }));
	}

	const textInputs = mode === 'add' ? activePill?.variables || [] : activePill?.editVariables || activePill?.variables || [];
	const mergeAvailable = !activePill?.noMerge;

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={step === 1 || step === 2 ? "md" : "sm"}
		>
			<DialogContent>
				<Typography variant='h6'>{currentData?.id ? "Update" : ("Add" + " option")}</Typography>
				{step === 0 ?
					<Box>
						{textInputs.map((item, index) => {
							return (
								<Box mt={1} key={index}>
									<Typography variant='subtitle2'>Enter {item?.label || ""} <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
									<TextField
										value={data?.[item.key] || ""}
										onChange={(e) => handleChange(item.key, e.target.value)}
										placeholder={"Please add a " + item.label + "!"}
										sx={{ width: "100%", background: "#FFFFFF", mt: 1 }}
										inputProps={{ paddingBottom: "100px" }}
									/>
								</Box>
							)
						})}
						{mergeAvailable
							?
							<FormControlLabel control={<Checkbox checked={merge} onChange={() => setMerge(prev => !prev)} />} label="Merge with other vendor(s)" />
							:
							null
						}
					</Box>
					:
					null}
				{step === 1 ?
					<Box>
						<SettingsOptionsDataTable
							title={"Select the vendor(s) you want to merge with:"}
							data={list2}
							columns={activePill.columns}
							currentPage={currentPage}
							maxPage={maxPage}
							searchText={searchText}
							selectionMode={true}
							handleSelection={(e) => handleSelectFromList(e)}
							handlePageChange={handlePageChange}
							requestSearch={handleSearch}
							selectedTabledData={list}
							disabledRows={currentData?.id ? [currentData.id] : []}
						/>
					</Box>
					:
					null}
				{step === 2 ?
					<Box sx={{ width: '100%' }}>
						<Grid container spacing={1} p={1}>
							{(list || []).map((set, index) => {
								return (
									<Grid item key={"chip-" + index}>
										<Chip id={`chip-${index + 1}`} color="primary" label={vendorList?.[set]?.vendor_name || ""} p={1} variant={activeVendor === set ? "filled" : "outlined"} onClick={() => setActiveVendor(set || null)} />
									</Grid>
								)
							})}
						</Grid>
						<TabNav list={['Insurance', 'SDF']} id="vendor-settings" value={activeTab} handleChange={handleChangeTab} />
						<TabPanel id="vendor-settings" value={activeTab} index={0}>
							<SettingsOptionsDataTable
								title={"Select the insurance(s) you want to merge with:"}
								data={list2}
								columns={vendorSettingsOptions[activeTab].columns}
								currentPage={currentPage}
								maxPage={maxPage}
								searchText={searchText}
								selectionMode={true}
								handleSelection={setInsuranceList}
								handlePageChange={handlePageChangeInsurance}
								requestSearch={handleSearchInsurance}
								selectedTabledData={insuranceList}
							/>
						</TabPanel>
						<TabPanel id="vendor-settings" value={activeTab} index={1}>
							<SettingsOptionsDataTable
								title={"Select the sdf(s) you want to merge with:"}
								data={list2}
								columns={vendorSettingsOptions[activeTab].columns}
								currentPage={currentPage}
								maxPage={maxPage}
								searchText={searchText}
								selectionMode={true}
								handleSelection={setSDFList}
								handlePageChange={handlePageChangeSDF}
								requestSearch={handleSearchSDF}
								selectedTabledData={sdfList}
							/>
						</TabPanel>
					</Box>
					:
					null}
			</DialogContent>
			<DialogActions>
				{step === 1 || step === 2 ?
					<Button id='back-btn' onClick={handleBack}>Go Back</Button>
					:
					<Button id='close-btn' onClick={handleModalClose}>Close</Button>
				}
				{merge
					?
					step === 0 || step === 1
						?
						<Button id='next-btn' onClick={handleNext} variant='contained'>Next</Button>
						:
						<Button id='merge-btn' onClick={() => currentData?.id ? handleUpdateOption() : handleAddOption()} variant='contained'>{currentData?.id ? "Update" : "Add"} & Merge</Button>
					:
					<Button id='submit-btn' onClick={() => currentData?.id ? handleUpdateOption() : handleAddOption()} variant='contained'>{currentData?.id ? "Update" : "Add"}</Button>
				}
			</DialogActions>
		</Dialog>
	)
}

export default VendorCUModal;