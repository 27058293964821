import {
  CREATE_SETTING_CONSTANTS_IN_PROGRESS,
  CREATE_SETTING_CONSTANTS_SUCCESS,
  CREATE_SETTING_CONSTANTS_FAILED,
  GET_SETTING_CONSTANTS_IN_PROGRESS,
  GET_SETTING_CONSTANTS_SUCCESS,
  GET_SETTING_CONSTANTS_FAILED,
  UPDATE_SETTING_CONSTANTS_IN_PROGRESS,
  UPDATE_SETTING_CONSTANTS_SUCCESS,
  UPDATE_SETTING_CONSTANTS_FAILED,
  DELETE_SETTING_CONSTANTS_IN_PROGRESS,
  DELETE_SETTING_CONSTANTS_SUCCESS,
  DELETE_SETTING_CONSTANTS_FAILED,
  GET_SETTING_VENDOR_IN_PROGRESS,
  GET_SETTING_VENDOR_SUCCESS,
  GET_SETTING_VENDOR_FAILED,
  GET_VENDOR_SETTING_CONSTANTS_IN_PROGRESS,
  GET_VENDOR_SETTING_CONSTANTS_SUCCESS,
  GET_VENDOR_SETTING_CONSTANTS_FAILED,
  GET_SETTING_AGENCIES_IN_PROGRESS,
  GET_SETTING_AGENCIES_SUCCESS,
  GET_SETTING_AGENCIES_FAILED,
  GET_SETTING_CONSTANTS2_IN_PROGRESS,
  GET_SETTING_CONSTANTS2_SUCCESS,
  GET_SETTING_CONSTANTS2_FAILED,
  GET_VENDOR_SETTING_CONSTANTS2_IN_PROGRESS,
  GET_VENDOR_SETTING_CONSTANTS2_SUCCESS,
  GET_VENDOR_SETTING_CONSTANTS2_FAILED,
  GET_CONFIGURATION_FIELDS_IN_PROGRESS,
  GET_CONFIGURATION_FIELDS_FAILED,
  GET_CONFIGURATION_FIELDS_SUCCESS,
  CONFIGURATION_FIELD_IN_PROGRESS,
  CONFIGURATION_FIELD_SUCCESS,
  CONFIGURATION_FIELD_FAILED,
  GET_CONFIGURATION_GROUPS_IN_PROGRESS,
  GET_CONFIGURATION_GROUPS_SUCCESS,
  GET_CONFIGURATION_GROUPS_FAILED,
  CONFIGURATION_GROUP_IN_PROGRESS,
  CONFIGURATION_GROUP_SUCCESS,
  CONFIGURATION_GROUP_FAILED,
  CONFIGURATION_USER_IN_PROGRESS,
  CONFIGURATION_USER_SUCCESS,
  CONFIGURATION_USER_FAILED,
  GET_CONFIGURATION_USERS_IN_PROGRESS,
  GET_CONFIGURATION_USERS_SUCCESS,
  GET_CONFIGURATION_USERS_FAILED,
  GET_FORM_CONFIGURATIONS_IN_PROGRESS,
  GET_FORM_CONFIGURATIONS_SUCCESS,
  GET_FORM_CONFIGURATIONS_FAILED,
  GET_ALL_FORM_CONFIGURATIONS_FAILED,
  GET_ALL_FORM_CONFIGURATIONS_SUCCESS,
  GET_ALL_FORM_CONFIGURATIONS_IN_PROGRESS,
  FORM_CONFIGURATION_IN_PROGRESS,
  FORM_CONFIGURATION_FAILED,
  FORM_CONFIGURATION_SUCCESS,
  GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_FAILED,
  GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_SUCCESS,
  GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_IN_PROGRESS,
  REGION_OPERATION_IN_PROGRESS,
  REGION_OPERATION_SUCCESS,
  REGION_OPERATION_FAILED,
  GET_REGIONS_IN_PROGRESS,
  GET_REGIONS_SUCCESS,
  GET_REGIONS_FAILED,
  GET_REGION_USERS_IN_PROGRESS,
  GET_REGION_USERS_FAILED,
  GET_REGION_USERS_SUCCESS,
} from "../../constants/store";

export const initialState = {
  maxPage: 0,
  currentPage: 0,
  loading: false,
  list: [],
  vendorList: [],
  agencies: [],
  agencyLoading: false,
  maxPage2: 0,
  currentPage2: 0,
  loading2: false,
  list2: [],
  vendorList2: [],
  loadingConfiguration: false,
  configurationList: [],
  loadingConfigurationGroups: false,
  configurationGroupList: [],
  loadingConfigurationUsers: false,
  configurationUserList: [],
  loadingFormConfigurations: false,
  formConfigurations: [],
  allFormConfigurations: [],
  loadingAllConfigurations: false,
  allRegionFormConfigurations: [],
  loadingRegions: false,
  regions: [],
  loadingRegionUsers: false,
  regionUsers: [],
};

function settingConstantsReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_SETTING_CONSTANTS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };

      return newState;
    }
    case CREATE_SETTING_CONSTANTS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case CREATE_SETTING_CONSTANTS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }

    // UPDATE
    case UPDATE_SETTING_CONSTANTS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };

      return newState;
    }
    case UPDATE_SETTING_CONSTANTS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case UPDATE_SETTING_CONSTANTS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }

    // DELETE
    case DELETE_SETTING_CONSTANTS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };

      return newState;
    }
    case DELETE_SETTING_CONSTANTS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case DELETE_SETTING_CONSTANTS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }

    // GET
    case GET_SETTING_CONSTANTS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
        list: []
      };
      return newState;
    }
    case GET_SETTING_CONSTANTS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        list: action.payload.list,
        maxPage: action.payload.maxPage,
        currentPage: action.payload.currentPage,
      };
      return newState;
    }
    case GET_SETTING_CONSTANTS_FAILED: {
      const newState = {
        ...state,
        loading: false,
        list: []
      };
      return newState;
    }
    case GET_SETTING_CONSTANTS2_IN_PROGRESS: {
      const newState = {
        ...state,
        loading2: true,
      };
      return newState;
    }
    case GET_SETTING_CONSTANTS2_SUCCESS: {
      const newState = {
        ...state,
        loading2: false,
        list2: action.payload.list,
        maxPage2: action.payload.maxPage,
        currentPage2: action.payload.currentPage,
      };
      return newState;
    }
    case GET_SETTING_CONSTANTS2_FAILED: {
      const newState = {
        ...state,
        loading2: false,
      };
      return newState;
    }
    // GET VENDOR
    case GET_SETTING_VENDOR_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_SETTING_VENDOR_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        vendorList: action.payload.list,
        maxPage: action.payload.maxPage,
        currentPage: action.payload.currentPage,
      };
      return newState;
    }
    case GET_SETTING_VENDOR_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // GET VENDOR SETTINGS CONSTANTS
    case GET_VENDOR_SETTING_CONSTANTS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
        list: []
      };
      return newState;
    }
    case GET_VENDOR_SETTING_CONSTANTS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        list: action.payload.list,
        maxPage: action.payload.maxPage,
        currentPage: action.payload.currentPage,
      };
      return newState;
    }
    case GET_VENDOR_SETTING_CONSTANTS_FAILED: {
      const newState = {
        ...state,
        loading: false,
        list: []
      };
      return newState;
    }
    case GET_VENDOR_SETTING_CONSTANTS2_IN_PROGRESS: {
      const newState = {
        ...state,
        loading2: true,
      };
      return newState;
    }
    case GET_VENDOR_SETTING_CONSTANTS2_SUCCESS: {
      const newState = {
        ...state,
        loading2: false,
        list2: action.payload.list,
        maxPage2: action.payload.maxPage,
        currentPage2: action.payload.currentPage,
      };
      return newState;
    }
    case GET_VENDOR_SETTING_CONSTANTS2_FAILED: {
      const newState = {
        ...state,
        loading2: false,
      };
      return newState;
    }

    // GET AGENCIES
    case GET_SETTING_AGENCIES_IN_PROGRESS: {
      const newState = {
        ...state,
        agencyLoading: true,
        agencies: []
      };
      return newState;
    }
    case GET_SETTING_AGENCIES_SUCCESS: {
      const newState = {
        ...state,
        agencyLoading: false,
        agencies: action.payload,
      };
      return newState;
    }
    case GET_SETTING_AGENCIES_FAILED: {
      const newState = {
        ...state,
        agencyLoading: false,
        agencies: []
      };
      return newState;
    }
    // GET CONFIGURATION FIELD
    case GET_CONFIGURATION_FIELDS_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingConfiguration: true,
      };
      return newState;
    }
    case GET_CONFIGURATION_FIELDS_SUCCESS: {
      const newState = {
        ...state,
        loadingConfiguration: false,
        configurationList: action.payload,
      };
      return newState;
    }
    case GET_CONFIGURATION_FIELDS_FAILED: {
      const newState = {
        ...state,
        loadingConfiguration: false,
      };
      return newState;
    }
    // CONFIGURATION FIELD OPERATION
    case CONFIGURATION_FIELD_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingConfiguration: true,
      };
      return newState;
    }
    case CONFIGURATION_FIELD_SUCCESS: {
      const newState = {
        ...state,
        loadingConfiguration: false,
      };
      return newState;
    }
    case CONFIGURATION_FIELD_FAILED: {
      const newState = {
        ...state,
        loadingConfiguration: false,
      };
      return newState;
    }
    // GET CONFIGURATION GROUPS
    case GET_CONFIGURATION_GROUPS_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingConfigurationGroups: true,
      };
      return newState;
    }
    case GET_CONFIGURATION_GROUPS_SUCCESS: {
      const newState = {
        ...state,
        loadingConfigurationGroups: false,
        configurationGroupList: action.payload,
      };
      return newState;
    }
    case GET_CONFIGURATION_GROUPS_FAILED: {
      const newState = {
        ...state,
        loadingConfigurationGroups: false,
      };
      return newState;
    }
    // CONFIGURATION GROUP OPERATION
    case CONFIGURATION_GROUP_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingConfigurationGroups: true,
      };
      return newState;
    }
    case CONFIGURATION_GROUP_SUCCESS: {
      const newState = {
        ...state,
        loadingConfigurationGroups: false,
      };
      return newState;
    }
    case CONFIGURATION_GROUP_FAILED: {
      const newState = {
        ...state,
        loadingConfigurationGroups: false,
      };
      return newState;
    }
    // GET CONFIGURATION USERS
    case GET_CONFIGURATION_USERS_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingConfigurationGroups: true,
      };
      return newState;
    }
    case GET_CONFIGURATION_USERS_SUCCESS: {
      const newState = {
        ...state,
        loadingConfigurationGroups: false,
        configurationUserList: action.payload,
      };
      return newState;
    }
    case GET_CONFIGURATION_USERS_FAILED: {
      const newState = {
        ...state,
        loadingConfigurationGroups: false,
      };
      return newState;
    }
    // CONFIGURATION USER OPERATION
    case CONFIGURATION_USER_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingConfigurationUsers: true,
      };
      return newState;
    }
    case CONFIGURATION_USER_SUCCESS: {
      const newState = {
        ...state,
        loadingConfigurationUsers: false,
      };
      return newState;
    }
    case CONFIGURATION_USER_FAILED: {
      const newState = {
        ...state,
        loadingConfigurationUsers: false,
      };
      return newState;
    }
    // GET ALL FORM CONFIGURATIONS
    case GET_ALL_FORM_CONFIGURATIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingFormConfigurations: true,
      };
      return newState;
    }
    case GET_ALL_FORM_CONFIGURATIONS_SUCCESS: {
      const newState = {
        ...state,
        loadingFormConfigurations: false,
        allFormConfigurations: action.payload,
      };
      return newState;
    }
    case GET_ALL_FORM_CONFIGURATIONS_FAILED: {
      const newState = {
        ...state,
        loadingFormConfigurations: false,
      };
      return newState;
    }
    // GET FORM CONFIGURATIONS
    case GET_FORM_CONFIGURATIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingFormConfigurations: true,
      };
      return newState;
    }
    case GET_FORM_CONFIGURATIONS_SUCCESS: {
      const newState = {
        ...state,
        loadingFormConfigurations: false,
        formConfigurations: action.payload,
      };
      return newState;
    }
    case GET_FORM_CONFIGURATIONS_FAILED: {
      const newState = {
        ...state,
        loadingFormConfigurations: false,
      };
      return newState;
    }
    // CONFIGURATION USER OPERATION
    case CONFIGURATION_USER_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingFormConfigurations: true,
      };
      return newState;
    }
    case CONFIGURATION_USER_SUCCESS: {
      const newState = {
        ...state,
        loadingFormConfigurations: false,
      };
      return newState;
    }
    case CONFIGURATION_USER_FAILED: {
      const newState = {
        ...state,
        loadingFormConfigurations: false,
      };
      return newState;
    }
    // Form configuration operation
    case FORM_CONFIGURATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingFormConfigurations: true,
      };
      return newState;
    }
    case FORM_CONFIGURATION_SUCCESS: {
      const newState = {
        ...state,
        loadingFormConfigurations: false,
      };
      return newState;
    }
    case FORM_CONFIGURATION_FAILED: {
      const newState = {
        ...state,
        loadingFormConfigurations: false,
      };
      return newState;
    }
    // GET ALL REGION CONFIGURATIONS
    case GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingAllConfigurations: true,
      };
      return newState;
    }
    case GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_SUCCESS: {
      const newState = {
        ...state,
        loadingAllConfigurations: false,
        allRegionFormConfigurations: action.payload,
      };
      return newState;
    }
    case GET_ALL_FORM_CONFIGURATIONS_FOR_ALL_REGION_FAILED: {
      const newState = {
        ...state,
        loadingAllConfigurations: false,
      };
      return newState;
    }
    //GET REGION
    case GET_REGIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingRegions: true,
      };
      return newState;
    }
    case GET_REGIONS_SUCCESS: {
      const newState = {
        ...state,
        loadingRegions: false,
        regions: action.payload,
      };
      return newState;
    }
    case GET_REGIONS_FAILED: {
      const newState = {
        ...state,
        loadingRegions: false,
      };
      return newState;
    }
    //REGION OPERATION
    case REGION_OPERATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingRegions: true,
      };
      return newState;
    }
    case REGION_OPERATION_SUCCESS: {
      const newState = {
        ...state,
        loadingRegions: false,
      };
      return newState;
    }
    case REGION_OPERATION_FAILED: {
      const newState = {
        ...state,
        loadingRegions: false,
      };
      return newState;
    }
    // GET REGION USERS
    case GET_REGION_USERS_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingRegionUsers: true,
      };
      return newState;
    }
    case GET_REGION_USERS_SUCCESS: {
      const newState = {
        ...state,
        loadingRegionUsers: false,
        regionUsers: action.payload,
      };
      return newState;
    }
    case GET_REGION_USERS_FAILED: {
      const newState = {
        ...state,
        loadingRegionUsers: false,
      };
      return newState;
    }
    default:
      return state;
  }
}

export default settingConstantsReducer;
