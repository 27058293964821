import { Box, IconButton, Typography } from '@mui/material';
import { extractPivotDataPoints } from '../../utils/chartHelper';
import { useEffect, useState } from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import PivotTable from 'react-pivottable/PivotTable';
import ViewSidebarIcon from '@mui/icons-material/ViewSidebar';
import { blue, grey } from '@mui/material/colors';
import moment from 'moment';
import NoDataCard from './noDataCard';

const PivotCard = ({ data = [], visualizationData = null, title = "" }) => {
  const [list, setList] = useState([]);
  const [plotData, setPlotData] = useState({});
  const [showMenu, setShowMenu] = useState(false);
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    const dataPoints = extractPivotDataPoints(data, visualizationData, title);
    let list = dataPoints?.list || [];
    setList(list);
    setTitle(dataPoints?.title || title);
    if (list?.length > 1) {
      let headers = list[0];
      let header1 = headers[0];
      setPlotData(prev => ({
        ...prev,
        aggregatorName: "Sum",
        rows: headers,
        vals: [headers[headers.length - 1]],
        rendererName: title,
        sorters: {
          [header1]: function (a, b) {
            if (new Date(moment(a).format('YYYY-MM-DD')) > new Date(moment(b).format('YYYY-MM-DD'))) {
              return -1;
            } else {
              return 1;
            }
          },
        },
      }))
    }
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'scroll' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      {
        showMenu
          ?
          <IconButton onClick={() => setShowMenu(false)} sx={{ color: blue[500], transform: 'rotate(180deg)' }} aria-label="delete">
            <ViewSidebarIcon />
          </IconButton>
          :
          <IconButton onClick={() => setShowMenu(true)} sx={{ color: grey[400] }} aria-label="delete">
            <ViewSidebarIcon />
          </IconButton>
      }
      {
        showMenu
          ?
          <PivotTableUI
            data={list}
            onChange={s => setPlotData(s)}
            {...plotData}
          />
          :
          <PivotTable
            data={list}
            onChange={s => setPlotData(s)}
            {...plotData}
          />
      }
    </Box>
  )
}

export default PivotCard;