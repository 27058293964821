export const releaseLogs = [{
    type: "Major",
    version: "2.1.0",
    releaseDate: "08-02-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: ["Region Manager", "Report Improvements"],
    description:
      "Support for multi region contract management.",
  },
  {
    type: "Major",
    version: "2.0.1",
    releaseDate: "07-12-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: ["Application Logs", "Bug Fixes"],
    description:
      "Exportable app logs for admins. Bug Fixes.",
  },
  {
    type: "Patch",
    version: "2.0.0-a",
    releaseDate: "07-02-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: ["Bug Fixes"],
    description:
      "This patch fixes bugs related to contracts form flow & change requests.",
  },
  {
    type: "Major",
    version: "2.0.0",
    releaseDate: "06-28-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: ["Form Builder", "Improved UI", "Bug Fixes"],
    description:
      "Form configurations can now be managed through the GUI. This is the foundation for the upcoming Region extensions.",
  },
  {
    type: "Major",
    version: "1.4.8",
    releaseDate: "05-24-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Downloadable logs for requested options",
      "Vendor Merge",
      "Bug Fixes",
    ],
    description:
      "Admins can now download logs for all the requested options. Vendors can now be merged with support to cherry-pick existing insurance & SDF values.",
  },
  {
    type: "Patch",
    version: "1.4.7-a",
    releaseDate: "05-10-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: ["Bug Fixes"],
    description:
      "This patch fixes bugs related to dropdowns, settings & data request approvals.",
  },
  {
    type: "Major",
    version: "1.4.9",
    releaseDate: "06-07-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: ["Change Request flow update", "Bug Fixes"],
    description:
      'Data operators should be able to make changes to "In-Process" & "In-Active" contracts even if they are already pending approval and resubmit changes.',
  },
  {
    type: "Major",
    version: "1.4.8",
    releaseDate: "05-24-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Downloadable logs for requested options",
      "Vendor Merge",
      "Bug Fixes",
    ],
    description:
      "Admins can now download logs for all the requested options. Vendors can now be merged with support to cherry-pick existing insurance & SDF values.",
  },
  {
    type: "Patch",
    version: "1.4.7-a",
    releaseDate: "05-10-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: ["Bug Fixes"],
    description:
      "This patch fixes bugs related to dropdowns, settings & data request approvals.",
  },
  {
    type: "Major",
    version: "1.4.7",
    releaseDate: "05-07-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: ["SOW Automation", "Report Improvements", "Bug Fixes"],
    description:
      "SOW module now supports automatic text extraction. The reports performance is significantly faster and supports easy switching between tabs. Improved report filters and support for new chart types added.",
  },
  {
    type: "Major",
    version: "1.4.6",
    releaseDate: "04-17-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "In-App Data Addition Requests",
      "Searchable dropdowns in contracts form",
      "Improved SOW Management Interface",
    ],
    description:
      "Users with privileges can now request to add new settings data right from within the contracts form. To streamline this process, the contracts form now uses searchable dropdown everywhere. The SOW interface is also improved to a multi-panel interface.",
  },
  {
    type: "Major",
    version: "1.4.5",
    releaseDate: "03-30-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Added Dynamic Reports for executives",
      "Improved SDF & Insurance Management",
    ],
    description:
      "This is the first beta of Dynamic Reports. Admins can now manage multiple insurance/sdf certificates for the selected vendor from a single pop-up.",
  },
  {
    type: "Patch",
    version: "1.4.4-a",
    releaseDate: "03-20-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Fixed existing contract disappears when adding a new contract",
    ],
    description:
      "This update fixes the issue with contract view list where existing contracts would disappear on adding a new contract",
  },
  {
    type: "Minor",
    version: "1.4.4",
    releaseDate: "03-16-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Log Out fix",
      "SOW Management",
      "Change Requests Modal Improvement",
      "Enable data update for pending In-Process & In-Active contracts",
    ],
    description:
      "This update offers several improvements. Users are automatically logged out after inactivity, and data management is enhanced with features for managing Statements of Work and updating data in pending contracts. Additionally, approving changes to contracts becomes more granular.",
  },
  {
    type: "Minor",
    version: "1.4.3",
    releaseDate: "03-05-2024",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Extension to Recruitment Floor",
      "Client & End-client Separation",
      "Release Logs",
    ],
    description:
      "This update focuses on improvements in contract management for MDM. It streamlines draft creation for recruiters, separates client lists for better organization, and implements a release log for easier tracking of changes.",
  },
  {
    type: "Major",
    version: "1.4.2-a",
    releaseDate: "",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Improved Contracts Input Form",
      "Fixed SDF data not loading while editing Contracts",
      "Fixed candidate name and email in contract disapproval email",
    ],
    description:
      "In this release, we introduced a comprehensive overhaul of the contracts module, resulting in a substantial performance enhancement. Users can seamlessly navigate between tabs within the contracts form while maintaining data validations, streamlining the contract creation process. Additionally, a critical bug affecting the display of SDF data in contracts has been resolved, ensuring accurate data representation. Furthermore, an issue where candidate names and emails were omitted from contract disapproval emails, despite existing headers, has been fixed, enhancing communication and transparency in the contract management workflow.",
  },
  {
    type: "Major",
    version: "1.4.2",
    releaseDate: "",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Settings UX Improvement",
      "Merge & Edit in Settings",
      "Settings log export",
      "SDF & Insurance Management",
      "End Date auto fill-in prevented",
      "Remove ADM field & improve the labels for other HR fields",
    ],
    description:
      "In this update, version 1.4.2 introduces several enhancements to the settings module, aimed at improving data management and user experience. Users now have the capability to edit and merge settings data with other options, facilitating data cleaning and preserving data integrity. The settings interface has been refined to offer a more intuitive user experience with enhanced memory management capabilities. Additionally, users can now view and export settings logs for selected date ranges directly from within the settings interface. Furthermore, improvements have been made to manage insurance and SDF data at a vendor level, ensuring that only relevant data is displayed in the contract form dropdowns. Notably, the end date in the contract form no longer auto-populates, requiring manual input, and unnecessary fields like the ADM field have been removed from the contracts form while ensuring correct labeling for other HR fields. These updates collectively enhance the efficiency and functionality of the settings module for our users.",
  },
  {
    type: "Major",
    version: "1.4.0",
    releaseDate: "",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "CX team interface",
      "HCL specific fields to be added to Non-HCL clients",
      "Vengage Integration",
      "Finance Exports Fix",
      "Minor Bug Fixes",
    ],
    description:
      "In this release, version 1.4.0 introduces support for the CX team to manage and extract data from MDM, enhancing data management capabilities. Additionally, certain fields such as Track, SOT, etc., previously visible only for HCL clients, are now accessible for non-HCL clients upon request from the contracts team, improving flexibility and inclusivity. Furthermore, VEngage is now seamlessly linked with MDM, ensuring that any contract updates made in MDM are instantly reflected in VEngage, although currently undergoing validation before full rollout to all users within two weeks. Lastly, a bug affecting the finance exports feature, which prevented the export of correct records, has been addressed, ensuring accurate data exports in this release.",
  },
  {
    type: "Major",
    version: "1.3.0",
    releaseDate: "",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Added Tentative End Date",
      "Added basic candidate information in the data exports interface",
      "Dynamic Access Control",
    ],
    description:
      "This update introduces several improvements to contract management and data export functionalities. By adding a tentative end date and making the end date mandatory only for inactive contracts, the contracts team gains better control over project end dates, facilitating more accurate management upon project end notifications and enabling finance and CX teams to export active contracts with actual end dates. Additionally, the inclusion of candidate basic information fields (such as name and email) in the export interface addresses a previous issue where these fields were missing, ensuring comprehensive data export capabilities. Furthermore, administrators and sub-administrators now have granular control over access to different sections and pages within the application, enhancing security and user management capabilities. These enhancements collectively streamline contract management processes and improve data export accuracy while providing greater control over application access for administrators.",
  },
  {
    type: "Major",
    version: "1.1.21",
    releaseDate: "",
    keyFeaturesTitle: "Highlights",
    keyFeatures: [
      "Po-Pending reversal",
      "Client/End-Client/Domain Dropdown bug fix",
      '"Associate Team Lead" addition',
      '"Nambu" added to discount approval lists',
      "Finance Exports Improvement",
      "Compliance ID added as a receiver for Expiration emails",
    ],
    description:
      'In this update, several improvements and fixes have been implemented. Firstly, the reversal of the PO-Pending Status introduced in v1.1.19 has been executed, transitioning five contracts from this status to active, with their respective PO-pending checkbox values set to true, now toggleable from the contracts form. Additionally, the issue of IDs displaying instead of actual values in certain dropdowns has been resolved. Furthermore, enhancements include the addition of a new dropdown in the HR section to capture Associate Team Lead information, the inclusion of "Nambu" as a third option in the approval by dropdowns alongside "Vinay" and "Suresh," and the introduction of a new dropdown in the finance exports interface allowing users to select contracts specifically starting or ending in the chosen range. Lastly, compliance@vdartinc.com has been added as the recipient for all expiration-related emails, with admins and sub-admins included in the CC, improving communication and data management processes across the platform.',
  },
];
