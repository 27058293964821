export const userRole = {
  ADMIN: 1,
  SUBADMIN: 2,
  DATAOPERATER: 3,
  EXECUTIVE: 4,
  ITEXECUTIVE: 5,
  FINANCE: 6,
  CXTEAM: 7,
  RECRUITERADMIN:8,
  RECRUITER:9,
  SOWMANAGER:10,
};

export const userRoleLabels = {
  1: "Admin",
  2: "Sub admin",
  3: "Data Operator",
  4: "Executive",
  5: "IT Admin",
  6: "Finance",
  7: "CX Team",
  8: "Recruitment Admin",
  9: "Recruiter",
  10: "SOW Manager",
};

export const userRoleArray = [
  {
    id: 1,
    label: "Admin",
    value: "Admin",
  },
  {
    id: 2,
    label: "Sub admin",
    value: "Sub admin",
  },
  {
    id: 3,
    label: "Data Operator",
    value: "Data Operator",
  },
  {
    id: 4,
    label: "Executive",
    value: "Executive",
  },
  {
    id: 5,
    label: "IT Admin",
    value: "IT Admin",
  },
  {
    id: 6,
    label: "Finance",
    value: "Finance",
  },
  {
    id: 7,
    label: "CX Team",
    value: "CX Team"
  },
  {
    id: 8,
    label: "Recruiter Admin",
    value: "Recruiter Admin"
  },
  {
    id: 9,
    label: "Recruiter User",
    value: "Recruiter User"
  },
  {
    id: 10,
    label: "SOW Manager",
    value: "SOW Manager"
  },
]
