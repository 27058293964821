import { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import DividerComponent from '../../../../../components/Divider/Divider';
import { toast } from 'react-toastify';
import InputTypeText from './InputTypeText';
import InputTypeDropdown from './InputTypeDropdown';
import InputTypeLocation from './InputTypeLocation';
import InputTypePlan from './InputTypePlan';
import InputTypeUpload from './InputTypeUpload';
import { formComponents } from '../../../../../utils/consultantConstants';

const InputMakerModal = ({ open = false, handleClose = () => { }, handleAdd = () => { }, currentData = null, handleUpdate = () => { } }) => {
	const [data, setData] = useState(null);
	const [activeType, setActiveType] = useState(null);
	const [activeVarient, setActiveVarient] = useState(null);
	const [activeSelection, setActiveSelection] = useState(null);
	const [optionValue, setOptionValue] = useState(null);
	const [requestVariable, setRequestVariable] = useState(null);

	useEffect(() => {
		if (currentData) {
			if (currentData?.type === 'location') {
				let d = { ...currentData };
				d = {
					// address
					addressLabel: currentData?.address?.label || "",
					addressVariable: currentData?.address?.variable || "",
					// country
					countryLabel: currentData?.country?.label || "",
					countryVariable: currentData?.country?.variable || "",
					// state
					stateLabel: currentData?.state?.label || "",
					stateVariable: currentData?.state?.variable || "",
					// city
					cityLabel: currentData?.city?.label || "",
					cityVariable: currentData?.city?.variable || "",
					// zipCode
					zipCodeLabel: currentData?.zipCode?.label || "",
					zipCodeVariable: currentData?.zipCode?.variable || "",
				}
				setData(d);
			} else {
				setData({ ...currentData, showTooltip: currentData?.tooltips?.length || false, confidential: currentData?.confidential ? true : false, confidentialKey: currentData?.confidential || "" });
			}
			if (currentData?.configType) {
				formComponents.forEach((iT, iTIndex) => {
					if ((iT?.label || "").toLowerCase() === currentData.configType) {
						setActiveType(iTIndex);
						iT.variants.forEach((v, vIndex) => {
							if ((v?.label || "").toLowerCase() === currentData.configVariant) {
								setActiveVarient(vIndex);
								setActiveSelection({
									type: (iT?.label || "").toLowerCase(),
									variant: (v?.label || "").toLowerCase(),
									key: (v?.key || "").toLowerCase(),
								});
							}
						})
					}
				});
			}
		}
	}, [currentData])

	const handleActiveType = (value = 0) => {
		if (value !== activeType) {
			setActiveType(value);
			setActiveVarient(0);
			setActiveSelection({
				type: (formComponents[value]?.label || "").toLowerCase(),
				variant: (formComponents[value]?.variants[0]?.label || "").toLowerCase(),
				key: (formComponents[value]?.variants[0]?.key || "").toLowerCase(),
			});
			setData(null);
		}
	}

	const handleActiveVarient = (value = 0) => {
		if (value !== activeVarient) {
			setActiveVarient(value);
			setActiveSelection({
				type: (formComponents[activeType]?.key || "").toLowerCase(),
				variant: (formComponents[activeType]?.variants[value]?.label || "").toLowerCase(),
				key: (formComponents[activeType]?.variants[value]?.key || "").toLowerCase(),
			});
			setOptionValue(null);
			setRequestVariable(null);
		}
	}

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const handleModalClose = () => {
		handleClose();
	}

	const checkValidations = () => {
		let valid = false;
		if (activeSelection?.key === 'location') {
			if (data?.addressLabel && data?.addressVariable) {
				valid = true;
			} else if (data?.countryLabel && data?.countryVariable) {
				valid = true;
			} else if (data?.stateLabel && data?.stateVariable) {
				valid = true;
			} else if (data?.cityLabel && data?.cityVariable) {
				valid = true;
			} else if (data?.zipCodeLabel && data?.zipCodeVariable) {
				valid = true;
			} else {
				toast.error('Please fill atleast one location parameter!');
			}
		} else if (activeSelection?.key === 'searchable-dropdown' && data?.label && data?.variable && data?.searchVariable) {
			valid = true;
		} else if (activeSelection?.key === 'dropdown' && data?.label && data?.variable && data?.options?.length) {
			valid = true;
		} else if ((activeSelection?.key === 'text' || activeSelection?.key === 'phone' || activeSelection?.key === 'email' || activeSelection?.key === 'date') && data?.label && data?.variable) {
			valid = true;
		} else if (activeSelection?.key === 'plan' && data?.label && data?.variable && data?.searchVariable) {
			valid = true;
		} else if (activeSelection?.key === 'upload' && data?.list?.length) {
			valid = true;
		} else {
			toast.error('Please fill all the required fields!');
		}
		return valid;
	}

	const handleAddInput = () => {
		let valid = checkValidations();
		if (valid) {
			let payload = null;
			if (activeSelection?.key === 'location') {
				payload = {
					type: activeSelection?.key,
					label: data?.addressLabel || data?.countryLabel || data?.stateLabel || data?.cityLabel || data?.zipCodeLabel || "",
					variable: data?.addressVariable || data?.countryVariable || data?.stateVariable || data?.cityVariable || data?.zipCodeVariable || "",
					searchVariable: "",
					confidential: "",
					confidentialLabel: "",
					textLength: "",
					prefix: "",
					systemRequired: false,
					decimal: false,
					multiple: false,
					request: false,
					requestVariables: [],
					tooltips: [],
					options: [],
					list: [],
					address: {
						label: data?.addressLabel || '',
						variable: data?.addressVariable,
					},
					country: {
						label: data?.countryLabel || '',
						variable: data?.countryVariable,
					},
					state: {
						label: data?.stateLabel || '',
						variable: data?.stateVariable,
					},
					city: {
						label: data?.cityLabel || '',
						variable: data?.cityVariable,
					},
					zipCode: {
						label: data?.zipCodeLabel || '',
						variable: data?.zipCodeVariable,
					},
					systemRequired: data?.systemRequired || false,
					configType: activeSelection?.type || "",
					configVariant: activeSelection?.variant || "",
				}
			} else if (activeSelection?.key === 'upload') {
				payload = {
					type: activeSelection?.key,
					label: new Date().getTime(),
					variable: new Date().getTime(),
					searchVariable: "",
					confidential: "",
					confidentialLabel: "",
					textLength: "",
					prefix: "",
					systemRequired: false,
					decimal: false,
					multiple: false,
					request: false,
					requestVariables: [],
					tooltips: [],
					options: [],
					list: data?.list || [],
					configType: activeSelection?.type || "",
					configVariant: activeSelection?.variant || "",
				};
			} else {
				payload = {
					type: activeSelection?.key,
					label: data?.label || "",
					variable: data?.variable || "",
					searchVariable: data?.searchVariable || "",
					confidential: data?.confidential ? data?.confidentialKey : "",
					confidentialLabel: data?.confidential ? data?.confidentialLabel : "",
					textLength: data?.textLength || "",
					prefix: data?.prefix || "",
					systemRequired: data?.systemRequired || false,
					decimal: data?.decimal || false,
					multiple: data?.multiple || false,
					request: data?.request || false,
					requestVariables: data?.requestVariables || [],
					tooltips: data?.tooltips || [],
					options: data?.options || [],
					list: data?.list || [],
					configType: activeSelection?.type || "",
					configVariant: activeSelection?.variant || "",
				};
			}
			handleAdd(payload);
		}
	}

	const handleUpdateInput = () => {
		let valid = checkValidations();
		if (valid) {
			let payload = {};
			if (activeSelection?.key === 'location') {
				payload = {
					type: activeSelection?.key,
					label: data?.addressLabel || data?.countryLabel || data?.stateLabel || data?.cityLabel || data?.zipCodeLabel || "",
					variable: data?.addressVariable || data?.countryVariable || data?.stateVariable || data?.cityVariable || data?.zipCodeVariable || "",
					searchVariable: "",
					confidential: "",
					confidentialLabel: "",
					textLength: "",
					prefix: "",
					systemRequired: false,
					decimal: false,
					multiple: false,
					request: false,
					requestVariables: [],
					tooltips: [],
					options: [],
					list: [],
					address: {
						label: data?.addressLabel || '',
						variable: data?.addressVariable,
					},
					country: {
						label: data?.countryLabel || '',
						variable: data?.countryVariable,
					},
					state: {
						label: data?.stateLabel || '',
						variable: data?.stateVariable,
					},
					city: {
						label: data?.cityLabel || '',
						variable: data?.cityVariable,
					},
					zipCode: {
						label: data?.zipCodeLabel || '',
						variable: data?.zipCodeVariable,
					},
					systemRequired: data?.systemRequired || false,
					configType: activeSelection?.type || "",
					configVariant: activeSelection?.variant || "",
					id: currentData.id,
				}
			} else if (activeSelection?.key === 'upload') {
				payload = {
					type: activeSelection?.key,
					label: new Date().getTime(),
					variable: new Date().getTime(),
					searchVariable: "",
					confidential: "",
					confidentialLabel: "",
					textLength: "",
					prefix: "",
					systemRequired: false,
					decimal: false,
					multiple: false,
					request: false,
					requestVariables: [],
					tooltips: [],
					options: [],
					list: data?.list || [],
					configType: activeSelection?.type || "",
					configVariant: activeSelection?.variant || "",
				};
			} else {
				payload = {
					type: activeSelection?.key,
					label: data?.label || "",
					variable: data?.variable || "",
					searchVariable: data?.searchVariable || "",
					confidential: data?.confidential ? data?.confidentialKey : "",
					confidentialLabel: data?.confidential ? data?.confidentialLabel : "",
					textLength: data?.textLength || null,
					prefix: data?.prefix || "",
					systemRequired: data?.systemRequired || false,
					decimal: data?.decimal || false,
					multiple: data?.multiple || false,
					request: data?.request || false,
					requestVariables: data?.requestVariables || [],
					tooltips: data?.tooltips || [],
					options: data?.options || [],
					list: data?.list || [],
					configType: activeSelection?.type || "",
					configVariant: activeSelection?.variant || "",
					id: currentData.id,
				};
			}
			handleUpdate(payload);
		}
	}

	const handleAddOption = () => {
		if (optionValue?.label && optionValue?.value) {
			setData(prev => {
				let options = prev?.options || [];
				return (
					{
						...prev,
						options: [
							...options,
							optionValue,
						]
					}
				)
			});
			setOptionValue(null);
		} else {
			if (!optionValue?.label) {
				toast.error('Please add a label!');
			} else {
				toast.error('Please enter a value!');
			}
		}
	}

	const handleRemoveOption = (value = "") => {
		setData(prev => {
			let options = (prev?.options || []).filter(item => item?.value !== value);
			return (
				{
					...prev,
					options: options
				}
			)
		});
	}

	const handleRemoveTooltip = (value = "") => {
		setData(prev => {
			let tooltips = (prev?.tooltips || []).filter(item => item?.key !== value);
			return (
				{
					...prev,
					tooltips: tooltips
				}
			)
		});
	}

	const handleRemoveRequestVariables = (value = "") => {
		setData(prev => {
			let requestVariables = (prev?.requestVariables || []).filter(item => item?.key !== value);
			return (
				{
					...prev,
					requestVariables: requestVariables
				}
			)
		});
	}

	const handleAddRequestVariable = () => {
		if (requestVariable?.label && requestVariable?.key) {
			setData(prev => {
				let requestVariables = prev?.requestVariables || [];
				return (
					{
						...prev,
						requestVariables: [
							...requestVariables,
							requestVariable,
						]
					}
				)
			});
			setRequestVariable(null);
		} else {
			if (!requestVariable?.label) {
				toast.error('Please add a label!');
			} else {
				toast.error('Please enter a key!');
			}
		}
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-tab-modal"
			aria-describedby="add-tab-modal-description"
			fullWidth
			maxWidth={"lg"}
		>
			<DialogTitle sx={{ color: '#000000DE' }}>{currentData !== null ? `Update form component` : `Add new component`}</DialogTitle>
			<DialogContent>
				<Typography variant='subtitle2'>Type</Typography>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mt: 0.5 }}>
					{formComponents.map((inputType, inputIndex) => {
						return (
							<Button id={'input-type-'+inputType.label} onClick={() => handleActiveType(inputIndex)} sx={{ color: activeType === inputIndex ? '#1976D2' : '#6f6f6f', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 1, border: activeType === inputIndex ? '2px solid #1976D2' : '2px solid #6f6f6f', borderRadius: 1, width: 120, height: 40, pl: 1, cursor: activeType === inputIndex ? 'default' : 'pointer', textTransform: 'none' }}>
								{inputType?.icon || null}
								<Typography variant='subtitle2' sx={{ color: activeType === inputIndex ? '#1976D2' : '#6f6f6f' }}>{inputType?.label || ""}</Typography>
							</Button>
						)
					})}
				</Box>
				<DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
				<Typography variant='subtitle2'>Varient</Typography>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
					{(formComponents[activeType]?.variants?.map((variant, varientIndex) => {
						return (
							<Button id={'variant-type-'+variant?.label} onClick={() => handleActiveVarient(varientIndex)} sx={{ color: activeVarient === varientIndex ? '#1976D2' : '#6f6f6f', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 1, border: activeVarient === varientIndex ? '2px solid #1976D2' : '2px solid #6f6f6f', borderRadius: 1, width: 120, height: 40, pl: 1, cursor: activeVarient === varientIndex ? 'default' : 'pointer', textTransform: 'none' }}>
								{variant?.icon || null}
								<Typography variant='subtitle2' sx={{ color: activeVarient === varientIndex ? '#1976D2' : '#6f6f6f' }}>{variant?.label || ""}</Typography>
							</Button>
						)
					}))}
				</Box>
				{activeSelection?.type === 'text'
					?
					<InputTypeText handleChange={handleChange} activeSelection={activeSelection} data={data} />
					:
					null
				}
				{activeSelection?.type === 'dropdown'
					?
					<InputTypeDropdown
						handleChange={handleChange}
						activeSelection={activeSelection}
						data={data}
						handleRemoveOption={handleRemoveOption}
						handleAddOption={handleAddOption}
						handleRemoveTooltip={handleRemoveTooltip}
						setRequestVariable={setRequestVariable}
						requestVariable={requestVariable}
						handleRemoveRequestVariables={handleRemoveRequestVariables}
						handleAddRequestVariable={handleAddRequestVariable}
					/>
					:
					null
				}
				{activeSelection?.type === 'location'
					?
					<InputTypeLocation handleChange={handleChange} activeSelection={activeSelection} data={data} />
					:
					null
				}
				{activeSelection?.type === 'plan'
					?
					<InputTypePlan
						handleChange={handleChange}
						data={data}
						setRequestVariable={setRequestVariable}
						requestVariable={requestVariable}
						handleRemoveRequestVariables={handleRemoveRequestVariables}
						handleAddRequestVariable={handleAddRequestVariable}
					/>
					:
					null
				}
				{activeSelection?.type === 'upload'
					?
					<InputTypeUpload
						handleChange={handleChange}
						data={data}
					/>
					:
					null
				}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleModalClose}>Cancel</Button>
				{currentData !== null
					?
					<Button id='input-cancel-btn' name='input-cancel-btn' onClick={handleUpdateInput} variant='contained'>Update</Button>
					:
					(activeSelection?.key
						?
						<Button id='input-add-btn' name='input-add-btn' onClick={handleAddInput} variant='contained'>Add</Button>
						:
						null
					)
				}
			</DialogActions>
		</Dialog>
	)
}

export default InputMakerModal;