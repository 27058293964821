import { CREATE_DASHBOARD_USER_FAILED, CREATE_DASHBOARD_USER_IN_PROGRESS, CREATE_DASHBOARD_USER_SUCCESS, CREATE_REPORT_GROUP_FAILED, CREATE_REPORT_GROUP_IN_PROGRESS, CREATE_REPORT_GROUP_SUCCESS, GET_DASHBOARD_CARD_INFO_FAILED, GET_DASHBOARD_CARD_INFO_IN_PROGRESS, GET_DASHBOARD_CARD_INFO_SUCCESS, GET_DASHBOARD_DROPDOWN_OPTIONS_FAILED, GET_DASHBOARD_DROPDOWN_OPTIONS_IN_PROGRESS, GET_DASHBOARD_DROPDOWN_OPTIONS_SUCCESS, GET_DASHBOARD_USERS_FAILED, GET_DASHBOARD_USERS_IN_PROGRESS, GET_DASHBOARD_USERS_SUCCESS, GET_REPORT_DASHBOARDS_FAILED, GET_REPORT_DASHBOARDS_IN_PROGRESS, GET_REPORT_DASHBOARDS_SUCCESS, GET_REPORT_DASHBOARD_INFO_FAILED, GET_REPORT_DASHBOARD_INFO_IN_PROGRESS, GET_REPORT_DASHBOARD_INFO_SUCCESS, GET_REPORT_GROUPS_FAILED, GET_REPORT_GROUPS_IN_PROGRESS, GET_REPORT_GROUPS_SUCCESS, GET_REPORT_GROUP_INFO_FAILED, GET_REPORT_GROUP_INFO_IN_PROGRESS, GET_REPORT_GROUP_INFO_SUCCESS, GET_REPORT_USER_DASHBOARDS_FAILED, GET_REPORT_USER_DASHBOARDS_IN_PROGRESS, GET_REPORT_USER_DASHBOARDS_SUCCESS, STORE_DASHBOARD_FILTER_FAILED, STORE_DASHBOARD_FILTER_IN_PROGRESS, STORE_DASHBOARD_FILTER_SUCCESS } from "../../constants/store";
import { extractMetabaseOptions } from "../../utils/chartHelper";

export const initialState = {
  loading: false,
  userDashboards: [],
  dashboards: [],
  activeDashboards: {},
  groups: [],
  groupInfo: null,
  users: [],
  cards: {},
  dropdownOptions: {},
  loadingDropdown: false,
  dashboardFilters: {},
  loadingCards: [],
};

function reportsReducer(state = initialState, action) {
  switch (action.type) {
    // GET ALL DASHBOARDS
    case GET_REPORT_USER_DASHBOARDS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_REPORT_USER_DASHBOARDS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        userDashboards: action?.payload?.list || [],
      };
      return newState;
    }
    case GET_REPORT_USER_DASHBOARDS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // GET ALL DASHBOARDS
    case GET_REPORT_DASHBOARDS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_REPORT_DASHBOARDS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        dashboards: action?.payload?.list || [],
      };
      return newState;
    }
    case GET_REPORT_DASHBOARDS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // REPORT_DASHBOARD_INFO
    case GET_REPORT_DASHBOARD_INFO_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_REPORT_DASHBOARD_INFO_SUCCESS: {
      const { info, id } = action.payload;
      const newState = {
        ...state,
        loading: false,
        activeDashboards: {
          ...state.activeDashboards,
          [id]: info,
        },
      };
      return newState;
    }
    case GET_REPORT_DASHBOARD_INFO_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // REPORT_GROUPS
    case GET_REPORT_GROUPS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_REPORT_GROUPS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        groups: action?.payload?.list || [],
      };
      return newState;
    }
    case GET_REPORT_GROUPS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // CREATE_REPORT_GROUP
    case CREATE_REPORT_GROUP_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case CREATE_REPORT_GROUP_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case CREATE_REPORT_GROUP_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // REPORT_GROUP_INFO
    case GET_REPORT_GROUP_INFO_IN_PROGRESS: {
      const newState = {
        ...state,
        groupInfo: null,
        loading: true,
      };
      return newState;
    }
    case GET_REPORT_GROUP_INFO_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        groupInfo: action?.payload?.info || null,
      };
      return newState;
    }
    case GET_REPORT_GROUP_INFO_FAILED: {
      const newState = {
        ...state,
        groupInfo: null,
        loading: false,
      };
      return newState;
    }
    // GET DASHBOARD USERS
    case GET_DASHBOARD_USERS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case GET_DASHBOARD_USERS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        users: action?.payload?.list || [],
      };
      return newState;
    }
    case GET_DASHBOARD_USERS_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // CREATE_DASHBOARD_USER
    case CREATE_DASHBOARD_USER_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case CREATE_DASHBOARD_USER_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    case CREATE_DASHBOARD_USER_FAILED: {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    }
    // GET CARD INFO
    case GET_DASHBOARD_CARD_INFO_IN_PROGRESS: {
      const { cardId } = action.payload;
      let cardList = state.loadingCards.filter(item => item !== cardId);
      cardList.push(cardId);
      const newState = {
        ...state,
        loadingCards: cardList,
      };
      return newState;
    }
    case GET_DASHBOARD_CARD_INFO_SUCCESS: {
      const { cardId, cardInfo } = action.payload;
      let cardList = state.loadingCards.filter(item => item !== cardId);
      const newState = {
        ...state,
        loadingCards: cardList,
        cards: {
          ...state.cards,
          [cardId]: cardInfo,
        }
      };
      return newState;
    }
    case GET_DASHBOARD_CARD_INFO_FAILED: {
      const { cardId } = action.payload;
      let cardList = state.loadingCards.filter(item => item !== cardId);
      const newState = {
        ...state,
        loadingCards: cardList,
      };
      return newState;
    }
    // GET_DASHBOARD_DROPDOWN_OPTIONS
    case GET_DASHBOARD_DROPDOWN_OPTIONS_IN_PROGRESS: {
      const newState = {
        ...state,
        loadingDropdown: true,
      };
      return newState;
    }
    case GET_DASHBOARD_DROPDOWN_OPTIONS_SUCCESS: {
      const { options, id } = action.payload;
      let list = extractMetabaseOptions(options);
      const newState = {
        ...state,
        dropdownOptions: {
          ...state.dropdownOptions,
          [id]: list,
        },
        loadingDropdown: false,
      };
      return newState;
    }
    case GET_DASHBOARD_DROPDOWN_OPTIONS_FAILED: {
      const newState = {
        ...state,
        loadingDropdown: false,
      };
      return newState;
    }
    // STORE_DASHBOARD_FILTER
    case STORE_DASHBOARD_FILTER_IN_PROGRESS: {
      return state;
    }
    case STORE_DASHBOARD_FILTER_SUCCESS: {
      const { filters, id } = action.payload;
      const newState = {
        ...state,
        dashboardFilters: {
          ...state.filters,
          [id]: filters,
        },
        loadingDropdown: false,
      };
      return newState;
    }
    case STORE_DASHBOARD_FILTER_FAILED: {
      return state;
    }
    default:
      return state;
  }
}

export default reportsReducer;
