import api from "../../../config/https";
import {
  SET_LOADING_TRUE,
  SET_LOADING_FALSE,
  UPDATE_CONTRACT_STATUS_IN_PROGRESS,
  UPDATE_CONTRACT_STATUS_SUCCESS,
  UPDATE_CONTRACT_STATUS_FAILED,
} from "../../../constants/store";

export const updateContractStatus = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CONTRACT_STATUS_IN_PROGRESS,
      payload: {
        loading: true,
        error: false,
      },
    });
    dispatch({
      type: SET_LOADING_TRUE,
    });

    let res = await api.put(
      `/masterAdmin/contract/contractStatus/${payload.contractId}`,
      { contractStatus: payload.status }
    );
    if (res?.data?.data) {
      let isSuccess = res?.data?.status;
      dispatch({
        type: UPDATE_CONTRACT_STATUS_SUCCESS,
        payload: {
          error: !isSuccess,
          success: isSuccess,
          message: res?.data?.message,
        },
      });
      setTimeout(() => {
        dispatch({
          type: UPDATE_CONTRACT_STATUS_SUCCESS,
          payload: { error: false, success: false, message: "" },
        });
        dispatch({
          type: SET_LOADING_FALSE,
        });
      }, 3000);
    }
    return res;
  } catch (e) {
    dispatch({
      type: UPDATE_CONTRACT_STATUS_FAILED,
      payload: {
        loading: false,
        error: true,
      },
    });
    dispatch({
      type: SET_LOADING_FALSE,
    });
  }
};
