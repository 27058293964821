import _ from "lodash";
// icons
import Candidates from "../assets/image/cand.png";
import Rec from '../assets/image/rec.png';
import PenRec from '../assets/image/penRec.png';
import Approvals from "../assets/image/cr.png";
import SOW from "../assets/image/sow.png";
import Settings from "../assets/image/set.png";
import Exports from "../assets/image/export.png";
import Profiles from "../assets/image/roles.png";
import Reports from "../assets/image/report.png";
import RequestOptions from "../assets/image/requestOptions.png";
import Logs from "../assets/image/logs.png";


export const formatSectionData = (permissionList = []) => {
  let list = _.groupBy(permissionList, item => item.parent_id);
  let newList = [];
  list['null']?.forEach(section => {
    newList.push({ id: parseInt(section.id), name: section.name, identifier: section.identifier, created_at: section.created_at, updated_at: section.updated_at })
  });
  Object.keys(list).forEach(listKey => {
    if (listKey !== 'null') {
      const children = [];
      list[listKey]?.forEach(child => {
        children.push({ id: parseInt(child.id), name: child.name, identifier: child.identifier, created_at: child.created_at, updated_at: child.updated_at, parent: child.parent_id })
      });
      let foundItem = _.find(newList, { id: parseInt(listKey) });
      foundItem.children = children;
      newList = newList.map(item => item?.id === foundItem?.id ? foundItem : item)
    }
  });
  return newList;
};

export const formatPermissionData = (permissionList = []) => {
  let data = {};
  permissionList?.forEach(section => {
    data = {
      ...data,
      [section.identifier]: {
        read: (section?.actions || [])?.includes('READ'),
        write: (section?.actions || [])?.includes('WRITE'),
      },
    };
  });
  return data;
};

export const getIconFromIdentifier = (identifier = '') => {
  switch (identifier) {
    case 'consultants':
      return Candidates;
    case 'recruitmentDrafts':
      return Rec;
    case 'pendingRecruitmentDrafts':
      return PenRec;
    case 'approvals':
      return Approvals;
    case 'sow':
      return SOW;
    case 'settings':
      return Settings;
    case 'financeExport':
      return Exports;
    case 'userManagement':
      return Profiles;
    case 'reports':
      return Reports;
    case 'requestOptions':
      return RequestOptions;
    case 'logs':
      return Logs;
    default:
      return null;
  }
};

export const formatPermissionPayloadData = (permissions = null, permissionList = []) => {
  let list = [];
  Object.keys(permissions)?.forEach(permissionKey => {
    let foundItem = _.find(permissionList, { identifier: permissionKey });
    if (foundItem) {
      let item = {
        ...foundItem,
        actions: [],
      };
      if (permissions[permissionKey]?.read) {
        item.actions.push('READ');
      };
      if (permissions[permissionKey]?.write) {
        item.actions.push('WRITE');
      };
      delete item.children;
      list.push(item);
      if (foundItem?.children?.length) {
        foundItem?.children?.forEach(child => {
          let childItem = {
            ...child,
            actions: [],
          };
          if (permissions[permissionKey]?.read) {
            childItem.actions.push('READ');
          };
          if (permissions[permissionKey]?.write) {
            childItem.actions.push('WRITE');
          };
          list.push(childItem);
        });
      }
    }
  });
  return list;
};