import { Box, Button, ButtonGroup, Grid, TextField, Typography } from '@mui/material';
import DividerComponent from '../../../../../components/Divider/Divider';
import LocalSearchableDropdown from '../../../../../components/LocalSearchableDropdown';
import { cityOptions, countryOptions, stateOptions } from '../../../../../utils/consultantConstants';
import { useState } from 'react';

const InputTypeLocation = ({ handleChange = () => { }, data = null, }) => {
  const [options, setOptions] = useState(null);
  const [values, setValues] = useState(null);

  const handleChangeLocationData = (name = "", value = "", belong = "") => {
    if (belong === 'country') {
      let op = stateOptions(value);
      setOptions(prev => ({ ...prev, stateOptions: op, cityOptions: [] }));
      setValues(prev => ({ ...prev, [name]: value, stateValue: "", cityValue: "" }));
    } else if (belong === 'state') {
      let op = cityOptions(values.countryValue, value);
      setOptions(prev => ({ ...prev, cityOptions: op }));
      setValues(prev => ({ ...prev, [name]: value, cityValue: "" }));
    } else {
      setValues(prev => ({ ...prev, [name]: value }));
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Configuration</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Address Label</Typography>
          <TextField
            value={data?.addressLabel || ""}
            onChange={(e) => handleChange('addressLabel', e.target.value)}
            placeholder={"Please enter a label!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'address-label-input'}
            name={'address-label-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Address Variable Name</Typography>
          <TextField
            value={data?.addressVariable || ""}
            onChange={(e) => handleChange('addressVariable', e.target.value)}
            placeholder={"Please enter a variable name!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'address-variable-input'}
            name={'address-variable-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Country Label</Typography>
          <TextField
            value={data?.countryLabel || ""}
            onChange={(e) => handleChange('countryLabel', e.target.value)}
            placeholder={"Please enter a label!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'country-label-input'}
            name={'country-label-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Country Variable Name</Typography>
          <TextField
            value={data?.countryVariable || ""}
            onChange={(e) => handleChange('countryVariable', e.target.value)}
            placeholder={"Please enter a variable name!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'country-variable-input'}
            name={'country-variable-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>State Label</Typography>
          <TextField
            value={data?.stateLabel || ""}
            onChange={(e) => handleChange('stateLabel', e.target.value)}
            placeholder={"Please enter a label!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'state-label-input'}
            name={'state-label-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>State Variable Name</Typography>
          <TextField
            value={data?.stateVariable || ""}
            onChange={(e) => handleChange('stateVariable', e.target.value)}
            placeholder={"Please enter a variable name!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'state-variable-input'}
            name={'state-variable-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>City Label</Typography>
          <TextField
            value={data?.cityLabel || ""}
            onChange={(e) => handleChange('cityLabel', e.target.value)}
            placeholder={"Please enter a label!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'city-label-input'}
            name={'city-label-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>City Variable Name</Typography>
          <TextField
            value={data?.cityVariable || ""}
            onChange={(e) => handleChange('cityVariable', e.target.value)}
            placeholder={"Please enter a variable name!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'city-variable-input'}
            name={'city-variable-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Zip Code Label</Typography>
          <TextField
            value={data?.zipCodeLabel || ""}
            onChange={(e) => handleChange('zipCodeLabel', e.target.value)}
            placeholder={"Please enter a label!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'zipCode-label-input'}
            name={'zipCode-label-input'}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Zip Code Variable Name</Typography>
          <TextField
            value={data?.zipCodeVariable || ""}
            onChange={(e) => handleChange('zipCodeVariable', e.target.value)}
            placeholder={"Please enter a variable name!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'zipCode-variable-input'}
            name={'zipCode-variable-input'}
          />
        </Grid>
      </Grid>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2'>Preview</Typography>
      <Grid container spacing={2}>
        {data?.addressLabel
          ?
          <Grid item xs={12}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>{data?.addressLabel || ""} {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
            <TextField
              value={values?.addressValue || ""}
              onChange={(e) => handleChangeLocationData('addressValue', e?.target?.value)}
              placeholder={"Please enter " + (data?.addressLabel || "Address").toLowerCase() + "!"}
              sx={{ width: "100%", background: "#FFFFFF" }}
              id={'addressValue'}
              name={'addressValue'}
            />
          </Grid>
          :
          null
        }
        {data?.countryLabel
          ?
          <Grid item xs={4}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>{data?.countryLabel || "Country"} {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
            <LocalSearchableDropdown
              value={values?.countryValue || null}
              label={data?.countryLabel}
              options={countryOptions}
              handleChange={(v) => handleChangeLocationData('countryValue', v?.value, 'country')}
              id={'countryValue'}
            />
          </Grid>
          :
          null
        }
        {data?.stateLabel && options?.stateOptions?.length
          ?
          <Grid item xs={4}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>{data?.stateLabel || ""} {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
            <LocalSearchableDropdown
              value={values?.stateValue || null}
              label={data?.stateLabel}
              options={options?.stateOptions || []}
              handleChange={(v) => handleChangeLocationData('stateValue', v?.value, 'state')}
              id={'stateValue'}
            />
          </Grid>
          :
          null
        }
        {data?.cityLabel && options?.cityOptions?.length
          ?
          <Grid item xs={4}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>{data?.cityLabel || ""} {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
            <LocalSearchableDropdown
              value={values?.cityValue || null}
              label={data?.cityLabel}
              options={options?.cityOptions || []}
              handleChange={(v) => handleChangeLocationData('cityValue', v?.value, 'city')}
              id={'cityValue'}
            />
          </Grid>
          :
          null
        }
        {data?.zipCodeLabel
          ?
          <Grid item xs={4}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>{data?.zipCodeLabel || ""} {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
            <TextField
              value={values?.zipCodeValue || ""}
              onChange={(e) => handleChangeLocationData('zipCodeValue', e?.target?.value)}
              placeholder={"Please enter " + (data?.zipCodeLabel || "").toLowerCase() + "!"}
              sx={{ width: "100%", background: "#FFFFFF" }}
              id={'zipCodeValue'}
              name={'zipCodeValue'}
            />
          </Grid>
          :
          null
        }
      </Grid>
    </Box>
  )
}

export default InputTypeLocation;