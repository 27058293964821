import { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, CartesianGrid, LabelList } from 'recharts';
import { COLORS, extractLineDataPoints } from '../../utils/chartHelper';
import { Box, Typography } from '@mui/material';
import NoDataCard from './noDataCard';
import RenderCustomLineLabel from './customLineLabel';
import CustomTooltip from './CustomTooltip';
import CustomizedAxisTick from './customAxisTick';
import calculateSize from 'calculate-size';

const LineCard = ({ data = null, visualizationData = null, title = "", showAnimation = true }) => {
  const [axes, setAxes] = useState([]);
  const [list, setList] = useState([]);
  const [xName, setXName] = useState("");
  const [yName, setYName] = useState("");
  const [chartTitle, setTitle] = useState("");
  const [size, setSize] = useState(null);
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const dataPoints = extractLineDataPoints(data, visualizationData, title);
    setList(dataPoints?.list || []);
    setAxes(dataPoints?.axes || []);
    setXName(dataPoints?.xName || "");
    setYName(dataPoints?.yName || "");
    setTitle(dataPoints?.title || title);
    setSize(dataPoints?.size || null);
    setGroups(dataPoints?.groups || []);
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  const yNameSize = calculateSize(yName || "", {
    font: 'Poppins',
    fontSize: '14px',
  });

  const gap = size?.width ? size.width / 2 : 10;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <Box sx={{ width: '100%', height: xName ? '85%' : '95%', display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative', mt: 0.5 }}>
        {yName ?
          <div style={{ whiteSpace: 'normal', verticalAlign: 'bottom', width: 40 }}>
            <span style={{ transformOrigin: 'left', transform: "translate(50%," + (yNameSize.width / 2 + yNameSize.height / 2) + "px) rotate(-90deg)", display: 'block', textWrap: 'nowrap', fontSize: 14 }}>{yName}</span>
          </div>
          :
          null
        }
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={list}
            margin={{
              top: 0,
              right: gap > 40 ? 40 : gap,
              bottom: 0,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {groups?.length > 2
              ?
              <XAxis padding={{ left: size?.width ? size.width / 2 : 0, right: size?.width ? size.width / 2 : 0 }} height={size?.height ? (size?.width > 100 || list?.length > 10 ? 80 : size.height + 10) : 25} dataKey={axes[1]?.dataKey || null} tick={<CustomizedAxisTick rotate={size?.width > 100 || list?.length > 10} />} interval={0} />
              :
              <XAxis padding={{ left: size?.width ? size.width / 2 : 0, right: size?.width ? size.width / 2 : 0 }} height={size?.height ? (size?.width > 100 ? 80 : size.height + 10) : 25} dataKey={axes[0]?.dataKey || null} tick={<CustomizedAxisTick rotate={size?.width > 100} />} interval={0} />
            }<YAxis allowDataOverflow={true} />
            <Tooltip content={<CustomTooltip />} />
            <Legend align="left" verticalAlign='top' wrapperStyle={{ paddingBottom: gap / 2 > 20 ? 20 : gap / 2 }} />
            {(groups?.length ? groups : axes)?.map((item, index) => {
              if (index > 0 || groups?.length) {
                return (
                  <Line isAnimationActive={showAnimation} unit={item?.unit} name={item.displayName} legendType='line' key={`line-chart-gp-${index}`} dataKey={item.dataKey} stroke={COLORS[index]} strokeWidth={2} >
                    <LabelList dataKey={item.dataKey} content={<RenderCustomLineLabel unit={item?.unit || ''} />} />
                  </Line>
                )
              }
              return null;
            })}
          </LineChart>
        </ResponsiveContainer>
      </Box>
      {xName ? <Typography noWrap sx={{ textAlign: 'center', fontSize: 14 }}>{xName}</Typography> : null}
    </Box>
  )
}

export default LineCard;