import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

const VendorSettingsDeleteModal = ({ open = false, handleClose, handleDelete, currentData = null }) => {

	const handleModalClose = () => {
		handleClose();
	}

	const handleDeleteOption = () => {
		handleDelete({ id: currentData.id });
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={"sm"}
		>
			<DialogContent>
				<Typography variant='h6'>Are you sure you want to delete this option?</Typography>
			</DialogContent>
			<DialogActions>
				<Button id='close-btn' onClick={handleModalClose}>No</Button>
				<Button id='delete-btn' onClick={handleDeleteOption} variant='contained'>Yes</Button>
			</DialogActions>
		</Dialog>
	)
}

export default VendorSettingsDeleteModal;