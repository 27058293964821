import { Fragment, useEffect, useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import { cityOptions, convertSearchedListToOptions, countryOptions, extractValuableOptions, formComponents, formComponentsTypes, getOptionFromList, stateOptions } from '../../../../utils/consultantConstants';
import PhoneNumberInput from '../../../../components/PhoneNumberInput/PhoneNumberInput';
import LocalSearchableDropdown from '../../../../components/LocalSearchableDropdown';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DividerComponent from '../../../../components/Divider/Divider';
import { blue, grey } from '@mui/material/colors';
import { Add } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import { toast } from 'react-toastify';
import api from '../../../../config/https';
import { useDebounce } from '../../../../utils/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { getConfigurationFields } from "../../../../store/Settings/action";

const Accordion = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	'&:not(:last-child)': {
		borderBottom: 0,
	},
	'&::before': {
		display: 'none',
	},
}));

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
		{...props}
	/>
))(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark'
			? 'rgba(255, 255, 255, .05)'
			: 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FormModal = ({ open = false, handleClose, currentData = null, handleAdd = () => { }, handleUpdate = () => { }, sectionOptions = [], activeFields = [] }) => {
	const dispatch = useDispatch();
	const { configurationList = [], loadingConfiguration = false } = useSelector((state) => state.settingsConstants);

	const [fields, setFields] = useState([]);
	const [activeType, setActiveType] = useState(0);
	const [selectedFields, setSelectedFields] = useState([]);
	const [data, setData] = useState(null);
	const [searchText, setSearchText] = useState("");
	const [selectedSection, setSelectedSection] = useState("");

	const debouncedValue = useDebounce(searchText, 300);

	useEffect(() => {
		dispatch(getConfigurationFields(res => {
			if (res?.status) {
				setFields(res?.data || []);
			}
		}));
	}, []);

	useEffect(() => {
		if (currentData?.type) {
			if (currentData?.type === 'divider') {
				setActiveType(1);
				setData(currentData);
			}
		}
	}, [currentData]);

	useEffect(() => {
		let f = JSON.parse(JSON.stringify(configurationList));
		if (searchText) {
			f = f.filter(item => ((item?.type || "").toLowerCase().includes(searchText.toLowerCase()) || (item?.label || "").toLowerCase().includes(searchText.toLowerCase()) || (item?.configType || "").toLowerCase().includes(searchText.toLowerCase()) || (item?.configVariant || "").toLowerCase().includes(searchText.toLowerCase())))
		}
		setFields(f);
	}, [debouncedValue]);

	const handleActiveType = (value = 0) => {
		if (value !== activeType) {
			setActiveType(value);
		}
	}

	const handleSelectInput = (index = 0) => {
		if (selectedFields.includes(index)) {
			setSelectedFields(prev => prev.filter(item => item !== index));
		} else {
			setSelectedFields(prev => ([...prev, index]));
		}
	}

	const handleChange = (name = "", value = "") => {
		setData(prev => ({ ...prev, [name]: value }))
	}

	const handleAddComponent = () => {
		if (activeType === 0) {
			let items = configurationList.filter(item => selectedFields.includes(item.id));
			items = items.map(item => ({ ...item, required: item?.systemRequired || item?.required || false, rules: [], disabled: false, hidden: false }));
			if (items.length) {
				handleAdd(items, selectedSection);
			} else {
				toast.error('Please select an input from the list!');
			}
		} else {
			if (data?.label) {
				let payload = {
					type: formComponentsTypes[activeType].key,
					label: data?.label || "",
					variable: data?.label || "",
					id: new Date().getTime(),
					hidden: data?.hidden || false,
				}
				handleAdd([payload]);
			} else {
				toast.error('Please fill all the required fields!');
			}
		}
	};

	const handleUpdateComponent = () => {
		let payload = {
			id: currentData.id,
			type: formComponentsTypes[activeType].key,
			label: data?.label || "",
			variable: data?.variable || "",
			hidden: data?.hidden || false,
		}
		handleUpdate(payload);
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="add-tab-modal"
			aria-describedby="add-tab-modal-description"
			fullWidth
			maxWidth={"lg"}
		>
			<DialogTitle sx={{ color: '#000000DE' }}>{currentData !== null ? `Update form component` : `Choose a new component`}</DialogTitle>
			<DialogContent>
				{currentData?.type
					?
					null
					:
					<Fragment>
						<Box sx={{ width: '100%', }}>
							<Typography variant='subtitle2'>Type</Typography>
							<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt: 0.5 }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
									{formComponentsTypes.map((inputType, inputIndex) => {
										return (
											<Button id={inputType.label} onClick={() => handleActiveType(inputIndex)} sx={{ color: activeType === inputIndex ? '#1976D2' : '#6f6f6f', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: 1, border: activeType === inputIndex ? '2px solid #1976D2' : '2px solid #6f6f6f', borderRadius: 1, minWidth: 120, height: 40, pl: 1, cursor: activeType === inputIndex ? 'default' : 'pointer', textTransform: 'none' }}>
												{inputType?.icon || null}
												<Typography variant='subtitle2' sx={{ color: activeType === inputIndex ? '#1976D2' : '#6f6f6f' }}>{inputType?.label || ""}</Typography>
											</Button>
										)
									})}
								</Box>
							</Box>
							<DividerComponent outerStyle={{ margin: "10px 0 5px 0", padding: 0 }} />
						</Box>
					</Fragment>
				}
				{
					activeType === 0
						?
						<Box sx={{ width: '100%', }}>
							{sectionOptions?.length > 1 ?
								<Box sx={{ width: '100%', }}>
									<Typography variant='subtitle2'>Section</Typography>
									<FormControl margin='dense'>
										<Select
											id={"page-section-dropdown"}
											name={"page-section-dropdown"}
											sx={{ width: "100%", background: "#FFFFFF" }}
											displayEmpty
											value={selectedSection}
											onChange={(e) => setSelectedSection(e?.target?.value)}
										>
											<MenuItem disabled value="">Please select a section</MenuItem>
											{sectionOptions?.map((item, itemIndex) => {
												return (
													<MenuItem key={itemIndex} value={item.value}>{item.label}</MenuItem>
												)
											})}
										</Select>
									</FormControl>
									<DividerComponent outerStyle={{ margin: "10px 0 5px 0", padding: 0 }} />
								</Box>
								:
								null}
							<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
								<TextField
									variant='standard'
									InputProps={{
										startAdornment: <InputAdornment position="start"><SearchIcon sx={{ color: blue[500] }} fontSize="medium" /></InputAdornment>,
									}}
									value={searchText}
									onChange={(e) => setSearchText(e?.target?.value || "")}
									id={'search-input'}
									name={'search-input'}
								/>
								<Typography variant='subtitle2' color={blue[500]}>{selectedFields.length} item(s) selected</Typography>
							</Box>
							<RenderInputs
								fields={fields.filter(item => !activeFields.includes(item.id))}
								selectedFields={selectedFields}
								handleSelectInput={handleSelectInput}
								viewMode='category'
								actionMode='select'
								searchText={searchText}
							/>
						</Box>
						:
						null
				}
				{
					activeType === 1
						?
						<Box sx={{ width: '100%' }}>
							<Typography variant='subtitle2' sx={{ mb: 1 }}>Configuration</Typography>
							<Grid container spacing={2}>
								<Grid item xs={4}>
									<Typography variant='subtitle2' sx={{ mb: 1 }}>Label <span style={{ color: 'red' }}>*</span></Typography>
									<TextField
										id={'page-section-label'}
										name={'page-section-label'}
										value={data?.label || ""}
										onChange={(e) => handleChange('label', e.target.value)}
										placeholder={"Please enter a label!"}
										sx={{ width: "100%", background: "#FFFFFF" }}
										type={'text'}
									/>
								</Grid>
							</Grid>
							<DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
							<Typography variant='subtitle2'>Preview</Typography>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<DividerComponent title={data?.label || ""} />
								</Grid>
							</Grid>
						</Box>
						:
						null
				}
			</DialogContent>
			<DialogActions>
				<Button id='form-cancel-btn' onClick={handleClose}>Cancel</Button>
				{currentData?.type
					?
					<Button id='form-update-btn' onClick={handleUpdateComponent} variant='contained'>Update</Button>
					:
					<Button id='form-add-btn' onClick={handleAddComponent} variant='contained'>Add</Button>
				}
			</DialogActions>
		</Dialog>
	);
}

export const RenderInputs = ({
	viewMode = 'category',
	actionMode = "view",
	fields = [],
	selectedFields = [],
	searchText = "",
	handleSelectInput = () => { },
}) => {
	const [expanded, setExpanded] = useState('panel00');
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState([]);
	const [options, setOptions] = useState(null);
	const [selectedOptions, setSelectedOptions] = useState(null);
	const [fieldList, setFieldList] = useState([]);

	useEffect(() => {
		setFieldList(fields);
	}, [fields]);

	useEffect(() => {
		setData(null);
	}, [viewMode, actionMode]);

	const handleChangeAccordian = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleChange = (name = "", value = "") => {
		setData(prev => ({ ...prev, [name]: value }));
	};

	const handleSearch = async (name = "", value = "", key) => {
		setLoading(prev => [...prev, name]);
		let url = `/masterAdmin/constant?fieldType=${name}&search=${value}`;
		if (name === 'vendor') {
			url = `/masterAdmin/vendor?vendorName=${value}`;
			let res = await api.get(url);
			if (res?.data?.status && res?.data?.data?.data?.length) {
				let list = res?.data?.data?.data;
				let d = convertSearchedListToOptions(list, 'options', 'vendor_name', 'id');
				setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
			}
			setLoading(prev => prev.filter(item => item !== name));
		} else if (name === 'businessUnit' || name === 'clients' || name === 'endclients' || name === 'domains' || name === 'hr') {
			url = `/masterAdmin/${name}?search=${value}`;
			let res = await api.get(url);
			if (res?.data?.status && res?.data?.data?.data?.length) {
				let list = res?.data?.data?.data;
				let d = convertSearchedListToOptions(list, 'options', 'name', 'id');
				setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
			}
			setLoading(prev => prev.filter(item => item !== name));
		} else if (name === 'sdf' || name === 'insurance') {
			if (data?.contractorName) {
				url = `/masterAdmin/${name}?vendorID=${data.contractorName}&search=${value}`;
				let res = await api.get(url);
				if (res?.data?.status && res?.data?.data?.data?.length) {
					let list = res?.data?.data?.data;
					if (name === 'insurance') {
						let d = convertSearchedListToOptions(list, 'options', 'cert_of_insurance_type', 'id');
						setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
					} else {
						let d = convertSearchedListToOptions(list, 'options', name, 'id');
						setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
					}
				}
			} else {
				toast.error('Please enter vendor name');
			}
			setLoading(prev => prev.filter(item => item !== name));
		} else {
			let res = await api.get(url);
			if (res?.data?.status && res?.data?.data?.data?.length) {
				let list = res?.data?.data?.data;
				let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
				setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
			}
			setLoading(prev => prev.filter(item => item !== name));
		}
	}

	const handleChangeLocationData = (name = "", value = "", belong = "", item) => {
		if (belong === 'country') {
			let op = stateOptions(value);
			setOptions(prev => ({ ...prev, [item.state.variable]: op, [item.city.variable]: [] }));
			setData(prev => ({ ...prev, [name]: value, [item.state.variable]: "", [item.city.variable]: "" }));
		} else if (belong === 'state') {
			let op = cityOptions(data[item.country.variable], value);
			setOptions(prev => ({ ...prev, [item.city.variable]: op }));
			setData(prev => ({ ...prev, [name]: value, [item.city.variable]: "" }));
		} else {
			setData(prev => ({ ...prev, [name]: value }));
		}
	}

	return (
		<Box sx={{ width: '100%', mt: 1 }}>
			{
				!searchText && viewMode === 'category' && formComponents.map((formComponent, formComponentIndex) => {
					return formComponent.variants.map((variant, varientIndex) => {
						let items = fieldList.filter(field => field?.configType === (formComponent?.label).toLowerCase() && field?.configVariant === (variant?.label).toLowerCase());
						return (
							<Accordion expanded={expanded === `panel${formComponentIndex + "" + varientIndex}`} onChange={handleChangeAccordian(`panel${formComponentIndex + "" + varientIndex}`)} key={varientIndex}>
								<AccordionSummary aria-controls={`panel${formComponentIndex + "" + varientIndex}d-content`} id={`panel${formComponentIndex + "" + varientIndex}d-header`}>
									<Box sx={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
										<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
											<Typography color={blue[500]} variant="subtitle1">{formComponent?.label || ""}:</Typography>
											<Typography variant="subtitle2">{variant?.label || ""}</Typography>
										</Box>
										<Typography color={blue[500]} variant="subtitle2">{items?.length || 0} input field(s)</Typography>
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									<Grid container spacing={2}>
										{
											expanded === `panel${formComponentIndex + "" + varientIndex}` && fieldList.map((field, fieldIndex) => {
												if (field?.configType === (formComponent?.label).toLowerCase() && field?.configVariant === (variant?.label).toLowerCase()) {
													return (
														<RenderComponent
															key={fieldIndex}
															item={{ ...field, selected: selectedFields.includes(field.id) }}
															index={fieldIndex}
															data={data}
															loading={loading}
															options={options}
															mode={actionMode}
															selectedOptions={selectedOptions}
															setSelectedOptions={setSelectedOptions}
															handleSelectInput={handleSelectInput}
															handleSearch={handleSearch}
															handleChange={handleChange}
															handleChangeLocationData={handleChangeLocationData}
														/>
													)
												}
											})
										}
										{
											!items?.length
												?
												<Grid item xs={12}>
													<Typography variant="subtitle2" color={grey[500]}>No components found!</Typography>
												</Grid>
												:
												null
										}
									</Grid>
								</AccordionDetails>
							</Accordion>
						)
					})
				})
			}
			{searchText
				?
				<Grid container spacing={2}>
					{fieldList.map((field, fieldIndex) => {
						return (
							<RenderComponent
								key={fieldIndex}
								item={{ ...field, selected: selectedFields.includes(field.id) }}
								index={fieldIndex}
								data={data}
								loading={loading}
								options={options}
								mode={actionMode}
								selectedOptions={selectedOptions}
								setSelectedOptions={setSelectedOptions}
								handleSelectInput={handleSelectInput}
								handleSearch={handleSearch}
								handleChange={handleChange}
								handleChangeLocationData={handleChangeLocationData}
							/>
						)
					})}
					{!fieldList?.length && actionMode === 'view'
						?
						<Grid xs={12}>
							<Box sx={{ p: 2, mt: 2, mx: 'auto', width: '98%', height: '200px', bgcolor: grey[100], borderRadius: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
								<Box sx={{ width: 35, height: 35, color: '#475569' }}>
									<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
								</Box>
								<Typography sx={{ fontSize: '14px', mt: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>No components added</Typography>
							</Box>
						</Grid>
						:
						null
					}
				</Grid>
				:
				null
			}
		</Box>
	)
}


const RenderComponent = ({
	item = null,
	index = 0,
	data = null,
	loading = [],
	options = null,
	mode = 'view',
	selectedOptions = null,
	setSelectedOptions = () => { },
	handleSelectInput = () => { },
	handleChange = () => { },
	handleSearch = () => { },
	handleChangeLocationData = () => { },
}) => {
	if (item.hidden && mode === 'view') {
		return null;
	} else if (item.type === 'divider') {
		return (
			<Grid item xs={12} key={index}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<DividerComponent title={item?.label || ""} />
				</Box>
			</Grid>
		)
	} else if (item?.type === 'text') {
		return (
			<Grid item xs={4}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
					<TextField
						placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
						sx={{ width: "100%", background: "#FFFFFF" }}
						inputProps={{ step: 0.01, maxLength: item?.textLength || 524288 }}
						required={item?.required || false}
						InputProps={{
							startAdornment: <InputAdornment position="start">{item?.prefix || ""}</InputAdornment>,
						}}
						type={item?.decimal === 'decimal-number' ? 'number' : 'text'}
						value={data?.[item.variable] || ""}
						onChange={(e) => {
							let value = e?.target?.value || "";
							if (item?.configVariant === 'number') {
								const numberRegex = /^[0-9]+$/;
								if (item?.decimal) {
									const decimalNumberRegex = /^[0-9]+\.?[0-9]*$/;
									if (decimalNumberRegex.test(value) || value === '') {
										handleChange(item.variable, value);
									}
								} else if (numberRegex.test(value) || value === '') {
									handleChange(item.variable, value);
								}
							} else {
								handleChange(item.variable, value);
							}
						}}
						disabled={item.disabled}
						id={item.variable}
						name={item.variable}
					/>
					{item?.confidential
						?
						<FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
						:
						null
					}
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item?.label}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	} else if (item?.type === 'email') {
		return (
			<Grid item xs={item?.size || 4} key={'input-' + index}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
					<TextField
						placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
						sx={{ width: "100%", background: "#FFFFFF" }}
						InputProps={{
							startAdornment: <InputAdornment position="start">{item?.prefix || ""}</InputAdornment>,
						}}
						type='email'
						value={data?.[item.variable] || ""}
						onChange={(e) => handleChange(item.variable, e?.target?.value)}
						disabled={item.disabled}
						id={item.variable}
						name={item.variable}
					/>
					{item?.confidential
						?
						<FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
						:
						null
					}
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item?.label}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	} else if (item.type === 'phone') {
		return (
			<Grid item xs={4}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
					<PhoneNumberInput
						value={data?.[item.variable] || ""}
						handleChange={(value) => handleChange(item.variable, value)}
						disabled={item.disabled}
						id={item.variable}
						name={item.variable}
					/>
					{item?.confidential
						?
						<FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
						:
						null
					}
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item?.label}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	} else if (item.type === 'date') {
		return (
			<Grid item xs={4}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							renderInput={(inputProps) =>
								<TextField
									InputLabelProps={{ shrink: true }}
									type="date"
									variant="outlined"
									sx={{ width: "100%", background: "#FFFFFF" }}
									value={data?.[item.variable] || ""}
									onChange={(e) => handleChange(item.variable, e?.target?.value)}
									disabled={item.disabled}
									id={item.variable}
									name={item.variable}
								/>}
						/>
					</LocalizationProvider>
					{item?.confidential
						?
						<FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
						:
						null
					}
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item?.label}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	} else if (item.type === 'dropdown') {
		return (
			<Grid item xs={4}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
					<FormControl fullWidth>
						<Select
							sx={{ width: "100%", background: "#FFFFFF" }}
							displayEmpty
							value={data?.[item.variable] || ""}
							onChange={(e) => handleChange(item?.variable, e?.target?.value)}
							disabled={item.disabled}
							id={item.variable}
							name={item.variable}
						>
							<MenuItem disabled value="">{"Please select a " + (item?.label || "").toLowerCase()}</MenuItem>
							{(extractValuableOptions(item?.options || [])).map((option, index) => {
								return (
									<MenuItem key={index} value={option.value}>{option.label}</MenuItem>
								)
							})}
						</Select>
					</FormControl>
					{item?.confidential
						?
						<FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
						:
						null
					}
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item?.label}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	} else if (item?.type === 'searchable-dropdown') {
		return (
			<Grid item xs={4}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
					<LocalSearchableDropdown
						id={item.variable}
						multiple={item?.multiple}
						label={item?.label}
						value={item?.multiple ? data?.[item.variable] || [] : data?.[item.variable] || null}
						handleChange={(v) => {
							if (item?.multiple) {
								let oldValues = data?.[data.variable] || [];
								let intersection = [];
								if (v.length > oldValues.length) {
									intersection = v.filter(x => !oldValues.includes(x));
									if (intersection.length) {
										const option = getOptionFromList(options[item.variable], intersection[0]);
										if (option?.value) {
											setSelectedOptions(prev => {
												let oldOptions = prev?.[item.searchVariable] || [];
												let op = [...oldOptions, option];
												let list = [...new Map(op.map(item => [item.value, item])).values()];
												return ({
													...prev,
													[item.searchVariable]: list,
												});
											});
										}
									}
								} else {
									intersection = oldValues.filter(x => !v.includes(x));
									if (intersection.length) {
										setSelectedOptions(prev => {
											let oldOptions = prev?.[item.searchVariable] || [];
											oldOptions = oldOptions.filter(item => item.value !== intersection[0])
											return ({
												...prev,
												[item.searchVariable]: oldOptions,
											});
										});
									}
								}
								handleChange(item.variable, v);
							} else {
								handleChange(item.variable, v?.value);
								if (v?.value) {
									setSelectedOptions(prev => {
										let oldOptions = prev?.[item.searchVariable] || [];
										let op = [...oldOptions, v];
										let list = [...new Map(op.map(item => [item.value, item])).values()];
										return ({
											...prev,
											[item.searchVariable]: list,
										});
									});
								} else {
									setSelectedOptions(prev => ({
										...prev,
										[item.searchVariable]: [],
									}));
								}
							}
						}}
						addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
						options={options?.[item.variable] || []}
						handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
						add={item?.request || false}
						loading={loading.includes(item?.searchVariable)}
						matchingOptions={selectedOptions?.[item.searchVariable] || []}
						tooltipVariables={item?.tooltipVariables || []}
						showTooltip={item?.tooltipVariables?.length}
						disabled={item.disabled}
					/>
					{item?.confidential
						?
						<FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} name={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={"Please check if " + item?.confidentialLabel || ""} />
						:
						null
					}
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item?.label}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	} else if (item?.type === 'location') {
		return (
			<Grid item xs={12}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Grid container spacing={2}>
						{item?.address?.label
							?
							<Grid item xs={12}>
								<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.address?.label || ""} {item?.address?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
								<TextField
									value={data?.[item?.address?.variable] || ""}
									onChange={(e) => handleChangeLocationData(item?.address?.variable, e?.target?.value)}
									placeholder={"Please enter " + (item?.address?.label || "").toLowerCase() + "!"}
									sx={{ width: "100%", background: "#FFFFFF" }}
									disabled={item.disabled}
									id={item.address.variable}
									name={item.address.variable}
								/>
							</Grid>
							:
							null
						}
						{item?.country?.label
							?
							<Grid item xs={4}>
								<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.country?.label || ""} {item?.country?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
								<LocalSearchableDropdown
									value={data?.[item?.country?.variable] || null}
									label={item?.country?.label || ""}
									options={countryOptions}
									handleChange={(v) => handleChangeLocationData(item?.country?.variable, v?.value, 'country', item)}
									disabled={item.disabled}
									id={item.country.variable}
								/>
							</Grid>
							:
							null
						}
						{item?.state?.label && options?.[item?.state?.variable]?.length
							?
							<Grid item xs={4}>
								<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.state?.label || ""} {item?.state?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
								<LocalSearchableDropdown
									value={data?.[item?.state?.variable] || null}
									label={item?.state?.label || ""}
									options={options?.[item?.state?.variable] || []}
									handleChange={(v) => handleChangeLocationData(item?.state?.variable, v?.value, 'state', item)}
									disabled={item.disabled}
									id={item.state.variable}
								/>
							</Grid>
							:
							null
						}
						{item?.city?.label && options?.[item?.city?.variable]?.length
							?
							<Grid item xs={4}>
								<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.city?.label || ""} {item?.city?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
								<LocalSearchableDropdown
									value={data?.[item?.city?.variable] || null}
									label={item?.city?.label || ""}
									options={options?.[item?.city?.variable] || []}
									handleChange={(v) => handleChangeLocationData(item?.city?.variable, v?.value, 'city', item)}
									disabled={item.disabled}
									id={item.city.variable}
								/>
							</Grid>
							:
							null
						}
						{item?.zipCode?.label
							?
							<Grid item xs={4}>
								<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.zipCode?.label || ""} {item?.zipCode?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
								<TextField
									value={data?.[item?.zipCode?.variable] || ""}
									onChange={(e) => handleChangeLocationData(item?.zipCode?.variable, e?.target?.value, item)}
									placeholder={"Please enter " + (item?.zipCode?.label || "").toLowerCase() + "!"}
									sx={{ width: "100%", background: "#FFFFFF" }}
									disabled={item.disabled}
									id={item.zipCode.variable}
									name={item.zipCode.variable}
								/>
							</Grid>
							:
							null
						}
					</Grid>
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item?.address?.label || item?.country?.label || item?.state?.label || item?.city?.label || item?.zipCode?.label}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	} else if (item?.type === 'plan') {
		return (
			<Grid item xs={12}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
							<LocalSearchableDropdown
								id={item.variable}
								multiple={item?.multiple}
								label={item?.label}
								value={item?.multiple ? (data?.[item.variable] || []) : data?.[item.variable] || null}
								handleChange={(v) => {
									if (item?.multiple) {
										let oldValues = data?.[item.variable] || [];
										let intersection = [];
										if (v.length > oldValues.length) {
											intersection = v.filter(x => !oldValues.includes(x));
											if (intersection.length) {
												const option = getOptionFromList(options[item.variable], intersection[0]);
												if (option?.value) {
													setSelectedOptions(prev => {
														let oldOptions = prev?.[item.searchVariable] || [];
														let op = [...oldOptions, option];
														let list = [...new Map(op.map(item => [item.value, item])).values()];
														return ({
															...prev,
															[item.searchVariable]: list,
														});
													});
												}
											}
										} else {
											intersection = oldValues.filter(x => !v.includes(x));
											if (intersection.length) {
												setSelectedOptions(prev => {
													let oldOptions = prev?.[item.searchVariable] || [];
													oldOptions = oldOptions.filter(item => item.value !== intersection[0])
													return ({
														...prev,
														[item.searchVariable]: oldOptions,
													});
												});
											}
										}
										handleChange(item.variable, v);
									} else {
										if (v?.value) {
											setSelectedOptions(prev => {
												let oldOptions = prev?.[item.searchVariable] || [];
												let op = [...oldOptions, v];
												let list = [...new Map(op.map(item => [item.value, item])).values()];
												return ({
													...prev,
													[item.searchVariable]: list,
												});
											});
										} else {
											setSelectedOptions(prev => ({
												...prev,
												[item.searchVariable]: [],
											}));
										}
										handleChange(item.variable, v?.value);
									}
								}}
								addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
								options={options?.[item.variable] || []}
								handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
								add={item?.request || false}
								loading={loading.includes(item?.searchVariable)}
								matchingOptions={selectedOptions?.[item.searchVariable] || []}
								disabled={item.disabled}
							/>
						</Grid>
						{
							data?.[item.variable]
								?
								selectedOptions?.[item.searchVariable]?.map((selectedPlan, selectedPlanIndex) => {
									return (
										<Grid item xs={4} key={selectedPlanIndex}>
											<Typography variant='subtitle2' sx={{ mb: 1 }}>{selectedPlan?.label || ""} Effective date {item?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
											<LocalizationProvider dateAdapter={AdapterDayjs}>
												<DatePicker
													renderInput={(inputProps) => {
														return (
															<TextField
																InputLabelProps={{ shrink: true }}
																type="date"
																value={data?.[item.variable + "" + selectedPlan?.label] || ""}
																variant="outlined"
																sx={{ width: "100%", background: "#FFFFFF" }}
																onChange={(e) => handleChange(item.variable + "" + selectedPlan?.label, e.target.value)}
																id={item.variable + "" + selectedPlan?.label}
																name={item.variable + "" + selectedPlan?.label}
															/>
														)
													}}
												/>
											</LocalizationProvider>
										</Grid>
									)
								})
								:
								null
						}
					</Grid>
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item.label}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	} else if (item?.type === 'upload') {
		return (
			<Grid item xs={12}>
				<Box sx={{ position: 'relative', border: mode === 'edit' || mode === 'select' ? '2px solid #f1f1f1' : 'none', padding: mode === 'edit' || mode === 'select' ? 2 : 0, borderRadius: mode === 'edit' || mode === 'select' ? 2 : 0 }}>
					<Grid container spacing={2}>
						{item?.list?.map((option, optionIndex) => {
							return (
								<Grid item xs={10} key={optionIndex}>
									<DividerComponent title={option?.title || ""} titleStyle={{ fontSize: 14, fontWeight: 500 }} outerStyle={{ marginTop: 0, }} />
									<Box>
										{option?.variants?.map((variant, varientIndex) => {
											return (
												<Box key={varientIndex}>
													<Typography variant="subtitle2">{variant}</Typography>
													<Typography variant="body1" color={blue[500]}>test_file.pdf</Typography>
												</Box>
											)
										})}
										{!item?.variants?.length ? <Typography variant="subtitle2">No files</Typography> : null}
									</Box>
									<Button id={'upload-document'} name={'upload-document'} variant='text' sx={{ width: 50, height: 50, bgcolor: blue[50], borderRadius: 50, minWidth: 'auto', mt: 2 }}>
										<Add />
									</Button>
								</Grid>
							)
						})}
					</Grid>
					{mode === 'select' ?
						<Box sx={{ position: 'absolute', right: 0, top: 0 }}>
							<IconButton id={`${item?.list?.[0]?.title || 'upload'}-checkbox`} onClick={() => handleSelectInput(item.id)}>
								{
									item.selected
										?
										<CheckBoxIcon color='success' />
										:
										<CheckBoxOutlineBlankIcon />
								}
							</IconButton>
						</Box>
						:
						null
					}
				</Box>
			</Grid>
		)
	}
	return null;
}

export default FormModal;