import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import { COLORS, STACKEDCOLORS, extractBarDataPoints } from '../../utils/chartHelper';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import NoDataCard from './noDataCard';
import RenderCustomBarLabel from './customBarLabel';
import CustomTooltip from './CustomTooltip';
import CustomizedAxisTick from './customAxisTick';
import calculateSize from 'calculate-size';

const BarCard = ({ data = [], visualizationData = null, title = "", showAnimation = true }) => {

  const [axes, setAxes] = useState([]);
  const [list, setList] = useState([]);
  const [xName, setXName] = useState("");
  const [yName, setYName] = useState("");
  const [chartTitle, setTitle] = useState("");
  const [size, setSize] = useState(null);
  const [stacked, setStacked] = useState([]);
  const [stackType, setStackType] = useState('');

  useEffect(() => {
    const dataPoints = extractBarDataPoints(data, visualizationData, title);
    setList(dataPoints?.list || []);
    setAxes(dataPoints?.axes || []);
    setXName(dataPoints?.xName || "");
    setYName(dataPoints?.yName || "");
    setTitle(dataPoints?.title || title);
    setSize(dataPoints?.size || null);
    setStacked(dataPoints?.stacked || []);
    setStackType(dataPoints?.stackedType || '');
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  const yNameSize = calculateSize(yName || "", {
    font: 'Poppins',
    fontSize: '14px',
  });

  const gap = size?.width ? size.width / 2 : 10;

  const toPercent = (decimal, fixed = 0) => `${decimal}%`;

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <Box sx={{ width: '100%', height: xName ? '85%' : '95%', display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 0.5, position: 'relative' }}>
        {yName ?
          <div style={{ whiteSpace: 'normal', verticalAlign: 'bottom', width: 40 }}>
            <span style={{ transformOrigin: 'left', transform: "translate(50%," + (yNameSize.width / 2 + yNameSize.height / 2) + "px) rotate(-90deg)", display: 'block', textWrap: 'nowrap', fontSize: 14 }}>{yName}</span>
          </div>
          :
          null
        }
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={list}
            margin={{
              top: 0,
              right: gap > 40 ? 40 : gap,
              bottom: 0,
              left: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis padding={{ left: gap > 40 ? 40 : gap, right: gap > 100 ? 40 : gap }} height={size?.height ? (size?.width > 100 ? 80 : size.height) : 25} dataKey={axes[0]?.dataKey || null} tick={<CustomizedAxisTick rotate={size?.width > 100} />} interval={0} />
            {stackType === 'normalized'
              ?
              <YAxis domain={[0, 100]} ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} tickFormatter={toPercent} tickCount={10} allowDataOverflow={false} />
              :
              <YAxis allowDataOverflow={true} />
            }
            <Tooltip position={{ x: -0, y: -150 }} content={props => <CustomTooltip stackType={stackType} {...props} />} />
            <Legend align="left" verticalAlign='top' wrapperStyle={{ paddingBottom: gap / 2 > 20 ? 20 : gap / 2 }} />
            {
              stacked?.length
                ?
                stacked.map((item, index) => {
                  if (stackType === 'normalized') {
                    return (
                      <Bar isAnimationActive={showAnimation} stackId='a' name={item} legendType='circle' key={`bar-chart-gp-${index}`} dataKey={'normalized-' + item} fill={index > (STACKEDCOLORS.length - 1) ? STACKEDCOLORS[index % (STACKEDCOLORS.length - 1)] : STACKEDCOLORS[index]} />
                    )
                  }
                  return (
                    <Bar isAnimationActive={showAnimation} stackId='a' name={item} legendType='circle' key={`bar-chart-gp-${index}`} dataKey={item} fill={index > (STACKEDCOLORS.length - 1) ? STACKEDCOLORS[index % (STACKEDCOLORS.length - 1)] : STACKEDCOLORS[index]} />
                  )
                })
                :
                axes?.map((item, index) => {
                  if (index > 0) {
                    return (
                      <Bar isAnimationActive={showAnimation} unit={item?.unit || null} legendType='circle' key={`bar-chart-gp-${index}`} dataKey={item.dataKey} fill={COLORS[index]}>
                        <LabelList dataKey={item.dataKey} content={<RenderCustomBarLabel unit={item?.unit || ''} />} />
                      </Bar>
                    )
                  }
                  return null;
                })
            }
          </BarChart>
        </ResponsiveContainer>
      </Box>
      {xName ? <Typography noWrap sx={{ textAlign: 'center', fontSize: 14 }}>{xName}</Typography> : null}
    </Box>
  )
}

export default BarCard;