import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material';
import DividerComponent from '../../../../../components/Divider/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Add, Cancel } from '@mui/icons-material';
import { blue, grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';

const InputTypeUpload = ({ data = null, handleChange = () => { }, }) => {
  const [values, setValues] = useState(null);
  const [optionValue, setOptionValue] = useState(null);
  const [optionValue2, setOptionValue2] = useState(null);

  useEffect(() => {
    setValues(null);
  }, [data]);

  const handleChangeValue = (name, value) => {
    setValues(prev => ({ ...prev, [name]: value }));
  };

  const handleAddNewList = () => {
    let list = data?.list || [];
    list.push({ title: optionValue?.label || "", variants: [] });
    handleChange('list', list);
    setOptionValue(null);
  };

  const handleRemoveList = (value = "") => {
    let list = data?.list || [];
    list = list.filter(item => item.title !== value);
    handleChange('list', list);
  }

  const handleAddNewVarient = (index = 0) => {
    let list = data?.list || [];
    list[index].variants.push(optionValue2?.label || "");
    handleChange('list', list);
    setOptionValue2(null);
  }

  const handleRemoveVarient = (index = 0, value = "") => {
    let list = data?.list || [];
    let variants = list?.[index]?.variants || [];
    variants = variants.filter(item => item !== value);
    list[index].variants = variants;
    handleChange('list', list);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Configuration</Typography>
      {data?.list?.length
        ?
        null
        :
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
          <TextField id={'title-input'} name={'title-input'} label='List title' sx={{ background: "#FFFFFF", width: '100%' }} value={optionValue?.label || ""} onChange={e => setOptionValue(prev => ({ ...prev, label: e.target.value }))} />
          <Button id={'add-btn'} name={'add-btn'} onClick={handleAddNewList} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
            <AddCircleIcon />
            <Typography variant='subtitle2'>Add</Typography>
          </Button>
        </Box>
      }
      {data?.list?.map((item, itemIndex) => {
        return (
          <Box sx={{ my: 2, bgcolor: grey[50], p: 2, borderRadius: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              <Typography variant='subtitle2' color={blue[500]}>Title:</Typography>
              <Typography>{item?.title || ""}</Typography>
              <IconButton id={'remove-btn'} name={'remove-btn'} color='error' onClick={() => handleRemoveList(item?.title)}>
                <Cancel />
              </IconButton>
            </Box>
            {item?.variants?.length ? <Typography variant='subtitle2' color={blue[500]}>Varients</Typography> : null}
            {
              item?.variants?.map((variant, varientIndex) => {
                return (
                  <Box key={varientIndex} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <Typography variant='subtitle1'>{variant}</Typography>
                    <IconButton id={'remove-list-btn' + (varientIndex + 1)} name={'remove-list-btn' + (varientIndex + 1)} color='error' onClick={() => handleRemoveVarient(itemIndex, variant)}>
                      <Cancel />
                    </IconButton>
                  </Box>
                )
              })
            }
            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mt: 2 }}>
              <TextField id={'list-input'} name={'list-input'} label='Add variant title' sx={{ background: "#FFFFFF", width: '50%' }} value={optionValue2?.label || ""} onChange={e => setOptionValue2(prev => ({ ...prev, label: e.target.value }))} />
              <Button id={'add-list-btn'} name={'add-list-btn'} onClick={() => handleAddNewVarient(itemIndex)} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, textTransform: 'none' }}>
                <AddCircleIcon />
                <Typography variant='subtitle2'>Add</Typography>
              </Button>
            </Box>
          </Box>
        )
      })}
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2'>Preview</Typography>
      <Grid container spacing={2}>
        {data?.list?.map((item, itemIndex) => {
          return (
            <Grid item xs={12}>
              <DividerComponent title={item?.title || ""} titleStyle={{ fontSize: 14, fontWeight: 500 }} outerStyle={{ marginTop: 0, }} />
              <Box>
                {
                  item?.variants?.map((variant, varientIndex) => {
                    return (
                      <Box key={varientIndex}>
                        <Typography variant="subtitle2">{variant}</Typography>
                        <Typography variant="body1" color={blue[500]}>test_file.pdf</Typography>
                      </Box>
                    )
                  })
                }
                {!item?.variants?.length ? <Typography variant="subtitle2">No files</Typography> : null}
              </Box>
              <Button variant='text' sx={{ width: 50, height: 50, bgcolor: blue[50], borderRadius: 50, minWidth: 'auto', mt: 2 }}>
                <Add />
              </Button>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}

export default InputTypeUpload;