import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import DividerComponent from "./Divider/Divider";
import CloseIcon from "@mui/icons-material/Close";
import { blue, red } from '@mui/material/colors';
import { Add } from "@mui/icons-material";
import { useState } from "react";
import DropZone from "./dropZone";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocument, getDocumentForContract } from "../store/ConsultantForm/action";
import { toast } from "react-toastify";
import { userRole } from "../constants/userRoles";
import _ from "lodash";

const UploadDocuments = ({ listOptions = [], contractId = "", allowed = true, documents = [], dividerTitleStyle = {}, postSave = false, postSaveDocuments = [], setPostSaveDocuments }) => {
  const dispatch = useDispatch();

  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleModalClose = () => {
    setShowUploadModal(false);
  };

  const handleNext = () => {
    setShowUploadModal(false);
  };

  const handleRemove = async (id = "") => {
    const res = await deleteDocument({ id: id });
    if (res?.data?.status) {
      toast.success('Document removed successfully');
      dispatch(getDocumentForContract({ id: contractId }));
    } else {
      toast.error('Failed removing document!');
    }

  }

  const handleShowModal = (e) => {
    setCurrentIndex(e);
    setShowUploadModal(true);
  }

  const handlePostSave = (listName = "", documentSelected = "", file = {}) => {
    let existingFiles = [...postSaveDocuments];
    setShowUploadModal(false);
    var fileName = file.name.split('.');
    existingFiles.push({
      name: fileName.length ? fileName[0] : file.name,
      type: file.type,
      category: listName + ":" + documentSelected,
      id: JSON.stringify(new Date().valueOf()),
      file: file,
    });
    setPostSaveDocuments(existingFiles);
  }

  const handleRemovePostSaveDocument = (id = "") => {
    if (id) {
      let existingFiles = [...postSaveDocuments];
      existingFiles = existingFiles.filter(item => item.id !== id);
      setPostSaveDocuments(existingFiles);
    }
  }

  return (
    <Box>
      {listOptions.map((item, itemIndex) => {
        return (
          <Grid container key={itemIndex} mb={2} spacing={1}>
            <Grid item xs={12}>
              <DividerComponent title={item?.title || ""} titleStyle={{ fontSize: 14, fontWeight: 500, ...dividerTitleStyle }} outerStyle={{ marginTop: 0, }} />
            </Grid>
            <DocumentListRender
              documents={documents?.length ? documents : postSaveDocuments}
              category={item.title}
              allowed={allowed}
              handleRemove={handleRemove}
              postSave={postSave}
              postSaveDocuments={postSaveDocuments}
              handleRemovePostSaveDocument={handleRemovePostSaveDocument}
            />
            {allowed
              ?
              <Grid item xs={12}>
                <Button id='upload-btn' variant='text' onClick={() => handleShowModal(itemIndex)} sx={{ width: 50, height: 50, bgcolor: blue[50], borderRadius: 50, minWidth: 'auto' }}>
                  <Add />
                </Button>
              </Grid>
              :
              null
            }
          </Grid>
        )
      })}
      {showUploadModal && allowed ?
        <DropZone
          show={showUploadModal}
          handleClose={handleModalClose}
          handleNext={handleNext}
          options={listOptions[currentIndex].variants}
          contractId={contractId}
          listName={listOptions[currentIndex].title}
          postSave={postSave}
          handlePostSave={handlePostSave}
        />
        :
        null
      }
    </Box>
  )
}

const DocumentListRender = ({ documents = [], category = "", allowed = false, handleRemove, postSave = false, postSaveDocuments = [], handleRemovePostSaveDocument }) => {
  let finalDocuments = (postSave ? postSaveDocuments : documents).filter(doc => {
    var fileCategory = (doc?.category || "").split(":");
    return fileCategory.length > 1 && fileCategory[0] === category
  });
  let groupData = _.groupBy(finalDocuments, (item) => { return item.category });
  if (finalDocuments.length) {
    return (Object.keys(groupData)).map((groupDataKey, groupDataKeyIndex) => {
      var fileCategory = (groupDataKey || "").split(":");
      return (
        <Grid item xs={12} key={groupDataKeyIndex}>
          <Typography variant="subtitle2">{fileCategory[1]}</Typography>
          {groupData[groupDataKey]?.map((file, fileIndex) => {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }} key={fileIndex}>
                <Typography
                  id={'view-document-btn-' + (fileIndex + 1)}
                  onClick={() => {
                    if (file?.url) {
                      window.open(file.url, "_blank", "noreferrer");
                    }
                  }}
                  variant='body1'
                  sx={{ cursor: file?.url ? 'pointer' : 'not-allowed' }}
                  color={blue[500]}
                >
                  {file?.name || ""}{file?.type ? "." + file?.type : ""}
                </Typography>
                {allowed
                  ?
                  <Button id={'remove-document-btn-' + (fileIndex + 1)} variant='text' sx={{ px: 0.5, minWidth: 'auto', color: red[500] }} onClick={() => {
                    if (file?.url) {
                      handleRemove(file.id);
                    } else {
                      handleRemovePostSaveDocument(file.id);
                    }
                  }}>
                    <CloseIcon />
                  </Button>
                  :
                  null
                }
              </Box>
            )
          })}
        </Grid>
      )
    })
  } else {
    return (
      <Grid item xs={12}>
        <Typography variant="subtitle2">No files</Typography>
      </Grid>
    )
  }
}

export default UploadDocuments;