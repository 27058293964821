import {
  CREATE_CANDIDATE_REQUESTED,
  CREATE_CANDIDATE_SUCCESS,
  CREATE_CANDIDATE_FAILED,
  GET_CANDIDATE_REQUESTED,
  GET_CANDIDATE_SUCCESS,
  GET_CANDIDATE_FAILED,
  CANDIDATE_LIST_IN_PROGRESS,
  CANDIDATE_LIST_SUCCESS,
  CANDIDATE_LIST_FAILED,
  CANDIDATE_SEARCH_IN_PROGRESS,
  CANDIDATE_SEARCH_SUCCESS,
  CANDIDATE_SEARCH_FAILED,
} from "../../../constants/store";

export const initialState = {
  candidate: false,
  loading: false,
  error: false,
  errorMessage: "",
  list: [],
  maxPage:0,
};

function candidateReducers(state = initialState, action) {
  switch (action.type) {
    case CREATE_CANDIDATE_REQUESTED: {
      const newState = {
        ...state,
        loading: true,
      };

      return newState;
    }
    case CREATE_CANDIDATE_SUCCESS: {
      
      const newState = {
        ...state,
        candidate: action.payload,
      };

      return newState;
    }
    case CREATE_CANDIDATE_FAILED: {
      const newState = {
        ...state,
        error: true,
        errorMessage: action?.payload?.errorMessage,
      };
      return newState;
    }

    // GET
    case GET_CANDIDATE_REQUESTED: {
      const newState = {
        loading: true,
        ...state,
      };

      return newState;
    }
    case GET_CANDIDATE_SUCCESS: {
      const newState = {
        ...state,
        candidate: action.payload,
      };
      return newState;
    }
    case GET_CANDIDATE_FAILED: {
      const newState = {
        error: true,
        ...state,
      };
      return newState;
    }

    // CANDIDATE LIST
    case CANDIDATE_LIST_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case CANDIDATE_LIST_SUCCESS: {
      const newState = {
        ...state,
        list: action?.payload?.result || [],
        max: action?.payload?.count || 0,
        loading:false
      };
      return newState;
    }
    case CANDIDATE_LIST_FAILED: {
      const newState = {
        error: true,
        ...state,
        loading:false
      };
      return newState;
    }


    // CANDIDATE SEARCH
    case CANDIDATE_SEARCH_IN_PROGRESS: {
      const newState = {
        loading: true,
        ...state,
      };
      return newState;
    }
    case CANDIDATE_SEARCH_SUCCESS: {
      const newState = {
        ...state,
        list: action?.payload?.result || [],
        max: action?.payload?.count || 0,
      };
      return newState;
    }
    case CANDIDATE_SEARCH_FAILED: {
      const newState = {
        error: true,
        ...state,
      };
      return newState;
    }

    default:
      return state;
  }
}

export default candidateReducers;
