import { forwardRef } from 'react';
import { TextField } from '@mui/material';

const phoneInput = (props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      variant="outlined"
      fullWidth
    />
  )
}
export default forwardRef(phoneInput);