import { Box } from '@mui/material';
import React from 'react';
import AppBar from '../../../components/AppBar';
import Release from './release';
import { releaseLogs } from './release-logs-data';

const ReleaseLogs = () => {
  return (
    <Box sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <AppBar appBarTitle='Release Logs' />
      <Box
        id="recruitment-list-box"
        sx={{
          background: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 2,
          mt: 2,
          width: "100%",
          position: 'relative',
          pb: 2,
          height: 'calc(100svh - 120px)',
          overflowY:'auto'
        }}>
        {releaseLogs.map((releaseLog, index) => {
          return <Release {...releaseLog} key={index} />
        })}
      </Box>
    </Box>
  )
}

export default ReleaseLogs;