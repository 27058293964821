import {
  CANDIDATE_LIST_IN_PROGRESS,
  CANDIDATE_LIST_SUCCESS,
  CANDIDATE_LIST_FAILED,
} from "../../../constants/store";
import api from "../../../config/https";

export const listFilterCandidates = (params) => async (dispatch) => {
  try {
    dispatch({
      type: CANDIDATE_LIST_IN_PROGRESS,
    });
    let res = await api
      .post("/masterAdmin/candidates/new", params)
      .then(({ data }) => {
        dispatch({
          type: CANDIDATE_LIST_SUCCESS,
          payload: data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: CANDIDATE_LIST_FAILED,
        });
      });
  } catch (error) { }
};