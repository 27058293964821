import React, { useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
} from "@mui/material";
import { useEffect } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

////////////////////////////////////////////////////
// This component will let user select options
// in the select field.
////////////////////////////////////////////////////

function SelectDropdown({
  inputName,
  value,
  options: selectOptions,
  setFields,
  fieldName,
  required,
  validateForm,
  setFormError,
  readOnly = false,
  valueKey = "",
  isBooleanSelection = false,
  showInfo = false,
  disabled,
  id='demo-simple-select',
}) {
  const [selection, setSelection] = useState(value);
  const [validationError, setValidationError] = useState(false);

  const checkIfHasValue = (value) => {
    if (value === "" || value === null || value === undefined ) {
      return true;
    } else {
      return false;
    }
  };


  useEffect(() => {
    ////////////////////////////////////////////////////
    // Ensure which data type the dropdown values contain
    // to make sure the selection matches with the data
    ////////////////////////////////////////////////////
    if (value || value === false) {
      let ensureDataType;
      if (typeof value === "boolean") {
        ensureDataType = value ? "Yes" : "No";
      } else {
        ensureDataType = value;
      }

      setSelection(ensureDataType);
    }
  }, [value]);

  useEffect(() => {
    if (required && validateForm) {
      setValidationError(checkIfHasValue(value));
      if (required && setFormError) {
        setFormError((prev) => {
          const findExistingField = prev?.findIndex(
            (index) => index.input === fieldName
          );

          if (findExistingField !== -1) {
            const update = prev?.map((index) => {
              if (index.input === fieldName) {
                index.isError = checkIfHasValue(value);
              }
              return index;
            });
            return update;
          } else {
            return [
              ...prev,
              {
                input: fieldName,
                inputName: inputName,
                isError: checkIfHasValue(value),
              },
            ];
          }
        });
      }
    }
  }, [value, validateForm]);

  //handle the creation
  const _handleSelection = (inputValue) => {
    const name = inputValue.target.name;
    const value = inputValue.target.value;
    if (required) {
      setValidationError(value !== "" ? false : true);
    }
    if(fieldName === "certificateOfInsurance" && value === "No"){
      const pName = "insuranceEntries"
      setFields((prevValue) => {
        const updatedValue = {
          ...prevValue,
          [pName]: [],
          [name]: isBooleanSelection
          ? value === ""
            ? null
            : value === "Yes"
            ? true
            : false
          : value
        }
        return updatedValue
      });
      setSelection(inputValue.target.value);
    } else if(fieldName === "diversityStatus" && value === "No"){
      const pName = "sdfEntires"
      setFields((prevValue) => {
        const updatedValue = {
          ...prevValue,
          [pName]: [],
          [name]: isBooleanSelection
          ? value === ""
            ? null
            : value === "Yes"
            ? true
            : false
          : value
        }
        return updatedValue
      });
      setSelection(inputValue.target.value);
    }else {
      setFields((prevValue) => {
        prevValue[name] = isBooleanSelection
          ? value === ""
            ? null
            : value === "Yes"
            ? true
            : false
          : value;
        return {
          ...prevValue,
        };
      });
      setSelection(inputValue.target.value);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: 14,
              lineHeight: 2,
              display: "block",
              color: "#1a1a1a",
            }}
          >
            {inputName}
            {/* <br /> */}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Select
            labelId="demo-simple-select-label"
            id={id}
            value={selection || ""}
            size="small"
            name={fieldName}
            onChange={(e) => _handleSelection(e)}
            onblur={(e) => _handleSelection(e)}
            style={{ width: "100%", background: "#FFFFFF" }}
            displayEmpty={true}
            inputProps={{
              "aria-label": "Without label",
            }}
            error={validationError}
            required={required}
            readOnly={readOnly}
            disabled={disabled}
          >
            <MenuItem defaultValue="" value="">
              Select {inputName.toLowerCase()}
            </MenuItem>
            {selectOptions &&
              selectOptions.length > 0 &&
              selectOptions.map((option) => {
                return (
                  <MenuItem
                    value={valueKey !== "" ? option[valueKey] : option?.label}
                  >
                    {option?.label}
                  </MenuItem>
                );
              })}
          </Select>
          {showInfo && (
            <FormHelperText
              style={{ fontSize: 10, display: "flex", alignItems: "center" }}
            >
              <InfoOutlinedIcon fontSize="12" style={{ marginRight: 3 }} />
              {showInfo}
            </FormHelperText>
          )}
          {validationError && required && (
            <FormHelperText error>
              Please select {inputName.toLowerCase()}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default SelectDropdown;
