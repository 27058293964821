import { useEffect, useRef, useState } from "react";
import VEngageLogo from "../assets/image/VEngageLogo.png";
import Candidates from "../assets/image/cand.png";
import CandidatesSelected from "../assets/image/candActive.png";
import Rec from '../assets/image/rec.png';
import RecActive from '../assets/image/recActive.png';
import PenRec from '../assets/image/penRec.png';
import PenRecActive from '../assets/image/penRecActive.png';
import Approvals from "../assets/image/cr.png";
import ApprovalsSelected from "../assets/image/crActive.png";
import SOW from "../assets/image/sow.png";
import SOWActive from "../assets/image/sowActive.png";
import Settings from "../assets/image/set.png";
import SettingsSelected from "../assets/image/setActive.png";
import Exports from "../assets/image/export.png";
import ExportsSelected from "../assets/image/exportActive.png";
import Profiles from "../assets/image/roles.png";
import ProfilesSelected from "../assets/image/rolesActive.png";
import Reports from "../assets/image/report.png";
import ReportsSelected from "../assets/image/reportActive.png";
import RequestOptions from "../assets/image/requestOptions.png";
import RequestOptionsSelected from "../assets/image/requestOptionsActive.png";
import Logs from "../assets/image/logs.png";
import LogsSelected from "../assets/image/logsActive.png";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { blue, green, grey, red } from "@mui/material/colors";
import { useHistory, useLocation } from "react-router-dom";
import { releaseLogs } from "../pages/MasterDataModule/ReleaseLogs/release-logs-data";
import PublicIcon from '@mui/icons-material/Public';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from "react-redux";
import { fetchChangeRequestNotification } from "../store/actions/ChangeRequestNotification";
import { fetchAllPendingRecruitmentDraftsNotifications } from "../store/PendingRecruitmentNotification/action";
import { fetchAllPendingRequestOptionsNotifications } from "../store/PendingRequestOptionsNotification/action";
import { fetchAllPendingSOWNotifications } from "../store/PendingSOWNotification/action";
import { useIdleTimer } from "react-idle-timer";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CloseOutlined } from "@mui/icons-material";
import _ from "lodash";
import countries from 'flag-icons/country.json';
import { logOutUser, refreshUserInfo, refreshUserToken } from "../store/Auth/actions";

const sidebarItems = [
  {
    identifier: "consultants",
    name: "Consultants",
    image: Candidates,
    imageSelected: CandidatesSelected,
    path: '/consultants',
    matches: ['/consultants'],
  },
  {
    identifier: "recruitmentDrafts",
    name: "Recruitment Drafts",
    image: Rec,
    imageSelected: RecActive,
    path: '/recruitmentDrafts',
    matches: ['recruitmentDrafts'],
  },
  {
    identifier: "pendingRecruitmentDrafts",
    name: "Pending Recruitment Drafts",
    image: PenRec,
    imageSelected: PenRecActive,
    path: '/pendingRecruitmentDrafts',
    matches: ['pendingRecruitmentDrafts'],
    badge: 'pendingRecruitmentDraftNotification?.pendingCount',
  },
  {
    identifier: "approvals",
    name: "Change Requests",
    image: Approvals,
    imageSelected: ApprovalsSelected,
    path: '/approvals',
    matches: ['approvals'],
    badge: 'changeRequestsCount?.changeRequestNotificationCount',
  },
  {
    identifier: "requestOptions",
    name: "Request Options",
    image: RequestOptions,
    imageSelected: RequestOptionsSelected,
    path: '/requestOptions/0',
    matches: ['requestOptions'],
    badge: 'pendingRequestOptionsNotification?.pendingCount',
  },
  {
    identifier: "sow",
    name: "SOW",
    image: SOW,
    imageSelected: SOWActive,
    path: '/sow/0',
    matches: ['sow'],
    badge: 'pendingSOWNotification?.pendingCount',
  },
  {
    identifier: "reports",
    name: "Reports",
    image: Reports,
    imageSelected: ReportsSelected,
    path: '/reports',
    matches: ['reports'],
  },
  {
    identifier: "settings",
    name: "Settings",
    image: Settings,
    imageSelected: SettingsSelected,
    path: '/dropdownSettings',
    matches: ['dropdownSettings', 'vendorSettings', 'inputDesigner', 'formDesigner', 'regionBuilder'],
  },
  {
    identifier: "financeExport",
    name: "Exports",
    image: Exports,
    imageSelected: ExportsSelected,
    path: '/financeExport',
    matches: ['financeExport'],
  },
  {
    identifier: "userManagement",
    name: "User Permissions",
    image: Profiles,
    imageSelected: ProfilesSelected,
    path: '/userManagement',
    matches: ['userManagement'],
  },
  {
    identifier: "logs",
    name: "Application Logs",
    image: Logs,
    imageSelected: LogsSelected,
    path: '/logs',
    matches: ['logs'],
  },
];

const inActiveMinutes = 60;
const addMinutes = 0.2;

const SideBar = () => {
  const intervalId = useRef();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser = null } = useSelector(state => state.auth);
  const changeRequestsCount = useSelector((state) => state.changeRequestNotification);
  const pendingRecruitmentDraftNotification = useSelector((state) => state.pendingRecruitmentDraftNotification);
  const pendingRequestOptionsNotification = useSelector((state) => state.pendingRequestOptionsNotification);
  const pendingSOWNotification = useSelector((state) => state.pendingSOWNotification);

  const [activeItem, setActiveItem] = useState(0);
  const [showTimerDialog, setShowTimerDialog] = useState(false);
  const [loadingSession, setLoadingSession] = useState(false);
  const [open, setOpen] = useState(false);
  const [showRegion, setShowRegion] = useState(false);
  const [currentRegion, setCurrentRegion] = useState('');
  const [settingRegion, setSettingRegion] = useState(false);
  const [hasRegionPermission, setHasRegionPermission] = useState(true);
  const [allowedList, setAllowedList] = useState([]);
  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (currentUser !== null) {
      start();
      if (showTimerDialog) {
        intervalId.current = setTimeout(logout, addMinutes * 60 * 1000);
      }
      return () => {
        clearInterval(intervalId.current);
      };
    } else {
      pause();
    }
  }, [currentUser, showTimerDialog]);

  useEffect(() => {
    if (currentUser !== null) {
      localStorage.setItem('userDetails', JSON.stringify(currentUser));
      setAllowedList((currentUser?.permisson_matrix || []).filter(item => (item?.actions || []).includes('READ')).map(item => item.identifier));
      const regions = currentUser?.regions || [];
      if (regions?.length) {
        const regionCodes = regions.map(item => (item?.region_code || "").toUpperCase());
        const regionCode = (localStorage.getItem("master-data-region-code") || '').toUpperCase();
        setCurrentRegion(regionCode);
        setHasRegionPermission(regionCodes.includes(regionCode));
      } else {
        setCurrentRegion('US');
        setHasRegionPermission(true);
      }
      setInitialLoading(false);
    } else {
      localStorage.removeItem('userDetails');
    }
  }, [currentUser]);

  useEffect(() => {
    let found = false;
    sidebarItems.forEach((item, index) => {
      item.matches.forEach(match => {
        if (location.pathname.includes(match)) {
          setActiveItem(index);
          found = true;
        };
      })
    });
    if (!found) {
      setActiveItem(null);
    }
    const sectionId = localStorage.getItem("sectionId") || null;
    if (currentUser !== null && sectionId) {
      refreshUserPermission();
      if (allowedList.includes('approvals')) {
        dispatch(fetchChangeRequestNotification());
      }
      if (allowedList.includes('pendingRecruitmentDrafts')) {
        dispatch(fetchAllPendingRecruitmentDraftsNotifications());
      }
      if (allowedList.includes('requestOptions')) {
        dispatch(fetchAllPendingRequestOptionsNotifications());
      }
      if (allowedList.includes('sow')) {
        dispatch(fetchAllPendingSOWNotifications());
      }
    }
  }, [location.pathname]);

  const refreshUserPermission = async () => {
    let email = currentUser?.email || "";
    const payload = {
      email: email,
    };
    dispatch(refreshUserInfo(payload));
  }

  const handlePath = (index = 0) => {
    if (activeItem !== index) {
      setActiveItem(index);
      history.push(sidebarItems?.[index]?.path || "");
    }
  }

  const handleOnIdle = () => {
    if (currentUser !== null) {
      setShowTimerDialog(true);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const { start, reset, pause } = useIdleTimer({
    timeout: inActiveMinutes * 60 * 1000,
    onIdle: () => handleOnIdle(),
    onActive: () => { },
    onAction: () => { },
    startManually: true,
  });

  const handleContinueActivity = () => {
    setLoadingSession(true);
    dispatch(refreshUserToken({ refresh_token: currentUser?.refresh_token }, (res) => {
      if (res.status) {
        setShowTimerDialog(false);
        clearInterval(intervalId.current);
        reset();
      }
      setLoadingSession(false);
    }));
  }

  const handleSetRegion = (code = "") => {
    if (code !== currentRegion) {
      setCurrentRegion(code);
      setSettingRegion(true);
      setTimeout(() => {
        localStorage.setItem('master-data-region-code', code);
        window.location.reload();
      }, 1000);
    }
  }

  const logout = () => {
    setShowTimerDialog(false);
    setOpen(false);
    dispatch(logOutUser());
  };

  const values = {
    'changeRequestsCount?.changeRequestNotificationCount': changeRequestsCount?.changeRequestNotificationCount,
    'pendingRecruitmentDraftNotification?.pendingCount': pendingRecruitmentDraftNotification?.pendingCount,
    'pendingRequestOptionsNotification?.pendingCount': pendingRequestOptionsNotification?.pendingCount,
    'pendingSOWNotification?.pendingCount': pendingSOWNotification?.pendingCount,
  }

  if (location.pathname === '/login' || location.pathname === '/oldlogin') {
    return null;
  }

  return (
    <>
      <Box sx={{ width: '100px', bgcolor: blue[500], height: '100svh', overflowY: 'scroll', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', gap: 2, py: 3 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          <Box sx={{ width: 35, height: 35, mb: 1 }}>
            <img src={VEngageLogo} alt="VEngage logo" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
          </Box>
          <Box sx={{ bgcolor: blue[500], display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            {sidebarItems?.map((sidebarItem, sidebarItemIndex) => {
              const active = activeItem === sidebarItemIndex;
              if (allowedList.includes(sidebarItem.identifier)) {
                const val = values?.[sidebarItem?.badge] || 0;
                return (
                  <Tooltip placement="right" key={sidebarItem.name} id={sidebarItem.name} title={sidebarItem.name}>
                    <Box id={`sidebar-item-${sidebarItem.name}`} onClick={() => handlePath(sidebarItemIndex)} sx={{ width: 35, height: 35, borderRadius: '50%', cursor: active ? 'default' : 'pointer', position: 'relative' }}>
                      <img src={active ? sidebarItem.imageSelected : sidebarItem.image} alt={sidebarItem.name + ' logo'} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      {values?.[sidebarItem?.badge] ?
                        <Box
                          sx={{
                            color: "#FFFFFF",
                            position: "absolute",
                            right: 0,
                            top: 0,
                            bgcolor: red[500],
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: 10,
                            borderRadius: 8,
                            py: 0.25,
                            px: 0.5,
                          }}
                        >
                          {val > 99 ? '99+' : val}
                        </Box>
                        :
                        null
                      }
                    </Box>
                  </Tooltip>
                )
              }
            })}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
          {process.env.REACT_APP_REGION === null || currentUser?.regions?.length > 1 ?
            <Tooltip id='change-region-tooltip' title="Change Region" placement="right">
              <IconButton id="change-region-btn" sx={{ position: 'relative' }} onClick={() => setShowRegion(true)}>
                <PublicIcon sx={{ color: '#fff' }} />
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, width: '100%' }}>
                  {currentUser?.regions?.map(region => {
                    if (region?.region_code === currentRegion) {
                      return (
                        <Box key={region?.region_code} className={`fi fi-${(region?.region_code || "").toLowerCase()}`} sx={{ width: 15, height: 15, backgroundSize: 'contain', position: 'absolute', bottom: 5, right: 5 }}></Box>
                      )
                    }
                  })}
                </Box>
              </IconButton>
            </Tooltip>
            :
            null
          }
          <Tooltip id='logout-tooltip' title="Logout" placement="right">
            <IconButton id="logout-btn" onClick={() => setOpen(true)}>
              <LogoutIcon sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
          {releaseLogs.length && releaseLogs[0]?.version ?
            <Box
              onClick={() => {
                history.push('/releases');
                setActiveItem(null);
              }}
              sx={{ cursor: 'pointer', color: location.pathname === '/releases' ? blue[800] : '#fff', px: 1, background: location.pathname === '/releases' ? '#fff' : 'transparent', borderRadius: 2 }}
            >
              <Typography style={{ fontSize: '14px', fontWeight: 500 }}>{releaseLogs[0].version}</Typography>
            </Box>
            :
            null
          }
        </Box>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Are you sure you want to log out?
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            <Button onClick={logout} autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showTimerDialog} disableEscapeKeyDown={true}>
          <DialogTitle>
            Inactive Session
          </DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1">Your session is about to expire due to inactivity.</Typography>
            <Typography variant="subtitle2">Your will be logged out in 12 seconds.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={logout} >
              Logout
            </Button>
            <Button
              disabled={loadingSession}
              startIcon={
                loadingSession
                  ?
                  <CircularProgress sx={{ color: 'white' }} size={20} />
                  :
                  null
              }
              variant="contained"
              color="primary"
              onClick={handleContinueActivity}
            >
              Continue Session
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showRegion} onClose={() => setShowRegion(false)} maxWidth='lg' fullWidth>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%', p: 2 }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 2, width: '100%' }}>
                <Typography variant="subtitle2" sx={{ fontSize: 20, color: blue[500] }}>Choose a region</Typography>
                {!settingRegion ?
                  <IconButton sx={{ position: 'relative' }} onClick={() => setShowRegion(false)}>
                    <CloseOutlined sx={{ color: '#000' }} />
                  </IconButton>
                  :
                  null
                }
              </Box>
              <Box sx={{ width: '100%', bgcolor: grey[200], height: 2 }} />
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, width: '100%' }}>
                {currentUser?.regions?.map(region => {
                  let name = _.find(countries, { code: (region?.region_code || "").toLowerCase() })?.name || '';
                  return (
                    <Button disabled={settingRegion} onClick={() => handleSetRegion(region?.region_code)} variant={currentRegion === region?.region_code ? 'outlined' : 'text'} key={region?.region_code} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, textTransform: 'none', px: 2, borderWidth: 2, '&:hover': { borderWidth: 2 } }}>
                      <Box className={`fi fi-${(region?.region_code || "").toLowerCase()}`} sx={{ width: 50, height: 50, backgroundSize: 'contain' }}></Box>
                      <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                        <Typography variant="subtitle2" sx={{ color: '#000' }} >{name || ""}</Typography>
                        {currentRegion === region?.region_code ?
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {settingRegion ?
                              <CircularProgress sx={{ color: green[500], marginRight: 1 }} size={16} />
                              :
                              <CheckCircleIcon sx={{ color: green[500], fontSize: 16, marginRight: '1px' }} />
                            }
                            <Typography variant="subtitle2" sx={{ fontSize: 10, color: '#000' }}>{settingRegion ? `Activating region...` : `Active`}</Typography>
                          </Box>
                          :
                          null
                        }
                      </Box>
                    </Button>
                  )
                })}
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </Dialog>
      </Box>
      {!hasRegionPermission ?
        <Box sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', zIndex: 999 }}>
          <Box sx={{ width: 35, height: 35, color: '#475569' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: '14px', mt: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>You dont seem to have permission for this region!</Typography>
            <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>Please choose a region from below</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2, width: '100%', justifyContent: currentUser?.regions?.length > 1 ? 'center' : 'start' }}>
              {currentUser?.regions.map(region => {
                let name = _.find(countries, { code: (region?.region_code || "").toLowerCase() })?.name || '';
                return (
                  <Button disabled={settingRegion} onClick={() => handleSetRegion(region?.region_code)} color={region?.region_code === currentRegion ? 'success' : 'info'} variant='outlined' key={region?.region_code} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start', gap: 2, textTransform: 'none', px: 2, width: 300, borderColor: region?.region_code === currentRegion ? green[500] : grey[300], borderWidth: 2 }}>
                    <Box className={`fi fi-${(region?.region_code || "").toLowerCase()}`} sx={{ width: 50, height: 50, backgroundSize: 'contain' }}></Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="subtitle2" sx={{ color: '#000' }} >{name || ""}</Typography>
                      {currentRegion === region?.region_code ?
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {settingRegion ?
                            <CircularProgress sx={{ color: green[500], marginRight: 1 }} size={16} />
                            :
                            <CheckCircleIcon sx={{ color: green[500], fontSize: 16, marginRight: '1px' }} />
                          }
                          <Typography variant="subtitle2" sx={{ fontSize: 10, color: '#000' }}>{settingRegion ? `Activating region...` : `Active`}</Typography>
                        </Box>
                        :
                        null
                      }
                    </Box>
                  </Button>
                )
              })}
            </Box>
          </Box>
        </Box>
        :
        null
      }
      {initialLoading ?
        <Box sx={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', zIndex: 999 }}>
          <Box sx={{ width: 35, height: 35, color: '#475569' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
              <CircularProgress size={20} color="primary" />
              <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>Loading application data...</Typography>
            </Box>
          </Box>
        </Box>
        :
        null
      }
    </>
  )
};

export default SideBar;