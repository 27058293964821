import { useState, useEffect } from "react";
import { Grid, TextField, Box, Select, MenuItem, Typography, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BACKEND_URL } from "../../constants/constants";
import axios from "axios";
import { userRole } from "../../constants/userRoles";
import { GoogleLogin } from "@react-oauth/google";
import VEngageLogo from "../../assets/image/VEngageLogo.png";
import { appRegions } from "../../constants/config";
import { useSelector, useDispatch } from "react-redux";
import { verifyUser } from "../../store/Auth/actions";

export default function SSOLogin() {
  const regionCode = localStorage.getItem("master-data-region-code");
  const { currentUser = null, loading = false } = useSelector(state => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  const [qrdialog, setQrdialog] = useState(false);
  const [authqr, setAuthqr] = useState(null);
  const [emailid, setEmailid] = useState("");
  const [otpdialog, setOtpdialog] = useState(false);
  const [totp, setTotp] = useState("");
  const [newqrmodal, setNewqrmodal] = useState(false);
  const [currentRegion, setCurrentRegion] = useState(regionCode || 'US');

  useEffect(() => {
    if (currentUser !== null) {
      if (currentUser?.role === userRole.FINANCE) {
        history.push("/master/exports");
      } else if (currentUser?.role === userRole.ITEXECUTIVE) {
        history.push("/master/profile");
      } else if (currentUser?.role === userRole.RECRUITER || currentUser?.role === userRole.RECRUITERADMIN) {
        history.push("/recruitmentDrafts");
      } else if (currentUser?.role === userRole.SOWMANAGER) {
        history.push("/sow");
      } else {
        history.push("/");
      }
    }
  }, [currentUser]);

  const responseMessage = async (response) => {
    let config = {
      headers: {
        'X-CALLING-REGION-CODE': process.env.REACT_APP_REGION || currentRegion,
      }
    }
    const userDetails = await axios.post(BACKEND_URL + "/masterAdmin/user/verifyGoogleToken", { token: response.credential }, config);
    if (userDetails.data.message === "Success" && userDetails.data.data.qr === "") {
      setOtpdialog(true);
      setEmailid(userDetails.data.data.email);
    } else if (userDetails.data.message === "Success" && userDetails.data.data.qr !== "") {
      setAuthqr(userDetails.data.data.qr);
      setEmailid(userDetails.data.data.email);
      setQrdialog(true);
    } else {
      toast.error(userDetails.data.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    }
  };

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={6}
          style={{
            height: "100vh",
            backgroundColor: "#004DA7",
            borderRadius: "0px 30px 30px 0px",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              height: "calc(100vh - 64px)",
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 5 }}>
              <img
                src={VEngageLogo}
                width={100}
                height={100}
                alt='logo'
              />
              {process.env.REACT_APP_REGION
                ?
                null
                :
                <Box sx={{ width: '100%', maxWidth: 360, }}>
                  <Typography variant='subtitle2' sx={{ mb: 1, color: '#fff' }}>Region <span style={{ color: 'red' }}>*</span></Typography>
                  <Select
                    id='log-region-input'
                    sx={{ width: '100%', background: "#FFFFFF" }}
                    displayEmpty
                    value={currentRegion}
                    onChange={(e) => {
                      setCurrentRegion(e?.target?.value);
                    }}
                  >
                    <MenuItem disabled value="">{"Please choose a region"}</MenuItem>
                    {(appRegions.map(item => ({ label: item?.name || "", value: item?.code || "" }))).map((option, index) => {
                      return (
                        <MenuItem key={index} value={option?.value || ""}>
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Box className={`fi fi-${(option?.value || "").toLowerCase()}`} sx={{ width: 30, height: 30, backgroundSize: 'contain' }}></Box>
                            <Typography variant="subtitle2" sx={{ fontSize: 14 }}>{option.label}</Typography>
                          </Box>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </Box>
              }
              <GoogleLogin onSuccess={responseMessage} size="large" />
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            height: "calc(100vh - 64px)",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "30px 0px 0px 30px",
            }}
            src='contract.png'
            alt='contract_Illustration'
          />
        </Grid>
      </Grid>

      {/* QR Dialog */}
      <Dialog
        open={qrdialog}
        onClose={() => setQrdialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='md'
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0 0 9%",
        }}
      >
        <DialogTitle>Scan QR</DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogContentText>
            Scan the QR code in your authenticator app
          </DialogContentText>
          <img src={authqr} alt='qr' />
          <br />
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <DialogContentText>Recommended Authenticator App</DialogContentText>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <a
              href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_IN&gl=US'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                alt='google play'
                width='115'
                height='45'
              />
            </a>
            <a
              href='https://apps.apple.com/us/app/google-authenticator/id388497605'
              target='_blank'
              rel='noreferrer'
            >
              <img
                src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/1200px-Download_on_the_App_Store_Badge.svg.png'
                alt='app store'
                width='100'
                height='30'
              />
            </a>
          </Grid>
          <DialogContentText>
            Click here to enter your OTP from authenticator app
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button id='otp-input' variant="contained" onClick={() => { setOtpdialog(true); }}>Enter OTP</Button>
        </DialogActions>
      </Dialog>

      {/* OTP Dialog */}
      <Dialog
        open={otpdialog}
        onClose={() => setOtpdialog(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='md'
      >
        <DialogTitle>Enter OTP</DialogTitle>
        <DialogContent>
          <br />
          <TextField
            id='otp-input'
            variant='outlined'
            placeholder='OTP'
            style={{
              width: "400px",
            }}
            onChange={(e) => setTotp(e.target.value)}
            type='number'
          />
          {qrdialog ? (
            <></>
          ) : (
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
              }}
            >
              {/* Section to generate new QR */}
              <p
                style={{
                  fontSize: "12px",
                  textAlign: "center",
                  margin: "10px 0 0",
                }}
              >
                Lost access to your authenticator app?
              </p>
              &nbsp;
              <p
                style={{
                  color: "#1976d2",
                  fontSize: "12px",
                  textAlign: "center",
                  cursor: "pointer",
                  margin: "10px 0 0",
                }}
                id='qr-generate-btn'
                onClick={() => {
                  setNewqrmodal(true);
                }}
              >
                Click here to generate a new QR
              </p>
            </Grid>
          )}
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0 9%",
          }}
        >
          <Button
            id='verify-otp-btn'
            variant="contained"
            onClick={async () => {
              dispatch(verifyUser({ email: emailid, totp: totp }, res => {
                if (!res?.status) toast.error('Incorrect OTP!');
              }));
            }}
          >
            Verify OTP
          </Button>
        </DialogActions>
      </Dialog>
      {/* New qr Modal */}

      <Dialog
        open={newqrmodal}
        onClose={() => setNewqrmodal(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='md'
      >
        <DialogTitle>Generate New QR</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please read the following instructions carefully before generating a
            new QR code
          </DialogContentText>
          <DialogContentText>
            <ul>
              <li>
                This action will remove the older QR code from your account.
              </li>
              <li>
                You will have to scan the new QR code in your authenticator app.
              </li>
              <li>
                Administrators of the application will be informed about the
                change.
              </li>
            </ul>
          </DialogContentText>
          <DialogContentText>
            Click here to generate a new QR code
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            id='generate-qr-btn'
            variant="contained"
            onClick={async () => {
              let config = {
                headers: {
                  'X-CALLING-REGION-CODE': process.env.REACT_APP_REGION || currentRegion,
                }
              }
              await axios.post(BACKEND_URL + "/masterAdmin/user/EditMFA", { email: emailid }, config)
                .then((res) => {
                  if (res.data.message === "Success") {
                    setAuthqr(res.data.data.qr);
                    setQrdialog(true);
                    setOtpdialog(false);
                    setNewqrmodal(false);
                  } else {
                    toast.error("Something went wrong!", {
                      position: toast.POSITION.TOP_RIGHT,
                      autoClose: 2000,
                    });
                  }
                });
            }}
          >
            Generate QR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

