import { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, FormControl, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { toast } from "react-toastify";
import { extractValuableOptions } from '../../../utils/consultantConstants';
import { useSelector } from 'react-redux';

const SectionCUModal = ({ open = false, currentData = null, handleClose = () => { }, handleAdd = () => { }, handleUpdate = () => { } }) => {
  const { permissionList = [], loading = false } = useSelector(state => state.userMagement);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (currentData) {
      setData({ ...currentData });
    }
  }, [currentData]);

  const handleChange = (name, value) => {
    setData(prev => {
      return {
        ...prev,
        [name]: value
      }
    });
  }

  const checkValid = () => {
    let valid = true;
    if (!data?.name) {
      toast.error('Please enter permission name!');
      valid = false;
    } else if (!data?.identifier) {
      toast.error('Please enter permission identifier!');
      valid = false;
    }
    return valid;
  };

  const handleAddSection = () => {
    const check = checkValid();
    if (check) {
      handleAdd({ ...data });
      setData(null);
    }
  };

  const handleUpdateSection = () => {
    const check = checkValid();
    if (check) {
      handleUpdate({ id: currentData?.id || "", name: data?.name || "", identifier: data?.identifier || "" });
      setData(null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="add-item-modal"
      aria-describedby="add-item-modal-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Typography variant='h6'>{currentData !== null ? `Update permission` : `Add new permission`}</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Name <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              placeholder={"Please enter permission name!"}
              sx={{ width: "100%", background: "#FFFFFF" }}
              type='text'
              value={data?.name || ""}
              onChange={(e) => handleChange('name', e?.target?.value)}
              id='name-input'
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Identifier <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              placeholder={"Please enter permission identifier!"}
              sx={{ width: "100%", background: "#FFFFFF" }}
              type='text'
              value={data?.identifier || ""}
              onChange={(e) => handleChange('identifier', e?.target?.value)}
              id='identifier-input'
            />
          </Grid>
          {currentData ?
            null
            :
            <Grid item xs={12}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>Role</Typography>
              <FormControl fullWidth>
                <Select
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  displayEmpty
                  value={data?.parent || ""}
                  onChange={(e) => handleChange('parent', e?.target?.value)}
                  id='user-role'
                >
                  <MenuItem disabled value="">{"Please select a parent!"}</MenuItem>
                  {(extractValuableOptions((permissionList || []).map(item => ({ value: item?.id, label: item?.name })))).map((option, index) => {
                    return (
                      <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button id='form-cancel-btn' onClick={handleClose}>Close</Button>
        <Button id='form-add-btn' onClick={() => currentData ? handleUpdateSection() : handleAddSection()} variant='contained'>{currentData ? `Update` : `Add`}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default SectionCUModal;