import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';

const SOWRejectModal = ({ open = false, handleClose, handleReject, currentData = null }) => {

	const handleModalClose = () => {
		handleClose();
	}

	const handleRejectDoc = () => {
		handleReject({ id: currentData?.id, status: 1 });
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-item-modal"
			aria-describedby="add-item-modal-description"
			fullWidth
			maxWidth={"sm"}
		>
			<DialogTitle>Reject statement of work</DialogTitle>
			<DialogContent>
				<Typography variant='body1'>Are you sure you want to reject this statement of work?</Typography>
			</DialogContent>
			<DialogActions>
				<Button id='close-btn' onClick={handleModalClose}>No</Button>
				<Button id='reject-btn' onClick={handleRejectDoc} variant='contained' color='error'>Reject</Button>
			</DialogActions>
		</Dialog>
	)
}

export default SOWRejectModal;