import {
  SAVE_CONSULTANT_INFORMATION_IN_PROGRESS,
  SAVE_CONSULTANT_INFORMATION_SUCCESS,
  SAVE_CONTRACT_IN_PROGRESS,
  SAVE_CONTRACT_SUCCESS,
  SAVE_HUMAN_RESOURCE_SUCCESS,
  SAVE_PAYMENTS_SUCCESS,
  SAVE_PAYMENTS_IN_PROGRESS,
  SAVE_HUMAN_RESOURCE_IN_PROGRESS,
} from "../../../constants/store";

export const initialState = {
  loading: false,
  error: false,
  validate: false,
  contractDetail: null,
  consultantInformation: null,
  contract: null,
  payment: null,
  humanResources: null,
  miscellaneous: null,
  compareObject: [],
};

function contractDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_CONSULTANT_INFORMATION_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
        validate: true,
      };
      return newState;
    }
    case SAVE_CONSULTANT_INFORMATION_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        validate: false,
        consultantInformation: action.payload,
      };
      return newState;
    }
    case SAVE_CONTRACT_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case SAVE_CONTRACT_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        contract: action.payload,
      };
      return newState;
    }
    case SAVE_PAYMENTS_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case SAVE_PAYMENTS_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        payment: action.payload,
      };
      return newState;
    }
    case SAVE_HUMAN_RESOURCE_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }
    case SAVE_HUMAN_RESOURCE_SUCCESS: {
      const newState = {
        ...state,
        loading: false,
        humanResources: action.payload,
      };
      return newState;
    }
    default:
      return state;
  }
}

export default contractDetailsReducer;
