import { Box, Typography } from '@mui/material';
import { extractTableDataPoints, formatDashboardName } from '../../utils/chartHelper';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import NoDataCard from './noDataCard';

const TableCard = ({ data = [], visualizationData = null, title }) => {

  const [axes, setAxes] = useState([]);
  const [list, setList] = useState([]);
  const [chartTitle, setTitle] = useState("");

  useEffect(() => {
    const dataPoints = extractTableDataPoints(data, visualizationData, title);
    setList(dataPoints?.list || []);
    setAxes(dataPoints?.axes || []);
    setTitle(dataPoints?.title || "");
  }, [data, visualizationData, title]);

  if (!list.length) {
    return <NoDataCard />
  }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Typography noWrap sx={{ fontSize: '16px', fontWeight: 'medium', color: '#475569' }}>{chartTitle}</Typography>
      <DataGrid
        rows={list.map((item, itemIndex) => ({ ...item, id: itemIndex }))}
        columns={axes.map((item, itemIndex) => ({
          field: item.dataKey,
          headerName: formatDashboardName(item.displayName),
          id: itemIndex,
          flex: 1,
        }))}
        pagination
        density="compact"
        disableSelectionOnClick
        disableRowSelectionOnClick
        rowsPerPageOptions={[100]}
        headerHeight={50}
        sx={{
          px: 2,
          border: 0,
          height: "95%",
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#1089FF",
          },
          "& .MuiDataGrid-cell": {
            margin: 0,
            border: 0,
          },
          "& .MuiDataGrid-row": {
            margin: 0,
          },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}

export default TableCard;