import api from "../../config/https";
import {
  APPROVE_REQUEST_OPTIONS_LIST_FAILED,
  APPROVE_REQUEST_OPTIONS_LIST_SUCESS,
  APPROVE_REQUEST_OPTION_FAILED,
  APPROVE_REQUEST_OPTION_IN_PROGRESS,
  APPROVE_REQUEST_OPTION_SUCESS,
  REQUEST_OPTIONS_LIST_FAILED,
  REQUEST_OPTIONS_LIST_IN_PROGRESS,
  REQUEST_OPTIONS_LIST_SUCESS,
} from "../../constants/store";

export const requestOptionsPageLimit=20;

export const fetchAllRequestsOptions = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_OPTIONS_LIST_IN_PROGRESS,
    });
    let res = await api.get(`/masterAdmin/settings/getAllData?status=${payload.status}&offset=${payload.page*requestOptionsPageLimit}&limit=${requestOptionsPageLimit}&search=${payload.searchText}`);
    if (res?.data?.status && res?.data?.data) {
      let data=res.data.data;
      let list=data?.data || [];
      dispatch({
        type: REQUEST_OPTIONS_LIST_SUCESS,
        payload: {data:list,maxPage:data?.totalCount || list.length, currentPage:payload.page},
      });
    }else{
      dispatch({
        type: REQUEST_OPTIONS_LIST_FAILED,
        payload: {reset:payload.page===0},
      });
    }
  } catch (e) {
    dispatch({
      type: REQUEST_OPTIONS_LIST_FAILED,
      payload: {reset:payload.page===0},
    });
  }
};

export const approveRequestedOption = (payload,callback) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_REQUEST_OPTION_IN_PROGRESS,
    });
    let res = await api.put(payload.url);
    if (res?.data?.status && res?.data?.data) {
      dispatch({
        type: APPROVE_REQUEST_OPTION_SUCESS,
      });
    }else{
      dispatch({
        type: APPROVE_REQUEST_OPTION_FAILED,
      });
    }
    if(callback){
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: APPROVE_REQUEST_OPTION_FAILED,
    });
  }
};

export const disapproveRequestedOption = (payload,callback) => async (dispatch) => {
  try {
    dispatch({
      type: APPROVE_REQUEST_OPTION_IN_PROGRESS,
    });
    let res = await api.put(`/masterAdmin/convertConstantStatus/${payload.id}?status=1&comment=${payload.comment}`);
    if (res?.data?.status && res?.data?.data) {
      dispatch({
        type: APPROVE_REQUEST_OPTION_SUCESS,
      });
    }else{
      dispatch({
        type: APPROVE_REQUEST_OPTION_FAILED,
      });
    }
    if(callback){
      callback(res.data);
    }
  } catch (e) {
    dispatch({
      type: APPROVE_REQUEST_OPTION_FAILED,
    });
  }
};

export const getMatchingData = async (payload) => {
  try {
    let res = await api.get(`/masterAdmin/settings/getMatchingData?value=${payload.value}&field_type=${payload.field_type}`);
    return res.data;
  } catch (e) {
    console.log(e);
  }
};

export const getRequestOptionsExportLogs = async (startdate,enddate) => {
  try {
    let res = await api.get(`/masterAdmin/downloadConstantLog?start_date=${startdate}&end_date=${enddate}`);
    return res.data
  } catch (e) {
    console.log(e)
  }
}