import { Box, Button, Typography, Dialog, DialogContent, DialogActions, CircularProgress, Alert, AlertTitle } from '@mui/material';
import { grey, blue } from '@mui/material/colors';
import { Fragment, useEffect, useState } from 'react';
import { getFieldValues } from '../../../constants/config';
import { getMatchingData } from '../../../store/RequestOptions/actions';

const ApproveModal = ({ open = false, handleClose, handleApprove, handleDisapprove, currentData = null, mode = 'approve' }) => {
  const [data, setData] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [fieldValues, setFieldValues] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentData?.field_type) {
      handleInit();
    }
  }, [currentData]);

  const handleInit = async () => {
    setLoading(true);
    let f = getFieldValues(currentData?.field_type);
    if (currentData?.field_type === 'client' && (currentData?.is_client === null || currentData?.is_client === 0)) {
      f = getFieldValues('endClients');
    }
    let v = f?.variables || [];
    let d = null;
    v.forEach(item => {
      const key = item.key;
      if (currentData[key]) {
        d = {
          ...d,
          [key]: currentData[key]
        }
      }
    });
    if (currentData?.field_type === 'business_unit') {
      d = {
        ...d,
        bu_owner: currentData?.options || "",
      }
    } else if (currentData?.field_type === 'domain') {
      d = {
        ...d,
        name: currentData?.options || "",
      }
    } else if (currentData?.field_type === 'client') {
      d = {
        ...d,
        name: currentData?.options || "",
      }
    } else if (currentData?.field_type === 'vendor') {
      d = {
        ...d,
        vendorName: currentData?.options || "",
      }
    }
    setFieldValues(f);
    setData(d);
    const res = await getMatchingData({ field_type: currentData?.field_type, value: currentData?.options });
    if (res?.status) {
      let d = res?.data || []
      setOptions(d.map(item => ({ value: item, label: item })));
    }
    setLoading(false);
  }

  const handleDispproveSubmit = () => {
    handleDisapprove({ id: currentData.id, comment: `Similar to this option we already have an option as ${selectedValue.label}` });
  }

  const handleSubmit = () => {
    let url = `/masterAdmin/convertConstantStatus/${currentData.id}?status=2`;
    if (fieldValues?.apiFieldName === 'hr') {
      url = `/masterAdmin/convertHrStatus/${currentData.id}?status=2`;
    } else if (fieldValues?.apiFieldName === 'domains') {
      url = `/masterAdmin/convertDomainStatus/${currentData.id}?status=2`;
    } else if (fieldValues?.apiFieldName === 'businessUnit') {
      url = `/masterAdmin/convertBusinessUnitStatus/${currentData.id}?status=2`;
    } else if (fieldValues?.apiFieldName === 'clients') {
      url = `/masterAdmin/convertClientStatus/${currentData.id}?status=2`;
    } else if (fieldValues?.apiFieldName === 'endClients') {
      url = `/masterAdmin/convertClientStatus/${currentData.id}?status=2`;
    } else if (fieldValues?.apiFieldName === 'vendor') {
      url = `/masterAdmin/convertVendorStatus/${currentData.id}?status=2`;
    }
    handleApprove({ url });
  }

  const textInputs = fieldValues?.variables || [];

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      fullWidth
      maxWidth='sm'
    >
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            {
              mode === 'approve'
                ?
                <Fragment>
                  {
                    selectedValue?.value
                      ?
                      <Fragment>
                        <Box sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: '#fdeded', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
                          <Box sx={{ width: 35, height: 35, color: '#DB2F2F' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-exclamation-triangle"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20z" /></svg>
                          </Box>
                        </Box>
                        <Box sx={{ mt: 1, flex: 1 }}>
                          <Typography sx={{ fontSize: '25px', color: '#101828', fontWeight: 'medium' }}>Disapprove Request ?</Typography>
                          <Typography sx={{ fontSize: '16px', color: grey[600], fontWeight: 400 }}>Are you sure you want to disapprove this request ?</Typography>
                        </Box>
                      </Fragment>
                      :
                      <Fragment>
                        <Box sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: '#86E49A', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
                          <Box sx={{ width: 35, height: 35, color: '#0FB333' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-check"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 14l4 4L19 8" /></svg>
                          </Box>
                        </Box>
                        <Box sx={{ mt: 1, flex: 1 }}>
                          <Typography sx={{ fontSize: '25px', color: '#101828', fontWeight: 'medium' }}>Approve Request ?</Typography>
                          <Typography sx={{ fontSize: '16px', color: grey[600], fontWeight: 400 }}>Are you sure you want to approve this request ?</Typography>
                        </Box>
                      </Fragment>
                  }
                </Fragment>
                :
                <Fragment>
                  {currentData?.status === 1
                    ?
                    <Fragment>
                      <Box sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: '#fdeded', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
                        <Box sx={{ width: 35, height: 35, color: '#DB2F2F' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-exclamation-triangle"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v4m0 3v.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.068C2.842 18.4 3.808 20 5.313 20z" /></svg>
                        </Box>
                      </Box>
                      <Box sx={{ flex: 1, mt: 1 }}>
                        <Typography sx={{ fontSize: '25px', color: '#101828', fontWeight: 'medium' }}>Disapproved Request</Typography>
                        <Alert severity={'error'} sx={{ mt: 2 }}>
                          <AlertTitle>The request was marked disapproved due to the following reason stated below:</AlertTitle>
                          {currentData?.comment || ""}
                        </Alert>
                      </Box>
                    </Fragment>
                    :
                    null
                  }
                  {
                    currentData?.status === 2
                      ?
                      <Fragment>
                        <Box sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: '#86E49A', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
                          <Box sx={{ width: 35, height: 35, color: '#0FB333' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-check"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 14l4 4L19 8" /></svg>
                          </Box>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography sx={{ fontSize: '25px', color: '#101828', fontWeight: 'medium' }}>Approve Request</Typography>
                          <Alert severity={'success'} sx={{ mt: 2 }}>
                            <AlertTitle>The request was marked approved</AlertTitle>
                          </Alert>
                        </Box>
                      </Fragment>
                      :
                      null
                  }
                  {
                    currentData?.status === 0
                      ?
                      <Fragment>
                        <Box sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: '#e5f6fd', display: 'flex', alignItems: 'center', justifyContent: 'center', mr: 2 }}>
                          <Box sx={{ width: 35, height: 35, color: '#2a94d6' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-clock"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.5V12l3.5 2m5.5-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                          </Box>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography sx={{ fontSize: '25px', color: '#101828', fontWeight: 'medium' }}>Pending Request</Typography>
                          <Alert severity={'info'} sx={{ mt: 2 }}>
                            <AlertTitle>The request is still pending for approval!</AlertTitle>
                          </Alert>
                        </Box>
                      </Fragment>
                      :
                      null
                  }
                </Fragment>
            }
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '86%', mt: 2 }}>
            {textInputs.map((item, index) => {
              return (
                <Box key={index} sx={{ width: '100%', display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant='subtitle1' color={grey[700]} textTransform={'capitalize'}>{(item?.label || "").toLowerCase()} :</Typography>
                  <Typography variant='subtitle1' fontWeight='medium'>{data?.[item.key] || ""}</Typography>
                </Box>
              )
            })}
            {mode === 'approve' ?
              <Fragment>
                {loading ?
                  <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <CircularProgress color="primary" size={20} />
                    <Typography variant='subtitle2' color={blue[700]}>Fetching similar options...</Typography>
                  </Box>
                  :
                  options?.length ?
                    <Fragment>
                      <Typography variant="subtitle2" sx={{ color: blue[400], mt: 2 }}>Similar Options</Typography>
                      <Box sx={{ width: '100%', border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 1 }}>
                        {options?.map((option, index) => {
                          return (
                            <Typography
                              id={`option-btn-${index + 1}`}
                              onClick={() => setSelectedValue(prev => (prev?.value === option?.value ? null : option))}
                              variant='subtitle2'
                              sx={{ cursor: 'pointer', px: 2, py: 1, color: '#101828', bgcolor: selectedValue?.value !== option?.value ? '#fff' : blue[200], borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
                            >
                              {option?.label || ""}
                            </Typography>
                          )
                        })}
                      </Box>
                    </Fragment>
                    :
                    <Typography variant="subtitle2" sx={{ color: blue[400], mt: 2 }}>No similar options founds</Typography>
                }
                <Typography color='error' variant='subtitle2' mt={2}>This action cannot be undone.</Typography>
              </Fragment>
              : null
            }
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ bgcolor: '#F3F3F3', p: 2 }}>
        {
          mode === 'approve'
            ?
            <Fragment>
              {
                selectedValue
                  ?
                  <Button id='disapprove-modal-btn' variant="contained" color="error" disableElevation onClick={handleDispproveSubmit}>
                    Disapprove
                  </Button>
                  :
                  <Button id='approve-modal-btn' variant="contained" color="success" disableElevation sx={{ bgcolor: '#0FB333', '&:hover': { bgcolor: '#0FB333' }, }} onClick={handleSubmit}>
                    Approve
                  </Button>
              }
            </Fragment>
            :
            null
        }
        <Button id='cancel-modal-btn' variant="contained" disableElevation sx={{ bgcolor: '#fff', color: '#101828', '&:hover': { bgcolor: '#fff' }, }} onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog >
  )
}

export default ApproveModal;