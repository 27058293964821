import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from "../../../components/AppBar";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip, Typography, Chip, Tabs, Tab } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { } from "../../../store/Settings/action";
import moment from "moment";
import { toast } from "react-toastify";
import { getSOWs, getSOWDropDownOptions, sowListLimit, addSOW, deleteSOW, getSOWRevisions, deleteSOWRevision, getSOWClientsAsPerEntity, uploadSOW, updateSOWStatus } from '../../../store/Sow/action';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import SOWCUModal from './SOWCUModal';
import SOWDeleteModal from './SOWDeleteModal';
import { blue, grey } from '@mui/material/colors';
import SOWFilter from './SOWFilter';
import SearchBar from '../../../components/SearchBar';
import TuneIcon from "@mui/icons-material/Tune";
import SOWRevisions from './SOWRevisionHistory';
import UpdateIcon from '@mui/icons-material/Update';
import './sowStyles.css';
import SOWUploadModal from './SOWUploadModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Check, Close, Refresh } from '@mui/icons-material';
import SOWRejectModal from './SOWRejectModal';
import _ from 'lodash';
import { getCurrentPageIdentifier } from '../../../utils/appHelper';

const tabs = [
  {
    label: 'Completed',
    key: 3,
  },
  {
    label: 'Pending',
    key: 0,
  },
  {
    label: 'Failed',
    key: 1,
  },
];

const initialFilter = {
  offset: 0,
  limit: sowListLimit,
  entity_id: [],
  client_id: [],
  endDateRangeStart: "",
  endDateRangeEnd: "",
  search: "",
}

const pendingFilter = {
  offset: 0,
  limit: sowListLimit,
  extraction_status: [0, 2],
}

const failedFilter = {
  offset: 0,
  limit: sowListLimit,
  extraction_status: [1],
}

const SOWScreen = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { currentUser = null } = useSelector(state => state.auth);
  let hasWritePermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: getCurrentPageIdentifier() });
  if (foundItem) {
    hasWritePermission = (foundItem?.actions || []).includes('WRITE');
  }
  const options = useSelector((state) => state.sow.options);
  const list = useSelector((state) => state.sow.list);
  const loading = useSelector((state) => state.sow.loading);
  const maxPage = useSelector((state) => state.sow.maxPage);
  const currentPage = useSelector((state) => state.sow.currentPage);
  const revList = useSelector((state) => state.sow.revList);
  const [searchText, setSearchText] = useState('');
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRevisionModal, setShowRevisionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const [filter, setFilter] = useState(initialFilter);
  const [tempFilter, setTempFilter] = useState(initialFilter);
  const [activeEntity, setActiveEntity] = useState(null);
  const [activeClient, setActiveClient] = useState(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [tempEntity, setTempEntity] = useState(null);
  const [tempClient, setTempClient] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  useEffect(() => {
    const pathname = location?.pathname || "";
    const ids = pathname.split("/");
    const id = ids.length > 1 ? ids[ids.length - 1] : "";
    if (id) {
      setActiveTab(parseInt(id));
    }
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getSOWDropDownOptions());
  }, []);

  useEffect(() => {
    if (filter?.client_id?.length && filter?.entity_id?.length && activeTab === 0) {
      dispatch(getSOWs(filter));
    }
  }, [filter.client_id, filter.entity_id]);

  useEffect(() => {
    if (activeTab === 1) {
      dispatch(getSOWs(pendingFilter));
      setFilter(pendingFilter);
    } else if (activeTab === 2) {
      dispatch(getSOWs(failedFilter));
      setFilter(failedFilter);
    } else {
      setActiveClient(null);
      setActiveEntity(null);
      setFilter(initialFilter);
    }
  }, [activeTab]);

  useEffect(() => {
    if (filter?.client_id?.length && filter?.entity_id?.length) {
      setFilter(prev => {
        return { ...prev, search: searchText }
      });
      dispatch(getSOWs({
        ...filter,
        offset: 0,
        search: searchText,
      }));
    }
  }, [searchText]);

  const handleChangeTab = (e, value) => {
    setActiveTab(value);
  }

  const getSOWStatus = (value = 0) => {
    switch (value) {
      case 0:
        return 'Processing Document'
      case 1:
        return 'Document Rejected'
      case 2:
        return 'Document Review Pending'
      case 3:
        return 'Active Document'
      default:
        return ''
    }
  }

  const handlePageChange = (pageNo) => {
    setFilter(prev => {
      return { ...prev, offset: pageNo }
    });
    dispatch(getSOWs({ ...filter, page: pageNo }));
  };

  const handleRefreshPending = () => {
    dispatch(getSOWs(pendingFilter));
    setFilter(pendingFilter);
  };

  const handleShowUploadModal = () => {
    setShowUploadModal(true);
    setCurrentData(null);
  };

  const handleHideUploadModal = () => {
    setShowUploadModal(false);
    setCurrentData(null);
  };

  const handleUpload = (payload) => {
    dispatch(uploadSOW(payload, res => {
      if (res?.status) {
        toast.success("Statement of work uploaded successfully");
        if (activeTab !== 1) {
          setActiveTab(1);
        } else {
          dispatch(getSOWs(pendingFilter));
          setFilter(pendingFilter);
        }
        setShowUploadModal(false);
      } else {
        toast.error("Failed to add new statement of work!");
      }
    }));
  };

  const handleShowRejectModal = (data) => {
    setShowRejectModal(true);
    setCurrentData(data);
  };

  const handleCloseRejectModal = () => {
    setShowRejectModal(false);
    setCurrentData(null);
  };

  const handleRejectDocument = (payload) => {
    setShowRejectModal(false);
    setCurrentData(null);
    if (payload?.id) {
      dispatch(updateSOWStatus(payload, (res) => {
        if (res?.status) {
          toast.success("Statement of work rejected successfully");
          dispatch(getSOWs(pendingFilter));
          setFilter(pendingFilter);
        } else {
          toast.error("Failed to reject statement of work!");
        }
      }));
    }
  };

  const handleShowAddModal = (data) => {
    setShowAddModal(true);
    setCurrentData(data);
  };

  const handleCloseModal = () => {
    setShowAddModal(false);
    setCurrentData(null);
  };

  const handleShowRevisionModal = (rows) => {
    setCurrentData(rows);
    dispatch(getSOWRevisions({ id: rows?.id || "" }, res => {
      if (res?.status) {
        setShowRevisionModal(true);
      } else {
        toast.error('Failed to fetch revisions!');
      }
    }));
  };

  const handleCloseRevisionModal = () => {
    setCurrentData(null);
    setShowRevisionModal(false);
  };

  const handleShowEditModal = (rows) => {
    setCurrentData(rows);
    setShowUploadModal(true);
  };

  const handleShowDeleteModal = (rows) => {
    setCurrentData(rows);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = (rows) => {
    setShowDeleteModal(false);
    setCurrentData(null);
  };

  const handleShowFilter = () => {
    setTempFilter(filter);
    setShowFilter(true);
  };

  const handleCloseFilter = () => {
    setShowFilter(false);
  };

  const handleFilterChange = (name, value) => {
    setTempFilter(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handleApplyFilter = () => {
    setFilter(tempFilter);
    setShowFilter(false);
    dispatch(getSOWs(tempFilter));
  };

  const handleResetFilter = () => {
    setTempFilter(initialFilter);
    setFilter(initialFilter);
    setShowFilter(false);
    setActiveClient(null);
    setActiveEntity(null);
    dispatch(getSOWs(initialFilter));
  };

  const handleAddSOW = (payload) => {
    dispatch(addSOW(payload, res => {
      if (res?.status) {
        toast.success("New statement of work added successfully");
        setShowAddModal(false);
        dispatch(getSOWs(pendingFilter));
        setFilter(pendingFilter);
      } else {
        toast.error("Failed to add new statement of work!");
      }
    }));
  };

  const handleDeleteSOW = (data = null) => {
    const payload = {
      id: data?.id || "",
    };
    setShowDeleteModal(false);
    dispatch(deleteSOW(payload, (res) => {
      if (res?.status) {
        toast.success("Statement of work removed successfully");
        dispatch(getSOWs(filter));
      } else {
        toast.error("Failed to remove statement of work!");
      }
    }));
  };

  const handleDeleteSOWLatest = (data = null) => {
    const payload = {
      id: data?.id || "",
    };
    setShowDeleteModal(false);
    dispatch(deleteSOWRevision(payload, (res) => {
      if (res?.status) {
        toast.success("Statement of work removed successfully");
        setTempFilter(initialFilter);
        setFilter(initialFilter);
        dispatch(getSOWs(initialFilter));
      } else {
        toast.error("Failed to remove statement of work!");
      }
    }));
  };

  const handleSelectEntity = async (entity) => {
    setFetching(true);
    setActiveEntity(entity);
    setFilter(prev => ({
      ...prev,
      entity_id: [entity.id],
      client_id: [],
    }));
    setClientOptions([]);
    setActiveClient(null);
    let res = await getSOWClientsAsPerEntity({ id: entity.id });
    if (res?.status) {
      let data = res?.data || [];
      setClientOptions(data);
    }
    setFetching(false);
  };

  const handleSelectClient = (client) => {
    setActiveClient(client);
    setFilter(prev => ({
      ...prev,
      client_id: [client.id],
    }));
  };

  const handleShowAddClientModal = () => {
    setTempEntity(activeEntity);
    setTempClient(null);
    setShowAddModal(true);
    setCurrentData(null);
  };

  return (
    <Box sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <AppBar appBarTitle="Statement of Works" >
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {
              activeTab === 0
                ?
                <Fragment>
                  <SearchBar
                    placeholder={"Search with Name, Client Entity"}
                    onClick={() => { }}
                    setSearchTerm={(searchTerm) => setSearchText(searchTerm)}
                    value={searchText}
                  />
                  <button
                    style={{
                      border: "none",
                      background: "none",
                      outline: "none",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      marginLeft: '10px',
                    }}
                    onClick={handleShowFilter}
                  >
                    <TuneIcon />
                    <Typography
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        marginLeft: "5px",
                      }}
                    >
                      Filter
                    </Typography>
                  </button>
                </Fragment>
                :
                null
            }
          </Box>
          {
            hasWritePermission
              ?
              <Button id='add-new-sow-btn' onClick={handleShowUploadModal} variant="contained" startIcon={<AddIcon />}>
                Add New
              </Button>
              :
              null
          }
        </Box>
      </AppBar>
      <Box
        id='sow-view-area'
        sx={{
          background: "#FFFFFF",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 2,
          mt: 2,
          width: "100%",
          position: 'relative',
          pb: 2,
          height: 'calc(100svh - 120px)'
        }}
      >
        {fetching || loading ? <OverlayLoader /> : null}
        <Box sx={{ px: 2, py: 1, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <TabNav id='sow' list={tabs} value={activeTab} handleChange={handleChangeTab} />
          {
            activeTab === 1
              ?
              <Button variant='outlined' onClick={handleRefreshPending} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                <Refresh />
              </Button> :
              null
          }
        </Box>
        <TabPanel id="sow" value={activeTab} index={0}>
          <CompletedTab
            hasWritePermission={hasWritePermission}
            list={list}
            options={options}
            clientOptions={clientOptions}
            currentPage={currentPage}
            maxPage={maxPage}
            filter={filter}
            activeEntity={activeEntity}
            activeClient={activeClient}
            handlePageChange={handlePageChange}
            handleShowAddClientModal={handleShowAddClientModal}
            handleShowEditModal={handleShowEditModal}
            handleShowRevisionModal={handleShowRevisionModal}
            handleShowDeleteModal={handleShowDeleteModal}
            handleSelectEntity={handleSelectEntity}
            handleSelectClient={handleSelectClient}
          />
        </TabPanel>
        <TabPanel id="sow" value={activeTab} index={1}>
          <PendingList
            hasWritePermission={hasWritePermission}
            list={list}
            currentPage={currentPage}
            maxPage={maxPage}
            handlePageChange={handlePageChange}
            getSOWStatus={getSOWStatus}
            handleShowRejectModal={handleShowRejectModal}
            handleShowAddModal={handleShowAddModal}
          />
        </TabPanel>
        <TabPanel id="sow" value={activeTab} index={2}>
          <FailedList
            hasWritePermission={hasWritePermission}
            list={list}
            currentPage={currentPage}
            maxPage={maxPage}
            handlePageChange={handlePageChange}
            getSOWStatus={getSOWStatus}
          />
        </TabPanel>
        {
          showUploadModal
            ?
            <SOWUploadModal open={showUploadModal} handleClose={handleHideUploadModal} handleUpload={handleUpload} currentData={currentData} />
            :
            null
        }
        {
          showRejectModal
            ?
            <SOWRejectModal open={showRejectModal} handleClose={handleCloseRejectModal} handleReject={handleRejectDocument} currentData={currentData} />
            :
            null
        }
        {
          showFilter
            ?
            <SOWFilter open={showFilter} handleResetFilter={handleResetFilter} handleClose={handleCloseFilter} handleApplyFilter={handleApplyFilter} filter={tempFilter} handleFilterChange={handleFilterChange} />
            :
            null
        }
        {
          showAddModal
            ?
            <SOWCUModal open={showAddModal} handleClose={handleCloseModal} handleAdd={handleAddSOW} currentData={currentData} />
            :
            null
        }
        {
          showRevisionModal
            ?
            <SOWRevisions open={showRevisionModal} handleClose={handleCloseRevisionModal} list={revList} />
            :
            null
        }
        {
          showDeleteModal
            ?
            <SOWDeleteModal open={showDeleteModal} handleClose={handleCloseDeleteModal} handleDelete={handleDeleteSOW} handleDeleteLatest={handleDeleteSOWLatest} currentData={currentData} />
            :
            null
        }
      </Box>
    </Box>
  )
}

const CompletedTab = ({ hasWritePermission = false, list = [], options = null, clientOptions = [], currentPage = 0, maxPage = 0, handlePageChange = () => { }, filter = null, handleShowAddClientModal = () => { }, handleShowEditModal = () => { }, handleShowRevisionModal = () => { }, handleShowDeleteModal = () => { }, activeEntity = null, activeClient = null, handleSelectEntity = () => { }, handleSelectClient = () => { }, }) => {

  let documentColumns = [
    {
      field: "project_title",
      flex: 1,
      headerName: "Project Title",
      minWidth: 150,
    },
    {
      field: "sow_start_date",
      flex: 1,
      headerName: "Start Date",
      minWidth: 150,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY") : "",
    },
    {
      field: "sow_end_date",
      flex: 1,
      headerName: "End Date",
      minWidth: 150,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY") : "",
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Created",
      minWidth: 200,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      minWidth: 150,
      height: 150,
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id={`download-tooltip-${rows?.id}`} title='Download'>
                <IconButton id={`download-btn-${rows?.id}`} disabled={!rows?.url} aria-label='download' size='small' color='success' onClick={() => {
                  if (rows?.url) {
                    window.open(rows.url, "_blank", "noreferrer");
                  }
                }}>
                  <DownloadIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              <Tooltip id={`update-tooltip-${rows?.id}`} title='Revision History'>
                <IconButton id={`update-btn-${rows?.id}`} disabled={!rows?.parent_id} aria-label='revision_history' size='small' color={grey[800]} onClick={() => handleShowRevisionModal(rows)}>
                  <UpdateIcon fontSize='medium' />
                </IconButton>
              </Tooltip>
              {hasWritePermission
                ?
                <Tooltip id={`add-tooltip-${rows?.id}`} title='Add New Revision'>
                  <IconButton aria-label='add' size='small' color='primary' onClick={() => handleShowEditModal(rows)}>
                    <AddIcon id={`add-btn-${rows?.id}`} fontSize='small' />
                  </IconButton>
                </Tooltip>
                :
                null
              }
              {hasWritePermission
                ?
                <Tooltip id={`delete-tooltip-${rows?.id}`} title='Delete'>
                  <IconButton id={`delete-btn-${rows?.id}`} aria-label='delete' size='small' color='error' onClick={() => handleShowDeleteModal(rows)}>
                    <DeleteIcon fontSize='small' />
                  </IconButton>
                </Tooltip>
                :
                null
              }
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={2} sx={{ height: '80vh', overflowY: 'auto', borderRight: '1px solid #c7c7c7' }}>
        <Typography sx={{ mt: 2, mx: 2 }} variant='subtitle2'>Choose an entity</Typography>
        <Grid container spacing={2} p={2}>
          {(options?.entity || []).map((set, index) => {
            return (
              <Grid item key={"chip-" + index}>
                <Chip id={`entity-chip-${index + 1}`} color="primary" label={set?.options || ""} p={1} variant={activeEntity?.id === set?.id ? "filled" : "outlined"} onClick={() => handleSelectEntity(set)} />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid item xs={2} display={activeEntity?.id && clientOptions?.length ? 'block' : 'none'} sx={{ height: '80vh', overflowY: 'auto', borderRight: '1px solid #c7c7c7' }}>
        <Typography sx={{ mt: 2 }} variant='subtitle2'>Choose a client</Typography>
        <Grid container spacing={2} py={2}>
          {clientOptions.map((set, index) => {
            return (
              <Grid item key={"chip-" + index}>
                <Chip id={`client-chip-${index + 1}`} color="primary" label={set?.name || ""} p={1} variant={activeClient?.id === set?.id ? "filled" : "outlined"} onClick={() => handleSelectClient(set)} />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
      <Grid item xs={8} display={filter?.client_id?.length && filter?.entity_id?.length ? 'block' : 'none'}>
        <ClientDataTable
          data={list}
          columns={documentColumns}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          maxPage={maxPage}
        />
      </Grid>
    </Grid>
  )
}

const PendingList = ({ list = [], handlePageChange = () => { }, currentPage = 0, maxPage = 0, hasWritePermission = false, getSOWStatus = () => { }, handleShowRejectModal = () => { }, handleShowAddModal = () => { }, }) => {

  let pendingColumns = [
    {
      field: "document_name",
      flex: 1,
      headerName: "Document Name",
      minWidth: 150,
    },
    {
      field: "parent_id",
      flex: 1,
      headerName: "Type",
      minWidth: 50,
      valueFormatter: params => params.value ? "Revision" : "New",
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Created",
      minWidth: 200,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      field: "extraction_status",
      flex: 1,
      headerName: "Status",
      minWidth: 200,
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {rows?.extraction_status === 0 ? <CircularProgress color='primary' size={16} sx={{ marginRight: 1 }} /> : null}
            {rows?.extraction_status === 2 ? <VisibilityIcon color='primary' sx={{ marginRight: 1, fontSize: 16 }} /> : null}
            <Typography variant='subtitle2'>{getSOWStatus(rows?.extraction_status)}</Typography>
          </Box>
        )
      }
    },
    {
      minWidth: 150,
      height: 150,
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id={`download-tooltip-${rows?.id}`} title='Download'>
                <IconButton id={`download-btn-${rows?.id}`} disabled={!rows?.url} aria-label='download' size='small' color='success' onClick={() => {
                  if (rows?.url) {
                    window.open(rows.url, "_blank", "noreferrer");
                  }
                }}>
                  <DownloadIcon fontSize='small' />
                </IconButton>
              </Tooltip>
              {hasWritePermission && rows?.extraction_status === 2
                ?
                <Tooltip id={`review-tooltip-${rows?.id}`} title='Review'>
                  <IconButton id={`review-btn-${rows?.id}`} aria-label='review' size='small' color='primary' onClick={() => handleShowAddModal(rows)}>
                    <Check fontSize='small' />
                  </IconButton>
                </Tooltip>
                :
                null
              }
              {hasWritePermission
                ?
                <Tooltip id={`delete-tooltip-${rows?.id}`} title='Reject'>
                  <IconButton id={`delete-btn-${rows?.id}`} aria-label='reject' size='small' color='error' onClick={() => handleShowRejectModal(rows)}>
                    <Close fontSize='small' />
                  </IconButton>
                </Tooltip>
                :
                null
              }
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <ClientDataTable
        data={list}
        columns={pendingColumns}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        maxPage={maxPage}
      />
    </Box>
  )
}


const FailedList = ({ list = [], handlePageChange = () => { }, currentPage = 0, maxPage = 0, getSOWStatus = () => { } }) => {

  let failedColumns = [
    {
      field: "document_name",
      flex: 1,
      headerName: "Document Name",
      minWidth: 150,
    },
    {
      field: "parent_id",
      flex: 1,
      headerName: "Type",
      minWidth: 50,
      valueFormatter: params => params.value ? "Revision" : "New",
    },
    {
      field: "created_at",
      flex: 1,
      headerName: "Created",
      minWidth: 200,
      valueFormatter: params => params.value ? moment.utc(params?.value).local().format("MMM, DD YYYY hh:mm A") : "",
    },
    {
      field: "extraction_status",
      flex: 1,
      headerName: "Status",
      minWidth: 200,
      valueFormatter: params => getSOWStatus(params?.value),
    },
    {
      minWidth: 150,
      height: 150,
      flex: 1,
      headerName: "Action",
      renderCell: (params) => {
        const rows = params.row;
        return (
          <Grid container spacing={1}>
            <Grid item>
              <Tooltip id={`download-tooltip-${rows?.id}`} title='Download'>
                <IconButton id={`download-btn-${rows?.id}`} disabled={!rows?.url} aria-label='download' size='small' color='success' onClick={() => {
                  if (rows?.url) {
                    window.open(rows.url, "_blank", "noreferrer");
                  }
                }}>
                  <DownloadIcon fontSize='small' />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      <ClientDataTable
        data={list}
        columns={failedColumns}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        maxPage={maxPage}
      />
    </Box>
  )
}

export const ClientDataTable = ({ height = "", data = [], columns = [], maxPage, currentPage, handlePageChange }) => {
  return (
    <Box sx={{ width: '100%', height: 'calc(100svh - 210px)' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pagination
        paginationMode='server'
        pageSize={sowListLimit}
        rowCount={maxPage}
        page={currentPage}
        rowsPerPageOptions={[sowListLimit]}
        onPageChange={handlePageChange}
        density="comfortable"
        disableSelectionOnClick
        disableRowSelectionOnClick
        sx={{
          px: 2,
          border: 0,
          height: height || "80vh",
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "#1089FF",
          },
          "& .MuiDataGrid-cell": {
            margin: 0,
            border: 0,
            width: 300,
          },
          "& .MuiDataGrid-row": {
            margin: 0,
          },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}

const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100%', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4 }}>
      <CircularProgress color="primary" />
    </Box>
  )
}


const TabNav = ({ id = "", list = [], value, handleChange }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label={id + '-tabs'}
      indicatorColor="primary"
    >
      {list.map((l, index) => {
        return (
          <Tab
            label={l?.label || ""}
            {...a11yProps(id, index)}
          />
        )
      })}
    </Tabs>
  )
};

const TabPanel = ({ children, id = "", value, index = 0, ...other }) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`${id}-tabpanel-${index}`}
      aria-labelledby={`sow-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ position: 'relative' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (id = "", index = 0) => {
  return {
    id: `${id}-tab-${index}`,
    "aria-controls": `${id}-tabpanel-${index}`,
  };
}

export default SOWScreen;