import { LOGIN_USER_FAILED, LOGIN_USER_IN_PROGRESS, LOGIN_USER_SUCCESS, LOGOUT_USER_FAILED, LOGOUT_USER_IN_PROGRESS, LOGOUT_USER_SUCCESS, REFRESH_LOGIN_USER_FAILED, REFRESH_LOGIN_USER_IN_PROGRESS, REFRESH_LOGIN_USER_SUCCESS, REFRESH_LOGIN_USER_TOKEN_FAILED, REFRESH_LOGIN_USER_TOKEN_IN_PROGRESS, REFRESH_LOGIN_USER_TOKEN_SUCCESS } from "../../constants/store";

const getUserInfo = () => {
  let userData = localStorage.getItem("userDetails") ? JSON.parse(localStorage.getItem("userDetails")) : null;
  if (userData) {
    const id = userData?.id || null;
    const name = userData?.name || '';
    const email = userData?.email || '';
    const role = userData?.role || '';
    const token = userData?.token || null;
    const refresh_token = userData?.refresh_token || null;
    const permisson_matrix = userData?.permisson_matrix || [];
    const regions = userData?.regions || [];
    return { id, name, email, role, token, refresh_token, permisson_matrix, regions };
  }
  return null;
}

const currentUser = getUserInfo();

const initialState = {
  currentUser: currentUser,
  loading: false,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    // LOGIN USER
    case LOGIN_USER_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        loading: false,
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
      };
    // REFRESH LOGIN USER TOKEN
    case REFRESH_LOGIN_USER_TOKEN_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case REFRESH_LOGIN_USER_TOKEN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
        loading: false,
      };
    case REFRESH_LOGIN_USER_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      };
    // REFRESH LOGIN USER
    case REFRESH_LOGIN_USER_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case REFRESH_LOGIN_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
        loading: false,
      };
    case REFRESH_LOGIN_USER_FAILED:
      return {
        ...state,
        loading: false,
      };
    // LOGOUT USER
    case LOGOUT_USER_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        currentUser: null,
        loading: false,
      };
    case LOGOUT_USER_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export default authReducer;
