import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { formatToCamelCase } from '../../../../utils/consultantConstants';

const TabCUModal = ({ open = false, handleClose, handleAdd, handleUpdate, currentData = null }) => {
	const [data, setData] = useState(null);

	useEffect(() => {
		setData({ name: currentData?.name || "" });
	}, [currentData]);

	const handleChange = (name, value) => {
		setData(prev => {
			return {
				...prev,
				[name]: value
			}
		});
	}

	const handleModalClose = () => {
		handleClose();
	}

	const handleAddTab = () => {
		handleAdd({ name: data.name, fields: [], variable: formatToCamelCase(data.name) });
	}

	const handleUpdateTab = () => {
		handleUpdate({ name: data.name, id: currentData.id });
	}

	return (
		<Dialog
			open={open}
			onClose={handleModalClose}
			aria-labelledby="add-tab-modal"
			aria-describedby="add-tab-modal-description"
			fullWidth
			maxWidth={"xs"}
		>
			<DialogTitle>{currentData?.name ? `Update tab information` : `Add a new tab information`}</DialogTitle>
			<DialogContent>
				<Box mt={1}>
					<Typography variant='subtitle2'>Enter tab name <Typography display="inline" sx={{ color: 'red' }}>*</Typography></Typography>
					<TextField
						value={data?.name || ""}
						onChange={(e) => handleChange('name', e.target.value)}
						placeholder={"Please add a name!"}
						sx={{ width: "100%", background: "#FFFFFF", mt: 1 }}
						inputProps={{ paddingBottom: "100px", maxLength: 50 }}
						id='tab-name-input'
						name='tab-name-input'
					/>
				</Box>
			</DialogContent>
			<DialogActions>
				<Button id='form-cancel-btn' onClick={handleModalClose}>Cancel</Button>
				{currentData?.name
					?
					<Button id='form-add-btn' onClick={handleUpdateTab} variant='contained'>Update</Button>
					:
					<Button id='form-add-btn' onClick={handleAddTab} variant='contained'>Add</Button>
				}
			</DialogActions>
		</Dialog>
	)
}

export default TabCUModal;