import {
  GET_USERS_LIST_IN_PROGRESS,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAILED,
  CREATE_USER_IN_PROGRESS,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  UPDATE_USER_IN_PROGRESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILED,
  SEARCH_USER_IN_PROGRESS,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILED,
} from "../../../constants/store";
// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  success: false,
  list: {},
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    // GET USERS
    case GET_USERS_LIST_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };

      return newState;
    }
    case GET_USERS_LIST_SUCCESS: {
      const newState = {
        ...state,
        list: action.payload,
        loading: false,
        success: false,
      };

      return newState;
    }
    case GET_USERS_LIST_FAILED: {
      const newState = {
        ...state,
        loading: false,
        error: true,
      };

      return newState;
    }

    // ADD USER
    case CREATE_USER_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: action?.payload?.loading,
        error: action?.payload?.error,
        success: false,
      };
      return newState;
    }

    case CREATE_USER_SUCCESS: {
      const newState = {
        ...state,
        loading: action?.payload?.loading,
        error: action?.payload?.error,
        success: action?.payload?.success,
      };
      return newState;
    }

    case CREATE_USER_FAILED: {
      const newState = {
        ...state,
        loading: action?.payload?.loading,
        error: action?.payload?.error,
        success: false,
      };
      return newState;
    }

    // UPDATE USER
    case UPDATE_USER_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: action?.payload?.loading,
        error: action?.payload?.error,
        success: false,
      };
      return newState;
    }

    case UPDATE_USER_SUCCESS: {
      const newState = {
        ...state,
        loading: action?.payload?.loading,
        error: action?.payload?.error,
        success: action?.payload?.success,
      };
      return newState;
    }

    case UPDATE_USER_FAILED: {
      const newState = {
        ...state,
        loading: action?.payload?.loading,
        error: action?.payload?.error,
        success: false,
      };
      return newState;
    }

    // SEARCH USER
    case SEARCH_USER_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };
      return newState;
    }

    case SEARCH_USER_SUCCESS: {
      const newState = {
        ...state,
        list: action.payload.users,
      };
      return newState;
    }

    case SEARCH_USER_FAILED: {
      const newState = {
        ...state,
        error: true,
      };
      return newState;
    }

    default:
      return state;
  }
}

export default userReducer;
