import { Box, Button, ButtonGroup, Checkbox, FormControlLabel, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import DividerComponent from '../../../../../components/Divider/Divider';
import { Fragment } from 'react';
import PhoneNumberInput from '../../../../../components/PhoneNumberInput/PhoneNumberInput';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const InputTypeText = ({ activeSelection = null, handleChange = () => { }, data = null }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2' sx={{ mb: 1 }}>Configuration</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Label <span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            value={data?.label || ""}
            onChange={(e) => handleChange('label', e.target.value)}
            placeholder={"Please enter a label!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'input-label'}
            name={'input-label'}
          />
          <FormControlLabel control={<Checkbox id={'input-checkbox'} name={'input-checkbox'} checked={data?.confidential || false} onChange={() => handleChange('confidential', !data?.confidential)} />} label='Add checkbox to this label' />
        </Grid>
        {
          data?.confidential
            ?
            <Fragment>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Checkbox Variable <span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                  value={data?.confidentialKey || ""}
                  onChange={(e) => handleChange('confidentialKey', e.target.value)}
                  placeholder={"Please enter a confidential variable!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  type={'text'}
                  id={'input-checkbox-variable'}
                  name={'input-checkbox-variable'}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2' sx={{ mb: 1 }}>Checkbox Label <span style={{ color: 'red' }}>*</span></Typography>
                <TextField
                  value={data?.confidentialLabel || ""}
                  onChange={(e) => handleChange('confidentialLabel', e.target.value)}
                  placeholder={"Please enter a confidential label!"}
                  sx={{ width: "100%", background: "#FFFFFF" }}
                  type={'text'}
                  id={'input-checkbox-label'}
                  name={'input-checkbox-label'}
                />
              </Grid>
            </Fragment>
            :
            null
        }
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>Variable Name <span style={{ color: 'red' }}>*</span></Typography>
          <TextField
            value={data?.variable || ""}
            onChange={(e) => handleChange('variable', e.target.value)}
            placeholder={"Please enter a variable name!"}
            sx={{ width: "100%", background: "#FFFFFF" }}
            type={'text'}
            id={'input-variable'}
            name={'input-variable'}
          />
        </Grid>
        {activeSelection?.variant === 'text' || activeSelection?.variant === 'number' || activeSelection?.variant === 'email'
          ?
          <Fragment>
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>Max input length</Typography>
              <TextField
                value={data?.textLength || ""}
                onChange={(e) => handleChange('textLength', e.target.value)}
                placeholder={"Please enter input text length!"}
                sx={{ width: "100%", background: "#FFFFFF" }}
                type={'number'}
                id={'input-max'}
                name={'input-max'}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>Prefix</Typography>
              <TextField
                value={data?.prefix || ""}
                onChange={(e) => handleChange('prefix', e.target.value)}
                placeholder={"Please enter a prefix value!"}
                sx={{ width: "100%", background: "#FFFFFF" }}
                type={'text'}
                id={'input-prefix'}
                name={'input-prefix'}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>Suffix</Typography>
              <TextField
                value={data?.postfilled || ""}
                onChange={(e) => handleChange('postfilled', e.target.value)}
                placeholder={"Please enter a suffix value!"}
                sx={{ width: "100%", background: "#FFFFFF" }}
                type={'text'}
                id={'input-suffix'}
                name={'input-suffix'}
              />
            </Grid>
          </Fragment>
          :
          null
        }
        {activeSelection?.variant === 'number'
          ?
          <Grid item xs={4}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Allow decimal number</Typography>
            <ButtonGroup sx={{ mt: 1 }}>
              <Button id={'input-decimal-number'} name={'input-decimal-number'} onClick={() => handleChange('decimal', true)} variant={data?.decimal ? 'contained' : 'outlined'}>Yes</Button>
              <Button id={'input-no-decimal-number'} name={'input-no-decimal-number'} onClick={() => handleChange('decimal', false)} variant={!data?.decimal ? 'contained' : 'outlined'}>No</Button>
            </ButtonGroup>
          </Grid>
          :
          null
        }
        <Grid item xs={4}>
          <Typography variant='subtitle2' sx={{ mb: 1 }}>System required field</Typography>
          <ButtonGroup sx={{ mt: 1 }}>
            <Button id={'required-btn'} name={'required-btn'} onClick={() => handleChange('systemRequired', true)} variant={data?.systemRequired ? 'contained' : 'outlined'}>Yes</Button>
            <Button id={'not-required-btn'} name={'not-required-btn'} onClick={() => handleChange('systemRequired', false)} variant={!data?.systemRequired ? 'contained' : 'outlined'}>No</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <DividerComponent outerStyle={{ margin: 0, padding: 0 }} />
      <Typography variant='subtitle2'>Preview</Typography>
      <Grid container spacing={2}>
        {
          data?.label
            ?
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ my: 1 }}>{data?.label || ""} {data?.systemRequired ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
              {
                activeSelection?.variant === 'text' || activeSelection?.variant === 'number' || activeSelection?.variant === 'email'
                  ?
                  <TextField
                    id={data.variable}
                    name={data.variable}
                    value={data?.value || ""}
                    onChange={(e) => {
                      if (activeSelection?.variant === 'number') {
                        handleChange('value', e.target.value.replace(/[^0-9.]/g, ''));
                      } else {
                        handleChange('value', e.target.value);
                      }
                    }}
                    placeholder={"Please enter " + (data?.label || "").toLowerCase() + "!"}
                    sx={{ width: "100%", background: "#FFFFFF" }}
                    inputProps={{ step: 0.01, maxLength: data?.textLength || 524288 }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">{data?.prefix || ""}</InputAdornment>,
                      endAdornment: <InputAdornment position="end">{data?.postfilled || ""}</InputAdornment>,
                    }}
                    type={data?.decimal === 'decimal-number' ? 'number' : 'text'}
                  />
                  :
                  null
              }
              {
                activeSelection?.variant === 'phone'
                  ?
                  <PhoneNumberInput
                    id={data.variable}
                    name={data.variable}
                    value={data?.value || ""}
                    handleChange={(value) => handleChange('value', value)}
                  />
                  :
                  null
              }
              {
                activeSelection?.variant === 'date'
                  ?
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      renderInput={(inputProps) => {
                        return (
                          <TextField
                            InputLabelProps={{ shrink: true }}
                            type="date"
                            value={data?.value || ""}
                            variant="outlined"
                            sx={{ width: "100%", background: "#FFFFFF" }}
                            onChange={(e) => handleChange('value', e.target.value)}
                            id={data.variable}
                            name={data.variable}
                          />
                        )
                      }}
                    />
                  </LocalizationProvider>
                  :
                  null
              }
              {data?.confidential && data?.confidentialLabel
                ?
                <FormControlLabel control={<Checkbox id={data.confidentialKey} name={data.confidentialKey} checked={data?.confidentialValue || false} onChange={() => handleChange('confidentialValue', !data?.confidentialValue)} />} label={data?.confidentialLabel || ""} />
                :
                null
              }
            </Grid>
            :
            null
        }
      </Grid>
    </Box>
  )
}

export default InputTypeText;