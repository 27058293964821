import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, Select, Tab, Tabs, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';
import PhoneNumberInput from '../../../components/PhoneNumberInput/PhoneNumberInput';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { cityOptions, convertSearchedListToOptions, convertToRequiredTabFormat, countryOptions, extractValuableOptions, getOptionFromList, handleTabValidations, stateOptions } from '../../../utils/consultantConstants';
import { ArrowBack } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { toast } from 'react-toastify';
import InformationSummary from '../../../components/informationSummary';
import api from '../../../config/https';
import { TabPanel } from '../Settings/SettingsScreen';
import { addContractReferralInformation, getContractReferralInformation, getUserConfiguration, requestOption, updateContractReferralInformation } from '../../../store/ConsultantForm/action';
import DividerComponent from '../../../components/Divider/Divider';
import LocalSearchableDropdown from '../../../components/LocalSearchableDropdown';
import RequestOptionModal from '../../../components/RequestOptionModal';

const RefferalForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const action = location?.state?.action || "add";
  const status = location?.state?.status || "";
  const editId = location?.state?.id || null;
  const contractId = location?.state?.contractId || null;
  const { currentUser = null } = useSelector(state => state.auth);
  const { configurations = [], loading = false, info = null, optionList = null, allOptions = null } = useSelector((state) => state.consultantForm);
  const configuration = _.find(configurations, { varient: status });

  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState({ contractStatus: status });
  const [options, setOptions] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [loadingOptions, setLoadingOptions] = useState([]);
  const [requestingOptions, setRequestingOptions] = useState([]);
  const [currentData, setCurrentData] = useState(null);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [disabledList, setDisabledList] = useState([]);

  useEffect(() => {
    dispatch(getUserConfiguration({ id: currentUser?.id }, res => {
      if (res?.status) {
        let list = res?.data?.userGroup?.configuration || [];
        let foundItem = _.find(list, { varient: status });
        let tabs = convertToRequiredTabFormat(foundItem?.tabs || []);
        if (action === 'edit') {
          dispatch(getContractReferralInformation({ id: editId, config: tabs }));
        }
      }
    }));
  }, [action, editId, status, currentUser?.id]);

  useEffect(() => {
    if (configuration?.tabs) {
      let list = convertToRequiredTabFormat(configuration?.tabs || []);
      setTabs(list);
    }
  }, [configuration]);

  useEffect(() => {
    if (info !== null) {
      setData(info);
    }
  }, [info]);

  useEffect(() => {
    if (allOptions !== null) {
      setSelectedOptions(allOptions);
    }
  }, [allOptions]);

  useEffect(() => {
    if (optionList !== null) {
      setOptions(optionList);
    }
  }, [optionList]);

  useEffect(() => {
    handleApplyRules();
    handleCheckDisabledTabs();
  }, [data]);

  const handleApplyRules = () => {
    let tabList = JSON.parse(JSON.stringify(convertToRequiredTabFormat(configuration?.tabs || [])));
    let conditions = [];
    tabList.forEach(tabItem => {
      (tabItem?.fields || []).forEach(field => {
        if (field?.rules?.length) {
          conditions.push(...field.rules);
        }
      });
    });
    conditions.forEach(conditionItem => {
      let conditionSatisfiedCount = 0;
      conditionItem?.conditions?.forEach(condition => {
        let conditionSatisfied = false;
        let key = condition?.key || "";
        let value = data?.[key] || "";
        if (condition?.type === 'equalTo') {
          // eslint-disable-next-line eqeqeq
          conditionSatisfied = condition?.value == value;
        } else if (condition?.type === 'notEqualTo') {
          // eslint-disable-next-line eqeqeq
          conditionSatisfied = condition?.value != value;
        } else if (condition?.type === 'empty') {
          conditionSatisfied = !value;
        } else if (condition?.type === 'notEmpty') {
          conditionSatisfied = value ? true : false;
        }
        if (conditionSatisfied) {
          conditionSatisfiedCount++;
        }
      });
      if (conditionSatisfiedCount === conditionItem?.conditions?.length) {
        tabList.forEach(tabItem => {
          (tabItem?.fields || []).forEach(item => {
            let variable = item?.variable || "";
            if (item?.type === 'location') {
              variable = item?.address?.variable || item?.country?.variable || item?.state?.variable || item?.city?.variable || item?.zipCode?.variable;
            }
            if ((conditionItem?.variable === variable || conditionItem?.variable === item.confidential) && !item.systemRequired) {
              if (item?.type === 'location') {
                let locationConfig = {};
                locationConfig.address = {
                  hidden: !conditionItem.visibility1,
                  required: conditionItem.requiredField1,
                };
                locationConfig.country = {
                  hidden: !conditionItem.visibility2,
                  required: conditionItem.requiredField2,
                };
                locationConfig.state = {
                  hidden: !conditionItem.visibility3,
                  required: conditionItem.requiredField3,
                };
                locationConfig.city = {
                  hidden: !conditionItem.visibility4,
                  required: conditionItem.requiredField4,
                };
                locationConfig.zipCode = {
                  hidden: !conditionItem.visibility5,
                  required: conditionItem.requiredField5,
                };
                item.locationConfig = locationConfig;
              } else {
                item.hidden = !conditionItem.visibility;
                item.required = conditionItem.requiredField;
              }
            }
          })
        })
      }
    });
    setTabs(tabList);
  };

  const handleCheckDisabledTabs = () => {
    let tabList = JSON.parse(JSON.stringify(tabs));
    let dList = [...tabList].map(item => item.name);
    dList.push('Preview');
    tabList.forEach(tab => {

    });
    for (let i = 0; i < tabList.length; i++) {
      let fields = [];
      tabList[i]?.fields.forEach(field => {
        if (field?.type !== 'divider') {
          fields.push(field);
        }
      });
      const valid = handleTabValidations(data, fields, options, false);
      dList = dList.filter(item => item !== tabList[i]?.name);
      if (valid !== true) {
        break;
      }
    }
    if (dList.length === 1) {
      setDisabledList([]);
    } else {
      setDisabledList(dList);
    }
  }

  const handleGoBack = () => {
    history.goBack();
  }

  const handleChangeTab = (value) => {
    setActiveTab(value);
    let elm = document.getElementById('form-container-view');
    if (elm) {
      elm.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  const handleChangeValue = (name = "", value = "") => {
    setData(prev => ({ ...prev, [name]: value }));
  }

  const handleChangeLocationData = (name = "", value = "", belong = "", item) => {
    if (belong === 'country') {
      let op = stateOptions(value);
      setOptions(prev => ({ ...prev, [item.state.variable]: op, [item.city.variable]: [] }));
      setData(prev => ({ ...prev, [name]: value, [item.state.variable]: "", [item.city.variable]: "" }));
    } else if (belong === 'state') {
      let op = cityOptions(data[item.country.variable], value);
      setOptions(prev => ({ ...prev, [item.city.variable]: op }));
      setData(prev => ({ ...prev, [name]: value, [item.city.variable]: "" }));
    } else {
      setData(prev => ({ ...prev, [name]: value }));
    }
  }

  const handleSearch = async (name = "", value = "", key) => {
    setLoadingOptions(prev => [...prev, name]);
    let url = `/masterAdmin/constant?fieldType=${name}&search=${value}`;
    if (name === 'vendor') {
      url = `/masterAdmin/vendor?vendorName=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'vendor_name', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoadingOptions(prev => prev.filter(item => item !== name));
    } else if (name === 'businessUnit' || name === 'clients' || name === 'endclients' || name === 'domains' || name === 'hr') {
      url = `/masterAdmin/${name}?search=${value}`;
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        if (name === 'businessUnit') {
          let d = convertSearchedListToOptions(list, 'options', 'bu_owner', 'id');
          setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
        } else {
          let d = convertSearchedListToOptions(list, 'options', 'name', 'id');
          setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
        }
      }
      setLoadingOptions(prev => prev.filter(item => item !== name));
    } else if (name === 'sdf' || name === 'insurance') {
      if (data?.contractorName) {
        url = `/masterAdmin/${name}?vendorID=${data.contractorName}&search=${value}`;
        let res = await api.get(url);
        if (res?.data?.status && res?.data?.data?.data?.length) {
          let list = res?.data?.data?.data;
          let d = convertSearchedListToOptions(list, 'options', name, 'id');
          setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
        }
      } else {
        toast.error('Please enter vendor name');
      }
      setLoadingOptions(prev => prev.filter(item => item !== name));
    } else {
      let res = await api.get(url);
      if (res?.data?.status && res?.data?.data?.data?.length) {
        let list = res?.data?.data?.data;
        let d = convertSearchedListToOptions(list, 'options', 'options', 'id');
        setOptions(prev => ({ ...prev, [key]: d?.options || [] }));
      }
      setLoadingOptions(prev => prev.filter(item => item !== name));
    }
  }

  const handleShowRequestModal = (info = null) => {
    setCurrentData(info);
    setShowRequestModal(true);
  }

  const handleCloseRequestModal = () => {
    const ele = document.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
    setCurrentData(null);
    setShowRequestModal(false);
  }

  const handleRequestOption = async (name, payload) => {
    setCurrentData(null);
    setShowRequestModal(false);
    setRequestingOptions(prev => ([
      ...prev,
      name
    ]));
    let res = await requestOption(payload);
    if (res.status) {
      if ((res?.message || "").includes(`Value Already Exists`)) {
        toast.error('This option has already been requested!');
      } else {
        toast.success('New option requested successfully!');
      }
    } else {
      toast.error(res?.message || 'Failed to request new option!');
    }
    setRequestingOptions(prev => prev.filter(item => item !== name));
  }

  const handleBack = () => {
    if (activeTab !== 0) {
      setActiveTab(activeTab - 1);
      let elm = document.getElementById('form-container-view');
      if (elm) {
        elm.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  }

  const handleNext = () => {
    let tabList = JSON.parse(JSON.stringify(tabs));
    let fields = [];
    tabList.forEach(tab => {
      tab?.fields.forEach(field => {
        if (field?.type !== 'divider') {
          fields.push(field);
        }
      });
    });
    const valid = handleTabValidations(data, fields, options, false);
    if (valid !== true && activeTab + 1 !== valid.tab) {
      handleTabValidations(data, fields, options, true);
      setActiveTab(valid.tab);
      setTimeout(() => {
        let elm = document.getElementById(valid.id);
        if (elm) {
          elm.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500);
    } else {
      if ((activeTab < tabs.length)) {
        setActiveTab(activeTab + 1);
        let elm = document.getElementById('form-container-view');
        if (elm) {
          elm.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    }
  }

  const handleSubmit = () => {
    let tabList = JSON.parse(JSON.stringify(tabs));
    let fields = [];
    tabList.forEach(tab => {
      tab?.fields.forEach(field => {
        if (field?.type !== 'divider') {
          fields.push(field);
        }
      });
    });
    const valid = handleTabValidations(data, fields, options, true);
    if (valid !== true) {
      setActiveTab(valid.tab);
      setTimeout(() => {
        let elm = document.getElementById(valid.id);
        if (elm) {
          elm.scrollIntoView({ behavior: 'smooth' })
        }
      }, 500);
    } else {
      let payload = {};
      let item = {};
      tabs?.forEach(tab => {
        tab?.fields?.forEach(field => {
          if (field?.type !== 'divider' && field?.variable) {
            if (field?.type === 'location') {
              let addressKey = field?.address?.variable;
              if (addressKey) {
                let addressValue = !field?.locationConfig?.address?.hidden ? data?.[addressKey] : null;
                item[addressKey] = addressValue;
              }
              let countryKey = field?.country?.variable;
              if (countryKey) {
                let countryValue = !field?.locationConfig?.country?.hidden ? data?.[countryKey] : null;
                item[countryKey] = countryValue;
              }
              let stateKey = field?.state?.variable;
              if (stateKey) {
                let stateValue = !field?.locationConfig?.state?.hidden ? data?.[stateKey] : null;
                item[stateKey] = stateValue;
              }
              let cityKey = field?.city?.variable;
              if (cityKey) {
                let cityValue = !field?.locationConfig?.city?.hidden ? data?.[cityKey] : null;
                item[cityKey] = cityValue;
              }
              let zipCodeKey = field?.zipCode?.variable;
              if (zipCodeKey) {
                let zipCodeValue = !field?.locationConfig?.zipCode?.hidden ? data?.[zipCodeKey] : null;
                item[zipCodeKey] = zipCodeValue;
              }
            } else if (field?.type === 'plan') {
              let key = field?.variable;
              if (key) {
                let d = {};
                selectedOptions?.[field.searchVariable]?.forEach(op => {
                  let planValue = data?.[field.variable + "" + op?.label] || {};
                  d = {
                    ...d,
                    [op.value]: planValue,
                  };
                });
                item[key] = !field?.hidden ? d : {};
              }
            } else {
              let key = field?.variable;
              let value = data?.[key] || null;
              if (key) {
                if (field?.multiple) {
                  item[key] = !field?.hidden && value !== null ? value : [];
                } else {
                  item[key] = !field?.hidden ? value : null;
                }
              }
              let cKey = field?.confidential;
              let cValue = data?.[cKey] || null;
              if (field?.confidential && field?.confidentialLabel) {
                item[cKey] = !field?.hidden ? cValue : null;
              }
              if (field?.type === 'dropdown') {
                let yesNoList = false;
                let optionList = field?.options || [];
                optionList = optionList.map(o => (o?.label || "").toLowerCase());
                if (optionList.includes('yes') && optionList.includes('no')) {
                  yesNoList = true;
                }
                if (yesNoList && value === 'Yes') {
                  item[key] = 1;
                } else if (yesNoList && value === 'No') {
                  item[key] = 0;
                }
              }
            }
          }
        });
        payload = { ...item };
        if (action === 'add') {
          dispatch(addContractReferralInformation({ data: { ...payload, contractId: editId } }, res => {
            if (res?.status) {
              toast.success('Submitted successfully');
              history.goBack();
            } else {
              toast.error('Failed to save information!');
            }
          }));
        } else if (action === 'edit') {
          dispatch(updateContractReferralInformation({ id: editId, data: { ...payload, contractId: contractId } }, res => {
            if (res?.status) {
              toast.success('Updated successfully');
              history.goBack();
            } else {
              toast.error('Failed to save information!');
            }
          }));
        }
      });
    }
  }

  return (
    <Box id="consultant-form-container" sx={{ width: '100%', height: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 1, zIndex: 999 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
          <IconButton id='exit-btn' onClick={handleGoBack} sx={{ width: 35, height: 35, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
            <ArrowBack />
          </IconButton>
          <Typography variant='subtitle2' sx={{ color: blue[500], fontSize: 20, textTransform: 'capitalize' }}>{`${action} Referral Information`}</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
          {activeTab !== 0
            ?
            <Button id='back-btn' onClick={handleBack} variant='outlined'>
              Back
            </Button>
            :
            null
          }
          {activeTab < tabs.length && tabs.length > 1
            ?
            <Button id='next-btn' onClick={handleNext} variant='outlined'>
              Next
            </Button>
            :
            null
          }
          {(tabs?.length === 1 || activeTab === tabs.length) && !_.isEqual(info, data) && !disabledList?.length
            ?
            <Button id='submit-btn' color='success' onClick={handleSubmit} variant='contained' sx={{ border: 'none', boxShadow: 'none' }}>Submit</Button>
            :
            null
          }
        </Box>
      </Box>
      {!tabs?.length && !loading
        ?
        <Box sx={{ width: '100%', height: '220px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', bgcolor: '#fff', borderRadius: 2, p: 2, mt: 2 }}>
          <Box sx={{ width: 35, height: 35, color: '#475569' }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
          </Box>
          <Typography sx={{ fontSize: '14px', my: 2, color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>No configuration found</Typography>
        </Box>
        :
        <Box sx={{ bgcolor: "#FFF", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 2, mt: 2, position: 'relative' }}>
          {tabs?.length > 1
            ?
            <Box sx={{ px: 2, pt: 1 }}>
              <TabNav disabledList={disabledList} value={activeTab} list={tabs?.length === 1 ? [...tabs.map(item => item?.name || "")] : [...tabs.map(item => item?.name || ""), 'Preview']} handleChange={(e, v) => handleChangeTab(v)} />
            </Box>
            :
            null
          }
          <Box id={'form-container-view'} sx={{ width: "100%", height: tabs?.length > 1 ? 'calc(100svh - 160px)' : 'calc(100svh - 100px)', overflowY: 'auto', px: 2, pt: 0.5, pb: 2, }}>
            {tabs?.map((tab, tabIndex) => {
              return (
                <Box key={`tab-panel-${tabIndex}`} sx={{ marginTop: 2 }}>
                  <TabPanel id="consultant-form" value={activeTab} index={tabIndex}>
                    <Grid container spacing={2}>
                      {(tab?.fields || [])?.map((field, fieldIndex) => {
                        return (
                          <RenderComponent
                            id={field.id}
                            key={`input-field-${fieldIndex}`}
                            item={field}
                            data={data}
                            options={options}
                            loading={loadingOptions}
                            requestingOptions={requestingOptions}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                            handleChange={handleChangeValue}
                            handleChangeLocationData={handleChangeLocationData}
                            handleSearch={handleSearch}
                            handleShowRequestModal={handleShowRequestModal}
                          />
                        )
                      })}
                    </Grid>
                  </TabPanel>
                </Box>
              )
            })}
            <Box sx={{ marginTop: 2 }}>
              {tabs.length ?
                <TabPanel id="consultant-form" value={activeTab} index={tabs.length}>
                  <InformationSummary
                    tabs={tabs}
                    data={data}
                    oldData={info}
                    handleChangeTab={handleChangeTab}
                    optionList={selectedOptions}
                  />
                </TabPanel>
                :
                null
              }
            </Box>
          </Box>
        </Box>
      }
      {showRequestModal ?
        <RequestOptionModal open={showRequestModal} handleClose={handleCloseRequestModal} handleRequest={handleRequestOption} currentData={currentData} />
        :
        null
      }
      {loading ? <OverlayLoader /> : null}
    </Box>
  );
}

const OverlayLoader = () => {
  return (
    <Box sx={{ position: 'absolute', left: 0, top: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 999, width: '100%', height: '100svh', backgroundColor: 'rgba(255,255,255,0.5)', borderRadius: 4, }}>
      <CircularProgress color="primary" />
    </Box>
  )
}

const RenderComponent = ({ id = "", item = null, data = null, options = null, loading = [], requestingOptions = [], selectedOptions = null, setSelectedOptions = () => { }, handleChange = () => { }, handleChangeLocationData = () => { }, handleSearch = () => { }, handleShowRequestModal = () => { }, }) => {
  if (item?.hidden) {
    return null;
  } else if (item?.type === 'divider') {
    return (
      <Grid item xs={12} id={id}>
        <DividerComponent title={item?.label || ""} />
      </Grid>
    )
  } else if (item?.type === 'text') {
    return (
      <Grid item xs={4} id={id}>
        <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
        <TextField
          placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
          sx={{ width: "100%", background: "#FFFFFF" }}
          inputProps={{ step: 0.01, maxLength: item?.textLength || 524288 }}
          required={item?.required || false}
          InputProps={{
            startAdornment: <InputAdornment position="start">{item?.prefix || ""}</InputAdornment>,
          }}
          type={item?.decimal ? 'number' : 'text'}
          value={data?.[item.variable] || ""}
          onChange={(e) => {
            let value = e?.target?.value || "";
            if (item?.configVariant === 'number') {
              const numberRegex = /^[0-9]+$/;
              if (item?.decimal) {
                const decimalNumberRegex = /^[0-9]+\.?[0-9]*$/;
                if (decimalNumberRegex.test(value) || value === '') {
                  handleChange(item.variable, value, item?.rules);
                }
              } else if (numberRegex.test(value) || value === '') {
                handleChange(item.variable, value, item?.rules);
              }
            } else {
              handleChange(item.variable, value, item?.rules);
            }
          }}
          disabled={item.disabled}
          id={item.variable}
          name={item.variable}
        />
        {item?.confidential
          ?
          <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={'Please check if ' + (item?.confidentialLabel || "")} />
          :
          null
        }
      </Grid>
    )
  } else if (item?.type === 'email') {
    return (
      <Grid item xs={4} id={id}>
        <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
        <TextField
          placeholder={"Please enter " + (item?.label || "").toLowerCase() + "!"}
          sx={{ width: "100%", background: "#FFFFFF" }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{item?.prefix || ""}</InputAdornment>,
          }}
          type='email'
          value={data?.[item.variable] || ""}
          onChange={(e) => handleChange(item.variable, e?.target?.value, item?.rules)}
          disabled={item.disabled}
          id={item.variable}
          name={item.variable}
        />
        {item?.confidential
          ?
          <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={'Please check if ' + (item?.confidentialLabel || "")} />
          :
          null
        }
      </Grid>
    )
  } else if (item?.type === 'phone') {
    return (
      <Grid item xs={4} id={id}>
        <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
        <PhoneNumberInput
          value={data?.[item.variable] || ""}
          handleChange={(value) => handleChange(item.variable, value, item?.rules)}
          disabled={item.disabled}
          id={item.variable}
          name={item.variable}
        />
        {item?.confidential
          ?
          <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={'Please check if ' + (item?.confidentialLabel || "")} />
          :
          null
        }
      </Grid>
    )
  } else if (item?.type === 'date') {
    return (
      <Grid item xs={4} id={id}>
        <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(inputProps) =>
              <TextField
                InputLabelProps={{ shrink: true }}
                type="date"
                variant="outlined"
                sx={{ width: "100%", background: "#FFFFFF" }}
                value={data?.[item.variable] || ""}
                onChange={(e) => handleChange(item.variable, e?.target?.value, item?.rules)}
                disabled={item.disabled}
                id={item.variable}
                name={item.variable}
              />}
          />
        </LocalizationProvider>
        {item?.confidential
          ?
          <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={'Please check if ' + (item?.confidentialLabel || "")} />
          :
          null
        }
      </Grid>
    )
  } else if (item?.type === 'dropdown') {
    return (
      <Grid item xs={4} id={id}>
        <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
        <FormControl fullWidth>
          <Select
            sx={{ width: "100%", background: "#FFFFFF" }}
            displayEmpty
            value={data?.[item.variable] || ""}
            onChange={(e) => handleChange(item?.variable, e?.target?.value, item?.rules)}
            disabled={item.disabled}
            id={item.variable}
          >
            <MenuItem disabled value="">{"Please select a " + (item?.label || "").toLowerCase()}</MenuItem>
            {(extractValuableOptions(item?.options || [])).map((option, index) => {
              return (
                <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
              )
            })}
          </Select>
        </FormControl>
        {item?.confidential
          ?
          <FormControlLabel disabled={item.disabled} control={<Checkbox id={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={'Please check if ' + (item?.confidentialLabel || "")} />
          :
          null
        }
      </Grid>
    )
  } else if (item?.type === 'searchable-dropdown') {
    return (
      <Grid item xs={4} id={id}>
        <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
        <LocalSearchableDropdown
          id={item.variable}
          multiple={item?.multiple}
          label={item?.label}
          value={item?.multiple ? data?.[item.variable] || [] : data?.[item.variable] || null}
          handleChange={(v, newEntry) => {
            if (item?.multiple) {
              if (newEntry) {
                handleShowRequestModal({ ...item, value: newEntry });
              } else {
                let oldValues = data?.[item.variable] || [];
                let intersection = [];
                if (v.length > oldValues.length) {
                  intersection = v.filter(x => !oldValues.includes(x));
                  if (intersection.length) {
                    const option = getOptionFromList(options[item.variable], intersection[0]);
                    if (option?.value) {
                      setSelectedOptions(prev => {
                        let oldOptions = prev?.[item.searchVariable] || [];
                        let op = [...oldOptions, option];
                        let list = [...new Map(op.map(item => [item.value, item])).values()];
                        return ({
                          ...prev,
                          [item.searchVariable]: list,
                        });
                      });
                    }
                  }
                } else {
                  intersection = oldValues.filter(x => !v.includes(x));
                  if (intersection.length) {
                    setSelectedOptions(prev => {
                      let oldOptions = prev?.[item.searchVariable] || [];
                      oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                      return ({
                        ...prev,
                        [item.searchVariable]: oldOptions,
                      });
                    });
                  }
                }
                handleChange(item.variable, v, item?.rules);
              }
            } else {
              if (v?.inputValue) {
                handleShowRequestModal({ ...item, value: v.inputValue });
              } else {
                handleChange(item.variable, v?.value, item?.rules);
                if (v?.value) {
                  setSelectedOptions(prev => {
                    let oldOptions = prev?.[item.searchVariable] || [];
                    let op = [...oldOptions, v];
                    let list = [...new Map(op.map(item => [item.value, item])).values()];
                    return ({
                      ...prev,
                      [item.searchVariable]: list,
                    });
                  });
                } else {
                  setSelectedOptions(prev => ({
                    ...prev,
                    [item.searchVariable]: [],
                  }));
                }
              }
            }
          }}
          addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
          options={options?.[item.variable] || []}
          handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
          add={item?.request || false}
          loading={loading.includes(item?.searchVariable) || requestingOptions.includes(item.variable)}
          matchingOptions={selectedOptions?.[item.searchVariable] || []}
          tooltipVariables={item?.tooltips || []}
          showTooltip={item?.tooltips?.length}
          disabled={item?.disabled || requestingOptions.includes(item.variable)}
        />
        {item?.confidential
          ?
          <FormControlLabel disabled={item?.disabled || requestingOptions.includes(item.variable)} control={<Checkbox id={item.confidential} checked={data?.[item.confidential]} onChange={() => handleChange(item.confidential, !data?.[item.confidential])} />} label={'Please check if ' + (item?.confidentialLabel || "")} />
          :
          null
        }
      </Grid>
    )
  } else if (item?.type === 'location') {
    return (
      <Grid item xs={12} id={id}>
        <Grid container spacing={2}>
          {!item?.locationConfig?.address?.hidden && item?.address?.label && item?.address?.variable ?
            <Grid item xs={12}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.address?.label || ""} {item?.locationConfig?.address?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
              <TextField
                value={data?.[item?.address?.variable] || ""}
                onChange={(e) => handleChangeLocationData(item?.address?.variable, e?.target?.value)}
                placeholder={"Please enter " + (item?.address?.label || "").toLowerCase() + "!"}
                sx={{ width: "100%", background: "#FFFFFF" }}
                disabled={item?.locationConfig?.address?.disabled}
                id={item?.address?.variable}
                name={item?.address?.variable}
              />
            </Grid>
            :
            null
          }
          {!item?.locationConfig?.country?.hidden && item?.country?.label && item?.country?.variable ?
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.country?.label || ""} {item?.locationConfig?.country?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
              <LocalSearchableDropdown
                value={data?.[item?.country?.variable] || null}
                label={item?.country?.label || ""}
                options={countryOptions}
                handleChange={(v) => handleChangeLocationData(item?.country?.variable, v?.value, 'country', item)}
                disabled={item?.locationConfig?.country?.disabled}
                id={item?.country?.variable}
              />
            </Grid>
            :
            null
          }
          {!item?.locationConfig?.state?.hidden && item?.state?.label && item?.state?.variable && options?.[item?.state?.variable]?.length ?
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.state?.label || ""} {item?.locationConfig?.state?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
              <LocalSearchableDropdown
                value={data?.[item?.state?.variable] || null}
                label={item?.state?.label || ""}
                options={options?.[item?.state?.variable] || []}
                handleChange={(v) => handleChangeLocationData(item?.state?.variable, v?.value, 'state', item)}
                disabled={item?.locationConfig?.state?.disabled}
                id={item?.state?.variable}
              />
            </Grid>
            :
            null
          }
          {!item?.locationConfig?.city?.hidden && item?.city?.label && item?.city?.variable && options?.[item?.city?.variable]?.length ?
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.city?.label || ""} {item?.locationConfig?.city?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
              <LocalSearchableDropdown
                value={data?.[item?.city?.variable] || null}
                label={item?.city?.label || ""}
                options={options?.[item?.city?.variable] || []}
                handleChange={(v) => handleChangeLocationData(item?.city?.variable, v?.value, 'city', item)}
                disabled={item?.locationConfig?.city?.disabled}
                id={item?.city?.variable}
              />
            </Grid>
            :
            null
          }
          {!item?.locationConfig?.zipCode?.hidden && item?.zipCode?.label && item?.zipCode?.variable ?
            <Grid item xs={4}>
              <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.zipCode?.label || ""} {item?.locationConfig?.zipCode?.required && <span style={{ color: 'red' }}>*</span>}</Typography>
              <TextField
                value={data?.[item?.zipCode?.variable] || ""}
                onChange={(e) => handleChangeLocationData(item?.zipCode?.variable, e?.target?.value, item)}
                placeholder={"Please enter " + (item?.zipCode?.label || "").toLowerCase() + "!"}
                sx={{ width: "100%", background: "#FFFFFF" }}
                disabled={item?.locationConfig?.zipCode?.disabled}
                id={item?.zipCode?.variable}
                name={item?.zipCode?.variable}
              />
            </Grid>
            :
            null
          }
        </Grid>
      </Grid>
    )
  } else if (item?.type === 'plan') {
    return (
      <Grid item xs={12} id={id}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>{item?.label || ""} {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
            <LocalSearchableDropdown
              id={item.variable}
              multiple={item?.multiple}
              label={item?.label}
              value={item?.multiple ? (data?.[item.variable] || []) : data?.[item.variable] || null}
              handleChange={(v, newEntry) => {
                if (item?.multiple) {
                  if (newEntry) {
                    handleShowRequestModal({ ...item, value: newEntry });
                  } else {
                    let oldValues = data?.[item.variable] || [];
                    let intersection = [];
                    if (v.length > oldValues.length) {
                      intersection = v.filter(x => !oldValues.includes(x));
                      if (intersection.length) {
                        const option = getOptionFromList(options[item.variable], intersection[0]);
                        if (option?.value) {
                          setSelectedOptions(prev => {
                            let oldOptions = prev?.[item.searchVariable] || [];
                            let op = [...oldOptions, option];
                            let list = [...new Map(op.map(item => [item.value, item])).values()];
                            return ({
                              ...prev,
                              [item.searchVariable]: list,
                            });
                          });
                        }
                      }
                    } else {
                      intersection = oldValues.filter(x => !v.includes(x));
                      if (intersection.length) {
                        setSelectedOptions(prev => {
                          let oldOptions = prev?.[item.searchVariable] || [];
                          oldOptions = oldOptions.filter(item => item.value !== intersection[0])
                          return ({
                            ...prev,
                            [item.searchVariable]: oldOptions,
                          });
                        });
                      }
                    }
                    handleChange(item.variable, v, item?.rules);
                  }
                } else {
                  if (v?.inputValue) {
                    handleShowRequestModal({ ...item, value: v.inputValue });
                  } else {
                    if (v?.value) {
                      setSelectedOptions(prev => {
                        let oldOptions = prev?.[item.searchVariable] || [];
                        let op = [...oldOptions, v];
                        let list = [...new Map(op.map(item => [item.value, item])).values()];
                        return ({
                          ...prev,
                          [item.searchVariable]: list,
                        });
                      });
                    } else {
                      setSelectedOptions(prev => ({
                        ...prev,
                        [item.searchVariable]: [],
                      }));
                    }
                    handleChange(item.variable, v?.value, item?.rules);
                  }
                }
              }}
              addLabel='𝘙𝘦𝘲𝘶𝘦𝘴𝘵 𝘵𝘩𝘪𝘴 𝘰𝘱𝘵𝘪𝘰𝘯: '
              options={options?.[item.variable] || []}
              handleInputChange={e => handleSearch(item?.searchVariable, e, item.variable)}
              add={item?.request || false}
              loading={loading.includes(item?.searchVariable) || requestingOptions.includes(item.variable)}
              matchingOptions={selectedOptions?.[item.searchVariable] || []}
              disabled={item?.disabled || requestingOptions.includes(item.variable)}
            />
          </Grid>
          {
            data?.[item.variable]
              ?
              selectedOptions?.[item.searchVariable]?.map((selectedPlan, selectedPlanIndex) => {
                return (
                  <Grid item xs={4} key={selectedPlanIndex}>
                    <Typography variant='subtitle2' sx={{ mb: 1 }}>{selectedPlan?.label || ""} Effective date {item?.systemRequired || item?.required ? <span style={{ color: 'red' }}>*</span> : null}</Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        renderInput={(inputProps) => {
                          return (
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              type="date"
                              value={data?.[item.variable + "" + selectedPlan?.label] || ""}
                              variant="outlined"
                              sx={{ width: "100%", background: "#FFFFFF" }}
                              onChange={(e) => handleChange(item.variable + "" + selectedPlan?.label, e.target.value)}
                              id={item.variable + "" + selectedPlan?.label}
                              name={item.variable + "" + selectedPlan?.label}
                            />
                          )
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                )
              })
              :
              null
          }
        </Grid>
      </Grid>
    )
  }
  return null;
}

export const TabNav = ({ disabledList = [], id = "", list = [], value, handleChange }) => {
  return (
    <Tabs
      value={value}
      onChange={handleChange}
      aria-label={id + '-tabs'}
      indicatorColor='primary'
      sx={{ pointerEvents: 'pointer' }}
      variant={"scrollable"}
      scrollButtons={"on"}
    >
      {list.map((l, index) => {
        return (
          <Tab
            disabled={disabledList.includes(l)}
            key={`${id}-${index}`}
            label={l}
            {...a11yProps(id, index)}
          />
        )
      })}
    </Tabs>
  )
};

const a11yProps = (id = "", index = 0) => {
  return {
    id: `${id}-tab-${index}`,
    "aria-controls": `${id}-tabpanel-${index}`,
  };
}

export default RefferalForm;
