import {
  GET_CONTRACT_REVISION_IN_PROGRESS,
  GET_CONTRACT_REVISION_SUCCESS,
  GET_CONTRACT_REVISION_FAILED,
} from "../../../constants/store";

let initialState = {
  loading: false,
  error: false,
    list: {},
};

function revisionHistoryReducer(state = initialState, action) {
  switch (action.type) {
    // GET USERS
    case GET_CONTRACT_REVISION_IN_PROGRESS: {
      const newState = {
        ...state,
        loading: true,
      };

      return newState;
    }
    case GET_CONTRACT_REVISION_SUCCESS: {
      const newState = {
        ...state,
        list: action.payload,
        loading: false,
      };

      return newState;
    }
    case GET_CONTRACT_REVISION_FAILED: {
      const newState = {
        ...state,
        loading: false,
        error: true,
      };

      return newState;
    }
    default:
      return state;
  }
}

export default revisionHistoryReducer;
