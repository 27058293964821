import { PureComponent } from "react";
import calculateSize from 'calculate-size';

class RenderCustomLineLabel extends PureComponent {
  render() {
    let { x, y, value, unit } = this.props;
    let finalLabel = value;
    if (unit === '$') {
      finalLabel = unit + value;
    } else {
      finalLabel = value + unit;
    }
    const size = calculateSize(finalLabel, {
      font: 'Poppins',
      fontSize: '14px',
    });
    return (
      <g transform={`translate(${x},${y})`}>
        {finalLabel && finalLabel !== '0' && finalLabel !== 0
          ?
          <text
            x={0}
            y={0}
            fill='#334155'
            fontSize={14}
            fontFamily='Poppins'
            fontWeight='bold'
            transform={`translate(${-(size.width / 2)},-10)`}
          >
            {finalLabel}
          </text>
          :
          null
        }
      </g>
    )
  }
}

export default RenderCustomLineLabel;