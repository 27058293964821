import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import LocalSearchableDropdown from './LocalSearchableDropdown';
import { toast } from 'react-toastify';
import CloseIcon from "@mui/icons-material/Close";
import { red } from '@mui/material/colors';
import { getDocumentForContract, uploadDocument } from '../store/ConsultantForm/action';
import { useDispatch } from 'react-redux';
import { trimFilename } from '../utils/consultantConstants';

const baseStyle = {
  padding: '20px',
  borderWidth: 2,
  borderRadius: 10,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  height: '250px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const DropZone = ({ show = false, handleClose, handleNext, options = [], contractId = "", listName = "", postSave = false, handlePostSave }) => {
  const [file, setFile] = useState(null);
  const [documentSelected, setDocumentSelected] = useState(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const uploadedFile = acceptedFiles[acceptedFiles.length - 1];
      const reader = new FileReader();
      reader.readAsDataURL(uploadedFile);
      reader.onload = () => {
        let fileSize = (uploadedFile.size / (1024 * 1024)).toFixed(2);
        var uploadInput = document.getElementById('file-upload-input');
        uploadInput.value = '';
        if (fileSize <= 5) {
          let fileInfo = {
            name: trimFilename(uploadedFile.name),
            value: reader.result,
            fileObj: uploadedFile,
          };
          if (uploadedFile.type === "application/pdf") {
            setFile({
              type: "pdf",
              ...fileInfo,
            });
          } else if (
            uploadedFile.type === "image/jpeg" ||
            uploadedFile.type === "image/png"
          ) {
            setFile({
              type: "jpg",
              ...fileInfo,
            });
          } else if (
            uploadedFile.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
            uploadedFile.type === "application/vnd.ms-excel"
          ) {
            setFile({
              type: "excel",
              ...fileInfo,
            });
          } else if (uploadedFile.type === "text/csv") {
            setFile({
              type: "csv",
              ...fileInfo,
            });
          } else if (
            uploadedFile.type === "application/msword" ||
            uploadedFile.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          ) {
            setFile({
              type: "doc",
              ...fileInfo,
            });
          } else {
            toast.error('Document format not supported!');
          }
        } else {
          toast.error('Document size should not exceed 5 MB!');
        }
      };
    }
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({ onDrop, maxFile: 1 });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const handleChange = (value) => {
    setDocumentSelected(value);
  }

  const handleUpload = async () => {
    if (!loading) {
      if (!documentSelected) {
        toast.error('Please select a document type!');
      } else if (!file) {
        toast.error('Please choose a file to upload!');
      } else {
        setLoading(true);
        var fileName = file.name.split('.');
        const res = await uploadDocument({
          name: fileName.length ? fileName[0] : file.name,
          base64: file.value,
          type: file.type,
          category: listName + ":" + documentSelected,
          id: contractId
        });
        if (res?.data?.status) {
          dispatch(getDocumentForContract({ id: contractId }));
          toast.success('Document uploaded successfully');
          setLoading(false);
          handleNext(file, documentSelected, res?.data?.data?.url);
        } else {
          toast.error('Document upload failed!');
          setLoading(false);
        }
      }
    }
  }

  const handleRemoveFile = () => {
    setFile(null);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="upload-document-modal"
      aria-describedby="upload-document-modal-description"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle2' sx={{ mb: 1 }}>Document Type</Typography>
            <LocalSearchableDropdown
              value={documentSelected}
              label={'Document Type'}
              options={options.map(item => ({ value: item, label: item }))}
              handleChange={(v) => handleChange(v.value)}
              id={'document-type-input'}
            />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} {...getRootProps({ style })}>
              <input id="file-upload-input" {...getInputProps()} />
              {
                file?.name ?
                  <Box sx={{ borderColor: '#00e676', borderStyle: 'dashed', borderWidth: 2, p: 2, borderRadius: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant='body1' color='#000'>{file?.name || ""}</Typography>
                    <Button id={'remove-document-btn'} variant='text' sx={{ px: 0, minWidth: 'auto', color: red[500] }} onClick={handleRemoveFile}>
                      <CloseIcon />
                    </Button>
                  </Box>
                  :
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant='subtitle2'>Drag 'n' drop file here, or click to select a file</Typography>
                    <Typography variant='subtitle2'>Supported Formats: Pdf, Excel, Image, CSV & Word.</Typography>
                  </Box>
              }
            </Box>
          </Grid>
        </Grid>
        <Alert severity="warning" sx={{ mt: 2 }}>
          Max file size: 5 MB
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button id={'form-close-btn'} onClick={handleClose}>Close</Button>
        <Button
          id={'form-upload-btn'}
          startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null}
          onClick={() => {
            if (postSave) {
              handlePostSave(listName, documentSelected, file);
            } else {
              handleUpload();
            }
          }}
          variant='contained'
        >
          {postSave ? `Add` : `Upload`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DropZone;