import { useEffect, useState } from "react";
import { ArrowBack, Info } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { getCandidateInformation } from "../../../store/Contract/action";
import { getUserConfiguration } from "../../../store/ConsultantForm/action";
import { convertToRequiredTabFormat } from "../../../utils/consultantConstants";
import _ from "lodash";
import Revision from "../ContractRevisionHistory/Revision";
import { getCurrentPageIdentifier } from "../../../utils/appHelper";

function CandidateDetailsScreen() {
  const { currentUser = null } = useSelector(state => state.auth);
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const pathname = location?.pathname || "";
  const ids = pathname.split("/");
  const id = ids.length > 1 ? ids[ids.length - 1] : "";
  const status = ids.length > 2 ? ids[ids.length - 2] : "";
  let hasPermission = false;
  let foundItem = _.find(currentUser?.permisson_matrix || [], { identifier: getCurrentPageIdentifier() });
  if (foundItem) {
    hasPermission = (foundItem?.actions || []).includes('READ');
  }
  const { candidate = null, loadingHistory = false, allOptions = null } = useSelector((state) => state.contract);
  const { loading = false } = useSelector((state) => state.consultantForm);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (id && hasPermission) {
      dispatch(getUserConfiguration({ id: currentUser?.id }, (res) => {
        if (res?.status) {
          let list = res?.data?.userGroup?.configuration || [];
          let foundItem = _.find(list, { varient: status });
          let tabList = convertToRequiredTabFormat(foundItem?.tabs || []);
          setTabs(tabList);
          dispatch(getCandidateInformation({ id: id, config: tabList }));
        }
      }));
    }
  }, [hasPermission]);

  useEffect(() => {
    handleApplyRules();
  }, [candidate]);

  const handleApplyRules = () => {
    let tabList = JSON.parse(JSON.stringify(tabs));
    let conditions = [];
    tabList.forEach(tabItem => {
      (tabItem?.fields || []).forEach(field => {
        if (field?.rules?.length) {
          conditions.push(...field.rules);
        }
      });
    });
    conditions.forEach(conditionItem => {
      let conditionSatisfiedCount = 0;
      conditionItem?.conditions?.forEach(condition => {
        let conditionSatisfied = false;
        let key = condition?.key || "";
        let value = candidate?.[key] || "";
        if (condition?.type === 'equalTo') {
          conditionSatisfied = condition?.value == value;
        } else if (condition?.type === 'notEqualTo') {
          conditionSatisfied = condition?.value != value;
        } else if (condition?.type === 'empty') {
          conditionSatisfied = !value;
        } else if (condition?.type === 'notEmpty') {
          conditionSatisfied = value ? true : false;
        }
        if (conditionSatisfied) {
          conditionSatisfiedCount++;
        }
      });
      if (conditionSatisfiedCount === conditionItem?.conditions?.length) {
        tabList.forEach(tabItem => {
          (tabItem?.fields || []).forEach(item => {
            let variable = item?.variable || "";
            if (item?.type === 'location') {
              variable = item?.address?.variable || item?.country?.variable || item?.state?.variable || item?.city?.variable || item?.zipCode?.variable;
            }
            if ((conditionItem?.variable === variable || conditionItem?.variable === item.confidential) && !item.systemRequired) {
              if (item?.type === 'location') {
                let locationConfig = {};
                locationConfig.address = {
                  hidden: !conditionItem.visibility1,
                  required: conditionItem.requiredField1,
                };
                locationConfig.country = {
                  hidden: !conditionItem.visibility2,
                  required: conditionItem.requiredField2,
                };
                locationConfig.state = {
                  hidden: !conditionItem.visibility3,
                  required: conditionItem.requiredField3,
                };
                locationConfig.city = {
                  hidden: !conditionItem.visibility4,
                  required: conditionItem.requiredField4,
                };
                locationConfig.zipCode = {
                  hidden: !conditionItem.visibility5,
                  required: conditionItem.requiredField5,
                };
                item.locationConfig = locationConfig;
              } else {
                item.hidden = !conditionItem.visibility;
                item.required = conditionItem.requiredField;
              }
            }
          })
        })
      }
    });
    setTabs(tabList);
  };

  const handleGoBack = () => {
    history.goBack();
  }

  return (
    <Box id="candidate-information-screen" sx={{ width: '100%', minHeight: '100svh', bgcolor: '#fff', position: 'relative', p: 2 }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, p: 1, bgcolor: blue[50], borderRadius: 2, mb: 1, zIndex: 999 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, }}>
          <IconButton id='exit-btn' sx={{ cursor: 'default', width: 35, height: 35, bgcolor: blue[500], borderRadius: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#fff', }}>
            <Info />
          </IconButton>
          <Typography variant='subtitle2' sx={{ color: blue[500], fontSize: 20 }}>Candidate Information</Typography>
        </Box>
      </Box>
      <Box sx={{ bgcolor: grey[100], border: "1px solid rgba(0, 0, 0, 0.05)", borderRadius: 2, mt: 2, position: 'relative', minHeight: '85svh' }}>
        <Box id={'revision-history-view'} sx={{ width: "100%", height: '80svh', overflowY: 'auto', px: 2, pt: 0.5, pb: 2 }}>
          {hasPermission && tabs?.length ?
            <Revision showOnlyChanges={false} data={candidate} compareData={{ finalObj: candidate, allOptions: allOptions }} tabs={tabs.slice(0, 1)} optionList={allOptions} />
            :
            <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ width: 35, height: 35, color: '#475569' }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" viewBox="0 0 24 24" className="humbleicons hi-ship"><path xmlns="http://www.w3.org/2000/svg" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 17l.756.378a3 3 0 002.523.074l1.04-.446a3 3 0 012.363 0l1.04.446a3 3 0 002.523-.074l.413-.207a3 3 0 012.684 0l.547.273a3 3 0 002.29.163L21 17M5 14.5L4 10h4m10 4.5l2.5-4.5h-8.245H13.5m0 0l-.721-3H8v3m5.5 0H8m3 3h.1M10 4.5l-.2-.2a2 2 0 00-1.899-.525l-.336.084a2 2 0 01-1.118-.043L5.5 3.5" /></svg>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                {loading ? <CircularProgress size={20} color="primary" /> : null}
                <Typography sx={{ fontSize: '14px', color: '#475569', textAlign: 'center', fontFamily: 'Poppins', fontWeight: 600 }}>{loading || loadingHistory ? `Fetching candidate information` : `No permissions found`}</Typography>
              </Box>
            </Box>
          }
        </Box>
      </Box>
    </Box>
  )
}

export default CandidateDetailsScreen;